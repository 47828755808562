import React from 'react';
import OrderList from '../../../components/admin/orders/OrderList';

const Orders: React.FC = () => {

    return (<div>
        <OrderList />
    </div>)
};

export default Orders;