import React from 'react';
import ClientList from '../../../components/admin/clients/ClientList';

const Clients: React.FC = () => {

    return (<div>
        <ClientList />
    </div>)
};

export default Clients;