import React, { useContext, useMemo } from "react";
import {
    Link,
    Navigate,
    Outlet,
    Route,
    Routes,
    useLocation
} from "react-router-dom";
import AdminApp from "./AdminApp";
import StatisticsView from "./components/admin/statistics/StatisticsView";
import ForgotPassword from "./components/authenticate/ForgotPassword";
import Login from "./components/authenticate/Login";
import ResetPassword from "./components/authenticate/ResetPassword";
import FullScreenWrapper from "./components/FullscreenWrapper";
import { AuthContext } from "./contexts/AuthContext";
import CreateApp from "./CreateApp";
import Clients from "./pages/admin/clients/Clients";
import DeliveryConfiguration from "./pages/admin/deliveryConfigurations/DeliveryConfigurations";
import AdminLandingpage from "./pages/admin/landingpage/Landingpage";
import Materials from "./pages/admin/materials/Materials";
import Modelpacks from "./pages/admin/modelpacks/Modelpacks";
import Models from "./pages/admin/models/Models";
import Modifiers from "./pages/admin/modifiers/Modifiers";
import Scenes from "./pages/admin/scenes/Scenes";
import AdminTemplates from "./pages/admin/templates/Templates";
import Users from "./pages/admin/users/Users";
import { CutoutsPage } from "./pages/CutoutsPage";
import Designer from "./pages/Designer";
import ErrorPage from "./pages/ErrorPage";
import Landingpage from "./pages/Landingpage";
import Projects from "./pages/Projects";
import StatisticsPage from "./pages/StatisticsPage";
import Template from "./pages/Template";
import TemplateCategories from "./pages/TemplateCategories";
import { ConfigContext } from "./contexts/ConfigContext";
import ProjectsPage from "./pages/admin/projects/Projects";
import ProjectPage from "./pages/admin/projects/Project";
import Orders from "./pages/admin/orders/Orders";
import Order from "./pages/admin/orders/Order";
import ProjectFolder from "./pages/ProjectFolder";
import AssetProjectPage from "./pages/admin/assetProjects/AssetProject";
import AssetProjectsPage from "./pages/admin/assetProjects/AssetProjects";
import SearchPage from "./pages/SearchPage";

const ProtectedRoute = () => {
    const { isLoggedIn } = useContext(AuthContext);
    const location = useLocation();

    if (!isLoggedIn) {
        return <Navigate to="/users/login" replace state={{ from: location }} />
    }

    return (
        <Outlet />
    );
};

const clientRoutes: { [key: string]: JSX.Element } = {
    'default': (
        <Route path="create" key='default' element={<CreateApp />}>
            <Route index element={<Landingpage />} />
            <Route path="new" element={<TemplateCategories />} />
            <Route path="new/:templateID" element={<Template />} />
            <Route path="edit/:projectID?" element={<Designer />} />
            <Route path="projects/:projectId?" element={<Projects />} />
            <Route path="projectfolders/:folderId" element={<ProjectFolder />} />
            <Route path="statistics" element={<StatisticsPage />} />
            <Route path="cutouts" element={<CutoutsPage />} />
            <Route path="search" element={<SearchPage />} />
        </Route>
    ),
}

const AppRouter: React.FC = () => {
    const { currentClient } = useContext(ConfigContext);


    let routes = useMemo(() => {
        const clientRoute = clientRoutes[currentClient.name.toLowerCase()] ?? clientRoutes['default'];

        return (
            <Routes key={clientRoute.key}>
                <Route errorElement={<ErrorPage />}>
                    <Route path="/users" element={<FullScreenWrapper />}>
                        <Route path="login" element={<Login />} />
                        <Route path="forgotpassword" element={<ForgotPassword />} />
                        <Route path="resetpassword" element={<ResetPassword />} />
                        <Route path="resetconfirmation" element={<><h1>Your password has been reset!</h1><Link to="/">Go to login</Link></>} />
                        <Route path="forgotconfirmation" element={<h1>You will receive an email shortly with a link to reset your password.</h1>} />
                    </Route>
                    <Route element={<ProtectedRoute />}>
                        <Route index element={<Navigate to={{ pathname: "/create" }} />} />
                        {clientRoute}
                        <Route path="/admin" element={<AdminApp />}>
                            <Route index element={<AdminLandingpage />} />
                            <Route path="orders">
                                <Route index element={<Orders />} />
                                <Route path=":orderid" element={<Order />} />
                            </Route>
                            <Route path="projects">
                                <Route index element={<ProjectsPage />} />
                                <Route path=":projectId" element={<ProjectPage />} />
                            </Route>
                            <Route path="assetprojects">
                                <Route index element={<AssetProjectsPage />} />
                                <Route path=":projectId" element={<AssetProjectPage />} />
                            </Route>
                            <Route path="clients" element={<Clients />} />
                            <Route path="deliveryConfigurations" element={<DeliveryConfiguration />} />
                            <Route path="materials" element={<Materials />} />
                            <Route path="models" element={<Models />} />
                            <Route path="modelpacks" element={<Modelpacks />} />
                            <Route path="scenes" element={<Scenes />} />
                            <Route path="templates" element={<AdminTemplates />} />
                            <Route path="modifiers" element={<Modifiers />} />
                            <Route path="users" element={<Users />} />
                            <Route path="statistics" element={<StatisticsView />} />
                        </Route>
                    </Route>
                </Route>
            </Routes>);
    }, [currentClient]);

    return routes;
};

export default AppRouter;
