import classnames from 'classnames';
import React from 'react';
import ModernDrawer from 'react-modern-drawer';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import './BottomDrawer.scss';
import { ReactComponent as Logo } from '../../assets/logo.svg';

type Props = React.PropsWithChildren<{
    className?: string;
    open: boolean;
    onClose?: () => void;
    size: number;
    modal?: boolean;
}>

const BottomDrawer: React.FC<Props> = ({ children, className, open, onClose, size, modal }) => {
    const classes = classnames('bottomdrawer', className)

    return (
        <ModernDrawer className={classes} open={open} onClose={onClose} direction='bottom' size={size} overlayOpacity={0} enableOverlay={modal !== false}>
            <Logo className='logo' />
            {onClose &&
                <div className='header'>
                    <button className='close' onClick={onClose}>
                        <CloseIcon />
                    </button>
                </div>
            }

            <div className='content'>
                {children}
            </div>

        </ModernDrawer>
    )
};

export default BottomDrawer;