import React, { useCallback, useContext, useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import './ModelConfigurator.scss';
import ProductSelector, { Asset, MaterialAsset, ModelAsset, ModelpackAsset } from './ProductSelector';
import { ItemConfigurationValue, ModelConfiguration, Point, SurfaceConfiguration } from '../../contexts/SceneDesignerContext';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty.svg';
import { ConfirmDialog } from '../layout/Dialog';
import { MaterialService, MaterialViewDTO, ModelPackService, ModelPackViewDTO, ModelPlacementPointDTO, ModelService, ModelSurfaceDTO, ModelViewDTO, ScenePlacementpointDTO, SceneSurfaceDTO } from '../../openapi/requests';
import { BASE_PATH } from '../..';
import { ConfigContext } from '../../contexts/ConfigContext';
import { GenerateODataFilter } from '../../helpers/odataFunctions';
import ReactGA from "react-ga4";


interface Props {
    open: boolean;
    point: Point | undefined;
    onChange: (config: ModelConfiguration) => void;
    onClose: () => void;
    simplify?: boolean;
}

const ModelConfigurator: React.FC<Props> = ({ open, onClose, onChange, point, simplify }) => {
    const { odataAssetFilters, odataFilters } = useContext(ConfigContext);

    const [selectorOpen, setSelectorOpen] = useState(false);
    const [editorOpen, setEditorOpen] = useState(false);
    const [selectedSurface, setSelectedSurface] = useState<SurfaceConfiguration | undefined>(undefined);
    const [surfaceSelectorOpen, setSurfaceSelectorOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState<ModelConfiguration | undefined>(undefined);
    const [modelConfiguratorOpen, setModelConfiguratorOpen] = useState(false);

    const [showConfirm, setShowConfirm] = useState(false);
    const [products, setProducts] = useState<(ModelViewDTO | ModelPackViewDTO)[]>([]);
    const [working, setWorking] = useState(false);

    const handleSurfaceClick = (surface: SurfaceConfiguration) => {
        setSelectedSurface(surface);
        setSurfaceSelectorOpen(true);
    }

    const handleModelClick = (model: ModelConfiguration) => {
        setSelectedModel(model);
        setModelConfiguratorOpen(true);
    }

    useEffect(() => {
        if (simplify) {
            setSelectorOpen(open);
        } else {
            if (open) {
                if (point?.type === 'model' && (point.configuration.models.length > 0 || point.configuration.surfaces.length > 0)) {
                    setEditorOpen(true);
                } else {
                    setSelectorOpen(true);
                }
            } else {
                setEditorOpen(false);
                setSelectorOpen(false);
            }
        }
    }, [open, point, simplify]);

    const doModelChange = useCallback(async (products: Asset[], force: boolean): Promise<boolean> => {
        if (point && point.type === "model") {
            ReactGA.event('changeproduct', {type: "model"});

            let oldConfig = point.configuration;

            const placements = oldConfig.values.map(v => ({
                placement: v.placement,
                parent: v.parent,
                values: products.filter(e => e.validFor.some(e => e.name === v.placement.name)) as (ModelAsset | ModelpackAsset)[]
            }));

            let newConfig = oldConfig.setValue(placements);
            newConfig.models = [];
            newConfig.surfaces = [];

            let placementPointsByLabel: { [key: string]: {placement: ModelPlacementPointDTO, parent: ModelViewDTO | ModelPackViewDTO}[] } = {};
            let surfacesByLabel: { [key: string]: {placement: ModelSurfaceDTO, parent: ModelViewDTO | ModelPackViewDTO}[] } = {};
            const modelsAdded: number[] = [];


            for (let i = 0; i < newConfig.uniqueValues.length; i++) {
                const asset = newConfig.uniqueValues[i];
                
                if((asset as ModelPackViewDTO).models){
                    let modelpack = asset as ModelPackViewDTO;
                    var mastermodelID = modelpack.models[0].modelId;

                    var master = modelpack.models.find(e => e.isMasterModel);
                    if (master) {
                        mastermodelID = master.modelId;
                    }

                    let masterModel = await ModelService.getModel1(mastermodelID);

                    if (masterModel && !modelsAdded.includes(masterModel.id)) {
                        masterModel.placementpoints.forEach(p => {
                            if (placementPointsByLabel[p.label]) {
                                placementPointsByLabel[p.label].push({placement: p, parent: asset});
                            } else {
                                placementPointsByLabel[p.label] = [{placement: p, parent: asset}];
                            }
                        });
                        masterModel.surfaces.forEach(s => {
                            if (surfacesByLabel[s.label]) {
                                surfacesByLabel[s.label].push({placement: s, parent: asset});
                            } else {
                                surfacesByLabel[s.label] = [{placement: s, parent: asset}];
                            }
                        });
                        modelsAdded.push(masterModel.id);
                    }
                }else{
                    let model = asset as ModelViewDTO;

                    if (!modelsAdded.includes(model.id)) {
                        model.placementpoints.forEach(p => {
                            if (placementPointsByLabel[p.label]) {
                                placementPointsByLabel[p.label].push({placement: p, parent: asset});
                            } else {
                                placementPointsByLabel[p.label] = [{placement: p, parent: asset}];
                            }
                        });
                        model.surfaces.forEach(s => {
                            if (surfacesByLabel[s.label]) {
                                surfacesByLabel[s.label].push({placement: s, parent: asset});
                            } else {
                                surfacesByLabel[s.label] = [{placement: s, parent: asset}];
                            }
                        });
                        modelsAdded.push(model.id);
                    }
                }
            }

            for (const key in surfacesByLabel) {
                if (Object.prototype.hasOwnProperty.call(surfacesByLabel, key)) {
                    const surfaces = surfacesByLabel[key];

                    const oldSurfaceConfig = oldConfig.surfaces.find(e => e.placement.label === surfaces[0].placement.label);
                    const placements: ItemConfigurationValue<MaterialViewDTO, SceneSurfaceDTO | ModelSurfaceDTO>[] = surfaces.map(e => ({placement: e.placement, parent: e.parent, values: []}));

                    if(oldSurfaceConfig !== undefined && oldSurfaceConfig.uniqueValues.length > 0){

                        for (let i = 0; i < surfaces.length; i++) {
                            const surface = surfaces[i].placement;
                            let validMaterials = await MaterialService.getMaterial(surface.materialSelectionFilter, undefined, undefined, GenerateODataFilter(odataAssetFilters));
                            
                            let placement = placements.find(e => e.placement.name === surface.name);
                            if(placement){
                                placement.values = oldSurfaceConfig.uniqueValues.filter(v => validMaterials.value.some(e => e.id === v.id));
                            }
                        }
                    }

                    const newSurfaceConfig = new SurfaceConfiguration(placements);

                    if(oldSurfaceConfig && oldSurfaceConfig.uniqueValues.length > 0 && newSurfaceConfig.uniqueValues.length === 0){
                        if(!force){
                            return false;
                        }
                    }

                    newConfig.surfaces.push(newSurfaceConfig);
                }
            }

            for (const key in placementPointsByLabel) {
                if (Object.prototype.hasOwnProperty.call(placementPointsByLabel, key)) {
                    const points = placementPointsByLabel[key];

                    const oldModelConfig = oldConfig.models.find(e => e.placement.label === points[0].placement.label);
                    const placements: ItemConfigurationValue<ModelViewDTO | ModelPackViewDTO, ScenePlacementpointDTO | ModelPlacementPointDTO>[] = points.map(e => ({placement: e.placement, parent: e.parent, values: []}));

                    if(oldModelConfig !== undefined && oldModelConfig.uniqueValues.length > 0){

                        for (let i = 0; i < points.length; i++) {
                            const point = points[i].placement;

                            if(point.useModelpack){
                                let validModelpacks = await ModelPackService.getModelPack(point.modelSelectionFilter, undefined, undefined, GenerateODataFilter(odataFilters));
                                
                                let placement = placements.find(e => e.placement.name === point.name);
                                if(placement){
                                    placement.values = oldModelConfig.uniqueValues.filter(v => validModelpacks.value.some(e => e.id === v.id));
                                }
                            }else{
                                let validModels = await ModelService.getModel(point.modelSelectionFilter, undefined, undefined, GenerateODataFilter(odataAssetFilters));
                                
                                let placement = placements.find(e => e.placement.name === point.name);
                                if(placement){
                                    placement.values = oldModelConfig.uniqueValues.filter(v => validModels.value.some(e => e.id === v.id));
                                }
                            }
                        }
                    }

                    const newModelConfig = new ModelConfiguration(placements);

                    if(oldModelConfig && oldModelConfig.uniqueValues.length > 0 && newModelConfig.uniqueValues.length === 0){
                        if(!force){
                            return false;
                        }
                    }

                    newConfig.models.push(newModelConfig);
                }
            }



            onChange(newConfig);
            return true;
        }

        return true;
    }, [point, onChange, odataAssetFilters, odataFilters]);

    const handleMaterialChange = useCallback((product: Asset[]) => {
        if (selectedSurface && point && point.type === 'model') {
            ReactGA.event('changeproduct', {type: "material"});

            const placements = selectedSurface.values.map(v => ({
                placement: v.placement,
                parent: v.parent,
                values: product.filter(e => e.validFor.some(e => e.name === v.placement.name)) as MaterialAsset[]
            }));
            const newSurface = selectedSurface.setValue(placements);

            const newConfig = point.configuration.clone();
            newConfig.surfaces = [...newConfig.surfaces.filter(e => e.placement.name !== selectedSurface.placement.name), newSurface];

            onChange(newConfig);
        }
    }, [point, onChange, selectedSurface]);

    const handleSubModelChange = useCallback((product: ModelConfiguration) => {
        if (selectedModel && point && point.type === 'model') {
            const newConfig = point.configuration.clone();
            newConfig.models = [...newConfig.models.filter(e => e.placement.name !== selectedModel.placement.name), product];
            setSelectedModel(product);
            onChange(newConfig);
        }
    }, [point, onChange, selectedModel]);

    const handleModelChange = useCallback((products: Asset[]) => {

        setWorking(true);
        doModelChange(products, false).then(result => {
            setWorking(false);
            if(!result){
                setProducts(products as (ModelAsset | ModelpackAsset)[]);
                setShowConfirm(true);
            }else{
                setSelectorOpen(false);
                onClose();
            }
        });

    }, [doModelChange, onClose]);

    const handleConfirm = useCallback(() => {
        setShowConfirm(false);
        setWorking(true);

        doModelChange(products as (ModelAsset | ModelpackAsset)[], true).then(() => {
            setWorking(false);
            setSelectorOpen(false);
            onClose();
        });
    }, [doModelChange, onClose, products]);

    const handleSelectorClose = useCallback(() => {
        setSelectorOpen(false);
        onClose();
    }, [onClose]);

    return (
        <>
            <ConfirmDialog onConfirm={handleConfirm} open={showConfirm} onCancel={() => setShowConfirm(false)} text='By making this selection, you are about the remove the attached items.' title='Are you sure?' />

            {(point !== undefined && point.type === 'model') &&
                <>
                    <Drawer direction='right' className='modelConfigurator' open={editorOpen} size={530} onClose={onClose} overlayOpacity={0} enableOverlay={false}>
                        <div className='header'>
                            <h1>Configure {point.configuration.placement.label}</h1>
                            <button className='close' onClick={onClose}>
                                <CloseIcon />
                            </button>
                        </div>

                        <div className='current-model'>
                            <div className='product-image'>
                                <span className='point-label'>{point.configuration.id}</span>
                                {point.configuration.value === undefined ?
                                    <EmptyIcon /> :
                                    <img src={BASE_PATH + point.configuration.value.thumbnail} alt='' />
                                }
                            </div>

                            <h3>{point.configuration.value?.title ?? "Empty"}</h3>
                        </div>

                        <div className='attributes'>

                            <div className='attribute'>
                                <div>
                                    <label>Interaction Point</label>
                                    {point.configuration.placement.label}
                                </div>
                                <svg className='line' width="1000" height="2" viewBox="0 0 1000 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.5" d="M1000 1H0" stroke="black" strokeOpacity="0.5" strokeDasharray="2 2" />
                                </svg>
                            </div>

                            {point.configuration.value?.metadata.some(e => e.name === 'ean') && <div className='attribute'>
                                <div>
                                    <label>Serial Number</label>
                                    {point.configuration.value?.metadata.find(e => e.name === 'ean')?.value}
                                </div>
                                <svg className='line' width="1000" height="2" viewBox="0 0 1000 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.5" d="M1000 1H0" stroke="black" strokeOpacity="0.5" strokeDasharray="2 2" />
                                </svg>
                            </div>}

                            <button onClick={() => setSelectorOpen(true)}>
                                <span>Change item</span>
                                <ArrowRightIcon />
                            </button>

                            {point.configuration.value && <div className='model-items'>
                                {(point.configuration.surfaces.length > 0 || point.configuration.models.length > 0) && <h2>Attached Items ({point.configuration.surfaces.length + point.configuration.models.length})</h2>}
                                {point.configuration.surfaces.map(surface => <div className='item' onClick={() => handleSurfaceClick(surface)} key={surface.placement.name}>
                                    <div className='product-image'>
                                        <span className='point-label'>{surface.id}</span>
                                        {surface.value === undefined ?
                                            <EmptyIcon /> :
                                            <img src={BASE_PATH + surface.value.thumbnail} alt='' />
                                        }
                                    </div>
                                </div>)}
                                {point.configuration.models.map(model => <div className='item' onClick={() => handleModelClick(model)} key={model.placement.name}>
                                    <div className='product-image'>
                                        <span className='point-label'>{model.id}</span>
                                        {model.value === undefined ?
                                            <EmptyIcon /> :
                                            <img src={BASE_PATH + model.value.thumbnail} alt='' />
                                        }
                                    </div>
                                </div>)}
                            </div>}
                        </div>
                    </Drawer>


                    <ProductSelector
                        open={selectorOpen}
                        point={point}
                        working={working}
                        onCancel={handleSelectorClose}
                        onProductSelected={handleModelChange} />

                    {selectedSurface && <ProductSelector
                        open={surfaceSelectorOpen}
                        point={{ type: 'material', configuration: selectedSurface, isRequired: true, label: '', offbrandProduct: false }}
                        onCancel={() => setSurfaceSelectorOpen(false)}
                        onProductSelected={handleMaterialChange} />}

                    {selectedModel && <ModelConfigurator
                        point={{ type: 'model', configuration: selectedModel, isRequired: selectedModel.placement.isRequired, label: '', offbrandProduct: false }}
                        onChange={handleSubModelChange}
                        onClose={() => setModelConfiguratorOpen(false)}
                        open={modelConfiguratorOpen}
                    />}
                </>
            }
        </>
    );
};

export default ModelConfigurator;