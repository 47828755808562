import { Button, Flex, Input, message, Popconfirm, Space, Table } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { PlusOutlined } from '@ant-design/icons';
import CreateTemplate from './CreateTemplate';
import EditTemplate from './EditTemplate';
import { TemplateViewDTO } from '../../../openapi/requests';
import { useDebounce } from 'use-debounce';
import { Filter } from '../../../types/types';
import { useTemplateServiceDeleteTemplate, useTemplateServiceGetTemplate } from '../../../openapi/queries';
import { GenerateODataFilter } from '../../../helpers/odataFunctions';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import TemplateQA from './TemplateQA';
import TemplateCameraRender from './TemplateCameraRender';


const TemplateList: React.FC = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateViewDTO | undefined>(undefined);

    const [createOpen, setCreateOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [qaOpen, setQaOpen] = useState(false);
    const [camRenderOpen, setCamRenderOpen] = useState(false);

    const [query, setQuery] = useState("");
    const [debouncedQuery] = useDebounce(query, 500);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState<string>("id");
    const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");

    const filters = useMemo(() => {
        let filters: Filter[] = [];

        if (debouncedQuery) {
            filters.push({type: 'search', values: [debouncedQuery], property: 'title', name: 'title'});
        }

        return filters;
    }, [debouncedQuery]);

    const { data, isLoading, refetch } = useTemplateServiceGetTemplate({ 
        top: pageSize.toString(), 
        skip: ((page - 1) * pageSize).toString(),
        filter: GenerateODataFilter(filters),
        orderby: `${sortField} ${sortOrder}`,
        count: 'true' })
    const {mutate, isSuccess} = useTemplateServiceDeleteTemplate();


    const openCreate = () => {
        setCreateOpen(true);
    };

    const handleEdit = useCallback((template: TemplateViewDTO) => {
        setSelectedTemplate(template);
        setEditOpen(true);
    }, []);

    const handleQa = useCallback((template: TemplateViewDTO) => {
        setSelectedTemplate(template);
        setQaOpen(true);
    }, []);

    const handleCamRender = useCallback((template: TemplateViewDTO) => {
        setSelectedTemplate(template);
        setCamRenderOpen(true);
    }, []);

    const handleCreateClose = useCallback(() => {
        setCreateOpen(false);
        refetch();
    }, [refetch]);

    const handleEditClose = useCallback(() => {
        setEditOpen(false);
        refetch();
    }, [refetch]);

    const handleQaClose = useCallback(() => {
        setQaOpen(false);
    }, []);

    const handleCamRenderClose = useCallback(() => {
        setCamRenderOpen(false);
    }, []);

    const handleTableChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<TemplateViewDTO> | SorterResult<TemplateViewDTO>[]) => {
        setPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);

        if(!Array.isArray(sorter)){
            setSortField(sorter.field?.toString() ?? "id");
            setSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc');
        }
    }, []);

    const handleDelete = useCallback((material: TemplateViewDTO) => {
        if(material.id){
            mutate({key: material.id})
        }
    }, [mutate]);

    useEffect(() => {
        if(isSuccess){
            messageApi.success("Template deleted");
        }
    }, [isSuccess, messageApi]);

    const columns: ColumnsType<TemplateViewDTO> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => (a.id || 0) - (b.id || 0),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => (a.title || '').localeCompare(b.title || ''),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => handleEdit(record)}>Edit</Button>
                    <Button type="link" onClick={() => handleQa(record)}>Render QA images</Button>
                    <Button type="link" onClick={() => handleCamRender(record)}>Render cameras</Button>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                        <Button type="link">Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <>
            {contextHolder}

            <Flex justify='space-between' style={{ marginBottom: 16 }}>
                <Space>
                    <Input placeholder='search' value={query} onChange={e => setQuery(e.target.value)} />
                </Space>

                <Space>
                    <Button type="primary" onClick={openCreate} icon={<PlusOutlined />}>
                        New template
                    </Button>
                </Space>
            </Flex>

            <CreateTemplate isOpen={createOpen} onClose={handleCreateClose} />

            <EditTemplate isOpen={editOpen} onClose={handleEditClose} template={selectedTemplate} />

            <TemplateQA isOpen={qaOpen} onClose={handleQaClose} template={selectedTemplate} />

            <TemplateCameraRender isOpen={camRenderOpen} onClose={handleCamRenderClose} template={selectedTemplate} />

            <Table
                loading={isLoading}
                dataSource={data?.value ?? Array.from({ length: pageSize }).map(x => ({}))}
                columns={columns}
                rowKey={"id"}
                onChange={handleTableChange}
                pagination={{
                    total: (data !== undefined && data['@odata.count']) ? data['@odata.count'] : 0,
                    current: page,
                    pageSize: pageSize,
                }} />
        </>
    );
};

export default TemplateList;