import classnames from 'classnames';
import React, { MutableRefObject, useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import './Layout.scss';
import { Menu } from './menu/Menu';

const Layout: React.FC<React.PropsWithChildren<{className?: string, onScroll?: (ref: MutableRefObject<HTMLDivElement>) => void}>> = ({children, className, onScroll}) => {
    const { username } = useContext(AuthContext);
    const [ isMenuOpen, setIsMenuOpen ] = useState(false)
    const classes = classnames('page-content', className)
    const contentRef = useRef<HTMLDivElement>({} as HTMLDivElement)
    
    const onPageScroll = (ref: MutableRefObject<HTMLDivElement>) => {
        var scrollFunc = (onScroll || null);
        if (scrollFunc) {
            scrollFunc(ref)
        }
    }

    return (<div className='page-container'>
        <header>
            <Link to={'/create'}><span className='logo'>C✦D</span></Link>
            <button onClick={() => { setIsMenuOpen(!isMenuOpen) }}>Menu</button>
            <span className='spacer'></span>
            <span>Welcome {username}</span>
        </header>
        <Menu isOpen={isMenuOpen} onClose={() => { setIsMenuOpen(!isMenuOpen) }} />
        <div className={classes} ref={contentRef} onScroll={() => onPageScroll(contentRef)}>
            {children}
        </div>
    </div>)
};

export default Layout;