import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { OpenAPI } from './openapi/requests';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import App from './App';
import { ConfigProvider } from './contexts/ConfigContext';

import ReactGA from "react-ga4"

ReactGA.initialize("G-X3J1VP9Z8L");

// eslint-disable-next-line no-restricted-globals
export const isDevSite = /*location.hostname.includes("dev") || */location.hostname.includes("internal") || location.hostname.includes("localhost");
export const BASE_PATH = (isDevSite ? process.env.REACT_APP_APIBASEPATH_DEV : process.env.REACT_APP_APIBASEPATH_PROD) || "";

OpenAPI.BASE = BASE_PATH;
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ConfigProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ConfigProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
