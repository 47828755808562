import { NodeModel, NodeModelGenerics, DiagramEngine, PortModel } from '@projectstorm/react-diagrams';
import * as React from 'react';
import { DeserializeEvent, PeformanceWidget } from '@projectstorm/react-canvas-core';
import { ModelPortModel } from './ModelPort';
import { MaterialPortModel } from './MaterialPort';
import { useCallback } from 'react';
import { ModifierPortModel } from './ModifierPort';
import { ConfiguratorPortLabel } from './ConfiguratorPort';
import { ModelPackPortModel } from './ModelPackPort';

export class ConfiguratorNodeModel<T extends {id: number}> extends NodeModel<NodeModelGenerics> {
	protected modelPorts: ModelPortModel[];
	protected modelPackPorts: ModelPackPortModel[];
	protected materialPorts: MaterialPortModel[];
	protected modifierPorts: ModifierPortModel[];

	data: T | undefined;
	updateCallback: () => void;

	constructor(type: string, updateCallback: () => void) {
		super({
			type
		});
		this.modelPorts = [];
		this.modelPackPorts = [];
		this.materialPorts = [];
        this.modifierPorts = [];

		this.updateCallback = updateCallback;
	}

	async loadData(id: number) {

	}

	addPort<T extends PortModel>(port: T): T {
		super.addPort(port);
		if (port instanceof ModelPortModel) {
			if (this.modelPorts.indexOf(port) === -1) {
				this.modelPorts.push(port);
			}
		}
		if (port instanceof ModelPackPortModel) {
			if (this.modelPackPorts.indexOf(port) === -1) {
				this.modelPackPorts.push(port);
			}
		}
		if (port instanceof MaterialPortModel) {
			if (this.materialPorts.indexOf(port) === -1) {
				this.materialPorts.push(port);
			}
		}
		if (port instanceof ModifierPortModel) {
			if (this.modifierPorts.indexOf(port) === -1) {
				this.modifierPorts.push(port);
			}
		}
		return port;
	}

	deserialize(event: DeserializeEvent<this>) {
		super.deserialize(event);
		this.modelPorts = event.data.modelPorts.map((id: any) => this.getPortFromID(id)) as ModelPortModel[];
		this.materialPorts = event.data.materialPorts.map((id: any) => this.getPortFromID(id)) as MaterialPortModel[];
		this.modifierPorts = event.data.modifierPorts.map((id: any) => this.getPortFromID(id)) as ModifierPortModel[];
		this.modelPackPorts = event.data.modelPackPorts.map((id: any) => this.getPortFromID(id)) as ModelPackPortModel[];
	}

	serialize(): any {
		return {
			...super.serialize(),
			dataId: this.data?.id ?? 0,
			modelPorts: this.modelPorts.map(e => e.getID()),
			materialPorts: this.materialPorts.map(e => e.getID()),
			modifierPorts: this.modifierPorts.map(e => e.getID()),
			modelPackPorts: this.modelPackPorts.map(e => e.getID()),
		};
	}

	getModelPorts(): ModelPortModel[] {
		return this.modelPorts;
	}

	getModelPackPorts(): ModelPackPortModel[] {
		return this.modelPackPorts;
	}

	getMaterialPorts(): MaterialPortModel[] {
		return this.materialPorts;
	}

	getModifierPorts(): ModifierPortModel[] {
		return this.modifierPorts;
	}
}

export interface ModelNodeProps {
	node: ConfiguratorNodeModel<any>;
	engine: DiagramEngine;
	children?: React.ReactNode;
}

export const ConfiguratorNodeWidget: React.FC<ModelNodeProps> = ({ engine, node, children }) => {
	const [canDrop, setCanDrop] = React.useState(false);

	const onDrop = useCallback((evt: React.DragEvent) => {

		var data: { type: string, id: number } = JSON.parse(evt.dataTransfer.getData('node'));

		if (data.type === node.getType()) {
			evt.stopPropagation();
			node.loadData(data.id);
		}

		setCanDrop(false);
	}, [node]);

	const onDragEnter = useCallback((evt: React.DragEvent) => {
		if (evt.currentTarget.contains(evt.relatedTarget as HTMLElement)) {
			return;
		}

		var data: { type: string, id: number } = JSON.parse(evt.dataTransfer.getData('node'));

		if (data.type === node.getType()) {
			setCanDrop(true);
		}
	}, [node]);

	const onDragLeave = useCallback((evt: React.DragEvent) => {
		if (evt.currentTarget.contains(evt.relatedTarget as HTMLElement)) {
			return;
		}

		setCanDrop(false);
	}, []);

	return (
		<PeformanceWidget model={node} serialized={{...node.serialize(), canDrop}}>
			{() => {
				return (
					<div
						className={`node-item node-item-${node.getType()} ${node.isSelected() ? 'selected' : ''} ${canDrop ? 'replace' : ''}`}
						data-nodeid={node.getID()}
						onDragEnter={onDragEnter}
						onDragLeave={onDragLeave}
						style={{
							top: node.getY(),
							left: node.getX()
						}}
						onDrop={onDrop}
					>
						<div className='replace-info'>
							Drop to replace
						</div>
						<div className='header'>
							{node.data?.title}
						</div>
						<div className='props'>
							{children}
						</div>
						<div className='ports'>
							{node.getModelPorts().filter(e => !e.in).map(e => <ConfiguratorPortLabel engine={engine} port={e} key={e.getID()} />)}
							{node.getModelPackPorts().filter(e => !e.in).map(e => <ConfiguratorPortLabel engine={engine} port={e} key={e.getID()} />)}
							{node.getMaterialPorts().filter(e => !e.in).map(e => <ConfiguratorPortLabel engine={engine} port={e} key={e.getID()} />)}
							{node.getModifierPorts().filter(e => !e.in).map(e => <ConfiguratorPortLabel engine={engine} port={e} key={e.getID()} />)}

							{node.getModelPorts().filter(e => e.in).map(e => <ConfiguratorPortLabel engine={engine} port={e} key={e.getID()} />)}
							{node.getModelPackPorts().filter(e => e.in).map(e => <ConfiguratorPortLabel engine={engine} port={e} key={e.getID()} />)}
							{node.getMaterialPorts().filter(e => e.in).map(e => <ConfiguratorPortLabel engine={engine} port={e} key={e.getID()} />)}
							{node.getModifierPorts().filter(e => e.in).map(e => <ConfiguratorPortLabel engine={engine} port={e} key={e.getID()} />)}
						</div>
					</div>
				);
			}}
		</PeformanceWidget>
	);
}