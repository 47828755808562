/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClaimDTO } from '../models/ClaimDTO';
import type { RoleDTO } from '../models/RoleDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleService {

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postRole(
requestBody?: RoleDTO,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Role',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @returns RoleDTO Success
     * @throws ApiError
     */
    public static getRole(): CancelablePromise<Array<RoleDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Role',
        });
    }

    /**
     * @param key 
     * @param id 
     * @param requestBody 
     * @returns RoleDTO Success
     * @throws ApiError
     */
    public static putRole(
key: string,
id?: number,
requestBody?: RoleDTO,
): CancelablePromise<RoleDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/Role/{key}',
            path: {
                'key': key,
            },
            query: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param id 
     * @returns RoleDTO Success
     * @throws ApiError
     */
    public static deleteRole(
key: string,
id?: number,
): CancelablePromise<RoleDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Role/{key}',
            path: {
                'key': key,
            },
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static getRole1(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Role/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @returns ClaimDTO Success
     * @throws ApiError
     */
    public static getRoleClaim(
key: number,
): CancelablePromise<Array<ClaimDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Role/{key}/claim',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns ClaimDTO Success
     * @throws ApiError
     */
    public static postRoleClaim(
key: number,
requestBody?: ClaimDTO,
): CancelablePromise<ClaimDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Role/{key}/claim',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteRoleClaim(
key: number,
requestBody?: ClaimDTO,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Role/{key}/claim',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @returns ClaimDTO Success
     * @throws ApiError
     */
    public static getRoleClaimsAll(): CancelablePromise<Array<ClaimDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Role/claims/all',
        });
    }

}
