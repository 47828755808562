import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../../contexts/CartContext';
import { StillImageConfiguration } from '../../models/StillImageConfiguration';
import { ProjectFolderService, TemplateViewDTO } from '../../openapi/requests';
import { useProjectFolderServiceGetProjectFolder } from '../../openapi/queries';
import { AuthContext } from '../../contexts/AuthContext';
import './CreateCutoutProject.scss';
import { ConfigContext } from '../../contexts/ConfigContext';
import { GenerateODataFilter } from '../../helpers/odataFunctions';
import BottomDrawer from '../layout/BottomDrawer';
import ReactGA from "react-ga4";

interface Props {
    isOpen: boolean;
    onCancel: () => void;
    onCreate: () => void;
}

const CreateCutoutProject: React.FC<Props> = ({isOpen, onCancel, onCreate}) => {
    const { featureFlags } = useContext(ConfigContext);
    const { hasPermission } = useContext(AuthContext);
    const { odataFilters } = useContext(ConfigContext);
   // const navigate = useNavigate();
    const cart = useContext(CartContext);
    const [name, setName] = useState("");
    const [reference, setReference] = useState("");
    const [folderName, setFolderName] = useState("");
    const [selectedFolder, setSelectedFolder] = useState("");
    const [saving, setSaving] = useState(false);

    const filter = useMemo(() => {
        
        let combinedFilters = [...odataFilters];
 
        if (!hasPermission('ViewAllOrders')) {
            combinedFilters.push({ name: "Owner", property: "IsOwner", type: "", values: ["true"] });
        }

        return GenerateODataFilter(combinedFilters);
    }, [hasPermission, odataFilters])

    const {data: folders} = useProjectFolderServiceGetProjectFolder({ filter });

    useEffect(() => {
        if(!isOpen){
            setName("");
            setReference("");
            setFolderName("");
            setSelectedFolder("");
        }else{
            ReactGA.event('open_dialog', {dialog: 'createcutoutproject'});
        }
    }, [isOpen]);
    
    const handleCreate = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();      

        let folderId: number | undefined = undefined;

        if(selectedFolder) {
            folderId = parseInt(selectedFolder, 10);
        } else if(folderName) {
            let folder = await ProjectFolderService.postProjectFolder({name: folderName, id: 0});
            folderId = folder.id;
        }

        await cart.createNewCutoutProject(name, reference, folderId, {});

        setSaving(false);
        onCreate();
      
    }, [cart, folderName, name, reference, selectedFolder, onCreate]);

    return (
        <BottomDrawer className='createproject' open={isOpen} onClose={onCancel} size={580}>

                <form onSubmit={handleCreate}>
                    <h1>Name your project</h1>
                    <input type='text' required placeholder='Type name...' value={name} onChange={e => setName(e.target.value)} />
                    
                    <h1>RR-code</h1>
                    <input type='text' placeholder='Type code...' value={reference} onChange={e => setReference(e.target.value)} />

                    {featureFlags.ProjectFolders && <>
                            <h1>Add to folder <span className='faded'>(Optional)</span></h1>
                            <select value={selectedFolder} onChange={e => setSelectedFolder(e.target.value)}>
                                <option key={''} className='placeholder' value=''>Select existing folder</option>
                                {folders?.value.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
                            </select>

                            <h2>Or</h2>

                            <input type='text' placeholder='Type new folder name...' value={folderName} onChange={e => setFolderName(e.target.value)} />
                        </>
                    }
                    
                    <button disabled={saving} type='submit'>Continue</button>
                </form>

        </BottomDrawer>
        )
};

export default CreateCutoutProject;