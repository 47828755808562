import { useCallback, useEffect, useState } from "react";
import { ReactComponent as Loading } from "../../../assets/icons/loading.svg";
import { ReactComponent as Spin360 } from "../../../assets/icons/spin360.svg";
import "./Turntable.scss";

export const Turntable: React.FC<{ sensitivity?: number, images: string[] }> = ({ sensitivity = 1, images }) => {
    const [angle, setAngle] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [initialX, setInitialX] = useState(0);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [imagesLoadedProgress, setImagesLoadedProgress] = useState(0);

    const handleMouseDown = useCallback((e: any) => {      
        e.preventDefault();
        setIsDragging(imagesLoaded);
        setInitialX(e.clientX);
    }, [imagesLoaded]);

    const handleMouseMove = useCallback((e: any) => {
        if (isDragging) {
            const deltaX = e.clientX - initialX;
            const angleChange = (deltaX / sensitivity) % 360;
            const newAngle = (angle + angleChange + 360) % 360;
            const roundedAngle = Math.round(newAngle / 10) * 10;

            // Ensure the angle is not 0 or 360
            setAngle(roundedAngle === 360 ? 0 : roundedAngle);
            setInitialX(e.clientX);
        }
    }, [angle, initialX, isDragging, sensitivity]);

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
    },[])

    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    });

    useEffect(() => {

        let imagesLoadedCount = 0;

        const loadImage = (imageUrl: string) => {
            return new Promise((resolve, reject) => {
                const loadImg = new Image()
                loadImg.src = imageUrl           
                loadImg.onload = () =>
                  // setTimeout(() =>            
                    resolve(imageUrl)
                  // , 2000);
                loadImg.onerror = err => reject(err)
            }).then(() => {
                imagesLoadedCount++;
                let imageLoadedProgress = Math.round((imagesLoadedCount * 100) / images.length);
                setImagesLoadedProgress(imageLoadedProgress);
            })
        }

        Promise.all(images.map(image => loadImage(image)))
            .then(() =>
                setImagesLoaded(true)
             )
            .catch(err => console.log("Failed to load images", err))

    }, [images])

    return (
        <div className="turntable" onMouseDown={handleMouseDown}>
            <div>
                {!imagesLoaded ? (
                    <div className="loading">                       
                        <span><Loading  /></span>          
                        <span>{imagesLoadedProgress}%</span>                                    
                    </div>
                ): null}                
                {imagesLoaded ? (
                    <>
                    {images.map((imageSrc, index) => (
                        <img
                            key={imageSrc}
                            src={imageSrc}
                            alt={"turntable-image-" + index}
                            style={{
                                position: "absolute",
                                opacity: index === angle / 10 ? 1 : 0,
                                inset: 0,
                                width: "100%"
                            }}
                        />
                    ))}                   
                    <div className="degress">Degress: {angle}&deg;</div>
                    <div className="spin360"><Spin360/></div>
                    </>
                ) : null
                }                
            </div>
           
        </div>
    );
}

export default Turntable;