import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from 'use-debounce';
import { Camera, MaterialSelection, ModelPlacement, ModifierSelection, Position, PropsetSelection, StillImageConfiguration, Animation } from "../models/StillImageConfiguration";
import { CartContext, OrderConfiguration } from "./CartContext";
import { ConfigurationAssets, ConfigurationService, MaterialViewDTO, ModelPackViewDTO, ModelPlacementPointDTO, ModelSurfaceDTO, ModelViewDTO, ModifierViewDTO, SceneCameraDTO, SceneCameraPlacementPointDTO, SceneModifierTargetDTO, ScenePlacementpointDTO, ScenePropsetDTO, ScenePropsetOptionDTO, SceneSurfaceDTO, TemplateViewDTO } from "../openapi/requests";
import { makeRandomId } from "../helpers/helpers";

export const moodboardColoumns = 24;

export class MoodboardPosition {
    x: number;
    y: number;
    height: number;
    width: number;

    constructor(x: number, y: number, width: number, height: number) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    public static fromPos(pos?: Position): MoodboardPosition | undefined {
        if (pos === undefined) {
            return undefined;
        }

        return new MoodboardPosition(
            Math.round(pos.x1 * moodboardColoumns),
            Math.round(pos.y1 * moodboardColoumns),
            Math.round((pos.x2 - pos.x1) * moodboardColoumns),
            Math.round((pos.y2 - pos.y1) * moodboardColoumns),
        );
    }

    public toPos(): Position {
        return {
            x1: this.x / moodboardColoumns,
            x2: (this.x + this.width) / moodboardColoumns,
            y1: this.y / moodboardColoumns,
            y2: (this.y + this.height) / moodboardColoumns
        }
    }

    public contains(position: MoodboardPosition): boolean {
        if (
            (this.x < (position.x + position.width) && (this.x + this.width) > position.x) &&
            (this.y < (position.y + position.height) && (this.y + this.height) > position.y)
        ) {
            return true;
        }
        else {
            return false;
        }
    }
}

export interface Configuration {
    surfaces: SurfaceConfiguration[];
    propsets: PropsetConfiguration[];
    models: ModelConfiguration[];
    modifiers: ModifierConfiguration[];
    moodboard: {
        backgroundColor: string;
        previewPosition: MoodboardPosition;
    },
    cameras: ({ camera: SceneCameraDTO } & Camera)[];
    animations: Animation[];
}

export interface ItemConfigurationValue<AssetT, PlacementT> {
    placement: PlacementT;
    values: AssetT[];
    parent: ModelViewDTO | ModelPackViewDTO | undefined;
}

export class ItemConfiguration<AssetT, PlacementT extends { label: string, isRequired?: boolean }> {
    protected _values: ItemConfigurationValue<AssetT, PlacementT>[];
    protected _uniqueValues: AssetT[];
    moodboardPosition?: MoodboardPosition;
    id: string;
    path: string[];
    hasChanged: boolean;

    get label(): string {
        return this._values[0].placement.label;
    }

    get isRequired(): boolean {
        return this._values[0].placement.isRequired ?? true;
    }

    get value(): AssetT | undefined {
        if (this._uniqueValues.length) {
            return this._uniqueValues[0];
        }
        return undefined;
    }

    get values(): ItemConfigurationValue<AssetT, PlacementT>[] {
        return this._values;
    }

    get uniqueValues(): AssetT[] {
        return this._uniqueValues;
    }

    get placement(): PlacementT {
        return this.values[0].placement;
    }

    get placements(): PlacementT[] {
        return this.values.map(e => e.placement);
    }

    constructor(placements: ItemConfigurationValue<AssetT, PlacementT>[]) {
        if (placements.length === 0) {
            throw new Error("placements must have at least 1 item");
        }

        this._values = placements;
        var items = placements.flatMap(e => e.values);
        this._uniqueValues = items.filter((value, index, array) => array.indexOf(value) === index);
        this.hasChanged = false;
        this.id = "";
        this.path = [];
    }

    clearValue(): this {
        let clone = this.clone() as this;

        clone._values = this._values.map(e => ({placement: e.placement, values: [], parent: e.parent}));
        clone._uniqueValues = [];
        clone.hasChanged = true;

        return clone;
    }

    setValue(placements: ItemConfigurationValue<AssetT, PlacementT>[]): this {
        if (placements.length === 0) {
            throw new Error("placements must have at least 1 item");
        }

        let clone = this.clone() as this;
        var items = placements.flatMap(e => e.values);

        clone._values = placements;
        clone._uniqueValues = items.filter((value, index, array) => array.indexOf(value) === index);
        clone.hasChanged = true;

        return clone;
    }

    clone(): any {
        throw new Error("not implemented");
    }

    protected static findModel = (assets: ConfigurationAssets, nameOrID?: string | number): ModelViewDTO | undefined => {
        if (nameOrID === undefined) {
            return undefined;
        }

        if (typeof nameOrID === 'number') {
            return assets.models.find(e => e.id === nameOrID);
        }
        return assets.models.find(e => e.name === nameOrID);
    }

    protected static findModelpack = (assets: ConfigurationAssets, nameOrID?: string | number): ModelPackViewDTO | undefined => {
        if (nameOrID === undefined) {
            return undefined;
        }

        if (typeof nameOrID === 'number') {
            return assets.modelpacks.find(e => e.id === nameOrID);
        }
        return assets.modelpacks.find(e => e.name === nameOrID);
    }

    protected static findMaterial = (assets: ConfigurationAssets, nameOrID?: string | number): MaterialViewDTO | undefined => {
        if (nameOrID === undefined) {
            return undefined;
        }

        if (typeof nameOrID === 'number') {
            return assets.materials.find(e => e.id === nameOrID);
        }
        return assets.materials.find(e => e.name === nameOrID);
    }

    protected static findModifier = (assets: ConfigurationAssets, nameOrID?: string | number): ModifierViewDTO | undefined => {
        if (nameOrID === undefined) {
            return undefined;
        }

        if (typeof nameOrID === 'number') {
            return assets.modifiers.find(e => e.id === nameOrID);
        }
        return assets.modifiers.find(e => e.name === nameOrID);
    }
}

export class SurfaceConfiguration extends ItemConfiguration<MaterialViewDTO, SceneSurfaceDTO | ModelSurfaceDTO> {

    static deserialize(placements: {placement: SceneSurfaceDTO | ModelSurfaceDTO, parent: ModelViewDTO | ModelPackViewDTO | undefined}[],
        selection: MaterialSelection | undefined,
        defaultSelection: MaterialSelection | undefined,
        assets: ConfigurationAssets): SurfaceConfiguration {

        if (placements.length === 0) {
            throw new Error("placements must have at least 1 item");
        }

        let values: ItemConfigurationValue<MaterialViewDTO, SceneSurfaceDTO | ModelSurfaceDTO>[] = [];

        if (selection?.Values) {
            placements.forEach(p => {
                var placementSelection = selection!.Values!.filter(e => e.name === undefined || e.name === p.placement.name);
                var items = placementSelection.map(e => this.findMaterial(assets, e.value))
                values.push({ placement: p.placement, parent: p.parent, values: items.filter(e => e !== undefined) as MaterialViewDTO[] });
            });
        } else if (selection) {
            let value = this.findMaterial(assets, selection?.Value);
            values = placements.map(p => ({ placement: p.placement, parent: p.parent, values: value ? [value] : [] }));
        } else {
            values = placements.map(p => ({ placement: p.placement, parent: p.parent, values: [] }));
        }

        let config = new SurfaceConfiguration(values);

        config.moodboardPosition = MoodboardPosition.fromPos(selection?.MoodboardPosition);
        config.hasChanged = selection?.Value !== defaultSelection?.Value;

        return config;
    }

    serialize = (): MaterialSelection => {
        return {
            Name: this.placement.name,
            Value: this.value!.name,
            Values: this.values?.flatMap(v => v.values.map(e => ({
                name: v.placement.name,
                value: e.name,
                filenamePrefix: e.metadata.find(m => m.name.toLowerCase() === 'ean')?.value,
                parentName: v.parent?.name
            }))),
            validParents: [...new Set(this.values.map(e => e.parent?.name).filter(e => e !== undefined))] as string[],
            MoodboardPosition: this.moodboardPosition?.toPos(),
            Id: this.id,
        }
    }

    clone(): SurfaceConfiguration {
        let clone = new SurfaceConfiguration(this.values);
        clone.moodboardPosition = this.moodboardPosition;
        clone.id = this.id;
        clone.path = this.path;
        clone.hasChanged = this.hasChanged;
        return clone;
    }
}

export class ModelConfiguration extends ItemConfiguration<ModelViewDTO | ModelPackViewDTO, ScenePlacementpointDTO | ModelPlacementPointDTO>{
    surfaces: SurfaceConfiguration[];
    models: ModelConfiguration[];
    features: FeatureConfiguration[];

    constructor(placements: ItemConfigurationValue<ModelViewDTO | ModelPackViewDTO, ScenePlacementpointDTO | ModelPlacementPointDTO>[]) {
        super(placements);

        this.surfaces = [];
        this.models = [];
        this.features = [];
    }

    static deserialize(placements: {placement: ScenePlacementpointDTO | ModelPlacementPointDTO, parent: ModelViewDTO | ModelPackViewDTO | undefined}[],
        selection: ModelPlacement | undefined,
        defaultSelection: ModelPlacement | undefined,
        assets: ConfigurationAssets): ModelConfiguration {

        if (placements.length === 0) {
            throw new Error("placements must have at least 1 item");
        }

        let values: ItemConfigurationValue<ModelViewDTO | ModelPackViewDTO, ScenePlacementpointDTO | ModelPlacementPointDTO>[] = [];

        let placementPointsByLabel: { [key: string]: {placement: ModelPlacementPointDTO, parent: ModelViewDTO | ModelPackViewDTO}[] } = {};
        let surfacesByLabel: { [key: string]: {placement: ModelSurfaceDTO, parent: ModelViewDTO | ModelPackViewDTO}[] } = {};
        const modelsAdded: number[] = [];

        if (placements[0].placement.useModelpack) {
            if (selection?.Values) {

                placements.forEach(p => {
                    var placementSelection = selection!.Values!.filter(e => e.name === undefined || e.name === p.placement.name);
                    var items = placementSelection.map(e => this.findModelpack(assets, e.value)).filter(e => e !== undefined);

                    items.forEach(item => {
                        if (item) {
                            let modelId = item.models.find(e => e.isMasterModel)?.modelId;

                            if (!modelId && item.models.length > 0) {
                                modelId = item.models[0].modelId;
                            }

                            let model = this.findModel(assets, modelId);

                            if (model && !modelsAdded.includes(model.id)) {
                                model.placementpoints.forEach(p => {
                                    if (placementPointsByLabel[p.label]) {
                                        placementPointsByLabel[p.label].push({placement: p, parent: model!});
                                    } else {
                                        placementPointsByLabel[p.label] = [{placement: p, parent: model!}];
                                    }
                                });
                                model.surfaces.forEach(s => {
                                    if (surfacesByLabel[s.label]) {
                                        surfacesByLabel[s.label].push({placement: s, parent: model!});
                                    } else {
                                        surfacesByLabel[s.label] = [{placement: s, parent: model!}];
                                    }
                                });
                                modelsAdded.push(model.id);
                            }
                        }
                    });

                    values.push({ placement: p.placement, parent: p.parent, values: items as ModelPackViewDTO[] });
                });
            } else if (selection) {
                let value = this.findModelpack(assets, selection?.Value);
                if (value) {
                    let modelId = value.models.find(e => e.isMasterModel)?.modelId;

                    if (!modelId && value.models.length > 0) {
                        modelId = value.models[0].modelId;
                    }

                    let model = this.findModel(assets, modelId);

                    if (model && !modelsAdded.includes(model.id)) {
                        model.placementpoints.forEach(p => {
                            if (placementPointsByLabel[p.label]) {
                                placementPointsByLabel[p.label].push({placement: p, parent: model!});
                            } else {
                                placementPointsByLabel[p.label] = [{placement: p, parent: model!}];
                            }
                        });
                        model.surfaces.forEach(s => {
                            if (surfacesByLabel[s.label]) {
                                surfacesByLabel[s.label].push({placement: s, parent: model!});
                            } else {
                                surfacesByLabel[s.label] = [{placement: s, parent: model!}];
                            }
                        });
                        modelsAdded.push(model.id);
                    }
                }
                values = placements.map(e => ({ placement: e.placement, parent: e.parent, values: value ? [value] : [] }));
            } else {
                values = placements.map(e => ({ placement: e.placement, parent: e.parent, values: [] }));
            }
        } else {
            if (selection?.Values) {

                placements.forEach(p => {
                    var placementSelection = selection!.Values!.filter(e => e.name === undefined || e.name === p.placement.name);
                    var items = placementSelection.map(e => this.findModel(assets, e.value)).filter(e => e !== undefined);

                    items.forEach(item => {
                        if (item) {
                            if (!modelsAdded.includes(item.id)) {
                                item.placementpoints.forEach(p => {
                                    if (placementPointsByLabel[p.label]) {
                                        placementPointsByLabel[p.label].push({placement: p, parent: item});
                                    } else {
                                        placementPointsByLabel[p.label] = [{placement: p, parent: item}];
                                    }
                                });
                                item.surfaces.forEach(s => {
                                    if (surfacesByLabel[s.label]) {
                                        surfacesByLabel[s.label].push({placement: s, parent: item});
                                    } else {
                                        surfacesByLabel[s.label] = [{placement: s, parent: item}];
                                    }
                                });
                                modelsAdded.push(item.id);
                            }
                        }
                    });

                    values.push({ placement: p.placement, parent: p.parent, values: items as ModelViewDTO[] });
                });
            } else if (selection) {
                let value = this.findModel(assets, selection?.Value);
                if (value) {
                    value.placementpoints.forEach(p => {
                        if (placementPointsByLabel[p.label]) {
                            placementPointsByLabel[p.label].push({placement: p, parent: value!});
                        } else {
                            placementPointsByLabel[p.label] = [{placement: p, parent: value!}];
                        }
                    });
                    value.surfaces.forEach(s => {
                        if (surfacesByLabel[s.label]) {
                            surfacesByLabel[s.label].push({placement: s, parent: value!});
                        } else {
                            surfacesByLabel[s.label] = [{placement: s, parent: value!}];
                        }
                    });
                }
                values = placements.map(e => ({ placement: e.placement, parent: e.parent, values: value ? [value] : [] }));
            } else {
                values = placements.map(e => ({ placement: e.placement, parent: e.parent, values: [] }));
            }
        }

        let config = new ModelConfiguration(values);

        config.moodboardPosition = MoodboardPosition.fromPos(selection?.MoodboardPosition);
        config.hasChanged = selection?.Value !== defaultSelection?.Value;


        for (const key in surfacesByLabel) {
            if (Object.prototype.hasOwnProperty.call(surfacesByLabel, key)) {
                const surfaces = surfacesByLabel[key];
                config.surfaces.push(SurfaceConfiguration.deserialize(
                    surfaces, 
                    selection?.SurfaceSelections.find(e => surfaces.some(s => s.placement.name === e.Name)), 
                    defaultSelection?.SurfaceSelections.find(e => surfaces.some(s => s.placement.name === e.Name)), 
                    assets))
            }
        }

        for (const key in placementPointsByLabel) {
            if (Object.prototype.hasOwnProperty.call(placementPointsByLabel, key)) {
                const points = placementPointsByLabel[key];
                config.models.push(ModelConfiguration.deserialize(
                    points, 
                    selection?.ModelSelections.find(e => points.some(s => s.placement.name === e.Name)), 
                    defaultSelection?.ModelSelections.find(e => points.some(s => s.placement.name === e.Name)), 
                    assets))
            }
        }

        return config;
    }

    serialize = (): ModelPlacement => {
        return {
            FeatureSelections: this.features.map(e => ({ Name: e.name, Value: e.value })),
            ModelSelections: this.models.filter(e => e.value !== undefined).map(e => e.serialize()),
            Name: this.placement.name,
            SurfaceSelections: this.surfaces.filter(e => e.value !== undefined).map(e => e.serialize()),
            Value: this.value!.name,
            Values: this.values?.flatMap(v => v.values.map(e => ({
                name: v.placement.name,
                value: e.name,
                filenamePrefix: e.metadata.find(m => m.name.toLowerCase() === 'ean')?.value,
                parentName: v.parent?.name
            }))),
            validParents: [...new Set(this.values.map(e => e.parent?.name).filter(e => e !== undefined))] as string[],
            MoodboardPosition: this.moodboardPosition?.toPos(),
            Id: this.id,
        }
    }

    clearValue(): this {
        let clone = Object.create(this) as this;

        clone._values = this._values.map(e => ({placement: e.placement, values: [], parent: e.parent}));
        clone._uniqueValues = [];
        clone.hasChanged = true;
        clone.surfaces = [];
        clone.models = [];

        return clone;
    }

    clone(): ModelConfiguration {
        let clone = new ModelConfiguration(this.values);
        clone.surfaces = this.surfaces;
        clone.models = this.models;
        clone.moodboardPosition = this.moodboardPosition;
        clone.id = this.id;
        clone.path = this.path;
        clone.hasChanged = this.hasChanged;
        return clone;
    }
}

export class ModifierConfiguration extends ItemConfiguration<ModifierViewDTO, SceneModifierTargetDTO> {

    static deserialize(placements: SceneModifierTargetDTO[],
        selection: ModifierSelection | undefined,
        defaultSelection: ModifierSelection | undefined,
        assets: ConfigurationAssets): ModifierConfiguration {

        if (placements.length === 0) {
            throw new Error("placements must have at least 1 item");
        }

        let values: ItemConfigurationValue<ModifierViewDTO, SceneModifierTargetDTO>[] = [];

        if (selection?.Values) {
            placements.forEach(p => {
                var placementSelection = selection!.Values!.filter(e => e.name === undefined || e.name === p.name);
                var items = placementSelection.map(e => this.findModifier(assets, e.value))
                values.push({ placement: p, parent: undefined, values: items.filter(e => e !== undefined) as ModifierViewDTO[] });
            });
        } else if (selection) {
            let value = this.findModifier(assets, selection?.Value);
            values = placements.map(placement => ({ placement, parent: undefined, values: value ? [value] : [] }));
        } else {
            values = placements.map(placement => ({ placement, parent: undefined, values: [] }));
        }

        let config = new ModifierConfiguration(values);

        config.moodboardPosition = MoodboardPosition.fromPos(selection?.MoodboardPosition);
        config.hasChanged = selection?.Value !== defaultSelection?.Value;

        return config;
    }

    serialize = (): ModifierSelection => {
        return {
            Name: this.placement.name,
            Value: this.value!.name,
            Values: this.values?.flatMap(v => v.values.map(e => ({
                name: v.placement.name,
                value: e.name,
                filenamePrefix: e.metadata.find(m => m.name.toLowerCase() === 'ean')?.value,
                parentName: v.parent?.name
            }))),
            MoodboardPosition: this.moodboardPosition?.toPos(),
            Id: this.id,
        }
    }

    clone(): ModifierConfiguration {
        let clone = new ModifierConfiguration(this.values);
        clone.moodboardPosition = this.moodboardPosition;
        clone.id = this.id;
        clone.path = this.path;
        clone.hasChanged = this.hasChanged;
        return clone;
    }
}

export class PropsetConfiguration extends ItemConfiguration<ScenePropsetOptionDTO, ScenePropsetDTO> {

    static deserialize(placements: ScenePropsetDTO[],
        selection: PropsetSelection | undefined,
        defaultSelection: PropsetSelection | undefined): PropsetConfiguration {

        if (placements.length === 0) {
            throw new Error("placements must have at least 1 item");
        }

        let values: ItemConfigurationValue<ScenePropsetOptionDTO, ScenePropsetDTO>[] = [];

        if (selection?.Values) {
            placements.forEach(p => {
                var placementSelection = selection!.Values!.filter(e => e.name === undefined || e.name === p.name);
                var items = placementSelection.map(e => p.options.find(o => o.name === e.value));
                values.push({ placement: p, parent: undefined, values: items.filter(e => e !== undefined) as ScenePropsetOptionDTO[] });
            });
        } else if (selection) {
            var value = placements[0].options.find(e => e.name === selection!.Value);
            values = placements.map(placement => ({ placement, parent: undefined, values: value ? [value] : [] }));
        } else {
            values = placements.map(placement => ({ placement, parent: undefined, values: [] }));
        }

        let config = new PropsetConfiguration(values);

        config.moodboardPosition = MoodboardPosition.fromPos(selection?.MoodboardPosition);
        config.hasChanged = selection?.Value !== defaultSelection?.Value;

        return config;
    }

    serialize = (): PropsetSelection => {
        return {
            Name: this.placement.name,
            Value: this.value!.name,
            MoodboardPosition: this.moodboardPosition?.toPos(),
            Id: this.id,
        }
    }

    clone(): PropsetConfiguration {
        let clone = new PropsetConfiguration(this.values);
        clone.moodboardPosition = this.moodboardPosition;
        clone.id = this.id;
        clone.path = this.path;
        clone.hasChanged = this.hasChanged;
        return clone;
    }
}

export interface FeatureConfiguration {
    name: string;
    value: string;
}

type Margins = {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

type BasePoint = {
    label: string;
    x?: number;
    y?: number;
    isRequired: boolean;
    view?: Margins;
    offbrandProduct: boolean;
}

type ModelPoint = BasePoint & {
    type: "model";
    configuration: ModelConfiguration;
}
type MaterialPoint = BasePoint & {
    type: "material";
    configuration: SurfaceConfiguration;
}
type ModifierPoint = BasePoint & {
    type: "modifier";
    configuration: ModifierConfiguration;
}
type PropsetPoint = BasePoint & {
    type: "propset";
    configuration: PropsetConfiguration;
}
export type Point = ModelPoint | MaterialPoint | ModifierPoint | PropsetPoint;

export type CameraView = SceneCameraDTO & {
    latestRendering: string | undefined;
}

export interface SceneDesignerContextState {
    loading: boolean;
    template: TemplateViewDTO | undefined;
    selectedCamera: CameraView | undefined;
    setSelectedCamera: (cam: SceneCameraDTO | undefined) => void;
    configuration: Configuration;
    setConfiguration: (config: Configuration, dontAddToHistory?: boolean) => void;
    undo: () => void;
    redo: () => void;
    canUndo: boolean;
    canRedo: boolean;
    reset: () => void;
    products: Point[];
    batchProducts: Point[];
    selectedBrand: string | undefined;
    setSelectedBrand: (brand: string | undefined) => void;
};

export const emptyConfiguration: Configuration = {
    models: [],
    modifiers: [],
    propsets: [],
    surfaces: [],
    moodboard: {
        backgroundColor: '#fff',
        previewPosition: new MoodboardPosition(0, 0, 9, 9)
    },
    cameras: [],
    animations: [],
}

const contextDefaultValues: SceneDesignerContextState = {
    loading: false,
    template: undefined,
    selectedCamera: undefined,
    setSelectedCamera: () => { },
    configuration: emptyConfiguration,
    setConfiguration: () => { },
    undo: () => { },
    redo: () => { },
    canUndo: false,
    canRedo: false,
    reset: () => { },
    products: [],
    batchProducts: [],
    selectedBrand: undefined,
    setSelectedBrand: () => {},
};

export const SceneDesignerContext = createContext<SceneDesignerContextState>(
    contextDefaultValues
);

export const SceneDesignerProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const { currentProject, currentOrder, updateCurrentOrder, newestDelivery } = useContext(CartContext);

    const [loading, setLoading] = useState(false);
    const [template, setTemplate] = useState<TemplateViewDTO | undefined>(undefined);
    const [selectedCameraInternal, setSelectedCamera] = useState<SceneCameraDTO | undefined>(undefined);
    const [configuration, internalSetConfiguration] = useState(emptyConfiguration);
    const [selectedBrand, setSelectedBrand] = useState<string | undefined>(undefined);

    const [undoHistory, setUndoHistory] = useState<Configuration[]>([]);
    const [redoHistory, setRedoHistory] = useState<Configuration[]>([]);

    const canUndo = undoHistory.length > 0;
    const canRedo = redoHistory.length > 0;

    const selectedCamera = useMemo(() => {
        if(selectedCameraInternal){
            let latestRendering: string | undefined = undefined;

            for (let i = 0; i < newestDelivery.length; i++) {
                const orderline = newestDelivery[i];
                
                const config = JSON.parse(orderline.configurationSpec) as StillImageConfiguration;

                if(config.camera === selectedCameraInternal.cameraName){
                    latestRendering = orderline.files[0].url;
                }
            }

            return {latestRendering, ...selectedCameraInternal};
        }

        return undefined;
    }, [newestDelivery, selectedCameraInternal]);

    const products = useMemo(() => {
        const result: Point[] = [];

        const isOffbrand = (asset: ModelConfiguration | SurfaceConfiguration | ModifierConfiguration) => {
            if(asset.value === undefined){
                return false;
            }

            if(selectedBrand){
                return !asset.uniqueValues.some(e => 
                    !e.metadata.some(m => m.name === 'brand') ||
                    e.metadata.some(m => m.name === 'brand' && m.value.toLowerCase() === selectedBrand.toLowerCase() ))
            }
            return false;
        }

        const flatten = (model: ModelConfiguration, position: SceneCameraPlacementPointDTO | undefined) => {
            for (let i = 0; i < model.surfaces.length; i++) {
                result.push({
                    configuration: model.surfaces[i],
                    isRequired: true,
                    label: model.surfaces[i].label,
                    type: 'material',
                    offbrandProduct: isOffbrand(model.surfaces[i]),
                    view: position && {
                        left: Math.min(position.rectX1, position.rectX2) * 100,
                        right: 100 - (Math.max(position.rectX1, position.rectX2) * 100),
                        top: Math.min(position.rectY1, position.rectY2) * 100,
                        bottom: 100 - (Math.max(position.rectY1, position.rectY2) * 100),
                    }
                });
            }
            for (let i = 0; i < model.models.length; i++) {
                result.push({
                    configuration: model.models[i],
                    isRequired: model.models[i].values[0].placement.isRequired,
                    label: model.models[i].label,
                    offbrandProduct: isOffbrand(model.models[i]),
                    type: 'model',
                    view: position && {
                        left: Math.min(position.rectX1, position.rectX2) * 100,
                        right: 100 - (Math.max(position.rectX1, position.rectX2) * 100),
                        top: Math.min(position.rectY1, position.rectY2) * 100,
                        bottom: 100 - (Math.max(position.rectY1, position.rectY2) * 100),
                    }
                });
                flatten(model.models[i], position);
            }
        }

        for (let i = 0; i < configuration.surfaces.length; i++) {

            let point: Point = {
                configuration: configuration.surfaces[i],
                isRequired: true,
                label: configuration.surfaces[i].label,
                offbrandProduct: isOffbrand(configuration.surfaces[i]),
                type: 'material'
            };

            if (selectedCamera && template) {
                const surface = point.configuration.placement as SceneSurfaceDTO;
                const position = selectedCamera.surfacePoints.find(e => e.surface === surface.id);

                if (position) {
                    point.view = {
                        left: Math.min(position.rectX1, position.rectX2) * 100,
                        right: 100 - (Math.max(position.rectX1, position.rectX2) * 100),
                        top: Math.min(position.rectY1, position.rectY2) * 100,
                        bottom: 100 - (Math.max(position.rectY1, position.rectY2) * 100),
                    }
                    point.x = position.posX;
                    point.y = position.posY;
                }
            }

            result.push(point);
        }

        for (let i = 0; i < configuration.modifiers.length; i++) {
            let point: Point = {
                configuration: configuration.modifiers[i],
                isRequired: configuration.modifiers[i].isRequired,
                label: configuration.modifiers[i].label,
                offbrandProduct: isOffbrand(configuration.modifiers[i]),
                type: 'modifier'
            };

            if (selectedCamera && template) {
                const modifier = point.configuration.placement;
                const position = selectedCamera.modifierPoints.find(e => e.modifierTarget === modifier.id);

                if (position) {
                    point.view = {
                        left: Math.min(position.rectX1, position.rectX2) * 100,
                        right: 100 - (Math.max(position.rectX1, position.rectX2) * 100),
                        top: Math.min(position.rectY1, position.rectY2) * 100,
                        bottom: 100 - (Math.max(position.rectY1, position.rectY2) * 100),
                    }
                    point.x = position.posX;
                    point.y = position.posY;
                }
            }

            result.push(point);
        }

        for (let i = 0; i < configuration.propsets.length; i++) {
            if (configuration.propsets[i].label.toLowerCase() === "lighting") {
                continue;
            }

            let point: Point = {
                configuration: configuration.propsets[i],
                isRequired: configuration.propsets[i].isRequired,
                label: configuration.propsets[i].label,
                offbrandProduct: false,
                type: 'propset'
            };

            if (selectedCamera && template) {
                const propset = point.configuration.placement;
                const position = selectedCamera.propsetPoints.find(e => e.propset === propset.id);

                if (position) {
                    point.view = {
                        left: Math.min(position.rectX1, position.rectX2) * 100,
                        right: 100 - (Math.max(position.rectX1, position.rectX2) * 100),
                        top: Math.min(position.rectY1, position.rectY2) * 100,
                        bottom: 100 - (Math.max(position.rectY1, position.rectY2) * 100),
                    }
                    point.x = position.posX;
                    point.y = position.posY;
                }
            }

            result.push(point);
        }

        for (let i = 0; i < configuration.models.length; i++) {
            let point: Point = {
                configuration: configuration.models[i],
                isRequired: configuration.models[i].isRequired,
                label: configuration.models[i].label,
                offbrandProduct: isOffbrand(configuration.models[i]),
                type: 'model'
            };

            let position: SceneCameraPlacementPointDTO | undefined = undefined;

            if (selectedCamera && template) {
                const model = point.configuration.placement;
                position = selectedCamera.placementPoints.find(e => e.placementPoint === model.id);

                if (position) {
                    point.view = {
                        left: Math.min(position.rectX1, position.rectX2) * 100,
                        right: 100 - (Math.max(position.rectX1, position.rectX2) * 100),
                        top: Math.min(position.rectY1, position.rectY2) * 100,
                        bottom: 100 - (Math.max(position.rectY1, position.rectY2) * 100),
                    }
                    point.x = position.posX;
                    point.y = position.posY;
                }
            }

            result.push(point);


            flatten(configuration.models[i], position);
        }

        return result;
    }, [selectedBrand, configuration, selectedCamera, template]);

    const batchProducts = useMemo(() => {
        return products.filter(e => e.configuration.uniqueValues.length > 1);
    }, [products]);

    useEffect(() => {
        setTemplate(undefined);
        setSelectedCamera(undefined);
        internalSetConfiguration(emptyConfiguration);

        if (currentOrder?.configurations) {

            for (let index = 0; index < currentOrder.configurations.length; index++) {
                const element = currentOrder.configurations[index];

                if (element.template.editorModule === 'generic_3d_scene') {
                    setTemplate(element.template);
                    setUndoHistory([]);
                    setRedoHistory([]);

                    if (element.configuration) {
                        load(element);
                    }

                    break;
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProject, currentOrder?.order.id]);

    const ensureMoodboardPositions = useCallback((config: Configuration) => {

        const occupiedPositions: MoodboardPosition[] = [config.moodboard.previewPosition];
        const items: (ModelConfiguration | SurfaceConfiguration | ModifierConfiguration | PropsetConfiguration)[] = [];

        const isPositionOccupied = (position: MoodboardPosition): boolean => {
            return occupiedPositions.some(e => e.contains(position));
        }

        const getNextFreePosition = (): MoodboardPosition => {
            for (let y = 0; y < 50; y++) {
                for (let x = 0; x < moodboardColoumns - 2; x++) {
                    const pos = new MoodboardPosition(x, y, 3, 3);

                    if (!isPositionOccupied(pos)) {
                        return pos;
                    }
                }
            }

            return new MoodboardPosition(0, 0, 3, 3);
        }

        const flatten = (model: ModelConfiguration) => {
            let index = 1;


            const surfaces = [...model.surfaces].sort((a, b) => (parseInt(a.id) || 999) - (parseInt(b.id) || 999));

            for (let i = 0; i < surfaces.length; i++) {

                surfaces[i].id = model.id + "." + index.toString();
                surfaces[i].path = [...model.path, model.id];
                index++;

                items.push(surfaces[i]);
            }

            const models = [...model.models].sort((a, b) => (parseInt(a.id) || 999) - (parseInt(b.id) || 999));

            for (let i = 0; i < models.length; i++) {

                models[i].id = model.id + "." + index.toString();
                models[i].path = [...model.path, model.id];
                index++;

                items.push(models[i]);
                flatten(models[i]);
            }
        }

        let index = 1;

        const surfaces = [...config.surfaces].sort((a, b) => (parseInt(a.id) || 999) - (parseInt(b.id) || 999));

        for (let i = 0; i < surfaces.length; i++) {

            surfaces[i].id = index.toString();
            surfaces[i].path = [];
            index++;

            items.push(surfaces[i]);
        }

        const modifiers = [...config.modifiers].sort((a, b) => (parseInt(a.id) || 999) - (parseInt(b.id) || 999));

        for (let i = 0; i < modifiers.length; i++) {

            modifiers[i].id = index.toString();
            modifiers[i].path = [];
            index++;

            items.push(modifiers[i]);
        }

        const propsets = [...config.propsets].sort((a, b) => (parseInt(a.id) || 999) - (parseInt(b.id) || 999));

        for (let i = 0; i < propsets.length; i++) {

            if (propsets[i].label.toLowerCase() === "lighting") {
                continue;
            }

            propsets[i].id = index.toString();
            propsets[i].path = [];
            index++;

            items.push(propsets[i]);
        }

        const models = [...config.models].sort((a, b) => (parseInt(a.id) || 999) - (parseInt(b.id) || 999));

        for (let i = 0; i < models.length; i++) {

            models[i].id = index.toString();
            models[i].path = [];

            items.push(models[i]);

            flatten(models[i]);
            index++;
        }

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (item.moodboardPosition) {
                if (isPositionOccupied(item.moodboardPosition) && false) {
                    item.moodboardPosition = undefined;
                } else {
                    occupiedPositions.push(item.moodboardPosition);
                }
            }
        }

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (!item.moodboardPosition) {
                item.moodboardPosition = getNextFreePosition();
                occupiedPositions.push(item.moodboardPosition);
            }
        }

    }, []);

    const save = useCallback(() => {

        const config: StillImageConfiguration = {
            camera: selectedCamera!.cameraName,
            PropsetSelections: configuration.propsets.filter(e => e.value !== undefined).map(e => e.serialize()),
            ModifierSelections: configuration.modifiers.filter(e => e.value !== undefined).map(e => e.serialize()),
            SurfaceSelections: configuration.surfaces.filter(e => e.value !== undefined).map(e => e.serialize()),
            ModelSelections: configuration.models.filter(e => e.value !== undefined).map(e => e.serialize()),
            Moodboard: {
                BackgroundColor: configuration.moodboard.backgroundColor,
                PreviewPosition: configuration.moodboard.previewPosition.toPos(),
            },
            Cameras: configuration.cameras.map(e => ({
                Name: e.camera.cameraName,
                Variants: e.Variants
            })),
            Animations: configuration.animations.map(e => ({
                Name: e.Name,
                Shots: e.Shots,
                Variants: e.Variants,
                Title: e.Title
            })),
        }

        updateCurrentOrder({ configurations: currentOrder?.configurations.map(e => (e.template.id === template?.id) ? { ...e, configuration: config } : e) });


    }, [configuration, selectedCamera, updateCurrentOrder, template, currentOrder]);

    const debouncedSave = useDebouncedCallback(save, 1000);

    const mapConfiguration = useCallback(async (order: OrderConfiguration): Promise<Configuration> => {


        let defaultConfig: StillImageConfiguration = {
            camera: '',
            ModelSelections: [],
            ModifierSelections: [],
            PropsetSelections: [],
            SurfaceSelections: [],
        };

        if (order.template.scene.defaultConfigurationSpec && order.template.scene.defaultConfigurationSpec !== "") {
            defaultConfig = JSON.parse(order.template.scene.defaultConfigurationSpec);
        }

        var configItems = await ConfigurationService.postConfigurationItems(order.template.clientId, JSON.stringify(JSON.stringify(order.configuration)));

        const configuration: Configuration = {
            surfaces: order.template.scene.surfaces.map(surface => SurfaceConfiguration.deserialize(
                [{placement: surface, parent: undefined}], 
                order.configuration.SurfaceSelections.find(e => e.Name === surface.name), 
                defaultConfig.SurfaceSelections.find(e => e.Name === surface.name), 
                configItems)),
            propsets: order.template.scene.propsets.map(propset => PropsetConfiguration.deserialize(
                [propset], 
                order.configuration.PropsetSelections.find(e => e.Name === propset.name),
                defaultConfig.PropsetSelections.find(e => e.Name === propset.name))),
            modifiers: order.template.scene.modifierTargets.map(modifiertarget => ModifierConfiguration.deserialize(
                [modifiertarget], 
                order.configuration.ModifierSelections.find(e => e.Name === modifiertarget.name), 
                defaultConfig.ModifierSelections.find(e => e.Name === modifiertarget.name), 
                configItems)),
            models: order.template.scene.placementpoints.map(placement => ModelConfiguration.deserialize(
                [{placement, parent: undefined}], 
                order.configuration.ModelSelections.find(e => e.Name === placement.name), 
                defaultConfig.ModelSelections.find(e => e.Name === placement.name), 
                configItems)),
            moodboard: {
                backgroundColor: order.configuration.Moodboard?.BackgroundColor ?? emptyConfiguration.moodboard.backgroundColor,
                previewPosition: MoodboardPosition.fromPos(order.configuration.Moodboard?.PreviewPosition ?? { x1: 0, x2: 0.375, y1: 0, y2: 0.375 })!,
            },
            cameras: order.configuration.Cameras ? order.configuration.Cameras.map(e => ({
                camera: order.template.scene.cameras.find(c => c.cameraName === e.Name)!,
                Name: e.Name,
                Variants: e.Variants,
            })).filter(e => e.camera !== undefined) : [],
            animations: order.configuration.Animations ? order.configuration.Animations.map(e => ({
                animation: order.template.scene.animations.find(c => c.name === e.Name)!,
                Name: e.Name || makeRandomId(8),
                Variants: e.Variants || [],
                Shots: e.Shots,
                Title: e.Title || '',
            })) : [],
        }

        return configuration;
    }, []);

    const load = useCallback((order: OrderConfiguration) => {
        setLoading(true);

        const _load = async () => {
            internalSetConfiguration(emptyConfiguration);


            let configuration = await mapConfiguration(order);


            var cam = configuration.cameras[0]?.camera;
            if (!cam) {
                cam = order.template.scene.cameras[0];
            }
            setSelectedCamera(cam);

            ensureMoodboardPositions(configuration);

            internalSetConfiguration(configuration);
        }

        _load().then(() => {
            setUndoHistory([]);
            setRedoHistory([]);
            setLoading(false);
        });

    }, [mapConfiguration, ensureMoodboardPositions]);

    const setConfiguration = useCallback((config: Configuration, dontAddToHistory: boolean = false) => {
        ensureMoodboardPositions(config);

        if (!dontAddToHistory) {
            const history = [...undoHistory, configuration].slice(-50);
            setUndoHistory(history);
            setRedoHistory([]);
        }

        internalSetConfiguration(config);

        debouncedSave();

    }, [configuration, debouncedSave, ensureMoodboardPositions, undoHistory]);

    const reset = useCallback(async () => {
        if (template) {
            const history = [...undoHistory, configuration].slice(-50);

            let config: StillImageConfiguration = {
                camera: '',
                ModelSelections: [],
                ModifierSelections: [],
                PropsetSelections: [],
                SurfaceSelections: [],
                Cameras: [],
            };

            if (template.scene.defaultConfigurationSpec !== undefined && template.scene.defaultConfigurationSpec !== null && template.scene.defaultConfigurationSpec !== '') {
                config = JSON.parse(template.scene.defaultConfigurationSpec);
            }

            const newConfiguration: Configuration = {
                ...await mapConfiguration({ configuration: config, template }),
                cameras: configuration.cameras,
            }
            ensureMoodboardPositions(newConfiguration);

            setUndoHistory(history);
            internalSetConfiguration(newConfiguration);
            setRedoHistory([]);

            debouncedSave();
        }

    }, [configuration, debouncedSave, ensureMoodboardPositions, mapConfiguration, template, undoHistory]);

    const undo = useCallback(() => {
        if (undoHistory.length > 0) {
            const newConfig = undoHistory[undoHistory.length - 1];

            const newUndoHistory = [...undoHistory].slice(0, -1);
            setUndoHistory(newUndoHistory);

            const newRedoHistory = [...redoHistory, configuration];
            setRedoHistory(newRedoHistory);

            internalSetConfiguration(newConfig);

            debouncedSave();
        }
    }, [configuration, debouncedSave, redoHistory, undoHistory]);

    const redo = useCallback(() => {
        if (redoHistory.length > 0) {
            const newConfig = redoHistory[redoHistory.length - 1];

            const newUndoHistory = [...undoHistory, configuration];
            setUndoHistory(newUndoHistory);

            const newRedoHistory = [...redoHistory].slice(0, -1);
            setRedoHistory(newRedoHistory);

            internalSetConfiguration(newConfig);

            debouncedSave();
        }
    }, [configuration, debouncedSave, redoHistory, undoHistory]);


    return (
        <SceneDesignerContext.Provider
            value={{
                loading,
                template,
                selectedCamera,
                setSelectedCamera,
                configuration,
                setConfiguration,
                undo,
                redo,
                canRedo,
                canUndo,
                reset,
                products,
                batchProducts,
                selectedBrand, 
                setSelectedBrand,
            }}
        >
            {children}
        </SceneDesignerContext.Provider>
    );
};