import { Modal } from "antd";
import { useCallback } from "react";
import HotspotEditor from "../hotspotEditor/HotspotEditor";
import { SceneCameraDTO, SceneViewDTO } from "../../../../../openapi/requests";

const Hotspots: React.FC<{ camera: SceneCameraDTO | undefined, scene: SceneViewDTO | undefined, onSave: () => void}> = ({camera, scene, onSave}) => {

    const handleClose = useCallback(() => {
        onSave();
    }, [onSave]);   

    return (
        <Modal title="Hotspots" open={true} onOk={handleClose} onCancel={handleClose} width='90vw'>
            <div style={{ width: '100%', height: '75vh' }}>
                <div className='configuration'>
                    <HotspotEditor camera={camera} scene={scene} />
                </div>
            </div>
        </Modal>
    )
}

export default Hotspots;