import classnames from 'classnames';
import React from 'react';
import ModernDrawer from 'react-modern-drawer';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import './Overlay.scss';

type Props = React.PropsWithChildren<{
    className?: string;
    open: boolean;
    onClose: () => void;
}>

const Overlay: React.FC<Props> = ({children, className, open, onClose}) => {
    const classes = classnames('overlay-content', className)
    
    return (
        <ModernDrawer className={'overlay'} open={open} onClose={onClose} direction='bottom' size={'100%'} overlayOpacity={0}>
            <div className='overlay-header'>
                <button className='close' onClick={onClose}>
                    <CloseIcon />
                </button>
            </div>

            <div className={classes}>
                {children}
            </div>
        </ModernDrawer>
    )
};

export default Overlay;