import { Button, Col, Descriptions, Dropdown, Image, Input, Modal, Row, Space, Tag, Timeline, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EditModifier, { ModifierHistoryDetailsButton } from './EditModifier';
import { ModifierService, ModifierViewDTO } from '../../../openapi/requests';
import { useModifierServiceGetModifierApprovalHistory, useModifierServiceGetModifierQAimages, useModifierServicePutModifier } from '../../../openapi/queries';
import { AssetApprovalStatus, AssetApprovalStatusLabels } from '../../../models/enums';
import { BASE_PATH } from '../../..';
import ReactTimeago from 'react-timeago';
const { Text } = Typography;

const ApproveModifier: React.FC<{ modifiers: ModifierViewDTO[], isOpen: boolean, onClose: () => void, hideEditor?: boolean }> = ({ modifiers, isOpen, onClose, hideEditor }) => {
    const [index, setIndex] = useState(0);
    const [comment, setComment] = useState("");
    const [isEditorOpen, setIsEditorOpen] = useState(false);

    const modifier = useMemo(() => {
        if (index < modifiers.length) {
            return modifiers[index];
        }
        return undefined;
    }, [index, modifiers]);

    const { data: qaImages } = useModifierServiceGetModifierQAimages({ key: modifier?.id ?? 0 }, undefined, { enabled: isOpen && modifier !== undefined });
    const { data: qaHistory } = useModifierServiceGetModifierApprovalHistory({ key: modifier?.id ?? 0 }, undefined, { enabled: isOpen && modifier !== undefined });
    const { mutateAsync, isPending } = useModifierServicePutModifier();

    useEffect(() => {
        if (isOpen) {
            setIndex(0);
            setComment("");
        }
    }, [modifiers, isOpen]);

    const handleApprove = useCallback((enable: boolean) => {
        if (modifier === undefined) {
            return;
        }

        mutateAsync({
            key: modifier.id, 
            requestBody: {
                ...modifier,
                isEnabled: enable ? enable : modifier.isEnabled,
                approved: true,
                approvalComment: comment
            }
        }).then(() => {
            if (index + 1 === modifiers.length) {
                onClose();
            } else {
                setIndex(index + 1);
                setComment("");
            }
        });
    }, [comment, index, modifier, mutateAsync, modifiers, onClose]);

    const handleReject = useCallback(() => {
        if (modifier === undefined) {
            return;
        }

        mutateAsync({
            key: modifier.id, 
            requestBody: {
                ...modifier,
                approved: false,
                approvalComment: comment
            }
        }).then(() => {
            if (index + 1 === modifiers.length) {
                onClose();
            } else {
                setIndex(index + 1);
                setComment("");
            }
        });
    }, [comment, index, modifier, mutateAsync, modifiers, onClose]);

    const handleSkip = useCallback(() => {
        if (index + 1 === modifiers.length) {
            onClose();
        } else {
            setIndex(index + 1);
            setComment("");
        }
    }, [index, modifiers.length, onClose]);

    const handleEdit = useCallback(() => {
        setIsEditorOpen(true)
    }, []);

    const handleEditClose = useCallback(() => {
        if (modifier) {
            ModifierService.getModifier1(modifier.id)
                .then((e) => {
                    Object.assign(modifier, e);
                    setIsEditorOpen(false);
                });
        }
    }, [modifier]);

    return (
        <>
            <Modal
                width={'90%'}
                open={isOpen}
                title={modifier?.title}
                onCancel={onClose}
                styles={{
                    footer: {
                        gap: '10px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }
                }}
                footer={[
                    <Button key="back" disabled={isPending} danger type="primary" onClick={handleReject}>
                        Reject
                    </Button>,
                    <Space key="submit" >
                        <Dropdown.Button onClick={() => handleApprove(false)} menu={{ items: [{key: 1, label: 'Approve and enable'}], onClick: () =>  handleApprove(true)}}>Approve</Dropdown.Button>
                    </Space>,
                    hideEditor === true ? null : <Button key="edit" disabled={isPending} type="default" onClick={handleEdit}>
                        Edit
                    </Button>,
                    <Button key="submit" disabled={isPending} type="link" onClick={handleSkip}>
                        Skip
                    </Button>,
                ]}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <p>Thumbnail:</p>
                        <Image src={BASE_PATH + modifier?.image} />
                        <p>QA Images:</p>
                        <Space>
                            {qaImages?.map(e => <Image src={BASE_PATH + e} />)}
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Descriptions layout='horizontal' size='small' bordered column={1} title="Data" items={[
                            {
                                label: 'Status',
                                children: AssetApprovalStatusLabels[(modifier?.approvalStatus ?? 0) as AssetApprovalStatus]
                            },
                            {
                                label: 'Enabled',
                                children: modifier?.isEnabled ? 'True' : 'False'
                            },
                            {
                                label: 'Title',
                                children: modifier?.title
                            },
                            {
                                label: 'Filename',
                                children: modifier?.filename
                            },
                            {
                                label: 'SourceObject',
                                children: modifier?.sourceObject
                            },
                            {
                                label: 'Name',
                                children: modifier?.name
                            },
                            {
                                label: 'Tags',
                                children: modifier?.tags.map(e => <Tag>{e}</Tag>)
                            },
                            {
                                label: 'Meta data',
                                children: <Descriptions size='small' colon layout='horizontal' column={1} items={modifier?.metadata.map(e => ({ label: e.name, children: e.value }))} />
                            },
                            {
                                label: 'Surfaces',
                                children: modifier?.values.map(value => (
                                    <Descriptions style={{ marginBottom: 20 }} labelStyle={{ width: 175 }} bordered size='small' colon layout='horizontal' column={1} items={[
                                        {
                                            label: 'variableName',
                                            children: value.variableName
                                        },
                                        {
                                            label: 'value',
                                            children: value.value
                                        },
                                        {
                                            label: 'isNumeric',
                                            children: value.isNumeric.toString()
                                        },
                                    ]} />
                                ))
                            },
                            {
                                label: 'History',
                                children: <Timeline items={[
                                    {
                                        color: 'blue',
                                        children: (<>
                                            Modifier created <ReactTimeago date={modifier?.timestampCreate ?? ""} />
                                        </>)
                                    },
                                    ...(qaHistory || []).map(e => ({
                                        color: e.newStatus === AssetApprovalStatus.Approved ? 'green' : (e.newStatus === AssetApprovalStatus.Rejected ? 'red' : 'blue'),
                                        children: (<>
                                            Status changed from <b>{AssetApprovalStatusLabels[e.oldStatus as AssetApprovalStatus]}</b> to <b>{AssetApprovalStatusLabels[e.newStatus as AssetApprovalStatus]}</b> {' '}
                                            <ReactTimeago date={e.timestamp} />
                                            <ModifierHistoryDetailsButton item={e} modifier={modifier} />
                                            {e.comment && <>
                                                <br />
                                                <Text code>{e.comment}</Text>
                                            </>}
                                        </>)
                                    }))]} />
                            },
                        ]} />
                        <Input.TextArea value={comment} placeholder='comments' title='reason' onChange={e => setComment(e.target.value)} />
                    </Col>
                </Row>
            </Modal>

            {(hideEditor !== true) && <EditModifier modifier={modifier} isOpen={isEditorOpen} onClose={handleEditClose} />}
        </>

    );
};

export default ApproveModifier;