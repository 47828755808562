import { ReactNode, useEffect, useState } from "react";
import "./CheckBox.scss";

export const CheckBox: React.FC<{label: string, checked: boolean, onChange: (checked: boolean) => void, children?: ReactNode}> = ({label, checked, onChange, children}) => {
    const defaultChecked = checked ? checked: false;
    const [isChecked, setIsChecked] = useState(defaultChecked);

    useEffect(() => {
        setIsChecked(checked ? checked: false)
    }, [checked])

    return (        
        <div className='checkbox'>
            <label>
                <input type="checkbox" checked={isChecked} onChange={(e) => { onChange(!isChecked); setIsChecked(!isChecked);  }} />
                <span className="check">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M3.5 8.5L6 11L12.5 4.5" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
                <span>{label}</span>
                {children}
            </label>           
        </div>
    )
}

export default CheckBox;
