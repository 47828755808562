import { Button, Drawer, Form, Input, Select, Space, Transfer, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { TransferDirection } from "antd/es/transfer";
import { useInternalClientServiceGetInternalClient, useRoleServiceGetRole, useUserServiceGetUserClaimsAll, useUserServicePostUser } from "../../../openapi/queries";
import { RoleService, UserDTO } from "../../../openapi/requests";
import { useQueries } from "@tanstack/react-query";

const CreateUser: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
    const { data: clients } = useInternalClientServiceGetInternalClient({}, undefined, { enabled: isOpen });
    const { data: roles } = useRoleServiceGetRole(undefined, { enabled: isOpen });
    const { data: claims } = useUserServiceGetUserClaimsAll(undefined, { enabled: isOpen });
    const { mutateAsync, isPending } = useUserServicePostUser();

    const rolesClaims = useQueries({
        queries: roles
          ? roles.map((role) => {
              return {
                queryKey: ['roleClaims', role.id],
                queryFn: () => RoleService.getRoleClaim(role.id),
              }
            })
          : [],
      });

    const [form] = Form.useForm<UserDTO>();
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedClaims, setSelectedClaims] = useState<string[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

    const implicitClaims = useMemo(() => {
        var claims: string[] = [];

        for (let i = 0; i < selectedRoles.length; i++) {
            var roleIndex = roles.findIndex(e => e.name === selectedRoles[i]);

            if(rolesClaims[roleIndex] && rolesClaims[roleIndex].data){
                claims = [...claims, ...rolesClaims[roleIndex].data!.map(e => e.value)]
            }
        }

        return claims;
    }, [selectedRoles, roles, rolesClaims])

    const onSubmit = () => {
        form.submit()
    }

    useEffect(() => {
        form.resetFields();
        setSelectedClaims([]);
        setSelectedRoles([]);
    }, [isOpen,form]);

    const handleClaimTransfer = (newTargetKeys: React.Key[],
        direction: TransferDirection,
        moveKeys: React.Key[],) => {
        console.log(newTargetKeys, direction, moveKeys);
        setSelectedClaims(newTargetKeys.map(e => e.toString()));
    }

    const handleRoleTransfer = (newTargetKeys: React.Key[],
        direction: TransferDirection,
        moveKeys: React.Key[],) => {
        console.log(newTargetKeys, direction, moveKeys);
        setSelectedRoles(newTargetKeys.map(e => e.toString()));
    }

    const onFinish = () => {
        const user = form.getFieldsValue(true) as UserDTO;
        user.userClaims = claims.filter(x => { return selectedClaims.includes(x.value) });
        user.userRoles = roles.filter(x => { return selectedRoles.includes(x.name) });

        mutateAsync({ requestBody: user })
            .then(() => {
                messageApi.success("User created");
                onClose();
            }).catch(reason => {
                messageApi.error(JSON.stringify(reason));
            });
    };

    return (
        <>
            {contextHolder}
            <Drawer
                title="Create a new user"
                width={720}
                onClose={onClose}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >

                    <Form.Item rules={[{ required: true }]} name='clientId' label="Client">
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name="username" label="Username">
                        <Input />
                    </Form.Item>

                    <Form.Item rules={[{
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    }]} name="email" label="Email">
                        <Input />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name="password" label="Password">
                        <Input />
                    </Form.Item>

                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                        }),
                    ]} dependencies={['password']} name="confirm" label="Confirm password">
                        <Input />
                    </Form.Item>

                    <Form.Item label="Roles">
                        <Transfer
                            dataSource={roles?.map(x => { return { key: x.name, value: x.name } })}
                            listStyle={{
                                width: 300,
                                height: 300,
                            }}
                            targetKeys={selectedRoles}
                            onChange={handleRoleTransfer}
                            render={item => item.value}
                        />
                    </Form.Item>

                    <Form.Item label="Claims">
                        <Transfer
                            dataSource={claims?.map(x => { return { key: x.value, value: x.value, disabled: implicitClaims.includes(x.value) } })}
                            listStyle={{
                                width: 300,
                                height: 300,
                            }}
                            titles={["Available", "Chosen"]}
                            targetKeys={[...selectedClaims, ...implicitClaims]}
                            onChange={handleClaimTransfer}
                            render={item => item.value}
                        />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default CreateUser;