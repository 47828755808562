import { Filter } from "../types/types";

export const GenerateODataFilter = (filters: Filter[]): string | undefined => {

    let filterString = "";
    let filterCombined: string[] = [];

    filters.forEach((filter, index) => {

        let filterValues: string[] = [];

        if (filter.values.length > 0) {

            if (index > 0 && filterString.length > 0 && filter.values.length > 0) {
                filterString += " and ";
            }

            switch (filter.type) {
                case "search":

                    [...filter.property.split(",")].forEach(property => {
                        filter.values.forEach((value,valueindex) => {
                            if (property.indexOf(".") > 0) {
                                var props = property.split(".")
                                let propPath = props.slice(0, props.length-1).join("/")+"/any";

                                if (value.toString().indexOf(",") > 0) {
                                    var values = value.toString().split(",")
                                    propPath += `(p:tolower(p/Name) eq '${props[props.length-1].toLowerCase()}' and ${values.reduce((prev,curr,index) => prev += `${index > 0 ? ` or ` : ``}contains(tolower(p/Value),'${curr.toString().toLowerCase()}')`,"")})`;
                                } else {
                                    propPath += `(p:tolower(p/Name) eq '${props[props.length-1].toLowerCase()}' and contains(tolower(p/Value),'${value.toString().toLowerCase()}'))`;
                                }

                                filterValues.push(propPath);
                            }else{
                                filterValues.push(`contains(tolower(${property}),'${value.toString().toLowerCase()}')`);
                            }
                        });
                    });

                    filterCombined.push(`(${filterValues.join(" or ")})`)

                    break;
                case "select":

                    [...filter.property.split(",")].forEach(property => {
                        filter.values.forEach((value,valueindex)  => {

                            if(typeof value === 'boolean'){
                                value = value.toString();
                            }else if(typeof value !== 'number'){
                                value = `'${value}'`;
                            }

                            const comparison = filter.negate ? "ne" : "eq";
                        
                            var propertyPath = `${property} ${comparison} ${value}`;
                            if (property.indexOf(".") > 0) {
                                var props = property.split(".")
                                propertyPath = props.slice(0, props.length-1).join("/")+"/any";
                                propertyPath += `(p:tolower(p/Name) eq '${props[props.length-1].toLowerCase()}' and tolower(p/Value) ${comparison} ${value.toString().toLowerCase()})`
                            }

                            filterValues.push(propertyPath);                   
                        });
                    });

                    filterCombined.push(`(${filterValues.join(" or ")})`)

                    break;

                case "daterange":

                    filter.values.forEach((value, valueindex) => {
                        const comparer = (valueindex === 1) ? "lt" : "gt";
                        const dateValue = new Date(parseInt(value.toString())).toISOString()
                        filterValues.push(`${filter.property} ${comparer} ${dateValue}`);
                    });

                    filterCombined.push(`(${filterValues.join(" and ")})`)

                    break;
                 default: 
                    const comparison = filter.negate ? "ne" : "eq";

                    filter.values.forEach(value => {
                       filterValues.push(`${filter.property} ${comparison} ${value}`);
                    });

                    filterCombined.push(`(${filterValues.join(" or ")})`)
                 break;
            }
        }   
        
        filterString = filterCombined.join(" and ")
    });

    if (filterString.length === 0)
        return undefined;

    return filterString;
}