import { Modal, Layout, Card, Flex, Button, message } from "antd";
import { useAssetFilterPresetServiceGetAssetFilterPreset, useAssetFilterPresetServiceGetAssetFilterPresetKey, useAssetServiceGetAssetTypesAll, useAssetServiceGetAssetTypesAllKey, useUserServiceGetUserAssetFilterKey, useUserServiceGetUserAssetfilterpreset, useUserServiceGetUserAssetfilterpresetKey } from "../../../../../openapi/queries"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AssetFilterPresetDTO, AssetFilterPresetService, UserAssetFilterDTO, UserService, UserViewDTO } from "../../../../../openapi/requests";
import { ExpressionEditor } from "../../../../editors/ExpressionEditor";
import { Header, Content } from "antd/es/layout/layout";
import { FilterPresets } from "../FilterPresets";
import ScrollContainer from "react-indiana-drag-scroll";
import { parseNested } from "../../../../../helpers/jsonFunctions";
import { FilterAssetPreset, FilterAssetPresets } from "../../../../../types/types";
import { DeleteOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";

interface UserFiltersEditorProps {
    userAssetFilters: UserAssetFilterDTO[]
    isOpen: boolean
    onClose: () => void;
    user: UserViewDTO | undefined;
    onFiltersChange: (filters: UserAssetFilterDTO[]) => void;  
}

export const UserFiltersEditor: React.FC<UserFiltersEditorProps> = ({ user, userAssetFilters, isOpen, onClose, onFiltersChange }) => {

    const currentUserAssetFilters = useRef<UserAssetFilterDTO[]>([]);
    const [localAssetFilters, setLocalUserAssetFilters] = useState<any[]>([]);

    const [messageApi] = message.useMessage();

    const { data: assetTypes } = useAssetServiceGetAssetTypesAll([useAssetServiceGetAssetTypesAllKey]);
    const { data: assetFilterPresets } = useAssetFilterPresetServiceGetAssetFilterPreset({}, [useAssetFilterPresetServiceGetAssetFilterPresetKey], { enabled: isOpen });
    const { data: userAssetFilterPreset } = useUserServiceGetUserAssetfilterpreset({ key: user?.id ?? 0 }, [useUserServiceGetUserAssetfilterpresetKey], { enabled: isOpen });

    const queryClient = useQueryClient();

    useEffect(() => {

        let updatedAssetFilters: any[] = [];
        if (assetTypes) {
            assetTypes.forEach(assetType => {

                if (userAssetFilters) {
                    var userAssetFilter = userAssetFilters.find(x => x.type === assetType);
                    if (userAssetFilter) {
                        updatedAssetFilters.push({ key: crypto.randomUUID(), id: userAssetFilter.id, type: userAssetFilter.type, filter: userAssetFilter.filter })
                    } else {
                        updatedAssetFilters.push({ key: crypto.randomUUID(), id: 0, type: assetType, filter: null });
                    }
                }

            });

            setLocalUserAssetFilters(updatedAssetFilters);
        }
    }, [userAssetFilters, assetTypes])

    const hasAssetFilterPreset = useMemo(() => {
        return userAssetFilterPreset !== ""
    }, [userAssetFilterPreset]);

    const onSave = (e: any) => {
        onFiltersChange(currentUserAssetFilters.current);
        onClose();
    }

    const onFilterChange = useCallback((assetType: string, expression: string) => {
        let updateAssetFilter = currentUserAssetFilters.current.find(x => x.type === assetType);
        if (updateAssetFilter) {
            updateAssetFilter.filter = expression;
        } else {
            updateAssetFilter = { id: 0, type: assetType, filter: expression, userId: user?.id }
        }

        currentUserAssetFilters.current = [...currentUserAssetFilters.current.filter(x => x.type !== updateAssetFilter?.type), updateAssetFilter];

    }, [currentUserAssetFilters, user]);

    const onDeleteFilterPreset = useCallback(async (presetId: number) => {

        await AssetFilterPresetService.deleteAssetFilterPreset(presetId);
        await queryClient.refetchQueries({ queryKey: [useUserServiceGetUserAssetfilterpresetKey] });        
        await queryClient.refetchQueries({ queryKey: [useAssetFilterPresetServiceGetAssetFilterPresetKey] });

       // messageApi.success("Filter preset deleted"); 

    }, [queryClient])

    const onApplyFilterPreset = async (presetId: number) => {

        if (user) {
            let updatedAssetFilters: any[] = [];

            let assetFilterPreset = assetFilterPresets.value.find(x => x.id === presetId);
            if (assetFilterPreset) {

                var assetFilterPresetConfig = parseNested(assetFilterPreset.configurationSpec) as FilterAssetPresets;

                assetTypes.forEach(assetType => {

                    var assetFilterPresetAssetTypeFilter = assetFilterPresetConfig.filters.find(x => x.type === assetType);
                    var userAssetFilter = userAssetFilters.find(x => x.type === assetType);
                    
                    let updatedAssetFilter: any = { key: crypto.randomUUID(), id: 0, type: assetType, filter: null }

                    if (userAssetFilters) {
                       
                        if (userAssetFilter) {
                            updatedAssetFilter.id = userAssetFilter.id;
                        }

                        if (assetFilterPresetAssetTypeFilter) {
                            updatedAssetFilter.filter = assetFilterPresetAssetTypeFilter.filter;
                          
                        } 
                        updatedAssetFilters.push(updatedAssetFilter);
                    }
                });

                await UserService.postUserAssetfilterpreset(user.id, assetFilterPreset);

                currentUserAssetFilters.current = updatedAssetFilters;
                setLocalUserAssetFilters(updatedAssetFilters);
                onFiltersChange(updatedAssetFilters);    
                
                await queryClient.refetchQueries({ queryKey: [useAssetFilterPresetServiceGetAssetFilterPresetKey] });
                await queryClient.refetchQueries({ queryKey: [useUserServiceGetUserAssetfilterpresetKey] });
              
            }
        }
    }

    const onDeleteUserFilterPreset = useCallback(async () => {

        if (user) {
            await UserService.deleteUserAssetfilterpreset(user.id);            
            await queryClient.refetchQueries({ queryKey: [useAssetFilterPresetServiceGetAssetFilterPresetKey] });
            await queryClient.refetchQueries({ queryKey: [useUserServiceGetUserAssetfilterpresetKey] });
        }

    }, [queryClient, user])

    const onNewFilterPreset = useCallback(async (name: string) => {

        let combinedFilters = { filters: [] } as FilterAssetPresets;   
        currentUserAssetFilters.current.forEach(userAssetFilter => {
            if (userAssetFilter.filter !== "") {
                let filter = { type: userAssetFilter.type, filter: userAssetFilter.filter } as FilterAssetPreset
                combinedFilters.filters = [...combinedFilters.filters, filter]
            }
        });

        let newFilterPreset = {
            id: 0,
            clientId: user?.clientId,
            name: name,
            configurationSpec: `{ "filters": ${JSON.stringify(combinedFilters.filters)} }`
        } as AssetFilterPresetDTO

        await AssetFilterPresetService.postAssetFilterPreset(newFilterPreset);        
        await queryClient.refetchQueries({ queryKey: [useAssetFilterPresetServiceGetAssetFilterPresetKey] });
        await queryClient.refetchQueries({ queryKey: [useUserServiceGetUserAssetfilterpresetKey] });
        
        //messageApi.success("Filter preset created"); 

    }, [user, queryClient, currentUserAssetFilters])

    const onUpdateFilterPreset = useCallback(async (presetId:number, updatedFilters: FilterAssetPresets) => {
        
       let combinedFilters =  {filters: []} as FilterAssetPresets;    
       updatedFilters.filters.forEach(updatedAssetFilter => {
            if (updatedAssetFilter.filter !== "") {
                let filter = { type: updatedAssetFilter.type, filter: updatedAssetFilter.filter } as FilterAssetPreset
                combinedFilters.filters = [...combinedFilters.filters, filter]
            }
        });
        let updateFilterPreset = {
            id: presetId,
            configurationSpec: JSON.stringify(combinedFilters)
        } as AssetFilterPresetDTO

        await AssetFilterPresetService.putAssetFilterPreset(presetId, updateFilterPreset);
        await queryClient.refetchQueries({ queryKey: [useUserServiceGetUserAssetFilterKey] });
        await queryClient.refetchQueries({ queryKey: [useAssetFilterPresetServiceGetAssetFilterPresetKey] });   
        
    },[queryClient])

    return (
        <Modal open={isOpen} title="Filter Editor" onCancel={onClose} width={"100%"} style={{ minWidth: "600px", maxWidth: "1200px" }} onOk={onSave}>
            <Layout>
                <Header style={{ padding: "0", backgroundColor: "#FFF", display: "flex" }}>
                    <Flex justify="space-between" align="center" flex="1 0 auto">
                        <FilterPresets selectedPreset={userAssetFilterPreset?.assetFilterPresetId} onApplyFilterPreset={onApplyFilterPreset} onNewFilterPreset={onNewFilterPreset} onDeleteFilterPreset={onDeleteFilterPreset} onUpdateFilterPreset={onUpdateFilterPreset} />
                        {hasAssetFilterPreset ? <Button title="Remove preset" type="primary" icon={<DeleteOutlined />} onClick={onDeleteUserFilterPreset}>Remove User Preset</Button> : null}
                    </Flex>
                </Header>
                <Content>
                    <Flex gap={8} vertical>
                        {localAssetFilters.map((assetFilter, index) =>
                            <Card key={index} title={assetFilter.type} headStyle={{ backgroundColor: "rgba(0, 0, 0, 0.45)", color: "#FFF" }}>
                                <ScrollContainer className="scroll-container" buttons={[1, 2]} hideScrollbars={false}>
                                    <ExpressionEditor enabled={!hasAssetFilterPreset} key={assetFilter.key} expression={assetFilter.filter} onExpressionChange={(e) => onFilterChange(assetFilter.type, e)} />
                                </ScrollContainer>
                            </Card>
                        )}
                    </Flex>
                </Content>
            </Layout>
        </Modal>
    )
} 