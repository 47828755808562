/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetApprovalHistoryDTO } from '../models/AssetApprovalHistoryDTO';
import type { ModelEditDTO } from '../models/ModelEditDTO';
import type { ModelViewDTO } from '../models/ModelViewDTO';
import type { ModelViewDTOODataListResponse } from '../models/ModelViewDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ModelService {

    /**
     * @param query 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ModelViewDTOODataListResponse Success
     * @throws ApiError
     */
    public static getModel(
query?: string,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ModelViewDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Model',
            query: {
                'query': query,
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ModelEditDTO Success
     * @throws ApiError
     */
    public static postModel(
requestBody?: ModelEditDTO,
): CancelablePromise<ModelEditDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Model',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ModelViewDTO Success
     * @throws ApiError
     */
    public static getModel1(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ModelViewDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Model/{key}',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns ModelEditDTO Success
     * @throws ApiError
     */
    public static putModel(
key: number,
requestBody?: ModelEditDTO,
): CancelablePromise<ModelEditDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/Model/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteModel(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Model/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param clientId 
     * @param query 
     * @returns ModelViewDTO Success
     * @throws ApiError
     */
    public static getModelQuery(
clientId?: number,
query?: string,
): CancelablePromise<Array<ModelViewDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Model/query',
            query: {
                'clientId': clientId,
                'query': query,
            },
        });
    }

    /**
     * @param key 
     * @param size 
     * @param format 
     * @returns any Success
     * @throws ApiError
     */
    public static getModelThumbnail(
key: number,
size: number = 256,
format: string = 'webp',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Model/{key}/thumbnail/{size}',
            path: {
                'key': key,
            },
            query: {
                'size': size,
                'format': format,
            },
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static getModelImage(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Model/{key}/image',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postModelImage(
key: number,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Model/{key}/image',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postModelQAimages(
key: number,
formData?: {
files?: Array<Blob>;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Model/{key}/QAimages',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @returns string Success
     * @throws ApiError
     */
    public static getModelQAimages(
key: number,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Model/{key}/QAimages',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param index 
     * @returns any Success
     * @throws ApiError
     */
    public static getModelQAimages1(
key: number,
index: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Model/{key}/QAimages/{index}',
            path: {
                'key': key,
                'index': index,
            },
        });
    }

    /**
     * @param key 
     * @param index 
     * @returns any Success
     * @throws ApiError
     */
    public static getModelApprovalHistoryImages(
key: number,
index: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Model/{key}/ApprovalHistory/images/{index}',
            path: {
                'key': key,
                'index': index,
            },
        });
    }

    /**
     * @param key 
     * @returns AssetApprovalHistoryDTO Success
     * @throws ApiError
     */
    public static getModelApprovalHistory(
key: number,
): CancelablePromise<Array<AssetApprovalHistoryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Model/{key}/ApprovalHistory',
            path: {
                'key': key,
            },
        });
    }

}
