import { UploadOutlined } from "@ant-design/icons";
import { Button, Image, Space, Upload, UploadProps, message } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { useCallback, useMemo, useState } from "react";

interface Props {
    action: string;
    title?: string;
    previewPath?: string;
    isVideo?: boolean;
    onUpload?: (info: UploadChangeParam) => void;
    size?: number;
}

const ImageFileUpload: React.FC<Props> = ({ action, title, previewPath, onUpload, isVideo, size }) => {

    const [isUploading, setIsUploading] = useState(false);
    const [rnd, setRnd] = useState(1);

    const previewUrl = useMemo(() => {
        if (previewPath) {
            var url = new URL(previewPath);
            url.searchParams.set('rnd', rnd.toString());
            return url.href;
        }
        return undefined;
    }, [previewPath, rnd]);


    const uploadCameraThumb = useCallback((info: UploadChangeParam) => {
        if (info.file.status === "uploading") {
            setIsUploading(true);
        }
        if (info.file.status === "error") {
            setIsUploading(false);
            message.error(`${info.file.name} file upload failed`)
        }
        if (info.file.status === "done") {
            setIsUploading(false);
            setRnd(e => e + 1);
            if (onUpload) {
                onUpload(info);
            }
            message.success(`${info.file.name} file uploaded successfully`)
        }
    }, [onUpload]);

    const uploadProps: UploadProps = {
        method: "post",
        showUploadList: false,
        accept: isVideo ? ".mp4" : ".jpeg,.jpg,.pjpeg,.png,.x-png,.gif"
    }

    return (
        <Space>
            <Upload onChange={uploadCameraThumb} action={action} {...uploadProps}>
                {previewUrl === undefined ?
                    <Button title='Upload' loading={isUploading}>
                        <UploadOutlined />{title}
                    </Button> :
                    <div style={{ position: 'relative' }}>
                        {isVideo ? <video src={previewUrl} autoPlay loop muted style={{ width: size }}></video> :
                            <Image src={previewUrl} preview={false} width={size} />
                        }
                        <span style={{ position: 'absolute', bottom: '0', left: '50%', transform: 'translate(-50%, 0)' }}>
                            {title}
                        </span>
                        <Button title='Upload' loading={isUploading} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <UploadOutlined />
                        </Button>
                    </div>
                }
            </Upload>
        </Space>
    )
}

export default ImageFileUpload