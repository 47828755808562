import { CloudUploadOutlined, DownloadOutlined, EditOutlined, ExclamationOutlined, HourglassOutlined, LoadingOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons';
import { Card, Col, Descriptions, Image, Row, Timeline } from 'antd';
import React from 'react';
import TimeAgo from 'react-timeago';
import { BASE_PATH } from '../../..';
import { useClientServiceGetClient1, useOrderServiceGetOrder, useOrderlineServiceGetOrderOrderline, useProjectServiceGetProject1, useUserServiceGetUser1 } from '../../../openapi/queries';
import { OrderDTO } from '../../../openapi/requests';

var orderStatuses: { [key: number]: { icon: React.ReactElement, name: string } } = {
    0: { icon: <EditOutlined />, name: 'Draft' },
    10: { icon: <HourglassOutlined />, name: 'Placed' },
    20: { icon: <HourglassOutlined />, name: 'Delegating' },
    30: { icon: <LoadingOutlined spin />, name: 'Processing' },
    40: { icon: <StopOutlined />, name: 'Declined' },
    50: { icon: <DownloadOutlined />, name: 'Deliverable' },
    53: { icon: <ExclamationOutlined />, name: 'Delivery failed' },
    60: { icon: <CloudUploadOutlined />, name: 'Delivered' },
    99: { icon: <WarningOutlined />, name: 'Failed' },
}

const Order: React.FC<{ order: OrderDTO }> = ({ order }) => {
    const { data: orderlines } = useOrderlineServiceGetOrderOrderline({ key: order.id });
    const { data: user } = useUserServiceGetUser1({ key: order.orderedByUserId });
    const { data: client } = useClientServiceGetClient1({ key: order.clientId });

    return (
        <>
            <h3>{orderStatuses[order.status]?.icon}{' '}{orderStatuses[order.status]?.name} - Order id: {order.id}</h3>

            <Descriptions column={1} items={[
                {
                    key: 'user',
                    label: 'User',
                    children: user?.username
                },
                {
                    key: 'client',
                    label: 'Client',
                    children: client?.name
                },
                {
                    key: 'isPreview',
                    label: 'Type',
                    children: orderlines?.some(e => e.isPreview) ? 'Preview' : 'Final'
                }
            ]} />

            <Row gutter={16}>
                {orderlines?.flatMap(e => e.files).map(e => <Col span={8}>
                    <Card cover={<Image src={BASE_PATH + e.url} />} bordered={false}>
                        <Card.Meta title={e.name} description={<a download href={BASE_PATH + e.url}>Download</a>} />
                    </Card>
                </Col>)}
            </Row>
        </>
    );
};

const Project: React.FC<{ projectId: number }> = ({ projectId }) => {
    const { data: project } = useProjectServiceGetProject1({ key: projectId });
    const { data: orders } = useOrderServiceGetOrder({ filter: `projectID eq ${projectId}`, orderby: 'timestampCreate asc' });

    return (
        <>
            <h1>{project?.name}</h1>

            <Descriptions column={1} items={[
                {
                    key: 'created',
                    label: 'Created at',
                    children: <TimeAgo date={project?.timestampCreate} />
                },
                {
                    key: 'modified',
                    label: 'Modified at',
                    children: <TimeAgo date={project?.timestampModify} />
                }
            ]} />

            <h2>Timeline</h2>
            <Timeline
                mode={'left'}
                items={orders?.value.map(e => ({ label: <TimeAgo date={e.timestampCreate} />, children: <Order order={e} /> }))}
            />
        </>
    );
};

export default Project;