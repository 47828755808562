import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as GridIcon } from '../assets/icons/grid.svg';
import { ReactComponent as ListIcon } from '../assets/icons/list.svg';
import TemplateDetails from '../components/designer/TemplateDetails';
import AssetList from '../components/layout/AssetList';
import Layout from '../components/layout/Layout';
import CreateProject from '../components/project/CreateProject';
import { useTemplateServiceGetTemplate1 } from '../openapi/queries';
import { SceneCameraDTO } from '../openapi/requests';
import './Template.scss';

const scenemetadata = [
    {
        label: 'Brand',
        name: 'brand'
    },
    {
        label: 'Range',
        name: 'range'
    },
    {
        label: 'Category',
        name: 'category'
    },
    {
        label: 'Description',
        name: 'description'
    }
];

interface Camera {
    name: string;
    title: string;
    thumbnail: string;
    camera: SceneCameraDTO;
}

const Templates: React.FC = () => {
    let { templateID } = useParams();

    const { data: template } = useTemplateServiceGetTemplate1({ key: parseInt(templateID ?? "0") });
    const [formOpen, setFormOpen] = useState(false);
    const [mode, setMode] = useState<"medium" | "large">("medium");
    const [selection, setSelection] = useState<Camera[]>([]);
    const [showDetails, setShowDetails] = useState(false);

    const cameras = useMemo((): Camera[] => {
        if (template?.scene === undefined) {
            return [];
        }

        return template.scene.cameras.filter(e => !e.isAnimated).map(cam => ({
            name: cam.cameraName,
            title: cam.label,
            thumbnail: cam.thumbnail + "/1024",
            thumbnailAspectRatio: cam.aspectRatio,
            camera: cam,
        }))
    }, [template]);

    const handledoubleClick = useCallback((camera: Camera) => {
        setFormOpen(true);
    }, []);

    const handleCreateClick = useCallback(() => {
        setFormOpen(true);
    }, []);

    const metadata = useMemo(() => {
        const result = [];

        if (template?.scene) {
            for (let i = 0; i < scenemetadata.length; i++) {
                const element = scenemetadata[i];

                const data = template.scene.metadata.find(e => e.name.toLowerCase() === element.name.toLowerCase());

                if (data) {
                    result.push({
                        label: element.label,
                        value: data.value
                    })
                }
            }
        }

        return result;
    }, [template]);

    return (<Layout className='template-page'>
        <header>
            <div>

            </div>
            <div>
                <button className={(mode === "large") ? 'selected' : 'deselected'} onClick={() => setMode("large")}>
                    <ListIcon />
                </button>
                <button className={(mode === "medium") ? 'selected' : 'deselected'} onClick={() => setMode("medium")}>
                    <GridIcon />
                </button>
            </div>
            <div>

            </div>
        </header>

        {template &&
            <div className='template'>
                <div className='template-header'>

                    <div className="actions">
                        <AnimatePresence>
                            {selection.length > 0 && <motion.button
                                className={'button secondary clear'}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                key={'clear'}
                                onClick={() => setSelection([])}>
                                Clear selection
                            </motion.button>}
                            {selection.length > 0 && <motion.button
                                className={'button primary create-btn'}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                key={'create'}
                                onClick={handleCreateClick}>
                                Start Creating ({selection.length})
                            </motion.button>}
                        </AnimatePresence>
                    </div>
                    <h1>{template.title}</h1>
                    <p className='placeholder'>
                        {metadata.map(e => <span key={e.label}>{e.label}: {e.value}</span>)}
                    </p>
                </div>

                <div className='template-cameras'>
                    <AssetList assets={cameras} displayMode={mode} allowMultiSelect selection={selection} onSelectionChanged={(sel) => setSelection(sel)} onItemDoubleClick={handledoubleClick} />
                </div>
            </div>
        }

        <button className='info' onClick={() => setShowDetails(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <circle cx="6" cy="6" r="6" fill="black" />
            </svg>
            More info
        </button>

        <CreateProject isOpen={formOpen} onCancel={() => setFormOpen(false)} template={template} />

        <TemplateDetails open={showDetails} onClose={() => setShowDetails(false)} templateId={template?.id} />
    </Layout>)
};

export default Templates;