import React from 'react';
import ModelList from '../../../components/admin/models/ModelList';

const Models: React.FC = () => {

    return (<div>
        <ModelList />
    </div>)
};

export default Models;