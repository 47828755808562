import { Button, message, Popconfirm, Space, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { PlusOutlined } from '@ant-design/icons';
import CreateClient from './CreateClient';
import EditClient from './EditClient';
import { useInternalClientServiceDeleteInternalClient, useInternalClientServiceGetInternalClient } from '../../../openapi/queries';
import { InternalClientDTO } from '../../../openapi/requests';
import { FilterValue, SorterResult } from 'antd/es/table/interface';


const ClientList: React.FC = () => {
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState<string>("id");
    const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");

    const { data, isLoading, refetch } = useInternalClientServiceGetInternalClient({ 
        top: pageSize.toString(), 
        skip: ((page - 1) * pageSize).toString(), 
        orderby: `${sortField} ${sortOrder}`,
        count: 'true' })
    const {mutate, isSuccess} = useInternalClientServiceDeleteInternalClient();

    const [messageApi, contextHolder] = message.useMessage();
    const [selectedClient, setSelectedClient] = useState<InternalClientDTO | undefined>(undefined);

    const [createOpen, setCreateOpen] = useState(false);

    const openCreate = () => {
        setCreateOpen(true);
    };

    const handleCreateClose = useCallback(() => {
        setCreateOpen(false);
        refetch();
    }, [refetch]);

    const handleEditClose = useCallback(() => {
        setSelectedClient(undefined);
        refetch();
    }, [refetch]);

    const handleDelete = useCallback((client: InternalClientDTO) => {
        if(client.id){
            mutate({key: client.id})
        }
    }, [mutate]);

    useEffect(() => {
        if(isSuccess){
            messageApi.success("Client deleted");
        }
    }, [isSuccess, messageApi]);
    

    const handleTableChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<InternalClientDTO> | SorterResult<InternalClientDTO>[]) => {
        setPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);

        if(!Array.isArray(sorter)){
            setSortField(sorter.field?.toString() ?? "id");
            setSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc');
        }
    }, []);

    const columns: ColumnsType<InternalClientDTO> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => (a.id || 0) - (b.id || 0),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => setSelectedClient(record)}>Edit</Button>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                        <Button type="link">Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <>
            {contextHolder}

            <Button type="primary" onClick={openCreate} icon={<PlusOutlined />} style={{marginBottom: 16}}>
                New client
            </Button>

            <CreateClient isOpen={createOpen} onClose={handleCreateClose} />

            <EditClient isOpen={selectedClient != null} onClose={handleEditClose} client={selectedClient} />

            <Table
                loading={isLoading}
                dataSource={data?.value ?? Array.from({ length: pageSize }).map(x => ({}))}
                columns={columns}
                rowKey={"id"}
                onChange={handleTableChange}
                pagination={{
                    total: (data !== undefined && data['@odata.count']) ? data['@odata.count'] : 0,
                    current: page,
                    pageSize: pageSize,
                }} />
        </>
    );
};

export default ClientList;