import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserServicePostUserForgotpassword } from "../../openapi/queries";


const ForgotPassword: React.FC = () => {
    const [username, setUsername] = useState("");
    const navigate = useNavigate();
    const { mutateAsync } = useUserServicePostUserForgotpassword();

    const onFinish = () => {

        mutateAsync({ requestBody: { email: username } }).then(resp => {
            navigate("/users/forgotconfirmation");
        });
    }

    return (
        <>
            <h1>Forgot Your Password?</h1>

            <input type='email' onChange={e => setUsername(e.target.value)} name='username' placeholder='Email address' />
            <button onClick={onFinish}>Submit</button>
        </>
    )
}

export default ForgotPassword;