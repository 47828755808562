import { FC, createContext, useState } from "react";

export interface UIContextState {
    selectedProjectView: string;
    setSelectedProjectView: (value: string) => void
}

const contextDefaultValues: UIContextState = {
    selectedProjectView: "",
    setSelectedProjectView: () => {}
}
    
export const UIContext = createContext<UIContextState>(
    contextDefaultValues
); 

export const UIProvider: FC<{ children: React.ReactNode }> = ({children}) => {
    
    const [selectedProjectView, setSelectedProjectView] = useState<string>("owned");


    return (
        <UIContext.Provider 
            value={{
                selectedProjectView,
                setSelectedProjectView
            }}
            >  
           {children}
        </UIContext.Provider>
    )
}