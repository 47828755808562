import React from 'react';
import { useParams } from 'react-router-dom';
import OrderDetails from '../../../components/admin/orders/OrderDetails';

const Order: React.FC = () => {
    let { orderid } = useParams();

    return (<div>
        <OrderDetails orderId={parseInt(orderid || '0')} />
    </div>)
};

export default Order;