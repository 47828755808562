import { useMemo, useState } from "react";
import { ApiError, ResetPasswordModel } from "../../openapi/requests";
import { useUserServicePostUserResetpassword } from "../../openapi/queries";
import { useNavigate } from "react-router-dom";

const ResetPassword: React.FC<{ onReset?: () => void, email?: string, token?: string }> = ({ onReset, email, token }) => {

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [notMatching, setNotMatching] = useState(false);
    const { mutateAsync, error } = useUserServicePostUserResetpassword();
    const navigate = useNavigate();

    const onFinish = () => {
        const queryParameters = new URLSearchParams(window.location.search);

        if (password !== passwordConfirm) {
            setNotMatching(true);
        } else {
            const resetModel: ResetPasswordModel = {
                email: email ?? queryParameters.get("email") ?? "",
                token: token ?? queryParameters.get("token") ?? "",
                password: password
            }
            mutateAsync({ requestBody: resetModel }).then(() => {
                if (onReset) {
                    onReset();
                } else {
                    navigate("/users/resetconfirmation", { replace: true });
                }
            });
        }
    }

    const errors = useMemo(() => {
        if (error) {
            const apiError = error as ApiError;
            const errors = apiError.body as { code: string, description: string }[];

            return errors.map(e => e.description);
        }

    }, [error])

    return (
        <>
            <div className="resetpassword">
                <h1>Reset password</h1>
                <div>
                    <input type='password' onChange={e => setPassword(e.target.value)} name='password' placeholder='Password' />
                    <input type='password' onChange={e => setPasswordConfirm(e.target.value)} name='passwordConfirmation' placeholder='Confirm password' />
                    <button onClick={onFinish}>Reset</button>
                </div>
            </div>
            {notMatching && (
                <p className='error'>Passwords do not match</p>
            )}
            {errors && (
                errors.map(e => <p className='error'>{e}</p>)
            )}
        </>

    )
}

export default ResetPassword;