/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeliveryMethodS3 } from '../models/DeliveryMethodS3';
import type { InternalDeliveryMethodS3DTO } from '../models/InternalDeliveryMethodS3DTO';
import type { InternalDeliveryMethodS3DTOODataListResponse } from '../models/InternalDeliveryMethodS3DTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalDeliveryMethodS3Service {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns InternalDeliveryMethodS3DTOODataListResponse Success
     * @throws ApiError
     */
    public static getInternalDeliveryMethodS3(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<InternalDeliveryMethodS3DTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/DeliveryMethodS3',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InternalDeliveryMethodS3DTO Success
     * @throws ApiError
     */
    public static postInternalDeliveryMethodS3(
requestBody?: InternalDeliveryMethodS3DTO,
): CancelablePromise<InternalDeliveryMethodS3DTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal/DeliveryMethodS3',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns InternalDeliveryMethodS3DTO Success
     * @throws ApiError
     */
    public static getInternalDeliveryMethodS31(
key: number,
): CancelablePromise<InternalDeliveryMethodS3DTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/DeliveryMethodS3/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns InternalDeliveryMethodS3DTO Success
     * @throws ApiError
     */
    public static putInternalDeliveryMethodS3(
key: number,
requestBody?: InternalDeliveryMethodS3DTO,
): CancelablePromise<InternalDeliveryMethodS3DTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal/DeliveryMethodS3/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteInternalDeliveryMethodS3(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal/DeliveryMethodS3/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param clientId 
     * @param query 
     * @returns DeliveryMethodS3 Success
     * @throws ApiError
     */
    public static getInternalDeliveryMethodS3Query(
clientId?: number,
query?: string,
): CancelablePromise<Array<DeliveryMethodS3>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/DeliveryMethodS3/query',
            query: {
                'clientId': clientId,
                'query': query,
            },
        });
    }

}
