import React from 'react';
import { useMaterialServiceGetMaterial1, useModelPackServiceGetModelPack1, useModelServiceGetModel1, useModifierServiceGetModifier1 } from '../../../openapi/queries';
import { AssetProjectAsset } from '../../../openapi/requests';
import EditMaterial from '../materials/EditMaterial';
import EditModelpack from '../modelpacks/EditModelpack';
import EditModel from '../models/EditModel';
import EditModifier from '../modifiers/EditModifier';

const EditAssetProjectItem: React.FC<{ asset?: AssetProjectAsset, isOpen: boolean, onClose: () => void }> = ({ asset, isOpen, onClose }) => {

    const material = useMaterialServiceGetMaterial1({key: asset?.itemId ?? 0}, undefined, {enabled: (asset !== undefined && asset.assetType === "Material")});
    const model = useModelServiceGetModel1({key: asset?.itemId ?? 0}, undefined, {enabled: asset !== undefined && asset.assetType === "Model"});
    const modifier = useModifierServiceGetModifier1({key: asset?.itemId ?? 0}, undefined, {enabled: asset !== undefined && asset.assetType === "Modifier"});
    const modelPack = useModelPackServiceGetModelPack1({key: asset?.itemId ?? 0}, undefined, {enabled: asset !== undefined && asset.assetType === "Modelpack"});

    return (
        <>
            {(asset && asset.assetType === "Material") && <EditMaterial material={material.data} isOpen={isOpen} onClose={onClose} />}
            {(asset && asset.assetType === "Model") && <EditModel model={model.data} isOpen={isOpen} onClose={onClose} />}
            {(asset && asset.assetType === "Modelpack") && <EditModelpack modelpack={modelPack.data} isOpen={isOpen} onClose={onClose} />}
            {(asset && asset.assetType === "Modifier") && <EditModifier modifier={modifier.data} isOpen={isOpen} onClose={onClose} />}
        </>
    );
};

export default EditAssetProjectItem;