import { useCallback, useMemo } from "react";
import Select, { components, OptionProps, StylesConfig } from "react-select";
import DatePicker from "react-datepicker";
import "../layout/elements/Filter.scss";

export type Filter = {
    property: string;
    values: any[];
    mapFn?: (values: any) => Option;
}

export type Property = {
    property: string;
    name: string;
    type: string;
    items: any[];
    default?: any;
    mapFn?: (values: any) => Option;
}

type Props = {
    isOpen: boolean;
    filters: Filter[];
    onChange: (filters: Filter[]) => void;
    properties: Property[];  
};

type InputProps = {
    property: string;
    name: string;
    filter: Filter;
    onChange: (value: Filter) => void;
    items: any[];
    mapFn?: (values: any) => Option;
}

interface Option {
    value: string | null;
    label: string;
}

const colourStyles: StylesConfig<Option, true> = {
    control: (styles) => ({
        ...styles,
        borderRadius: '0',
        fontSize: '12px',
        minHeight: '32px'
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        padding: '0px 8px 0px 0px'
    }),
    clearIndicator: (styles) => ({
        ...styles,
        padding: '0px 0px 0px 0px'
    }),
    noOptionsMessage: (styles) => ({
        ...styles,
        fontSize: '12px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        color: (isSelected) ? '#FFF' : (isFocused) ? '#FFF' : '#000',
        backgroundColor: (isSelected) ? '#000' : (isFocused) ? 'rgba(0,0,0,.26)' : '#FFF',
        ':active': {
            ...styles[':active'],
            color: (isSelected) ? '#FFF' : (isFocused) ? '#FFF' : '#000',
            backgroundColor: (isSelected) ? '#000' : (isFocused) ? 'rgba(0,0,0,.26)' : '#FFF',
        }
    })
};

const OptionComponent = (props: OptionProps<Option>) => {
    return (
        <components.Option {...props}>{props.children}</components.Option>
    );
};

export const StatisticsFilter: React.FC<Props> = ({ properties, onChange, filters, isOpen }) => {
    
        const onFilterAdded = useCallback((filter: Filter) => {

            let filteredFilters = [...filters.filter(e => e.property !== filter.property), filter]
            if (filter.property === "projecttype") {
                filteredFilters = [...filteredFilters.filter(e => e.property !== "scene")]
            }

            onChange(filteredFilters);
        }, [filters, onChange]);
    
        const clearAll = useCallback(() => {
            onChange([]);
        }, [onChange]);
    
    /*    const onFilterRemove = useCallback((filter: Filter, value: string) => {
            onChange([...filters.filter(e => e.property !== filter.property), { ...filter, values: filter.values.filter(e => e.value !== value) }]);
        }, [filters, onChange]);*/
    
        const renderInput = useCallback((prop: Property) => {
            switch (prop.type) {
                case "select": {
                    return <StatisticsFilterInput key={prop.name}
                        name={prop.name}
                        property={prop.property}
                        onChange={onFilterAdded}
                        mapFn={prop.mapFn}
                        items={prop.items}                      
                        filter={filters.find(e => e.property === prop.property) ?? { property: prop.property, values: [] }} />
                }             
                case "daterange": {
                    return <StatisticsDateRangeInput key={prop.name}
                        name={prop.name}
                        property={prop.property}
                        onChange={onFilterAdded}
                        mapFn={prop.mapFn}
                        items={prop.items}
                        filter={filters.find(e => e.property === prop.property) ?? { property: prop.property, values: [] }} />
                }
            }
        }, [filters, onFilterAdded])
    
        const StatisticsDateRangeInput: React.FC<InputProps> = ({ name, property, onChange, items, filter, mapFn }) => {
    
            const onFromDateChange = (date: Date) => {
    
                let dateValues: string[] = [];
                if (date) {
                    dateValues = [date.getTime().toString()]
                }
    
                if (filter.values[1]) {
                    dateValues = [...dateValues, new Date(parseInt(filter.values[1])).getTime().toString()]
                }
    
                onChange({ property: property, values: dateValues });
            }
    
            const onToDateChange = (date: Date) => {
    
                let dateValues: string[] = [];
                if (date) {
                    dateValues = [date.getTime().toString()]
                }
    
                if (filter.values[0]) {
                    dateValues = [new Date(parseInt(filter.values[0])).getTime().toString(), ...dateValues]
                }
    
                onChange({ property: property, values: dateValues, mapFn: mapFn });
            }
    
            const minDate = useMemo(() => {
                return new Date(Math.min(...items.map(x => new Date(x["timestampCreate"]).getTime())))
            }, [items])
    
            const maxDate = useMemo(() => {
                return new Date(Math.max(...items.map(x => new Date(x["timestampCreate"]).getTime())))
            }, [items])
    
            const selectedFromDate = useMemo(() => {
                return (filter.values[0]) ? new Date(parseInt(filter.values[0])) : null;
            }, [filter])
    
            const selectedToDate = useMemo(() => {
                return (filter.values[1]) ? new Date(parseInt(filter.values[1])) : null;
            }, [filter])
    
            return (
                <div className='filter-input date-input'>
                    <DatePicker placeholderText="From" dateFormat={"dd.MM.yyyy"} isClearable selected={selectedFromDate} minDate={minDate} maxDate={maxDate} onChange={onFromDateChange} />
                    <DatePicker placeholderText="To" dateFormat={"dd.MM.yyyy"} disabled={filter.values.length === 0} isClearable selected={selectedToDate} minDate={minDate} maxDate={maxDate} onChange={onToDateChange} />
                </div>
            )
        }
    
        const StatisticsFilterInput: React.FC<InputProps> = ({ name, property, onChange, items, filter, mapFn }) => {
    
            const options = useMemo(() => {
                let values = items.map(x => ({value: x.value, label: x.label}));             
                values = Array.from(new Set(values));
    
                if (mapFn) {
                    return values.map(e => mapFn(e));
                }
    
                return values;
            }, [items, mapFn]);
    
            const handleChange = useCallback((options: readonly Option[] | Option) => {
          
                let values = [];                

                if (options) {
                    values = Array.isArray(options) ? options : [options];
                }

                onChange({
                    property: property,
                    values: values,
                    mapFn: mapFn
                });
            }, [onChange, property, mapFn]);
    
            return (
                <div className='filter-input'>
                    <Select  
                        isClearable                   
                        placeholder={name}                        
                        components={{ Option: OptionComponent }}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}                        
                        value={filter.values.map(e => ({value: e.value, label: e.label}))}
                        onChange={handleChange}
                        options={options}
                        styles={colourStyles}
                        controlShouldRenderValue={true}
                    />
                </div>
            )
        };
    
        return (
            <div className='filter'>
                <div className='filter-properties'>
                    {properties.map(prop =>
                        renderInput(prop)
                    )}
                </div>
    
                {/* { <div className='filter-values'>
                    {(filters.some(e => e.values.length > 0)) && <div className='clear' onClick={clearAll}>Clear all filters</div>}
                    {filters.filter(x => x.property !== "date").map(filter => filter.values.map(option => <div className='filter'>{filter.property}: {(filter.mapFn) ? filter.mapFn(option.value).label : option.label}<CloseIcon onClick={() => onFilterRemove(filter, option.value)} /> </div>))}
                </div>} */}
            </div>
        )
    
}

export default StatisticsFilter;