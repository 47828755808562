/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InternalUiscenePlacementpointHotspotDTO } from '../models/InternalUiscenePlacementpointHotspotDTO';
import type { InternalUiscenePlacementpointHotspotDTOODataListResponse } from '../models/InternalUiscenePlacementpointHotspotDTOODataListResponse';
import type { UiscenePlacementpointHotspot } from '../models/UiscenePlacementpointHotspot';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalUiscenePlacementpointHotspotService {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns InternalUiscenePlacementpointHotspotDTOODataListResponse Success
     * @throws ApiError
     */
    public static getInternalUiscenePlacementpointHotspot(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<InternalUiscenePlacementpointHotspotDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/UiscenePlacementpointHotspot',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InternalUiscenePlacementpointHotspotDTO Success
     * @throws ApiError
     */
    public static postInternalUiscenePlacementpointHotspot(
requestBody?: InternalUiscenePlacementpointHotspotDTO,
): CancelablePromise<InternalUiscenePlacementpointHotspotDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal/UiscenePlacementpointHotspot',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns InternalUiscenePlacementpointHotspotDTO Success
     * @throws ApiError
     */
    public static getInternalUiscenePlacementpointHotspot1(
key: number,
): CancelablePromise<InternalUiscenePlacementpointHotspotDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/UiscenePlacementpointHotspot/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns InternalUiscenePlacementpointHotspotDTO Success
     * @throws ApiError
     */
    public static putInternalUiscenePlacementpointHotspot(
key: number,
requestBody?: InternalUiscenePlacementpointHotspotDTO,
): CancelablePromise<InternalUiscenePlacementpointHotspotDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal/UiscenePlacementpointHotspot/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteInternalUiscenePlacementpointHotspot(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal/UiscenePlacementpointHotspot/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param clientId 
     * @param query 
     * @returns UiscenePlacementpointHotspot Success
     * @throws ApiError
     */
    public static getInternalUiscenePlacementpointHotspotQuery(
clientId?: number,
query?: string,
): CancelablePromise<Array<UiscenePlacementpointHotspot>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/UiscenePlacementpointHotspot/query',
            query: {
                'clientId': clientId,
                'query': query,
            },
        });
    }

}
