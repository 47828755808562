
import { useCallback, useContext, useMemo, useState } from 'react';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { Configuration, ModelConfiguration, MoodboardPosition, Point, SceneDesignerContext, moodboardColoumns } from '../../contexts/SceneDesignerContext';
import ModelConfigurator from './ModelConfigurator';
import './MoodboardDesigner.scss';
import ProductSelector, { Asset, MaterialAsset, ModifierAsset, PropsetAsset } from './ProductSelector';
import GridLayout from "react-grid-layout";
import { useResizeDetector } from 'react-resize-detector';

import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import SceneDesigner from './SceneDesigner';
import { AnimatePresence, motion } from 'framer-motion';
import { BASE_PATH } from '../..';
import classNames from 'classnames';
import { MaterialViewDTO, ModelPackViewDTO, ModelViewDTO, ModifierViewDTO, ScenePropsetOptionDTO } from '../../openapi/requests';
import ReactGA from "react-ga4";

const Moodboard: React.FC = () => {
    const { configuration, setConfiguration, products, selectedBrand } = useContext(SceneDesignerContext);
    const [selectedPoint, setSelectedPoint] = useState<Point | undefined>(undefined);
    const [editorOpen, setEditorOpen] = useState(false);
    const { width, ref } = useResizeDetector();
    const [hoverPoint, setHoverPoint] = useState<string | undefined>(undefined);
    const [hoverParents, setHoverParents] = useState<string[] | undefined>(undefined);

    const handleModelChange = useCallback((config: ModelConfiguration) => {
        if (config.path.length === 0) {
            setConfiguration({
                ...configuration,
                models: configuration.models.map(e => e.id === config.id ? config : e),
            });
        } else {
            let parent: ModelConfiguration | undefined = configuration.models.find(e => e.id === config.path[0]);

            for (let i = 1; i < config.path.length; i++) {
                parent = parent?.models.find(e => e.id === config.path[i]);
            }

            if (parent) {
                parent.models = parent.models.map(e => e.id === config.id ? config : e);
                setConfiguration({ ...configuration });
            }
        }

        if (selectedPoint && selectedPoint.type === 'model') {
            setSelectedPoint({ ...selectedPoint, configuration: config });
        }
    }, [configuration, selectedPoint, setConfiguration]);

    const handleProductChange = useCallback((product: Asset[]) => {
        setEditorOpen(false);
        setSelectedPoint(undefined);

        if (selectedPoint && configuration) {
            ReactGA.event('changeproduct', {type: selectedPoint.type});

            if (selectedPoint.configuration.path.length === 0) {
                if (selectedPoint.type === 'material') {

                    const placements = selectedPoint.configuration.values.map(v => ({
                        placement: v.placement,
                        parent: v.parent,
                        values: product.filter(e => e.validFor.some(e => e.name === v.placement.name)) as MaterialAsset[]
                    }));

                    const newSurface = selectedPoint.configuration.setValue(placements);
                    const newConfig: Configuration = {
                        ...configuration,
                        surfaces: [...configuration.surfaces.filter(e => e.placement.name !== selectedPoint.configuration.placement.name), newSurface]
                    };

                    setConfiguration(newConfig);
                } else if (selectedPoint.type === 'modifier') {

                    const placements = selectedPoint.configuration.values.map(v => ({
                        placement: v.placement,
                        parent: v.parent,
                        values: product.filter(e => e.validFor.some(e => e.name === v.placement.name)) as ModifierAsset[]
                    }));

                    const newModifier = selectedPoint.configuration.setValue(placements);
                    const newConfig: Configuration = {
                        ...configuration,
                        modifiers: [...configuration.modifiers.filter(e => e.placement.name !== selectedPoint.configuration.placement.name), newModifier]
                    };

                    setConfiguration(newConfig);
                } else if (selectedPoint.type === 'propset') {

                    const placements = selectedPoint.configuration.values.map(v => ({
                        placement: v.placement,
                        parent: v.parent,
                        values: product.filter(e => e.validFor.some(e => e.name === v.placement.name)) as PropsetAsset[]
                    }));

                    const newPropset = selectedPoint.configuration.setValue(placements);
                    const newConfig: Configuration = {
                        ...configuration,
                        propsets: [...configuration.propsets.filter(e => e.placement.name !== selectedPoint.configuration.placement.name), newPropset]
                    };

                    setConfiguration(newConfig);
                }
            } else {
                let parent: ModelConfiguration | undefined = configuration.models.find(e => e.id === selectedPoint.configuration.path[0]);

                for (let i = 1; i < selectedPoint.configuration.path.length; i++) {
                    parent = parent?.models.find(e => e.id === selectedPoint.configuration.path[i]);
                }

                if (parent) {
                    if (selectedPoint.type === 'material') {

                        const placements = selectedPoint.configuration.values.map(v => ({
                            placement: v.placement,
                            parent: v.parent,
                            values: product.filter(e => e.validFor.some(e => e.name === v.placement.name)) as MaterialAsset[]
                        }));
    
                        const newSurface = selectedPoint.configuration.setValue(placements);

                        parent.surfaces = parent.surfaces.map(e => e.id === selectedPoint.configuration.id ? newSurface : e);

                        setConfiguration({ ...configuration });
                    }
                }
            }
        }
    }, [configuration, selectedPoint, setConfiguration]);


    const layout = useMemo(() => {
        let result: GridLayout.Layout[] = products.map(item => ({
            i: item.configuration.id,
            x: item.configuration.moodboardPosition?.x ?? 0,
            y: item.configuration.moodboardPosition?.y ?? 0,
            w: item.configuration.moodboardPosition?.width ?? 1,
            h: item.configuration.moodboardPosition?.height ?? 1,
            static: false
        }));

        result.push({
            i: 'rendering',
            x: configuration.moodboard.previewPosition.x,
            y: configuration.moodboard.previewPosition.y,
            w: configuration.moodboard.previewPosition.width,
            h: configuration.moodboard.previewPosition.height,
            static: false,
        });

        return result;
    }, [configuration.moodboard, products]);

    const handleLayoutChange = useCallback((layout: GridLayout.Layout[]) => {

        const updatePosition = <Type extends { moodboardPosition?: MoodboardPosition; id: string; }>(item: Type): Type => {
            const position = layout.find(e => e.i === item.id);

            item.moodboardPosition = new MoodboardPosition(
                position?.x ?? 0,
                position?.y ?? 0,
                position?.w ?? 9,
                position?.h ?? 9
            );

            return item;
        }

        const updateModel = (item: ModelConfiguration): ModelConfiguration => {
            let clone = item.clone();
            updatePosition(clone);

            clone.models = clone.models.map(e => updateModel(e));
            clone.surfaces = clone.surfaces.map(e => updatePosition(e));

            return clone;
        }

        const previewPosition = layout.find(e => e.i === 'rendering');

        const config: Configuration = {
            ...configuration,
            moodboard: {
                ...configuration.moodboard,
                previewPosition: new MoodboardPosition(
                    previewPosition?.x ?? 0,
                    previewPosition?.y ?? 0,
                    previewPosition?.w ?? 9,
                    previewPosition?.h ?? 9
                )
            },
            surfaces: configuration.surfaces.map(e => updatePosition(e)),
            propsets: configuration.propsets.map(e => updatePosition(e)),
            modifiers: configuration.modifiers.map(e => updatePosition(e)),
            models: configuration.models.map(e => updateModel(e)),
        }

        setConfiguration(config);

    }, [configuration, setConfiguration]);

    const handleMouseOver = useCallback((point: Point) => {
        setHoverPoint(point.configuration.id);
        setHoverParents(point.configuration.placements.map(e => e.name));
    }, []);

    const handleMouseOut = useCallback((point: Point) => {
        setHoverPoint(undefined);
        setHoverParents(undefined);
    }, []);

    const handlePointSelect = useCallback((point: Point) => {
        ReactGA.event('click', {item: 'point', location: 'moodboard'});
        setSelectedPoint(point); 
        setEditorOpen(true);
    }, []);

    const handlePointDeselect = useCallback(() => {
        setSelectedPoint(undefined); 
        setEditorOpen(false);
    }, []);

    const isHoverParent = useCallback((item: ModifierViewDTO | ModelViewDTO | ModelPackViewDTO | MaterialViewDTO | ScenePropsetOptionDTO) => {
        if(hoverParents){
            var modelItem = item as ModelViewDTO;

            if(modelItem.placementpoints){
                if(modelItem.placementpoints.some(e => hoverParents.includes(e.name))){
                    return true;
                }
            }
            if(modelItem.surfaces){
                if(modelItem.surfaces.some(e => hoverParents.includes(e.name))){
                    return true;
                }
            }
        }
        return false;
    }, [hoverParents]);

    const handleDelete = useCallback((point: Point) => {
        if (configuration) {
            if (point.configuration.path.length === 0) {
                if (point.type === 'material') {
                    const newConfig: Configuration = {
                        ...configuration,
                        surfaces: configuration.surfaces.map(e => e.placement.name === point.configuration.placement.name ? e.clearValue() : e),
                    };
                    setConfiguration(newConfig);
                } else if (point.type === 'modifier') {
                    const newConfig: Configuration = {
                        ...configuration,
                        modifiers: configuration.modifiers.map(e => e.placement.name === point.configuration.placement.name ? e.clearValue() : e),
                    };
                    setConfiguration(newConfig);
                } else if (point.type === 'propset') {
                    const newConfig: Configuration = {
                        ...configuration,
                        propsets: configuration.propsets.map(e => e.placement.name === point.configuration.placement.name ? e.clearValue() : e),
                    };
                    setConfiguration(newConfig);
                } else if (point.type === 'model') {
                    const newConfig: Configuration = {
                        ...configuration,
                        models: configuration.models.map(e => e.placement.name === point.configuration.placement.name ? e.clearValue() : e),
                    };
                    setConfiguration(newConfig);
                }
            } else {
                let parent: ModelConfiguration | undefined = configuration.models.find(e => e.id === point.configuration.path[0]);

                for (let i = 1; i < point.configuration.path.length; i++) {
                    parent = parent?.models.find(e => e.id === point.configuration.path[i]);
                }

                if (parent) {
                    if (point.type === 'material') {
                        parent.surfaces = parent.surfaces.map(e => e.placement.name === point.configuration.placement.name ? e.clearValue() : e);
                        setConfiguration({ ...configuration });
                    }
                    if (point.type === 'model') {
                        parent.models = parent.models.map(e => e.placement.name === point.configuration.placement.name ? e.clearValue() : e);
                        setConfiguration({ ...configuration });
                    }
                }
            }
        }
    }, [configuration, setConfiguration]);

    const gridSize = (width ?? 1000) / moodboardColoumns;

    const backgroundGrid = useMemo(() => {
        const svgString = encodeURIComponent(`<svg width="${gridSize}" height="${gridSize}" viewBox="0 0 ${gridSize} ${gridSize}" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="6" y="6" width="${gridSize - 12}" height="${gridSize - 12}" stroke="#EEE" strokeWidth="0.5" rx="5" ry="5" />
        </svg>`);
        return `url("data:image/svg+xml,${svgString}")`;
    }, [gridSize]);

    return (<div className='moodboard-container' style={{ backgroundColor: configuration.moodboard.backgroundColor }}>
        <AnimatePresence>
            {selectedPoint !== undefined && <motion.div 
                className='moodboard-overlay'
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0}}
                onClick={handlePointDeselect}
                key={'overlay'} />}
        </AnimatePresence>
        <div className='moodboard-holder' key='moodboard' ref={ref}>
            <GridLayout
                cols={moodboardColoumns}
                rowHeight={gridSize - 10}
                className='moodboard'
                style={{
                    backgroundSize: gridSize,
                    backgroundImage: backgroundGrid,
                }}
                width={width ?? 1000}
                layout={layout}
                onDragStop={handleLayoutChange}
                onResizeStop={handleLayoutChange}
                containerPadding={[5, 5]}
                margin={[10, 10]}>

                <div className='rendering' key={'rendering'} style={{ backgroundColor: configuration.moodboard.backgroundColor }}>
                    <SceneDesigner 
                        selectedPoint={selectedPoint} 
                        hoveredPoint={hoverPoint}
                        onPointMouseOut={handleMouseOut}
                        onPointMouseOver={handleMouseOver}
                        onPointSelected={handlePointSelect} />
                </div>
                {products.map(item => <div
                    onPointerEnter={() => handleMouseOver(item)}
                    onPointerLeave={() => handleMouseOut(item)}
                    key={item.configuration.id}
                    className={classNames({
                        'item': true,
                        'changed': item.configuration.hasChanged,
                        'unchanged': !item.configuration.hasChanged,
                        'hover': (hoverPoint && ((item.configuration.id + ".").startsWith(hoverPoint + ".") || (hoverPoint + ".").startsWith(item.configuration.id + "."))),
                        'hover-parent': (hoverPoint && (hoverPoint + ".").startsWith(item.configuration.id + ".") && item.configuration.id !== hoverPoint),
                        'required': (item.isRequired && item.configuration.value === undefined),
                        'offbrand': item.offbrandProduct,
                    })}
                    onDoubleClick={() => handlePointSelect(item)}
                    style={{ backgroundColor: configuration.moodboard.backgroundColor }}>

                    <span className='label'>{item.configuration.id}</span>
                    <span className='name'>{item.configuration.label}</span>
                    {(item.isRequired && item.configuration.value === undefined) && <span className='empty'>!</span>}
                    {item.offbrandProduct && <span className='offbrandwarning'>Not {selectedBrand}</span>}
                    <div className={'image multiple'}>
                        {item.configuration?.value === undefined ?
                            <AddIcon /> : 
                            item.configuration.uniqueValues.map(subitem => <div>
                                    {item.type === 'propset' ? <span>{subitem.title}</span> :<img className={classNames({'hover': isHoverParent(subitem)})} key={subitem.id} src={BASE_PATH + subitem.thumbnail} alt='' /> }
                                </div>)
                        }
                    </div>
                    <div className='buttons'>
                        <button onClick={() => handlePointSelect(item)}><EditIcon /></button>
                        {(!item.isRequired && item.configuration.value !== undefined) && <button onClick={() => handleDelete(item)}><DeleteIcon /></button> }
                    </div>
                </div>)}
            </GridLayout>
        </div>

        <ModelConfigurator
            simplify
            open={editorOpen && selectedPoint?.type === 'model'}
            point={selectedPoint}
            onChange={handleModelChange}
            onClose={handlePointDeselect} />

        <ProductSelector
            open={editorOpen && selectedPoint?.type !== 'model'}
            point={selectedPoint}
            onCancel={handlePointDeselect}
            onProductSelected={handleProductChange} />

    </div>);
}

export default Moodboard;