import { Space, Button, Select, Input, Card, Flex } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useCallback, useState, useEffect, ReactNode } from "react";
import { useDebouncedCallback } from "use-debounce";
import { DeleteOutlined, PlusOutlined, GroupOutlined } from '@ant-design/icons';
import "./ExpressionEditor.scss"

export interface Expression {
    key: string
    operator: string | null
    label?: string
    equality: string | null
    value?: string | number | null
    parent: Expression | null
    type: ExpressionType
    expressions: Expression[]
}

export interface ExpressionEditorProps { 
    enabled: boolean
    expression: string | undefined
    onExpressionChange: (expression: string) => void
}

export const equalityOptions: DefaultOptionType[] = [
    { label: "=", value: "=" },
    { label: "<", value: "<" },
    { label: ">", value: ">" },
    { label: "<=", value: "<=" },
    { label: ">=", value: ">=" },
    { label: "IS NULL", value: "ISNULL" },
    { label: "IS NOT NULL", value: "ISNOTNULL" },
]

export enum ExpressionType {
    ROOT,
    GROUP,
    EXPRESSION
}

export const tokenizeExpression = (expression: string, root: Expression): Expression => {

    var expressionMatches = expression.split(/(\({[\s\S]*?}*?\))|\(({[\s\S]*?})\)|({[\s\S]*?})/gmi).filter(x => x!! && x !== "");
    var operator = null;

    if (expressionMatches) {

        root.expressions = [];

        for (var i = 0; i < expressionMatches.length; i++) {
            var expressionMatch = expressionMatches[i].trim();

            if (expressionMatch === "and" || expressionMatch === "or") {
                operator = expressionMatch;
            }

            if (expressionMatch.indexOf("(") >= 0) {

                var expressionGroup = { key: crypto.randomUUID(), type: ExpressionType.GROUP, parent: root, operator: operator, expressions: [] as Expression[] } as Expression
                expressionMatch = expressionMatch.replace(/[()]/gmi, "");

                root.expressions.push(tokenizeExpression(expressionMatch, expressionGroup))

            } else if (expressionMatch.match(/(\{[\s\S].*?\})/gmi)) {

                var match = expressionMatch.match(/(\{[\s\S].*?\})/gmi);
                if (match && match.length > 0) {
                    var equalityMatch = match[0];

                    equalityMatch = equalityMatch.replace("{", "");
                    equalityMatch = equalityMatch.replace("}", "");
                    equalityMatch = equalityMatch.replace(/\s/gmi, "");
                    var values = equalityMatch.split(/(=|<=|>=|<|>|ISNULL)/gmi);

                    let label = values[0];
                    let equality = values[1];
                    let value = values[2];

                    if (isEqualityNonValue(equality))
                    {
                        value = "";
                    }

                    root.expressions.push({ key: crypto.randomUUID(), type: ExpressionType.EXPRESSION, parent: root, operator: operator, label: label, equality: equality, value: value, expressions: [] });

                }

                operator = null;
            }
        }
    }

    return root;
}

export const serializeExpression = (root: Expression, expressionString: string) => {

    root.expressions.forEach(expression => {

        if (expression.operator) {
            expressionString += ` ${expression.operator} `
        }

        if (expression.expressions.length > 0) {
            expressionString += `(${serializeExpression(expression, "")})`
        }

        if (expression.equality) {

            if (isEqualityNonValue(expression.equality))
            {
                expression.value = "";
            }

            expressionString += `{${expression.label} ${formatEquality(expression.equality)} ${expression.value}}`
        }

    })

    return expressionString
}

export const isEqualityNonValue = (equality: string) => {
    switch (equality) {
        case "ISNULL":
        case "ISNOTNULL":
            return true;        
        default: 
            return false;   
    }
}

export const formatEquality = (operator: string) => {
    switch (operator) {
        case "ISNULL":
            return "IS NULL"
        case "ISNOTNULL":
            return "IS NOT NULL"
        default:
            return operator;
    }
}

export const ExpressionRender: React.FC<{
    enabled: boolean,
    expression: Expression,
    onExpressionDelete: (parentExpression: Expression | null) => void,
    onExpressionAdd: (expression: Expression | null, type: ExpressionType, index?: number) => void,
    onExpressionChange: (expression: Expression | null) => void
}> = ({ enabled, expression, onExpressionDelete, onExpressionAdd, onExpressionChange }) => {

    const onChange = useCallback((value: string, type: string, expression: Expression) => {

        const updatedExpression = Object.assign({}, expression);

        switch (type) {
            case "equality":
                updatedExpression.equality = value

                if (isEqualityNonValue(updatedExpression.equality))
                {
                    updatedExpression.value = "";
                }
                break;
            case "operator":
                updatedExpression.operator = value
                break;
            case "label":
                updatedExpression.label = value
                break;
            case "value":
                updatedExpression.value = serializeValue(value)
                break;
        }

        onExpressionChange(updatedExpression)
    }, [onExpressionChange]);

    const debouncedChange = useDebouncedCallback(onChange, 100);

    const serializeValue = (value: any) => {

        if (value === "")
            return value;

        let serializedValue = undefined;
        let intValue = parseInt(value);

        if (isNaN(intValue)) {
            serializedValue = `"${value}"`;
        } else {
            serializedValue = intValue
        }

        return serializedValue;
    }

    const deserializeValue = (value: any) => {

        let deserializedValue = undefined;
        if (value) {
            if (isNaN(value)) {
                deserializedValue = value.match(/\b(.*)\b/gmi)?.[0] ?? value
            } else {
                deserializedValue = value;
            }
        }
        return deserializedValue
    }

    const GroupWrapper: React.FC<{ condition: any, wrapper: any, children: ReactNode }> = ({ condition, wrapper, children }) => { return (condition ? wrapper(children) : children) }

    return (
        <>
            {expression.expressions.map((e, index) =>
                <>
                    {(e.operator !== "" && (e.operator === "and" || e.operator === "or")) ?
                        <Flex align="center" justify="center">
                            <Space.Compact>
                                <Button disabled={!enabled} type="primary" className="primary" icon={<GroupOutlined />} onClick={() => onExpressionAdd(expression, ExpressionType.GROUP, index - 1)}></Button>
                                <Button disabled={!enabled} type="primary" className="primary" icon={<PlusOutlined />} onClick={() => onExpressionAdd(expression, ExpressionType.EXPRESSION, index - 1)}></Button>
                                <Select disabled={!enabled} options={[{ label: 'AND', value: 'and' }, { label: 'OR', value: 'or' }]} defaultValue={e.operator} onChange={(value) => debouncedChange(value, "operator", e)} style={{ width: '100px', marginTop: '10px', marginBottom: '10px' }}></Select>
                            </Space.Compact>
                        </Flex> : null}
                    <GroupWrapper condition={e.type === ExpressionType.GROUP} wrapper={(children: ReactNode) => <Card size="small" extra={
                        <Space.Compact>
                            <Button disabled={!enabled} icon={<DeleteOutlined />} onClick={() => onExpressionDelete(e)}></Button>
                        </Space.Compact>} style={{ borderWidth: "4px", flex: "0 0 50%" }}>{children}</Card>}>

                        {e.equality ?

                            <Space>
                                <Space.Compact>
                                    <Input readOnly={!enabled} type="text" defaultValue={e.label} onChange={(input) => debouncedChange(input.currentTarget.value, "label", e)} />
                                    <Select disabled={!enabled} options={equalityOptions} defaultValue={e.equality} onChange={(value) => debouncedChange(value, "equality", e)} style={{ width: '100px' }}></Select>
                                    <Input  readOnly={!enabled} type="text" defaultValue={deserializeValue(e.value)} onChange={(input) => debouncedChange(input.currentTarget.value, "value", e)} />
                                </Space.Compact>
                                <Space.Compact block>
                                    <Button  disabled={!enabled} icon={<DeleteOutlined />} onClick={() => onExpressionDelete(e)}></Button>
                                </Space.Compact>
                            </Space>

                            : null}
                        <ExpressionRender enabled={enabled} expression={e} onExpressionDelete={onExpressionDelete} onExpressionAdd={onExpressionAdd} onExpressionChange={onExpressionChange} />
                        {e.type === ExpressionType.GROUP ?
                            <Flex align="center" justify="center">
                                <Space.Compact>
                                    <Button  disabled={!enabled} type="primary" className="primary" icon={<PlusOutlined />} onClick={() => onExpressionAdd(e, ExpressionType.EXPRESSION)}></Button>
                                </Space.Compact>
                            </Flex>
                            : null}
                    </GroupWrapper>
                </>
            )}
            {expression.type === ExpressionType.ROOT ?
                <Flex align="center" justify="center" gap="8">
                    <Space.Compact>
                        <Button  disabled={!enabled} type="primary" className="primary" icon={<GroupOutlined />} onClick={() => onExpressionAdd(expression, ExpressionType.GROUP)}></Button>
                        <Button  disabled={!enabled} type="primary" className="primary" icon={<PlusOutlined />} onClick={() => onExpressionAdd(expression, ExpressionType.EXPRESSION)}></Button>
                    </Space.Compact>
                </Flex>
                : null}
        </>
    )
}

export const ExpressionEditor: React.FC<ExpressionEditorProps> = ({ enabled, expression, onExpressionChange }) => {

    const [expressionRoot, setExpressionRoot] = useState<Expression>()  

    const findExpression = useCallback((expression: Expression, expressionKey: string): Expression | null => {
        if (expression.key === expressionKey) {
            return expression;
        } else if (expression.expressions != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < expression.expressions.length; i++) {
                result = findExpression(expression.expressions[i], expressionKey);
            }
            return result;
        }
        return null;
    }, [])

    const updateExpression = useCallback((root: Expression, key: string, expression: Expression): boolean => {
        if (root.key === key) {
            root = Object.assign(root, expression)
            return true;
        } else if (root.expressions != null) {
            var i;
            var isFound = false
            for (i = 0; !isFound && i < root.expressions.length; i++) {
                isFound = updateExpression(root.expressions[i], key, expression);
            }
            return isFound;
        }
        return false;
    }, [])

    const onChange = useCallback((expression: Expression | null) => {

        if (expression) {
            var updatedExpressionRoot = Object.assign({}, expressionRoot)
            if (updatedExpressionRoot) {
                updateExpression(updatedExpressionRoot, expression.key, expression)
                //setExpressionRoot(updatedExpressionRoot);
            }

            const serializedExpression = serializeExpression(updatedExpressionRoot, "")
            onExpressionChange(serializedExpression);
        }
    }, [expressionRoot, updateExpression, onExpressionChange])

    const onAdd = useCallback((expression: Expression | null, type: ExpressionType, index?: number) => {

        if (expression) {
            let updatedExpressionRoot = Object.assign({}, expressionRoot)
            if (updatedExpressionRoot) {

                let currentExpression = findExpression(updatedExpressionRoot, expression.key) as Expression

                if (currentExpression) {

                    let expressionIndex = currentExpression?.expressions.length - 1 ?? 0;

                    if (index !== undefined)
                        expressionIndex = index;

                    if (expressionIndex < 0)
                        expressionIndex = 0;

                    let operator = (currentExpression.expressions.length > 0) ? "or" : null;
                    let updatedExpressions: Expression[] = []

                    switch (type) {
                        case ExpressionType.GROUP:

                            var newGroup = { key: crypto.randomUUID(), type: ExpressionType.GROUP, parent: currentExpression, operator: operator, equality: null, expressions: [] as Expression[] }
                            newGroup.expressions.push({ key: crypto.randomUUID(), type: ExpressionType.EXPRESSION, parent: newGroup, operator: null, equality: "=", expressions: [] })

                            updatedExpressions = [
                                ...currentExpression.expressions.slice(0, expressionIndex + 1),
                                newGroup,
                                ...currentExpression.expressions.slice(expressionIndex + 1)
                            ]

                            break;
                        case ExpressionType.EXPRESSION:

                            var newExpression = { key: crypto.randomUUID(), type: ExpressionType.EXPRESSION, parent: currentExpression, operator: operator, equality: "=", expressions: [] }

                            updatedExpressions = [
                                ...currentExpression.expressions.slice(0, expressionIndex + 1),
                                newExpression,
                                ...currentExpression.expressions.slice(expressionIndex + 1)
                            ]

                            break;
                    }

                    currentExpression.expressions = updatedExpressions;
                }

                setExpressionRoot(updatedExpressionRoot);

                const serializedExpression = serializeExpression(updatedExpressionRoot, "")
                onExpressionChange(serializedExpression);
            }
        }
    }, [expressionRoot, findExpression, onExpressionChange])

    const onDelete = useCallback((expression: Expression | null) => {

        if (expression) {
            var updatedExpressionRoot = Object.assign({}, expressionRoot)
            if (updatedExpressionRoot) {

                var currentExpression = findExpression(updatedExpressionRoot, expression.key) as Expression
                var parentExpression = findExpression(updatedExpressionRoot, expression.parent?.key ?? "") as Expression

                if (currentExpression) {

                    if (parentExpression) {
                        parentExpression.expressions = parentExpression.expressions.filter(x => x.key !== currentExpression.key);
                        if (parentExpression.expressions.length > 0)
                            parentExpression.expressions[0].operator = null;

                        if (parentExpression.expressions.length === 0 && parentExpression.type === ExpressionType.GROUP) {
                            var parentParentExpression = findExpression(updatedExpressionRoot, parentExpression.parent?.key ?? "") as Expression
                            if (parentParentExpression) {
                                parentParentExpression.expressions = parentParentExpression.expressions.filter(x => x.key !== parentExpression?.key);
                            }
                        }
                    }
                }

                setExpressionRoot(updatedExpressionRoot);

                const serializedExpression = serializeExpression(updatedExpressionRoot, "")
                onExpressionChange(serializedExpression);
            }
        }
    }, [expressionRoot, findExpression, onExpressionChange])


    useEffect(() => {
        var tokenizedExpression = tokenizeExpression(expression ?? "", { key: crypto.randomUUID(), type: ExpressionType.ROOT, operator: null, equality: null, parent: null, expressions: [] })
        setExpressionRoot(tokenizedExpression)
    }, [expression])

    return (
        <div className="expression-editor">
            {expressionRoot ?
                <ExpressionRender enabled={enabled} expression={expressionRoot} onExpressionDelete={onDelete} onExpressionAdd={onAdd} onExpressionChange={onChange} />
                : null}
        </div>
    )
}