/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OkResult } from '../models/OkResult';
import type { OrderDTO } from '../models/OrderDTO';
import type { ProjectFolderEditDTO } from '../models/ProjectFolderEditDTO';
import type { ProjectFolderViewDTO } from '../models/ProjectFolderViewDTO';
import type { ProjectFolderViewDTOODataListResponse } from '../models/ProjectFolderViewDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectFolderService {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ProjectFolderViewDTOODataListResponse Success
     * @throws ApiError
     */
    public static getProjectFolder(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ProjectFolderViewDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ProjectFolder',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ProjectFolderViewDTO Success
     * @throws ApiError
     */
    public static postProjectFolder(
requestBody?: ProjectFolderEditDTO,
): CancelablePromise<ProjectFolderViewDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ProjectFolder',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ProjectFolderViewDTO Success
     * @throws ApiError
     */
    public static getProjectFolder1(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ProjectFolderViewDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ProjectFolder/{key}',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns ProjectFolderViewDTO Success
     * @throws ApiError
     */
    public static putProjectFolder(
key: number,
requestBody?: ProjectFolderEditDTO,
): CancelablePromise<ProjectFolderViewDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ProjectFolder/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteProjectFolder(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ProjectFolder/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns OrderDTO Success
     * @throws ApiError
     */
    public static getProjectFolderOrders(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<Array<OrderDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ProjectFolder/{key}/orders',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns any Success
     * @throws ApiError
     */
    public static getProjectFolderFiles(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ProjectFolder/{key}/files',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static getProjectFolderPowerpoint(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ProjectFolder/{key}/powerpoint',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static getProjectFolderKingfisherDamSheet(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ProjectFolder/{key}/kingfisherDAMSheet',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns OkResult Success
     * @throws ApiError
     */
    public static postProjectFolderUploadToKingfisherDam(
key: number,
formData?: {
file?: Blob;
},
): CancelablePromise<OkResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ProjectFolder/{key}/uploadToKingfisherDAM',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static postProjectFolderTransferToKingfisherDam(
key: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ProjectFolder/{key}/transferToKingfisherDAM',
            path: {
                'key': key,
            },
        });
    }

}
