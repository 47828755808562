import React, { useContext, useCallback } from "react";
import { BASE_PATH } from "../..";
import { CartContext } from "../../contexts/CartContext";
import { SceneDesignerContext } from "../../contexts/SceneDesignerContext";
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { SceneCameraDTO } from "../../openapi/requests";
import { AuthContext } from "../../contexts/AuthContext";

interface ImageSelectorImage {
    camera: SceneCameraDTO;
    selected: boolean;
    variants: {
        title: string;
        name: string | undefined;
        selected: boolean;
        deliveryFilenameTemplate: string;
    }[];
}

const CameraSelector: React.FC<{ showAnimatedCameras: boolean, showStillCameras: boolean }> = ({ showAnimatedCameras, showStillCameras }) => {
    const { configuration, setConfiguration, template } = useContext(SceneDesignerContext);
    const { currentProject } = useContext(CartContext);
    const { hasPermission } = useContext(AuthContext);

    const handleImageClick = useCallback((image: ImageSelectorImage) => {
        if (configuration.cameras.find(e => e.camera.cameraName === image.camera.cameraName)) {
            setConfiguration({ ...configuration, cameras: configuration.cameras.filter(e => e.camera.cameraName !== image.camera.cameraName) });
        } else {
            setConfiguration({ ...configuration, cameras: [...configuration.cameras, { camera: image.camera, Name: image.camera.cameraName, Variants: [] }] });
        }
    }, [configuration, setConfiguration]);

    const handleFilenameChange = useCallback((image: ImageSelectorImage, variant: string | undefined, value: string) => {
        let cam = configuration.cameras.find(e => e.camera.cameraName === image.camera.cameraName);

        if (cam) {
            let camVariant = cam.Variants.find(e => e.Lighting === variant);

            if (camVariant) {
                camVariant = { ...camVariant, DeliveryFilenameTemplate: value };
                cam = { ...cam, Variants: [...cam.Variants.map(e => e.Lighting === variant ? camVariant! : e)] }
            }

            setConfiguration({ ...configuration, cameras: configuration.cameras.map(e => e.camera.cameraName === image.camera.cameraName ? cam! : e) });
        }

    }, [configuration, setConfiguration]);


    const handleVariantClick = useCallback((image: ImageSelectorImage, variant: string | undefined, title: string) => {
        let cam = configuration.cameras.find(e => e.camera.cameraName === image.camera.cameraName);
        if (cam) {
            if (cam.Variants.some(e => e.Lighting === variant)) {
                cam = { ...cam, Variants: cam.Variants.filter(e => e.Lighting !== variant) };
            } else {
                cam = { ...cam, Variants: [...cam.Variants, { Lighting: variant, DeliveryFilenameTemplate: (currentProject?.name + " " + cam.camera.label + " " + title).trim() }] };
            }

            setConfiguration({ ...configuration, cameras: configuration.cameras.map(e => e.camera.cameraName === image.camera.cameraName ? cam! : e) });
        } else {
            setConfiguration({ ...configuration, cameras: [...configuration.cameras, { camera: image.camera, Name: image.camera.cameraName, Variants: [{ DeliveryFilenameTemplate: (currentProject?.name + " " + image.camera.label + " " + title).trim(), Lighting: variant }] }] });
        }
    }, [configuration, setConfiguration, currentProject]);

    if (!template) {
        return null;
    }

    let propset = template.scene.propsets.find(e => e.label.toLowerCase() === 'lighting');

    let cameras = template.scene.cameras;

    if (!showAnimatedCameras) {
        cameras = cameras.filter(e => !e.isAnimated);
    }
    if (!showStillCameras) {
        cameras = cameras.filter(e => e.isAnimated);
    }
    if(!hasPermission("RenderAnimatedCamera")){
        cameras = cameras.filter(e => !e.isAnimated);
    }

    let images: ImageSelectorImage[] = cameras.map(cam => ({
        camera: cam,
        hasLighting: propset !== undefined && propset.options.length !== 0,
        selected: configuration.cameras.some(e => e.camera.cameraName === cam.cameraName),
        variants: propset?.options.map(val => ({
            title: val.title,
            name: val.name,
            selected: configuration.cameras.find(e => e.camera.cameraName === cam.cameraName)?.Variants.some(e => e.Lighting === val.name) || false,
            deliveryFilenameTemplate: configuration.cameras.find(e => e.camera.cameraName === cam.cameraName)?.Variants.find(e => e.Lighting === val.name)?.DeliveryFilenameTemplate ?? ''
        })) ?? [{
            title: '',
            name: undefined,
            selected: configuration.cameras.find(e => e.camera.cameraName === cam.cameraName)?.Variants.some(e => e.Lighting === undefined) || false,
            deliveryFilenameTemplate: configuration.cameras.find(e => e.camera.cameraName === cam.cameraName)?.Variants.find(e => e.Lighting === undefined)?.DeliveryFilenameTemplate ?? ''
        }]
    }));

    return (

        <table>
            <colgroup>
                <col width={260} />
                <col width={150} />
                <col width={350} />
                <col />
                <col />
            </colgroup>
            <thead>
                <tr>
                    <th></th>
                    <th>Select Lighting</th>
                    <th>Render Name</th>
                    <th>Details</th>
                    <th></th>
                </tr>
                <tr>
                    <td colSpan={5} className='divider'>
                        <svg width="4000" height="2" viewBox="0 0 4000 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" d="M4000 1H0" stroke="#fff" strokeOpacity="0.6" strokeDasharray="2 2" />
                        </svg>
                    </td>
                </tr>
            </thead>
            <tbody>
                {images.map(image => <React.Fragment key={image.camera.id}>
                    <tr>
                        <td width={100}>
                            <div className={'camera' + (image.selected ? ' selected' : '')} onClick={() => handleImageClick(image)}>
                                <div className='checkmark'><CheckIcon /></div>
                                {image.camera.isAnimated ?
                                    <video src={BASE_PATH + image.camera.animationPreview} style={{ width: '100%' }} autoPlay loop muted></video> :
                                    <img src={BASE_PATH + image.camera.thumbnail} style={{
                                        aspectRatio: image.camera.aspectRatio,
                                        width: ((image.camera.aspectRatio) >= 1) ? '100%' : '',
                                        height: ((image.camera.aspectRatio) < 1) ? '100%' : ''
                                    }} alt={image.camera.label} />
                                }
                            </div>
                        </td>
                        <td className='variants'>
                            <div>
                                {image.variants.map(e => <button key={e.name} onClick={() => handleVariantClick(image, e.name, e.title)} className={'lighting' + (e.selected ? ' selected' : '')}>{e.title}</button>)}
                            </div>
                        </td>
                        <td className='filenames'>
                            <div>
                                {(!image.variants.some(e => e.selected)) ?
                                    <span className='placeholder'>You don’t have a configuration selected</span> :
                                    image.variants.map(variant => <div key={variant.name} className='deliveryFilename'>
                                        <input readOnly={!variant.selected} key={variant.name} type='text' value={variant.deliveryFilenameTemplate} placeholder='Type your image name here' onChange={e => handleFilenameChange(image, variant.name, e.target.value)} />
                                        <span className='error'>{(variant.selected && variant.deliveryFilenameTemplate === '') ? 'This field is mandatory' : null}</span>
                                    </div>)}
                            </div>
                        </td>
                        <td width={100}>
                            <label>{template.scene.title}</label>
                            {image.camera.label}
                        </td>
                        <td width={100}>
                            <label>Aspect</label>
                            {image.camera.aspectRatio === 1 && <span key='SQ'>Square</span>}
                            {image.camera.aspectRatio > 1 && <span key='LS'>Landscape</span>}
                            {image.camera.aspectRatio < 1 && <span key='PT'>Portrait</span>}

                            {image.camera.isAnimated && <>
                                <label>Duration</label>
                                <span>{(Math.floor((image.camera.animationEaseout - image.camera.animationEasein) / 25))} seconds</span>
                            </>}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={5} className='divider'>
                            <svg width="4000" height="2" viewBox="0 0 4000 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M4000 1H0" stroke="#fff" strokeOpacity="0.6" strokeDasharray="2 2" />
                            </svg>
                        </td>
                    </tr>
                </React.Fragment>)}
            </tbody>
        </table>
    )
};

export default CameraSelector;