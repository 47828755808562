import { Simulation, SimulationLinkDatum, SimulationNodeDatum, forceCollide, forceLink, forceManyBody, forceRadial, forceSimulation } from 'd3-force';
import React, { useEffect, useReducer, useState } from 'react';
import { MaterialSelection, ModelPlacement, ModifierSelection, StillImageConfiguration } from '../../../../../../models/StillImageConfiguration';
import { MaterialService, MaterialViewDTO, ModelPlacementPointDTO, ModelService, ModelSurfaceDTO, ModelViewDTO, ModifierService, ModifierViewDTO, SceneModifierTargetDTO, TemplateViewDTO } from '../../../../../../openapi/requests';
import './Graph.scss';


interface RootNode extends SimulationNodeDatum {
    models: ModelNode[];
    materials: MaterialNode[];
    modifiers: MaterialNode[];
}

interface ModelNode extends SimulationNodeDatum {
    placementPointName: string;
    placementPointTitle: string;
    modelTitle: string;
    models: ModelNode[];
    materials: MaterialNode[];
    modifiers: MaterialNode[];
}

interface MaterialNode extends SimulationNodeDatum {
    surfaceName: string;
    surfaceTitle: string;
    materialTitle: string;
}

const getGraph = (root: RootNode): { nodes: SimulationNodeDatum[], links: SimulationLinkDatum<SimulationNodeDatum>[] } => {

    let nodes: SimulationNodeDatum[] = [];
    let links: SimulationLinkDatum<SimulationNodeDatum>[] = [];

    nodes.push(root);

    const getNodes = (node: ModelNode | RootNode): SimulationNodeDatum[] => {
        var nodes = [...node.models, ...node.materials, ...node.modifiers, ...node.models.flatMap(e => getNodes(e))];
        return nodes;
    }

    nodes = nodes.concat(getNodes(root));

    let maxIndex = Math.max(...nodes.map(e => e.index || 0));

    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if(node.index === undefined){
            node.index = ++maxIndex;
        }
    }

    const getLinks = (node: ModelNode | RootNode): SimulationLinkDatum<SimulationNodeDatum>[] => {
        var links = [
            ...node.models.map(e => ({source: node.index!, target: e.index!})), 
            ...node.materials.map(e => ({source: node.index!, target: e.index!})), 
            ...node.modifiers.map(e => ({source: node.index!, target: e.index!})), 
            ...node.models.flatMap(e => getLinks(e))
        ];

        return links;
    }

    links = getLinks(root);

    return { nodes, links }
}

const Graph: React.FC<{configuration?: StillImageConfiguration, template?: TemplateViewDTO}> = ({configuration, template}) => {
    const [simulation, setSimulation] = useState<Simulation<SimulationNodeDatum, undefined> | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    
    const [models, setModels] = useState<ModelViewDTO[]>([]);
    const [materials, setMaterials] = useState<MaterialViewDTO[]>([]);
    const [modifiers, setModifiers] = useState<ModifierViewDTO[]>([]);

    const [rootNode] = useState<RootNode>({
        materials: [],
        models: [],
        modifiers: [],
        fx: 0,
        fy: 0,
    });

    useEffect(() => {
        ModelService.getModel().then(resp => setModels(resp.value));
        MaterialService.getMaterial().then(resp => setMaterials(resp.value));
        ModifierService.getModifier().then(resp => setModifiers(resp.value));
    }, []);

    useEffect(() => {
        if(template && simulation && configuration){


            const updateModelNode = (node: RootNode | ModelNode, config: {SurfaceSelections: MaterialSelection[], ModelSelections: ModelPlacement[], ModifierSelections?: ModifierSelection[], Value?: string}) => {
                node.models = node.models.filter(e => config.ModelSelections.some(p => p.Name === e.placementPointName));
                node.materials = node.materials.filter(e => config.SurfaceSelections.some(p => p.Name === e.surfaceName));
                node.modifiers = node.modifiers.filter(e => config.ModifierSelections?.some(p => p.Name === e.surfaceName));
                let modelPorts: ModelPlacementPointDTO[] = [];
                let materialPorts: ModelSurfaceDTO[] = [];
                let modifierPorts: SceneModifierTargetDTO[] = [];

                if(config.Value){
                    const nodeModel = models.find(e => e.name === config.Value);
                    if(nodeModel){
                        modelPorts = nodeModel.placementpoints;
                        materialPorts = nodeModel.surfaces;
                    }
                }else{
                    if(template.scene){
                        modelPorts = template.scene.placementpoints;
                        materialPorts = template.scene.surfaces;
                        modifierPorts = template.scene.modifierTargets;
                    }
                }


                for (let i = 0; i < config.ModelSelections.length; i++) {
                    const modelSelection = config.ModelSelections[i];
                    let model = models.find(e => e.name === modelSelection.Value);
                    
                    let modelNode = node.models.find(e => e.placementPointName === modelSelection.Name);

                    if(model){
                        if(modelNode === undefined){

                            modelNode = {
                                materials: [],
                                models: [],
                                modifiers: [],
                                placementPointName: modelSelection.Name,
                                x: (node.x || 0) * 1.1,
                                y: (node.y || 0) * 1.1,
                                modelTitle: '',
                                placementPointTitle: '',
                            };
                            node.models.push(modelNode);
                        }

                        let title = modelSelection.Name;
                        let port = modelPorts.find(e => e.name === modelSelection.Name);
                        if(port){
                            title = port.label;
                        }

                        modelNode.placementPointTitle = title;
                        modelNode.modelTitle = model.title;

                        updateModelNode(modelNode, modelSelection);
                    }else if(modelNode){
                        modelNode.modelTitle = '';
                    }
                }

                if(config.ModifierSelections){
                    for (let i = 0; i < config.ModifierSelections.length; i++) {
                        const modifierSelection = config.ModifierSelections[i];
                        let modifier = modifiers.find(e => e.name === modifierSelection.Value)
                        
                        let link = node.modifiers.find(e => e.surfaceName === modifierSelection.Name);
    
                        if(modifier){
                            if(link === undefined){
                                link = {
                                    x: (node.x || 0) * 1.1,
                                    y: (node.y || 0) * 1.1,
                                    materialTitle: '',
                                    surfaceName: modifierSelection.Name,
                                    surfaceTitle: '',
                                };
                                node.materials.push(link);
                            }
    
                            let title = modifierSelection.Name;
                            let port = modifierPorts.find(e => e.name === modifierSelection.Name);
                            if(port){
                                title = port.label;
                            }
    
                            link.surfaceTitle = title;
                            link.materialTitle = modifier.title;
                        }else if(link){
                            link.materialTitle = '';
                        }
                    }
                }

                for (let i = 0; i < config.SurfaceSelections.length; i++) {
                    const surfaceSelection = config.SurfaceSelections[i];
                    let material = materials.find(e => e.name === surfaceSelection.Value)
                    
                    let link = node.materials.find(e => e.surfaceName === surfaceSelection.Name);

                    if(material){
                        if(link === undefined){
                            link = {
                                x: (node.x || 0) * 1.1,
                                y: (node.y || 0) * 1.1,
                                materialTitle: '',
                                surfaceName: surfaceSelection.Name,
                                surfaceTitle: '',
                            };
                            node.materials.push(link);
                        }

                        let title = surfaceSelection.Name;
                        let port = materialPorts.find(e => e.name === surfaceSelection.Name);
                        if(port){
                            title = port.label;
                        }

                        link.surfaceTitle = title;
                        link.materialTitle = material.title;
                    }else if(link){
                        link.materialTitle = '';
                    }
                }
            }

            updateModelNode(rootNode, configuration);

            const {nodes, links} = getGraph(rootNode);

            simulation
                .stop()
                .alpha(1)
                .nodes(nodes)
                .force('link', forceLink(links).distance(100).strength(2));

            simulation.restart();
        }
    }, [configuration, materials, models, modifiers, rootNode, simulation, template]);

    useEffect(() => {
        const {nodes, links} = getGraph(rootNode);

        const sim = forceSimulation(nodes)
            .force('link', forceLink(links).distance(100).strength(2))
            .force('charge', forceManyBody().distanceMax(1000).strength(-40))
            .force('collide', forceCollide(50))
            .force('dagRadial', forceRadial(5000).strength(0.001))
            .on('tick', () => {
                forceUpdate();
            });

        setSimulation(sim);
    }, [rootNode]);

    const renderMaterialNode = (parent: SimulationNodeDatum, node: MaterialNode): React.ReactNode => {

        let lineStart = {x: parent.x!, y: parent.y!}
        let lineEnd = {x: node.x!, y: node.y!}
        let length = Math.sqrt(Math.pow(Math.abs(lineStart.x - lineEnd.x), 2) + Math.pow(Math.abs(lineStart.y - lineEnd.y), 2))
        let angle = Math.atan2(lineEnd.y - lineStart.y, lineStart.x - lineEnd.x) - Math.PI / 2;

        return (<React.Fragment key={node.index}>
            <div className='line-label' style={{top: (node.x! + parent.x!)/2, left: (node.y! + parent.y!)/2}}>
                <span>{node.surfaceTitle}</span>
            </div>
            <div className='node material' style={{top: node.x, left: node.y}}>
                <span>{node.materialTitle}</span>
            </div>
            <div className='line' style={{top: lineStart.x, left: lineStart.y, width: length, transform: `rotate(${angle}rad)`}}></div>
        </React.Fragment>)
    }

    const renderModelNode = (parent: SimulationNodeDatum, node: ModelNode): React.ReactNode => {

        let lineStart = {x: parent.x!, y: parent.y!}
        let lineEnd = {x: node.x!, y: node.y!}
        let length = Math.sqrt(Math.pow(Math.abs(lineStart.x - lineEnd.x), 2) + Math.pow(Math.abs(lineStart.y - lineEnd.y), 2))
        let angle = Math.atan2(lineEnd.y - lineStart.y, lineStart.x - lineEnd.x) - Math.PI / 2;

        return (<React.Fragment key={node.index}>
            <div className='node model' style={{top: node.x, left: node.y}}>
                <span>{node.modelTitle}</span>
            </div>
            <div className='line-label' style={{top: (node.x! + parent.x!)/2, left: (node.y! + parent.y!)/2}}>
                <span>{node.placementPointTitle}</span>
            </div>
            <div className='line' style={{top: lineStart.x, left: lineStart.y, width: length, transform: `rotate(${angle}rad)`}}></div>
            {node.models.map(e => renderModelNode(node, e))}
            {node.materials.map(e => renderMaterialNode(node, e))}
            {node.modifiers.map(e => renderMaterialNode(node, e))}
        </React.Fragment>)
    }

    return (
        <div className='moodboard-graph'>
            <div className='node root'>
                <span>scene</span>
            </div>
            {rootNode.models.map(e => renderModelNode(rootNode, e))}
            {rootNode.materials.map(e => renderMaterialNode(rootNode, e))}
            {rootNode.modifiers.map(e => renderMaterialNode(rootNode, e))}
        </div>
    );
}


export default Graph;