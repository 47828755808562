import { Button, Input, message, Popconfirm, Space, Table, Tag, Image } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { CheckCircleOutlined, PlusOutlined, StopOutlined } from '@ant-design/icons';
import CreateScene from './CreateScene';
import EditScene from './EditScene';
import { useDebounce } from 'use-debounce';
import { Filter } from '../../../types/types';
import { GenerateODataFilter } from '../../../helpers/odataFunctions';
import { useSceneServiceDeleteScene, useSceneServiceGetScene } from '../../../openapi/queries';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { SceneViewDTO } from '../../../openapi/requests';
import { BASE_PATH } from '../../..';
import { AssetApprovalStatus, AssetApprovalStatusLabels } from '../../../models/enums';

const SceneList: React.FC = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedScene, setSelectedScene] = useState<SceneViewDTO | undefined>(undefined);

    const [createOpen, setCreateOpen] = useState(false);

    const [query, setQuery] = useState("");
    const [debouncedQuery] = useDebounce(query, 500);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState<string>("id");
    const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");

    const filters = useMemo(() => {
        let filters: Filter[] = [];

        if (debouncedQuery) {
            filters.push({type: 'search', values: [debouncedQuery], property: 'title', name: 'title'});
        }

        return filters;
    }, [debouncedQuery]);

    const { data, isLoading, refetch } = useSceneServiceGetScene({ 
        top: pageSize.toString(), 
        skip: ((page - 1) * pageSize).toString(),
        filter: GenerateODataFilter(filters),
        orderby: `${sortField} ${sortOrder}`,
        count: 'true' })
    const {mutate, isSuccess} = useSceneServiceDeleteScene();


    const openCreate = () => {
        setCreateOpen(true);
    };

    const handleCreateClose = useCallback(() => {
        setCreateOpen(false);
        refetch();
    }, [refetch]);

    const handleEditClose = useCallback(() => {
        setSelectedScene(undefined);
        refetch();
    }, [refetch]);

    const handleTableChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<SceneViewDTO> | SorterResult<SceneViewDTO>[]) => {
        setPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);

        if(!Array.isArray(sorter)){
            setSortField(sorter.field?.toString() ?? "id");
            setSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc');
        }
    }, []);

    const handleDelete = useCallback((scene: SceneViewDTO) => {
        if(scene.id){
            mutate({key: scene.id})
        }
    }, [mutate]);

    useEffect(() => {
        if(isSuccess){
            messageApi.success("Scene deleted");
        }
    }, [isSuccess, messageApi]);

    const columns: ColumnsType<SceneViewDTO> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => (a.id || 0) - (b.id || 0),
        },
        {
            title: 'Thumbnail',
            dataIndex: 'thumbnail',
            key: 'title',
            render: (_, value) => (<Image height={100} src={BASE_PATH + value.thumbnail} />)
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => (a.title || '').localeCompare(b.title || ''),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (_, value) => (value.isEnabled ? <CheckCircleOutlined style={{color: '#389e0d'}} /> : <StopOutlined style={{color: '#cf1322'}} />)
        },
        {
            title: 'Status',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
            render: (_, value) => (AssetApprovalStatusLabels[value.approvalStatus as AssetApprovalStatus])
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (_, { tags }) => (<>{tags?.map((tag) => <Tag key={tag}>{tag}</Tag>)}</>),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => setSelectedScene(record)}>Edit</Button>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                        <Button type="link">Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <>
            {contextHolder}

            <Space style={{marginBottom: 16}}>
                <Button type="primary" onClick={openCreate} icon={<PlusOutlined />} >
                    New scene
                </Button>

                <Input placeholder='search' value={query} onChange={e => setQuery(e.target.value)} />
            </Space>

            <CreateScene isOpen={createOpen} onClose={handleCreateClose} />

            <EditScene isOpen={selectedScene != null} onClose={handleEditClose} scene={selectedScene} />

            <Table
                loading={isLoading}
                dataSource={data?.value ?? Array.from({ length: pageSize }).map(x => ({}))}
                columns={columns}
                rowKey={"id"}
                onChange={handleTableChange}
                pagination={{
                    total: (data !== undefined && data['@odata.count']) ? data['@odata.count'] : 0,
                    current: page,
                    pageSize: pageSize,
                }} />
        </>
    );
};

export default SceneList;