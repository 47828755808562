import React, { useContext, useCallback, useState, useEffect, SyntheticEvent, useMemo } from "react";
import { BASE_PATH } from "../..";
import { CartContext } from "../../contexts/CartContext";
import { SceneDesignerContext } from "../../contexts/SceneDesignerContext";
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { SceneCameraDTO } from "../../openapi/requests";
import { AnimationShot } from "../../models/StillImageConfiguration";
import { AuthContext } from "../../contexts/AuthContext";
import AnimationCreator from "./AnimationCreator";
import AnimationEditor from "./AnimationEditor";

interface AnimationSelectorImage {
    title: string;
    name: string;
    shots: (AnimationShot & {camera: SceneCameraDTO})[];
    length: number;
    selected: boolean;
    variants: {
        title: string;
        name: string | undefined;
        selected: boolean;
        deliveryFilenameTemplate: string;
    }[];
}

export const AnimationVideoPlayer: React.FC<{name: string}> = ({name}) => {
    const { configuration, template } = useContext(SceneDesignerContext);

    const videos = useMemo(() => {
        const templateAnimation = template?.scene.animations.find(e => e.name === name);
        if(templateAnimation){
            return templateAnimation.shots.map(e => BASE_PATH + template?.scene.cameras.find(c => c.id === e.cameraId)?.animationPreview);
        }

        const userAnimation = configuration.animations.find(e => e.Name === name);
        if(userAnimation){
            return userAnimation.Shots.map(e => BASE_PATH + template?.scene.cameras.find(c => c.cameraName === e.CameraName)?.animationPreview);
        }
    }, [configuration.animations, name, template?.scene.animations, template?.scene.cameras]);

    return (
        <VideoPlayer src={videos || []}/>
    );
}

export const VideoPlayer: React.FC<{src: string[], onProgress?: (index: number, progress: number) => void}> = ({src, onProgress}) => {
    const [index, setIndex] = useState(0);
    const [videos, setVideos] = useState<string[] | undefined>(undefined);

    useEffect(() => {
        if(src.length > 0){
            const promises = src.map(e => fetch(e).then(r => r.blob()));
            const objectUrls: string[] = [];

            Promise.all(promises).then(resp => {
                const _objectUrls = resp.map(e => URL.createObjectURL(e));
                _objectUrls.forEach(e => objectUrls.push(e));
                setVideos(_objectUrls);
            });
            
            return () => {
                objectUrls.forEach(e => URL.revokeObjectURL(e));
            }
        }else{
            setVideos(undefined)
        }
    }, [JSON.stringify(src)])

    const onEnded = (e: SyntheticEvent<HTMLVideoElement, Event>) => {
        if(videos){
            const newIndex = (index + 1) % videos.length;
            setIndex(newIndex);
            e.currentTarget.src = videos[newIndex];
        }
    }

    const handleTimeUpdate = useCallback((e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        const progress = (e.currentTarget.currentTime / e.currentTarget.duration);
        if(onProgress){
            onProgress(index, progress);
        }
    }, [index, onProgress]);

    if(!videos){
        return null;
    }

    return (
        <video onEnded={onEnded} src={videos[index]} onTimeUpdate={handleTimeUpdate} style={{width: '100%', aspectRatio: '1 / 1'}} autoPlay muted/>
    );
}

const AnimationSelector: React.FC = () => {
    const { configuration, setConfiguration, template } = useContext(SceneDesignerContext);
    const { currentProject } = useContext(CartContext);
    const { hasPermission } = useContext(AuthContext);
    const [showCreate, setShowCreate] = useState(false);
    const [selectedAnimation, setSelectedAnimation] = useState<string | undefined>(undefined);

    const handleFilenameChange = useCallback((image: AnimationSelectorImage, variant: string | undefined, value: string) => {
        let anim = configuration.animations.find(e => e.Name === image.name);

        if (anim) {
            anim = {...anim, Variants: anim.Variants.map(e => e.Lighting === variant ? {...e, DeliveryFilenameTemplate: value} : e)}
            setConfiguration({ ...configuration, animations: configuration.animations.map(e => (e.Name === image.name) ? anim! : e) });
        }

    }, [configuration, setConfiguration]);

    const handleVariantClick = useCallback((image: AnimationSelectorImage, variant: string | undefined, title: string) => {
        let anim = configuration.animations.find(e => e.Name === image.name);

        if (anim) {
            if(anim.Variants.some(e => e.Lighting === variant)){
                anim = {...anim, Variants: anim.Variants.filter(e => e.Lighting !== variant)}
            }else{
                anim = {...anim, Variants: [...anim.Variants, {Lighting: variant, DeliveryFilenameTemplate: (currentProject?.name + " " + image.title + " " + title)}]}
            }

            setConfiguration({ ...configuration, animations: configuration.animations.map(e => (e.Name === image.name) ? anim! : e) });
        }
    }, [configuration, setConfiguration, currentProject]);

    const handleDelete = useCallback((image: AnimationSelectorImage) => {
        setConfiguration({ ...configuration, animations: configuration.animations.filter(e => e.Name !== image.name) });
    }, [configuration, setConfiguration]);

    const handleCreateDone = useCallback((id: string) => {
        setShowCreate(false);
        setSelectedAnimation(id);
    }, []);

    const handleCreateClick = useCallback(() => {
        setShowCreate(true);
    }, []);

    const handleCreateCancel = useCallback(() => {
        setShowCreate(false);
    }, []);

    const handleEdit = useCallback((image: AnimationSelectorImage) => {
        setSelectedAnimation(image.name);
    }, []);

    const handleEditDone = useCallback(() => {
        setSelectedAnimation(undefined);
    }, []);

    if (!template) {
        return null;
    }

    let propset = template.scene.propsets.find(e => e.label.toLowerCase() === 'lighting');

    let animations = configuration.animations;

    if(!hasPermission("RenderVideo")){
        animations = [];
    }

    let images: AnimationSelectorImage[] = animations.map(animation => {
        const cameras = animation.Shots.map(e => ({
            start: e.StartFrame,
            end: e.EndFrame,
            cam: template.scene.cameras.find(c => c.cameraName === e.CameraName)
        }));

        return {
            name: animation.Name,
            title: animation.Title,
            shots: cameras.map(c => ({
                CameraName: c.cam!.cameraName,
                StartFrame: c.start,
                EndFrame: c.end,
                camera: c.cam!
            })),
            length: cameras.reduce((a, c) => a + (c.end - c.start), 0),
            hasLighting: propset !== undefined && propset.options.length !== 0,
            selected: animation.Variants.length > 0,
            variants: propset?.options.map(val => ({
                title: val.title,
                name: val.name,
                selected: animation.Variants.some(e => e.Lighting === val.name),
                deliveryFilenameTemplate: animation.Variants.find(e => e.Lighting === val.name)?.DeliveryFilenameTemplate ?? ''
            })) ?? [{
                title: '',
                name: undefined,
                selected: animation.Variants.some(e => e.Lighting === undefined),
                deliveryFilenameTemplate: animation.Variants.find(e => e.Lighting === undefined)?.DeliveryFilenameTemplate ?? ''
            }]
        }
    });

    if(showCreate) {
        return <AnimationCreator onCancel={handleCreateCancel} onCreated={handleCreateDone} />
    }

    if(selectedAnimation){
        return <AnimationEditor animationName={selectedAnimation} onClose={handleEditDone} />
    }

    return (
        <table>
            <colgroup>
                <col width={260} />
                <col width={150} />
                <col width={350} />
                <col />
                <col />
            </colgroup>
            <thead>
                <tr>
                    <th></th>
                    <th>Select Lighting</th>
                    <th>Render Name</th>
                    <th>Details</th>
                    <th></th>
                </tr>
                <tr>
                    <td colSpan={5} className='divider'>
                        <svg width="4000" height="2" viewBox="0 0 4000 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" d="M4000 1H0" stroke="#fff" strokeOpacity="0.6" strokeDasharray="2 2" />
                        </svg>
                    </td>
                </tr>
            </thead>
            <tbody>
                {images.map(image => <React.Fragment key={image.name}>
                    <tr>
                        <td width={100}>
                            <div className={'camera ' + (image.selected ? ' selected' : '')}>
                                <div className='checkmark'><CheckIcon /></div>
                                <VideoPlayer key={image.name} src={image.shots.map(e => BASE_PATH + e.camera.animationPreview)} />
                                                
                                <div className='buttons'>
                                    <button onClick={() => handleEdit(image)}><EditIcon /></button>
                                    <button onClick={() => handleDelete(image)}><DeleteIcon /></button>
                                </div>
                            </div>
                        </td>
                        <td className='variants'>
                            <div>
                                {image.variants.map(e => <button key={e.name} onClick={() => handleVariantClick(image, e.name, e.title)} className={'lighting' + (e.selected ? ' selected' : '')}>{e.title}</button>)}
                            </div>
                        </td>
                        <td className='filenames'>
                            <div>
                                {(!image.variants.some(e => e.selected)) ?
                                    <span className='placeholder'>You don’t have a configuration selected</span> :
                                    image.variants.map(variant => <div key={variant.name} className='deliveryFilename'>
                                        <input readOnly={!variant.selected} key={variant.name} type='text' value={variant.deliveryFilenameTemplate} placeholder='Type your image name here' onChange={e => handleFilenameChange(image, variant.name, e.target.value)} />
                                        <span className='error'>{(variant.selected && variant.deliveryFilenameTemplate === '') ? 'This field is mandatory' : null}</span>
                                    </div>)}
                            </div>
                        </td>
                        <td width={100}>
                            <label>{template.scene.title}</label>
                            {image.title}
                        </td>
                        <td width={100}>
                            <label>Duration</label>
                            <span>{(Math.floor(image.length / 25))} seconds</span>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={5} className='divider'>
                            <svg width="4000" height="2" viewBox="0 0 4000 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M4000 1H0" stroke="#fff" strokeOpacity="0.6" strokeDasharray="2 2" />
                            </svg>
                        </td>
                    </tr>
                </React.Fragment>)}
                <tr>
                    <td>
                        <button className="addnew camera" onClick={handleCreateClick}>
                            <AddIcon />
                            <p>Add new video</p>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    )
};

export default AnimationSelector;