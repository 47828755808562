import { Button, Card, Flex, Input, Layout, Modal, Select, Space } from "antd"
import { useAssetFilterPresetServiceGetAssetFilterPreset, useAssetFilterPresetServiceGetAssetFilterPresetKey, useAssetServiceGetAssetTypesAll } from "../../../../openapi/queries";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CheckOutlined, DeleteOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import "./FilterPresets.scss";
import { Content } from "antd/es/layout/layout";
import ScrollContainer from "react-indiana-drag-scroll";
import { ExpressionEditor } from "../../../editors/ExpressionEditor";
import { parseNested } from "../../../../helpers/jsonFunctions";
import { FilterAssetPreset, FilterAssetPresets } from "../../../../types/types";


interface FilterPresetsProps {
    selectedPreset: number,
    onApplyFilterPreset: (presetId: number) => void
    onDeleteFilterPreset: (presetId: number) => void
    onNewFilterPreset: (name: string) => void
    onUpdateFilterPreset: (presetId: number, updatedAssetFilters: FilterAssetPresets) => void
}

export const FilterPresets: React.FC<FilterPresetsProps> = ({ selectedPreset, onApplyFilterPreset, onNewFilterPreset, onDeleteFilterPreset, onUpdateFilterPreset }) => {

    const currentAssetFilters = useRef<FilterAssetPresets>({ filters: [] })

    const { data: assetTypes } = useAssetServiceGetAssetTypesAll();
    const { data: assetFilterPresets } = useAssetFilterPresetServiceGetAssetFilterPreset({}, [useAssetFilterPresetServiceGetAssetFilterPresetKey]);
    const [selectedFilterPresetId, setSelectedFilterPresetId] = useState(0);
    const [showPresetName, setShowPresetName] = useState(false);
    const [showEditPreset, setShowEditPreset] = useState(false);
    const [name, setName] = useState("")

    const mappedAssetFilterPresets = useMemo(() => {
        return assetFilterPresets?.value.map(x => ({ label: x.name, value: x.id })) ?? []
    }, [assetFilterPresets])

    useEffect(() => {
        setSelectedFilterPresetId(selectedPreset);

        let assetFilterPreset = assetFilterPresets?.value.find(x => x.id === selectedPreset);
        if (assetFilterPreset) {
            currentAssetFilters.current = parseNested(assetFilterPreset.configurationSpec);
        }
    }, [selectedPreset, assetFilterPresets]);

    const selectedFilterPreset = useMemo(() => {

        if (assetFilterPresets) {
            let assetFilterPreset = assetFilterPresets.value.find(x => x.id === selectedFilterPresetId);
            var filterAssetPresets: FilterAssetPreset[] = [];
            if (assetFilterPreset) {

                var assetFilterPresetConfig = parseNested(assetFilterPreset.configurationSpec) as FilterAssetPresets;
                assetFilterPresetConfig.filters.forEach(assetfilterPresetConfigFilter => {
                    filterAssetPresets.push(assetfilterPresetConfigFilter);
                })
                return filterAssetPresets;
            }
        }

    }, [assetFilterPresets, selectedFilterPresetId])

    const handleNewPreset = useCallback(() => {

        if (name) {
            onNewFilterPreset(name);
            setShowPresetName(false);
        }

    }, [onNewFilterPreset, name]);

    const handleUpdatePreset = useCallback((presetId: number) => {
        if (currentAssetFilters.current) {
            onUpdateFilterPreset(presetId, currentAssetFilters.current)
            setShowEditPreset(false)
        }

    }, [onUpdateFilterPreset]);

    const onFilterChange = (assetType: string, expression: string) => {

       
        let updateAssetFilter = currentAssetFilters.current?.filters.find(x => x.type === assetType);
        if (updateAssetFilter) {
            updateAssetFilter.filter = expression;
        } else {
            updateAssetFilter = { type: assetType, filter: expression }
        }

        currentAssetFilters.current = { filters: [...currentAssetFilters.current.filters.filter(x => x.type !== updateAssetFilter?.type), updateAssetFilter] };
    
    }

    return (
        <div className="filter-presets">
            <div>
                <label>Filterpreset:</label>
                <Space.Compact>
                    <Select className="filter-preset-select" value={selectedFilterPresetId} options={mappedAssetFilterPresets} onChange={(value, option) => setSelectedFilterPresetId(value)} />
                    <Button title="Apply" onClick={() => onApplyFilterPreset(selectedFilterPresetId)} icon={<CheckOutlined />} style={{ width: "4em" }}></Button>
                    <Button title="New" onClick={() => setShowPresetName(true)} icon={<PlusOutlined />} style={{ width: "4em" }}></Button>
                    <Button disabled={selectedFilterPresetId === undefined} title="Edit" onClick={() => setShowEditPreset(true)} icon={<EditOutlined />} style={{ width: "4em" }}></Button>
                    <Button title="Delete" onClick={() => onDeleteFilterPreset(selectedFilterPresetId)} icon={<DeleteOutlined />} style={{ width: "4em" }}></Button>
                </Space.Compact>

            </div>
            <Modal open={showPresetName} title="Preset Name" onOk={() => handleNewPreset()} onCancel={() => setShowPresetName(false)}>
                <Input title="Name" onChange={(e) => setName(e.currentTarget.value)} required />
            </Modal>
            <Modal open={showEditPreset} title="Edit Preset" onCancel={() => setShowEditPreset(false)} width={"100%"} style={{ minWidth: "600px", maxWidth: "1200px" }} onOk={() => handleUpdatePreset(selectedFilterPresetId)}>
                <Layout>
                    <Content>
                        <Flex gap={8} vertical>
                            {assetTypes?.map(assetType =>
                                <Card title={assetType} headStyle={{ backgroundColor: "rgba(0, 0, 0, 0.45)", color: "#FFF" }}>
                                    <ScrollContainer className="scroll-container" buttons={[1, 2]} hideScrollbars={false}>
                                        <ExpressionEditor enabled={true} expression={selectedFilterPreset?.find(x => x.type.toLowerCase() === assetType.toLowerCase())?.filter ?? ""} onExpressionChange={(e) => onFilterChange(assetType, e)} />
                                    </ScrollContainer>
                                </Card>
                            )}
                        </Flex>
                    </Content>
                </Layout>
            </Modal>
        </div>
    )

}