import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CartContext } from '../../contexts/CartContext';
import './Submit.scss';
import Drawer from '../layout/SideDrawer';
import { SceneDesignerContext } from '../../contexts/SceneDesignerContext';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as AnimationIcon } from '../../assets/icons/animation.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { Dialog } from '../layout/Dialog';
import { useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan } from '../../openapi/queries';
import { BASE_PATH } from '../..';
import { AuthContext } from '../../contexts/AuthContext';
import { GenerateODataFilter } from '../../helpers/odataFunctions';
import { ConfigContext } from '../../contexts/ConfigContext';
import { VideoPlayer } from '../designer/AnimationSelector';
import ReactGA from "react-ga4";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const Submit: React.FC<Props> = ({ isOpen, onClose }) => {
    const { userClient } = useContext(ConfigContext);
    const { hasPermission } = useContext(AuthContext);
    const { data: stillImageSubmissionPlans, isLoading: stillImageSubmissionPlansLoading } = useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan({ filter: GenerateODataFilter([{ name: 'client', property: 'clientid', values: [userClient.id], type: 'select' }]) });

    const { submitRendering, submitPreview, currentOrder, orderlineCount } = useContext(CartContext);
    const { batchProducts, configuration, setConfiguration, template } = useContext(SceneDesignerContext);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState<{ cam: string, variant: string | undefined }[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);    
    
    useEffect(() => {
        if(isOpen){
            ReactGA.event('open_dialog', {dialog: 'submitrender'});
        }
    }, [isOpen]);

    useEffect(() => {
        setDeleting([]);
    }, [currentOrder])

    const handleRenderingSubmit = useCallback(() => {
        const selectedPlan = stillImageSubmissionPlans?.value.find(e => e.name?.toLowerCase().includes("final"));
        if (selectedPlan) {
            setLoading(true);
            ReactGA.event('submit_render', {type: 'final'});
            submitRendering(selectedPlan).then(() => {
                setLoading(false);
                onClose();
            });
        } else {
            console.error("no suitable plan found");
        }
    }, [onClose, stillImageSubmissionPlans, submitRendering]);

    const handlePrioritySubmit = useCallback(() => {
        const selectedPlan = stillImageSubmissionPlans?.value.find(e => e.name?.toLowerCase().includes("priority"));
        if (selectedPlan) {
            setLoading(true);
            ReactGA.event('submit_render', {type: 'priority'});
            submitRendering(selectedPlan).then(() => {
                setLoading(false);
                onClose();
            });
        } else {
            console.error("no suitable plan found");
        }
    }, [onClose, stillImageSubmissionPlans, submitRendering]);


    const doSubmitPreview = useCallback((limitImageCount: boolean) => {
        const selectedPlan = stillImageSubmissionPlans?.value.find(e => e.name?.toLowerCase().includes("preview"));
        if (selectedPlan) {
            setLoading(true);
            ReactGA.event('submit_render', {type: 'preview'});
            submitPreview(selectedPlan, limitImageCount).then(() => {
                setLoading(false);
                onClose();
            });
        } else {
            console.error("no suitable plan found");
        }
    }, [onClose, stillImageSubmissionPlans, submitPreview]);

    const handlePreviewSubmit = useCallback(() => {
        if (batchProducts.length > 0) {
            setDialogOpen(true);
        } else {
            doSubmitPreview(false);
        }
    }, [doSubmitPreview, batchProducts]);

    const handleDeleteImage = useCallback((cam: string, variant: string | undefined) => {
        let cameras = [...configuration.cameras];

        cameras = cameras.map(e => e.camera.cameraName === cam ? { ...e, Variants: e.Variants.filter(v => v.Lighting !== variant) } : e)

        setDeleting([...deleting, { cam, variant }]);

        setConfiguration({
            ...configuration,
            cameras
        })
    }, [configuration, deleting, setConfiguration]);

    const handleDeleteAnimation = useCallback((anim: string, variant: string | undefined) => {
        let animations = [...configuration.animations];

        animations = animations.map(e => e.Name === anim ? { ...e, Variants: e.Variants.filter(v => v.Lighting !== variant) } : e)

        setDeleting([...deleting, { cam: anim, variant }]);

        setConfiguration({
            ...configuration,
            animations
        })
    }, [configuration, deleting, setConfiguration]);

    const noImages = (configuration.cameras.length === 0 || configuration.cameras.every(e => e.Variants.length === 0)) && configuration.animations.length === 0;
    const missingFilenames = (configuration.cameras.some(e => e.Variants.some(e => e.DeliveryFilenameTemplate === ''))) && configuration.animations.some(e => e.Variants.some(e => e.DeliveryFilenameTemplate === ''));


    const images = configuration.cameras.flatMap(cam => cam.Variants.map(img => <motion.div
        layout
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={cam.Name + img.Lighting}
        className={'image' + (deleting.some(e => e.cam === cam.Name && e.variant === img.Lighting) ? ' deleting' : '')}>

        <div className='image-holder'>
            {cam.camera.isAnimated ?
                <>
                    <AnimationIcon />
                    <video src={BASE_PATH + cam.camera.animationPreview} loop autoPlay muted style={{
                        aspectRatio: cam.camera.aspectRatio,
                        width: ((cam.camera.aspectRatio) >= 1) ? '100%' : '',
                        height: ((cam.camera.aspectRatio) < 1) ? '100%' : ''
                    }} />
                </> :
                <>
                    <img src={BASE_PATH + cam.camera.thumbnail} alt='' style={{
                        aspectRatio: cam.camera.aspectRatio,
                        width: ((cam.camera.aspectRatio) >= 1) ? '100%' : '',
                        height: ((cam.camera.aspectRatio) < 1) ? '100%' : ''
                    }} />
                </>
            }
        </div>
        <p>
            {img.DeliveryFilenameTemplate}
            {img.DeliveryFilenameTemplate === "" && <span className='error'>No name</span>}
        </p>

        <button onClick={() => handleDeleteImage(cam.Name, img.Lighting)}>
            <DeleteIcon />
        </button>
    </motion.div>));

    const animations = configuration.animations.flatMap(animation => animation.Variants.map(variant => {

        const cameras = animation.Shots.map(e => template?.scene.cameras.find(c => c.cameraName === e.CameraName)).filter(e => e !== undefined);

        return (<motion.div
            layout
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key={animation.Name + variant.Lighting}
            className={'image' + (deleting.some(e => e.cam === animation.Name && e.variant === variant.Lighting) ? ' deleting' : '')}>

            <div className='image-holder'>
                <AnimationIcon />
                <VideoPlayer src={cameras.map(e => BASE_PATH + e!.animationPreview)} />
            </div>
            <p>
                {variant.DeliveryFilenameTemplate}
                {variant.DeliveryFilenameTemplate === "" && <span className='error'>No name</span>}
            </p>

            <button onClick={() => handleDeleteAnimation(animation.Name, variant.Lighting)}>
                <DeleteIcon />
            </button>
        </motion.div>)
    }));

    const disabled = loading || noImages || missingFilenames || stillImageSubmissionPlansLoading;

    return (
        <>
            <Dialog open={dialogOpen} className='confirm'>
                <h1>Large order</h1>
                <p className='confirm-body'>
                    You are about to submit a preview render with {orderlineCount} images, this might take a while to complete.<br /><br />
                    Would you like to limit the order to 1 image per camera angle to save time?
                </p>

                <div className='confirm-footer'>
                    <button className='confirm' onClick={() => { doSubmitPreview(true); setDialogOpen(false) }}>Limit order</button>
                    <button onClick={() => { doSubmitPreview(false); setDialogOpen(false) }}>Submit all images</button>
                    <button onClick={() => setDialogOpen(false)}>Cancel</button>
                </div>
            </Dialog>
            <Drawer className='submit' open={isOpen} onClose={onClose} title='Render details'>
                <div className='details'>
                    <AnimatePresence mode='popLayout'>
                        {images}
                        {animations}
                    </AnimatePresence>
                    {noImages && <h2 className='error'>No images selected</h2>}
                </div>

                {batchProducts.length > 0 && <div>
                    You are about to render a batch order, with a total image count of {orderlineCount}
                </div>}

                {!(hasPermission("PreviewRender") || hasPermission("FinalRender") || hasPermission("PriorityRender")) && <button className='preview' disabled={disabled} onClick={onClose}>Save</button>}
                {hasPermission("PreviewRender") && <button className='preview' disabled={disabled} onClick={handlePreviewSubmit}>Preview render</button>}
                {hasPermission("FinalRender") && <button className='render' disabled={disabled} onClick={handleRenderingSubmit}>Final render</button>}
                {hasPermission("PriorityRender") && <button className='render' disabled={disabled} onClick={handlePrioritySubmit}>Priority render</button>}
            </Drawer>
        </>
    );
};

export default Submit;