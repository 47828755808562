/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChartDTOODataListResponse } from '../models/ChartDTOODataListResponse';
import type { ProjectType } from '../models/ProjectType';
import type { RenderType } from '../models/RenderType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatisticsService {

    /**
     * @param startDate 
     * @param endDate 
     * @param modelId 
     * @param userId 
     * @param clientId 
     * @param dateInterval 
     * @param sceneId 
     * @param renderType 
     * @param projectType 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ChartDTOODataListResponse Success
     * @throws ApiError
     */
    public static getStatistics(
startDate?: string,
endDate?: string,
modelId?: string,
userId?: number,
clientId?: number,
dateInterval?: string,
sceneId?: number,
renderType?: RenderType,
projectType?: ProjectType,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ChartDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Statistics',
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'modelId': modelId,
                'userId': userId,
                'clientId': clientId,
                'dateInterval': dateInterval,
                'sceneId': sceneId,
                'renderType': renderType,
                'projectType': projectType,
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

}
