import { DiagramEngine } from '@projectstorm/react-diagrams';
import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { MaterialPortModel } from './MaterialPort';
import { ConfiguratorNodeModel, ConfiguratorNodeWidget } from './ConfiguratorNode';
import { ConfiguratorPortModel } from './ConfiguratorPort';
import { MaterialService, MaterialViewDTO } from '../../../../../openapi/requests';


export class MaterialNodeModel extends ConfiguratorNodeModel<MaterialViewDTO> {

    constructor(updateCallback: () => void, onPortSelected: (port: ConfiguratorPortModel) => void) {
        super('material', updateCallback);
		this.addPort(new MaterialPortModel(false, 'out', 'out', onPortSelected, ''));
    }

	async loadData(materialID: number) {
        const resp = await MaterialService.getMaterial1(materialID);
		this.data = resp;
		this.updateCallback();
	}
}

export class MaterialNodeFactory extends AbstractReactFactory<MaterialNodeModel, DiagramEngine> {
    constructor() {
        super('material');
    }

    generateReactWidget(event: GenerateWidgetEvent<MaterialNodeModel>): JSX.Element {
        return <ConfiguratorNodeWidget engine={this.engine} node={event.model} />;
    }

    generateModel(event : GenerateModelEvent) {
        return new MaterialNodeModel(() => {}, () => {});
    }
}