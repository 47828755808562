import { Card, Checkbox, Drawer, Input, message, Select, Space, Tooltip } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import NodeEditor from './configuration/nodeEditor/NodeEditor';
import Moodboard from './configuration/moodboard/Moodboard';
import { AuthContext } from '../../../contexts/AuthContext';
import { useClientServiceGetClient, useDeliveryConfigurationServiceGetDeliveryConfiguration, useFilenameTokenServiceGetFilenameToken, useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan, useTemplateServiceGetTemplate } from '../../../openapi/queries';
import { OrderDTO, OrderlineEditDTO, OrderlineService, OrderlineViewDTO, OrderService, TemplateViewDTO } from '../../../openapi/requests';
import { OrderStatus } from '../../../models/enums';

interface FormData extends OrderDTO {
    lines: OrderlineViewDTO[];
}

const ConfigurationInput: React.FC<{ templates: TemplateViewDTO[], value?: OrderlineEditDTO, onChange?: (value: OrderlineEditDTO) => void }> = ({ onChange, templates, value }) => {
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [isMoodboardOpen, setIsMoodboardOpen] = useState(false);

    const showEditor = () => {
        setIsEditorOpen(true);
    };

    const showMoodboard = () => {
        setIsMoodboardOpen(true);
    };

    const onConfigurationChanged = useCallback((configuration: string) => {
        if (value) {
            onChange?.({ ...value, configurationSpec: configuration });
        }
        setIsEditorOpen(false);
        setIsMoodboardOpen(false);
    }, [onChange, value]);

    const template = useMemo(() => templates.find(e => e.id === value?.templateId), [templates, value?.templateId]);

    useEffect(() => {
        if (template == null || (template.editorModule !== "generic_3d_scene" && template.editorModule !== "cutout")) {
            if (value && value.configurationSpec !== "") {
                onChange?.({ ...value, configurationSpec: "" });
            }
        }
    }, [template, onChange, value]);

    if (template === undefined || (template.editorModule !== "generic_3d_scene" && template.editorModule !== "cutout")) {
        return null;
    }

    return (
        <>
            <Button disabled={!value?.templateId} onClick={showEditor}>Configure</Button>
            <Button disabled={!value?.templateId} onClick={showMoodboard}>Moodboard</Button>
            <NodeEditor isOpen={isEditorOpen} onSave={onConfigurationChanged} configuration={value?.configurationSpec} template={template} />
            <Moodboard isOpen={isMoodboardOpen} onSave={onConfigurationChanged} configuration={value?.configurationSpec} template={template} />
        </>
    );
}

const PostprocessingInput: React.FC<{ templates: TemplateViewDTO[], value?: OrderlineViewDTO, onChange?: (value: OrderlineViewDTO) => void }> = ({ onChange, templates, value }) => {
    const template = useMemo(() => templates.find(e => e.id === value?.templateId), [templates, value?.templateId]);
    const features = useMemo(() => template?.postProcessingFeatures || [], [template]);
    const options = features.map(e => ({ label: e.name, value: e.id }));

    const onChanged = useCallback((values: number[]) => {
        if (value && onChange) {
            const ids = values as number[];
            const processes = features.filter(e => ids.includes(e.id));
            onChange?.({ ...value, orderlinePostProcesses:  processes});
        }
    }, [features, onChange, value]);

    useEffect(() => {
        if (value) {
            if (value.orderlinePostProcesses.some(e => !options.some(x => x.value === e.id))) {
                onChange?.({ ...value, orderlinePostProcesses: value.orderlinePostProcesses.filter(e => options.some(x => x.value === e.id)) });
            }
        }
    }, [onChange, options, value]);

    return (
        <Checkbox.Group value={value?.orderlinePostProcesses.map(e => e.id)} options={options} onChange={onChanged} />
    );
}

const NewOrder: React.FC<{ isOpen: boolean, onClose: () => void, cloneFrom?: OrderDTO, order?: OrderDTO }> = ({ isOpen, onClose, cloneFrom, order }) => {

    const {data: clients} = useClientServiceGetClient({}, undefined, {enabled: isOpen});
    const {data: filenameTokens} = useFilenameTokenServiceGetFilenameToken({}, undefined, {enabled: isOpen});
    const {data: deliveries} = useDeliveryConfigurationServiceGetDeliveryConfiguration({}, undefined, {enabled: isOpen});
    const {data: templates} = useTemplateServiceGetTemplate({expand: 'scene'}, undefined, {enabled: isOpen});
    const {data: submissionPlans} = useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan({}, undefined, {enabled: isOpen});

    const [form] = Form.useForm<FormData>();
    const [messageApi, contextHolder] = message.useMessage();
    const [working, setWorking] = useState(false);
    const { hasRole } = useContext(AuthContext);

    useEffect(() => {
        if(isOpen && clients && clients.value.length === 1){
            form.setFieldValue("clientId", clients.value[0].id);
        }
        if(isOpen && deliveries && deliveries.value.length === 1){
            form.setFieldValue("deliveryconfigurationId", deliveries.value[0].id);
        }
    }, [clients, deliveries, form, isOpen]);

    const onSubmit = () => {
        setWorking(true);

        const values = form.getFieldsValue(true) as FormData;

        const lines: OrderlineViewDTO[] = values.lines;

        const orderDto: OrderDTO = {
            clientId: values.clientId,
            deliveryconfigurationId: values.deliveryconfigurationId,
            notificationRecipients: values.notificationRecipients,
            orderReference: values.orderReference,
            id: 0,
            status: OrderStatus.Draft,
            orderedByUserId: 0,
            timestampCreate: "1900-01-01"
        }

        let promise: Promise<OrderDTO>;

        if(values.id){
            promise = OrderService.putOrder(values.id, orderDto);
        }else{
            promise = OrderService.postOrder({...orderDto, orderReference: '', notificationRecipients: ''});
        }

        promise
            .then(order => {
                const orderId = order.id;
                const promises: Promise<any>[] = [];

                for (let i = 0; i < lines.length; i++) {
                    const line = lines[i];

                    if(line.id){
                        promises.push(OrderlineService.putOrderOrderline(orderId, line.id, line));
                    }else{
                        promises.push(OrderlineService.postOrderOrderline(orderId, line));
                    }
                }

                Promise.all(promises).then(() => {
                    if(values.id){
                        messageApi.success("Order updated");
                    }else{
                        messageApi.success("Order created");
                    }
                    setWorking(false);
                    onClose();
                }).catch(reason => {
                    messageApi.error(JSON.stringify(reason));
                    setWorking(false);
                });
            })
            .catch(reason => {
                messageApi.error(JSON.stringify(reason));
                setWorking(false);
            });
    };

    useEffect(() => {
        if (cloneFrom) {
            setWorking(true);
            form.resetFields();

            OrderlineService.getOrderOrderline(cloneFrom.id).then(linesResult => {
                const values: FormData = {
                    ...cloneFrom,
                    id: 0,
                    lines: linesResult.map(e => ({ ...e, id: 0})),
                };

                setWorking(false);
                form.setFieldsValue(values);
            });
        }
    }, [cloneFrom, form]);

    useEffect(() => {
        if (order) {
            setWorking(true);
            form.resetFields();

            OrderlineService.getOrderOrderline(order.id).then(linesResult => {
                const values: FormData = {
                    ...order,
                    lines: linesResult.map(e => ({ ...e})),
                };

                setWorking(false);
                form.setFieldsValue(values);
            });
        }
    }, [form, order])

    return (
        <>
            {contextHolder}

            <Drawer
                title={order === undefined ? "Create a new order" : "Edit order"}
                width={720}
                onClose={onClose}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={working}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout='vertical'>

                    <Form.Item name='clientId' label="Client" rules={[{ required: true }]}>
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} disabled={!hasRole("Cadesign")}  />
                    </Form.Item>

                    <Form.Item name='deliveryconfigurationId' label="Delivery" rules={[{ required: true }]}>
                        <Select options={deliveries?.value.map(e => ({ label: e.title, value: e.id }))} />
                    </Form.Item>


                    <Form.Item label='Lines'>
                        <Form.List name="lines">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Card size='small' title={<div style={{display: 'flex', alignItems: 'baseline', justifyContent: 'space-between'}}>{name + 1} <DeleteOutlined onClick={() => remove(name)} /></div>} key={key} style={{ marginBottom: 16 }}>

                                            <Form.Item
                                                label="Template"
                                                {...restField}
                                                name={[name, 'templateId']}
                                                rules={[{ required: true }]}
                                            >
                                                <Select options={templates?.value.map(e => ({ label: e.title, value: e.id }))} />
                                            </Form.Item>
                                            <Form.Item shouldUpdate name={[name]}>
                                                <ConfigurationInput templates={templates?.value ?? []} />
                                            </Form.Item>

                                            <Form.Item
                                                label="Filename template"
                                                {...restField}
                                                name={[name, 'deliveryFilenameTemplate']}
                                                style={{marginBottom: 0}}
                                                rules={[{ required: true }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Space.Compact block style={{marginBottom: 16}}>
                                                {filenameTokens?.value.map(token => <Tooltip title={token.description}><Button onClick={() => 
                                                    form.setFieldValue(['lines', name, 'deliveryFilenameTemplate'], (form.getFieldValue(['lines', name, 'deliveryFilenameTemplate']) || '') + '{{' + token.token + '}}')
                                                }>{token.title}</Button></Tooltip>)}
                                            </Space.Compact>

                                            <Space>
                                                <Form.Item
                                                    label="Width"
                                                    {...restField}
                                                    name={[name, 'stillImageSetting', 'width']}
                                                    rules={[{ required: true }]}
                                                    style={{width: 150}}
                                                >
                                                    <Input addonAfter="px" type="number" />
                                                </Form.Item>

                                                <Form.Item
                                                    label="Height"
                                                    {...restField}
                                                    name={[name, 'stillImageSetting', 'height']}
                                                    rules={[{ required: true }]}
                                                    style={{width: 150}}
                                                >
                                                    <Input addonAfter="px" type="number" />
                                                </Form.Item>

                                                <Form.Item
                                                    label="File format"
                                                    {...restField}
                                                    name={[name, 'stillImageSetting', 'fileformat']}
                                                    rules={[{ required: true }]}
                                                    style={{width: 100}}
                                                >
                                                    <Select options={[{label: 'png', value: 'png'}, {label: 'jpg', value: 'jpg'}]} />
                                                </Form.Item>



                                                <Form.Item
                                                    label="Plan"
                                                    {...restField}
                                                    name={[name, 'stillImageSetting', 'stillimagesubmissionplanId']}
                                                    rules={[{ required: true }]}
                                                    style={{width: 100}}
                                                >
                                                    <Select options={submissionPlans?.value.map(e => ({label: e.title, value: e.id}))} />
                                                </Form.Item>
                                            </Space>

                                            <Form.Item label="Post processing" shouldUpdate name={[name]}>
                                                <PostprocessingInput templates={templates?.value ?? []} />
                                            </Form.Item>
                                        </Card>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => {
                                                const newline: OrderlineEditDTO = {
                                                    templateId: 0,
                                                    isPreview: false,
                                                    deliveryFilenameTemplate: '',
                                                    requestManualProcess: false,
                                                    configurationSpec: '',
                                                    armodelSetting: {
                                                        createGlb: false,
                                                        createUsdz: false,
                                                    },
                                                    spin360Setting: {
                                                        backgroundcolor: 'ffffff',
                                                        fileformat: 'png',
                                                        frames: 36,
                                                        width: 800,
                                                        height: 600,
                                                    },
                                                    videoSetting: {
                                                        codec: '',
                                                        width: 800,
                                                        height: 600,
                                                    },
                                                    stillImageSetting: {
                                                        width: 800, 
                                                        height: 600, 
                                                        fileformat: 'png', 
                                                        backgroundcolor: 'ffffff',
                                                    },
                                                    orderlinePostProcesses: [],
                                                }
                                                add(newline)
                                            }} block icon={<PlusOutlined />}>
                                            Add line
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Form>
            </Drawer>
        </>

    );
};

export default NewOrder;