import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppstoreAddOutlined, BarChartOutlined, BorderOutlined, BuildOutlined, CloseCircleOutlined, CloudUploadOutlined, FolderOpenOutlined, InboxOutlined, PictureOutlined, ScanOutlined, ShopOutlined, ShoppingCartOutlined, SnippetsOutlined, TableOutlined, ToolOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu, Space, theme } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import './AdminApp.css';
import { saveAs } from 'file-saver';
import { BASE_PATH } from '.';
import ImportKingfisherAssets from './components/admin/tools/ImportKingfisherMetadata';
import ExportKingfisherProjects from './components/admin/tools/ExportKingfisherProjects';
const { Content, Sider } = Layout;

type MenuItem = {
    url: string;
    icon: JSX.Element;
    label: string;
}

const AdminApp: React.FC = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const { hasRole, hasPermission, logOut, username } = useContext(AuthContext);
    const [importOpen, setImportOpen] = useState(false);
    const [exportProjectsOpen, setExportProjectsOpen] = useState(false);

    const items: MenuItem[] = useMemo(() => {
        const menuItems: MenuItem[] = [];

        if (hasPermission('ViewOrders')) {
            menuItems.push({
                icon: <InboxOutlined />,
                url: '/admin/orders',
                label: 'Orders'
            });
            menuItems.push({
                icon: <ShoppingCartOutlined />,
                url: '/admin/projects',
                label: 'Projects'
            });
        }

        if (hasRole('Cadesign')) {
            menuItems.push({
                icon: <ShopOutlined />,
                url: '/admin/clients',
                label: 'Clients'
            });
            menuItems.push({
                icon: <CloudUploadOutlined />,
                url: '/admin/deliveryConfigurations',
                label: 'Delivery configurations'
            });
            menuItems.push({
                icon: <BorderOutlined />,
                url: '/admin/materials',
                label: 'Materials'
            });
            menuItems.push({
                icon: <BuildOutlined />,
                url: '/admin/models',
                label: 'Models'
            });
            menuItems.push({
                icon: <FolderOpenOutlined />,
                url: '/admin/modelpacks',
                label: 'Modelpacks'
            });
            menuItems.push({
                icon: <ScanOutlined />,
                url: '/admin/modifiers',
                label: 'Modifiers'
            });
            menuItems.push({
                icon: <PictureOutlined />,
                url: '/admin/scenes',
                label: 'Scenes'
            });
            menuItems.push({
                icon: <SnippetsOutlined />,
                url: '/admin/templates',
                label: 'Templates'
            });
            menuItems.push({
                icon: <AppstoreAddOutlined />,
                url: '/admin/assetprojects',
                label: 'Asset projects'
            });
        }

        if (hasPermission('UserAdmin')) {
            menuItems.push({
                icon: <UserOutlined />,
                url: '/admin/users',
                label: 'Users'
            });
        }

        if (hasPermission('ViewStatistics')) {
            menuItems.push({
                icon: <BarChartOutlined />,
                url: '/admin/statistics',
                label: 'Statistics'
            });
        }

        return menuItems;
    }, [hasPermission, hasRole]);

    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState(items.find(_item => location.pathname.startsWith(_item.url))?.url ?? "");


    useEffect(() => {
        setSelectedKey(items.find(_item => location.pathname.startsWith(_item.url))?.url ?? "")
    }, [items, location])

    const exportAssets = useCallback(() => {
        const token = localStorage.getItem('accessToken');

        fetch(`${BASE_PATH}/Export/kingfisher/assets`, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob, "export.xlsx");
        });
    }, []);

    return (
        <Layout style={{ minHeight: '100vh' }} hasSider id='adminapp'>
            <Sider width={200} style={{ background: colorBgContainer }}>
                <Menu
                    theme='dark'
                    mode="inline"
                    items={items.map(e => ({
                        key: e.url,
                        icon: e.icon,
                        label: <Link to={e.url}>{e.label}</Link>
                    }))}
                    selectedKeys={[selectedKey]}
                    style={{ height: '100%', borderRight: 0 }} />
            </Sider>
            <Layout style={{ alignSelf: 'flex-start' }}>
                <header>
                    <ImportKingfisherAssets isOpen={importOpen} onClose={() => setImportOpen(false)} />
                    <ExportKingfisherProjects isOpen={exportProjectsOpen} onClose={() => setExportProjectsOpen(false)} />
                    <Space>
                        <Dropdown menu={{
                            items: [{
                                label: 'Export kingfisher assets as excel',
                                key: '1',
                                icon: <TableOutlined />,
                                onClick: exportAssets
                            },{
                                label: 'Import kingfisher assets from excel',
                                key: '2',
                                icon: <TableOutlined />,
                                onClick: () => setImportOpen(true)
                            },{
                                label: 'Kingfisher project overview',
                                key: '3',
                                icon: <TableOutlined />,
                                onClick: () => setExportProjectsOpen(true)
                            }]
                        }} placement="bottomRight" arrow={{ pointAtCenter: false }}>
                            <Button><ToolOutlined /> Tools</Button>
                        </Dropdown>
                        <Dropdown menu={{
                            items: [{
                                label: 'Logout',
                                key: '2',
                                icon: <CloseCircleOutlined />,
                                onClick: logOut
                            },]
                        }} placement="bottomRight" arrow={{ pointAtCenter: false }}>
                            <Button><UserOutlined /> {username}</Button>
                        </Dropdown>
                    </Space>
                </header>
                <Layout style={{ padding: 24 }}>
                    <Content
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                            background: colorBgContainer,
                        }}
                    >
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default AdminApp;