import React, { createContext, FC, useContext, useEffect } from "react";
import { DialogContext } from "./DialogContext";
import { HelpService } from "../openapi/requests";

export interface ErrorReportingContextState {

};

const contextDefaultValues: ErrorReportingContextState = {

};

export const ErrorReportingContext = createContext<ErrorReportingContextState>(
    contextDefaultValues
);

export const ErrorReportingProvider: FC<{ children: React.ReactNode }> = ({ children }) => {

    const {error} = useContext(DialogContext)

    useEffect(() => {
        const onerror = (e: ErrorEvent) => {
            let exception = e.error;

            if(exception instanceof Error){
                console.log("Sending error");
                HelpService.postHelpJserror(exception.message, exception.stack, exception.name);
            }

            error("", e.error);
        }

        window.addEventListener('error', onerror);

        return () => {
            window.removeEventListener('error', onerror);
        }
    }, [error]);

    return (
        <ErrorReportingContext.Provider value={{}}>
            {children}
        </ErrorReportingContext.Provider>
    );
};