import { Drawer, Input, message, Space } from 'antd';
import React, { useEffect } from 'react';
import { Button, Form } from 'antd';
import { InternalClientDTO } from '../../../openapi/requests';
import { useInternalClientServicePostInternalClient } from '../../../openapi/queries';


const CreateClient: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {

    const [form] = Form.useForm<InternalClientDTO>();
    const [messageApi, contextHolder] = message.useMessage();

    const {mutate, isSuccess, isPending, isError, error} = useInternalClientServicePostInternalClient();

    const onSubmit = () => {
        form.submit();
    };

    useEffect(() => {
        if(isSuccess){
            messageApi.success("Client created");
        }
    }, [isSuccess, messageApi]);

    useEffect(() => {
        if(isError){
            messageApi.error(error?.toString());
        }
    }, [error, isError, isSuccess, messageApi]);

    const onFinish = () => {
        const values = form.getFieldsValue(true) as InternalClientDTO;
        mutate({requestBody: values});
    };

    return (
        <>
            {contextHolder}

            <Drawer
                title="Create a new client"
                width={720}
                onClose={onClose}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item rules={[{required: true}]} name='name' label="Name">
                        <Input />
                    </Form.Item>

                    <Form.Item name='maxModelsPath' label="Max Models Path">
                        <Input />
                    </Form.Item>

                    <Form.Item name='maxModifiersPath' label="Max Modifiers Path">
                        <Input />
                    </Form.Item>

                    <Form.Item name='maxMaterialsPath' label="Max Materials Path">
                        <Input />
                    </Form.Item>

                    <Form.Item name='maxScenesPath' label="Max Scenes Path">
                        <Input />
                    </Form.Item>

                    <Form.Item name='maxOutputPath' label="Max Output Path">
                        <Input />
                    </Form.Item>

                    <Form.Item name='arModelsPath' label="AR Models Path">
                        <Input />
                    </Form.Item>

                    <Form.Item name='arMaterialsPath' label="AR Materials Path">
                        <Input />
                    </Form.Item>
                </Form>
            </Drawer>
        </>

    );
};

export default CreateClient;