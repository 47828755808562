import { DiagramEngine } from '@projectstorm/react-diagrams';
import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { ModifierPortModel } from './ModifierPort';
import { ConfiguratorNodeModel, ConfiguratorNodeWidget } from './ConfiguratorNode';
import { ConfiguratorPortModel } from './ConfiguratorPort';
import { ModifierService, ModifierViewDTO } from '../../../../../openapi/requests';

export class ModifierNodeModel extends ConfiguratorNodeModel<ModifierViewDTO> {
    constructor(updateCallback: () => void, onPortSelected: (port: ConfiguratorPortModel) => void) {
        super('modifier', updateCallback);
		this.addPort(new ModifierPortModel(false, 'out', 'out', onPortSelected, ''));
    }

	async loadData(modifierID: number): Promise<void> {
        var resp = await ModifierService.getModifier1(modifierID)
		this.data = resp;
		this.updateCallback();
	}
}

export class ModifierNodeFactory extends AbstractReactFactory<ModifierNodeModel, DiagramEngine> {
    constructor() {
        super('modifier');
    }

    generateReactWidget(event: GenerateWidgetEvent<ModifierNodeModel>): JSX.Element {
        // event.model is basically what's returned from generateModel()
        return <ConfiguratorNodeWidget engine={this.engine} node={event.model} />;
    }

    generateModel(event : GenerateModelEvent) {
        return new ModifierNodeModel(() => {}, () => {});
    }
}