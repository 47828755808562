import { AbstractModelFactory, DeserializeEvent } from "@projectstorm/react-canvas-core";
import { DiagramEngine, LinkModel, LinkModelGenerics, PortModel, PortModelAlignment, PortWidget } from "@projectstorm/react-diagrams-core";
import { DefaultLinkModel } from "@projectstorm/react-diagrams-defaults";
import { itemFilter } from "./NodeEditor";

export class ConfiguratorPortModel extends PortModel {
    in: boolean;
    label: string;
	compatibleItems?: itemFilter;
	onSelected: (port: ConfiguratorPortModel) => void;
	filter: string;

    constructor(type: string, isIn: boolean, name: string, label: string, onSelected: (port: ConfiguratorPortModel) => void, filter: string) {
        super({
            type: type,
            name: name
        });

        this.options.alignment = isIn ? PortModelAlignment.RIGHT : PortModelAlignment.LEFT;

        this.label = label;
        this.in = isIn;
		this.onSelected = onSelected;
		this.filter = filter;
    }

    canLinkToPort(port: PortModel): boolean {
        if (port instanceof ConfiguratorPortModel) {
            return this.getType() === port.getType() && this.in !== port.in;
        }
        return false;
    }	
    
    deserialize(event: DeserializeEvent<this>) {
		super.deserialize(event);
		this.in = event.data.in;
	}

	serialize() {
		return {
			...super.serialize(),
			in: this.in
		};
	}
	
	addLink(link: LinkModel<LinkModelGenerics>): void {
        if (this.in) {
			const links = this.getLinks();

			for (const key in links) {
				if (Object.prototype.hasOwnProperty.call(links, key)) {
					const element = links[key];
					element.remove();
				}
			}
        }

		super.addLink(link);
	}

	link<T extends LinkModel>(port: PortModel, factory?: AbstractModelFactory<T>): T {
		let link = this.createLinkModel(factory);
		link.setSourcePort(this);
		link.setTargetPort(port);
		return link as T;
	}

	createLinkModel(factory?: AbstractModelFactory<LinkModel>): LinkModel {
		let link = super.createLinkModel();
		if (!link && factory) {
			return factory.generateModel({});
		}
		return link || new DefaultLinkModel();
	}

	onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void{
		e.stopPropagation();
		this.onSelected(this);
	}
}

export interface ModelPortLabelProps {
	port: ConfiguratorPortModel;
	engine: DiagramEngine;
}

export const ConfiguratorPortLabel: React.FC<ModelPortLabelProps> = ({engine, port}) => {
    return <div className={`port-label port-${port.getType()} ${port.in ? 'in' : 'out'} ${port.isSelected() ? 'selected' : ''}` } onClick={(e) => port.onClick(e)}>
        <PortWidget engine={engine} port={port}>
            <div className="port-widget"></div>
        </PortWidget>
        <span>{port.in ? port.label : ''}</span>
    </div>
}