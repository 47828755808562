import React from 'react';
import { useParams } from 'react-router-dom';
import AssetProjectDetails from '../../../components/admin/assetProjects/AssetProjectDetails';

const AssetProjectPage: React.FC = () => {
    let { projectId } = useParams();

    return (<div>
        <AssetProjectDetails projectId={parseInt(projectId || '0')} />
    </div>)
};

export default AssetProjectPage;