/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigurationAssets } from '../models/ConfigurationAssets';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConfigurationService {

    /**
     * @param clientId 
     * @param requestBody 
     * @returns ConfigurationAssets Success
     * @throws ApiError
     */
    public static postConfigurationItems(
clientId?: number,
requestBody?: string,
): CancelablePromise<ConfigurationAssets> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Configuration/items',
            query: {
                'clientId': clientId,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

}
