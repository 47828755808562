/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetApprovalHistoryDTO } from '../models/AssetApprovalHistoryDTO';
import type { SceneViewDTO } from '../models/SceneViewDTO';
import type { TemplateEditDTO } from '../models/TemplateEditDTO';
import type { TemplateViewDTO } from '../models/TemplateViewDTO';
import type { TemplateViewDTOODataListResponse } from '../models/TemplateViewDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TemplateService {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns TemplateViewDTOODataListResponse Success
     * @throws ApiError
     */
    public static getTemplate(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<TemplateViewDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Template',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns TemplateViewDTO Success
     * @throws ApiError
     */
    public static postTemplate(
requestBody?: TemplateEditDTO,
): CancelablePromise<TemplateViewDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Template',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns TemplateViewDTO Success
     * @throws ApiError
     */
    public static getTemplate1(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<TemplateViewDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Template/{key}',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns SceneViewDTO Success
     * @throws ApiError
     */
    public static putTemplate(
key: number,
requestBody?: TemplateEditDTO,
): CancelablePromise<SceneViewDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/Template/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteTemplate(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Template/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static getTemplatePdf(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Template/{key}/pdf',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param size 
     * @param format 
     * @returns any Success
     * @throws ApiError
     */
    public static getTemplateThumbnail(
key: number,
size: number = 256,
format: string = 'webp',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Template/{key}/thumbnail/{size}',
            path: {
                'key': key,
            },
            query: {
                'size': size,
                'format': format,
            },
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static getTemplateImage(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Template/{key}/image',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postTemplateImage(
key: number,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Template/{key}/image',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postTemplateQAimages(
key: number,
formData?: {
files?: Array<Blob>;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Template/{key}/QAimages',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @returns string Success
     * @throws ApiError
     */
    public static getTemplateQAimages(
key: number,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Template/{key}/QAimages',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param index 
     * @returns any Success
     * @throws ApiError
     */
    public static getTemplateQAimages1(
key: number,
index: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Template/{key}/QAimages/{index}',
            path: {
                'key': key,
                'index': index,
            },
        });
    }

    /**
     * @param key 
     * @param index 
     * @returns any Success
     * @throws ApiError
     */
    public static getTemplateApprovalHistoryImages(
key: number,
index: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Template/{key}/ApprovalHistory/images/{index}',
            path: {
                'key': key,
                'index': index,
            },
        });
    }

    /**
     * @param key 
     * @returns AssetApprovalHistoryDTO Success
     * @throws ApiError
     */
    public static getTemplateApprovalHistory(
key: number,
): CancelablePromise<Array<AssetApprovalHistoryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Template/{key}/ApprovalHistory',
            path: {
                'key': key,
            },
        });
    }

}
