/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InternalUisceneModifierTargetHotspotDTO } from '../models/InternalUisceneModifierTargetHotspotDTO';
import type { InternalUisceneModifierTargetHotspotDTOODataListResponse } from '../models/InternalUisceneModifierTargetHotspotDTOODataListResponse';
import type { UisceneModifierTargetHotspot } from '../models/UisceneModifierTargetHotspot';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalUisceneModifierTargetHotspotService {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns InternalUisceneModifierTargetHotspotDTOODataListResponse Success
     * @throws ApiError
     */
    public static getInternalUisceneModifierTargetHotspot(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<InternalUisceneModifierTargetHotspotDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/UisceneModifierTargetHotspot',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InternalUisceneModifierTargetHotspotDTO Success
     * @throws ApiError
     */
    public static postInternalUisceneModifierTargetHotspot(
requestBody?: InternalUisceneModifierTargetHotspotDTO,
): CancelablePromise<InternalUisceneModifierTargetHotspotDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal/UisceneModifierTargetHotspot',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns InternalUisceneModifierTargetHotspotDTO Success
     * @throws ApiError
     */
    public static getInternalUisceneModifierTargetHotspot1(
key: number,
): CancelablePromise<InternalUisceneModifierTargetHotspotDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/UisceneModifierTargetHotspot/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns InternalUisceneModifierTargetHotspotDTO Success
     * @throws ApiError
     */
    public static putInternalUisceneModifierTargetHotspot(
key: number,
requestBody?: InternalUisceneModifierTargetHotspotDTO,
): CancelablePromise<InternalUisceneModifierTargetHotspotDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal/UisceneModifierTargetHotspot/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteInternalUisceneModifierTargetHotspot(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal/UisceneModifierTargetHotspot/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param clientId 
     * @param query 
     * @returns UisceneModifierTargetHotspot Success
     * @throws ApiError
     */
    public static getInternalUisceneModifierTargetHotspotQuery(
clientId?: number,
query?: string,
): CancelablePromise<Array<UisceneModifierTargetHotspot>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/UisceneModifierTargetHotspot/query',
            query: {
                'clientId': clientId,
                'query': query,
            },
        });
    }

}
