import { Outlet } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';
import './FullScreenWrapper.scss';

const FullScreenWrapper: React.FC = () => {
    return (
        <div className='login-page'>
            <div className='page-content'>
                <Outlet />
            </div>

            <Logo className='logo' />
        </div>
    )
}
export default FullScreenWrapper;