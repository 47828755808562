import React from 'react';
import SceneList from '../../../components/admin/scenes/SceneList';

const Scenes: React.FC = () => {

    return (<div>
        <SceneList />
    </div>)
};

export default Scenes;