/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeliveryMethodCustomApi } from '../models/DeliveryMethodCustomApi';
import type { InternalDeliveryMethodCustomApiDTO } from '../models/InternalDeliveryMethodCustomApiDTO';
import type { InternalDeliveryMethodCustomApiDTOODataListResponse } from '../models/InternalDeliveryMethodCustomApiDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalDeliveryMethodCustomApiService {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns InternalDeliveryMethodCustomApiDTOODataListResponse Success
     * @throws ApiError
     */
    public static getInternalDeliveryMethodCustomApi(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<InternalDeliveryMethodCustomApiDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/DeliveryMethodCustomApi',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InternalDeliveryMethodCustomApiDTO Success
     * @throws ApiError
     */
    public static postInternalDeliveryMethodCustomApi(
requestBody?: InternalDeliveryMethodCustomApiDTO,
): CancelablePromise<InternalDeliveryMethodCustomApiDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal/DeliveryMethodCustomApi',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns InternalDeliveryMethodCustomApiDTO Success
     * @throws ApiError
     */
    public static getInternalDeliveryMethodCustomApi1(
key: number,
): CancelablePromise<InternalDeliveryMethodCustomApiDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/DeliveryMethodCustomApi/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns InternalDeliveryMethodCustomApiDTO Success
     * @throws ApiError
     */
    public static putInternalDeliveryMethodCustomApi(
key: number,
requestBody?: InternalDeliveryMethodCustomApiDTO,
): CancelablePromise<InternalDeliveryMethodCustomApiDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal/DeliveryMethodCustomApi/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteInternalDeliveryMethodCustomApi(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal/DeliveryMethodCustomApi/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param clientId 
     * @param query 
     * @returns DeliveryMethodCustomApi Success
     * @throws ApiError
     */
    public static getInternalDeliveryMethodCustomApiQuery(
clientId?: number,
query?: string,
): CancelablePromise<Array<DeliveryMethodCustomApi>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/DeliveryMethodCustomApi/query',
            query: {
                'clientId': clientId,
                'query': query,
            },
        });
    }

}
