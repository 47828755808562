import { Button, Form, Input, List, Modal, Radio, Select, Tabs, TabsProps } from 'antd';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import createEngine, {
    DiagramEngine,
    DiagramModel
} from '@projectstorm/react-diagrams';
import './NodeEditor.scss';

import {
    CanvasWidget
} from '@projectstorm/react-canvas-core';
import { SceneNodeFactory, SceneNodeModel } from './SceneNode';
import { ModelNodeFactory, ModelNodeModel } from './ModelNode';
import { MaterialNodeFactory, MaterialNodeModel } from './MaterialNode';
import { MaterialSelection, ModelPlacement, ModifierSelection, StillImageConfiguration } from '../../../../../models/StillImageConfiguration';
import { MaterialPortModel } from './MaterialPort';
import { ModelPortModel } from './ModelPort';
import { ModifierNodeFactory, ModifierNodeModel } from './ModifierNode';
import { ModifierPortModel } from './ModifierPort';
import { ConfiguratorNodeModel } from './ConfiguratorNode';
import { ModelPackNodeFactory, ModelPackNodeModel } from './ModelPackNode';
import { ModelPackPortModel } from './ModelPackPort';
import { ConfiguratorPortModel } from './ConfiguratorPort';
import { MaterialService, MaterialViewDTO, MetadataDTO, ModelPackService, ModelPackViewDTO, ModelService, ModelViewDTO, ModifierService, ModifierViewDTO, TemplateViewDTO } from '../../../../../openapi/requests';

export type tabs = 'models' | 'modelPacks' | 'materials' | 'modifiers';
export type itemFilter = { models: number[], modelPacks: number[], materials: number[], modifiers: number[] };

const disabledMetadataFilters = ["ean"];
const metadataTitles: { [key: string]: string } = {
    "product_type_primary": "Product type",
    "product_type_secondary": "Secondary product type",
    "category": "Category"
};

const ItemList: React.FC<{ items: { id: number, title: string, tags: string[], metadata: MetadataDTO[] }[], type: string, enabledItems?: number[] }> = ({ items, type, enabledItems }) => {
    const [filteredItems, setfilteredItems] = useState(items);
    const [tags, setTags] = useState<string[]>([]);
    const [metadata, setMetadata] = useState<{ name: string, title: string, options: string[] }[]>([]);
    const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);
    const [selectedMetadata, setSelectedMetadata] = useState<{ [key: string]: string }>({});
    const [query, setQuery] = useState("");

    useEffect(() => {
        let filtered = items;

        if (enabledItems && enabledItems.length > 0) {
            filtered = filtered.filter((e) => enabledItems.includes(e.id))
        }

        let itemTags: { [key: string]: { tag: string, count: number } } = {};
        let itemMetadata: { [key: string]: { name: string, title: string, count: number, options: string[] } } = {};

        for (let i = 0; i < filtered.length; i++) {
            const element = filtered[i];

            for (let j = 0; j < element.tags.length; j++) {
                const tag = element.tags[j];
                itemTags[tag] = { tag, count: itemTags[tag] ? itemTags[tag].count + 1 : 1 }
            }

            for (let j = 0; j < element.metadata.length; j++) {
                const metadata = element.metadata[j];

                if (metadata.value.trim() === "") {
                    continue;
                }

                if (!disabledMetadataFilters.includes(metadata.name)) {
                    itemMetadata[metadata.name] = {
                        name: metadata.name,
                        title: metadataTitles[metadata.name] ?? metadata.name,
                        count: itemMetadata[metadata.name] ? itemMetadata[metadata.name].count + 1 : 1,
                        options: Array.from(new Set([...itemMetadata[metadata.name] ? itemMetadata[metadata.name].options : [], metadata.value]))
                    }
                }
            }
        }

        let sortedTags = Object.values(itemTags).sort((a, b) => b.count - a.count);
        let sortedMetadata = Object.values(itemMetadata).sort((a, b) => b.count - a.count);

        if (sortedTags.length) {
            setTags(["all", ...sortedTags.slice(0, 3).map(e => e.tag)])
            setSelectedTag("all");
        } else {
            setTags([]);
            setSelectedTag(undefined);
        }

        if (sortedMetadata.length) {
            setMetadata(sortedMetadata.slice(0, 2));
            setSelectedMetadata({});
        } else {
            setMetadata([]);
            setSelectedMetadata({});
        }

    }, [items, enabledItems]);

    useEffect(() => {
        let filtered = items;

        if (enabledItems && enabledItems.length > 0) {
            filtered = filtered.filter((e) => enabledItems.includes(e.id))
        }
        if (query) {
            var lowercase = query.toLowerCase();

            filtered = filtered.filter((e) => {
                return e.title.toLowerCase().includes(lowercase) || e.tags.some(t => t.toLowerCase().includes(lowercase)) || e.metadata.some(t => t.value.toLowerCase().includes(lowercase))
            });
        }

        if (selectedTag && selectedTag !== "all") {
            filtered = filtered.filter((e) => e.tags.includes(selectedTag));
        }

        if (selectedMetadata) {
            for (const key in selectedMetadata) {
                if (Object.prototype.hasOwnProperty.call(selectedMetadata, key)) {
                    const value = selectedMetadata[key];
                    if (value !== undefined) {
                        filtered = filtered.filter((e) => e.metadata.some(m => m.name === key && m.value === value));
                    }
                }
            }
        }

        setfilteredItems(filtered);
    }, [items, query, enabledItems, selectedTag, selectedMetadata]);

    return <>
        <Form layout="vertical">
            <Form.Item >
                <Input value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Search" />
            </Form.Item>
            {(tags.length !== 0) && <Form.Item>
                <Radio.Group value={selectedTag} onChange={e => setSelectedTag(e.target.value)} optionType="button" buttonStyle="solid" options={tags} />
            </Form.Item>}
            {metadata.map(m => <Form.Item key={m.name} label={m.title} ><Select allowClear
                value={selectedMetadata[m.name]}
                onChange={e => setSelectedMetadata({ ...selectedMetadata, [m.name]: e })}
                options={m.options.map(e => ({ value: e, label: e }))}
            /></Form.Item>)}
        </Form>


        <List
            itemLayout="horizontal"
            dataSource={filteredItems}
            renderItem={(item, index) => (
                <List.Item draggable onDragStart={e => e.dataTransfer.setData('node', JSON.stringify({ 'type': type, 'id': item.id }))}>
                    <List.Item.Meta
                        title={item.title}
                    />
                </List.Item>
            )}
        />
    </>
}

const ConfigurationSidebar: React.FC<{ selectedTab: tabs, enabledItems?: itemFilter }> = ({ selectedTab, enabledItems }) => {
    const [models, setModels] = useState<ModelViewDTO[]>([]);
    const [modelPacks, setModelPacks] = useState<ModelPackViewDTO[]>([]);
    const [materials, setMaterials] = useState<MaterialViewDTO[]>([]);
    const [modifiers, setModifiers] = useState<ModifierViewDTO[]>([]);
    const [currentTab, setcurrentTab] = useState<tabs>('models');

    useEffect(() => {
        ModelService.getModel().then(resp => setModels(resp.value.sort((a, b) => a.title.localeCompare(b.title))));
        ModelPackService.getModelPack().then(resp => setModelPacks(resp.value.sort((a, b) => a.title.localeCompare(b.title))));
        MaterialService.getMaterial().then(resp => setMaterials(resp.value.sort((a, b) => a.title.localeCompare(b.title))));
        ModifierService.getModifier().then(resp => setModifiers(resp.value.sort((a, b) => a.title.localeCompare(b.title))));
    }, []);

    useEffect(() => {
        setcurrentTab(selectedTab)
    }, [selectedTab]);

    const items: TabsProps['items'] = [
        {
            key: 'models',
            label: `Models`,
            children: <ItemList items={models} type="model" enabledItems={enabledItems?.models} />,
        },
        {
            key: 'modelPacks',
            label: `Model packs`,
            children: <ItemList items={modelPacks} type="modelpack" enabledItems={enabledItems?.modelPacks} />,
        },
        {
            key: 'materials',
            label: `Materials`,
            children: <ItemList items={materials} type="material" enabledItems={enabledItems?.materials} />,
        },
        {
            key: 'modifiers',
            label: `Modifiers`,
            children: <ItemList items={modifiers} type="modifier" enabledItems={enabledItems?.modifiers} />,
        },
    ];

    return (
        <div className='sidebar'>
            <Tabs activeKey={currentTab} onTabClick={(e) => setcurrentTab(e as tabs)} items={items} />
        </div>
    )
};

const NodeEditor: React.FC<{ template: TemplateViewDTO | undefined, isOpen: boolean, configuration?: string, onSave: (serialized: string) => void }> = ({ template, isOpen, onSave, configuration }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [engine, setEngine] = useState<DiagramEngine | null>();
    const [initialized, setInitialized] = useState(false);
    const [currentTab, setCurrentTab] = useState<tabs>('models');
    const [itemFilter, setItemFilter] = useState<itemFilter | undefined>(undefined);
    const [selectedPort, setSelectedPort] = useState<string | undefined>(undefined);

    const onPortSelected = useCallback((port: ConfiguratorPortModel) => {
        if (port.getType() === 'model') {
            setCurrentTab('models');
        }
        if (port.getType() === 'material') {
            setCurrentTab('materials');
        }
        if (port.getType() === 'modelpack') {
            setCurrentTab('modelPacks');
        }
        if (port.getType() === 'modifier') {
            setCurrentTab('modifiers');
        }

        setSelectedPort(port.getID());
        setItemFilter(port.compatibleItems);
    }, []);

    useEffect(() => {
        if (engine) {
            const model = engine.getModel();
            const nodes = model.getNodes();

            for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i];
                const ports = node.getPorts();

                for (const key in ports) {
                    if (Object.prototype.hasOwnProperty.call(ports, key)) {
                        const port = ports[key];

                        if (port.getID() === selectedPort) {
                            port.setSelected(true);
                        } else if (port.isSelected()) {
                            port.setSelected(false);
                        }
                    }
                }
            }

            forceUpdate();
        }
    }, [engine, selectedPort]);

    const loadConfiguration = useCallback(async () => {
        if (engine === undefined || engine === null || template === undefined || configuration === undefined || configuration === "") {
            return;
        }

        const data = JSON.parse(configuration) as StillImageConfiguration;

        const diagramModel = engine.getModel();

        const models = (await ModelService.getModel()).value;
        const modelpacks = (await ModelPackService.getModelPack()).value;
        const materials = (await MaterialService.getMaterial()).value;
        const modifiers = (await ModifierService.getModifier()).value;

        const offsets: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        const maxRecursiveness = 8;

        const addMaterial = async (parent: ConfiguratorNodeModel<any>, selection: MaterialSelection, level: number) => {
            const material = materials.find(e => e.name === selection.Value);

            if (material) {
                const modelNode = new MaterialNodeModel(forceUpdate, onPortSelected);
                diagramModel.addNode(modelNode);
                var offset = offsets[level];
                offsets[level] += 100;
                modelNode.setPosition(75 + level * 200, 100 + offset);
                await modelNode.loadData(material.id);

                var destPort = parent.getMaterialPorts().find(e => e.in && e.getName() === selection.Name);
                var outPort = modelNode.getMaterialPorts().find(e => !e.in);

                if (destPort && outPort) {
                    let link = outPort.link(destPort);
                    diagramModel.addLink(link);
                }
            }
        }

        const addModifier = async (parent: ConfiguratorNodeModel<any>, selection: ModifierSelection, level: number) => {
            const modifier = modifiers.find(e => e.name === selection.Value);

            if (modifier) {
                const modelNode = new ModifierNodeModel(forceUpdate, onPortSelected);
                diagramModel.addNode(modelNode);
                var offset = offsets[level];
                offsets[level] += 100;
                modelNode.setPosition(75 + level * 200, 100 + offset);
                await modelNode.loadData(modifier.id);

                var destPort = parent.getModifierPorts().find(e => e.in && e.getName() === selection.Name);
                var outPort = modelNode.getModifierPorts().find(e => !e.in);

                if (destPort && outPort) {
                    let link = outPort.link(destPort);
                    diagramModel.addLink(link);
                }
            }
        }

        const addModel = async (parent: ConfiguratorNodeModel<any>, placement: ModelPlacement, level: number) => {
            if (level > maxRecursiveness) {
                return;
            }

            const model = models.find(e => e.name === placement.Value);
            var destPort = parent.getModelPorts().find(e => e.in && (e.getName() === placement.Name));

            if (model && destPort) {
                const modelNode = new ModelNodeModel(forceUpdate, onPortSelected);
                diagramModel.addNode(modelNode);
                var offset = offsets[level];
                offsets[level] += 200;
                modelNode.setPosition(75 + level * 200, 20 + offset);
                await modelNode.loadData(model.id);

                var outPort = modelNode.getModelPorts().find(e => !e.in);

                if (destPort && outPort) {
                    let link = outPort.link(destPort);
                    diagramModel.addLink(link);
                }

                if (placement.ModelSelections) {
                    for (let i = 0; i < placement.ModelSelections.length; i++) {
                        const element = placement.ModelSelections[i];
                        await addModel(modelNode, element, level + 1);
                    }
                }

                if (placement.SurfaceSelections) {
                    for (let i = 0; i < placement.SurfaceSelections.length; i++) {
                        const element = placement.SurfaceSelections[i];
                        await addMaterial(modelNode, element, level + 1);
                    }
                }
            } else {
                const modelPack = modelpacks.find(e => e.name === placement.Value);
                destPort = parent.getModelPackPorts().find(e => e.in && (e.getName() === placement.Name));

                if (modelPack) {
                    const modelNode = new ModelPackNodeModel(forceUpdate, onPortSelected);
                    diagramModel.addNode(modelNode);
                    offset = offsets[level];
                    offsets[level] += 200;
                    modelNode.setPosition(75 + level * 200, 20 + offset);
                    await modelNode.loadData(modelPack.id);

                    outPort = modelNode.getModelPackPorts().find(e => !e.in);

                    if (destPort && outPort) {
                        let link = outPort.link(destPort);
                        diagramModel.addLink(link);
                    }

                    if (placement.ModelSelections) {
                        for (let i = 0; i < placement.ModelSelections.length; i++) {
                            const element = placement.ModelSelections[i];
                            await addModel(modelNode, element, level + 1);
                        }
                    }

                    if (placement.SurfaceSelections) {
                        for (let i = 0; i < placement.SurfaceSelections.length; i++) {
                            const element = placement.SurfaceSelections[i];
                            await addMaterial(modelNode, element, level + 1);
                        }
                    }
                }
            }
        }

        const nodes = diagramModel.getNodes();
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            diagramModel.removeNode(node);
        }
        const links = diagramModel.getLinks();
        for (let i = 0; i < links.length; i++) {
            const link = links[i];
            diagramModel.removeLink(link);
        }

        const sceneNode = new SceneNodeModel(forceUpdate, onPortSelected);
        await sceneNode.loadData(template.scene.id);
        sceneNode.setPosition(20, 20);
        sceneNode.setLocked(true);

        for (let i = 0; i < sceneNode.propSets.length; i++) {
            const propSet = sceneNode.propSets[i];
            const value = data.PropsetSelections?.find(e => e.Name === propSet.set.name);

            if (value) {
                propSet.option = propSet.options.find(e => e.name === value.Value);
            }
        }

        let cam = sceneNode.cameras.find(e => e.cameraName === data.camera);
        if (cam) {
            sceneNode.camera = cam;
        }

        diagramModel.addNode(sceneNode);

        if (data.ModelSelections) {
            for (let i = 0; i < data.ModelSelections.length; i++) {
                const element = data.ModelSelections[i];
                await addModel(sceneNode, element, 1);
            }
        }
        if (data.SurfaceSelections) {
            for (let i = 0; i < data.SurfaceSelections.length; i++) {
                const element = data.SurfaceSelections[i];
                await addMaterial(sceneNode, element, 1);
            }
        }
        if (data.ModifierSelections) {
            for (let i = 0; i < data.ModifierSelections.length; i++) {
                const element = data.ModifierSelections[i];
                await addModifier(sceneNode, element, 1);
            }
        }

        forceUpdate();

    }, [configuration, engine, template, onPortSelected]);

    const handleReset = useCallback(() => {
        if (engine) {
            const diagramModel = engine.getModel();

            const nodes = diagramModel.getNodes();
            for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i];
                if (node.getType() !== 'scene') {
                    diagramModel.removeNode(node);
                }
            }
            const links = diagramModel.getLinks();
            for (let i = 0; i < links.length; i++) {
                const link = links[i];
                diagramModel.removeLink(link);
            }
            forceUpdate();
        }
    }, [engine]);

    useEffect(() => {

        if(!isOpen){
            return;
        }

        if (!engine) {
            const engine = createEngine();
            const model = new DiagramModel();

            var factories = engine.getNodeFactories()

            factories.registerFactory(new SceneNodeFactory());
            factories.registerFactory(new ModelNodeFactory());
            factories.registerFactory(new ModelPackNodeFactory());
            factories.registerFactory(new MaterialNodeFactory());
            factories.registerFactory(new ModifierNodeFactory());

            engine.setModel(model);
            engine.setMaxNumberPointsPerLink(0);

            setEngine(engine);
        }

        if (engine && template) {

            const model = engine.getModel();
            const nodes = model.getNodes();

            var sceneNode = nodes.find(e => e instanceof SceneNodeModel) as SceneNodeModel;

            if (!sceneNode || sceneNode.data?.id !== template.scene.id) {
                if (sceneNode) {
                    model.removeNode(sceneNode);

                    const ports = sceneNode.getPorts();

                    for (const key in ports) {
                        if (Object.prototype.hasOwnProperty.call(ports, key)) {
                            const links = ports[key].getLinks();

                            for (const key in links) {
                                if (Object.prototype.hasOwnProperty.call(links, key)) {
                                    model.removeLink(links[key]);
                                }
                            }
                        }
                    }
                }

                if (template.scene.id) {
                    sceneNode = new SceneNodeModel(forceUpdate, onPortSelected);
                    sceneNode.loadData(template.scene.id);
                    sceneNode.setPosition(20, 20);
                    sceneNode.setLocked(true);

                    model.addNode(sceneNode);
                }
            }

            if (!initialized) {
                setInitialized(true);
                loadConfiguration();
            }
        }
    }, [engine, initialized, loadConfiguration, template, onPortSelected, isOpen]);

    const onDrop = useCallback((evt: React.DragEvent) => {
        if (engine) {
            var data: { type: string, id: number } = JSON.parse(evt.dataTransfer.getData('node'));

            if (data.type === 'scene') {
                let node = new SceneNodeModel(forceUpdate, onPortSelected);
                node.loadData(data.id);

                let point = engine.getRelativeMousePoint(evt);
                node.setPosition(point);
                engine.getModel().addNode(node);
            } else if (data.type === 'model') {
                let node = new ModelNodeModel(forceUpdate, onPortSelected);
                node.loadData(data.id);

                let point = engine.getRelativeMousePoint(evt);
                node.setPosition(point);
                engine.getModel().addNode(node);
            } else if (data.type === 'modelpack') {
                let node = new ModelPackNodeModel(forceUpdate, onPortSelected);
                node.loadData(data.id);

                let point = engine.getRelativeMousePoint(evt);
                node.setPosition(point);
                engine.getModel().addNode(node);
            } else if (data.type === 'material') {
                let node = new MaterialNodeModel(forceUpdate, onPortSelected);
                node.loadData(data.id);

                let point = engine.getRelativeMousePoint(evt);
                node.setPosition(point);
                engine.getModel().addNode(node);
            } else if (data.type === 'modifier') {
                let node = new ModifierNodeModel(forceUpdate, onPortSelected);
                node.loadData(data.id);

                let point = engine.getRelativeMousePoint(evt);
                node.setPosition(point);
                engine.getModel().addNode(node);
            }

            forceUpdate();
        }
    }, [engine, onPortSelected]);

    const handleClose = useCallback(() => {
        if (engine === null || engine === undefined) {
            return;
        }

        const model = engine.getModel();
        const nodes = model.getNodes();

        var sceneNode = nodes.find(e => e instanceof SceneNodeModel) as SceneNodeModel;

        if (sceneNode === undefined) {
            return;
        }

        var result: StillImageConfiguration = {
            camera: sceneNode.camera?.cameraName || '',
            PropsetSelections: sceneNode.propSets.filter(e => e.option).map(e => ({ Name: e.set.name, Value: e.option!.name })),
            SurfaceSelections: [],
            ModelSelections: [],
            ModifierSelections: [],
            Cameras: []
        }

        var matPorts = sceneNode.getMaterialPorts();

        for (let i = 0; i < matPorts.length; i++) {
            const material = getMaterial(matPorts[i]);

            if (material !== undefined) {
                result.SurfaceSelections?.push({ Name: matPorts[i].getName(), Value: material.name })
            }
        }

        var modifierPorts = sceneNode.getModifierPorts();

        for (let i = 0; i < modifierPorts.length; i++) {
            const modifier = getModifier(modifierPorts[i]);

            if (modifier !== undefined) {
                result.ModifierSelections?.push({ Name: modifierPorts[i].getName(), Value: modifier.name || '' })
            }
        }

        var modelPorts = sceneNode.getModelPorts();

        for (let i = 0; i < modelPorts.length; i++) {
            const model = getModelPlacement(modelPorts[i], 8);

            if (model !== undefined) {
                result.ModelSelections?.push(model);
            }
        }

        var modelPackPorts = sceneNode.getModelPackPorts();

        for (let i = 0; i < modelPackPorts.length; i++) {
            const model = getModelPlacement(modelPackPorts[i], 8);

            if (model !== undefined) {
                result.ModelSelections?.push(model);
            }
        }

        onSave(JSON.stringify(result));

    }, [engine, onSave]);


    if (!engine) {
        return null;
    }

    return (

        <Modal
            title="Configuration"
            open={isOpen}
            onOk={handleClose}
            onCancel={handleClose}
            width='90vw'
            footer={[
                <Button key="back" onClick={handleReset}>
                    Reset
                </Button>,
                <Button key="submit" type="primary" onClick={handleClose}>
                    OK
                </Button>,
            ]}
        >
            <div style={{ width: '100%', height: '75vh' }}>
                <div className='node-editor'>
                    <ConfigurationSidebar selectedTab={currentTab} enabledItems={itemFilter} />
                    <div className='diagram-container' onDrop={onDrop} onClick={() => { setSelectedPort(undefined); setItemFilter(undefined) }} onDragOver={evt => evt.preventDefault()}>
                        <CanvasWidget engine={engine} className='diagram' />
                    </div>
                </div>
            </div>
        </Modal>
    )
};



const getModelPlacement = (port: ModelPortModel | ModelPackPortModel, depth: number): ModelPlacement | undefined => {
    if (depth === 0 || !port.in) {
        return undefined;
    }

    const link = port.links[Object.keys(port.links)[0]];

    if (link !== undefined) {
        const source = link.getSourcePort()?.getNode() as ModelNodeModel;
        if (source !== undefined) {
            const model: ModelPlacement = {
                Name: port.getName(),
                Value: source.data?.name || '',
                ModelSelections: [],
                SurfaceSelections: [],
                FeatureSelections: [],
            };

            const matPorts = source.getMaterialPorts();

            for (let i = 0; i < matPorts.length; i++) {
                const material = getMaterial(matPorts[i]);

                if (material !== undefined) {
                    model.SurfaceSelections?.push({ Name: matPorts[i].getName(), Value: material.name })
                }
            }

            const modelPorts = source.getModelPorts();

            for (let j = 0; j < modelPorts.length; j++) {
                const subModel = getModelPlacement(modelPorts[j], depth - 1);

                if (subModel !== undefined) {
                    model.ModelSelections?.push(subModel);
                }
            }

            return model;
        }
    }

    return undefined;
}

const getMaterial = (port: MaterialPortModel): MaterialViewDTO | undefined => {
    var link = port.links[Object.keys(port.links)[0]];

    if (link !== undefined) {
        var source = link.getSourcePort()?.getNode() as MaterialNodeModel;
        if (source !== undefined) {
            return source.data;
        }
    }

    return undefined;
}

const getModifier = (port: ModifierPortModel): ModifierViewDTO | undefined => {
    var link = port.links[Object.keys(port.links)[0]];

    if (link !== undefined) {
        var source = link.getSourcePort()?.getNode() as ModifierNodeModel;
        if (source !== undefined) {
            return source.data;
        }
    }

    return undefined;
}

export default NodeEditor;