/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InternalSceneCameraAspectRatioDTO } from '../models/InternalSceneCameraAspectRatioDTO';
import type { InternalSceneCameraAspectRatioDTOODataListResponse } from '../models/InternalSceneCameraAspectRatioDTOODataListResponse';
import type { SceneCameraAspectRatio } from '../models/SceneCameraAspectRatio';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalSceneCameraAspectRatioService {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns InternalSceneCameraAspectRatioDTOODataListResponse Success
     * @throws ApiError
     */
    public static getInternalSceneCameraAspectRatio(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<InternalSceneCameraAspectRatioDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/SceneCameraAspectRatio',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InternalSceneCameraAspectRatioDTO Success
     * @throws ApiError
     */
    public static postInternalSceneCameraAspectRatio(
requestBody?: InternalSceneCameraAspectRatioDTO,
): CancelablePromise<InternalSceneCameraAspectRatioDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal/SceneCameraAspectRatio',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns InternalSceneCameraAspectRatioDTO Success
     * @throws ApiError
     */
    public static getInternalSceneCameraAspectRatio1(
key: number,
): CancelablePromise<InternalSceneCameraAspectRatioDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/SceneCameraAspectRatio/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns InternalSceneCameraAspectRatioDTO Success
     * @throws ApiError
     */
    public static putInternalSceneCameraAspectRatio(
key: number,
requestBody?: InternalSceneCameraAspectRatioDTO,
): CancelablePromise<InternalSceneCameraAspectRatioDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal/SceneCameraAspectRatio/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteInternalSceneCameraAspectRatio(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal/SceneCameraAspectRatio/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param clientId 
     * @param query 
     * @returns SceneCameraAspectRatio Success
     * @throws ApiError
     */
    public static getInternalSceneCameraAspectRatioQuery(
clientId?: number,
query?: string,
): CancelablePromise<Array<SceneCameraAspectRatio>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/SceneCameraAspectRatio/query',
            query: {
                'clientId': clientId,
                'query': query,
            },
        });
    }

}
