import classnames from 'classnames';
import React, { useCallback, useContext } from 'react';
import './AssetList.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactComponent as LoadingIcon } from '../../assets/icons/loading.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { MetadataDTO } from '../../openapi/requests';
import { BASE_PATH } from '../..';
import { ConfigContext } from '../../contexts/ConfigContext';

const LoadingComponent = motion(LoadingIcon)

interface Asset {
    name?: string | null | undefined;
    title: string;
    thumbnail: string;
    thumbnailAspectRatio?: number;
    isVideoThumbnail?: boolean;
    metadata?: MetadataDTO[];
    isEnabled?: boolean;
    warning?: string;
}

type Props<T extends Asset> = {
    className?: string;
    allowMultiSelect?: boolean;
    assets: T[];
    selection?: T[];
    onItemClick?: (asset: T) => void;
    onItemInfoClick?: (asset: T) => void;
    onItemDoubleClick?: (asset: T) => void;
    onSelectionChanged?: (assets: T[]) => void;
    onItemMouseEnter?: (assets: T) => void;
    onItemMouseExit?: (assets: T) => void;
    displayMode: 'medium' | 'large' | 'list';
    loading?: boolean;
    renderOverlay?: (asset: T) => React.ReactElement;
}

const AssetList = <T extends Asset,>({ className, assets, selection, allowMultiSelect, onItemDoubleClick, onItemClick, onSelectionChanged, displayMode, loading, onItemInfoClick, onItemMouseEnter, onItemMouseExit, renderOverlay }: Props<T>) => {

    const { metadataKeys } = useContext(ConfigContext);

    const classes = classnames('assetlist', className, displayMode, {
        'multiselect': allowMultiSelect,
        'singleselect': !allowMultiSelect,
    });

    const handleClick = useCallback((asset: T) => {

        if (onItemClick) {
            onItemClick(asset);
        }

        if (onSelectionChanged) {
            if (allowMultiSelect && selection) {
                if (!selection.some(e => e.name === asset.name)) {
                    onSelectionChanged([...selection, asset]);
                } else {
                    onSelectionChanged(selection.filter(e => e.name !== asset.name));
                }

            } else {
                onSelectionChanged([asset]);
            }
        }
    }, [allowMultiSelect, onItemClick, onSelectionChanged, selection]);

    const handleDoubleClick = useCallback((asset: T) => {
        if (onSelectionChanged) {
            if (allowMultiSelect && selection) {
                if (!selection.some(e => e.name === asset.name)) {
                    onSelectionChanged([...selection, asset]);
                }
            } else {
                onSelectionChanged([asset]);
            }
        }

        if (onItemDoubleClick) {
            onItemDoubleClick(asset);
        }
    }, [allowMultiSelect, onItemDoubleClick, onSelectionChanged, selection]);

    const handleInfoClick = useCallback((e: React.MouseEvent<SVGSVGElement, MouseEvent>, asset: T) => {
        e.stopPropagation();
        if (onItemInfoClick) {
            onItemInfoClick(asset);
        }
    }, [onItemInfoClick]);

    return (
        <div className={classes}>
            <AnimatePresence>
                {loading && <LoadingComponent
                    className={'loading'}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key={'loading'} />}
            </AnimatePresence>

            {assets.map(asset => <div className={'asset' + (selection?.some(x => x.name === asset.name) ? ' selected' : '')}
                onClick={() => handleClick(asset)}
                onDoubleClick={() => handleDoubleClick(asset)}
                onPointerEnter={() => onItemMouseEnter && onItemMouseEnter(asset)}
                onPointerLeave={() => onItemMouseExit && onItemMouseExit(asset)}
                key={asset.name}>
                <div className='asset-image'>
                    <span className="check">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M3.5 8.5L6 11L12.5 4.5" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                    {(asset.isEnabled === false) && <span className='warning'>Not enabled for customers!</span>}
                    {(asset.warning) && <span className='warning'>{asset.warning}</span>}
                    {asset.isVideoThumbnail ? 
                        <video muted autoPlay loop src={BASE_PATH + asset.thumbnail} /> :
                        <LazyLoadImage 
                            style={{
                                aspectRatio: asset.thumbnailAspectRatio,
                                width: ((asset.thumbnailAspectRatio ?? 1) >= 1) ? '100%' : '',
                                height: ((asset.thumbnailAspectRatio ?? 1) < 1) ? '100%' : ''
                            }}
                            wrapperProps={{ style: { display: 'flex' } }}
                            src={BASE_PATH + asset.thumbnail} alt='' effect='opacity' threshold={0} />
                    }
                    {renderOverlay && renderOverlay(asset)}
                </div>
                <div className='asset-footer'>
                    <div className='asset-name'>
                        <span className='name'>{asset.title}</span>
                        <span className='ean'>{(asset.metadata ?? []).find(e => e.name === metadataKeys.itemlistDetail)?.value}</span>
                    </div>
                    {onItemInfoClick && <InfoIcon className='info' onClick={e => handleInfoClick(e, asset)} />}
                </div>
            </div>)}
        </div>
    )
};

export default AssetList;