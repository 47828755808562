import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, message, Select, Space } from 'antd';
import React, { useMemo } from 'react';
import { useInternalClientServiceGetInternalClient, useInternalTagServiceGetInternalTag, useModelPackServicePostModelPack } from '../../../openapi/queries';
import { ModelPackViewDTO } from '../../../openapi/requests';

const CreateModelpack: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
    const {data: clients} = useInternalClientServiceGetInternalClient({}, undefined, {enabled: isOpen});
    const {data: alltags} = useInternalTagServiceGetInternalTag({}, undefined, {enabled: isOpen});
    const {mutateAsync, isPending } = useModelPackServicePostModelPack();

    const [form] = Form.useForm<FormData>();
    const [messageApi, contextHolder] = message.useMessage();

    const tags = useMemo(() => {
        if(alltags){
            const tags = Array.from(new Set(alltags.value.map(e => e.value)));
            tags.sort();
            return tags;
        }
        return [];
    }, [alltags]);

    const onSubmit = () => {
        form.submit();
    };

    const onFinish = () => {
        const values = form.getFieldsValue(true) as ModelPackViewDTO;

        mutateAsync({ requestBody: values})
            .then(() => {
                messageApi.success("Model pack created");
                onClose();
            }).catch(reason => {
                messageApi.error(JSON.stringify(reason));
            });
    };

    return (
        <>
            {contextHolder}

            <Drawer
                title="Create a new model pack"
                width={720}
                onClose={onClose}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item rules={[{ required: true }]} name='clientId' label="Client">
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='title' label="Title">
                        <Input />
                    </Form.Item>

                    <Form.Item name='buildScript' label="build Script">
                        <Input />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='name' label="Name">
                        <Input />
                    </Form.Item>

                    <Form.Item name='tags' label="Tags">
                        <Select mode='tags' options={tags.map(e => ({ label: e, value: e }))} />
                    </Form.Item>

                    <Form.Item label='Metadata'>
                        <Form.List name="metadata">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="name" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="value" />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add metadata
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Form>
            </Drawer>
        </>

    );
};

export default CreateModelpack;