import { ProjectFolderViewDTO, ProjectShareDTO, ProjectShareService, ProjectViewDTO } from "../../openapi/requests";
import BottomDrawer from "../layout/BottomDrawer"
import { isProject } from '../../pages/Projects';
import { useState, useContext, useMemo } from "react";
import { useUserServiceGetUserClient, useUserServiceGetUserClientKey } from "../../openapi/queries";
import { DialogContext } from "../../contexts/DialogContext";
import { AuthContext } from "../../contexts/AuthContext";

interface Props {
    isOpen: boolean,
    projectFolderId?: string | null,
    projectOrFolder: ProjectViewDTO | ProjectFolderViewDTO | undefined | null,
    onShared: (projectOrFolder: ProjectViewDTO | ProjectFolderViewDTO | undefined | null) => void
    onClose: () => void;
}

export const ProjectShare: React.FC<Props> = ({ isOpen, projectFolderId, projectOrFolder, onClose, onShared }) => {

    const { username } = useContext(AuthContext);
    const [selectedUserId, setSelectedUserId] = useState<number>()
 //   const [canShare, setCanShare] = useState<boolean>(false)
    const { data: users, isPending: usersPending } = useUserServiceGetUserClient([useUserServiceGetUserClientKey, projectFolderId, projectOrFolder], { enabled: isOpen });  
    const { message } = useContext(DialogContext); 

    const filteredUsers = useMemo(() => {
        if (projectOrFolder?.isOwner){
            return users?.filter(x => x.username !== username)
        } else{
            return users;
        }
    },[projectOrFolder, username, users]);

    const handleShare = async (e: any) => {
        e.preventDefault();

        if (selectedUserId) {

            let projectId: number | undefined | null = undefined;
            let folderId: number | undefined  | null = undefined;

            if (projectOrFolder) {
                if (isProject(projectOrFolder)) {
                    projectId = projectOrFolder.id
                    if (projectFolderId)
                        folderId = parseInt(projectFolderId ?? "0")
                } else {
                    folderId = projectOrFolder.id
                }
            }

            let canShare = await ProjectShareService.getProjectShareValidateShareUser(selectedUserId, projectId, folderId);
            if (canShare) {
           
                let projectShareDto: ProjectShareDTO = await ProjectShareService.postProjectShare({ id: 0, userId: selectedUserId, projectId: projectId, projectFolderId: folderId})

                if (projectShareDto) {
                    message("Project shared", "Shared project/folder with user");                    
                } else {
                    message("Project not shared", "Project already shared with user");              
                }

               onShared(projectOrFolder);            
    
            } else {
                message("Project not shared","Selected user cannot access project/folder templates")              
            }
          //  setCanShare(canShare);
        }
    }

    return (
        <BottomDrawer className='createproject' open={isOpen} onClose={onClose} size={500}>
            <form onSubmit={handleShare}>
                <h1>User</h1>
                <select value={selectedUserId} onChange={e => setSelectedUserId(parseInt(e.target.value))}>
                    <option key={''} className='placeholder' value=''>None</option>
                    {filteredUsers?.map(e => <option key={e.id} value={e.id}>{e.username}</option>)}
                </select>

                <button disabled={usersPending} type='submit'>Share</button>
            </form>
        </BottomDrawer>
    )
}