import { Button, message, Popconfirm, Space, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { PlusOutlined } from '@ant-design/icons';
import CreateDeliveryConfiguration from './CreateDeliveryConfiguration';
import EditDeliveryConfiguration from './EditDeliveryConfiguration';
import { InternalDeliveryConfigurationDTO } from '../../../openapi/requests';
import { useInternalDeliveryConfigurationServiceDeleteInternalDeliveryConfiguration, useInternalDeliveryConfigurationServiceGetInternalDeliveryConfiguration } from '../../../openapi/queries';
import { FilterValue, SorterResult } from 'antd/es/table/interface';


const UserList: React.FC = () => {
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState<string>("id");
    const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");

    const { data, isLoading, refetch } = useInternalDeliveryConfigurationServiceGetInternalDeliveryConfiguration({ 
        top: pageSize.toString(), 
        skip: ((page - 1) * pageSize).toString(), 
        orderby: `${sortField} ${sortOrder}`,
        count: 'true' });

    const {mutate, isSuccess} = useInternalDeliveryConfigurationServiceDeleteInternalDeliveryConfiguration();

    const [messageApi, contextHolder] = message.useMessage();
    const [selectedDeliveryConfiguration, setSelectedDeliveryConfiguration] = useState<InternalDeliveryConfigurationDTO | undefined>(undefined);

    const [createOpen, setCreateOpen] = useState(false);

    const openCreate = () => {
        setCreateOpen(true);
    };

    const handleCreateClose = useCallback(() => {
        setCreateOpen(false);
        refetch();
    }, [refetch]);

    const handleEditClose = useCallback(() => {
        setSelectedDeliveryConfiguration(undefined);
        refetch();
    }, [refetch]);

    const handleDelete = useCallback((configuration: InternalDeliveryConfigurationDTO) => {
        if(configuration.id){
            mutate({key: configuration.id})
        }
    }, [mutate]);

    useEffect(() => {
        if(isSuccess){
            messageApi.success("Client deleted");
        }
    }, [isSuccess, messageApi]);
    
    const handleTableChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<InternalDeliveryConfigurationDTO> | SorterResult<InternalDeliveryConfigurationDTO>[]) => {
        setPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);

        if(!Array.isArray(sorter)){
            setSortField(sorter.field?.toString() ?? "id");
            setSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc');
        }
    }, []);

    const columns: ColumnsType<InternalDeliveryConfigurationDTO> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Client',
            dataIndex: 'client',
            key: 'clientId',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => setSelectedDeliveryConfiguration(record)}>Edit</Button>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                        <Button type="link">Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <>
            {contextHolder}

            <Button type="primary" onClick={openCreate} icon={<PlusOutlined />} style={{marginBottom: 16}}>
                New configuration
            </Button>

            <CreateDeliveryConfiguration isOpen={createOpen} onClose={handleCreateClose} />

            <EditDeliveryConfiguration isOpen={selectedDeliveryConfiguration != null} onClose={handleEditClose} deliveryConfiguration={selectedDeliveryConfiguration} />

            <Table
                loading={isLoading}
                dataSource={data?.value ?? Array.from({ length: pageSize }).map(x => ({}))}
                columns={columns}
                rowKey={"id"}
                onChange={handleTableChange}
                pagination={{
                    total: (data !== undefined && data['@odata.count']) ? data['@odata.count'] : 0,
                    current: page,
                    pageSize: pageSize,
                }} />
        </>
    );
};

export default UserList;