import { Alert, Button, Drawer, Form, message, Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAssetProjectServicePostAssetProjectImport } from '../../../openapi/queries';
import { ApiError, AssetProjectEditDTO, AssetProjectViewDTO } from '../../../openapi/requests';


const ImportAssetProject: React.FC<{ isOpen: boolean, project: AssetProjectViewDTO | undefined, onClose: () => void }> = ({ isOpen, onClose, project }) => {
    const [form] = Form.useForm<AssetProjectEditDTO>();
    const [file, setFile] = useState<File | undefined>(undefined);

    const {mutate, isPending, isSuccess, error} = useAssetProjectServicePostAssetProjectImport()

    const onSubmit = () => {
        form.submit();
    };

    const onFinish = () => {
        if(project){
            mutate({key: project.id, addnew: false, formData: {file}});
        }
    };

    useEffect(() => {
        if (isSuccess) {
            message.success("Assets updated");
            onClose();
        }
    }, [isSuccess, onClose]);

    const onFileChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;

        if (files && files.length !== 0) {
            setFile(files[0]);
        } else {
            setFile(undefined);
        }
    }, []);

    const errors: string[] | undefined = useMemo(() => {
        const apiError = error as ApiError;

        if(apiError){
            if(apiError.body && Array.isArray(apiError.body)){
                let errorMessages = apiError.body;
                return errorMessages.map(e => `line: ${e.line} -> ${e.message}`);
            }else{
                return [apiError.message];
            }
        }

        return undefined;
    }, [error]);

    return (
        <>
            <Drawer
                title="Add assets"
                width={720}
                onClose={onClose}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>
                    <Form.Item name='assets' label='Assets' >
                        <input type='file' accept='.xlsx' onChange={onFileChanged} />
                    </Form.Item>
                </Form>

                {errors && <Alert
                    message="Error"
                    description={errors?.map(e => <p>{e}</p>)}
                    type="error"
                    showIcon
                    />}
            </Drawer>
        </>

    );
};

export default ImportAssetProject;