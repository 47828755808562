/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetApprovalHistoryDTO } from '../models/AssetApprovalHistoryDTO';
import type { ModelPackEditDTO } from '../models/ModelPackEditDTO';
import type { ModelPackViewDTO } from '../models/ModelPackViewDTO';
import type { ModelPackViewDTOODataListResponse } from '../models/ModelPackViewDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ModelPackService {

    /**
     * @param query 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ModelPackViewDTOODataListResponse Success
     * @throws ApiError
     */
    public static getModelPack(
query?: string,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ModelPackViewDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ModelPack',
            query: {
                'query': query,
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ModelPackViewDTO Success
     * @throws ApiError
     */
    public static postModelPack(
requestBody?: ModelPackEditDTO,
): CancelablePromise<ModelPackViewDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ModelPack',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ModelPackViewDTO Success
     * @throws ApiError
     */
    public static getModelPack1(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ModelPackViewDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ModelPack/{key}',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns ModelPackViewDTO Success
     * @throws ApiError
     */
    public static putModelPack(
key: number,
requestBody?: ModelPackEditDTO,
): CancelablePromise<ModelPackViewDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ModelPack/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteModelPack(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ModelPack/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param clientId 
     * @param query 
     * @returns ModelPackViewDTO Success
     * @throws ApiError
     */
    public static getModelPackQuery(
clientId?: number,
query?: string,
): CancelablePromise<Array<ModelPackViewDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ModelPack/query',
            query: {
                'clientId': clientId,
                'query': query,
            },
        });
    }

    /**
     * @param key 
     * @param size 
     * @param format 
     * @returns any Success
     * @throws ApiError
     */
    public static getModelPackThumbnail(
key: number,
size: number = 256,
format: string = 'webp',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ModelPack/{key}/thumbnail/{size}',
            path: {
                'key': key,
            },
            query: {
                'size': size,
                'format': format,
            },
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static getModelPackImage(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ModelPack/{key}/image',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postModelPackImage(
key: number,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ModelPack/{key}/image',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postModelPackQAimages(
key: number,
formData?: {
files?: Array<Blob>;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ModelPack/{key}/QAimages',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @returns string Success
     * @throws ApiError
     */
    public static getModelPackQAimages(
key: number,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ModelPack/{key}/QAimages',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param index 
     * @returns any Success
     * @throws ApiError
     */
    public static getModelPackQAimages1(
key: number,
index: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ModelPack/{key}/QAimages/{index}',
            path: {
                'key': key,
                'index': index,
            },
        });
    }

    /**
     * @param key 
     * @param index 
     * @returns any Success
     * @throws ApiError
     */
    public static getModelPackApprovalHistoryImages(
key: number,
index: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ModelPack/{key}/ApprovalHistory/images/{index}',
            path: {
                'key': key,
                'index': index,
            },
        });
    }

    /**
     * @param key 
     * @returns AssetApprovalHistoryDTO Success
     * @throws ApiError
     */
    public static getModelPackApprovalHistory(
key: number,
): CancelablePromise<Array<AssetApprovalHistoryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ModelPack/{key}/ApprovalHistory',
            path: {
                'key': key,
            },
        });
    }

}
