import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react"
import React from "react"
import Sider from "antd/es/layout/Sider"
import { Button, Form, Layout, List, Select, Space, Tabs, TabsProps } from "antd"
import { Content } from "antd/es/layout/layout"
import "./HotspotEditor.scss"
import { ExpandOutlined, DeleteOutlined } from '@ant-design/icons';
import { InternalSceneCameraAspectRatioDTO, InternalSceneCameraAspectRatioService, InternalSceneCameraDTO, InternalSceneCameraService, InternalUisceneModifierTargetHotspotDTO, InternalUisceneModifierTargetHotspotService, InternalUiscenePlacementpointHotspotDTO, InternalUiscenePlacementpointHotspotService, InternalUiscenePropsetHotspotDTO, InternalUiscenePropsetHotspotService, InternalUisceneSurfaceHotspotDTO, InternalUisceneSurfaceHotspotService, SceneCameraDTO, SceneModifierTargetDTO, ScenePlacementpointDTO, ScenePropsetDTO, SceneSurfaceDTO, SceneViewDTO } from "../../../../../openapi/requests"
import { BASE_PATH } from "../../../../.."

export type tabs = 'placementpoints' | 'propsets' | 'surfaces';

interface Hotspot {
    id: number,
    placementPointId: number,
    type: string,
    label: string,
    posX: number,
    posY: number,
    rectX1: number,
    rectY1: number,
    rectX2: number,
    rectY2: number
}

const ScaleableRectangle: React.FC<{ item: Hotspot | undefined | null, hotspots: Hotspot[], hotspotsRef: MutableRefObject<HTMLElement | null>, hotspotElementsRef: MutableRefObject<HTMLElement[] | null[]>, onSave: (item: Hotspot) => void }> = ({ item, hotspotsRef, onSave }) => {

    const [rectX1, setRectX1] = useState(0);
    const [rectY1, setRectY1] = useState(0);
    const [rectX2, setRectX2] = useState(0);
    const [rectY2, setRectY2] = useState(0);
    const [isDragging, setIsDragging] = useState(false)
    const [currentHandle, setCurrentHandle] = useState<HTMLElement | null>()

    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    const [bottom, setBottom] = useState(0);
    const [right, setRight] = useState(0);


    var containerWidth = hotspotsRef?.current?.clientWidth ?? 0;
    var containerHeight = hotspotsRef?.current?.clientHeight ?? 0;

    const scaleRectHandlesRef = useRef<HTMLElement[] | null[]>([] as HTMLDivElement[])
    const scaleRectRef = useRef<HTMLDivElement>({} as HTMLDivElement)
    const scaleRectContainerRef = useRef<HTMLDivElement>({} as HTMLDivElement)

    useEffect(() => {
        if (item) {

            var handleElem = scaleRectHandlesRef.current[0]
            if (handleElem) {

                if (item.rectX1 === 0 &&
                    item.rectY1 === 0 &&
                    item.rectX2 === 0 &&
                    item.rectY2 === 0) {

                    item.rectX1 = (item.posX) - 0.035;
                    item.rectY1 = (item.posY) - 0.035;
                    item.rectX2 = (item.posX) + 0.035;
                    item.rectY2 = (item.posY) + 0.035;
                }

                setRectX1(item.rectX1);
                setRectY1(item.rectY1);
                setRectX2(item.rectX2);
                setRectY2(item.rectY2);

                setRectangleDimensions(item);

            }
        }
    }, [item, rectX1, rectX2, rectY1, rectY2, containerWidth, containerHeight]);

    const setRectangleDimensions = (item: Hotspot | undefined | null) => {

        if (item) {
            if (item.rectX1 >= item.rectX2) {
                setLeft(item.rectX2)
                setRight(item.rectX1)
            } else {
                setLeft(item.rectX1)
                setRight(item.rectX2)
            }

            if (item.rectY1 >= item.rectY2) {
                setTop(item.rectY2)
                setBottom(item.rectY1)
            } else {
                setTop(item.rectY1)
                setBottom(item.rectY2)
            }
        }

    }

    const onHandleStartDrag = (e: React.MouseEvent, index: number) => {

        if (e.preventDefault) {
            e.preventDefault();
        }

        scaleRectHandlesRef.current.forEach(handle => {
            handle?.classList.add("dragging")
        });

        var handleElem = scaleRectHandlesRef.current[index]    
        setCurrentHandle(handleElem)
        setIsDragging(true)
    }

    const onHandleDrag = (e: React.MouseEvent) => {

        if (e.preventDefault) {
            e.preventDefault();
        }

        if (isDragging && item) {
            if (currentHandle) {
                if (hotspotsRef.current) {

                    var rect = hotspotsRef.current.getBoundingClientRect();
                    var mouseX = ((e.clientX - rect.left) / containerWidth);
                    var mouseY = ((e.clientY - rect.top) / containerHeight);

                    if (currentHandle) {
                        var index = parseInt(currentHandle.getAttribute("data-index") ?? "0")

                        if (index === 0) {
                            item.rectX1 = mouseX;
                            item.rectY1 = mouseY;
                            setRectX1(mouseX)
                            setRectY1(mouseY)
                        }
                        if (index === 1) {
                            item.rectX2 = mouseX;
                            item.rectY1 = mouseY;
                            setRectX2(mouseX)
                            setRectY1(mouseY)
                        }
                        if (index === 2) {
                            item.rectX1 = mouseX;
                            item.rectY2 = mouseY;
                            setRectX1(mouseX)
                            setRectY2(mouseY)
                        }
                        if (index === 3) {
                            item.rectX2 = mouseX;
                            item.rectY2 = mouseY;
                            setRectX2(mouseX)
                            setRectY2(mouseY)
                        }

                        setRectangleDimensions(item);
                    }
                }
            }
        }
    }

    const onHandleDragEnd = (e: React.MouseEvent) => {

        if (e.preventDefault) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (isDragging) {
            
            scaleRectHandlesRef.current.forEach(handle => {
                handle?.classList.remove("dragging")
            });
    
            setCurrentHandle(null)
            setIsDragging(false)

            if (item) {

                item.rectX1 = left;
                item.rectX2 = right;
                item.rectY1 = top;
                item.rectY2 = bottom;

                onSave(item);
            }
        }
    }

    return (
        <div ref={scaleRectContainerRef} className="scale-rect-container" onMouseMove={(e) => onHandleDrag(e)} onMouseUp={(e) => onHandleDragEnd(e)}>
            <div className="scale-handle" draggable="true" data-index={0} ref={(element) => { scaleRectHandlesRef.current[0] = element }} onMouseDown={(e) => onHandleStartDrag(e, 0)} data-posx={item?.rectX1} data-posy={item?.rectY1} style={{ top: (top * 100) + "%", left: (left * 100) + "%" }}></div>
            <div className="scale-handle" draggable="true" data-index={1} ref={(element) => { scaleRectHandlesRef.current[1] = element }} onMouseDown={(e) => onHandleStartDrag(e, 1)} data-posx={item?.rectX2} data-posy={item?.rectY1} style={{ top: (top * 100) + "%", left: (right * 100) + "%" }}></div>
            <div className="scale-handle" draggable="true" data-index={2} ref={(element) => { scaleRectHandlesRef.current[2] = element }} onMouseDown={(e) => onHandleStartDrag(e, 2)} data-posx={item?.rectX1} data-posy={item?.rectY2} style={{ top: (bottom * 100) + "%", left: (left * 100) + "%" }}></div>
            <div className="scale-handle" draggable="true" data-index={3} ref={(element) => { scaleRectHandlesRef.current[3] = element }} onMouseDown={(e) => onHandleStartDrag(e, 3)} data-posx={item?.rectX2} data-posy={item?.rectY2} style={{ top: (bottom * 100) + "%", left: (right * 100) + "%" }}></div>
            <div className="scale-rect" ref={scaleRectRef} style={{ top: (top * 100) + "%", left: (left * 100) + "%", bottom: ((1 - bottom) * 100) + "%", right: ((1 - right) * 100) + "%" }}></div>
        </div>
    )
}

const HotspotItemsList: React.FC<{
    type: string,
    selectedHotspot: Hotspot | undefined | null,
    isEditing: boolean,
    hotspots: Hotspot[] | undefined | null,
    items: ScenePlacementpointDTO[] | ScenePropsetDTO[] | SceneSurfaceDTO[] | SceneModifierTargetDTO[] | undefined,
    onDragStart: (e: React.DragEvent<HTMLElement>, item: { id: number, type: string, label: string }) => void,
    onSelect: (item: Hotspot) => void,
    onEdit: (item: Hotspot) => void,
    onDelete: (item: Hotspot) => void
}> = ({ type, isEditing, selectedHotspot, hotspots, items, onDragStart, onSelect, onEdit, onDelete }) => {

    const placementPoints = items?.map(x => { return { id: x.id, type: type, label: x.label } })

    const onPlacementPointSelect = (e: React.MouseEvent, item: { id: number, type: string, label: string }) => {

        e.preventDefault();

        var hotspot: Hotspot | undefined = hotspots?.find(x => x.placementPointId === item.id && x.type === item.type)
        if (hotspot) {
            onSelect(hotspot)
        }
    }

    const onPlacementPointEdit = (e: React.MouseEvent, item: { id: number, type: string, label: string }) => {

        e.preventDefault();
        e.stopPropagation();

        var hotspot: Hotspot | undefined = hotspots?.find(x => x.placementPointId === item.id && x.type === item.type)
        if (hotspot) {
            onEdit(hotspot)
        }
    }

    const onPlacementDelete = (e: React.MouseEvent, item: { id: number, type: string, label: string }) => {
        e.preventDefault();
        e.stopPropagation();

        var hotspot: Hotspot | undefined = hotspots?.find(x => x.placementPointId === item.id && x.type === item.type)
        if (hotspot) {
            onDelete(hotspot)
        }
    }

    const hasPlacementPoint = useCallback((itemId: number) => {
        return hotspots?.map(x => x.placementPointId).includes(itemId);
    }, [hotspots])

    return (
        <List className="placementpoint-list"
            itemLayout="horizontal"
            dataSource={placementPoints}
            renderItem={(item) => (
                <List.Item className={`draggable${item.id === selectedHotspot?.placementPointId ? ' active' : ''}`} draggable={!hasPlacementPoint(item.id) ? true : false} onClick={e => onPlacementPointSelect(e, item)} onDragStart={e => onDragStart(e, item)}>
                    <List.Item.Meta
                        title={item.label}>
                    </List.Item.Meta>
                    <Space>
                        <Button title="Delete" disabled={!hasPlacementPoint(item.id)} onClick={(e) => onPlacementDelete(e, item)}>
                            <DeleteOutlined />
                        </Button>
                        {item.id === selectedHotspot?.placementPointId &&
                            <Button type={isEditing ? "primary" : "default"} title="ZoomRect" onClick={(e) => onPlacementPointEdit(e, item)}>
                                <ExpandOutlined />
                            </Button>
                        }
                    </Space>
                </List.Item>
            )}
        />
    )
}

const DisplayAspectRatio: React.FC<{ aspectRatio: number | undefined | null, imageWidth: number, imageHeight: number }> = ({ aspectRatio, imageWidth, imageHeight }) => {

    let clipPath = ""
    if (aspectRatio) {

        if (aspectRatio < 1) {

            const cropLeft = ((Math.abs(imageHeight - (imageHeight * aspectRatio)) / 2) / imageHeight) * 100;
            const cropRight = 100 - cropLeft;

            //clipPath = `polygon(0% 0%, 0% 100%, 25% 100%, 25% 0%, 75% 0%, 75% 100%, 100% 100%, 100% 0%)`
            clipPath = `polygon(0% 0%, 0% 100%, ${cropLeft}% 100%, ${cropLeft}% 0%, ${cropRight}% 0%, ${cropRight}% 100%, 100% 100%, 100% 0%)`


        }
        if (aspectRatio >= 1) {

            const cropTop = ((Math.abs(imageWidth - (imageWidth / aspectRatio)) / 2) / imageWidth) * 100;
            const cropBottom = 100 - cropTop;

            //clipPath = `polygon(0% 0%, 100% 0%, 100% 25%, 0% 25%, 0% 75%, 100% 75%, 100% 100%, 0% 100%)`
            clipPath = `polygon(0% 0%, 100% 0%, 100% ${cropTop}%, 0% ${cropTop}%, 0% ${cropBottom}%, 100% ${cropBottom}%, 100% 100%, 0% 100%)`

        }
    }

    return (
        <div className="aspect-overlay" style={{ "clipPath": clipPath }}>
        </div>
    )
}

const HotspotEditor: React.FC<{ camera: SceneCameraDTO | undefined, scene: SceneViewDTO | undefined }> = ({ camera, scene }) => {

    const draggerRef = useRef({} as HTMLElement);
    const hotspotsRef = useRef({} as HTMLDivElement);
    const hotspotsImageRef = useRef({} as HTMLImageElement);
    const hotspotElementsRef = useRef<HTMLElement[] | null[]>([])

    const [currentTab, setcurrentTab] = useState<tabs>('placementpoints');

    const [scenePlacementpointHotspots, setScenePlacementpointHotspots] = useState<InternalUiscenePlacementpointHotspotDTO[]>([])
    const [scenePropSetHotspots, setScenePropSetHotspots] = useState<InternalUiscenePropsetHotspotDTO[]>([])
    const [sceneSurfaceHotspots, setSceneSurfaceHotspots] = useState<InternalUisceneSurfaceHotspotDTO[]>([])
    const [sceneModifierTargetHotspots, setSceneModifierTargetHotspots] = useState<InternalUisceneModifierTargetHotspotDTO[]>([])
    const [sceneCameraAspectRatios, setSceneCameraAspectRatios] = useState<InternalSceneCameraAspectRatioDTO[]>([])

    const [selectedAspectRatio, setSelectedAspectRatio] = useState<number | null>()

    const [hotspots, setHotspots] = useState<Hotspot[]>([])
    const [selectedHotspot, setSelectedHotspot] = useState<Hotspot | null>()
    const [isDragging, setIsDragging] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    const onChangeTab = (e: string) => {
        setSelectedHotspot(null)
        setIsEditing(false)
        setcurrentTab(e as tabs)
    }

    const onChangeAspectRatio = async (value: number) => {

        if (value) {

            if (camera) {
                (await InternalSceneCameraService.putInternalSceneCamera(camera.id, { id: camera.id, aspectRatio: value, cameraName: camera.cameraName, label: camera.label, sceneId: scene?.id } as InternalSceneCameraDTO))
            }

            setSelectedAspectRatio(value)
        }
    }

    const onDragNewHotspotStart = (e: React.DragEvent<HTMLElement>, item: { id: number, type: string, label: string }) => {

        setIsEditing(false)

        e.dataTransfer.setDragImage(draggerRef.current, 0, 0);
        e.dataTransfer.dropEffect = "move";
        e.dataTransfer.setData("hotspot", JSON.stringify({ 'placementPointId': item.id, 'type': item.type, 'label': item.label }))

        const hotspot = { type: item.type, placementPointId: item.id, label: item.label, posX: 0, posY: 0, rectX1: 0, rectY1: 0, rectX2: 0, rectY2: 0 } as Hotspot
        setHotspots([...hotspots, hotspot])
        setSelectedHotspot(hotspot)

    }

    const onDragHotspotStart = (e: React.DragEvent<HTMLElement>, item: { id: number, placementPointId: number, type: string, label: string }) => {

        setIsEditing(false)
        setIsDragging(true)

        e.dataTransfer.dropEffect = "move";
        e.dataTransfer.setData("hotspot", JSON.stringify({ 'id': item.id, 'placementPointId': item.placementPointId, 'type': item.type, 'label': item.label }))

        var hotspot = hotspots.find(x => x.id === item.id && x.placementPointId === item.placementPointId && x.type === item.type);
        if (hotspot) {
            setSelectedHotspot(hotspot)
        }

    }

    const onDragHotspotOver = (e: React.DragEvent<HTMLElement>) => {

        if (isEditing)
            return false;

        if (e.preventDefault) {
            e.preventDefault();
        }

        var rect = e.currentTarget.getBoundingClientRect();
        var mouseX = e.clientX - rect.left;
        var mouseY = e.clientY - rect.top;

        var containerWidth = hotspotsRef.current.clientWidth;
        var containerHeight = hotspotsRef.current.clientHeight;

        if (selectedHotspot) {
            selectedHotspot.posY = (mouseY / containerHeight)
            selectedHotspot.posX = (mouseX / containerWidth)
        }
    }

    const onDragHotspotDrop = (e: React.DragEvent<HTMLElement>) => {

        if (isEditing)
            return false;

        var hotspotData = JSON.parse(e.dataTransfer.getData("hotspot"))
        var hotspot = hotspots.find(x => x.id === hotspotData.id && x.placementPointId === hotspotData.placementPointId && x.type === hotspotData.type)
        if (hotspot) {
            saveHotspot(hotspot)
            setSelectedHotspot(null)
            setIsDragging(false)
        }
    }

    const saveHotspot = async (item: Hotspot) => {
        if (item) {

            const promises: Promise<any>[] = [];

            switch (item.type) {
                case "placementpoints":
                    var scenePlacementPointHotspot = scenePlacementpointHotspots.find(x => x.id === item?.id && x.sceneplacementpointId === item.placementPointId);
                    if (!scenePlacementPointHotspot) {
                        let newHotspot = {
                            scenecameraId: camera?.id,
                            sceneplacementpointId: item.placementPointId,
                            posX: item.posX,
                            posY: item.posY,
                            rectX1: item.rectX1,
                            rectY1: item.rectY1,
                            rectX2: item.rectX2,
                            rectY2: item.rectY2
                        } as InternalUiscenePlacementpointHotspotDTO;
                        promises.push( InternalUiscenePlacementpointHotspotService.postInternalUiscenePlacementpointHotspot(newHotspot))
                    } else {
                        let existingHotspot = {
                            id: item.id,
                            scenecameraId: camera?.id,
                            sceneplacementpointId: item.placementPointId,
                            posX: item.posX,
                            posY: item.posY,
                            rectX1: item.rectX1,
                            rectY1: item.rectY1,
                            rectX2: item.rectX2,
                            rectY2: item.rectY2
                        } as InternalUiscenePlacementpointHotspotDTO;
                        promises.push(InternalUiscenePlacementpointHotspotService.putInternalUiscenePlacementpointHotspot(scenePlacementPointHotspot.id, existingHotspot))
                    }
                    break;
                case "propsets":
                    var scenePropSetHotspot = scenePropSetHotspots.find(x => x.id === item?.id && x.scenepropsetId === item.placementPointId);
                    if (!scenePropSetHotspot) {
                        let newHotspot = {
                            scenecameraId: camera?.id,
                            scenepropsetId: item.placementPointId,
                            posX: item.posX,
                            posY: item.posY,
                            rectX1: item.rectX1,
                            rectY1: item.rectY1,
                            rectX2: item.rectX2,
                            rectY2: item.rectY2
                        } as InternalUiscenePropsetHotspotDTO;
                        promises.push(InternalUiscenePropsetHotspotService.postInternalUiscenePropsetHotspot(newHotspot))
                    } else {
                        let existingHotspot = {
                            id: item.id,
                            scenecameraId: camera?.id,
                            scenepropsetId: item.placementPointId,
                            posX: item.posX,
                            posY: item.posY,
                            rectX1: item.rectX1,
                            rectY1: item.rectY1,
                            rectX2: item.rectX2,
                            rectY2: item.rectY2
                        } as InternalUiscenePropsetHotspotDTO;
                        promises.push(InternalUiscenePropsetHotspotService.putInternalUiscenePropsetHotspot(scenePropSetHotspot.id, existingHotspot))
                    }

                    break;
                case "surfaces":
                    var sceneSurfaceHotspot = sceneSurfaceHotspots.find(x => x.id === item?.id && x.scenesurfaceId === item.placementPointId);
                    if (!sceneSurfaceHotspot) {
                        let newHotspot = {
                            scenecameraId: camera?.id,
                            scenesurfaceId: item.placementPointId,
                            posX: item.posX,
                            posY: item.posY,
                            rectX1: item.rectX1,
                            rectY1: item.rectY1,
                            rectX2: item.rectX2,
                            rectY2: item.rectY2
                        } as InternalUisceneSurfaceHotspotDTO;
                        promises.push(InternalUisceneSurfaceHotspotService.postInternalUisceneSurfaceHotspot(newHotspot))
                    } else {
                        let existingHotspot = {
                            id: item.id,
                            scenecameraId: camera?.id,
                            scenesurfaceId: item.placementPointId,
                            posX: item.posX,
                            posY: item.posY,
                            rectX1: item.rectX1,
                            rectY1: item.rectY1,
                            rectX2: item.rectX2,
                            rectY2: item.rectY2
                        } as InternalUisceneSurfaceHotspotDTO;
                        promises.push(InternalUisceneSurfaceHotspotService.putInternalUisceneSurfaceHotspot(sceneSurfaceHotspot.id, existingHotspot))
                    }

                    break;
                case "modifiertargets":
                    var sceneModifierTargetHotspot = sceneModifierTargetHotspots.find(x => x.id === item?.id && x.scenemodifiertargetId === item.placementPointId);
                    if (!sceneModifierTargetHotspot) {
                        let newHotspot = {
                            scenecameraId: camera?.id,
                            scenemodifiertargetId: item.placementPointId,
                            posX: item.posX,
                            posY: item.posY,
                            rectX1: item.rectX1,
                            rectY1: item.rectY1,
                            rectX2: item.rectX2,
                            rectY2: item.rectY2
                        } as InternalUisceneModifierTargetHotspotDTO;
                        promises.push(InternalUisceneModifierTargetHotspotService.postInternalUisceneModifierTargetHotspot(newHotspot))
                    } else {
                        let existingHotspot = {
                            id: item.id,
                            scenecameraId: camera?.id,
                            scenemodifiertargetId: item.placementPointId,
                            posX: item.posX,
                            posY: item.posY,
                            rectX1: item.rectX1,
                            rectY1: item.rectY1,
                            rectX2: item.rectX2,
                            rectY2: item.rectY2
                        } as InternalUisceneModifierTargetHotspotDTO;
                        promises.push(InternalUisceneModifierTargetHotspotService.putInternalUisceneModifierTargetHotspot(sceneModifierTargetHotspot.id, existingHotspot))
                    }
                    break;
            }

            await Promise.all(promises)
            loadHotspots();

        }
    }

    const onHotspotEdit = (item: Hotspot) => {
        setSelectedHotspot(item)
        setIsEditing(!isEditing)
    }

    const onHotspotSelect = (item: Hotspot) => {
        setSelectedHotspot(item)
        setIsEditing(false);
    }

    const onHotspotDelete = async (item: Hotspot) => {

        if (item) {

            const promises: Promise<any>[] = [];

            switch (item.type) {
                case "placementpoints":
                    promises.push(InternalUiscenePlacementpointHotspotService.deleteInternalUiscenePlacementpointHotspot(item.id));
                    break;
                case "propsets":
                    promises.push(InternalUiscenePropsetHotspotService.deleteInternalUiscenePropsetHotspot(item.id));
                    break;
                case "surfaces":
                    promises.push(InternalUisceneSurfaceHotspotService.deleteInternalUisceneSurfaceHotspot(item.id));
                    break;
                case "modifiertargets":
                    promises.push(InternalUisceneModifierTargetHotspotService.deleteInternalUisceneModifierTargetHotspot(item.id));
                    break;
            }

            await Promise.all(promises);
            setSelectedHotspot(null);
            loadHotspots();
        }
    }

    const onScaleRectSave = (item: Hotspot) => {
        saveHotspot(item);
    }

    const loadHotspots = useCallback(async () => {

        const sceneCameraAspectRatios = (await InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatio()).value;
        setSceneCameraAspectRatios(sceneCameraAspectRatios)

        if (camera) {
            const sceneCamera = (await InternalSceneCameraService.getInternalSceneCamera1(camera.id))
            setSelectedAspectRatio(sceneCamera?.aspectRatio)
        }

        const placementPoints = (await InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspot(undefined, undefined, `ScenecameraId eq ${camera?.id}`)).value
        setScenePlacementpointHotspots(placementPoints)

        const propSetPoints = (await InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspot(undefined, undefined, `ScenecameraId eq ${camera?.id}`)).value
        setScenePropSetHotspots(propSetPoints)

        const surfacePoints = (await InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspot(undefined, undefined, `ScenecameraId eq ${camera?.id}`)).value
        setSceneSurfaceHotspots(surfacePoints)

        const modifierTargetPoints = (await InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspot(undefined, undefined, `ScenecameraId eq ${camera?.id}`)).value
        setSceneModifierTargetHotspots(modifierTargetPoints)

    }, [camera])

    useEffect(() => {

        let tmpHotspots = [];
        tmpHotspots = [...scenePlacementpointHotspots.map(x => { return { id: x.id, placementPointId: x.sceneplacementpointId, type: "placementpoints", label: scene?.placementpoints.find(p => p.id === x.sceneplacementpointId)?.label || "", posX: x.posX, posY: x.posY, rectX1: x.rectX1, rectY1: x.rectY1, rectX2: x.rectX2, rectY2: x.rectY2 } as Hotspot })]
        tmpHotspots = [...scenePropSetHotspots.map(x => { return { id: x.id, placementPointId: x.scenepropsetId, type: "propsets", label: scene?.placementpoints.find(p => p.id === x.scenepropsetId)?.label || "", posX: x.posX, posY: x.posY, rectX1: x.rectX1, rectY1: x.rectY1, rectX2: x.rectX2, rectY2: x.rectY2 } as Hotspot }), ...tmpHotspots]
        tmpHotspots = [...sceneSurfaceHotspots.map(x => { return { id: x.id, placementPointId: x.scenesurfaceId, type: "surfaces", label: scene?.placementpoints.find(p => p.id === x.scenesurfaceId)?.label || "", posX: x.posX, posY: x.posY, rectX1: x.rectX1, rectY1: x.rectY1, rectX2: x.rectX2, rectY2: x.rectY2 } as Hotspot }), ...tmpHotspots]
        tmpHotspots = [...sceneModifierTargetHotspots.map(x => { return { id: x.id, placementPointId: x.scenemodifiertargetId, type: "modifiertargets", label: scene?.placementpoints.find(p => p.id === x.scenemodifiertargetId)?.label || "", posX: x.posX, posY: x.posY, rectX1: x.rectX1, rectY1: x.rectY1, rectX2: x.rectX2, rectY2: x.rectY2 } as Hotspot }), ...tmpHotspots]
        setHotspots(tmpHotspots)

    }, [scenePlacementpointHotspots, scenePropSetHotspots, sceneSurfaceHotspots, sceneModifierTargetHotspots, scene])

    useEffect(() => {

        loadHotspots();

    }, [camera, loadHotspots])

    const items: TabsProps['items'] = [
        {
            key: "placementpoints",
            label: "Placement Points",
            children: <HotspotItemsList type="placementpoints" isEditing={isEditing} selectedHotspot={selectedHotspot} onDragStart={onDragNewHotspotStart} onSelect={onHotspotSelect} onEdit={onHotspotEdit} onDelete={onHotspotDelete} hotspots={hotspots} items={scene?.placementpoints} />
        },
        {
            key: "propsets",
            label: "Propsets",
            children: <HotspotItemsList type="propsets" isEditing={isEditing} selectedHotspot={selectedHotspot} onDragStart={onDragNewHotspotStart} onSelect={onHotspotSelect} onEdit={onHotspotEdit} onDelete={onHotspotDelete} hotspots={hotspots} items={scene?.propsets} />
        },
        {
            key: "surfaces",
            label: "Surfaces",
            children: <HotspotItemsList type="surfaces" isEditing={isEditing} selectedHotspot={selectedHotspot} onDragStart={onDragNewHotspotStart} onSelect={onHotspotSelect} onEdit={onHotspotEdit} onDelete={onHotspotDelete} hotspots={hotspots} items={scene?.surfaces} />
        },
        {
            key: "modifiertargets",
            label: "Modifiertargets",
            children: <HotspotItemsList type="modifiertargets" isEditing={isEditing} selectedHotspot={selectedHotspot} onDragStart={onDragNewHotspotStart} onSelect={onHotspotSelect} onEdit={onHotspotEdit} onDelete={onHotspotDelete} hotspots={hotspots} items={scene?.modifierTargets} />
        }
    ]

    return (
        <React.Fragment key={camera?.id}>
            <Layout style={{ width: '100%', height: '75vh' }} className="hotspots-editor">
                <Sider style={{ background: "white", padding: "20px" }} width={450}>
                    <Form layout="vertical" >
                        <Form.Item name="aspectRatio" valuePropName="" label="Aspect Ratio">
                            <Select
                                allowClear
                                value={selectedAspectRatio}
                                onChange={e => onChangeAspectRatio(e)}
                                options={sceneCameraAspectRatios.map(e => ({ value: e.ratio, label: e.name }))} />
                        </Form.Item>
                    </Form>
                    <Tabs activeKey={currentTab} onTabClick={(e) => onChangeTab(e as tabs)} items={items} />
                </Sider>
                <Content>
                    <div className="hotspots-container">
                        <div ref={hotspotsRef} className="hotspots" onDragOver={e => onDragHotspotOver(e)} onDrop={e => onDragHotspotDrop(e)}>
                            <img ref={hotspotsImageRef} src={`${BASE_PATH}/camera/${camera?.id}/image`} alt="sceneCameraImage" />
                            <DisplayAspectRatio aspectRatio={selectedAspectRatio} imageWidth={hotspotsImageRef.current.clientWidth} imageHeight={hotspotsImageRef.current.clientHeight} />
                            {hotspots?.map((item, index) => (
                                <div
                                    data-posx={item.posX}
                                    data-posy={item.posY}
                                    ref={element => hotspotElementsRef.current[index] = element}
                                    onClick={() => onHotspotSelect(item)}
                                    className={`hotspot-item ${item.type === currentTab ? ' active' : ''}${(item.id === selectedHotspot?.id && item.placementPointId === selectedHotspot?.placementPointId && item.type === selectedHotspot?.type) ? ' selected' : ''}${(item.id === selectedHotspot?.id && item.placementPointId === selectedHotspot?.placementPointId && item.type === selectedHotspot?.type && isDragging) ? ' dragging' : ''}`}
                                    draggable={item.type === currentTab} style={{ top: (item.posY * 100) + "%", left: (item.posX * 100) + "%" }}
                                    onDragStart={e => { onDragHotspotStart(e, item) }}>
                                </div>
                            ))}
                            {isEditing && <ScaleableRectangle item={selectedHotspot} hotspotElementsRef={hotspotElementsRef} hotspotsRef={hotspotsRef} hotspots={hotspots} onSave={onScaleRectSave} />}
                        </div>
                    </div>
                    <span ref={draggerRef} className="hotspots-drag-img"></span>
                </Content>
            </Layout>
        </React.Fragment>
    )
}

export default HotspotEditor;