import { Button, DatePicker, Drawer, Form } from 'antd';
import React, { useState } from 'react';
import { BASE_PATH } from '../../..';
import saveAs from 'file-saver';
import { Dayjs } from 'dayjs';
const { MonthPicker,  } = DatePicker;

const ExportKingfisherProjects: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm<{month: Dayjs}>();

    const onFinish = () => {
        const data = form.getFieldsValue(true) as {month: Dayjs};

        const token = localStorage.getItem('accessToken');
        let url = new URL(`${BASE_PATH}/Export/kingfisher/projects`);

        url.searchParams.append("start", data.month.startOf('month').toDate().toISOString());
        url.searchParams.append("end", data.month.endOf('month').toDate().toISOString());

        setLoading(true);

        fetch(url, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob, `projects ${data.month.format('MMMM')}.xlsx`);
            setLoading(false);
        });
    };

    return (
        <>
            <Drawer
                title={"Export projects"}
                width={720}
                onClose={onClose}
                open={isOpen}
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item name="month">
                        <MonthPicker />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Download
                        </Button>
                    </Form.Item>

                </Form>
            </Drawer>
        </>

    );
};

export default ExportKingfisherProjects;