/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeliveryConfigurationDTO } from '../models/DeliveryConfigurationDTO';
import type { DeliveryConfigurationDTOODataListResponse } from '../models/DeliveryConfigurationDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeliveryConfigurationService {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns DeliveryConfigurationDTOODataListResponse Success
     * @throws ApiError
     */
    public static getDeliveryConfiguration(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<DeliveryConfigurationDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/DeliveryConfiguration',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @returns DeliveryConfigurationDTO Success
     * @throws ApiError
     */
    public static getDeliveryConfiguration1(
key: number,
): CancelablePromise<DeliveryConfigurationDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/DeliveryConfiguration/{key}',
            path: {
                'key': key,
            },
        });
    }

}
