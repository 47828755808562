import { SelectProps, Select, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { GenerateODataFilter } from "../../../helpers/odataFunctions";
import { useModifierServiceGetModifier, useModifierServiceGetModifier1 } from "../../../openapi/queries";

export const ModifierSelector: React.FC<SelectProps<number, DefaultOptionType>> = (props) => {
    const [value, setValue] = useState("");
    const [debouncedValue] = useDebounce(value, 1000);

    const { data: modifier } = useModifierServiceGetModifier1({
        key: props.value ?? 0
    });

    const { data, isLoading } = useModifierServiceGetModifier({
        top: '50',
        filter: GenerateODataFilter([{ type: 'search', values: [debouncedValue], property: 'title,metadata.ean,metadata.product_type_primary,metadata.product_type_secondary', name: 'title' }]),
    }, undefined, { enabled: debouncedValue.length > 0 });

    return (
        <Select
            showSearch
            filterOption={false}
            onSearch={setValue}
            notFoundContent={isLoading ? <Spin size="small" /> : "No matches"}
            {...props}
            options={[{ label: modifier?.title, value: modifier?.id }, ...(data?.value.map(e => ({ label: e.title, value: e.id })) ?? [])].filter(e => e.value !== undefined)}
        />
    );
}