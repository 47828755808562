import format from "date-fns/format";
import { saveAs } from 'file-saver';
import { useCallback, useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router";
import { BASE_PATH } from "../..";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { OrderlineFileType, ProjectStatus, ProjectStatusLabels } from "../../models/enums";
import { OrderDTO, OrderlineService, OrderlineViewDTO, ProjectService, ProjectViewDTO, TemplateService } from "../../openapi/requests";
import IssueReporting from "../layout/IssueReporting";
import "../layout/elements/Lists.scss";
import { OrderlineList } from "./OrderlineList";
import "./ProjectDetail.scss";

interface Props {
    projectId?: number;
}

export const ProjectDetail: React.FC<Props> = ({ projectId }) => {

    const { hasPermission } = useContext(AuthContext);

    const navigate = useNavigate();
    const [files, setFiles] = useState<OrderlineViewDTO[]>([]);
    const [project, setProject] = useState<ProjectViewDTO | undefined>();
    const [order, setOrder] = useState<OrderDTO | undefined>();
    const [hasMoodboard, setHasMoodboard] = useState(false);
    const [projectFilesOrdered, setProjectFilesOrdered] = useState(0);
    const [projectFilesProduced, setProjectFilesProduced] = useState(0);
    const [lastUpdate, setLastUpdate] = useState(new Date());

    const [loading, setLoading] = useState<boolean>(false);

    const getProjectFile = useCallback((orderline: OrderlineViewDTO) => {
        return { type: orderline.fileType as unknown as OrderlineFileType, files: orderline.files.flat(1), orderline }
    }, []);

    useEffect(() => {
        setFiles([]);
        setProject(undefined);
        setOrder(undefined);
        setLoading(true)

        if (projectId) {
            ReactGA.event('open_dialog', {dialog: 'projectdetails'});

            ProjectService.getProject1(projectId).then(project => {
                setProject(project);

                ProjectService.getProjectOrders(project.id).then(orders => {

                    const sortedOrders = orders.sort((a, b) => {
                        return new Date(b.timestampCreate).getTime() - new Date(a.timestampCreate).getTime();
                    });

                    const lastOrder = sortedOrders[0];
                    setOrder(lastOrder);

                    const getFiles = async () => {
                        let orderLines: OrderlineViewDTO[] = [];
                        let lastDelivery: OrderlineViewDTO[] | undefined = undefined;

                        for (let i = 0; i < sortedOrders.length; i++) {
                            let order = orders[i];

                            let lines = await OrderlineService.getOrderOrderline(order.id);

                            orderLines = [...orderLines, ...lines];

                            if (lastDelivery === undefined && lines.some(e => e.files.length > 0)) {
                                lastDelivery = lines;
                            }

                            if (i === 0) {
                                var template = await TemplateService.getTemplate1(lines[0].templateId);

                                if (template?.editorModule === "cutout" || template?.title.toLowerCase().includes("cutout")) {
                                    setHasMoodboard(false);
                                } else {
                                    setHasMoodboard(true);
                                }

                                setLastUpdate(lines.reduce((i, e) => new Date(e.timestampUpdate) > i ? new Date(e.timestampUpdate) : i, new Date("1900-01-01")))
                            }
                        }

                        setProjectFilesOrdered(orderLines.reduce((i, e) => i + e.files.length, 0));
                        setProjectFilesProduced(orderLines.filter(x => x.status === 20).reduce((i, e) => i + e.files.length, 0));

                        if (lastDelivery) {
                            setFiles(lastDelivery);
                        }
                        setLoading(false);
                    }

                    getFiles();
                });


            })

        }
    }, [projectId, getProjectFile]);

    const onMoodboardClick = () => {
        if (project && (hasPermission("FinalRender") || hasPermission("PriorityRender") || hasPermission("PreviewRender"))) {
            navigate("/create/edit/" + project.id);
        }
    }

    const onDownloadAll = async (id: number) => {
        ReactGA.event('download_project_files', {});
        const token = localStorage.getItem('accessToken');

        fetch(`${BASE_PATH}/Project/${id}/files`, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob);
        });
    }

    const onDownloadPowerpoint = async () => {
        ReactGA.event('download_project_powerpoint', {});
        const token = localStorage.getItem('accessToken');

        fetch(`${BASE_PATH}/Project/${projectId}/powerpoint`, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob);
        });
    }



    return (
        <div className="project-detail">
            <div className="project-detail-header">
                <h1>{project?.name}</h1>
            </div>

            <div className="project-content-wrapper">
                <div>
                    <div className="info-list">
                        <div className="info-list-header"><span>Basics</span></div>
                        <ul>
                            <li>
                                <span>Name</span>
                                <span>{project?.name}</span>
                            </li>
                            <li>

                                <span>Order By</span>
                                <span>{project?.modifiedUserName}</span>
                            </li>
                            <li>

                                <span>Order Time</span>
                                <span>{format(new Date(order?.timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                            </li>
                            <li>

                                <span>Notifications</span>
                                <span>{order?.notificationRecipients}</span>
                            </li>
                            <li>
                                <span>Status</span>
                                <span>{ProjectStatusLabels[(project?.status ?? 0) as ProjectStatus]}</span>
                            </li>
                            <li>

                                <span>Last Status Update</span>
                                <span>{format(lastUpdate, "dd.MM.yyyy - pp")}</span>
                            </li>
                            <li>

                                <span>Files Ordered</span>
                                <span>{projectFilesOrdered}</span>
                            </li>
                            <li>

                                <span>Files Produced</span>
                                <span>{projectFilesProduced}</span>
                            </li>
                        </ul>
                    </div>

                    {hasMoodboard &&
                        <div className="info-list">
                            <div className="info-list-header">Moodboard</div>
                            <button className="project-info-list-img" onClick={() => onMoodboardClick()}>
                                <div className="project-edit-overlay">
                                    <span>
                                        <Edit />
                                    </span>
                                </div>
                                <img src={BASE_PATH + "/project/" + project?.id + "/moodboard"} alt="Edit project" />
                            </button>
                        </div>
                    }

                </div>

                <div className="info-list project-images-list">
                    <div className="info-list-header">
                        <span>Images created with this scene</span>
                        <span>
                            <IssueReporting type='project' projectId={projectId} className="rounded" />
                            <button className="btn" onClick={() => { onDownloadAll(project?.id ?? 0) }}>Download all</button>
                            <button className="btn" onClick={onDownloadPowerpoint}>Download as powerpoint</button>
                        </span>
                    </div>
                    {loading &&
                        <div className="loading"><Loading /></div>
                    }
                    <OrderlineList orderlines={files} order={order} />
                    {(files?.length === 0 && !loading) &&
                        <div className="project-images-list-no-images">
                            <span>No available images</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}