import React from 'react';
import AssetProjectList from '../../../components/admin/assetProjects/AssetProjectList';

const AssetProjectsPage: React.FC = () => {

    return (<div>
        <AssetProjectList />
    </div>)
};

export default AssetProjectsPage;