export enum AssetApprovalStatus {
    New = 0,
    Rejected = 10,
    RejectedButModified = 11,
    RejectedPendingModification = 12,
    Approved = 20,
    ApprovedButModified = 21,
    ApprovedPendingModification = 22,
    Quote = 30,
    Pending = 40,
    InProduction = 50,
    Commited = 60
}

export var AssetApprovalStatusLabels: { [key in AssetApprovalStatus]: string } = {
    0: "New",
    10: "Rejected",
    11: "Rejected but modified",
    12: "Rejected, with pending changes",
    20: "Approved",
    21: "Approved but modified",
    22: "Approved, with pending changes",
    30: "Quote",
    40: "Pending",
    50: "In production",
    60: "Commited"
}

export enum ProjectStatus {
    Draft = 0,
    RenderingPreview = 10,
    RenderingFinal = 20,
    PreviewComplete = 40,
    FinalComplete = 50,
    Failed = 99,
}

export const ProjectStatusLabels: { [key in ProjectStatus]: string } = {
    0: 'Draft',
    10: 'Rendering preview',
    20: 'Rendering final images',
    40: 'Preview complete',
    50: 'Final images complete',
    99: 'Failed',
}

export enum OrderStatus {
    Draft = 0,
    Placed = 10,
    Delegating = 20,
    Processing = 30,
    Declined = 40,
    ReadyForQA = 45,
    Deliverable = 50,
    DeliveryFailed = 53,
    Delivered = 60,
    Failed = 99
}

export const OrderStatusLabels: { [key in OrderStatus]: string } = {
    0: 'Draft',
    10: 'Placed',
    20: 'Delegating',
    30: 'Processing',
    40: 'Declined',
    45: 'ReadyForQA',
    50: 'Deliverable',
    53: 'DeliveryFailed',
    60: 'Delivered',
    99: 'Failed'
}

export enum OrderlineFileType {
    Still,
    Cutout,
    Spin,
    Clip,
    Animation
}

export enum AssetProjectStatus {
    New = 0,
    Quote = 1,
    InProduction = 2,
}

export var AssetProjectStatusLabels: { [key in AssetProjectStatus]: string } = {
    0: "New",
    1: "Quote",
    2: "In Production"
}

export enum AssetSourceType
{
    Unknown = 0,
    ExistingAsset = 1,
    Workflow = 2,
    None = 3,
    CADFile = 4,
    MaxFile = 5,
}

export var AssetSourceTypeLabels: { [key in AssetSourceType]: string } = {
    0: "Unknown",
    1: "Existing CoD asset",
    2: "Workflow",
    3: "None",
    4: "CAD File",
    5: "Max File"
}