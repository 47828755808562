import saveAs from 'file-saver';
import React, { useContext, useRef, useState } from 'react';
import { BASE_PATH } from "../..";
import { useProjectFolderServicePostProjectFolderTransferToKingfisherDam, useProjectFolderServicePostProjectFolderUploadToKingfisherDam, useProjectServicePostProjectUploadToKingfisherDam } from '../../openapi/queries';
import { ApiError, ExcelValidationMessage, ProjectFolderViewDTO, ProjectViewDTO } from '../../openapi/requests';
import { isProject } from '../../pages/Projects';
import { Dialog } from '../layout/Dialog';
import './CreateProject.scss';
import BottomDrawer from '../layout/BottomDrawer';
import { DialogContext } from '../../contexts/DialogContext';
import { ReactComponent as LoadingIcon } from '../../assets/icons/loading.svg';
import { motion } from 'framer-motion';
const LoadingComponent = motion(LoadingIcon)

interface Props {
    isOpen: boolean;
    onClose: () => void;
    projectOrFolder: ProjectViewDTO | ProjectFolderViewDTO | undefined | null;
}

const UploadToDam: React.FC<Props> = ({ isOpen, onClose, projectOrFolder }) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [showUploadError, setShowUploadError] = useState(false);
    const [uploadErrors, setUploadErrors] = useState<ExcelValidationMessage[]>([]);
    const [loading, setLoading] = useState(false);
    const { mutateAsync: uploadFolderAsync, isPending: uploadingFolder } = useProjectFolderServicePostProjectFolderUploadToKingfisherDam();
    const { mutateAsync: uploadProjectAsync, isPending: uploadingProject } = useProjectServicePostProjectUploadToKingfisherDam();
    const { mutateAsync: transferAsync, isPending: transfering } = useProjectFolderServicePostProjectFolderTransferToKingfisherDam();
    const { message } = useContext(DialogContext);

    const downloadDamSheet = () => {
        if (projectOrFolder) {
            setLoading(true);
            const token = localStorage.getItem('accessToken');
            const url = isProject(projectOrFolder) ?
                `${BASE_PATH}/Project/${projectOrFolder?.id}/kingfisherDAMSheet` :
                `${BASE_PATH}/Projectfolder/${projectOrFolder?.id}/kingfisherDAMSheet`;
            let filename = '';

            fetch(url, {
                method: 'get',
                headers: {
                    "Authorization": 'Bearer ' + token
                }
            }).then(res => {
                const header = res.headers.get('Content-Disposition');
                setLoading(false);
                if(header){
                    const parts = header.split(';');
                    filename = parts[1].split('=')[1];
                    filename = filename.replaceAll("\"", "");
                }
                return res.blob();
            }).then(blob => {
                saveAs(blob, filename);
            });
        }
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && projectOrFolder) {
            const file = e.target.files[0];

            if (isProject(projectOrFolder)) {
                uploadProjectAsync({ key: projectOrFolder.id, formData: { file } })
                    .then(() => {
                        message('File uploaded', 'Excel file uploaded, and images added to uploaded queue');
                    })
                    .catch((reason: ApiError) => {
                        setShowUploadError(true);
                        if (Array.isArray(reason.body)) {
                            setUploadErrors(reason.body as ExcelValidationMessage[]);
                        } else {
                            setUploadErrors([{ message: JSON.stringify(reason.body), line: undefined }]);
                        }
                    })
            } else {
                uploadFolderAsync({ key: projectOrFolder.id, formData: { file } })
                    .then(() => {
                        message('Transfer started', 'Files are being transfered to the DAM now');
                    })
                    .catch((reason: ApiError) => {
                        setShowUploadError(true);
                        if (Array.isArray(reason.body)) {
                            setUploadErrors(reason.body as ExcelValidationMessage[]);
                        } else {
                            setUploadErrors([{ message: JSON.stringify(reason.body), line: undefined }]);
                        }
                    })
            }
        }
    };

    const handleTransfer = async () => {
        if (projectOrFolder) {
            transferAsync({ key: projectOrFolder.id.toString() });
        }
    };

    const handleUpload = () => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    }

    return (
        <>
            <BottomDrawer open={isOpen} onClose={onClose} size={300}>
                <div className='wizard'>
                    <div className='wizard-step'>
                        <h1>1</h1>
                        <button className='primary button' onClick={downloadDamSheet} disabled={loading}>
                            {loading ? <LoadingComponent /> : 'Download DAM excel sheet'}
                            </button>
                    </div>

                    <div className='wizard-step'>
                        <h1>2</h1>
                        <input id="file" type="file" onChange={handleFileChange} style={{ display: 'none' }} ref={hiddenFileInput} />
                        <button className='primary button' onClick={handleUpload} disabled={uploadingFolder || uploadingProject}>Upload Excel</button>
                    </div>

                    <div className='wizard-step'>
                        <h1>3</h1>
                        <button className='primary button' onClick={handleTransfer} disabled={transfering}>Transfer to DAM</button>
                    </div>
                </div>
            </BottomDrawer>
            <Dialog open={showUploadError} className='confirm'>
                <h1>The following errors where found</h1>
                <p className='confirm-body'>
                    {uploadErrors.map(e => <p key={e.line}>
                        {e.line ? `line ${e.line}: ` : ''}{e.message}
                    </p>)}
                </p>

                <div className='confirm-footer'>
                    <button className='confirm' onClick={() => setShowUploadError(false)}>Ok</button>
                </div>
            </Dialog>
        </>
    )
};

export default UploadToDam;