/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeliveryMethodFtp } from '../models/DeliveryMethodFtp';
import type { InternalDeliveryMethodFtpDTO } from '../models/InternalDeliveryMethodFtpDTO';
import type { InternalDeliveryMethodFtpDTOODataListResponse } from '../models/InternalDeliveryMethodFtpDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalDeliveryMethodFtpService {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns InternalDeliveryMethodFtpDTOODataListResponse Success
     * @throws ApiError
     */
    public static getInternalDeliveryMethodFtp(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<InternalDeliveryMethodFtpDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/DeliveryMethodFtp',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InternalDeliveryMethodFtpDTO Success
     * @throws ApiError
     */
    public static postInternalDeliveryMethodFtp(
requestBody?: InternalDeliveryMethodFtpDTO,
): CancelablePromise<InternalDeliveryMethodFtpDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal/DeliveryMethodFtp',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns InternalDeliveryMethodFtpDTO Success
     * @throws ApiError
     */
    public static getInternalDeliveryMethodFtp1(
key: number,
): CancelablePromise<InternalDeliveryMethodFtpDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/DeliveryMethodFtp/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns InternalDeliveryMethodFtpDTO Success
     * @throws ApiError
     */
    public static putInternalDeliveryMethodFtp(
key: number,
requestBody?: InternalDeliveryMethodFtpDTO,
): CancelablePromise<InternalDeliveryMethodFtpDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal/DeliveryMethodFtp/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteInternalDeliveryMethodFtp(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal/DeliveryMethodFtp/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param clientId 
     * @param query 
     * @returns DeliveryMethodFtp Success
     * @throws ApiError
     */
    public static getInternalDeliveryMethodFtpQuery(
clientId?: number,
query?: string,
): CancelablePromise<Array<DeliveryMethodFtp>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/DeliveryMethodFtp/query',
            query: {
                'clientId': clientId,
                'query': query,
            },
        });
    }

}
