import { Drawer, Input, message, Select, Space } from 'antd';
import React from 'react';
import { Button, Form } from 'antd';
import { useInternalClientServiceGetInternalClient, useTemplateServicePostTemplate, useInternalTemplateTypeServiceGetInternalTemplateType, useSceneServiceGetScene } from '../../../openapi/queries';
import { TemplateEditDTO } from '../../../openapi/requests';


const CreateTemplate: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
    const {data: clients} = useInternalClientServiceGetInternalClient({}, undefined, {enabled: isOpen});
    const {data: templateTypes} = useInternalTemplateTypeServiceGetInternalTemplateType({}, undefined, {enabled: isOpen});
    const {data: scenes} = useSceneServiceGetScene({}, undefined, {enabled: isOpen});
    const [form] = Form.useForm<TemplateEditDTO>();
    const [messageApi, contextHolder] = message.useMessage();
    const {mutateAsync, isPending } = useTemplateServicePostTemplate();

    const onSubmit = () => {
        form.submit();
    };

    const onFinish = () => {
        const template = form.getFieldsValue(true) as TemplateEditDTO;

        mutateAsync({requestBody: template})
            .then(() => {
                messageApi.success("template created");
                onClose();
            }).catch(reason => {
                messageApi.error(JSON.stringify(reason));
            });
    };

    return (
        <>
            {contextHolder}

            <Drawer
                title="Create a new template"
                width={720}
                onClose={onClose}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item rules={[{required: true}]} name='clientId' label="Client">
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>

                    <Form.Item rules={[{required: true}]} name='title' label="Title">
                        <Input />
                    </Form.Item>

                    <Form.Item rules={[{required: true}]} name='templatetypeId' label="Template type">
                        <Select options={templateTypes?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>

                    <Form.Item name='sceneId' label="Scene">
                        <Select allowClear options={scenes?.value.map(e => ({ label: e.title, value: e.id }))} />
                    </Form.Item>

                    <Form.Item name='editorModule' label="Editor module">
                        <Input />
                    </Form.Item>
                </Form>
            </Drawer>
        </>

    );
};

export default CreateTemplate;