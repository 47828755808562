import React, { createContext, FC, useCallback, useState } from "react";
import { ConfirmDialog, MessageDialog } from "../components/layout/Dialog";

export interface DialogContextState {
    message: (title: string, message: React.ReactNode) => void;
    error: (message: string, error?: unknown) => void;
    confirm: (title: string, message: React.ReactNode) => Promise<any>;
};

const contextDefaultValues: DialogContextState = {
    confirm: () => Promise.resolve(),
    error: () => {},
    message: () => {},
};


interface MessageBox {
    title: string;
    message: React.ReactNode;
    details: string | undefined;
    type: 'error' | 'message';
    id: number;
    open: boolean;
}
interface ConfirmBoxes {
    title: string;
    message: React.ReactNode;
    id: number;
    resolve: (value: unknown) => any;
    reject: (reason: unknown) => any;
    open: boolean;
}

export const DialogContext = createContext<DialogContextState>(
    contextDefaultValues
);

export const DialogProvider: FC<{ children: React.ReactNode }> = ({ children }) => {

    const [messageBoxes, setMessageBoxes] = useState<MessageBox[]>([]);
    const [confirmBoxes, setConfirmBoxes] = useState<ConfirmBoxes[]>([]);

    const message = useCallback((title: string, message: React.ReactNode) => {
        setMessageBoxes([...messageBoxes, {
            id: Math.random(), 
            message, 
            title, 
            type: 'message',
            open: true,
            details: undefined
        }]);
    }, [messageBoxes]);

    const error = useCallback((message?: string, error?: unknown) => {
        setMessageBoxes([...messageBoxes, {
            id: Math.random(), 
            message: message || 'Help us improve your experience by sending an error report', 
            title: 'We have a little problem.', 
            type: 'error',
            open: true,
            details: ((error instanceof Error) ? 
                `${error.message}\r\n\r\n${error.stack}` : 
                ((error === undefined || error === null) ? undefined : error.toString()))
        }]);
    }, [messageBoxes]);

    const confirm = useCallback((title: string, message: React.ReactNode): Promise<any> => {
        const promise = new Promise((resolve, reject) => {
            setConfirmBoxes([...confirmBoxes, {
                id: Math.random(), 
                message, 
                title, 
                reject,
                resolve,
                open: true,
            }]);
        });
          
        return promise;
    }, [confirmBoxes]);

    const handleClose = useCallback((box: MessageBox) => {
        setMessageBoxes(messageBoxes.map(e => e.id === box.id ? {...e, open: false} : e));

        setTimeout(() => {
            setMessageBoxes(messageBoxes.filter(e => e.id !== box.id));
        }, 500);
    }, [messageBoxes]);

    const handleResolve = useCallback((box: ConfirmBoxes) => {
        setConfirmBoxes(confirmBoxes.map(e => e.id === box.id ? {...e, open: false} : e));
        box.resolve(null);

        setTimeout(() => {
            setConfirmBoxes(confirmBoxes.filter(e => e.id !== box.id));
        }, 500);
    }, [confirmBoxes]);

    const handleReject = useCallback((box: ConfirmBoxes) => {
        setConfirmBoxes(confirmBoxes.map(e => e.id === box.id ? {...e, open: false} : e));
        box.reject(null);

        setTimeout(() => {
            setConfirmBoxes(confirmBoxes.filter(e => e.id !== box.id));
        }, 500);
    }, [confirmBoxes]);

    return (
        <DialogContext.Provider
            value={{
                message,
                error,
                confirm
            }}
        >
            {messageBoxes.map(e => <MessageDialog 
                key={e.id} 
                open={e.open} 
                text={e.message} 
                title={e.title}
                details={e.details}
                onConfirm={() => handleClose(e)} />)}

            {confirmBoxes.map(e => <ConfirmDialog 
                key={e.id} 
                open={e.open} 
                text={e.message} 
                title={e.title} 
                onConfirm={() => handleResolve(e)} 
                onCancel={() => handleReject(e)} />)}

            {children}
        </DialogContext.Provider>
    );
};