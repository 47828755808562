import { AbstractModelFactory } from "@projectstorm/react-canvas-core";
import { DiagramEngine } from "@projectstorm/react-diagrams-core";
import { ConfiguratorPortModel } from "./ConfiguratorPort";
import { MaterialService } from "../../../../../openapi/requests";

export class MaterialPortModel extends ConfiguratorPortModel {
    constructor(isIn: boolean, name: string, label: string, onSelected: (port: ConfiguratorPortModel) => void, filter: string) {
        super('material', isIn, name, label, onSelected, filter);
    }

	onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
		if(this.in){
			if(this.filter && this.compatibleItems === undefined){
				MaterialService.getMaterial(this.filter).then(resp => {
					this.compatibleItems = {
						materials: resp.value.map(e => e.id),
						modelPacks: [],
						models: [],
						modifiers: []
					}
	
					super.onClick(e);
				});
			}

			super.onClick(e);
		}
	}
}

export class MaterialPortFactory extends AbstractModelFactory<MaterialPortModel, DiagramEngine> {
	constructor() {
		super('material');
	}

	generateModel(): MaterialPortModel {
		return new MaterialPortModel(true, 'unknown', 'unknown', () => {}, '');
	}
}