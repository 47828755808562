/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetApprovalHistoryDTO } from '../models/AssetApprovalHistoryDTO';
import type { SceneEditDTO } from '../models/SceneEditDTO';
import type { SceneViewDTO } from '../models/SceneViewDTO';
import type { SceneViewDTOODataListResponse } from '../models/SceneViewDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SceneService {

    /**
     * @param query 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns SceneViewDTOODataListResponse Success
     * @throws ApiError
     */
    public static getScene(
query?: string,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<SceneViewDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Scene',
            query: {
                'query': query,
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SceneViewDTO Success
     * @throws ApiError
     */
    public static postScene(
requestBody?: SceneEditDTO,
): CancelablePromise<SceneViewDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Scene',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns SceneViewDTO Success
     * @throws ApiError
     */
    public static getScene1(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<SceneViewDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Scene/{key}',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns SceneViewDTO Success
     * @throws ApiError
     */
    public static putScene(
key: number,
requestBody?: SceneEditDTO,
): CancelablePromise<SceneViewDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/Scene/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteScene(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Scene/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param id 
     * @param size 
     * @returns any Success
     * @throws ApiError
     */
    public static getCameraThumbnail(
id: number,
size: number = 256,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/camera/{id}/thumbnail/{size}',
            path: {
                'id': id,
            },
            query: {
                'size': size,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static getCameraImage(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/camera/{id}/image',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postCameraImage(
key: number,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/camera/{key}/image',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static getCameraAnimationpreview(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/camera/{id}/animationpreview',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postCameraAnimationpreview(
key: number,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/camera/{key}/animationpreview',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id 
     * @param size 
     * @returns any Success
     * @throws ApiError
     */
    public static getAnimationThumbnail(
id: number,
size: number = 256,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/animation/{id}/thumbnail/{size}',
            path: {
                'id': id,
            },
            query: {
                'size': size,
            },
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postAnimationThumbnail(
key: number,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/animation/{key}/thumbnail',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postSceneRenderings(
key: number,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Scene/{key}/renderings',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @returns string Success
     * @throws ApiError
     */
    public static getSceneRenderings(
key: number,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Scene/{key}/renderings',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param index 
     * @returns any Success
     * @throws ApiError
     */
    public static getSceneRenderings1(
key: number,
index: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Scene/{key}/renderings/{index}',
            path: {
                'key': key,
                'index': index,
            },
        });
    }

    /**
     * @param key 
     * @param index 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteSceneRenderings(
key: number,
index: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Scene/{key}/renderings/{index}',
            path: {
                'key': key,
                'index': index,
            },
        });
    }

    /**
     * @param clientId 
     * @param query 
     * @returns SceneViewDTO Success
     * @throws ApiError
     */
    public static getSceneQuery(
clientId?: number,
query?: string,
): CancelablePromise<Array<SceneViewDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Scene/query',
            query: {
                'clientId': clientId,
                'query': query,
            },
        });
    }

    /**
     * @param key 
     * @param size 
     * @param format 
     * @returns any Success
     * @throws ApiError
     */
    public static getSceneThumbnail(
key: number,
size: number = 256,
format: string = 'webp',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Scene/{key}/thumbnail/{size}',
            path: {
                'key': key,
            },
            query: {
                'size': size,
                'format': format,
            },
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static getSceneImage(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Scene/{key}/image',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postSceneImage(
key: number,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Scene/{key}/image',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postSceneQAimages(
key: number,
formData?: {
files?: Array<Blob>;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Scene/{key}/QAimages',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @returns string Success
     * @throws ApiError
     */
    public static getSceneQAimages(
key: number,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Scene/{key}/QAimages',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param index 
     * @returns any Success
     * @throws ApiError
     */
    public static getSceneQAimages1(
key: number,
index: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Scene/{key}/QAimages/{index}',
            path: {
                'key': key,
                'index': index,
            },
        });
    }

    /**
     * @param key 
     * @param index 
     * @returns any Success
     * @throws ApiError
     */
    public static getSceneApprovalHistoryImages(
key: number,
index: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Scene/{key}/ApprovalHistory/images/{index}',
            path: {
                'key': key,
                'index': index,
            },
        });
    }

    /**
     * @param key 
     * @returns AssetApprovalHistoryDTO Success
     * @throws ApiError
     */
    public static getSceneApprovalHistory(
key: number,
): CancelablePromise<Array<AssetApprovalHistoryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Scene/{key}/ApprovalHistory',
            path: {
                'key': key,
            },
        });
    }

}
