import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { ReactComponent as NextIcon } from '../../assets/icons/next.svg';
import { ReactComponent as PrevIcon } from '../../assets/icons/prev.svg';
import { Point, SceneDesignerContext } from '../../contexts/SceneDesignerContext';
import './SceneDesigner.scss';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { AnimatePresence, motion } from 'framer-motion';
import { BASE_PATH } from '../..';
import classNames from 'classnames';
import { ConfigContext } from '../../contexts/ConfigContext';

export type assetType = 'model' | 'modelpack' | 'material' | 'modifier' | 'propset' | undefined;

type Margins = {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

type Position = {
    top: number;
    left: number;
    width: number;
    height: number;
}

interface Props {
    selectedPoint: Point | undefined;
    hoveredPoint: string | undefined;
    onPointSelected: (point: Point) => void;
    onPointMouseOver: (point: Point) => void;
    onPointMouseOut: (point: Point) => void;
}


const Designer: React.FC<Props> = ({ selectedPoint, onPointSelected, hoveredPoint, onPointMouseOver, onPointMouseOut }) => {
    const { loading, selectedCamera, configuration, setSelectedCamera, products } = useContext(SceneDesignerContext);
    const { featureFlags } = useContext(ConfigContext);
    const { width, height, ref } = useResizeDetector();
    const windowSize = useWindowDimensions();
    const [margins, setMargins] = useState<Margins>({ bottom: 0, left: 0, right: 0, top: 0 });
    const [position, setPosition] = useState<Position>({ height: 0, left: 0, top: 0, width: 0 });
    const [containerPosition, setContainerPosition] = useState<Position | undefined>(undefined);
    const containerRef = useRef<HTMLDivElement>(null);

    const points = products.filter(e => e.x && e.y);

    useEffect(() => {
        let top = 0;
        let right = 0;
        let bottom = 0;
        let left = 0;

        if (selectedCamera) {
            if (selectedCamera.aspectRatio < 1) {
                left = (Math.abs(1 - (selectedCamera.aspectRatio)) / 2) * 100;
                right = left;
            } else {
                top = (Math.abs(1 - (1 / selectedCamera.aspectRatio)) / 2) * 100;
                bottom = top;
            }
        }

        if (selectedPoint && selectedPoint.view) {
            top = selectedPoint.view.top;
            right = selectedPoint.view.right;
            bottom = selectedPoint.view.bottom;
            left = selectedPoint.view.left;
        }

        if (selectedPoint) {
            if (containerRef.current) {
                const div = containerRef.current;
                const rect = div.getBoundingClientRect();
                setContainerPosition({ top: -rect.y, left: -rect.x, height: windowSize.height - 200, width: windowSize.width - 630 });
            }
        } else {
            setContainerPosition(undefined);
        }

        setMargins({ top, right, bottom, left });

        if (width && height) {
            let viewWidth = ((100 - (left + right)) / 100);
            let viewHeight = ((100 - (top + bottom)) / 100);

            let scaleX = 1 / viewWidth;
            let scaleY = 1 / viewHeight;

            let size = width * scaleX;
            if (size * viewHeight > height) {
                size = height * scaleY;
            }

            let canvasLeft = -(left / 100) * size;
            let canvasTop = -(top / 100) * size;

            canvasLeft -= (viewWidth / 2) * size;
            canvasTop -= (viewHeight / 2) * size;

            canvasLeft += width / 2;
            canvasTop += height / 2;

            setPosition({
                height: size,
                width: size,
                left: canvasLeft,
                top: canvasTop
            });
        }
    }, [height, selectedCamera, selectedPoint, width, windowSize.width, windowSize.height, ref]);

    const handlePrevClick = useCallback(() => {
        if (selectedPoint) {
            let index = products.findIndex(e => e.configuration.id === selectedPoint.configuration.id);
            index--;

            if (index < 0) {
                index = products.length - 1;
            }

            onPointSelected(products[index]);
        }
    }, [onPointSelected, products, selectedPoint]);

    const handleNextClick = useCallback(() => {
        if (selectedPoint) {
            let index = products.findIndex(e => e.configuration.id === selectedPoint.configuration.id);
            index++;

            if (index >= products.length) {
                index = 0;
            }

            onPointSelected(products[index]);
        }
    }, [onPointSelected, products, selectedPoint]);

    if (loading) {
        return <div>Loading</div>
    }
    if (!selectedCamera) {
        return <div></div>
    }

    let rendering = `${BASE_PATH}${selectedCamera.image}`;

    if (featureFlags.UseLatestRenderingInMoodboard && selectedCamera.latestRendering !== undefined) {
        rendering = `${BASE_PATH}${selectedCamera.latestRendering}`;
    }

    return (
        <div className={classNames({'designer-container': true, 'open': selectedPoint !== undefined})}>
            <div className='angle-selector'>
                {configuration.cameras.map(cam => <div key={cam.camera.cameraName} className={classNames({'angle': true, 'selected': selectedCamera.cameraName === cam.camera.cameraName})} onClick={() => setSelectedCamera(cam.camera)}>
                    {cam.camera.isAnimated ? 
                        <video src={BASE_PATH + cam.camera.animationPreview} loop autoPlay muted style={{ aspectRatio: cam.camera.aspectRatio }} /> :
                        <img src={BASE_PATH + cam.camera.image} alt={cam.camera.label} style={{ aspectRatio: cam.camera.aspectRatio }} /> }
                </div>)}
            </div>
            <AnimatePresence>
                {selectedPoint !== undefined && <motion.div
                    className='designer-overlay'
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0 }}
                    key={'overlay'} />}
            </AnimatePresence>

            <div className='designer-viewport' key='viewport' ref={containerRef}>
                <div className='canvas-container' style={containerPosition} ref={ref}>
                    <div className='canvas' style={{ clipPath: `inset(${margins.top}% ${margins.right}% ${margins.bottom}% ${margins.left}%)`, ...position }}>
                        <img className='camera-view' src={rendering} alt='' />
                        <div className='hotspots'>
                            {points.map(e => <div
                                key={e.configuration.id}
                                title={e.label}
                                onClick={() => onPointSelected(e)}
                                onPointerEnter={() => onPointMouseOver(e)}
                                onPointerLeave={() => onPointMouseOut(e)}
                                className={classNames({
                                    'hotspot': true,
                                    'changed': e.configuration.hasChanged,
                                    'hover': hoveredPoint && (hoveredPoint + '.').startsWith(e.configuration.id + ".")
                                })}
                                style={{ left: `${e.x! * 100}%`, top: `${e.y! * 100}%` }}>
                                {e.configuration.id}
                            </div>)}
                        </div>
                    </div>
                    <div className='pagination'>
                        <span className='current'>{((products.findIndex(e => e.configuration.id === selectedPoint?.configuration.id) ?? 0) + 1).toString().padStart(2, '0')}</span>
                        <span className='count'>{products.length.toString().padStart(2, '0')}</span>
                    </div>
                    <div className='navigation'>
                        <button onClick={handlePrevClick}><PrevIcon /></button>
                        <button onClick={handleNextClick}><NextIcon /></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Designer;