import { Checkbox, Drawer, Input, message, Select, Space } from 'antd';
import React, { useState } from 'react';
import { Button, Form } from 'antd';
import Title from 'antd/es/typography/Title';
import { InternalDeliveryConfigurationDTO, InternalDeliveryMethodCustomApiDTO, InternalDeliveryMethodFtpDTO, InternalDeliveryMethodHostedStorageDTO, InternalDeliveryMethodS3DTO, InternalDeliveryMethodSftpDTO } from '../../../openapi/requests';
import { useClientServiceGetClient, useInternalDeliveryConfigurationServicePostInternalDeliveryConfiguration, useInternalDeliveryMethodCustomApiServicePostInternalDeliveryMethodCustomApi, useInternalDeliveryMethodFtpServicePostInternalDeliveryMethodFtp, useInternalDeliveryMethodHostedStorageServicePostInternalDeliveryMethodHostedStorage, useInternalDeliveryMethodS3ServicePostInternalDeliveryMethodS3, useInternalDeliveryMethodSftpServicePostInternalDeliveryMethodSftp } from '../../../openapi/queries';

type DeliveryMethod = 'CustomAPI' | 'FTP' | 'HostedStorage' | 'S3' | 'SFTP';
const DeliveryMethodValues: DeliveryMethod[] = ['CustomAPI', 'FTP', 'HostedStorage', 'S3', 'SFTP'];

interface FormData extends InternalDeliveryConfigurationDTO {
    deliveryMethods: DeliveryMethod[];
    customAPI?: InternalDeliveryMethodCustomApiDTO;
    ftp?: InternalDeliveryMethodFtpDTO;
    hostedStorage?: InternalDeliveryMethodHostedStorageDTO;
    s3?: InternalDeliveryMethodS3DTO;
    sftp?: InternalDeliveryMethodSftpDTO;
}

const CreateDeliveryConfiguration: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
    const { data: clients } = useClientServiceGetClient({}, undefined, { enabled: isOpen });
    const { mutateAsync } = useInternalDeliveryConfigurationServicePostInternalDeliveryConfiguration();

    const { mutateAsync: mutateCustomApiAsync } = useInternalDeliveryMethodCustomApiServicePostInternalDeliveryMethodCustomApi();
    const { mutateAsync: mutateFtpAsync } = useInternalDeliveryMethodFtpServicePostInternalDeliveryMethodFtp();
    const { mutateAsync: mutateHostedStorageAsync } = useInternalDeliveryMethodHostedStorageServicePostInternalDeliveryMethodHostedStorage();
    const { mutateAsync: mutateS3Async } = useInternalDeliveryMethodS3ServicePostInternalDeliveryMethodS3();
    const { mutateAsync: mutateSftpAsync } = useInternalDeliveryMethodSftpServicePostInternalDeliveryMethodSftp();

    const [form] = Form.useForm<FormData>();
    const [messageApi, contextHolder] = message.useMessage();
    const deliveryMethods = Form.useWatch('deliveryMethods', form) ?? [];
    const [saving, setSaving] = useState(false);

    const onSubmit = () => {
        form.submit();
    };

    const onFinish = () => {
        setSaving(true);

        const values = form.getFieldsValue(true) as FormData;

        mutateAsync({ requestBody: values }).then(result => {
            const deliveryConfigurationId = result.id;
            let promises: Promise<any>[] = [];

            if (values.deliveryMethods.includes('CustomAPI')) {
                if (values.customAPI) {
                    promises.push(mutateCustomApiAsync({ requestBody: { ...values.customAPI, deliveryconfigurationId: deliveryConfigurationId } }));
                }
            }
            if (values.deliveryMethods.includes('FTP')) {
                if (values.ftp) {
                    promises.push(mutateFtpAsync({ requestBody: { ...values.ftp, deliveryconfigurationId: deliveryConfigurationId } }));
                }
            }
            if (values.deliveryMethods.includes('HostedStorage')) {
                if (values.hostedStorage) {
                    promises.push(mutateHostedStorageAsync({ requestBody: { ...values.hostedStorage, deliveryconfigurationId: deliveryConfigurationId } }));
                }
            }
            if (values.deliveryMethods.includes('S3')) {
                if (values.s3) {
                    promises.push(mutateS3Async({ requestBody: { ...values.s3, deliveryconfigurationId: deliveryConfigurationId } }));
                }
            }
            if (values.deliveryMethods.includes('SFTP')) {
                if (values.sftp) {
                    promises.push(mutateSftpAsync({ requestBody: { ...values.sftp, deliveryconfigurationId: deliveryConfigurationId } }));
                }
            }

            if (promises.length) {
                Promise.all(promises).then(() => {
                    messageApi.success("Delivery configuration created");
                    setSaving(false);
                    onClose();
                }).catch(reason => {
                    setSaving(false);
                    messageApi.error(JSON.stringify(reason));
                });
            } else {
                messageApi.error("An error occured");
            }
        });
    };

    return (
        <>
            {contextHolder}

            <Drawer
                title="Create a new configuration"
                width={720}
                onClose={onClose}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={saving}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item rules={[{ required: true }]} name='clientId' label="Client">
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name={'title'} label="Title">
                        <Input />
                    </Form.Item>

                    <Form.Item name="isDefault" valuePropName="checked">
                        <Checkbox>Default</Checkbox>
                    </Form.Item>

                    <Form.Item name="deliveryMethods" label='Method'>

                        <Checkbox.Group options={DeliveryMethodValues} />

                    </Form.Item>

                    {(deliveryMethods.includes("CustomAPI")) && <>
                        <Title level={4}>CustomAPI</Title>
                        <Form.Item rules={[{ required: true }]} name={['customApi', 'endpoint']} label="Endpoint">
                            <Input />
                        </Form.Item>
                    </>}

                    {(deliveryMethods.includes("FTP")) && <>
                        <Title level={4}>FTP</Title>
                        <Form.Item rules={[{ required: true }]} name={['ftp', 'hostname']} label="Hostname">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['ftp', 'port']} label="Port">
                            <Input type='number' />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['ftp', 'username']} label="Username">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['ftp', 'password']} label="Password">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['ftp', 'folder']} label="Folder">
                            <Input />
                        </Form.Item>
                    </>}

                    {(deliveryMethods.includes("HostedStorage")) && <>

                    </>}

                    {(deliveryMethods.includes("S3")) && <>
                        <Title level={4}>S3</Title>
                        <Form.Item rules={[{ required: true }]} name={['s3', 'serviceEndpoint']} label="Service endpoint">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['s3', 'accessSecret']} label="Access secret">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['s3', 'accessKey']} label="access key">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['s3', 'bucketName']} label="Bucket name">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['s3', 'pathPrefix']} label="Path prefix">
                            <Input />
                        </Form.Item>
                    </>}

                    {(deliveryMethods.includes("SFTP")) && <>
                        <Title level={4}>SFTP</Title>

                        <Form.Item rules={[{ required: true }]} name={['sftp', 'hostname']} label="Hostname">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['sftp', 'port']} label="Port">
                            <Input type='number' />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['sftp', 'username']} label="Username">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['sftp', 'sshkey']} label="Sshkey">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['sftp', 'folder']} label="Folder">
                            <Input />
                        </Form.Item>
                    </>}




                </Form>
            </Drawer>
        </>

    );
};

export default CreateDeliveryConfiguration;