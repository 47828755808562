import { useQueries } from "@tanstack/react-query";
import format from "date-fns/format";
import { saveAs } from 'file-saver';
import { useEffect, useMemo, useState } from "react";
import Drawer from 'react-modern-drawer';
import { BASE_PATH } from "../..";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import { useProjectFolderServiceGetProjectFolder1, useProjectFolderServiceGetProjectFolderOrders } from "../../openapi/queries";
import { OrderlineService, OrderlineViewDTO } from "../../openapi/requests";
import "../layout/elements/Lists.scss";
import { OrderlineList } from "./OrderlineList";
import "./ProjectDetail.scss";

interface Props {
    projectFolderId?: number;
    isOpen: boolean;
    onClose: () => void;
}

export const ProjectFolderDetail: React.FC<Props> = ({ projectFolderId, isOpen, onClose }) => {
    const [files, setFiles] = useState<OrderlineViewDTO[]>([]);

    const { data: projectFolder, isPending: isProjectFolderPending } = useProjectFolderServiceGetProjectFolder1({ key: projectFolderId ?? 0 }, undefined, { enabled: isOpen && projectFolderId !== undefined });
    const { data: orders, isPending: isOrdersPending } = useProjectFolderServiceGetProjectFolderOrders({ key: projectFolderId ?? 0 }, undefined, { enabled: isOpen && projectFolderId !== undefined });

    const loading = isProjectFolderPending || isOrdersPending;

    const combinedOrderlines = useQueries({
        queries: orders?.map(order => (
            { queryKey: ['orderlines', order.id], queryFn: () => OrderlineService.getOrderOrderline(order.id) }
        )) ?? [],
        combine: (results) => {
            return ({
                data: results.map(result => result.data),
                isPending: results.some(result => result.isPending),
            })
        }
    });

    const projectFilesOrdered = useMemo(() => {
        return combinedOrderlines?.data?.flat().reduce((i, e) => i + (e?.files.length ?? 0), 0) ?? 0;
    }, [combinedOrderlines?.data]);

    const projectFilesProduced = useMemo(() => {
        return combinedOrderlines?.data?.flat().filter(e => e?.status === 20).reduce((i, e) => i + (e?.files.length ?? 0), 0) ?? 0;
    }, [combinedOrderlines?.data]);

    const lastUpdate = useMemo(() => {
        return (combinedOrderlines?.data?.flat().filter(e => e !== undefined).reduce((i, e) => new Date(e!.timestampUpdate) > i ? new Date(e!.timestampUpdate) : i, new Date("1900-01-01")))
    }, [combinedOrderlines?.data]);

    useEffect(() => {
        async function fetchData() {
            if (!combinedOrderlines.isPending) {
                var files: OrderlineViewDTO[] = [];

                for (let i = 0; i < combinedOrderlines.data.length; i++) {
                    const orderlines = combinedOrderlines.data[i];

                    if (orderlines) {
                        for (let j = 0; j < orderlines.length; j++) {
                            const line = orderlines[j];

                            if (line.files.length) {
                                files.push(line);
                            }
                        }
                    }
                }
               
                if (files.length > 0)
                    setFiles(files);
            }
        }
        fetchData();
    }, [combinedOrderlines.data, combinedOrderlines.isPending]);

    const onDownloadAll = async (id: number) => {
        const token = localStorage.getItem('accessToken');

        fetch(`${BASE_PATH}/Projectfolder/${id}/files`, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob);
        });
    }

    const onDownloadPowerpoint = async () => {
        const token = localStorage.getItem('accessToken');

        fetch(`${BASE_PATH}/Projectfolder/${projectFolderId}/powerpoint`, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob);
        });
    }


    return (
        <Drawer className="project-detail-overlay" enableOverlay={false} open={isOpen} size={"100%"} direction="bottom" onClose={onClose}>
            <div className="project-detail-overlay-header">
                <button onClick={onClose}>
                    <svg width="56" height="28" viewBox="0 0 56 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="55" height="27" rx="13.5" fill="#1A1A1A" stroke="white" />
                        <path d="M30.5 10.5L23.5 17.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M23.5 10.5L30.5 17.5" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
            <div className="project-detail-overlay-content">
                <div className="project-detail">
                    <div className="project-detail-header">
                        <h1>{projectFolder?.name}</h1>

                    </div>

                    <div className="project-content-wrapper">
                        <div>
                            <div className="info-list">
                                <div className="info-list-header"><span>Basics</span></div>
                                <ul>
                                    <li>
                                        <span>Name</span>
                                        <span>{projectFolder?.name}</span>
                                    </li>
                                    <li>

                                        <span>Order By</span>
                                        <span>{projectFolder?.modifiedUserName}</span>
                                    </li>
                                    <li>

                                        <span>Order Time</span>
                                        <span>{format(new Date(projectFolder?.timestampCreate ?? "1900-01-01"), "dd.MM.yyyy - pp")}</span>
                                    </li>
                                    <li>

                                        <span>Last Status Update</span>
                                        <span>{format(lastUpdate, "dd.MM.yyyy - pp")}</span>
                                    </li>
                                    <li>

                                        <span>Files Ordered</span>
                                        <span>{projectFilesOrdered}</span>
                                    </li>
                                    <li>

                                        <span>Files Produced</span>
                                        <span>{projectFilesProduced}</span>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div className="info-list project-images-list">
                            <div className="info-list-header">
                                <span>Images created with this scene</span>
                                <span>
                                    <button className="btn" onClick={() => { onDownloadAll(projectFolderId ?? 0) }}>Download all</button>
                                    <button className="btn" onClick={onDownloadPowerpoint}>Download as powerpoint</button>
                                </span>
                            </div>
                            {loading &&
                                <div className="loading"><Loading /></div>
                            }
                            <OrderlineList orderlines={files} />
                            {(files?.length === 0 && !loading) &&
                                <div className="project-images-list-no-images">
                                    <span>No available images</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}