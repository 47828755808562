import { Modal } from 'antd';
import React, { useCallback } from 'react';
import { StillImageConfiguration } from '../../../../../models/StillImageConfiguration';
import Graph from './graph/Graph';
import { TemplateViewDTO } from '../../../../../openapi/requests';

const Moodboard: React.FC<{ template: TemplateViewDTO | undefined, isOpen: boolean, configuration?: string, onSave: (serialized: string) => void }> = ({ template, isOpen, onSave, configuration }) => {

    const handleClose = useCallback(() => {
        onSave(configuration || '');
    }, [configuration, onSave]);

    const config  = JSON.parse(configuration || '{}') as StillImageConfiguration;

    return (

        <Modal title="Configuration" open={isOpen} onOk={handleClose} onCancel={handleClose} width='90vw'>
            <div style={{ width: '100%', height: '75vh' }}>
                <div className='configuration'>
                    <Graph template={template} configuration={config} />
                </div>
            </div>
        </Modal>
    );
}


export default Moodboard;