import { DiagramEngine } from '@projectstorm/react-diagrams';
import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { ModelPortModel } from './ModelPort';
import { MaterialPortModel } from './MaterialPort';
import { ConfiguratorNodeModel, ConfiguratorNodeWidget } from './ConfiguratorNode';
import { ModelPackPortModel } from './ModelPackPort';
import { ConfiguratorPortModel } from './ConfiguratorPort';
import { ModelPackService, ModelPackViewDTO, ModelService } from '../../../../../openapi/requests';

export class ModelPackNodeModel extends ConfiguratorNodeModel<ModelPackViewDTO> {
	protected onPortSelected: (port: ConfiguratorPortModel) => void;

	constructor(updateCallback: () => void, onPortSelected: (port: ConfiguratorPortModel) => void) {
		super('modelpack', updateCallback);
		this.addPort(new ModelPackPortModel(false, 'out', 'out', onPortSelected, ''));
		this.onPortSelected = onPortSelected;
	}

	async loadData(modelID: number) {

		const ports = this.getPorts();

		for (const key in ports) {
			if (Object.prototype.hasOwnProperty.call(ports, key)) {
				const port = ports[key] as ModelPortModel | MaterialPortModel;

				if (port.in) {
					const links = port.getLinks();

					for (const key in links) {
						if (Object.prototype.hasOwnProperty.call(links, key)) {
							links[key].remove();
						}
					}

					port.remove();
				}
			}
		}

		this.modelPorts = this.modelPorts.filter(e => !e.in);
		this.modelPackPorts = this.modelPackPorts.filter(e => !e.in);
		this.materialPorts = this.materialPorts.filter(e => !e.in);
		this.modifierPorts = this.modifierPorts.filter(e => !e.in);

		let modelResponse = await ModelPackService.getModelPack1(modelID);
		this.data = modelResponse;

		if(this.data.models.length > 0){
			var mastermodelID = this.data.models[0].modelId;

			var master = this.data.models.find(e => e.isMasterModel);
			if(master){
				mastermodelID = master.modelId;
			}

			let modelReponse = await ModelService.getModel1(mastermodelID)
			let addedPorts:string[] = [];

			for (let i = 0; i < modelReponse.placementpoints.length; i++) {
				const point = modelReponse.placementpoints[i];

				if(addedPorts.some(e => e === point.name))
				{
					continue
				}

				addedPorts.push(point.name);
	
				if(point.useModelpack){
					this.addPort(new ModelPackPortModel(true, point.name, point.label, this.onPortSelected, point.modelSelectionFilter));
				}else{
					this.addPort(new ModelPortModel(true, point.name, point.label, this.onPortSelected, point.modelSelectionFilter));
				}
			}
			
			addedPorts = [];
	
			for (let i = 0; i < modelReponse.surfaces.length; i++) {
				const surface = modelReponse.surfaces[i];

				if(addedPorts.some(e => e === surface.name))
				{
					continue
				}

				addedPorts.push(surface.name);
	
				this.addPort(new MaterialPortModel(true, surface.name, surface.label, this.onPortSelected, surface.materialSelectionFilter));
			}
		}

		this.updateCallback();
	}
}

export class ModelPackNodeFactory extends AbstractReactFactory<ModelPackNodeModel, DiagramEngine> {
	constructor() {
		super('modelpack');
	}

	generateReactWidget(event: GenerateWidgetEvent<ModelPackNodeModel>): JSX.Element {
		return <ConfiguratorNodeWidget engine={this.engine} node={event.model} />;
	}

	generateModel(event: GenerateModelEvent) {
		return new ModelPackNodeModel(() => { }, () => {});
	}
}