import { CheckOutlined, CloudUploadOutlined, DownloadOutlined, EditOutlined, ExclamationOutlined, HourglassOutlined, LoadingOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';
import React, { useCallback } from 'react';
import TimeAgo from 'react-timeago'
import { Typography, Image, Space, Popconfirm, Button, message } from 'antd';
import prettyBytes from 'pretty-bytes';
import { useOrderServiceGetOrder1, useOrderlineServiceGetOrderOrderline } from '../../../openapi/queries';
import { OrderlineFile, OrderlineViewDTO, SceneService, TemplateService } from '../../../openapi/requests';
import { BASE_PATH } from '../../..';
import { StillImageConfiguration } from '../../../models/StillImageConfiguration';

const { Title } = Typography;

var orderStatuses: { [key: number]: { icon: React.ReactElement, name: string } } = {
    0: { icon: <EditOutlined />, name: 'Draft' },
    10: { icon: <HourglassOutlined />, name: 'Placed' },
    20: { icon: <HourglassOutlined />, name: 'Delegating' },
    30: { icon: <LoadingOutlined spin />, name: 'Processing' },
    40: { icon: <StopOutlined />, name: 'Declined' },
    50: { icon: <DownloadOutlined />, name: 'Deliverable' },
    53: { icon: <ExclamationOutlined />, name: 'Delivery failed' },
    60: { icon: <CloudUploadOutlined />, name: 'Delivered' },
    99: { icon: <WarningOutlined />, name: 'Failed' },
}

var orderlineStatuses: { [key: number]: { icon: React.ReactElement, name: string } } = {
    0: { icon: <EditOutlined />, name: 'Draft' },
    10: { icon: <LoadingOutlined spin />, name: 'Processing' },
    20: { icon: <CheckOutlined />, name: 'Complete' },
    99: { icon: <WarningOutlined />, name: 'Failed' },
}

const OrderDetails: React.FC<{ orderId: number }> = ({ orderId }) => {
    const {data:order, isLoading: orderLoading} = useOrderServiceGetOrder1({key: orderId});
    const {data:orderlines, isLoading: linesLoading} = useOrderlineServiceGetOrderOrderline({key: orderId});
    const [messageApi, contextHolder] = message.useMessage();

    const columns: ColumnsType<OrderlineViewDTO> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value) => (
                <>{orderlineStatuses[value]?.icon}{' '}{orderlineStatuses[value]?.name}</>
            )
        },
        {
            title: 'Created',
            dataIndex: 'timestampCreate',
            key: 'created',
            render: (value) => (
                <TimeAgo date={value} />
            )
        },
        {
            title: 'Production started',
            dataIndex: 'timestampProductionStart',
            key: 'started',
            render: (value) => (
                <TimeAgo date={value} />
            )
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm title="Are you sure?" onConfirm={() => handleUseAsDefault(record)}>
                        <Button type="link">Use as default for scene</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const handleUseAsDefault = useCallback(async (line: OrderlineViewDTO) => {
        const template = await TemplateService.getTemplate1(line.templateId);
        const scene = await SceneService.getScene1(template.sceneId!);
        
        let config = JSON.parse(line.configurationSpec) as StillImageConfiguration;

        config.Cameras = undefined;
        
        scene.defaultConfigurationSpec = JSON.stringify(config);

        await SceneService.putScene(template.sceneId!, scene);

        messageApi.success("Scene updated");
    }, [messageApi]);

    const expandedRowRender = (line: OrderlineViewDTO) => {
        const columns: ColumnsType<OrderlineFile> = [
            {
                title: 'preview',
                key: 'url',
                render: (e, r) => <Image width={200} src={BASE_PATH + r.url} />,
            },
            { title: 'Name', dataIndex: 'name', key: 'name' },
            {
                title: 'Size',
                key: 'size',
                render: (e, r) => prettyBytes(r.size),
            },
            {
                title: '',
                key: 'url',
                render: (e, r) => <a download href={BASE_PATH + r.url}>Download</a>,
            },
        ];

        return <Table columns={columns} dataSource={line.files} pagination={false} />;
    };

    return (
        <>
            {contextHolder}
            {order && <div>
                <Title level={2}>{orderStatuses[order.status]?.icon}{' '}{orderStatuses[order.status]?.name} - Order id: {order.id}</Title>
                <Title level={4}>created <TimeAgo date={order.timestampCreate} /></Title>
                {order.timestampDelivery && <Title level={4}>delivered <TimeAgo date={order.timestampDelivery} /></Title>}
            </div>}
            <Title level={5}>Order lines</Title>
            <Table columns={columns} dataSource={orderlines ?? []} loading={orderLoading || linesLoading} rowKey={"id"} expandable={{expandedRowRender, expandedRowKeys: orderlines?.map(e => e.id), rowExpandable: e => e.files.length !== 0}} />
        </>
    );
};

export default OrderDetails;