/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Client } from '../models/Client';
import type { InternalClientDTO } from '../models/InternalClientDTO';
import type { InternalClientDTOODataListResponse } from '../models/InternalClientDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalClientService {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns InternalClientDTOODataListResponse Success
     * @throws ApiError
     */
    public static getInternalClient(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<InternalClientDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/Client',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InternalClientDTO Success
     * @throws ApiError
     */
    public static postInternalClient(
requestBody?: InternalClientDTO,
): CancelablePromise<InternalClientDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal/Client',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns InternalClientDTO Success
     * @throws ApiError
     */
    public static getInternalClient1(
key: number,
): CancelablePromise<InternalClientDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/Client/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns InternalClientDTO Success
     * @throws ApiError
     */
    public static putInternalClient(
key: number,
requestBody?: InternalClientDTO,
): CancelablePromise<InternalClientDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal/Client/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteInternalClient(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal/Client/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param clientId 
     * @param query 
     * @returns Client Success
     * @throws ApiError
     */
    public static getInternalClientQuery(
clientId?: number,
query?: string,
): CancelablePromise<Array<Client>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/Client/query',
            query: {
                'clientId': clientId,
                'query': query,
            },
        });
    }

}
