import React from 'react';
import ProjectList from '../../../components/admin/projects/ProjectList';

const ProjectsPage: React.FC = () => {

    return (<div>
        <ProjectList />
    </div>)
};

export default ProjectsPage;