import React from 'react';
import TemplateList from '../../../components/admin/templates/TemplateList';

const Templates: React.FC = () => {

    return (<div>
        <TemplateList />
    </div>)
};

export default Templates;