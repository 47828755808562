import { CloudUploadOutlined, DownloadOutlined, EditOutlined, ExclamationOutlined, HourglassOutlined, LoadingOutlined, PlusOutlined, RedoOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space } from 'antd';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import React, { useCallback, useContext, useState } from 'react';
import TimeAgo from 'react-timeago'
import NewOrder from './NewOrder';
import { AuthContext } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { useOrderServiceGetOrder } from '../../../openapi/queries';
import { OrderDTO, OrderService } from '../../../openapi/requests';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { OrderStatus } from '../../../models/enums';

var orderStatuses: { [key: number]: { icon: React.ReactElement, name: string } } = {
    0: { icon: <EditOutlined />, name: 'Draft' },
    10: { icon: <HourglassOutlined />, name: 'Placed' },
    20: { icon: <HourglassOutlined />, name: 'Delegating' },
    30: { icon: <LoadingOutlined spin />, name: 'Processing' },
    40: { icon: <StopOutlined />, name: 'Declined' },
    50: { icon: <DownloadOutlined />, name: 'Deliverable' },
    53: { icon: <ExclamationOutlined />, name: 'Delivery failed' },
    60: { icon: <CloudUploadOutlined />, name: 'Delivered' },
    99: { icon: <WarningOutlined />, name: 'Failed' },
}

const OrderList: React.FC = () => {
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState<string>("id");
    const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");

    const { data, isLoading, refetch } = useOrderServiceGetOrder({ 
        top: pageSize.toString(), 
        skip: ((page - 1) * pageSize).toString(), 
        orderby: `${sortField} ${sortOrder}`,
        count: 'true' })

    const [cloneFrom, setCloneFrom] = useState<OrderDTO | undefined>();
    const [selectedorder, setSelectedOrder] = useState<OrderDTO | undefined>(undefined);
    const { hasPermission } = useContext(AuthContext);


    const [createOpen, setCreateOpen] = useState(false);

    const openCreate = () => {
        setCreateOpen(true);
    };

    const handleCreateClose = useCallback(() => {
        setCreateOpen(false);
        refetch();
    }, [refetch]);

    const handleClone = useCallback((from: OrderDTO) => {
        setCloneFrom(from);
        setCreateOpen(true);
    }, []);

    const handleEdit = useCallback((order: OrderDTO) => {
        setSelectedOrder(order);
        setCreateOpen(true);
    }, []);

    const handleDelete = useCallback((order: OrderDTO) => {
        if (order) {
            OrderService.deleteOrder(order.id).then(() => {
                refetch();
            })
        }
    }, [refetch]);

    const handlePlace = useCallback((order: OrderDTO) => {
        if (order) {
            OrderService.putOrderPlace(order.id).then(() => {
                refetch();
            });
        }
    }, [refetch]);

    const handleTableChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<OrderDTO> | SorterResult<OrderDTO>[]) => {
        setPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);

        if(!Array.isArray(sorter)){
            setSortField(sorter.field?.toString() ?? "id");
            setSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc');
        }
    }, []);

    const columns: ColumnsType<OrderDTO> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (value) => (
                <Link to={'/admin/orders/' + value}>{value}</Link>
            ),
            sorter: (a, b) => (a.id) - (b.id),
            defaultSortOrder: 'descend'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value) => (
                <>{orderStatuses[value]?.icon}{' '}{orderStatuses[value]?.name}</>
            ),
            sorter: (a, b) => (a.status) - (b.status),
        },
        {
            title: 'Created',
            dataIndex: 'timestampCreate',
            key: 'created',
            render: (value) => (
                value && <TimeAgo date={value} />
            ),
            sorter: (a, b) => (new Date(a.timestampCreate)).getTime() - (new Date(b.timestampCreate)).getTime(),
        },
        {
            title: 'Delivered',
            dataIndex: 'timestampDelivery',
            key: 'delivery',
            render: (value) => (
                value && <TimeAgo date={value} />
            ),
            sorter: (a, b) => (new Date(a.timestampDelivery || '1900')).getTime() - (new Date(b.timestampDelivery || '1900')).getTime(),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Link to={'/admin/orders/' + record.id}>Details</Link>
                    {record.projectId && <Link to={'/admin/projects/' + record.projectId}>View Project</Link>}
                    {hasPermission('CreateOrders') && <Button type="link" disabled={record.status !== OrderStatus.Draft} onClick={() => handleEdit(record)}>Edit</Button>}
                    {hasPermission('CreateOrders') && <Button type="link" onClick={() => handleClone(record)}>Clone</Button>}
                    {(hasPermission('FinalRender') || hasPermission('PriorityRender') || hasPermission('PreviewRender')) && <Popconfirm title="Sure to place?" onConfirm={() => handlePlace(record)}>
                        <Button disabled={record.status !== OrderStatus.Draft} type="link">Place</Button>
                    </Popconfirm>}
                    {hasPermission('CreateOrders') && <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                        <Button disabled={record.status !== OrderStatus.Draft} type="link">Delete</Button>
                    </Popconfirm>}
                </Space>
            ),
        },
    ];

    return (
        <>
            <Space>
                {hasPermission('CreateOrders') && <Button type="primary" onClick={openCreate} icon={<PlusOutlined />} style={{ marginBottom: 16 }}>
                    New order
                </Button>}
                <Button type="primary" onClick={() => refetch()} icon={<RedoOutlined />} style={{ marginBottom: 16 }}>
                    Refresh
                </Button>
            </Space>

            <NewOrder cloneFrom={cloneFrom} order={selectedorder} isOpen={createOpen} onClose={handleCreateClose} />

            <Table
                loading={isLoading}
                dataSource={data?.value ?? Array.from({ length: pageSize }).map(x => ({}))}
                columns={columns}
                rowKey={"id"}
                onChange={handleTableChange}
                pagination={{
                    total: (data !== undefined && data['@odata.count']) ? data['@odata.count'] : 0,
                    current: page,
                    pageSize: pageSize,
                }} />
        </>
    );
};

export default OrderList;