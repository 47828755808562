import { Checkbox, Drawer, Input, message, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import Title from 'antd/es/typography/Title';
import { useClientServiceGetClient, useInternalDeliveryConfigurationServicePutInternalDeliveryConfiguration, useInternalDeliveryMethodCustomApiServiceDeleteInternalDeliveryMethodCustomApi, useInternalDeliveryMethodCustomApiServicePostInternalDeliveryMethodCustomApi, useInternalDeliveryMethodCustomApiServicePutInternalDeliveryMethodCustomApi, useInternalDeliveryMethodFtpServiceDeleteInternalDeliveryMethodFtp, useInternalDeliveryMethodFtpServicePostInternalDeliveryMethodFtp, useInternalDeliveryMethodFtpServicePutInternalDeliveryMethodFtp, useInternalDeliveryMethodHostedStorageServiceDeleteInternalDeliveryMethodHostedStorage, useInternalDeliveryMethodHostedStorageServicePostInternalDeliveryMethodHostedStorage, useInternalDeliveryMethodHostedStorageServicePutInternalDeliveryMethodHostedStorage, useInternalDeliveryMethodS3ServiceDeleteInternalDeliveryMethodS3, useInternalDeliveryMethodS3ServicePostInternalDeliveryMethodS3, useInternalDeliveryMethodS3ServicePutInternalDeliveryMethodS3, useInternalDeliveryMethodSftpServiceDeleteInternalDeliveryMethodSftp, useInternalDeliveryMethodSftpServicePostInternalDeliveryMethodSftp, useInternalDeliveryMethodSftpServicePutInternalDeliveryMethodSftp } from '../../../openapi/queries';
import { InternalDeliveryConfigurationDTO, InternalDeliveryMethodCustomApiDTO, InternalDeliveryMethodCustomApiService, InternalDeliveryMethodFtpDTO, InternalDeliveryMethodFtpService, InternalDeliveryMethodHostedStorageDTO, InternalDeliveryMethodHostedStorageService, InternalDeliveryMethodS3DTO, InternalDeliveryMethodS3Service, InternalDeliveryMethodSftpDTO, InternalDeliveryMethodSftpService } from '../../../openapi/requests';

type DeliveryMethod = 'CustomAPI' | 'FTP' | 'HostedStorage' | 'S3' | 'SFTP';
const DeliveryMethodValues: DeliveryMethod[] = ['CustomAPI', 'FTP', 'HostedStorage', 'S3', 'SFTP'];

interface FormData extends InternalDeliveryConfigurationDTO {
    deliveryMethods: DeliveryMethod[];
    customAPI?: InternalDeliveryMethodCustomApiDTO;
    ftp?: InternalDeliveryMethodFtpDTO;
    hostedStorage?: InternalDeliveryMethodHostedStorageDTO;
    s3?: InternalDeliveryMethodS3DTO;
    sftp?: InternalDeliveryMethodSftpDTO;
}

const EditDeliveryConfiguration: React.FC<{ deliveryConfiguration?: InternalDeliveryConfigurationDTO, isOpen: boolean, onClose: () => void }> = ({ deliveryConfiguration, isOpen, onClose }) => {
    const {data: clients} = useClientServiceGetClient({}, undefined, {enabled: isOpen});

    const { mutateAsync } = useInternalDeliveryConfigurationServicePutInternalDeliveryConfiguration();
    const { mutateAsync: updateCustomApiAsync } = useInternalDeliveryMethodCustomApiServicePutInternalDeliveryMethodCustomApi();
    const { mutateAsync: updateFtpAsync } = useInternalDeliveryMethodFtpServicePutInternalDeliveryMethodFtp();
    const { mutateAsync: updateHostedStorageAsync } = useInternalDeliveryMethodHostedStorageServicePutInternalDeliveryMethodHostedStorage();
    const { mutateAsync: updateS3Async } = useInternalDeliveryMethodS3ServicePutInternalDeliveryMethodS3();
    const { mutateAsync: updateSftpAsync } = useInternalDeliveryMethodSftpServicePutInternalDeliveryMethodSftp();
    const { mutateAsync: createCustomApiAsync } = useInternalDeliveryMethodCustomApiServicePostInternalDeliveryMethodCustomApi();
    const { mutateAsync: createFtpAsync } = useInternalDeliveryMethodFtpServicePostInternalDeliveryMethodFtp();
    const { mutateAsync: createHostedStorageAsync } = useInternalDeliveryMethodHostedStorageServicePostInternalDeliveryMethodHostedStorage();
    const { mutateAsync: createS3Async } = useInternalDeliveryMethodS3ServicePostInternalDeliveryMethodS3();
    const { mutateAsync: createSftpAsync } = useInternalDeliveryMethodSftpServicePostInternalDeliveryMethodSftp();
    const { mutateAsync: deleteCustomApiAsync } = useInternalDeliveryMethodCustomApiServiceDeleteInternalDeliveryMethodCustomApi();
    const { mutateAsync: deleteFtpAsync } = useInternalDeliveryMethodFtpServiceDeleteInternalDeliveryMethodFtp();
    const { mutateAsync: deleteHostedStorageAsync } = useInternalDeliveryMethodHostedStorageServiceDeleteInternalDeliveryMethodHostedStorage();
    const { mutateAsync: deleteS3Async } = useInternalDeliveryMethodS3ServiceDeleteInternalDeliveryMethodS3();
    const { mutateAsync: deleteSftpAsync } = useInternalDeliveryMethodSftpServiceDeleteInternalDeliveryMethodSftp();

    const [form] = Form.useForm<FormData>();
    const [messageApi, contextHolder] = message.useMessage();
    const [saving, setSaving] = useState(true);

    const deliveryMethods = Form.useWatch('deliveryMethods', form) ?? [];

    const onSubmit = () => {
        form.submit();
    }

    const onFinish = () => {
        if (deliveryConfiguration) {
            setSaving(true);

            const values = form.getFieldsValue(true) as FormData;

            let promises: Promise<any>[] = [];

            promises.push(mutateAsync({ key: deliveryConfiguration.id, requestBody: values}));

            if(values.deliveryMethods.includes("CustomAPI")){
                if(values.customAPI){
                    if(values.customAPI.id){
                        promises.push(updateCustomApiAsync({ key: values.customAPI.id, requestBody: values.customAPI}));
                    }else{
                        promises.push(createCustomApiAsync({ requestBody: { ...values.customAPI, deliveryconfigurationId: deliveryConfiguration.id}}));
                    }
                }
            }else if(values.customAPI?.id){
                promises.push(deleteCustomApiAsync({ key: values.customAPI.id }));
            }

            if(values.deliveryMethods.includes("FTP")){
                if(values.ftp){
                    if(values.ftp.id){
                        promises.push(updateFtpAsync({key: values.ftp.id, requestBody: values.ftp}));
                    }else{
                        promises.push(createFtpAsync({ requestBody: {...values.ftp, deliveryconfigurationId: deliveryConfiguration.id}}));
                    }
                }
            }else if(values.ftp?.id){
                promises.push(deleteFtpAsync({key: values.ftp.id}));
            }

            if(values.deliveryMethods.includes("HostedStorage")){
                //if(values.hostedStorage){
                    if(values.hostedStorage?.id){
                        promises.push(updateHostedStorageAsync({key: values.hostedStorage.id, requestBody: values.hostedStorage}));
                    }else{
                        promises.push(createHostedStorageAsync({ requestBody: {id: 0, deliveryconfigurationId: deliveryConfiguration.id}}));
                    }
                //}
            }else if(values.hostedStorage?.id){
                promises.push(deleteHostedStorageAsync({key: values.hostedStorage.id}));
            }

            if(values.deliveryMethods.includes("S3")){
                if(values.s3){
                    if(values.s3.id){
                        promises.push(updateS3Async({key: values.s3.id, requestBody: values.s3}));
                    }else{
                        promises.push(createS3Async({requestBody: {...values.s3, deliveryconfigurationId: deliveryConfiguration.id}}));
                    }
                }
            }else if(values.s3?.id){
                promises.push(deleteS3Async({ key: values.s3.id}));
            }

            if(values.deliveryMethods.includes("SFTP")){
                if(values.sftp){
                    if(values.sftp.id){
                        promises.push(updateSftpAsync({key: values.sftp.id, requestBody: values.sftp}));
                    }else{
                        promises.push(createSftpAsync({requestBody: {...values.sftp, deliveryconfigurationId: deliveryConfiguration.id}}));
                    }
                }
            }else if(values.sftp?.id){
                promises.push(deleteSftpAsync({key: values.sftp.id}));
            }

            if(promises.length){
                Promise.all(promises).then(() => {
                    messageApi.success("Delivery configuration updated");
                    setSaving(false);
                    onClose();
                }).catch(reason => {
                    setSaving(false);
                    messageApi.error(JSON.stringify(reason));
                });
            }else{
                messageApi.error("An error occured");
            }


        }
    };

    useEffect(() => {
        if (deliveryConfiguration) {
            setSaving(true);
            form.resetFields();
            
            const promises: Promise<any>[] = [];

            const values:FormData = {
                ...deliveryConfiguration,
                deliveryMethods: [],
            }

            promises.push(InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApi(undefined, undefined, `deliveryconfigurationId eq ${deliveryConfiguration.id}`).then(resp => {
                if(resp.value.length > 0){
                    values.customAPI = resp.value[0];
                    values.deliveryMethods.push("CustomAPI");
                }
            }));
            promises.push(InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtp(undefined, undefined, `deliveryconfigurationId eq ${deliveryConfiguration.id}`).then(resp => {
                if(resp.value.length > 0){
                    values.ftp = resp.value[0];
                    values.deliveryMethods.push("FTP");
                }
            }));
            promises.push(InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorage(undefined, undefined, `deliveryconfigurationId eq ${deliveryConfiguration.id}`).then(resp => {
                if(resp.value.length > 0){
                    values.hostedStorage = resp.value[0];
                    values.deliveryMethods.push("HostedStorage");
                }
            }));
            promises.push(InternalDeliveryMethodS3Service.getInternalDeliveryMethodS3(undefined, undefined, `deliveryconfigurationId eq ${deliveryConfiguration.id}`).then(resp => {
                if(resp.value.length > 0){
                    values.s3 = resp.value[0];
                    values.deliveryMethods.push("S3");
                }
            }));
            promises.push(InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftp(undefined, undefined, `deliveryconfigurationId eq ${deliveryConfiguration.id}`).then(resp => {
                if(resp.value.length > 0){
                    values.sftp = resp.value[0];
                    values.deliveryMethods.push("SFTP");
                }
            }));

            Promise.all(promises).then(() => {
                setSaving(false);
                console.log(values);
                form.setFieldsValue(values);
            });

        }
    }, [deliveryConfiguration, form])

    return (
        <>
            {contextHolder}

            <Drawer
                title={`Edit ${deliveryConfiguration?.id}`}
                width={720}
                onClose={onClose}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={saving}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item rules={[{ required: true }]} name='clientId' label="Client">
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>
                
                    <Form.Item rules={[{ required: true }]} name={'title'} label="Title">
                        <Input />
                    </Form.Item>

                    <Form.Item name="isDefault" valuePropName="checked">
                        <Checkbox>Default</Checkbox>
                    </Form.Item>

                    <Form.Item name="deliveryMethods" label='Method'>

                        <Checkbox.Group options={DeliveryMethodValues} />

                    </Form.Item>

                    {(deliveryMethods.includes("CustomAPI")) && <>
                        <Title level={4}>CustomAPI</Title>
                        <Form.Item rules={[{ required: true }]} name={['customApi', 'endpoint']} label="Endpoint">
                            <Input />
                        </Form.Item>
                    </>}

                    {(deliveryMethods.includes("FTP")) && <>
                        <Title level={4}>FTP</Title>
                        <Form.Item rules={[{ required: true }]} name={['ftp', 'hostname']} label="Hostname">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['ftp', 'port']} label="Port">
                            <Input type='number' />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['ftp', 'username']} label="Username">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['ftp', 'password']} label="Password">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['ftp', 'folder']} label="Folder">
                            <Input />
                        </Form.Item>
                    </>}

                    {(deliveryMethods.includes("HostedStorage")) && <>

                    </>}

                    {(deliveryMethods.includes("S3")) && <>
                        <Title level={4}>S3</Title>
                        <Form.Item rules={[{ required: true }]} name={['s3', 'serviceEndpoint']} label="Service endpoint">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['s3', 'accessSecret']} label="Access secret">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['s3', 'accessKey']} label="access key">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['s3', 'bucketName']} label="Bucket name">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['s3', 'pathPrefix']} label="Path prefix">
                            <Input />
                        </Form.Item>
                    </>}

                    {(deliveryMethods.includes("SFTP")) && <>
                        <Title level={4}>SFTP</Title>

                        <Form.Item rules={[{ required: true }]} name={['sftp', 'hostname']} label="Hostname">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['sftp', 'port']} label="Port">
                            <Input type='number' />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['sftp', 'username']} label="Username">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['sftp', 'sshkey']} label="Sshkey">
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} name={['sftp', 'folder']} label="Folder">
                            <Input />
                        </Form.Item>
                    </>}
                    



                </Form>
            </Drawer>
        </>

    );
};

export default EditDeliveryConfiguration;