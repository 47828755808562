import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ProjectFolderViewDTO, ProjectViewDTO } from '../../openapi/requests';
import { useProjectFolderServiceGetProjectFolder, useProjectFolderServiceGetProjectFolderKey, useProjectFolderServicePutProjectFolder, useProjectServiceGetProjectKey, useProjectServicePutProject } from '../../openapi/queries';
import { AuthContext } from '../../contexts/AuthContext';
import './CreateProject.scss';
import { ConfigContext } from '../../contexts/ConfigContext';
import { GenerateODataFilter } from '../../helpers/odataFunctions';
import { isProject } from '../../pages/Projects';
import { useQueryClient } from '@tanstack/react-query';
import BottomDrawer from '../layout/BottomDrawer';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    projectOrFolder: ProjectViewDTO | ProjectFolderViewDTO | undefined | null;
}

const EditProject: React.FC<Props> = ({ isOpen, onClose, projectOrFolder }) => {
    const queryClient = useQueryClient();
    const { featureFlags } = useContext(ConfigContext);
    const { hasPermission } = useContext(AuthContext);
    const { odataFilters } = useContext(ConfigContext);
    const [name, setName] = useState("");
    const [reference, setReference] = useState("");
    const [selectedFolder, setSelectedFolder] = useState("");
    const { mutateAsync: mutateProjectAsync, isPending: projectPending } = useProjectServicePutProject();
    const { mutateAsync: mutateFolderAsync, isPending: folderpending } = useProjectFolderServicePutProjectFolder();

    const filter = useMemo(() => {

        let combinedFilters = [...odataFilters];

        if (!hasPermission('ViewAllOrders')) {
            combinedFilters.push({ name: "Owner", property: "IsOwner", type: "", values: ["true"] });
        }

        return GenerateODataFilter(combinedFilters);
    }, [hasPermission, odataFilters])

    const { data: folders } = useProjectFolderServiceGetProjectFolder({ filter });

    useEffect(() => {
        if (projectOrFolder) {
            setName(projectOrFolder.name);
            if (isProject(projectOrFolder)) {
                const project = projectOrFolder as ProjectViewDTO;
                setSelectedFolder(project.folderId ? project.folderId.toString() : '');
                setReference(project.reference ?? "");
            }
        }
    }, [projectOrFolder]);

    const handleCreate = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (projectOrFolder) {
            if (isProject(projectOrFolder)) {
                const project = projectOrFolder as ProjectViewDTO;
                const requestBody = { ...project, name, reference };

                if (selectedFolder) {
                    requestBody.folderId = parseInt(selectedFolder, 10);
                } else {
                    requestBody.folderId = undefined;
                }

                mutateProjectAsync({ key: project.id, requestBody }).then(() => {
                    queryClient.resetQueries({ queryKey: [useProjectServiceGetProjectKey], stale: true })
                    onClose();
                });
            } else {
                const folder = projectOrFolder as ProjectFolderViewDTO;
                const requestBody = { ...folder, name };

                mutateFolderAsync({ key: folder.id, requestBody }).then(() => {
                    queryClient.resetQueries({ queryKey: [useProjectFolderServiceGetProjectFolderKey], stale: true })
                    onClose();
                });
            }
        }
    }, [projectOrFolder, name, reference, selectedFolder, mutateProjectAsync, queryClient, onClose, mutateFolderAsync]);

    return (
        <BottomDrawer className='createproject' open={isOpen} onClose={onClose} size={500}>
            <form onSubmit={handleCreate}>
                <h1>Name</h1>
                <input type='text' required placeholder='Type name...' value={name} onChange={e => setName(e.target.value)} />

                {projectOrFolder && isProject(projectOrFolder) && <>
                    <h1>RR-code</h1>
                    <input type='text' placeholder='Type code...' value={reference} onChange={e => setReference(e.target.value)} />
                </>
                }

                {featureFlags.ProjectFolders && projectOrFolder && isProject(projectOrFolder) && <>
                    <h1>Folder</h1>
                    <select value={selectedFolder} onChange={e => setSelectedFolder(e.target.value)}>
                        <option key={''} className='placeholder' value=''>None</option>
                        {folders?.value.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
                    </select>
                </>
                }

                <button disabled={projectPending || folderpending} type='submit'>Save</button>
            </form>
        </BottomDrawer>
    )
};

export default EditProject;