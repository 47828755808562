import React from 'react';
import ModelpackList from '../../../components/admin/modelpacks/ModelpackList';

const Modelpacks: React.FC = () => {

    return (<div>
        <ModelpackList />
    </div>)
};

export default Modelpacks;