import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useState, isValidElement } from 'react';
import './Dialog.scss';

type Props = React.PropsWithChildren<{
    className?: string;
    open: boolean;
}>

export const Dialog: React.FC<Props> = ({ children, className, open }) => {
    const classes = classnames('dialog', className)

    return (
        <AnimatePresence>
            {open && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{duration: 0.25}}
                    className='dialog-overlay'
                >
                        <div className={classes}>
                            {children}
                        </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
};

type MessageDialogProps = {
    open: boolean;
    onConfirm: () => void;
    title: string;
    text: React.ReactNode;
    details?: string;
}

export const MessageDialog: React.FC<MessageDialogProps> = ({ open, title, text, onConfirm, details }) => {
    return (
        <Dialog open={open} className='message'>
            <h1>{title}</h1>
            <div className='message-body'>
                <p>{text}</p>

                {details && <p className='details'>technical Details:<pre>{details}</pre></p>}
            </div>

            <div className='message-footer'>
                <button onClick={onConfirm}>Ok</button>
            </div>
        </Dialog>
    )
};

type ConfirmDialogProps = {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    title: string;
    text: React.ReactNode;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, title, text, onCancel, onConfirm }) => {

    return (
        <Dialog open={open} className='confirm'>
            <h1>{title}</h1>
            <p className='confirm-body'>{text}</p>

            <div className='confirm-footer'>
                <button onClick={onCancel}>Cancel</button>
                <button className='confirm' onClick={onConfirm}>Confirm</button>
            </div>
        </Dialog>
    )
};

type ConfirmProps = React.PropsWithChildren<{
    onConfirm: () => void;
    title: string;
    text: string;
}>

export const Confirm: React.FC<ConfirmProps> = ({ children, title, text, onConfirm }) => {


    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClick = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const handleCancel = useCallback(() => {
        setDialogOpen(false);
    }, []);

    const handleConfirm = useCallback(() => {
        setDialogOpen(false);
        onConfirm();
    }, [onConfirm]);

    if (!isValidElement(children)) {
        return null;
    }

    return (
        <>
            <ConfirmDialog onCancel={handleCancel} onConfirm={handleConfirm} open={dialogOpen} text={text} title={title} />
            {React.cloneElement(children, { onClick: handleClick } as any)}
        </>
    )
};