import React from 'react';
import UserList from '../../../components/admin/users/UserList';

const Users: React.FC = () => {

    return (<div>
        <UserList />
    </div>)
    
}

export default Users;