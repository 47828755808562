import { Checkbox, Col, Drawer, InputNumber, notification, Row, Select, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'antd';
import { useDeliveryConfigurationServiceGetDeliveryConfiguration, useSceneServiceGetScene1, useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan } from '../../../openapi/queries';
import { MaterialService, MaterialViewDTO, ModelPackService, ModelPackViewDTO, ModelPlacementPointDTO, ModelService, ModelSurfaceDTO, ModelViewDTO, ModifierService, ModifierViewDTO, OrderlineEditDTO, OrderlineService, OrderService, SceneModifierTargetDTO, ScenePlacementpointDTO, SceneSurfaceDTO, SceneViewDTO, TemplateEditDTO } from '../../../openapi/requests';
import { StillImageConfiguration, PropsetSelection, ModifierSelection, MaterialSelection, ModelPlacement } from '../../../models/StillImageConfiguration';
import { OrderStatus } from '../../../models/enums';
import { CheckboxChangeEvent, CheckboxProps } from 'antd/es/checkbox';

type QAForm = {
    cameras: string[];
    lightings: string[];
    imageCount: number;
    size: number;
    deliveryconfigurationId: number;
    stillimagesubmissionplanId?: number | null;
}

const cachedModifiers: { [key: string]: ModifierViewDTO[] } = {};
const cachedMaterials: { [key: string]: MaterialViewDTO[] } = {};
const cachedModels: { [key: string]: ModelViewDTO[] } = {};
const cachedMasterModels: { [key: number]: ModelViewDTO } = {};
const cachedModelpacks: { [key: string]: ModelPackViewDTO[] } = {};



const getModifier = async (target: SceneModifierTargetDTO, client: number): Promise<ModifierSelection | undefined> => {
    let modifiers: ModifierViewDTO[];
    const cacheKey = `${target.modifierSelectionFilter}-${client}`;

    if (cachedModifiers[cacheKey]) {
        modifiers = cachedModifiers[cacheKey];
    } else {
        const result = await ModifierService.getModifier(target.modifierSelectionFilter, undefined, undefined, `clientid eq ${client}`).catch(() => {
            throw new Error(`Could not find modifier for ${target.label}, check selection filter for errors`);
        });
        modifiers = result.value;
        cachedModifiers[cacheKey] = modifiers;
    }

    if (modifiers.length === 0) {
        return undefined;
    }

    const modifier = modifiers[Math.floor(Math.random() * modifiers.length)];

    return {
        Name: target.name,
        Value: modifier.name
    }
}

const getMaterial = async (target: SceneSurfaceDTO | ModelSurfaceDTO, client: number, parent: ModelViewDTO | undefined): Promise<MaterialSelection | undefined> => {
    let materials: MaterialViewDTO[];
    const cacheKey = `${target.materialSelectionFilter}-${client}`;

    if (cachedMaterials[cacheKey]) {
        materials = cachedMaterials[cacheKey];
    } else {
        const result = await MaterialService.getMaterial(target.materialSelectionFilter, undefined, undefined, `clientid eq ${client}`).catch(() => {
            throw new Error(`Could not find material for "${target.label}" in ${parent?.title ?? 'scene'}, check selection filter for errors`);
        });
        materials = result.value;
        cachedMaterials[cacheKey] = materials;
    }

    if (materials.length === 0) {
        return undefined;
    }

    const material = materials[Math.floor(Math.random() * materials.length)];

    return {
        Name: target.name,
        Value: material.name
    }
}

const getModel = async (target: ScenePlacementpointDTO | ModelPlacementPointDTO, client: number, parent: ModelViewDTO | undefined): Promise<ModelPlacement | undefined> => {
    let value: string | undefined = undefined;
    let model: ModelViewDTO | undefined = undefined;
    const cacheKey = `${target.modelSelectionFilter}-${client}`;


    if (target.useModelpack) {
        let modelpacks: ModelPackViewDTO[];

        if (cachedModelpacks[cacheKey]) {
            modelpacks = cachedModelpacks[cacheKey];
        } else {
            const result = await ModelPackService.getModelPack(target.modelSelectionFilter, undefined, undefined, `clientid eq ${client}`).catch(() => {
                throw new Error(`Could not find modelpack for "${target.label}" in ${parent?.title ?? 'scene'}, check selection filter for errors`);
            });
            modelpacks = result.value;
            cachedModelpacks[cacheKey] = modelpacks;
        }

        if (modelpacks.length > 0) {
            const modelpack = modelpacks[Math.floor(Math.random() * modelpacks.length)];
            value = modelpack.name;

            var mastermodelID = modelpack.models[0]?.modelId ?? 0;

            var master = modelpack.models.find(e => e.isMasterModel);
            if (master) {
                mastermodelID = master.modelId;
            }

            if (cachedMasterModels[mastermodelID]) {
                model = cachedMasterModels[mastermodelID];
            } else {
                model = await ModelService.getModel1(mastermodelID);
                cachedMasterModels[mastermodelID] = model;
            }
        }
    } else {
        let models: ModelViewDTO[];

        if (cachedModels[cacheKey]) {
            models = cachedModels[cacheKey];
        } else {
            const result = await ModelService.getModel(target.modelSelectionFilter, undefined, undefined, `clientid eq ${client}`).catch(() => {
                throw new Error(`Could not find model for "${target.label}" in ${parent?.title ?? 'scene'}, check selection filter for errors`);
            });
            models = result.value;
            cachedModels[cacheKey] = models;
        }

        if (models.length > 0) {
            model = models[Math.floor(Math.random() * models.length)];
            value = model.name;
        }
    }

    if (!model || !value) {
        return undefined;
    }

    const subModels: ModelPlacement[] = [];
    const materials: MaterialSelection[] = [];

    for (let i = 0; i < model.placementpoints.length; i++) {
        const submodel = await getModel(model.placementpoints[i], client, model);
        if (submodel) {
            subModels.push(submodel);
        }
    }

    for (let i = 0; i < model.surfaces.length; i++) {
        const material = await getMaterial(model.surfaces[i], client, model);
        if (material) {
            materials.push(material);
        }
    }

    return {
        Name: target.name,
        Value: value,
        ModelSelections: subModels,
        SurfaceSelections: materials,
        FeatureSelections: []
    }
}

const generateConfig = async (scene: SceneViewDTO, camera: string, lighting?: string): Promise<StillImageConfiguration> => {
    var propsets: PropsetSelection[] = [];

    for (let i = 0; i < scene.propsets.length; i++) {
        const propset = scene.propsets[i];

        if (propset.label.toLowerCase() === 'lighting' && lighting) {
            propsets.push({
                Name: propset.name,
                Value: lighting
            });
        } else {
            propsets.push({
                Name: propset.name,
                Value: propset.options[Math.floor(Math.random() * propset.options.length)].name
            });
        }
    }

    let modifiers: ModifierSelection[] = [];
    for (let i = 0; i < scene.modifierTargets.length; i++) {
        const modifier = await getModifier(scene.modifierTargets[i], scene.clientId);
        if (modifier) {
            modifiers.push(modifier);
        }
    }

    let materials: MaterialSelection[] = [];
    for (let i = 0; i < scene.surfaces.length; i++) {
        const material = await getMaterial(scene.surfaces[i], scene.clientId, undefined);
        if (material) {
            materials.push(material);
        }
    }

    let models: ModelPlacement[] = [];
    for (let i = 0; i < scene.placementpoints.length; i++) {
        const model = await getModel(scene.placementpoints[i], scene.clientId, undefined);
        if (model) {
            models.push(model);
        }
    }

    const config: StillImageConfiguration = {
        camera: camera,
        PropsetSelections: propsets,
        ModifierSelections: modifiers,
        SurfaceSelections: materials,
        ModelSelections: models
    }

    return config;
}

const TemplateQA: React.FC<{ template?: TemplateEditDTO, isOpen: boolean, onClose: () => void }> = ({ template, isOpen, onClose }) => {
    const [api, contextHolder] = notification.useNotification();
    const { data: scene } = useSceneServiceGetScene1({ key: template?.sceneId ?? 0 }, undefined, { enabled: isOpen });
    const { data: deliveries } = useDeliveryConfigurationServiceGetDeliveryConfiguration({}, undefined, { enabled: isOpen });
    const { data: submissionPlans } = useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan({}, undefined, { enabled: isOpen });
    const [working, setWorking] = useState(false);
    const [form] = Form.useForm<QAForm>();
    const selectedCameras = Form.useWatch('cameras', form);

    const lighting = useMemo(() => {
        if (scene) {
            let propset = scene.propsets.find(e => e.label.toLowerCase() === 'lighting');
            return propset;
        }
        return undefined;
    }, [scene])

    useEffect(() => {
        if (scene) {
            form.setFieldsValue({
                cameras: scene.cameras.map(e => e.cameraName),
                lightings: lighting?.options.map(e => e.name),
                imageCount: 50,
                size: 800
            });
        }
        if (deliveries) {
            form.setFieldsValue({
                deliveryconfigurationId: deliveries.value.find(e => e.title.toLowerCase().includes("hosted"))?.id
            });
        }
        if (submissionPlans) {
            form.setFieldsValue({
                stillimagesubmissionplanId: submissionPlans.value.find(e => e.title?.toLowerCase().includes("qa"))?.id
            });
        }
    }, [scene, form, lighting, deliveries, submissionPlans]);

    const onSubmit = () => {
        form.submit();
    }

    const onFinish = async () => {
        if (template) {
            setWorking(true);
            const templateData = form.getFieldsValue(true) as QAForm;

            const orderLines: OrderlineEditDTO[] = [];

            const orderlineDefaults: OrderlineEditDTO = {
                templateId: template.id,
                isPreview: true,
                deliveryFilenameTemplate: '',
                stillImageSetting: { backgroundcolor: '000000', fileformat: 'jpg', height: 600, width: 800, stillimagesubmissionplanId: templateData.stillimagesubmissionplanId },
                configurationSpec: '',
                orderlinePostProcesses: [],
                requestManualProcess: false,
                spin360Setting: { backgroundcolor: '000000', fileformat: 'jpg', frames: 32, height: 600, width: 800 },
                videoSetting: { codec: '', height: 600, width: 800 },
                armodelSetting: { createGlb: false, createUsdz: false },
            }
            const maxSize = templateData.size;

            let propset = scene.propsets.find(e => e.label.toLowerCase() === 'lighting');


            let imageCounter = 0;

            try {
                while (imageCounter < templateData.imageCount) {
                    for (let i = 0; i < templateData.cameras.length; i++) {
                        const cameraName = templateData.cameras[i];
                        const camera = scene.cameras.find(e => e.cameraName === cameraName)!;
                        const ratio = camera.aspectRatio ?? 1;

                        let width = maxSize;
                        let height = Math.round(maxSize / ratio);

                        if (height > maxSize) {
                            width = Math.round(maxSize * ratio);
                            height = maxSize;
                        }

                        if (templateData.lightings.length) {
                            for (let j = 0; j < templateData.lightings.length; j++) {
                                const lighting = templateData.lightings[j];
                                const lightingName = propset?.options.find(e => e.name === lighting)?.title ?? "_"

                                orderLines.push({
                                    ...orderlineDefaults,
                                    configurationSpec: JSON.stringify(await generateConfig(scene, cameraName, lighting)),
                                    stillImageSetting: { ...orderlineDefaults.stillImageSetting, width, height },
                                    deliveryFilenameTemplate: `${imageCounter.toString().padStart(3, '0')}-${camera.label}-${lightingName}`
                                });

                                imageCounter++;

                                if (imageCounter >= templateData.imageCount) {
                                    break;
                                }
                            }
                            if (imageCounter >= templateData.imageCount) {
                                break;
                            }

                        } else {
                            orderLines.push({
                                ...orderlineDefaults,
                                configurationSpec: JSON.stringify(await generateConfig(scene, cameraName)),
                                stillImageSetting: { ...orderlineDefaults.stillImageSetting, width, height },
                                deliveryFilenameTemplate: `${imageCounter.toString().padStart(3, '0')}-${camera.label}`
                            });

                            imageCounter++;

                            if (imageCounter >= templateData.imageCount) {
                                break;
                            }
                        }
                    }
                }

                const order = await OrderService.postOrder({
                    clientId: scene.clientId,
                    deliveryconfigurationId: templateData.deliveryconfigurationId,
                    id: 0,
                    status: OrderStatus.Draft,
                    orderedByUserId: 0,
                    timestampCreate: '1900-01-01',
                    orderReference: `QA ${template.title}`,
                    notificationRecipients: ''
                });

                for (let i = 0; i < orderLines.length; i++) {
                    await OrderlineService.postOrderOrderline(order.id, orderLines[i]);
                }
                await OrderService.putOrderPlace(order.id);

                api.success({
                    message: 'Queued',
                    description: `order created width id: ${order.id}`,
                    placement: 'top',
                });
            } catch (reason: any) {
                let error = reason as Error;

                api.error({
                    message: 'Failed',
                    description: error.message,
                    placement: 'top',
                });
            }

            setWorking(false);
            onClose();
        }
    };

    const checkAll = selectedCameras?.length === scene?.cameras.length;
    const indeterminate = selectedCameras?.length > 0 && selectedCameras?.length < scene?.cameras.length;

    const onCheckAllChange: CheckboxProps['onChange'] = (e: CheckboxChangeEvent) => {
        form.setFieldsValue({
            cameras: e.target.checked ? scene.cameras.map(e => e.cameraName) : []
        });
    };

    return (
        <>
            {contextHolder}
            <Drawer
                title={`Render ${template?.title}`}
                width={720}
                onClose={onClose}
                open={isOpen}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={working}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}>


                    <Form.Item name="cameras" label={<Space>
                        Cameras
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>Check all</Checkbox>
                    </Space>} rules={[{ required: true }]}>
                        <Checkbox.Group>
                            <Row>
                                {scene?.cameras.map(cam =>
                                    <Col span={8}>
                                        <Checkbox value={cam.cameraName} style={{ lineHeight: '32px' }} defaultChecked>
                                            {cam.label}
                                        </Checkbox>
                                    </Col>)}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item name="lightings" label="Lightings">
                        <Checkbox.Group>
                            <Row>
                                {lighting?.options.map(option =>
                                    <Col span={8}>
                                        <Checkbox value={option.name} style={{ lineHeight: '32px' }} defaultChecked>
                                            {option.title}
                                        </Checkbox>
                                    </Col>)}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item name='imageCount' label="Number of images">
                        <InputNumber min={1} />
                    </Form.Item>

                    <Form.Item name='size' label="Image size">
                        <InputNumber min={1} addonAfter="px" />
                    </Form.Item>

                    <Form.Item name='deliveryconfigurationId' label="Delivery" rules={[{ required: true }]}>
                        <Select options={deliveries?.value.map(e => ({ label: e.title, value: e.id }))} />
                    </Form.Item>

                    <Form.Item name='stillimagesubmissionplanId' label="Plan" rules={[{ required: true }]}>
                        <Select options={submissionPlans?.value.map(e => ({ label: e.title, value: e.id }))} />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default TemplateQA;