import { Checkbox, Drawer, Input, message, Select, Space } from 'antd';
import React, { useMemo } from 'react';
import { Button, Form } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import QueryField from '../Inputs/QueryField';
import { useInternalClientServiceGetInternalClient, useInternalTagServiceGetInternalTag, useModelServicePostModel } from '../../../openapi/queries';
import { ModelViewDTO } from '../../../openapi/requests';

const CreateModel: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
    const {data: clients} = useInternalClientServiceGetInternalClient({}, undefined, {enabled: isOpen});
    const {data: alltags} = useInternalTagServiceGetInternalTag({}, undefined, {enabled: isOpen});
    const [form] = Form.useForm<ModelViewDTO>();
    const [messageApi, contextHolder] = message.useMessage();
    const {mutateAsync, isPending } = useModelServicePostModel();
    const clientId = Form.useWatch('clientId', form);

    const tags = useMemo(() => {
        if(alltags){
            const tags = Array.from(new Set(alltags.value.map(e => e.value)));
            tags.sort();
            return tags;
        }
        return [];
    }, [alltags]);

    const onSubmit = () => {
        form.submit();
    };

    const onFinish = () => {

        const values = form.getFieldsValue(true) as ModelViewDTO;

        mutateAsync({ requestBody: values})
            .then(() => {
                messageApi.success("Model created");
                onClose();
            }).catch(reason => {
                messageApi.error(JSON.stringify(reason));
            });
    };

    return (
        <>
            {contextHolder}

            <Drawer
                title="Create a new model"
                width={720}
                onClose={onClose}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item rules={[{ required: true }]} name='clientId' label="Client">
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>

                    <Form.Item name="isEnabled" valuePropName="checked">
                        <Checkbox>Enabled</Checkbox>
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='title' label="Title">
                        <Input />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='filename' label="Filename">
                        <Input />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='name' label="Name">
                        <Input />
                    </Form.Item>

                    <Form.Item name='tags' label="Tags">
                        <Select mode='tags' options={tags.map(e => ({ label: e, value: e }))} />
                    </Form.Item>

                    <Form.Item label='Metadata'>
                        <Form.List name="metadata">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="name" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="value" />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add metadata
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item label='Surfaces'>
                        <Form.List name="surfaces">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'label']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="Label" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="name" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'objectSelector']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="object selector" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'materialSelectionFilter']}
                                            >
                                                <QueryField placeholder="material filter" type='Material' clientId={clientId}/>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add surface
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item label='Placement points'>
                        <Form.List name="placementpoints">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'label']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="Label" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="name" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'objectSelector']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="object selector" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'modelSelectionFilter']}
                                            >
                                                <QueryField placeholder="model filter" type='Model' clientId={clientId}/>
                                            </Form.Item>

                                            <Form.Item name="isRequired" valuePropName="checked">
                                                <Checkbox>Required</Checkbox>
                                            </Form.Item>

                                            <Form.Item name="useModelpack" valuePropName="checked">
                                                <Checkbox>Use&nbsp;modelpack</Checkbox>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add placement point
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Form>
            </Drawer>
        </>

    );
};

export default CreateModel;