
import "./StatisticsPage.scss";

import Layout from "../components/layout/Layout";
import StatisticsPageView from "../components/statistics/StatisticsPageView";

export const StatisticsPage: React.FC = () => { 

    return (
        <Layout className="statistics">
            <div className="statistics-header">
                <h1>Statistics</h1>
            </div>

            <StatisticsPageView />
        </Layout>
    )
}

export default StatisticsPage;