import classnames from 'classnames';
import React from 'react';
import ModernDrawer from 'react-modern-drawer';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import './SideDrawer.scss';

type Props = React.PropsWithChildren<{
    className?: string;
    open: boolean;
    onClose: () => void;
    title: string;
}>

const SideDrawer: React.FC<Props> = ({children, className, open, onClose, title}) => {
    const classes = classnames('sidedrawer', className)
    
    return (
        <ModernDrawer className={classes} open={open} onClose={onClose} direction='right' size={445} overlayOpacity={0}>
            <div className='header'>
                <button className='close' onClick={onClose}>
                    <CloseIcon />
                </button>
                <h1>{title}</h1>
            </div>

            {children}
        </ModernDrawer>
    )
};

export default SideDrawer;