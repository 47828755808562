import { Button, Input, message, Popconfirm, Space, Table, Tag, Image, Select, Flex } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { CheckSquareOutlined, PlusOutlined } from '@ant-design/icons';
import CreateModelpack from './CreateModelpack';
import EditModelpack from './EditModelpack';
import ApproveModelpack from './ApproveModelpack';
import { Filter } from '../../../types/types';
import { useModelPackServiceDeleteModelPack, useModelPackServiceGetModelPack } from '../../../openapi/queries';
import { GenerateODataFilter } from '../../../helpers/odataFunctions';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { ModelPackViewDTO } from '../../../openapi/requests';
import { useDebounce } from 'use-debounce';
import { AssetApprovalStatus, AssetApprovalStatusLabels } from '../../../models/enums';
import { BASE_PATH } from '../../..';


const ModelpackList: React.FC = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedModelpack, setSelectedModelpack] = useState<ModelPackViewDTO | undefined>(undefined);

    const [approveOpen, setApproveOpen] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);

    const [query, setQuery] = useState("");
    const [debouncedQuery] = useDebounce(query, 500);
    const [statuses, setStatuses] = useState<number[]>([]);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState<string>("id");
    const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");


    const filters = useMemo(() => {
        let filters: Filter[] = [];

        if (debouncedQuery) {
            filters.push({type: 'search', values: [debouncedQuery], property: 'title', name: 'title'});
        }

        if (statuses.length > 0) {
            filters.push({type: 'select', values: statuses, property: 'approvalStatus', name: 'approvalStatus'});
        }

        return filters;
    }, [debouncedQuery, statuses]);

    useEffect(() => {
        setPage(1);
    }, [debouncedQuery, statuses]);

    const { data, isLoading, refetch } = useModelPackServiceGetModelPack({ 
        top: pageSize.toString(), 
        skip: ((page - 1) * pageSize).toString(),
        filter: GenerateODataFilter(filters),
        orderby: `${sortField} ${sortOrder}`,
        count: 'true' })
    const {mutate, isSuccess} = useModelPackServiceDeleteModelPack();


    const handleStatusChange = useCallback((value: any, option: { label: string; value: string; } | { label: string; value: string; }[]) => {
        if (Array.isArray(option)) {
            setStatuses(option.map(e => parseInt(e.value)));
        }
    }, []);

    const openCreate = () => {
        setCreateOpen(true);
    };

    const handleCreateClose = useCallback(() => {
        setCreateOpen(false);
        refetch();
    }, [refetch]);

    const handleEditClose = useCallback(() => {
        setSelectedModelpack(undefined);
        refetch();
    }, [refetch]);

    const startApproval = () => {
        setApproveOpen(true);
    };

    const handleApprovalClose = useCallback(() => {
        setApproveOpen(false);
        refetch();
    }, [refetch]);

    const handleTableChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<ModelPackViewDTO> | SorterResult<ModelPackViewDTO>[]) => {
        setPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);

        if(!Array.isArray(sorter)){
            setSortField(sorter.field?.toString() ?? "id");
            setSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc');
        }
    }, []);

    const handleDelete = useCallback((material: ModelPackViewDTO) => {
        if(material.id){
            mutate({key: material.id})
        }
    }, [mutate]);

    useEffect(() => {
        if(isSuccess){
            messageApi.success("model pack deleted");
            refetch();
        }
    }, [isSuccess, messageApi, refetch]);

    const columns: ColumnsType<ModelPackViewDTO> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => (a.id || 0) - (b.id || 0),
        },
        {
            title: 'Thumbnail',
            dataIndex: 'thumbnail',
            key: 'title',
            render: (_, value) => (
                <Image
                    preview={false}
                    src={BASE_PATH + value.thumbnail}
                    height={100}
                />)
        },
        {
            title: 'Status',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
            render: (_, value) => (AssetApprovalStatusLabels[value.approvalStatus as AssetApprovalStatus])
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => (a.title || '').localeCompare(b.title || ''),
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (_, { tags }) => (<>{tags?.map((tag) => <Tag key={tag}>{tag}</Tag>)}</>),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => setSelectedModelpack(record)}>Edit</Button>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                        <Button type="link">Delete</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <>
            {contextHolder}

            <Flex justify='space-between' style={{ marginBottom: 16 }}>

                <Space>
                    <Input placeholder='search' value={query} onChange={e => setQuery(e.target.value)} />
                    <Select
                        mode="tags"
                        placeholder="Status"
                        onChange={handleStatusChange}
                        popupMatchSelectWidth={false}
                        size='middle'
                        style={{ minWidth: 100 }}
                        options={[
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Quote],
                                value: AssetApprovalStatus.Quote.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Pending],
                                value: AssetApprovalStatus.Pending.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.InProduction],
                                value: AssetApprovalStatus.InProduction.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Commited],
                                value: AssetApprovalStatus.Commited.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.New],
                                value: AssetApprovalStatus.New.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Rejected],
                                value: AssetApprovalStatus.Rejected.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.RejectedButModified],
                                value: AssetApprovalStatus.RejectedButModified.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.Approved],
                                value: AssetApprovalStatus.Approved.toString()
                            },
                            {
                                label: AssetApprovalStatusLabels[AssetApprovalStatus.ApprovedButModified],
                                value: AssetApprovalStatus.ApprovedButModified.toString()
                            }
                        ]}
                    />
                </Space>

                <Space>
                    <Button type="primary" onClick={startApproval} icon={<CheckSquareOutlined />}>
                        Start approving
                    </Button>
                    <Button type="primary" onClick={openCreate} icon={<PlusOutlined />}>
                        New modelpack
                    </Button>
                </Space>
            </Flex>


            <CreateModelpack isOpen={createOpen} onClose={handleCreateClose} />

            <EditModelpack isOpen={selectedModelpack != null} onClose={handleEditClose} modelpack={selectedModelpack} />

            <Table
                loading={isLoading}
                dataSource={data?.value ?? Array.from({ length: pageSize }).map(x => ({}))}
                columns={columns}
                rowKey={"id"}
                onChange={handleTableChange}
                pagination={{
                    total: (data !== undefined && data['@odata.count']) ? data['@odata.count'] : 0,
                    current: page,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50, 100],
                }} />

            <ApproveModelpack isOpen={approveOpen} modelpacks={data?.value || []} onClose={handleApprovalClose} />
        </>
    );
};

export default ModelpackList;