import { useMutation } from '@tanstack/react-query';
import { Alert, Button, Drawer, Form, Input, message, Select, Space } from 'antd';
import saveAs from 'file-saver';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BASE_PATH } from '../../..';
import { useInternalClientServiceGetInternalClient } from '../../../openapi/queries';
import { ApiError, AssetProjectEditDTO, AssetProjectViewDTO, OpenAPI } from '../../../openapi/requests';
import { request } from '../../../openapi/requests/core/request';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';


const CreateAssetProject: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
    const { data: clients } = useInternalClientServiceGetInternalClient({}, undefined, { enabled: isOpen });
    const [form] = Form.useForm<AssetProjectEditDTO>();
    const [file, setFile] = useState<File | undefined>(undefined);
    const navigate = useNavigate();

    const {mutate, isPending, isSuccess, error, data} = useMutation<AssetProjectViewDTO, ApiError, AssetProjectEditDTO>({ mutationFn: (form: AssetProjectEditDTO) => request(OpenAPI, {
        method: 'POST',
        url: '/AssetProject',
        formData: form
    })});


    const downloadTemplate = useCallback(() => {
        const token = localStorage.getItem('accessToken');

        fetch(`${BASE_PATH}/assetproject/0/excelTemplate`, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob, "assets.xlsx");
        });
    }, []);

    const onSubmit = () => {
        form.submit();
    };

    const onFinish = () => {
        const values = form.getFieldsValue(true) as AssetProjectEditDTO;
        values.file = file as Blob;
        console.log(values);
        mutate(values);
    };

    useEffect(() => {
        if (isSuccess && data) {
            message.success("Project created");
            navigate("/admin/assetprojects/" + data.id)
        }
    }, [data, isSuccess, navigate]);

    const onFileChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;

        if (files && files.length !== 0) {
            setFile(files[0]);
        } else {
            setFile(undefined);
        }
    }, []);

    const errors: string[] | undefined = useMemo(() => {

        if(error){
            if(error.body && Array.isArray(error.body)){
                let errorMessages = error.body;
                return errorMessages.map(e => `line: ${e.line} -> ${e.message}`);
            }else{
                return [error.message];
            }
        }

        return undefined;
    }, [error]);

    return (
        <>
            <Drawer
                title="Create a new project"
                width={720}
                onClose={onClose}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item rules={[{ required: true }]} name='clientId' label="Client">
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name='title' label="Title">
                        <Input />
                    </Form.Item>

                    <Form.Item name='assets' label={<>Assets <Button type='link' onClick={downloadTemplate}>download template</Button></>} >
                        <input type='file' accept='.xlsx' onChange={onFileChanged} />
                    </Form.Item>
                </Form>

                {errors && <Alert
                    message="Error"
                    description={errors?.map(e => <p>{e}</p>)}
                    type="error"
                    showIcon
                    />}
                
                
            </Drawer>
        </>

    );
};

export default CreateAssetProject;