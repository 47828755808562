import React from 'react';
import ModifierList from '../../../components/admin/modifiers/ModifierList';

const Modifiers: React.FC = () => {

    return (<div>
        <ModifierList />
    </div>)
};

export default Modifiers;