import React, { useCallback, useState } from 'react';
import AssetSearch from '../components/search/AssetSearch';
import { AssetSearchResultDTO } from '../openapi/requests';
import ProjectSearch from '../components/search/ProjectSearch';

const SearchPage: React.FC = () => {
    const [selection, setSelection] = useState<AssetSearchResultDTO[]>([]);
    const [searchProjects, setSearchProjects] = useState(false);
    const [mode, setMode] = useState("or");

    const handleAssetSelect = useCallback((asset: AssetSearchResultDTO) => {
        const index = selection.findIndex(e => e.assetType === asset.assetType && e.id === asset.id);

        if (index === -1) {
            setSelection([...selection, asset]);
        } else {
            var items = [...selection];
            items.splice(index, 1);
            setSelection(items);
        }
    }, [selection]);

    const handleSearchOr = useCallback(() => {
        setMode('or');
        setSearchProjects(true);
    }, []);

    const handleSearchAnd = useCallback(() => {
        setMode('and');
        setSearchProjects(true);
    }, []);

    const handleCancel = useCallback(() => {
        setSearchProjects(false);
    }, []);

    return (<>
        {searchProjects ? 
            <ProjectSearch mode={mode} onAssetSelected={handleAssetSelect} selection={selection} onCancel={handleCancel} /> :
            <AssetSearch onAssetSelected={handleAssetSelect} selection={selection} onSearchProjectsAll={handleSearchAnd} onSearchProjectsAny={handleSearchOr} />
        }
    </>)
};

export default SearchPage;