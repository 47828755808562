import React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isDevSite } from '.';
import AppRouter from './AppRouter';

const App: React.FC = () => {
    return (
        <>
            {isDevSite && <div className='dev-banner'>
                This is a test site, you may encounter stability issues and bugs.
                <a href="https://cod.cadesignform.dk/">Click here</a> to open the production site
            </div>}
            <AppRouter />
            {isDevSite && <ReactQueryDevtools initialIsOpen={false} />}
        </>
    );
};

export default App;