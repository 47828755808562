import { useEffect, useState } from "react";
import { ReactComponent as LoadingIcon } from '../../../assets/icons/loading.svg';
import "./Loader.scss";

export const Loader: React.FC = () => {

    const [totalPct, setTotalPct] = useState(0);   

    useEffect(() => {
        document.addEventListener("loading",((event: CustomEvent) => {

            let total = parseInt(event.detail.total);
            let processed = parseInt(event.detail.processed);
            let totalPct = Math.round((processed / total) * 100);
            setTotalPct(totalPct);
        
         }) as EventListener);
    }, []);

    return (
        <div className="loader-overlay">
            <div className="loader-spinner">
                <span><LoadingIcon /></span>
                <span><b>{totalPct}%</b></span>
            </div>
        </div>
    )
} 