import { getFilteredRowModel, useReactTable, ColumnDef, getCoreRowModel, flexRender, getSortedRowModel, SortingState, ColumnFiltersState, ColumnFilter, OnChangeFn, TableState } from "@tanstack/react-table";
import { AnyObject } from "antd/es/_util/type";
import { ReactNode, useEffect, useState } from "react";

export interface StateChangeArgs {
    length: number
}

export const TableCell: React.FC<{ row: AnyObject, className?: string, children: ReactNode, onSelect: (e: any, row: AnyObject) => void }> = ({ row, children, onSelect, className }) => {
    return (
        <div className={className} onClick={(e) => onSelect(e, row.original)}>
            <span>{children}</span>
        </div>
    )
}

export const ProjectTable: React.FC<{ columns: ColumnDef<any, any>[], data: any[], globalFilter: string, filters: ColumnFilter[] | undefined,onStateChange?: (value: StateChangeArgs) => void }> = ({ columns, data, globalFilter, filters, onStateChange }) => {

    const [sorting, setSorting] = useState<SortingState>([])
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  
    const onTableStateChange: OnChangeFn<TableState> = () => {
        if (onStateChange) {
            onStateChange({ length: table.getFilteredRowModel().rows.length } as StateChangeArgs)
        }
    }

    const table = useReactTable({        
        columns,
        data,
        state: {
            sorting,
            columnFilters,
            globalFilter
        },
        manualSorting: true,
        onStateChange: onTableStateChange,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),     
        enableSortingRemoval: false
    });

    useEffect(() => {
        if (filters) {
            setColumnFilters(filters)
        }
    }, [filters])

    return (
        <table style={{ tableLayout: 'auto' }}>
            <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: 'auto' }} />
                <col style={{ width: '10%' }} />
            </colgroup>
            <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            return (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <>
                                            <div
                                                {...{
                                                    className: `${header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : ''} ${header.column.getIsSorted() as string !== 'false' ? header.column.getIsSorted() as string : ''}`,
                                                    onClick: header.column.getToggleSortingHandler(),
                                                }}
                                            >
                                                <span>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                </span>
                                                {{
                                                    asc: <span className="sort"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.5 6.5L8 11L12.5 6.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg></span>,
                                                    desc: <span className="sort"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.5 6.5L8 11L12.5 6.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg></span>,
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </div>
                                        </>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map(row => {
                    return (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => {
                                return (
                                    <td key={cell.id}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )

}