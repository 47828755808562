import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import ReactGA from "react-ga4";
import { BASE_PATH } from '../..';
import { ReactComponent as LoadingIcon } from '../../assets/icons/loading.svg';
import { useModelServiceGetModel1, useSearchCutoutServiceGetSearchCutout, useSearchProjectServiceGetSearchProject } from '../../openapi/queries';
import Overlay from '../layout/Overlay';
import ProjectList from '../layout/ProjectList';
import { OrderlineList } from '../project/OrderlineList';
import './AssetDetails.scss';

const LoadingComponent = motion(LoadingIcon)

interface Props {
    open: boolean;
    modelId: number | undefined;
    onClose: () => void;
}

interface Metadata {
    label: string;
    value: string;
}

const scenemetadata = [
    {
        label: 'Brand',
        name: 'brand'
    },
    {
        label: 'Banner',
        name: 'banner'
    },
    {
        label: 'Range',
        name: 'range'
    },
    {
        label: 'Category',
        name: 'category'
    },
    {
        label: 'Description',
        name: 'description'
    }
];


const ModelDetails: React.FC<Props> = ({ open, modelId, onClose }) => {
    const { data: model } = useModelServiceGetModel1({ key: modelId! }, undefined, { enabled: open && modelId !== undefined });
    const { data: projects, isLoading: projectsLoading } = useSearchProjectServiceGetSearchProject({ assets: [model?.name], top: '25', orderby: 'timestampModify desc', filter: '(status eq 40 or status eq 50)' }, undefined, { enabled: open && model !== undefined })
    const { data: cutouts, isLoading: cutoutsLoading } = useSearchCutoutServiceGetSearchCutout({ asset: model?.name, top: '25' }, undefined, { enabled: open && model !== undefined })

    useEffect(() => {
        if (open) {
            ReactGA.event('open_dialog', { dialog: 'modeldetails' });
        }
    }, [open]);

    const [metadata, setMetadata] = useState<Metadata[]>([]);

    useEffect(() => {
        const result = [];

        if (model) {
            for (let i = 0; i < scenemetadata.length; i++) {
                const element = scenemetadata[i];

                const data = model.metadata.find(e => e.name.toLowerCase() === element.name.toLowerCase());

                if (data) {
                    result.push({
                        label: element.label,
                        value: data.value
                    })
                }
            }
        }

        setMetadata(result);
    }, [model]);

    return (
        <>
            <Overlay open={open} onClose={onClose} className='asset-details'>
                <div className="asset-detail-header"><h1>{model?.title}</h1></div>
                <div className="asset-details-overlay-content">
                    <div className='meta info-list'>
                        <div className="info-list-header"><span>Details</span></div>
                        {model && <img src={BASE_PATH + model.image} alt={model.title} />}
                        <ul>
                            {metadata.map(e => <li key={e.label}><span>{e.label}</span><span>{e.value}</span></li>)}
                        </ul>
                    </div>
                    <div className='images info-list'>
                        <div className="info-list-header"><span>Cut-outs</span></div>
                        <AnimatePresence>
                            {(cutoutsLoading) && <LoadingComponent
                                className={'loading'}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                key={'loading'} />}
                        </AnimatePresence>
                        <OrderlineList orderlines={cutouts?.value ?? []} />
                        {(!cutoutsLoading && cutouts?.value.length === 0) && <span>Nothing found</span>}
                    </div>
                    <div className='images info-list'>
                        <div className="info-list-header"><span>Projects using this model</span></div>
                        <AnimatePresence>
                            {(projectsLoading) && <LoadingComponent
                                className={'loading'}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                key={'loading'} />}
                        </AnimatePresence>
                        <ProjectList projects={projects?.value ?? []} />
                        {(!projectsLoading && projects?.value.length === 0) && <span>Nothing found</span>}
                    </div>
                </div>
            </Overlay>
        </>
    );
};

export default ModelDetails;