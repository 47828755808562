/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeliveryMethodSftp } from '../models/DeliveryMethodSftp';
import type { InternalDeliveryMethodSftpDTO } from '../models/InternalDeliveryMethodSftpDTO';
import type { InternalDeliveryMethodSftpDTOODataListResponse } from '../models/InternalDeliveryMethodSftpDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalDeliveryMethodSftpService {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns InternalDeliveryMethodSftpDTOODataListResponse Success
     * @throws ApiError
     */
    public static getInternalDeliveryMethodSftp(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<InternalDeliveryMethodSftpDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/DeliveryMethodSftp',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns InternalDeliveryMethodSftpDTO Success
     * @throws ApiError
     */
    public static postInternalDeliveryMethodSftp(
requestBody?: InternalDeliveryMethodSftpDTO,
): CancelablePromise<InternalDeliveryMethodSftpDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal/DeliveryMethodSftp',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns InternalDeliveryMethodSftpDTO Success
     * @throws ApiError
     */
    public static getInternalDeliveryMethodSftp1(
key: number,
): CancelablePromise<InternalDeliveryMethodSftpDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/DeliveryMethodSftp/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns InternalDeliveryMethodSftpDTO Success
     * @throws ApiError
     */
    public static putInternalDeliveryMethodSftp(
key: number,
requestBody?: InternalDeliveryMethodSftpDTO,
): CancelablePromise<InternalDeliveryMethodSftpDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal/DeliveryMethodSftp/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteInternalDeliveryMethodSftp(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/internal/DeliveryMethodSftp/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param clientId 
     * @param query 
     * @returns DeliveryMethodSftp Success
     * @throws ApiError
     */
    public static getInternalDeliveryMethodSftpQuery(
clientId?: number,
query?: string,
): CancelablePromise<Array<DeliveryMethodSftp>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal/DeliveryMethodSftp/query',
            query: {
                'clientId': clientId,
                'query': query,
            },
        });
    }

}
