import { Button, Col, Descriptions, Image, Input, Modal, Row, Space, Tag } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EditModelpack from './EditModelpack';
import { ModelPackService, ModelPackViewDTO } from '../../../openapi/requests';
import { useModelPackServiceGetModelPackQAimages, useModelPackServicePutModelPack } from '../../../openapi/queries';
import { BASE_PATH } from '../../..';
import { AssetApprovalStatus, AssetApprovalStatusLabels } from '../../../models/enums';

const ApproveModelpack: React.FC<{ modelpacks: ModelPackViewDTO[], isOpen: boolean, onClose: () => void, hideEditor?: boolean }> = ({ modelpacks, isOpen, onClose, hideEditor }) => {
    const [index, setIndex] = useState(0);
    const [comment, setComment] = useState("");
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    
    const modelpack = useMemo(() => {
        if(index < modelpacks.length){
            return modelpacks[index];
        }
        return undefined;
    }, [index, modelpacks]);
    
    const {data: qaImages} = useModelPackServiceGetModelPackQAimages({key: modelpack?.id ?? 0}, undefined, {enabled: isOpen && modelpack !== undefined});
    const {mutateAsync, isPending} = useModelPackServicePutModelPack();

    useEffect(() => {
        if (isOpen) {
            setIndex(0);
            setComment("");
        }
    }, [modelpacks, isOpen]);

    const handleApprove = useCallback(() => {
        if(modelpack === undefined){
            return;
        }

        mutateAsync({
            key:modelpack.id,
            requestBody: {
                ...modelpack,
                approved: true,
                approvalComment: comment
            }
        }).then(() => {
            if (index + 1 === modelpacks.length) {
                onClose();
            } else {
                setIndex(index + 1);
                setComment("");
            }
        });
    }, [modelpack, mutateAsync, comment, index, modelpacks, onClose]);

    const handleReject = useCallback(() => {
        if(modelpack === undefined){
            return;
        }

        mutateAsync({
            key:modelpack.id,
            requestBody: {
                ...modelpack,
                approved: false,
                approvalComment: comment
            }
        }).then(() => {
            if (index + 1 === modelpacks.length) {
                onClose();
            } else {
                setIndex(index + 1);
                setComment("");
            }
        });
    }, [modelpack, mutateAsync, comment, index, modelpacks, onClose]);

    const handleSkip = useCallback(() => {
        if (index + 1 === modelpacks.length) {
            onClose();
        } else {
            setIndex(index + 1);
            setComment("");
        }
    }, [index, modelpacks.length, onClose]);

    const handleEdit = useCallback(() => {
        setIsEditorOpen(true)
    }, []);

    const handleEditClose = useCallback(() => {
        if(modelpack){
            ModelPackService.getModelPack1(modelpack.id)
            .then((e) => {
                Object.assign(modelpack, e);
                setIsEditorOpen(false);
            });
        }
    }, [modelpack]);

    return (
        <>
            <Modal
                width={1000}
                open={isOpen}
                title={modelpack?.title}
                onCancel={onClose}
                footer={[
                    <Space><Input value={comment} placeholder='comments' title='reason' onChange={e => setComment(e.target.value)} /></Space>,
                    <Button key="back" disabled={isPending} danger type="primary" onClick={handleReject}>
                        Reject
                    </Button>,
                    <Button key="submit" disabled={isPending} type="primary" onClick={handleApprove}>
                        Approve
                    </Button>,
                    hideEditor === true ? null : <Button key="edit" disabled={isPending} type="default" onClick={handleEdit}>
                        Edit
                    </Button>,
                    <Button key="submit" disabled={isPending} type="link" onClick={handleSkip}>
                        Skip
                    </Button>,
                ]}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        Thumbnail:
                        <Image src={BASE_PATH + modelpack?.image} />
                        QA Images:
                        <Space>
                            {qaImages?.map(e => <Image src={BASE_PATH + e} />)}
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Descriptions layout='horizontal' bordered column={1} size='small' title="Data" items={[
                            {
                                label: 'Status',
                                children: AssetApprovalStatusLabels[(modelpack?.approvalStatus ?? 0) as AssetApprovalStatus]
                            },
                            {
                                label: 'Title',
                                children: modelpack?.title
                            },
                            {
                                label: 'Build script',
                                children: modelpack?.buildScript
                            },
                            {
                                label: 'Name',
                                children: modelpack?.name
                            },
                            {
                                label: 'Tags',
                                children: modelpack?.tags.map(e => <Tag>{e}</Tag>)
                            },
                            {
                                label: 'Meta data',
                                children: <Descriptions size='small' colon layout='horizontal' column={1} items={modelpack?.metadata.map(e => ({ label: e.name, children: e.value }))} />
                            },
                        ]} />
                    </Col>
                </Row>
            </Modal>

            {(hideEditor !== true) && <EditModelpack modelpack={modelpack} isOpen={isEditorOpen} onClose={handleEditClose} />}
        </>

    );
};

export default ApproveModelpack;