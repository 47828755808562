/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientDTO } from '../models/ClientDTO';
import type { ClientDTOODataListResponse } from '../models/ClientDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClientService {

    /**
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ClientDTOODataListResponse Success
     * @throws ApiError
     */
    public static getClient(
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ClientDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Client',
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @returns ClientDTO Success
     * @throws ApiError
     */
    public static getClient1(
key: number,
): CancelablePromise<ClientDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Client/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns ClientDTO Success
     * @throws ApiError
     */
    public static postClient(
key: string,
requestBody?: ClientDTO,
): CancelablePromise<ClientDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Client/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns ClientDTO Success
     * @throws ApiError
     */
    public static putClient(
key: number,
requestBody?: ClientDTO,
): CancelablePromise<ClientDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/Client/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteClient(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Client/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param clientId 
     * @param query 
     * @returns ClientDTO Success
     * @throws ApiError
     */
    public static getQuery(
clientId?: number,
query?: string,
): CancelablePromise<Array<ClientDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/query',
            query: {
                'clientId': clientId,
                'query': query,
            },
        });
    }

}
