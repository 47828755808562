import React, { useCallback, useEffect, useState } from 'react';
import { HelpService } from '../../openapi/requests';
import { Dialog } from '../layout/Dialog';
import { ReactComponent as IssueIcon } from '../../assets/icons/issue.svg';
import ReactGA from "react-ga4";

type TemplateProps = {
    type: "template";
    templateId: number | undefined;
}

type ProjectProps = {
    type: "project";
    projectId: number | undefined;
}
type Props = (TemplateProps | ProjectProps) & {
    className?: string;
}

const IssueReporting: React.FC<Props> = (props) => {

    const [sendingErrorReport, setSendingErrorReport] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorReportVisible, setErrorReportVisible] = useState(false);

    useEffect(() => {
        if(!errorReportVisible){
            setErrorMessage("");
        }
    }, [errorReportVisible]);
    
    const handleReportError = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        ReactGA.event('report_issue', {type: props.type});
        
        e.preventDefault();
        setSendingErrorReport(true);

        if(props.type === "template"){
            HelpService.postHelpTemplate(errorMessage, props.templateId).then(() => {
                setSendingErrorReport(false);
                setErrorReportVisible(false);
            });
        }else if(props.type === "project"){
            HelpService.postHelpProject(errorMessage, props.projectId).then(() => {
                setSendingErrorReport(false);
                setErrorReportVisible(false);
            });
        }
    }, [errorMessage, props]);

    return (
        <>
            <button className={props.className} onClick={() => setErrorReportVisible(true)}><IssueIcon width={10} height={10} /> Report issue</button>
            <form  onSubmit={handleReportError}>
                <Dialog open={errorReportVisible} className='confirm'>
                    <h1>Report issue</h1>
                    
                    <p className='confirm-body'>
                        Please describe the issue, and we will respond to you asap
                        <textarea required value={errorMessage} onChange={e => setErrorMessage(e.target.value)} />
                    </p>

                    <div className='confirm-footer'>
                        <button onClick={() => setErrorReportVisible(false)}>Cancel</button>
                        <button disabled={sendingErrorReport} className='confirm' type='submit'>Send</button>
                    </div>
                </Dialog>
            </form>
        </>
    );
};

export default IssueReporting;