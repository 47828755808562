/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExportService {

    /**
     * @param start 
     * @param end 
     * @returns binary Success
     * @throws ApiError
     */
    public static getExportKingfisherProjects(
start?: string,
end?: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Export/kingfisher/projects',
            query: {
                'start': start,
                'end': end,
            },
        });
    }

    /**
     * @returns binary Success
     * @throws ApiError
     */
    public static getExportKingfisherAssets(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Export/kingfisher/assets',
        });
    }

    /**
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postExportKingfisherAssets(
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Export/kingfisher/assets',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
