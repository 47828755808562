import type { ColumnDef, OnChangeFn, SortingState } from '@tanstack/react-table';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React, { useMemo } from 'react';

export interface TableProps<TData> {
    data: TData[];
    columns: ColumnDef<TData>[];
    isLoading?: boolean;
    isFetching?: boolean;
    sorting?: SortingState;
    setSorting?: OnChangeFn<SortingState>;
}

export const ReactTable = <TData extends object>({
    data,
    columns,
    isLoading,
    sorting,
    setSorting,
}: TableProps<TData>) => {
    const memoizedData = useMemo(() => data, [data]);
    const memoizedColumns = useMemo(() => columns, [columns]);

    const table = useReactTable({
        data: memoizedData,
        columns: memoizedColumns,
        state: {
            sorting,
        },
        manualSorting: true,       
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
    });

    const isNoDataFound =
        !isLoading && (!memoizedData || memoizedData.length === 0);

    return (
        <>       
            <table style={{ tableLayout: 'auto' }}>
                <colgroup>
                    <col style={{ width: '40px' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: 'auto' }} />
                    <col style={{ width: '10%' }} />
                </colgroup>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <th key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                            <>
                                                <div
                                                    {...{
                                                        className: `${header.column.getCanSort()
                                                            ? 'cursor-pointer select-none'
                                                            : ''} ${header.column.getIsSorted() as string !== 'false' ? header.column.getIsSorted() as string : ''}`,
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    <span>
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                    </span>
                                                    {{
                                                        asc: <span className="sort"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.5 6.5L8 11L12.5 6.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg></span>,
                                                        desc: <span className="sort"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.5 6.5L8 11L12.5 6.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg></span>,
                                                    }[header.column.getIsSorted() as string] ?? null}
                                                </div>
                                            </>
                                        )}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>                   
                    {table.getRowModel().rows.map(row => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    {!isNoDataFound && isLoading ? ( <tr><td colSpan={5} align='center' style={{pointerEvents: 'none'}}>Loading...</td></tr> ) : null}
                </tbody>
            </table>
                
        </>

    );
};