import React from 'react';
import MaterialList from '../../../components/admin/materials/MaterialList';

const Materials: React.FC = () => {

    return (<div>
        <MaterialList />
    </div>)
};

export default Materials;