import { Button, Col, Descriptions, Dropdown, Image, Input, Modal, Row, Space, Tag, Timeline, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EditMaterial, { MaterialHistoryDetailsButton } from './EditMaterial';
import { MaterialService, MaterialViewDTO } from '../../../openapi/requests';
import { useMaterialServiceGetMaterialApprovalHistory, useMaterialServiceGetMaterialQAimages, useMaterialServicePutMaterial } from '../../../openapi/queries';
import { BASE_PATH } from '../../..';
import { AssetApprovalStatus, AssetApprovalStatusLabels } from '../../../models/enums';
import ReactTimeago from 'react-timeago';
const { Text } = Typography;

const ApproveMaterial: React.FC<{ materials: MaterialViewDTO[], isOpen: boolean, onClose: () => void, hideEditor?: boolean }> = ({ materials, isOpen, onClose, hideEditor }) => {
    const [index, setIndex] = useState(0);
    const [comment, setComment] = useState("");
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    
    const material = useMemo(() => {
        if(index < materials.length){
            return materials[index];
        }
        return undefined;
    }, [index, materials]);
    
    const {data: qaImages} = useMaterialServiceGetMaterialQAimages({key: material?.id ?? 0}, undefined, {enabled: isOpen && material !== undefined});
    const {data: qaHistory} = useMaterialServiceGetMaterialApprovalHistory({key: material?.id ?? 0}, undefined, {enabled: isOpen && material !== undefined});
    const {mutateAsync, isPending} = useMaterialServicePutMaterial();

    useEffect(() => {
        if (isOpen) {
            setIndex(0);
            setComment("");
        }
    }, [materials, isOpen]);

    const handleApprove = useCallback((enable: boolean) => {
        if(material === undefined){
            return;
        }

        mutateAsync({
            key:material.id,
            requestBody: {
                ...material,
                isEnabled: enable ? enable : material.isEnabled,
                approved: true,
                approvalComment: comment
            }
        }).then(() => {
            if (index + 1 === materials.length) {
                onClose();
            } else {
                setIndex(index + 1);
                setComment("");
            }
        });
    }, [material, mutateAsync, comment, index, materials, onClose]);

    const handleReject = useCallback(() => {
        if(material === undefined){
            return;
        }
        mutateAsync({
            key:material.id,
            requestBody: {
                ...material,
                approved: false,
                approvalComment: comment
            }
        }).then(() => {
            if (index + 1 === materials.length) {
                onClose();
            } else {
                setIndex(index + 1);
                setComment("");
            }
        });
    }, [material, mutateAsync, comment, index, materials, onClose]);

    const handleSkip = useCallback(() => {
        if (index + 1 === materials.length) {
            onClose();
        } else {
            setIndex(index + 1);
            setComment("");
        }
    }, [index, materials.length, onClose]);

    const handleEdit = useCallback(() => {
        setIsEditorOpen(true)
    }, []);

    const handleEditClose = useCallback(() => {
        if(material){
            MaterialService.getMaterial1(material.id)
            .then((e) => {
                Object.assign(material, e);
                setIsEditorOpen(false);
            });
        }
    }, [material]);

    return (
        <>
            <Modal
                width={1000}
                open={isOpen}
                title={material?.title}
                onCancel={onClose}
                styles={{
                    footer: {
                        gap: '10px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }
                }}
                footer={[
                    <Button key="back" disabled={isPending} danger type="primary" onClick={handleReject}>
                        Reject
                    </Button>,
                    <Space key="submit" >
                        <Dropdown.Button onClick={() => handleApprove(false)} menu={{ items: [{key: 1, label: 'Approve and enable'}], onClick: () =>  handleApprove(true)}}>Approve</Dropdown.Button>
                    </Space>,
                    hideEditor === true ? null : <Button key="edit" disabled={isPending} type="default" onClick={handleEdit}>
                        Edit
                    </Button>,
                    <Button key="submit" disabled={isPending} type="link" onClick={handleSkip}>
                        Skip
                    </Button>,
                ]}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        Thumbnail:
                        <Image src={BASE_PATH + material?.image} />
                        QA Images:
                        <Space>
                            {qaImages?.map(e => <Image src={BASE_PATH + e} />)}
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Descriptions layout='horizontal' bordered column={1} title="Data" items={[
                            {
                                label: 'Status',
                                children: AssetApprovalStatusLabels[(material?.approvalStatus ?? 0) as AssetApprovalStatus]
                            },
                            {
                                label: 'Enabled',
                                children: material?.isEnabled ? 'True' : 'False'
                            },
                            {
                                label: 'Title',
                                children: material?.title
                            },
                            {
                                label: 'Filename',
                                children: material?.filename
                            },
                            {
                                label: 'Name',
                                children: material?.name
                            },
                            {
                                label: 'Tags',
                                children: material?.tags.map(e => <Tag>{e}</Tag>)
                            },
                            {
                                label: 'Meta data',
                                children: <Descriptions size='small' colon layout='horizontal' column={1} items={material?.metadata.map(e => ({ label: e.name, children: e.value }))} />
                            },
                            {
                                label: 'History',
                                children: <Timeline items={[
                                    {
                                        color: 'blue',
                                        children: (<>
                                            Material created <ReactTimeago date={material?.timestampCreate ?? ""} />
                                        </>)
                                    },
                                    ...(qaHistory || []).map(e => ({
                                        color: e.newStatus === AssetApprovalStatus.Approved ? 'green' : (e.newStatus === AssetApprovalStatus.Rejected ? 'red' : 'blue'),
                                        children: (<>
                                            Status changed from <b>{AssetApprovalStatusLabels[e.oldStatus as AssetApprovalStatus]}</b> to <b>{AssetApprovalStatusLabels[e.newStatus as AssetApprovalStatus]}</b> {' '}
                                            <ReactTimeago date={e.timestamp} />
                                            <MaterialHistoryDetailsButton item={e} material={material} />
                                            {e.comment && <>
                                                <br />
                                                <Text code>{e.comment}</Text>
                                            </>}
                                        </>)
                                    }))]} />
                            },
                        ]} />
                        <Input.TextArea value={comment} placeholder='comments' title='reason' onChange={e => setComment(e.target.value)} />
                    </Col>
                </Row>
            </Modal>
            {(hideEditor !== true) && <EditMaterial material={material} isOpen={isEditorOpen} onClose={handleEditClose} />}
        </>
    );
};

export default ApproveMaterial;