/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetFilterPresetDTO } from '../models/AssetFilterPresetDTO';
import type { ClaimDTO } from '../models/ClaimDTO';
import type { ForgotPasswordModel } from '../models/ForgotPasswordModel';
import type { ResetPasswordModel } from '../models/ResetPasswordModel';
import type { RoleDTO } from '../models/RoleDTO';
import type { UserAssetFilterDTO } from '../models/UserAssetFilterDTO';
import type { UserDTO } from '../models/UserDTO';
import type { UserRenderLimitDTO } from '../models/UserRenderLimitDTO';
import type { UserViewDTO } from '../models/UserViewDTO';
import type { UserViewDTOODataListResponse } from '../models/UserViewDTOODataListResponse';
import type { WelcomeModel } from '../models/WelcomeModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param query 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns UserViewDTOODataListResponse Success
     * @throws ApiError
     */
    public static getUser(
query?: string,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<UserViewDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User',
            query: {
                'query': query,
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UserDTO Success
     * @throws ApiError
     */
    public static postUser(
requestBody?: UserDTO,
): CancelablePromise<UserDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/User',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @returns UserViewDTO Success
     * @throws ApiError
     */
    public static getUserMe(): CancelablePromise<UserViewDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/me',
        });
    }

    /**
     * @param key 
     * @returns UserViewDTO Success
     * @throws ApiError
     */
    public static getUser1(
key: number,
): CancelablePromise<UserViewDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns UserDTO Success
     * @throws ApiError
     */
    public static putUser(
key: number,
requestBody?: UserDTO,
): CancelablePromise<UserDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/User/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteUser(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/User/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns UserViewDTO Success
     * @throws ApiError
     */
    public static getUserClient(): CancelablePromise<Array<UserViewDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/client',
        });
    }

    /**
     * @param clientId 
     * @returns UserViewDTO Success
     * @throws ApiError
     */
    public static getUserClient1(
clientId: number,
): CancelablePromise<Array<UserViewDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/client/{clientId}',
            path: {
                'clientId': clientId,
            },
        });
    }

    /**
     * @param clientIds 
     * @returns UserViewDTO Success
     * @throws ApiError
     */
    public static getUserClients(
clientIds: Array<number>,
): CancelablePromise<Array<UserViewDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/clients/{clientIds}',
            path: {
                'clientIds': clientIds,
            },
        });
    }

    /**
     * @param key 
     * @returns UserAssetFilterDTO Success
     * @throws ApiError
     */
    public static getUserAssetFilter(
key: number,
): CancelablePromise<Array<UserAssetFilterDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/{key}/assetFilter',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns UserAssetFilterDTO Success
     * @throws ApiError
     */
    public static putUserAssetfilter(
key: number,
requestBody?: UserAssetFilterDTO,
): CancelablePromise<UserAssetFilterDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/User/assetfilter/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteUserAssetfilter(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/User/assetfilter/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UserAssetFilterDTO Success
     * @throws ApiError
     */
    public static postUserAssetfilter(
requestBody?: UserAssetFilterDTO,
): CancelablePromise<UserAssetFilterDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/User/assetfilter',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static getUserAssetfilterpreset(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/{key}/assetfilterpreset',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postUserAssetfilterpreset(
key: number,
requestBody?: AssetFilterPresetDTO,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/User/{key}/assetfilterpreset',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteUserAssetfilterpreset(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/User/{key}/assetfilterpreset',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postUserResetpassword(
requestBody?: ResetPasswordModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/User/resetpassword',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postUserForgotpassword(
requestBody?: ForgotPasswordModel,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/User/forgotpassword',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postUserWelcomemail(
requestBody?: WelcomeModel,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/User/welcomemail',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postUserResettoken(
requestBody?: ResetPasswordModel,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/User/resettoken',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns ClaimDTO Success
     * @throws ApiError
     */
    public static getUserClaim(
key: number,
): CancelablePromise<Array<ClaimDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/{key}/claim',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postUserClaim(
key: number,
requestBody?: ClaimDTO,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/User/{key}/claim',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteUserClaim(
key: number,
requestBody?: ClaimDTO,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/User/{key}/claim',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @returns ClaimDTO Success
     * @throws ApiError
     */
    public static getUserClaimsAll(): CancelablePromise<Array<ClaimDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/claims/all',
        });
    }

    /**
     * @param key 
     * @returns string Success
     * @throws ApiError
     */
    public static getUserRole(
key: number,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/{key}/role',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postUserRole(
key: number,
requestBody?: RoleDTO,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/User/{key}/role',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteUserRole(
key: number,
requestBody?: RoleDTO,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/User/{key}/role',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @returns UserRenderLimitDTO Success
     * @throws ApiError
     */
    public static getUserRenderlimits(): CancelablePromise<Array<UserRenderLimitDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/renderlimits',
        });
    }

}
