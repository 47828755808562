import { DeserializeEvent, DiagramEngine } from '@projectstorm/react-diagrams';
import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { ModelPortModel } from './ModelPort';
import { MaterialPortModel } from './MaterialPort';
import { ModifierPortModel } from './ModifierPort';
import { ConfiguratorNodeModel, ConfiguratorNodeWidget } from './ConfiguratorNode';
import { ModelPackPortModel } from './ModelPackPort';
import { Form, Select } from 'antd';
import { useCallback } from 'react';
import { ConfiguratorPortModel } from './ConfiguratorPort';
import { SceneCameraDTO, ScenePropsetDTO, ScenePropsetOptionDTO, SceneService, SceneViewDTO } from '../../../../../openapi/requests';

export class SceneNodeModel extends ConfiguratorNodeModel<SceneViewDTO> {
	protected onPortSelected: (port: ConfiguratorPortModel) => void;

    cameras: SceneCameraDTO[] = [];
    camera: SceneCameraDTO | undefined;

    propSets: {set: ScenePropsetDTO, options: ScenePropsetOptionDTO[], option: ScenePropsetOptionDTO | undefined}[] = [];

    constructor(updateCallback: () => void, onPortSelected: (port: ConfiguratorPortModel) => void) {
        super('scene', updateCallback);
        this.onPortSelected = onPortSelected;
    }

	deserialize(event: DeserializeEvent<this>) {
		super.deserialize(event);
	}

	serialize(): any {
		return {
			...super.serialize(),
			camera: this.camera?.cameraName,
            propSets: this.propSets.map(e => e.option?.name)
		};
	}

    async loadData(sceneID: number): Promise<void> {

		this.modelPorts = this.modelPorts.filter(e => !e.in);
		this.modelPackPorts = this.modelPackPorts.filter(e => !e.in);
		this.materialPorts = this.materialPorts.filter(e => !e.in);
		this.modifierPorts = this.modifierPorts.filter(e => !e.in);

        let sceneResponse = await SceneService.getScene1(sceneID);
        this.data = sceneResponse;
        
        for (let i = 0; i < this.data.placementpoints.length; i++) {
            const point = this.data.placementpoints[i];
            
            if(point.useModelpack){
                this.addPort(new ModelPackPortModel(true, point.name, point.label, this.onPortSelected, point.modelSelectionFilter));
            }else{
                this.addPort(new ModelPortModel(true, point.name, point.label, this.onPortSelected, point.modelSelectionFilter));
            }
        }

        for (let i = 0; i < this.data.surfaces.length; i++) {
            const point = this.data.surfaces[i];
            
            this.addPort(new MaterialPortModel(true, point.name, point.label, this.onPortSelected, point.materialSelectionFilter));
        }

        for (let i = 0; i < this.data.modifierTargets.length; i++) {
            const point = this.data.modifierTargets[i];
            
            this.addPort(new ModifierPortModel(true, point.name, point.label, this.onPortSelected, point.modifierSelectionFilter));
        }

        this.cameras = this.data.cameras;
        this.camera = this.cameras[0];

        for (let i = 0; i < this.data.propsets.length; i++) {
            const propset = this.data.propsets[i];

            this.propSets.push({
                set: propset,
                options: propset.options,
                option: undefined
            });
        }

        
        this.updateCallback();
    }
}

export class SceneNodeFactory extends AbstractReactFactory<SceneNodeModel, DiagramEngine> {
    constructor() {
        super('scene');
    }

    generateReactWidget(event: GenerateWidgetEvent<SceneNodeModel>): JSX.Element {
        return <SceneNodeWidget engine={this.engine} node={event.model} />;
    }

    generateModel(event : GenerateModelEvent) {
        return new SceneNodeModel(() => {}, () => {});
    }
}

export interface SceneNodeProps {
	node: SceneNodeModel;
	engine: DiagramEngine;
	children?: React.ReactNode;
}

export const SceneNodeWidget: React.FC<SceneNodeProps> = ({ engine, node }) => {
    const onCameraChanged = useCallback((value: any, option: {label: string, value: string} | {label: string, value: string}[]) => {
        if(!Array.isArray(option)){
            node.camera = node.cameras.find(e => e.cameraName === option.value);
            node.updateCallback();
        }
    }, [node]);

    const onPropsetChanged = useCallback((setId: string, value: any, option: {label: string, value: string} | {label: string, value: string}[]) => {
        if(!Array.isArray(option)){
            const set = node.propSets.find(e => e.set.name === setId);

            if(set){
                const selectedOption = set.options.find(e => e.name === option.value);
                set.option = selectedOption;
                node.updateCallback();
            }
        }
    }, [node]);

    return <ConfiguratorNodeWidget node={node} engine={engine}>
        <Form>
            <Form.Item label='camera'>
                <Select popupMatchSelectWidth={false} value={node.camera?.cameraName} options={node.cameras.map(e => ({ label: e.label, value: e.cameraName }))} onChange={onCameraChanged} />
            </Form.Item>
            {node.propSets.map(e => (
                <Form.Item label={e.set.label} key={e.set.name}>
                    <Select popupMatchSelectWidth={false} value={e.option?.name} options={e.options.map(e => ({ label: e.title, value: e.name }))} onChange={(value, option) => onPropsetChanged(e.set.name, value, option)} />
                </Form.Item>
            ))}
        </Form>
    </ConfiguratorNodeWidget>
};