import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BASE_PATH } from '../..';
import { ReactComponent as LoadingIcon } from '../../assets/icons/loading.svg';
import { ModelPlacement, StillImageConfiguration } from '../../models/StillImageConfiguration';
import { useConfigurationServicePostConfigurationItems, useSceneServiceGetSceneRenderings, useTemplateServiceGetTemplate1 } from '../../openapi/queries';
import { MaterialViewDTO, ModelPackViewDTO, ModelService, ModelViewDTO, ModifierViewDTO, ScenePlacementpointDTO } from '../../openapi/requests';
import IssueReporting from '../layout/IssueReporting';
import Overlay from '../layout/Overlay';
import './TemplateDetails.scss';
import saveAs from 'file-saver';
import ReactGA from "react-ga4";

const LoadingComponent = motion(LoadingIcon)

interface Props {
    open: boolean;
    templateId: number | undefined;
    onClose: () => void;
}

interface Point {
    type: "model" | "material" | "modifier" | "propset";
    name: string;
    productName: string;
    ean: string;
    id: string;
    position: { [key: string]: { x: number, y: number } }
}

interface Metadata {
    label: string;
    value: string;
}

const scenemetadata = [
    {
        label: 'Brand',
        name: 'brand'
    },
    {
        label: 'Banner',
        name: 'banner'
    },
    {
        label: 'Range',
        name: 'range'
    },
    {
        label: 'Category',
        name: 'category'
    },
    {
        label: 'Location',
        name: 'location'
    },
    {
        label: 'Square meters',
        name: 'square meters'
    },
    {
        label: 'House type',
        name: 'house type'
    },
    {
        label: 'Facing',
        name: 'facing'
    },
    {
        label: 'Customer Profile',
        name: 'customer profile'
    },
    {
        label: 'Time of year',
        name: 'time of year'
    },
    {
        label: 'Time of day',
        name: 'time of day'
    },
    {
        label: 'Description',
        name: 'description'
    }
];


const TemplateDetails: React.FC<Props> = ({ open, templateId, onClose }) => {
    const { data: template } = useTemplateServiceGetTemplate1({ key: templateId!, expand: 'scene' }, undefined, { enabled: open && templateId !== undefined });
    const { data: configItems, mutate, isPending } = useConfigurationServicePostConfigurationItems();
    const { data: renderings } = useSceneServiceGetSceneRenderings({ key: template?.scene.id ?? 0 }, undefined, { enabled: open && template !== undefined });

    useEffect(() => {
        if(open){
            ReactGA.event('open_dialog', {dialog: 'templatedetails'});
        }
    }, [open]);

    useEffect(() => {
        if(template && template.scene.defaultConfigurationSpec && open){
            mutate({ clientId: template.clientId, requestBody: JSON.stringify(template.scene.defaultConfigurationSpec)})
        }
    }, [mutate, template, open]);

    const config = useMemo(() => {
        let config: StillImageConfiguration = {
            camera: '',
            ModelSelections: [],
            ModifierSelections: [],
            PropsetSelections: [],
            SurfaceSelections: []
        }

        if (template && template.scene.defaultConfigurationSpec && template.scene.defaultConfigurationSpec !== "") {
            config = JSON.parse(template.scene.defaultConfigurationSpec);
        }

        return config;
    }, [template])

    const [metadata, setMetadata] = useState<Metadata[]>([]);
    const [points, setPoints] = useState<Point[]>([]);
    const [working, setWorking] = useState(false);

    useEffect(() => {
        const result = [];

        if (template?.scene) {
            for (let i = 0; i < scenemetadata.length; i++) {
                const element = scenemetadata[i];

                const data = template.scene.metadata.find(e => e.name.toLowerCase() === element.name.toLowerCase());

                if (data) {
                    result.push({
                        label: element.label,
                        value: data.value
                    })
                }
            }
        }

        setMetadata(result);
    }, [template]);

    useEffect(() => {
        const fetchData = async () => {
            let items: Point[] = [];

            const findModel = (name?: string): ModelViewDTO | undefined => {
                if(name === undefined){
                    return undefined;
                }
    
                return configItems.models.find(e => e.name === name);
            }
    
            const findModelpack = (name?: string): ModelPackViewDTO | undefined => {
                if(name === undefined){
                    return undefined;
                }
    
                return configItems.modelpacks.find(e => e.name === name);
            }
    
            const findMaterial = (name?: string): MaterialViewDTO | undefined => {
                if(name === undefined){
                    return undefined;
                }
                
                return configItems.materials.find(e => e.name === name);
            }
    
            const findModifier = (name?: string): ModifierViewDTO | undefined => {
                if(name === undefined){
                    return undefined;
                }
    
                return configItems.modifiers.find(e => e.name === name);
            }

            let index = 1;
            let scene = template.scene;

            for (let i = 0; i < scene.surfaces.length; i++) {
                const surfaceTarget = scene.surfaces[i];

                let point: Point = {
                    ean: '',
                    id: index.toString(),
                    name: surfaceTarget.label,
                    productName: '',
                    type: 'material',
                    position: {}
                }

                scene.cameras.forEach(cam => {
                    let camPoint = cam.surfacePoints.find(e => e.surface === surfaceTarget.id);

                    if (camPoint) {
                        point.position[cam.cameraName] = { x: camPoint.posX, y: camPoint.posY };
                    }
                });

                let selectedItem = config.SurfaceSelections.find(e => e.Name === surfaceTarget.name);

                if (selectedItem && selectedItem.Value) {
                    let material = findMaterial(selectedItem?.Value);

                    if (material) {
                        point.productName = material.title;
                        point.ean = material.metadata.find(e => e.name.toLowerCase() === "ean")?.value ?? ""
                    }
                }

                items.push(point);
                index++;
            }

            for (let i = 0; i < scene.modifierTargets.length; i++) {

                const modifierTarget = scene.modifierTargets[i];

                let point: Point = {
                    ean: '',
                    id: index.toString(),
                    name: modifierTarget.label,
                    productName: '',
                    type: 'material',
                    position: {}
                }

                scene.cameras.forEach(cam => {
                    let camPoint = cam.modifierPoints.find(e => e.modifierTarget === modifierTarget.id);

                    if (camPoint) {
                        point.position[cam.cameraName] = { x: camPoint.posX, y: camPoint.posY };
                    }
                });

                let selectedItem = config.ModifierSelections.find(e => e.Name === modifierTarget.name);

                if (selectedItem && selectedItem.Value) {
                    let modifier = findModifier(selectedItem?.Value);

                    if (modifier) {
                        point.productName = modifier.title;
                        point.ean = modifier.metadata.find(e => e.name.toLowerCase() === "ean")?.value ?? ""
                    }
                }

                items.push(point);
                index++;
            }

            const flatten = async (modelTarget: ScenePlacementpointDTO, index: number[], values: ModelPlacement[]) => {

                let point: Point = {
                    ean: '',
                    id: index.join("."),
                    name: modelTarget.label,
                    productName: '',
                    type: 'material',
                    position: {}
                }

                scene.cameras.forEach(cam => {
                    let camPoint = cam.placementPoints.find(e => e.placementPoint === modelTarget.id);

                    if (camPoint) {
                        point.position[cam.cameraName] = { x: camPoint.posX, y: camPoint.posY };
                    }
                });
                items.push(point);

                let selectedItem = values.find(e => e.Name === modelTarget.name);

                if (selectedItem && selectedItem.Value) {
                    let model: ModelViewDTO | undefined = undefined;

                    if (modelTarget.useModelpack) {
                        let modelPack = findModelpack(selectedItem?.Value);

                        if (modelPack) {
                            point.productName = modelPack.title;
                            point.ean = modelPack.metadata.find(e => e.name.toLowerCase() === "ean")?.value ?? "";

                            if (modelPack.models.length > 0) {
                                model = await ModelService.getModel1(modelPack!.models[0].modelId);
                            }
                        }
                    } else {
                        model = findModel(selectedItem?.Value);

                        if (model) {
                            point.productName = model.title;
                            point.ean = model.metadata.find(e => e.name.toLowerCase() === "ean")?.value ?? "";
                        }
                    }


                    if (model) {
                        let subIndex = 1;

                        model.surfaces.forEach(surfaceTarget => {
                            let point: Point = {
                                ean: '',
                                id: [...index, subIndex].join("."),
                                name: surfaceTarget.label,
                                productName: '',
                                type: 'material',
                                position: {}
                            }

                            let selectedItem = config.SurfaceSelections.find(e => e.Name === surfaceTarget.name);

                            if (selectedItem && selectedItem.Value) {
                                let material = findMaterial(selectedItem?.Value);

                                if (material) {
                                    point.productName = material.title;
                                    point.ean = material.metadata.find(e => e.name.toLowerCase() === "ean")?.value ?? ""
                                }
                            }

                            items.push(point);
                            subIndex++;
                        });

                        model.placementpoints.forEach(modelTarget => {
                            flatten(modelTarget, [...index, subIndex], selectedItem?.ModelSelections || []);
                            subIndex++;
                        });
                    }
                }
            }

            for (let i = 0; i < scene.placementpoints.length; i++) {
                const modelTarget = scene.placementpoints[i];
                await flatten(modelTarget, [index], config.ModelSelections);
                index++;
            }

            setWorking(false);
            setPoints(items);
        }

        setPoints([]);
        setWorking(true);

        if (template?.scene !== undefined && configItems !== undefined && open) {
            fetchData();
        }
    }, [template, open, configItems, config]);
    

    const onDownloadDF = async () => {
        const token = localStorage.getItem('accessToken');

        fetch(`${BASE_PATH}/template/${template.id}/pdf`, {
            method: 'get',
            headers: {
                "Authorization": 'Bearer ' + token
            }
        }).then(response => {
            return response.blob();
        }).then(blob => {
            saveAs(blob);
        });
    }

    return (
        <Overlay open={open} onClose={onClose} className='template-details'>
            <div className="template-details-overlay-content">
            <div className='meta'>
                {metadata.map(e => <React.Fragment key={e.label}><span>{e.label}: {e.value}</span><br /></React.Fragment >)}
                <button className='button rounded' onClick={onDownloadDF}>Download as pdf</button>
                <IssueReporting type='template' templateId={templateId} className='button rounded' />
            </div>
            <div className='products'>
                <AnimatePresence>
                    {(working || isPending) && <LoadingComponent
                        className={'loading'}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        key={'loading'} />}
                </AnimatePresence>
                {points.map(point => <div key={point.id} className='product'>
                    <div className='name'>
                        <h3>{point.productName || point.name}</h3>
                        <span>{point.ean}</span>
                    </div>
                    <div className='point'>{point.id}</div>
                </div>)}
            </div>
            <div className='images'>
                {renderings?.map((rendering, i) => <div key={'rendering-' +i} className='rendering'>
                    <LazyLoadImage
                        wrapperProps={{ style: { display: 'flex' } }}
                        src={BASE_PATH + rendering} alt={''} effect='opacity' threshold={0} />
                </div>)}
                {template?.scene?.cameras.map(cam => <div key={cam.cameraName} className='image'>
                    <LazyLoadImage style={{
                        aspectRatio: cam.aspectRatio,
                        width: ((cam.aspectRatio ?? 1) >= 1) ? '100%' : '',
                        height: ((cam.aspectRatio ?? 1) < 1) ? '100%' : ''
                    }}
                        wrapperProps={{ style: { display: 'flex' } }}
                        src={BASE_PATH + cam.thumbnail + '/1024'} alt={cam.label} effect='opacity' threshold={0} />


                    <div className='points'>
                        {points.map(p => {
                            let pos = p.position[cam.cameraName];

                            if (pos === undefined) {
                                return null;
                            }

                            return <div key={p.id} className='point' style={{ left: `${pos.x * 100}%`, top: `${pos.y * 100}%` }}>{p.id}</div>
                        })}
                    </div>
                </div>)}
            </div>
            </div>

        </Overlay>
    );
};

export default TemplateDetails;