import { CloseCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Form, Result, Typography } from 'antd';
import Upload from 'antd/es/upload';
import React, { useState } from 'react';
import { useExportServicePostExportKingfisherAssets } from '../../../openapi/queries';
import { ApiError, ExcelValidationMessage, ModelViewDTO } from '../../../openapi/requests';
const { Paragraph, Text } = Typography;

const ImportKingfisherAssets: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
    const [file, setFile] = useState<File | undefined>(undefined);
    const { mutateAsync: uploadAsync, isPending: uploading } = useExportServicePostExportKingfisherAssets();
    const [uploadErrors, setUploadErrors] = useState<ExcelValidationMessage[] | undefined>(undefined);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const [form] = Form.useForm<ModelViewDTO>();

    const onFinish = () => {
        if (file) {
            setUploadErrors(undefined);
            setUploadSuccess(false);

            uploadAsync({ formData: { file } }).catch((reason: ApiError) => {
                if (Array.isArray(reason.body)) {
                    setUploadErrors(reason.body as ExcelValidationMessage[]);
                } else {
                    setUploadErrors([{ line: 0, message: JSON.stringify(reason.body) }]);
                }
            }).then(() => {
                setUploadSuccess(true);
            });
        }
    };

    const beforeUpload = (file: File) => {
        setFile(file);
        return false;
    }

    return (
        <>
            <Drawer
                title={"Import data"}
                width={720}
                onClose={onClose}
                open={isOpen}
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item name="dragger">
                        <Upload.Dragger name="files" beforeUpload={beforeUpload} multiple={false} showUploadList={false}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            {file?.name}
                        </Upload.Dragger>
                    </Form.Item>

                    <Form.Item>
                        <Flex justify='center'>
                            <Button type="primary" htmlType="submit" loading={uploading}>
                                Submit
                            </Button>
                        </Flex>
                    </Form.Item>

                    {uploadErrors && <Result
                        status="error"
                        title="Import Failed"
                        subTitle="Please check and modify the following information before resubmitting."
                    >
                        <div className="desc">
                            <Paragraph>
                                <Text
                                    strong
                                    style={{
                                        fontSize: 16,
                                    }}
                                >
                                    The file you submitted has the following errors:
                                </Text>
                            </Paragraph>
                            {uploadErrors?.map(e => <Paragraph>
                                <CloseCircleOutlined className="site-result-demo-error-icon" /> line: {e.line} - {e.message}
                            </Paragraph>)}
                        </div>
                    </Result>}

                    {uploadSuccess && <Result
                        status="success"
                        title="Successfully updated assets"
                    />}

                </Form>
            </Drawer>
        </>

    );
};

export default ImportKingfisherAssets;