// generated with @7nohe/openapi-react-query-codegen@0.5.1 
import { useQuery, useMutation, UseQueryResult, UseQueryOptions, UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { WelcomeModel } from "../requests/models/WelcomeModel";
import { VideoSettingDTO } from "../requests/models/VideoSettingDTO";
import { VideoSetting } from "../requests/models/VideoSetting";
import { UserViewDTOODataListResponse } from "../requests/models/UserViewDTOODataListResponse";
import { UserViewDTO } from "../requests/models/UserViewDTO";
import { UserRenderLimitDTO } from "../requests/models/UserRenderLimitDTO";
import { UserRenderLimit } from "../requests/models/UserRenderLimit";
import { UserDTO } from "../requests/models/UserDTO";
import { UserAssetFilterPreset } from "../requests/models/UserAssetFilterPreset";
import { UserAssetFilterDTO } from "../requests/models/UserAssetFilterDTO";
import { UserAssetFilter } from "../requests/models/UserAssetFilter";
import { User } from "../requests/models/User";
import { UisceneSurfaceHotspot } from "../requests/models/UisceneSurfaceHotspot";
import { UiscenePropsetHotspot } from "../requests/models/UiscenePropsetHotspot";
import { UiscenePlacementpointHotspot } from "../requests/models/UiscenePlacementpointHotspot";
import { UisceneModifierTargetHotspot } from "../requests/models/UisceneModifierTargetHotspot";
import { Token } from "../requests/models/Token";
import { TemplateViewDTOODataListResponse } from "../requests/models/TemplateViewDTOODataListResponse";
import { TemplateViewDTO } from "../requests/models/TemplateViewDTO";
import { TemplateTypeViewDTO } from "../requests/models/TemplateTypeViewDTO";
import { TemplateType } from "../requests/models/TemplateType";
import { TemplateEditDTO } from "../requests/models/TemplateEditDTO";
import { Template } from "../requests/models/Template";
import { Tag } from "../requests/models/Tag";
import { StillImageSubmissionPlanDTOODataListResponse } from "../requests/models/StillImageSubmissionPlanDTOODataListResponse";
import { StillImageSubmissionPlanDTO } from "../requests/models/StillImageSubmissionPlanDTO";
import { StillImageSubmissionPlan } from "../requests/models/StillImageSubmissionPlan";
import { StillImageSettingDTO } from "../requests/models/StillImageSettingDTO";
import { StillImageSetting } from "../requests/models/StillImageSetting";
import { Spin360SettingDTO } from "../requests/models/Spin360SettingDTO";
import { Spin360Setting } from "../requests/models/Spin360Setting";
import { SceneViewDTOODataListResponse } from "../requests/models/SceneViewDTOODataListResponse";
import { SceneViewDTO } from "../requests/models/SceneViewDTO";
import { SceneSurfaceDTO } from "../requests/models/SceneSurfaceDTO";
import { SceneSurface } from "../requests/models/SceneSurface";
import { ScenePropsetOptionDTO } from "../requests/models/ScenePropsetOptionDTO";
import { ScenePropsetOption } from "../requests/models/ScenePropsetOption";
import { ScenePropsetDTO } from "../requests/models/ScenePropsetDTO";
import { ScenePropset } from "../requests/models/ScenePropset";
import { ScenePlacementpointDTO } from "../requests/models/ScenePlacementpointDTO";
import { ScenePlacementpoint } from "../requests/models/ScenePlacementpoint";
import { SceneModifierTargetDTO } from "../requests/models/SceneModifierTargetDTO";
import { SceneModifierTarget } from "../requests/models/SceneModifierTarget";
import { SceneEditDTO } from "../requests/models/SceneEditDTO";
import { SceneCameraSurfacePointDTO } from "../requests/models/SceneCameraSurfacePointDTO";
import { SceneCameraPropsetPointDTO } from "../requests/models/SceneCameraPropsetPointDTO";
import { SceneCameraPlacementPointDTO } from "../requests/models/SceneCameraPlacementPointDTO";
import { SceneCameraModifierPointDTO } from "../requests/models/SceneCameraModifierPointDTO";
import { SceneCameraDTO } from "../requests/models/SceneCameraDTO";
import { SceneCameraAspectRatio } from "../requests/models/SceneCameraAspectRatio";
import { SceneCamera } from "../requests/models/SceneCamera";
import { SceneAnimationShotDTO } from "../requests/models/SceneAnimationShotDTO";
import { SceneAnimationShot } from "../requests/models/SceneAnimationShot";
import { SceneAnimationDTO } from "../requests/models/SceneAnimationDTO";
import { SceneAnimation } from "../requests/models/SceneAnimation";
import { Scene } from "../requests/models/Scene";
import { RoleDTO } from "../requests/models/RoleDTO";
import { ResetPasswordModel } from "../requests/models/ResetPasswordModel";
import { RenderType } from "../requests/models/RenderType";
import { RenderPreset } from "../requests/models/RenderPreset";
import { PullCoDImageResponse } from "../requests/models/PullCoDImageResponse";
import { PullCoDImageAsAssetResponse } from "../requests/models/PullCoDImageAsAssetResponse";
import { ProjectViewDTOODataListResponse } from "../requests/models/ProjectViewDTOODataListResponse";
import { ProjectViewDTO } from "../requests/models/ProjectViewDTO";
import { ProjectType } from "../requests/models/ProjectType";
import { ProjectShareDTO } from "../requests/models/ProjectShareDTO";
import { ProjectShare } from "../requests/models/ProjectShare";
import { ProjectSearchDTOODataListResponse } from "../requests/models/ProjectSearchDTOODataListResponse";
import { ProjectSearchDTO } from "../requests/models/ProjectSearchDTO";
import { ProjectFolderViewDTOODataListResponse } from "../requests/models/ProjectFolderViewDTOODataListResponse";
import { ProjectFolderViewDTO } from "../requests/models/ProjectFolderViewDTO";
import { ProjectFolderEditDTO } from "../requests/models/ProjectFolderEditDTO";
import { ProjectFolder } from "../requests/models/ProjectFolder";
import { ProjectDTO } from "../requests/models/ProjectDTO";
import { Project } from "../requests/models/Project";
import { ProblemDetails } from "../requests/models/ProblemDetails";
import { PresetConfiguration } from "../requests/models/PresetConfiguration";
import { PostProcessingFeatureDTO } from "../requests/models/PostProcessingFeatureDTO";
import { PostProcessingFeature } from "../requests/models/PostProcessingFeature";
import { Permission } from "../requests/models/Permission";
import { OutputFileSet } from "../requests/models/OutputFileSet";
import { OutputFile } from "../requests/models/OutputFile";
import { OrderPostProcessingFeatureDTO } from "../requests/models/OrderPostProcessingFeatureDTO";
import { OrderlineViewDTO } from "../requests/models/OrderlineViewDTO";
import { OrderlineSearchDTOODataListResponse } from "../requests/models/OrderlineSearchDTOODataListResponse";
import { OrderlineSearchDTO } from "../requests/models/OrderlineSearchDTO";
import { OrderlinePostProcess } from "../requests/models/OrderlinePostProcess";
import { OrderlineFile } from "../requests/models/OrderlineFile";
import { OrderlineEditDTO } from "../requests/models/OrderlineEditDTO";
import { Orderline } from "../requests/models/Orderline";
import { OrderDTOODataListResponse } from "../requests/models/OrderDTOODataListResponse";
import { OrderDTO } from "../requests/models/OrderDTO";
import { Order } from "../requests/models/Order";
import { OkResult } from "../requests/models/OkResult";
import { ODataTypeAnnotation } from "../requests/models/ODataTypeAnnotation";
import { ODataSingletonInfo } from "../requests/models/ODataSingletonInfo";
import { ODataServiceDocument } from "../requests/models/ODataServiceDocument";
import { ODataFunctionImportInfo } from "../requests/models/ODataFunctionImportInfo";
import { ODataEntitySetInfo } from "../requests/models/ODataEntitySetInfo";
import { ModifierViewDTOODataListResponse } from "../requests/models/ModifierViewDTOODataListResponse";
import { ModifierViewDTO } from "../requests/models/ModifierViewDTO";
import { ModifierValueDTO } from "../requests/models/ModifierValueDTO";
import { ModifierValue } from "../requests/models/ModifierValue";
import { ModifierEditDTO } from "../requests/models/ModifierEditDTO";
import { Modifier } from "../requests/models/Modifier";
import { ModelViewDTOODataListResponse } from "../requests/models/ModelViewDTOODataListResponse";
import { ModelViewDTO } from "../requests/models/ModelViewDTO";
import { ModelSurfaceDTO } from "../requests/models/ModelSurfaceDTO";
import { ModelSurface } from "../requests/models/ModelSurface";
import { ModelPlacementPointDTO } from "../requests/models/ModelPlacementPointDTO";
import { ModelPlacementpoint } from "../requests/models/ModelPlacementpoint";
import { ModelPackViewDTOODataListResponse } from "../requests/models/ModelPackViewDTOODataListResponse";
import { ModelPackViewDTO } from "../requests/models/ModelPackViewDTO";
import { ModelPackItemDTO } from "../requests/models/ModelPackItemDTO";
import { ModelPackItem } from "../requests/models/ModelPackItem";
import { ModelPackEditDTO } from "../requests/models/ModelPackEditDTO";
import { ModelPack } from "../requests/models/ModelPack";
import { ModelModelpackDTO } from "../requests/models/ModelModelpackDTO";
import { ModelEditDTO } from "../requests/models/ModelEditDTO";
import { Model } from "../requests/models/Model";
import { MetadataDTO } from "../requests/models/MetadataDTO";
import { Metadata } from "../requests/models/Metadata";
import { MaterialViewDTOODataListResponse } from "../requests/models/MaterialViewDTOODataListResponse";
import { MaterialViewDTO } from "../requests/models/MaterialViewDTO";
import { MaterialEditDTO } from "../requests/models/MaterialEditDTO";
import { Material } from "../requests/models/Material";
import { LoginModel } from "../requests/models/LoginModel";
import { Log } from "../requests/models/Log";
import { KingfisherDamupload } from "../requests/models/KingfisherDamupload";
import { InternalVideoSettingDTOODataListResponse } from "../requests/models/InternalVideoSettingDTOODataListResponse";
import { InternalVideoSettingDTO } from "../requests/models/InternalVideoSettingDTO";
import { InternalUserRenderLimitDTOODataListResponse } from "../requests/models/InternalUserRenderLimitDTOODataListResponse";
import { InternalUserRenderLimitDTO } from "../requests/models/InternalUserRenderLimitDTO";
import { InternalUserAssetFilterPresetDTOODataListResponse } from "../requests/models/InternalUserAssetFilterPresetDTOODataListResponse";
import { InternalUserAssetFilterPresetDTO } from "../requests/models/InternalUserAssetFilterPresetDTO";
import { InternalUserAssetFilterDTOODataListResponse } from "../requests/models/InternalUserAssetFilterDTOODataListResponse";
import { InternalUserAssetFilterDTO } from "../requests/models/InternalUserAssetFilterDTO";
import { InternalUisceneSurfaceHotspotDTOODataListResponse } from "../requests/models/InternalUisceneSurfaceHotspotDTOODataListResponse";
import { InternalUisceneSurfaceHotspotDTO } from "../requests/models/InternalUisceneSurfaceHotspotDTO";
import { InternalUiscenePropsetHotspotDTOODataListResponse } from "../requests/models/InternalUiscenePropsetHotspotDTOODataListResponse";
import { InternalUiscenePropsetHotspotDTO } from "../requests/models/InternalUiscenePropsetHotspotDTO";
import { InternalUiscenePlacementpointHotspotDTOODataListResponse } from "../requests/models/InternalUiscenePlacementpointHotspotDTOODataListResponse";
import { InternalUiscenePlacementpointHotspotDTO } from "../requests/models/InternalUiscenePlacementpointHotspotDTO";
import { InternalUisceneModifierTargetHotspotDTOODataListResponse } from "../requests/models/InternalUisceneModifierTargetHotspotDTOODataListResponse";
import { InternalUisceneModifierTargetHotspotDTO } from "../requests/models/InternalUisceneModifierTargetHotspotDTO";
import { InternalTemplateTypeDTOODataListResponse } from "../requests/models/InternalTemplateTypeDTOODataListResponse";
import { InternalTemplateTypeDTO } from "../requests/models/InternalTemplateTypeDTO";
import { InternalTemplateDTOODataListResponse } from "../requests/models/InternalTemplateDTOODataListResponse";
import { InternalTemplateDTO } from "../requests/models/InternalTemplateDTO";
import { InternalTagDTOODataListResponse } from "../requests/models/InternalTagDTOODataListResponse";
import { InternalTagDTO } from "../requests/models/InternalTagDTO";
import { InternalStillImageSubmissionPlanDTOODataListResponse } from "../requests/models/InternalStillImageSubmissionPlanDTOODataListResponse";
import { InternalStillImageSubmissionPlanDTO } from "../requests/models/InternalStillImageSubmissionPlanDTO";
import { InternalStillImageSettingDTOODataListResponse } from "../requests/models/InternalStillImageSettingDTOODataListResponse";
import { InternalStillImageSettingDTO } from "../requests/models/InternalStillImageSettingDTO";
import { InternalSpin360SettingDTOODataListResponse } from "../requests/models/InternalSpin360SettingDTOODataListResponse";
import { InternalSpin360SettingDTO } from "../requests/models/InternalSpin360SettingDTO";
import { InternalSceneSurfaceDTOODataListResponse } from "../requests/models/InternalSceneSurfaceDTOODataListResponse";
import { InternalSceneSurfaceDTO } from "../requests/models/InternalSceneSurfaceDTO";
import { InternalScenePropsetOptionDTOODataListResponse } from "../requests/models/InternalScenePropsetOptionDTOODataListResponse";
import { InternalScenePropsetOptionDTO } from "../requests/models/InternalScenePropsetOptionDTO";
import { InternalScenePropsetDTOODataListResponse } from "../requests/models/InternalScenePropsetDTOODataListResponse";
import { InternalScenePropsetDTO } from "../requests/models/InternalScenePropsetDTO";
import { InternalScenePlacementpointDTOODataListResponse } from "../requests/models/InternalScenePlacementpointDTOODataListResponse";
import { InternalScenePlacementpointDTO } from "../requests/models/InternalScenePlacementpointDTO";
import { InternalSceneModifierTargetDTOODataListResponse } from "../requests/models/InternalSceneModifierTargetDTOODataListResponse";
import { InternalSceneModifierTargetDTO } from "../requests/models/InternalSceneModifierTargetDTO";
import { InternalSceneDTOODataListResponse } from "../requests/models/InternalSceneDTOODataListResponse";
import { InternalSceneDTO } from "../requests/models/InternalSceneDTO";
import { InternalSceneCameraDTOODataListResponse } from "../requests/models/InternalSceneCameraDTOODataListResponse";
import { InternalSceneCameraDTO } from "../requests/models/InternalSceneCameraDTO";
import { InternalSceneCameraAspectRatioDTOODataListResponse } from "../requests/models/InternalSceneCameraAspectRatioDTOODataListResponse";
import { InternalSceneCameraAspectRatioDTO } from "../requests/models/InternalSceneCameraAspectRatioDTO";
import { InternalRenderPresetDTOODataListResponse } from "../requests/models/InternalRenderPresetDTOODataListResponse";
import { InternalRenderPresetDTO } from "../requests/models/InternalRenderPresetDTO";
import { InternalProjectShareDTOODataListResponse } from "../requests/models/InternalProjectShareDTOODataListResponse";
import { InternalProjectShareDTO } from "../requests/models/InternalProjectShareDTO";
import { InternalProjectFolderDTOODataListResponse } from "../requests/models/InternalProjectFolderDTOODataListResponse";
import { InternalProjectFolderDTO } from "../requests/models/InternalProjectFolderDTO";
import { InternalProjectDTOODataListResponse } from "../requests/models/InternalProjectDTOODataListResponse";
import { InternalProjectDTO } from "../requests/models/InternalProjectDTO";
import { InternalPresetConfigurationDTOODataListResponse } from "../requests/models/InternalPresetConfigurationDTOODataListResponse";
import { InternalPresetConfigurationDTO } from "../requests/models/InternalPresetConfigurationDTO";
import { InternalPostProcessingFeatureDTOODataListResponse } from "../requests/models/InternalPostProcessingFeatureDTOODataListResponse";
import { InternalPostProcessingFeatureDTO } from "../requests/models/InternalPostProcessingFeatureDTO";
import { InternalPermissionDTOODataListResponse } from "../requests/models/InternalPermissionDTOODataListResponse";
import { InternalPermissionDTO } from "../requests/models/InternalPermissionDTO";
import { InternalOutputFileSetDTOODataListResponse } from "../requests/models/InternalOutputFileSetDTOODataListResponse";
import { InternalOutputFileSetDTO } from "../requests/models/InternalOutputFileSetDTO";
import { InternalOutputFileDTOODataListResponse } from "../requests/models/InternalOutputFileDTOODataListResponse";
import { InternalOutputFileDTO } from "../requests/models/InternalOutputFileDTO";
import { InternalOrderlinePostProcessDTOODataListResponse } from "../requests/models/InternalOrderlinePostProcessDTOODataListResponse";
import { InternalOrderlinePostProcessDTO } from "../requests/models/InternalOrderlinePostProcessDTO";
import { InternalOrderlineDTOODataListResponse } from "../requests/models/InternalOrderlineDTOODataListResponse";
import { InternalOrderlineDTO } from "../requests/models/InternalOrderlineDTO";
import { InternalOrderDTOODataListResponse } from "../requests/models/InternalOrderDTOODataListResponse";
import { InternalOrderDTO } from "../requests/models/InternalOrderDTO";
import { InternalModifierValueDTOODataListResponse } from "../requests/models/InternalModifierValueDTOODataListResponse";
import { InternalModifierValueDTO } from "../requests/models/InternalModifierValueDTO";
import { InternalModifierDTOODataListResponse } from "../requests/models/InternalModifierDTOODataListResponse";
import { InternalModifierDTO } from "../requests/models/InternalModifierDTO";
import { InternalModelSurfaceDTOODataListResponse } from "../requests/models/InternalModelSurfaceDTOODataListResponse";
import { InternalModelSurfaceDTO } from "../requests/models/InternalModelSurfaceDTO";
import { InternalModelPlacementpointDTOODataListResponse } from "../requests/models/InternalModelPlacementpointDTOODataListResponse";
import { InternalModelPlacementpointDTO } from "../requests/models/InternalModelPlacementpointDTO";
import { InternalModelPackItemDTOODataListResponse } from "../requests/models/InternalModelPackItemDTOODataListResponse";
import { InternalModelPackItemDTO } from "../requests/models/InternalModelPackItemDTO";
import { InternalModelPackDTOODataListResponse } from "../requests/models/InternalModelPackDTOODataListResponse";
import { InternalModelPackDTO } from "../requests/models/InternalModelPackDTO";
import { InternalModelDTOODataListResponse } from "../requests/models/InternalModelDTOODataListResponse";
import { InternalModelDTO } from "../requests/models/InternalModelDTO";
import { InternalMetadataDTOODataListResponse } from "../requests/models/InternalMetadataDTOODataListResponse";
import { InternalMetadataDTO } from "../requests/models/InternalMetadataDTO";
import { InternalMaterialDTOODataListResponse } from "../requests/models/InternalMaterialDTOODataListResponse";
import { InternalMaterialDTO } from "../requests/models/InternalMaterialDTO";
import { InternalLogDTOODataListResponse } from "../requests/models/InternalLogDTOODataListResponse";
import { InternalLogDTO } from "../requests/models/InternalLogDTO";
import { InternalKingfisherDamuploadDTOODataListResponse } from "../requests/models/InternalKingfisherDamuploadDTOODataListResponse";
import { InternalKingfisherDamuploadDTO } from "../requests/models/InternalKingfisherDamuploadDTO";
import { InternalHostedStoragePlanDTOODataListResponse } from "../requests/models/InternalHostedStoragePlanDTOODataListResponse";
import { InternalHostedStoragePlanDTO } from "../requests/models/InternalHostedStoragePlanDTO";
import { InternalHostedFileDTOODataListResponse } from "../requests/models/InternalHostedFileDTOODataListResponse";
import { InternalHostedFileDTO } from "../requests/models/InternalHostedFileDTO";
import { InternalFilenameTokenDTOODataListResponse } from "../requests/models/InternalFilenameTokenDTOODataListResponse";
import { InternalFilenameTokenDTO } from "../requests/models/InternalFilenameTokenDTO";
import { InternalEmailTemplateDTOODataListResponse } from "../requests/models/InternalEmailTemplateDTOODataListResponse";
import { InternalEmailTemplateDTO } from "../requests/models/InternalEmailTemplateDTO";
import { InternalDeliveryMethodSftpDTOODataListResponse } from "../requests/models/InternalDeliveryMethodSftpDTOODataListResponse";
import { InternalDeliveryMethodSftpDTO } from "../requests/models/InternalDeliveryMethodSftpDTO";
import { InternalDeliveryMethodS3DTOODataListResponse } from "../requests/models/InternalDeliveryMethodS3DTOODataListResponse";
import { InternalDeliveryMethodS3DTO } from "../requests/models/InternalDeliveryMethodS3DTO";
import { InternalDeliveryMethodHostedStorageDTOODataListResponse } from "../requests/models/InternalDeliveryMethodHostedStorageDTOODataListResponse";
import { InternalDeliveryMethodHostedStorageDTO } from "../requests/models/InternalDeliveryMethodHostedStorageDTO";
import { InternalDeliveryMethodFtpDTOODataListResponse } from "../requests/models/InternalDeliveryMethodFtpDTOODataListResponse";
import { InternalDeliveryMethodFtpDTO } from "../requests/models/InternalDeliveryMethodFtpDTO";
import { InternalDeliveryMethodCustomApiDTOODataListResponse } from "../requests/models/InternalDeliveryMethodCustomApiDTOODataListResponse";
import { InternalDeliveryMethodCustomApiDTO } from "../requests/models/InternalDeliveryMethodCustomApiDTO";
import { InternalDeliveryConfigurationDTOODataListResponse } from "../requests/models/InternalDeliveryConfigurationDTOODataListResponse";
import { InternalDeliveryConfigurationDTO } from "../requests/models/InternalDeliveryConfigurationDTO";
import { InternalDefaultMetadataFieldDTOODataListResponse } from "../requests/models/InternalDefaultMetadataFieldDTOODataListResponse";
import { InternalDefaultMetadataFieldDTO } from "../requests/models/InternalDefaultMetadataFieldDTO";
import { InternalClientDTOODataListResponse } from "../requests/models/InternalClientDTOODataListResponse";
import { InternalClientDTO } from "../requests/models/InternalClientDTO";
import { InternalAssetFilterPresetDTOODataListResponse } from "../requests/models/InternalAssetFilterPresetDTOODataListResponse";
import { InternalAssetFilterPresetDTO } from "../requests/models/InternalAssetFilterPresetDTO";
import { InternalAssetDTOODataListResponse } from "../requests/models/InternalAssetDTOODataListResponse";
import { InternalAssetDTO } from "../requests/models/InternalAssetDTO";
import { InternalAssetApprovalHistoryDTOODataListResponse } from "../requests/models/InternalAssetApprovalHistoryDTOODataListResponse";
import { InternalAssetApprovalHistoryDTO } from "../requests/models/InternalAssetApprovalHistoryDTO";
import { InternalArmodelSettingDTOODataListResponse } from "../requests/models/InternalArmodelSettingDTOODataListResponse";
import { InternalArmodelSettingDTO } from "../requests/models/InternalArmodelSettingDTO";
import { IEdmVocabularyAnnotation } from "../requests/models/IEdmVocabularyAnnotation";
import { IEdmVocabularyAnnotatable } from "../requests/models/IEdmVocabularyAnnotatable";
import { IEdmTypeReference } from "../requests/models/IEdmTypeReference";
import { IEdmType } from "../requests/models/IEdmType";
import { IEdmTerm } from "../requests/models/IEdmTerm";
import { IEdmSchemaElement } from "../requests/models/IEdmSchemaElement";
import { IEdmModel } from "../requests/models/IEdmModel";
import { IEdmExpression } from "../requests/models/IEdmExpression";
import { IEdmEntityContainerElement } from "../requests/models/IEdmEntityContainerElement";
import { IEdmEntityContainer } from "../requests/models/IEdmEntityContainer";
import { IEdmDirectValueAnnotationsManager } from "../requests/models/IEdmDirectValueAnnotationsManager";
import { HostedStoragePlan } from "../requests/models/HostedStoragePlan";
import { HostedFile } from "../requests/models/HostedFile";
import { ForgotPasswordModel } from "../requests/models/ForgotPasswordModel";
import { FilenameTokenDTOODataListResponse } from "../requests/models/FilenameTokenDTOODataListResponse";
import { FilenameTokenDTO } from "../requests/models/FilenameTokenDTO";
import { FilenameToken } from "../requests/models/FilenameToken";
import { ExcelValidationMessage } from "../requests/models/ExcelValidationMessage";
import { EmailTemplate } from "../requests/models/EmailTemplate";
import { EdmTypeKind } from "../requests/models/EdmTypeKind";
import { EdmSchemaElementKind } from "../requests/models/EdmSchemaElementKind";
import { EdmExpressionKind } from "../requests/models/EdmExpressionKind";
import { EdmContainerElementKind } from "../requests/models/EdmContainerElementKind";
import { DeliveryMethodSftp } from "../requests/models/DeliveryMethodSftp";
import { DeliveryMethodS3 } from "../requests/models/DeliveryMethodS3";
import { DeliveryMethodHostedStorage } from "../requests/models/DeliveryMethodHostedStorage";
import { DeliveryMethodFtp } from "../requests/models/DeliveryMethodFtp";
import { DeliveryMethodCustomApi } from "../requests/models/DeliveryMethodCustomApi";
import { DeliveryConfigurationDTOODataListResponse } from "../requests/models/DeliveryConfigurationDTOODataListResponse";
import { DeliveryConfigurationDTO } from "../requests/models/DeliveryConfigurationDTO";
import { DeliveryConfiguration } from "../requests/models/DeliveryConfiguration";
import { DefaultMetadataField } from "../requests/models/DefaultMetadataField";
import { ConfigurationAssets } from "../requests/models/ConfigurationAssets";
import { CombinedResultDTOODataListResponse } from "../requests/models/CombinedResultDTOODataListResponse";
import { CombinedResultDTO } from "../requests/models/CombinedResultDTO";
import { ClientDTOODataListResponse } from "../requests/models/ClientDTOODataListResponse";
import { ClientDTO } from "../requests/models/ClientDTO";
import { Client } from "../requests/models/Client";
import { ClaimDTO } from "../requests/models/ClaimDTO";
import { ChartDTOODataListResponse } from "../requests/models/ChartDTOODataListResponse";
import { ChartDTO } from "../requests/models/ChartDTO";
import { ChartData } from "../requests/models/ChartData";
import { AssetSearchResultDTOODataListResponse } from "../requests/models/AssetSearchResultDTOODataListResponse";
import { AssetSearchResultDTO } from "../requests/models/AssetSearchResultDTO";
import { AssetProjectViewDTOODataListResponse } from "../requests/models/AssetProjectViewDTOODataListResponse";
import { AssetProjectViewDTO } from "../requests/models/AssetProjectViewDTO";
import { AssetProjectEditDTO } from "../requests/models/AssetProjectEditDTO";
import { AssetProjectAssetODataListResponse } from "../requests/models/AssetProjectAssetODataListResponse";
import { AssetProjectAsset } from "../requests/models/AssetProjectAsset";
import { AssetProject } from "../requests/models/AssetProject";
import { AssetFilterPresetDTOODataListResponse } from "../requests/models/AssetFilterPresetDTOODataListResponse";
import { AssetFilterPresetDTO } from "../requests/models/AssetFilterPresetDTO";
import { AssetFilterPreset } from "../requests/models/AssetFilterPreset";
import { AssetApprovalHistoryDTO } from "../requests/models/AssetApprovalHistoryDTO";
import { AssetApprovalHistory } from "../requests/models/AssetApprovalHistory";
import { Asset } from "../requests/models/Asset";
import { ArmodelSettingDTO } from "../requests/models/ArmodelSettingDTO";
import { ArmodelSetting } from "../requests/models/ArmodelSetting";
import { UserService } from "../requests/services/UserService";
import { TemplateService } from "../requests/services/TemplateService";
import { StillImageSubmissionPlanService } from "../requests/services/StillImageSubmissionPlanService";
import { StatisticsService } from "../requests/services/StatisticsService";
import { SearchProjectService } from "../requests/services/SearchProjectService";
import { SearchCutoutService } from "../requests/services/SearchCutoutService";
import { SearchAssetService } from "../requests/services/SearchAssetService";
import { SceneService } from "../requests/services/SceneService";
import { RoleService } from "../requests/services/RoleService";
import { ProjectShareService } from "../requests/services/ProjectShareService";
import { ProjectService } from "../requests/services/ProjectService";
import { ProjectFolderService } from "../requests/services/ProjectFolderService";
import { OrderService } from "../requests/services/OrderService";
import { OrderlineService } from "../requests/services/OrderlineService";
import { ModifierService } from "../requests/services/ModifierService";
import { ModelService } from "../requests/services/ModelService";
import { ModelPackService } from "../requests/services/ModelPackService";
import { MetadataService } from "../requests/services/MetadataService";
import { MaterialService } from "../requests/services/MaterialService";
import { InternalVideoSettingService } from "../requests/services/InternalVideoSettingService";
import { InternalUserRenderLimitService } from "../requests/services/InternalUserRenderLimitService";
import { InternalUserAssetFilterService } from "../requests/services/InternalUserAssetFilterService";
import { InternalUserAssetFilterPresetService } from "../requests/services/InternalUserAssetFilterPresetService";
import { InternalUisceneSurfaceHotspotService } from "../requests/services/InternalUisceneSurfaceHotspotService";
import { InternalUiscenePropsetHotspotService } from "../requests/services/InternalUiscenePropsetHotspotService";
import { InternalUiscenePlacementpointHotspotService } from "../requests/services/InternalUiscenePlacementpointHotspotService";
import { InternalUisceneModifierTargetHotspotService } from "../requests/services/InternalUisceneModifierTargetHotspotService";
import { InternalTemplateTypeService } from "../requests/services/InternalTemplateTypeService";
import { InternalTemplateService } from "../requests/services/InternalTemplateService";
import { InternalTagService } from "../requests/services/InternalTagService";
import { InternalStillImageSubmissionPlanService } from "../requests/services/InternalStillImageSubmissionPlanService";
import { InternalStillImageSettingService } from "../requests/services/InternalStillImageSettingService";
import { InternalSpin360SettingService } from "../requests/services/InternalSpin360SettingService";
import { InternalSceneSurfaceService } from "../requests/services/InternalSceneSurfaceService";
import { InternalSceneService } from "../requests/services/InternalSceneService";
import { InternalScenePropsetService } from "../requests/services/InternalScenePropsetService";
import { InternalScenePropsetOptionService } from "../requests/services/InternalScenePropsetOptionService";
import { InternalScenePlacementpointService } from "../requests/services/InternalScenePlacementpointService";
import { InternalSceneModifierTargetService } from "../requests/services/InternalSceneModifierTargetService";
import { InternalSceneCameraService } from "../requests/services/InternalSceneCameraService";
import { InternalSceneCameraAspectRatioService } from "../requests/services/InternalSceneCameraAspectRatioService";
import { InternalRenderPresetService } from "../requests/services/InternalRenderPresetService";
import { InternalProjectShareService } from "../requests/services/InternalProjectShareService";
import { InternalProjectService } from "../requests/services/InternalProjectService";
import { InternalProjectFolderService } from "../requests/services/InternalProjectFolderService";
import { InternalPresetConfigurationService } from "../requests/services/InternalPresetConfigurationService";
import { InternalPostProcessingFeatureService } from "../requests/services/InternalPostProcessingFeatureService";
import { InternalPermissionService } from "../requests/services/InternalPermissionService";
import { InternalOutputFileSetService } from "../requests/services/InternalOutputFileSetService";
import { InternalOutputFileService } from "../requests/services/InternalOutputFileService";
import { InternalOrderService } from "../requests/services/InternalOrderService";
import { InternalOrderlineService } from "../requests/services/InternalOrderlineService";
import { InternalOrderlinePostProcessService } from "../requests/services/InternalOrderlinePostProcessService";
import { InternalModifierValueService } from "../requests/services/InternalModifierValueService";
import { InternalModifierService } from "../requests/services/InternalModifierService";
import { InternalModelSurfaceService } from "../requests/services/InternalModelSurfaceService";
import { InternalModelService } from "../requests/services/InternalModelService";
import { InternalModelPlacementpointService } from "../requests/services/InternalModelPlacementpointService";
import { InternalModelPackService } from "../requests/services/InternalModelPackService";
import { InternalModelPackItemService } from "../requests/services/InternalModelPackItemService";
import { InternalMetadataService } from "../requests/services/InternalMetadataService";
import { InternalMaterialService } from "../requests/services/InternalMaterialService";
import { InternalLogService } from "../requests/services/InternalLogService";
import { InternalKingfisherDamuploadService } from "../requests/services/InternalKingfisherDamuploadService";
import { InternalHostedStoragePlanService } from "../requests/services/InternalHostedStoragePlanService";
import { InternalHostedFileService } from "../requests/services/InternalHostedFileService";
import { InternalFilenameTokenService } from "../requests/services/InternalFilenameTokenService";
import { InternalEmailTemplateService } from "../requests/services/InternalEmailTemplateService";
import { InternalDeliveryMethodSftpService } from "../requests/services/InternalDeliveryMethodSftpService";
import { InternalDeliveryMethodS3Service } from "../requests/services/InternalDeliveryMethodS3Service";
import { InternalDeliveryMethodHostedStorageService } from "../requests/services/InternalDeliveryMethodHostedStorageService";
import { InternalDeliveryMethodFtpService } from "../requests/services/InternalDeliveryMethodFtpService";
import { InternalDeliveryMethodCustomApiService } from "../requests/services/InternalDeliveryMethodCustomApiService";
import { InternalDeliveryConfigurationService } from "../requests/services/InternalDeliveryConfigurationService";
import { InternalDefaultMetadataFieldService } from "../requests/services/InternalDefaultMetadataFieldService";
import { InternalClientService } from "../requests/services/InternalClientService";
import { InternalAssetService } from "../requests/services/InternalAssetService";
import { InternalAssetFilterPresetService } from "../requests/services/InternalAssetFilterPresetService";
import { InternalAssetApprovalHistoryService } from "../requests/services/InternalAssetApprovalHistoryService";
import { InternalArmodelSettingService } from "../requests/services/InternalArmodelSettingService";
import { HostedFileService } from "../requests/services/HostedFileService";
import { HelpService } from "../requests/services/HelpService";
import { FilenameTokenService } from "../requests/services/FilenameTokenService";
import { ExportService } from "../requests/services/ExportService";
import { DeliveryConfigurationService } from "../requests/services/DeliveryConfigurationService";
import { CutoutService } from "../requests/services/CutoutService";
import { ConfigurationService } from "../requests/services/ConfigurationService";
import { ClientService } from "../requests/services/ClientService";
import { AuthenticateService } from "../requests/services/AuthenticateService";
import { AssetService } from "../requests/services/AssetService";
import { AssetProjectService } from "../requests/services/AssetProjectService";
import { AssetFilterPresetService } from "../requests/services/AssetFilterPresetService";
export const useUserServiceGetUserKey = "UserServiceGetUser";
export const useUserServiceGetUser = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof UserService.getUser>>, TError = unknown>({ query, top, skip, filter, select, orderby, expand, count }: {
    query?: string;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof UserService.getUser>>, unknown, Awaited<ReturnType<typeof UserService.getUser>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useUserServiceGetUserKey, ...(queryKey ?? [{ query, top, skip, filter, select, orderby, expand, count }])], queryFn: () => UserService.getUser(query, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof UserService.getUser>>, TError>, "data"> & {
    data: TData;
};
export const useUserServicePostUser = <TData = Awaited<ReturnType<typeof UserService.postUser>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.postUser>>, unknown, {
    requestBody?: UserDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => UserService.postUser(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.postUser>>, TError, {
    requestBody?: UserDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServiceGetUserMeKey = "UserServiceGetUserMe";
export const useUserServiceGetUserMe = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof UserService.getUserMe>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof UserService.getUserMe>>, unknown, Awaited<ReturnType<typeof UserService.getUserMe>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useUserServiceGetUserMeKey, ...(queryKey ?? [])], queryFn: () => UserService.getUserMe(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof UserService.getUserMe>>, TError>, "data"> & {
    data: TData;
};
export const useUserServiceGetUser1Key = "UserServiceGetUser1";
export const useUserServiceGetUser1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof UserService.getUser1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof UserService.getUser1>>, unknown, Awaited<ReturnType<typeof UserService.getUser1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useUserServiceGetUser1Key, ...(queryKey ?? [{ key }])], queryFn: () => UserService.getUser1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof UserService.getUser1>>, TError>, "data"> & {
    data: TData;
};
export const useUserServicePutUser = <TData = Awaited<ReturnType<typeof UserService.putUser>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.putUser>>, unknown, {
    key: number;
    requestBody?: UserDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => UserService.putUser(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.putUser>>, TError, {
    key: number;
    requestBody?: UserDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServiceDeleteUser = <TData = Awaited<ReturnType<typeof UserService.deleteUser>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.deleteUser>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => UserService.deleteUser(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.deleteUser>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServiceGetUserClientKey = "UserServiceGetUserClient";
export const useUserServiceGetUserClient = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof UserService.getUserClient>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof UserService.getUserClient>>, unknown, Awaited<ReturnType<typeof UserService.getUserClient>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useUserServiceGetUserClientKey, ...(queryKey ?? [])], queryFn: () => UserService.getUserClient(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof UserService.getUserClient>>, TError>, "data"> & {
    data: TData;
};
export const useUserServiceGetUserClient1Key = "UserServiceGetUserClient1";
export const useUserServiceGetUserClient1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof UserService.getUserClient1>>, TError = unknown>({ clientId }: {
    clientId: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof UserService.getUserClient1>>, unknown, Awaited<ReturnType<typeof UserService.getUserClient1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useUserServiceGetUserClient1Key, ...(queryKey ?? [{ clientId }])], queryFn: () => UserService.getUserClient1(clientId), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof UserService.getUserClient1>>, TError>, "data"> & {
    data: TData;
};
export const useUserServiceGetUserClientsKey = "UserServiceGetUserClients";
export const useUserServiceGetUserClients = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof UserService.getUserClients>>, TError = unknown>({ clientIds }: {
    clientIds: Array<number>;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof UserService.getUserClients>>, unknown, Awaited<ReturnType<typeof UserService.getUserClients>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useUserServiceGetUserClientsKey, ...(queryKey ?? [{ clientIds }])], queryFn: () => UserService.getUserClients(clientIds), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof UserService.getUserClients>>, TError>, "data"> & {
    data: TData;
};
export const useUserServiceGetUserAssetFilterKey = "UserServiceGetUserAssetFilter";
export const useUserServiceGetUserAssetFilter = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof UserService.getUserAssetFilter>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof UserService.getUserAssetFilter>>, unknown, Awaited<ReturnType<typeof UserService.getUserAssetFilter>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useUserServiceGetUserAssetFilterKey, ...(queryKey ?? [{ key }])], queryFn: () => UserService.getUserAssetFilter(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof UserService.getUserAssetFilter>>, TError>, "data"> & {
    data: TData;
};
export const useUserServicePutUserAssetfilter = <TData = Awaited<ReturnType<typeof UserService.putUserAssetfilter>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.putUserAssetfilter>>, unknown, {
    key: number;
    requestBody?: UserAssetFilterDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => UserService.putUserAssetfilter(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.putUserAssetfilter>>, TError, {
    key: number;
    requestBody?: UserAssetFilterDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServiceDeleteUserAssetfilter = <TData = Awaited<ReturnType<typeof UserService.deleteUserAssetfilter>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.deleteUserAssetfilter>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => UserService.deleteUserAssetfilter(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.deleteUserAssetfilter>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServicePostUserAssetfilter = <TData = Awaited<ReturnType<typeof UserService.postUserAssetfilter>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.postUserAssetfilter>>, unknown, {
    requestBody?: UserAssetFilterDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => UserService.postUserAssetfilter(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.postUserAssetfilter>>, TError, {
    requestBody?: UserAssetFilterDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServiceGetUserAssetfilterpresetKey = "UserServiceGetUserAssetfilterpreset";
export const useUserServiceGetUserAssetfilterpreset = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof UserService.getUserAssetfilterpreset>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof UserService.getUserAssetfilterpreset>>, unknown, Awaited<ReturnType<typeof UserService.getUserAssetfilterpreset>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useUserServiceGetUserAssetfilterpresetKey, ...(queryKey ?? [{ key }])], queryFn: () => UserService.getUserAssetfilterpreset(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof UserService.getUserAssetfilterpreset>>, TError>, "data"> & {
    data: TData;
};
export const useUserServicePostUserAssetfilterpreset = <TData = Awaited<ReturnType<typeof UserService.postUserAssetfilterpreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.postUserAssetfilterpreset>>, unknown, {
    key: number;
    requestBody?: AssetFilterPresetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => UserService.postUserAssetfilterpreset(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.postUserAssetfilterpreset>>, TError, {
    key: number;
    requestBody?: AssetFilterPresetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServiceDeleteUserAssetfilterpreset = <TData = Awaited<ReturnType<typeof UserService.deleteUserAssetfilterpreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.deleteUserAssetfilterpreset>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => UserService.deleteUserAssetfilterpreset(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.deleteUserAssetfilterpreset>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServicePostUserResetpassword = <TData = Awaited<ReturnType<typeof UserService.postUserResetpassword>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.postUserResetpassword>>, unknown, {
    requestBody?: ResetPasswordModel;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => UserService.postUserResetpassword(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.postUserResetpassword>>, TError, {
    requestBody?: ResetPasswordModel;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServicePostUserForgotpassword = <TData = Awaited<ReturnType<typeof UserService.postUserForgotpassword>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.postUserForgotpassword>>, unknown, {
    requestBody?: ForgotPasswordModel;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => UserService.postUserForgotpassword(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.postUserForgotpassword>>, TError, {
    requestBody?: ForgotPasswordModel;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServicePostUserWelcomemail = <TData = Awaited<ReturnType<typeof UserService.postUserWelcomemail>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.postUserWelcomemail>>, unknown, {
    requestBody?: WelcomeModel;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => UserService.postUserWelcomemail(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.postUserWelcomemail>>, TError, {
    requestBody?: WelcomeModel;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServicePostUserResettoken = <TData = Awaited<ReturnType<typeof UserService.postUserResettoken>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.postUserResettoken>>, unknown, {
    requestBody?: ResetPasswordModel;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => UserService.postUserResettoken(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.postUserResettoken>>, TError, {
    requestBody?: ResetPasswordModel;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServiceGetUserClaimKey = "UserServiceGetUserClaim";
export const useUserServiceGetUserClaim = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof UserService.getUserClaim>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof UserService.getUserClaim>>, unknown, Awaited<ReturnType<typeof UserService.getUserClaim>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useUserServiceGetUserClaimKey, ...(queryKey ?? [{ key }])], queryFn: () => UserService.getUserClaim(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof UserService.getUserClaim>>, TError>, "data"> & {
    data: TData;
};
export const useUserServicePostUserClaim = <TData = Awaited<ReturnType<typeof UserService.postUserClaim>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.postUserClaim>>, unknown, {
    key: number;
    requestBody?: ClaimDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => UserService.postUserClaim(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.postUserClaim>>, TError, {
    key: number;
    requestBody?: ClaimDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServiceDeleteUserClaim = <TData = Awaited<ReturnType<typeof UserService.deleteUserClaim>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.deleteUserClaim>>, unknown, {
    key: number;
    requestBody?: ClaimDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => UserService.deleteUserClaim(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.deleteUserClaim>>, TError, {
    key: number;
    requestBody?: ClaimDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServiceGetUserClaimsAllKey = "UserServiceGetUserClaimsAll";
export const useUserServiceGetUserClaimsAll = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof UserService.getUserClaimsAll>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof UserService.getUserClaimsAll>>, unknown, Awaited<ReturnType<typeof UserService.getUserClaimsAll>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useUserServiceGetUserClaimsAllKey, ...(queryKey ?? [])], queryFn: () => UserService.getUserClaimsAll(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof UserService.getUserClaimsAll>>, TError>, "data"> & {
    data: TData;
};
export const useUserServiceGetUserRoleKey = "UserServiceGetUserRole";
export const useUserServiceGetUserRole = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof UserService.getUserRole>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof UserService.getUserRole>>, unknown, Awaited<ReturnType<typeof UserService.getUserRole>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useUserServiceGetUserRoleKey, ...(queryKey ?? [{ key }])], queryFn: () => UserService.getUserRole(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof UserService.getUserRole>>, TError>, "data"> & {
    data: TData;
};
export const useUserServicePostUserRole = <TData = Awaited<ReturnType<typeof UserService.postUserRole>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.postUserRole>>, unknown, {
    key: number;
    requestBody?: RoleDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => UserService.postUserRole(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.postUserRole>>, TError, {
    key: number;
    requestBody?: RoleDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServiceDeleteUserRole = <TData = Awaited<ReturnType<typeof UserService.deleteUserRole>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof UserService.deleteUserRole>>, unknown, {
    key: number;
    requestBody?: RoleDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => UserService.deleteUserRole(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof UserService.deleteUserRole>>, TError, {
    key: number;
    requestBody?: RoleDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useUserServiceGetUserRenderlimitsKey = "UserServiceGetUserRenderlimits";
export const useUserServiceGetUserRenderlimits = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof UserService.getUserRenderlimits>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof UserService.getUserRenderlimits>>, unknown, Awaited<ReturnType<typeof UserService.getUserRenderlimits>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useUserServiceGetUserRenderlimitsKey, ...(queryKey ?? [])], queryFn: () => UserService.getUserRenderlimits(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof UserService.getUserRenderlimits>>, TError>, "data"> & {
    data: TData;
};
export const useTemplateServiceGetTemplateKey = "TemplateServiceGetTemplate";
export const useTemplateServiceGetTemplate = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof TemplateService.getTemplate>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof TemplateService.getTemplate>>, unknown, Awaited<ReturnType<typeof TemplateService.getTemplate>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useTemplateServiceGetTemplateKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => TemplateService.getTemplate(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof TemplateService.getTemplate>>, TError>, "data"> & {
    data: TData;
};
export const useTemplateServicePostTemplate = <TData = Awaited<ReturnType<typeof TemplateService.postTemplate>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof TemplateService.postTemplate>>, unknown, {
    requestBody?: TemplateEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => TemplateService.postTemplate(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof TemplateService.postTemplate>>, TError, {
    requestBody?: TemplateEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useTemplateServiceGetTemplate1Key = "TemplateServiceGetTemplate1";
export const useTemplateServiceGetTemplate1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof TemplateService.getTemplate1>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof TemplateService.getTemplate1>>, unknown, Awaited<ReturnType<typeof TemplateService.getTemplate1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useTemplateServiceGetTemplate1Key, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => TemplateService.getTemplate1(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof TemplateService.getTemplate1>>, TError>, "data"> & {
    data: TData;
};
export const useTemplateServicePutTemplate = <TData = Awaited<ReturnType<typeof TemplateService.putTemplate>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof TemplateService.putTemplate>>, unknown, {
    key: number;
    requestBody?: TemplateEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => TemplateService.putTemplate(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof TemplateService.putTemplate>>, TError, {
    key: number;
    requestBody?: TemplateEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useTemplateServiceDeleteTemplate = <TData = Awaited<ReturnType<typeof TemplateService.deleteTemplate>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof TemplateService.deleteTemplate>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => TemplateService.deleteTemplate(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof TemplateService.deleteTemplate>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useTemplateServiceGetTemplatePdfKey = "TemplateServiceGetTemplatePdf";
export const useTemplateServiceGetTemplatePdf = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof TemplateService.getTemplatePdf>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof TemplateService.getTemplatePdf>>, unknown, Awaited<ReturnType<typeof TemplateService.getTemplatePdf>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useTemplateServiceGetTemplatePdfKey, ...(queryKey ?? [{ key }])], queryFn: () => TemplateService.getTemplatePdf(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof TemplateService.getTemplatePdf>>, TError>, "data"> & {
    data: TData;
};
export const useTemplateServiceGetTemplateThumbnailKey = "TemplateServiceGetTemplateThumbnail";
export const useTemplateServiceGetTemplateThumbnail = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof TemplateService.getTemplateThumbnail>>, TError = unknown>({ key, size, format }: {
    key: number;
    size?: number;
    format?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof TemplateService.getTemplateThumbnail>>, unknown, Awaited<ReturnType<typeof TemplateService.getTemplateThumbnail>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useTemplateServiceGetTemplateThumbnailKey, ...(queryKey ?? [{ key, size, format }])], queryFn: () => TemplateService.getTemplateThumbnail(key, size, format), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof TemplateService.getTemplateThumbnail>>, TError>, "data"> & {
    data: TData;
};
export const useTemplateServiceGetTemplateImageKey = "TemplateServiceGetTemplateImage";
export const useTemplateServiceGetTemplateImage = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof TemplateService.getTemplateImage>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof TemplateService.getTemplateImage>>, unknown, Awaited<ReturnType<typeof TemplateService.getTemplateImage>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useTemplateServiceGetTemplateImageKey, ...(queryKey ?? [{ key }])], queryFn: () => TemplateService.getTemplateImage(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof TemplateService.getTemplateImage>>, TError>, "data"> & {
    data: TData;
};
export const useTemplateServicePostTemplateImage = <TData = Awaited<ReturnType<typeof TemplateService.postTemplateImage>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof TemplateService.postTemplateImage>>, unknown, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => TemplateService.postTemplateImage(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof TemplateService.postTemplateImage>>, TError, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useTemplateServicePostTemplateQAimages = <TData = Awaited<ReturnType<typeof TemplateService.postTemplateQAimages>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof TemplateService.postTemplateQAimages>>, unknown, {
    key: number;
    formData?: {
        files?: Array<Blob>;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => TemplateService.postTemplateQAimages(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof TemplateService.postTemplateQAimages>>, TError, {
    key: number;
    formData?: {
        files?: Array<Blob>;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useTemplateServiceGetTemplateQAimagesKey = "TemplateServiceGetTemplateQAimages";
export const useTemplateServiceGetTemplateQAimages = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof TemplateService.getTemplateQAimages>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof TemplateService.getTemplateQAimages>>, unknown, Awaited<ReturnType<typeof TemplateService.getTemplateQAimages>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useTemplateServiceGetTemplateQAimagesKey, ...(queryKey ?? [{ key }])], queryFn: () => TemplateService.getTemplateQAimages(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof TemplateService.getTemplateQAimages>>, TError>, "data"> & {
    data: TData;
};
export const useTemplateServiceGetTemplateQAimages1Key = "TemplateServiceGetTemplateQAimages1";
export const useTemplateServiceGetTemplateQAimages1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof TemplateService.getTemplateQAimages1>>, TError = unknown>({ key, index }: {
    key: number;
    index: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof TemplateService.getTemplateQAimages1>>, unknown, Awaited<ReturnType<typeof TemplateService.getTemplateQAimages1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useTemplateServiceGetTemplateQAimages1Key, ...(queryKey ?? [{ key, index }])], queryFn: () => TemplateService.getTemplateQAimages1(key, index), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof TemplateService.getTemplateQAimages1>>, TError>, "data"> & {
    data: TData;
};
export const useTemplateServiceGetTemplateApprovalHistoryImagesKey = "TemplateServiceGetTemplateApprovalHistoryImages";
export const useTemplateServiceGetTemplateApprovalHistoryImages = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof TemplateService.getTemplateApprovalHistoryImages>>, TError = unknown>({ key, index }: {
    key: number;
    index: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof TemplateService.getTemplateApprovalHistoryImages>>, unknown, Awaited<ReturnType<typeof TemplateService.getTemplateApprovalHistoryImages>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useTemplateServiceGetTemplateApprovalHistoryImagesKey, ...(queryKey ?? [{ key, index }])], queryFn: () => TemplateService.getTemplateApprovalHistoryImages(key, index), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof TemplateService.getTemplateApprovalHistoryImages>>, TError>, "data"> & {
    data: TData;
};
export const useTemplateServiceGetTemplateApprovalHistoryKey = "TemplateServiceGetTemplateApprovalHistory";
export const useTemplateServiceGetTemplateApprovalHistory = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof TemplateService.getTemplateApprovalHistory>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof TemplateService.getTemplateApprovalHistory>>, unknown, Awaited<ReturnType<typeof TemplateService.getTemplateApprovalHistory>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useTemplateServiceGetTemplateApprovalHistoryKey, ...(queryKey ?? [{ key }])], queryFn: () => TemplateService.getTemplateApprovalHistory(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof TemplateService.getTemplateApprovalHistory>>, TError>, "data"> & {
    data: TData;
};
export const useStillImageSubmissionPlanServiceGetStillImageSubmissionPlanKey = "StillImageSubmissionPlanServiceGetStillImageSubmissionPlan";
export const useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof StillImageSubmissionPlanService.getStillImageSubmissionPlan>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof StillImageSubmissionPlanService.getStillImageSubmissionPlan>>, unknown, Awaited<ReturnType<typeof StillImageSubmissionPlanService.getStillImageSubmissionPlan>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useStillImageSubmissionPlanServiceGetStillImageSubmissionPlanKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => StillImageSubmissionPlanService.getStillImageSubmissionPlan(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof StillImageSubmissionPlanService.getStillImageSubmissionPlan>>, TError>, "data"> & {
    data: TData;
};
export const useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan1Key = "StillImageSubmissionPlanServiceGetStillImageSubmissionPlan1";
export const useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof StillImageSubmissionPlanService.getStillImageSubmissionPlan1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof StillImageSubmissionPlanService.getStillImageSubmissionPlan1>>, unknown, Awaited<ReturnType<typeof StillImageSubmissionPlanService.getStillImageSubmissionPlan1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan1Key, ...(queryKey ?? [{ key }])], queryFn: () => StillImageSubmissionPlanService.getStillImageSubmissionPlan1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof StillImageSubmissionPlanService.getStillImageSubmissionPlan1>>, TError>, "data"> & {
    data: TData;
};
export const useStatisticsServiceGetStatisticsKey = "StatisticsServiceGetStatistics";
export const useStatisticsServiceGetStatistics = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof StatisticsService.getStatistics>>, TError = unknown>({ startDate, endDate, modelId, userId, clientId, dateInterval, sceneId, renderType, projectType, top, skip, filter, select, orderby, expand, count }: {
    startDate?: string;
    endDate?: string;
    modelId?: string;
    userId?: number;
    clientId?: number;
    dateInterval?: string;
    sceneId?: number;
    renderType?: RenderType;
    projectType?: ProjectType;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof StatisticsService.getStatistics>>, unknown, Awaited<ReturnType<typeof StatisticsService.getStatistics>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useStatisticsServiceGetStatisticsKey, ...(queryKey ?? [{ startDate, endDate, modelId, userId, clientId, dateInterval, sceneId, renderType, projectType, top, skip, filter, select, orderby, expand, count }])], queryFn: () => StatisticsService.getStatistics(startDate, endDate, modelId, userId, clientId, dateInterval, sceneId, renderType, projectType, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof StatisticsService.getStatistics>>, TError>, "data"> & {
    data: TData;
};
export const useSearchProjectServiceGetSearchProjectKey = "SearchProjectServiceGetSearchProject";
export const useSearchProjectServiceGetSearchProject = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SearchProjectService.getSearchProject>>, TError = unknown>({ q, assets, mode, top, skip, filter, select, orderby, expand, count }: {
    q?: string;
    assets?: Array<string>;
    mode?: string;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SearchProjectService.getSearchProject>>, unknown, Awaited<ReturnType<typeof SearchProjectService.getSearchProject>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSearchProjectServiceGetSearchProjectKey, ...(queryKey ?? [{ q, assets, mode, top, skip, filter, select, orderby, expand, count }])], queryFn: () => SearchProjectService.getSearchProject(q, assets, mode, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SearchProjectService.getSearchProject>>, TError>, "data"> & {
    data: TData;
};
export const useSearchCutoutServiceGetSearchCutoutKey = "SearchCutoutServiceGetSearchCutout";
export const useSearchCutoutServiceGetSearchCutout = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SearchCutoutService.getSearchCutout>>, TError = unknown>({ asset, top, skip, filter, select, orderby, expand, count }: {
    asset?: string;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SearchCutoutService.getSearchCutout>>, unknown, Awaited<ReturnType<typeof SearchCutoutService.getSearchCutout>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSearchCutoutServiceGetSearchCutoutKey, ...(queryKey ?? [{ asset, top, skip, filter, select, orderby, expand, count }])], queryFn: () => SearchCutoutService.getSearchCutout(asset, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SearchCutoutService.getSearchCutout>>, TError>, "data"> & {
    data: TData;
};
export const useSearchAssetServiceGetSearchAssetKey = "SearchAssetServiceGetSearchAsset";
export const useSearchAssetServiceGetSearchAsset = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SearchAssetService.getSearchAsset>>, TError = unknown>({ q, top, skip, filter, select, orderby, expand, count }: {
    q?: string;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SearchAssetService.getSearchAsset>>, unknown, Awaited<ReturnType<typeof SearchAssetService.getSearchAsset>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSearchAssetServiceGetSearchAssetKey, ...(queryKey ?? [{ q, top, skip, filter, select, orderby, expand, count }])], queryFn: () => SearchAssetService.getSearchAsset(q, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SearchAssetService.getSearchAsset>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServiceGetSceneKey = "SceneServiceGetScene";
export const useSceneServiceGetScene = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getScene>>, TError = unknown>({ query, top, skip, filter, select, orderby, expand, count }: {
    query?: string;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getScene>>, unknown, Awaited<ReturnType<typeof SceneService.getScene>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetSceneKey, ...(queryKey ?? [{ query, top, skip, filter, select, orderby, expand, count }])], queryFn: () => SceneService.getScene(query, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getScene>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServicePostScene = <TData = Awaited<ReturnType<typeof SceneService.postScene>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof SceneService.postScene>>, unknown, {
    requestBody?: SceneEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => SceneService.postScene(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof SceneService.postScene>>, TError, {
    requestBody?: SceneEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useSceneServiceGetScene1Key = "SceneServiceGetScene1";
export const useSceneServiceGetScene1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getScene1>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getScene1>>, unknown, Awaited<ReturnType<typeof SceneService.getScene1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetScene1Key, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => SceneService.getScene1(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getScene1>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServicePutScene = <TData = Awaited<ReturnType<typeof SceneService.putScene>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof SceneService.putScene>>, unknown, {
    key: number;
    requestBody?: SceneEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => SceneService.putScene(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof SceneService.putScene>>, TError, {
    key: number;
    requestBody?: SceneEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useSceneServiceDeleteScene = <TData = Awaited<ReturnType<typeof SceneService.deleteScene>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof SceneService.deleteScene>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => SceneService.deleteScene(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof SceneService.deleteScene>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useSceneServiceGetCameraThumbnailKey = "SceneServiceGetCameraThumbnail";
export const useSceneServiceGetCameraThumbnail = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getCameraThumbnail>>, TError = unknown>({ id, size }: {
    id: number;
    size?: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getCameraThumbnail>>, unknown, Awaited<ReturnType<typeof SceneService.getCameraThumbnail>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetCameraThumbnailKey, ...(queryKey ?? [{ id, size }])], queryFn: () => SceneService.getCameraThumbnail(id, size), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getCameraThumbnail>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServiceGetCameraImageKey = "SceneServiceGetCameraImage";
export const useSceneServiceGetCameraImage = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getCameraImage>>, TError = unknown>({ id }: {
    id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getCameraImage>>, unknown, Awaited<ReturnType<typeof SceneService.getCameraImage>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetCameraImageKey, ...(queryKey ?? [{ id }])], queryFn: () => SceneService.getCameraImage(id), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getCameraImage>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServicePostCameraImage = <TData = Awaited<ReturnType<typeof SceneService.postCameraImage>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof SceneService.postCameraImage>>, unknown, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => SceneService.postCameraImage(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof SceneService.postCameraImage>>, TError, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useSceneServiceGetCameraAnimationpreviewKey = "SceneServiceGetCameraAnimationpreview";
export const useSceneServiceGetCameraAnimationpreview = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getCameraAnimationpreview>>, TError = unknown>({ id }: {
    id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getCameraAnimationpreview>>, unknown, Awaited<ReturnType<typeof SceneService.getCameraAnimationpreview>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetCameraAnimationpreviewKey, ...(queryKey ?? [{ id }])], queryFn: () => SceneService.getCameraAnimationpreview(id), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getCameraAnimationpreview>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServicePostCameraAnimationpreview = <TData = Awaited<ReturnType<typeof SceneService.postCameraAnimationpreview>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof SceneService.postCameraAnimationpreview>>, unknown, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => SceneService.postCameraAnimationpreview(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof SceneService.postCameraAnimationpreview>>, TError, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useSceneServiceGetAnimationThumbnailKey = "SceneServiceGetAnimationThumbnail";
export const useSceneServiceGetAnimationThumbnail = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getAnimationThumbnail>>, TError = unknown>({ id, size }: {
    id: number;
    size?: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getAnimationThumbnail>>, unknown, Awaited<ReturnType<typeof SceneService.getAnimationThumbnail>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetAnimationThumbnailKey, ...(queryKey ?? [{ id, size }])], queryFn: () => SceneService.getAnimationThumbnail(id, size), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getAnimationThumbnail>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServicePostAnimationThumbnail = <TData = Awaited<ReturnType<typeof SceneService.postAnimationThumbnail>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof SceneService.postAnimationThumbnail>>, unknown, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => SceneService.postAnimationThumbnail(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof SceneService.postAnimationThumbnail>>, TError, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useSceneServicePostSceneRenderings = <TData = Awaited<ReturnType<typeof SceneService.postSceneRenderings>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof SceneService.postSceneRenderings>>, unknown, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => SceneService.postSceneRenderings(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof SceneService.postSceneRenderings>>, TError, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useSceneServiceGetSceneRenderingsKey = "SceneServiceGetSceneRenderings";
export const useSceneServiceGetSceneRenderings = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getSceneRenderings>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getSceneRenderings>>, unknown, Awaited<ReturnType<typeof SceneService.getSceneRenderings>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetSceneRenderingsKey, ...(queryKey ?? [{ key }])], queryFn: () => SceneService.getSceneRenderings(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getSceneRenderings>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServiceGetSceneRenderings1Key = "SceneServiceGetSceneRenderings1";
export const useSceneServiceGetSceneRenderings1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getSceneRenderings1>>, TError = unknown>({ key, index }: {
    key: number;
    index: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getSceneRenderings1>>, unknown, Awaited<ReturnType<typeof SceneService.getSceneRenderings1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetSceneRenderings1Key, ...(queryKey ?? [{ key, index }])], queryFn: () => SceneService.getSceneRenderings1(key, index), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getSceneRenderings1>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServiceDeleteSceneRenderings = <TData = Awaited<ReturnType<typeof SceneService.deleteSceneRenderings>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof SceneService.deleteSceneRenderings>>, unknown, {
    key: number;
    index: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, index }) => SceneService.deleteSceneRenderings(key, index), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof SceneService.deleteSceneRenderings>>, TError, {
    key: number;
    index: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useSceneServiceGetSceneQueryKey = "SceneServiceGetSceneQuery";
export const useSceneServiceGetSceneQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getSceneQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getSceneQuery>>, unknown, Awaited<ReturnType<typeof SceneService.getSceneQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetSceneQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => SceneService.getSceneQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getSceneQuery>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServiceGetSceneThumbnailKey = "SceneServiceGetSceneThumbnail";
export const useSceneServiceGetSceneThumbnail = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getSceneThumbnail>>, TError = unknown>({ key, size, format }: {
    key: number;
    size?: number;
    format?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getSceneThumbnail>>, unknown, Awaited<ReturnType<typeof SceneService.getSceneThumbnail>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetSceneThumbnailKey, ...(queryKey ?? [{ key, size, format }])], queryFn: () => SceneService.getSceneThumbnail(key, size, format), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getSceneThumbnail>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServiceGetSceneImageKey = "SceneServiceGetSceneImage";
export const useSceneServiceGetSceneImage = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getSceneImage>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getSceneImage>>, unknown, Awaited<ReturnType<typeof SceneService.getSceneImage>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetSceneImageKey, ...(queryKey ?? [{ key }])], queryFn: () => SceneService.getSceneImage(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getSceneImage>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServicePostSceneImage = <TData = Awaited<ReturnType<typeof SceneService.postSceneImage>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof SceneService.postSceneImage>>, unknown, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => SceneService.postSceneImage(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof SceneService.postSceneImage>>, TError, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useSceneServicePostSceneQAimages = <TData = Awaited<ReturnType<typeof SceneService.postSceneQAimages>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof SceneService.postSceneQAimages>>, unknown, {
    key: number;
    formData?: {
        files?: Array<Blob>;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => SceneService.postSceneQAimages(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof SceneService.postSceneQAimages>>, TError, {
    key: number;
    formData?: {
        files?: Array<Blob>;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useSceneServiceGetSceneQAimagesKey = "SceneServiceGetSceneQAimages";
export const useSceneServiceGetSceneQAimages = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getSceneQAimages>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getSceneQAimages>>, unknown, Awaited<ReturnType<typeof SceneService.getSceneQAimages>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetSceneQAimagesKey, ...(queryKey ?? [{ key }])], queryFn: () => SceneService.getSceneQAimages(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getSceneQAimages>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServiceGetSceneQAimages1Key = "SceneServiceGetSceneQAimages1";
export const useSceneServiceGetSceneQAimages1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getSceneQAimages1>>, TError = unknown>({ key, index }: {
    key: number;
    index: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getSceneQAimages1>>, unknown, Awaited<ReturnType<typeof SceneService.getSceneQAimages1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetSceneQAimages1Key, ...(queryKey ?? [{ key, index }])], queryFn: () => SceneService.getSceneQAimages1(key, index), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getSceneQAimages1>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServiceGetSceneApprovalHistoryImagesKey = "SceneServiceGetSceneApprovalHistoryImages";
export const useSceneServiceGetSceneApprovalHistoryImages = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getSceneApprovalHistoryImages>>, TError = unknown>({ key, index }: {
    key: number;
    index: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getSceneApprovalHistoryImages>>, unknown, Awaited<ReturnType<typeof SceneService.getSceneApprovalHistoryImages>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetSceneApprovalHistoryImagesKey, ...(queryKey ?? [{ key, index }])], queryFn: () => SceneService.getSceneApprovalHistoryImages(key, index), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getSceneApprovalHistoryImages>>, TError>, "data"> & {
    data: TData;
};
export const useSceneServiceGetSceneApprovalHistoryKey = "SceneServiceGetSceneApprovalHistory";
export const useSceneServiceGetSceneApprovalHistory = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof SceneService.getSceneApprovalHistory>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof SceneService.getSceneApprovalHistory>>, unknown, Awaited<ReturnType<typeof SceneService.getSceneApprovalHistory>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useSceneServiceGetSceneApprovalHistoryKey, ...(queryKey ?? [{ key }])], queryFn: () => SceneService.getSceneApprovalHistory(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof SceneService.getSceneApprovalHistory>>, TError>, "data"> & {
    data: TData;
};
export const useRoleServicePostRole = <TData = Awaited<ReturnType<typeof RoleService.postRole>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof RoleService.postRole>>, unknown, {
    requestBody?: RoleDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => RoleService.postRole(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof RoleService.postRole>>, TError, {
    requestBody?: RoleDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useRoleServiceGetRoleKey = "RoleServiceGetRole";
export const useRoleServiceGetRole = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof RoleService.getRole>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof RoleService.getRole>>, unknown, Awaited<ReturnType<typeof RoleService.getRole>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useRoleServiceGetRoleKey, ...(queryKey ?? [])], queryFn: () => RoleService.getRole(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof RoleService.getRole>>, TError>, "data"> & {
    data: TData;
};
export const useRoleServicePutRole = <TData = Awaited<ReturnType<typeof RoleService.putRole>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof RoleService.putRole>>, unknown, {
    key: string;
    id?: number;
    requestBody?: RoleDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, id, requestBody }) => RoleService.putRole(key, id, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof RoleService.putRole>>, TError, {
    key: string;
    id?: number;
    requestBody?: RoleDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useRoleServiceDeleteRole = <TData = Awaited<ReturnType<typeof RoleService.deleteRole>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof RoleService.deleteRole>>, unknown, {
    key: string;
    id?: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, id }) => RoleService.deleteRole(key, id), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof RoleService.deleteRole>>, TError, {
    key: string;
    id?: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useRoleServiceGetRole1Key = "RoleServiceGetRole1";
export const useRoleServiceGetRole1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof RoleService.getRole1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof RoleService.getRole1>>, unknown, Awaited<ReturnType<typeof RoleService.getRole1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useRoleServiceGetRole1Key, ...(queryKey ?? [{ key }])], queryFn: () => RoleService.getRole1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof RoleService.getRole1>>, TError>, "data"> & {
    data: TData;
};
export const useRoleServiceGetRoleClaimKey = "RoleServiceGetRoleClaim";
export const useRoleServiceGetRoleClaim = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof RoleService.getRoleClaim>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof RoleService.getRoleClaim>>, unknown, Awaited<ReturnType<typeof RoleService.getRoleClaim>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useRoleServiceGetRoleClaimKey, ...(queryKey ?? [{ key }])], queryFn: () => RoleService.getRoleClaim(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof RoleService.getRoleClaim>>, TError>, "data"> & {
    data: TData;
};
export const useRoleServicePostRoleClaim = <TData = Awaited<ReturnType<typeof RoleService.postRoleClaim>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof RoleService.postRoleClaim>>, unknown, {
    key: number;
    requestBody?: ClaimDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => RoleService.postRoleClaim(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof RoleService.postRoleClaim>>, TError, {
    key: number;
    requestBody?: ClaimDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useRoleServiceDeleteRoleClaim = <TData = Awaited<ReturnType<typeof RoleService.deleteRoleClaim>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof RoleService.deleteRoleClaim>>, unknown, {
    key: number;
    requestBody?: ClaimDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => RoleService.deleteRoleClaim(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof RoleService.deleteRoleClaim>>, TError, {
    key: number;
    requestBody?: ClaimDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useRoleServiceGetRoleClaimsAllKey = "RoleServiceGetRoleClaimsAll";
export const useRoleServiceGetRoleClaimsAll = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof RoleService.getRoleClaimsAll>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof RoleService.getRoleClaimsAll>>, unknown, Awaited<ReturnType<typeof RoleService.getRoleClaimsAll>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useRoleServiceGetRoleClaimsAllKey, ...(queryKey ?? [])], queryFn: () => RoleService.getRoleClaimsAll(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof RoleService.getRoleClaimsAll>>, TError>, "data"> & {
    data: TData;
};
export const useProjectShareServiceGetProjectShareValidateShareUserKey = "ProjectShareServiceGetProjectShareValidateShareUser";
export const useProjectShareServiceGetProjectShareValidateShareUser = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectShareService.getProjectShareValidateShareUser>>, TError = unknown>({ userId, projectId, projectFolderId }: {
    userId: number;
    projectId?: number;
    projectFolderId?: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectShareService.getProjectShareValidateShareUser>>, unknown, Awaited<ReturnType<typeof ProjectShareService.getProjectShareValidateShareUser>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectShareServiceGetProjectShareValidateShareUserKey, ...(queryKey ?? [{ userId, projectId, projectFolderId }])], queryFn: () => ProjectShareService.getProjectShareValidateShareUser(userId, projectId, projectFolderId), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectShareService.getProjectShareValidateShareUser>>, TError>, "data"> & {
    data: TData;
};
export const useProjectShareServicePostProjectShare = <TData = Awaited<ReturnType<typeof ProjectShareService.postProjectShare>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ProjectShareService.postProjectShare>>, unknown, {
    requestBody?: ProjectShareDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => ProjectShareService.postProjectShare(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ProjectShareService.postProjectShare>>, TError, {
    requestBody?: ProjectShareDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useProjectServiceGetProjectKey = "ProjectServiceGetProject";
export const useProjectServiceGetProject = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectService.getProject>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectService.getProject>>, unknown, Awaited<ReturnType<typeof ProjectService.getProject>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectServiceGetProjectKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => ProjectService.getProject(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectService.getProject>>, TError>, "data"> & {
    data: TData;
};
export const useProjectServicePostProject = <TData = Awaited<ReturnType<typeof ProjectService.postProject>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ProjectService.postProject>>, unknown, {
    requestBody?: ProjectDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => ProjectService.postProject(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ProjectService.postProject>>, TError, {
    requestBody?: ProjectDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useProjectServiceGetProject1Key = "ProjectServiceGetProject1";
export const useProjectServiceGetProject1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectService.getProject1>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectService.getProject1>>, unknown, Awaited<ReturnType<typeof ProjectService.getProject1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectServiceGetProject1Key, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => ProjectService.getProject1(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectService.getProject1>>, TError>, "data"> & {
    data: TData;
};
export const useProjectServicePutProject = <TData = Awaited<ReturnType<typeof ProjectService.putProject>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ProjectService.putProject>>, unknown, {
    key: number;
    requestBody?: ProjectDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => ProjectService.putProject(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ProjectService.putProject>>, TError, {
    key: number;
    requestBody?: ProjectDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useProjectServiceDeleteProject = <TData = Awaited<ReturnType<typeof ProjectService.deleteProject>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ProjectService.deleteProject>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => ProjectService.deleteProject(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ProjectService.deleteProject>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useProjectServiceGetProjectOrdersKey = "ProjectServiceGetProjectOrders";
export const useProjectServiceGetProjectOrders = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectService.getProjectOrders>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectService.getProjectOrders>>, unknown, Awaited<ReturnType<typeof ProjectService.getProjectOrders>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectServiceGetProjectOrdersKey, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => ProjectService.getProjectOrders(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectService.getProjectOrders>>, TError>, "data"> & {
    data: TData;
};
export const useProjectServicePostProjectCopy = <TData = Awaited<ReturnType<typeof ProjectService.postProjectCopy>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ProjectService.postProjectCopy>>, unknown, {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, top, skip, filter, select, orderby, expand, count }) => ProjectService.postProjectCopy(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ProjectService.postProjectCopy>>, TError, {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, TContext>, "data"> & {
    data: TData;
};
export const useProjectServiceGetProjectFilesKey = "ProjectServiceGetProjectFiles";
export const useProjectServiceGetProjectFiles = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectService.getProjectFiles>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectService.getProjectFiles>>, unknown, Awaited<ReturnType<typeof ProjectService.getProjectFiles>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectServiceGetProjectFilesKey, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => ProjectService.getProjectFiles(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectService.getProjectFiles>>, TError>, "data"> & {
    data: TData;
};
export const useProjectServiceGetProjectPowerpointKey = "ProjectServiceGetProjectPowerpoint";
export const useProjectServiceGetProjectPowerpoint = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectService.getProjectPowerpoint>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectService.getProjectPowerpoint>>, unknown, Awaited<ReturnType<typeof ProjectService.getProjectPowerpoint>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectServiceGetProjectPowerpointKey, ...(queryKey ?? [{ key }])], queryFn: () => ProjectService.getProjectPowerpoint(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectService.getProjectPowerpoint>>, TError>, "data"> & {
    data: TData;
};
export const useProjectServiceGetProjectMoodboardKey = "ProjectServiceGetProjectMoodboard";
export const useProjectServiceGetProjectMoodboard = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectService.getProjectMoodboard>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectService.getProjectMoodboard>>, unknown, Awaited<ReturnType<typeof ProjectService.getProjectMoodboard>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectServiceGetProjectMoodboardKey, ...(queryKey ?? [{ key }])], queryFn: () => ProjectService.getProjectMoodboard(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectService.getProjectMoodboard>>, TError>, "data"> & {
    data: TData;
};
export const useProjectServiceGetProjectThumbnailKey = "ProjectServiceGetProjectThumbnail";
export const useProjectServiceGetProjectThumbnail = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectService.getProjectThumbnail>>, TError = unknown>({ key, size, filetype }: {
    key: number;
    size?: number;
    filetype?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectService.getProjectThumbnail>>, unknown, Awaited<ReturnType<typeof ProjectService.getProjectThumbnail>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectServiceGetProjectThumbnailKey, ...(queryKey ?? [{ key, size, filetype }])], queryFn: () => ProjectService.getProjectThumbnail(key, size, filetype), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectService.getProjectThumbnail>>, TError>, "data"> & {
    data: TData;
};
export const useProjectServiceGetProjectKingfisherDamSheetKey = "ProjectServiceGetProjectKingfisherDamSheet";
export const useProjectServiceGetProjectKingfisherDamSheet = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectService.getProjectKingfisherDamSheet>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectService.getProjectKingfisherDamSheet>>, unknown, Awaited<ReturnType<typeof ProjectService.getProjectKingfisherDamSheet>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectServiceGetProjectKingfisherDamSheetKey, ...(queryKey ?? [{ key }])], queryFn: () => ProjectService.getProjectKingfisherDamSheet(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectService.getProjectKingfisherDamSheet>>, TError>, "data"> & {
    data: TData;
};
export const useProjectServicePostProjectUploadToKingfisherDam = <TData = Awaited<ReturnType<typeof ProjectService.postProjectUploadToKingfisherDam>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ProjectService.postProjectUploadToKingfisherDam>>, unknown, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => ProjectService.postProjectUploadToKingfisherDam(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ProjectService.postProjectUploadToKingfisherDam>>, TError, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useProjectServicePostProjectTransferToKingfisherDam = <TData = Awaited<ReturnType<typeof ProjectService.postProjectTransferToKingfisherDam>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ProjectService.postProjectTransferToKingfisherDam>>, unknown, {
    key: string;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => ProjectService.postProjectTransferToKingfisherDam(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ProjectService.postProjectTransferToKingfisherDam>>, TError, {
    key: string;
}, TContext>, "data"> & {
    data: TData;
};
export const useProjectFolderServiceGetProjectFolderKey = "ProjectFolderServiceGetProjectFolder";
export const useProjectFolderServiceGetProjectFolder = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectFolderService.getProjectFolder>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectFolderService.getProjectFolder>>, unknown, Awaited<ReturnType<typeof ProjectFolderService.getProjectFolder>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectFolderServiceGetProjectFolderKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => ProjectFolderService.getProjectFolder(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectFolderService.getProjectFolder>>, TError>, "data"> & {
    data: TData;
};
export const useProjectFolderServicePostProjectFolder = <TData = Awaited<ReturnType<typeof ProjectFolderService.postProjectFolder>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ProjectFolderService.postProjectFolder>>, unknown, {
    requestBody?: ProjectFolderEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => ProjectFolderService.postProjectFolder(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ProjectFolderService.postProjectFolder>>, TError, {
    requestBody?: ProjectFolderEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useProjectFolderServiceGetProjectFolder1Key = "ProjectFolderServiceGetProjectFolder1";
export const useProjectFolderServiceGetProjectFolder1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectFolderService.getProjectFolder1>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectFolderService.getProjectFolder1>>, unknown, Awaited<ReturnType<typeof ProjectFolderService.getProjectFolder1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectFolderServiceGetProjectFolder1Key, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => ProjectFolderService.getProjectFolder1(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectFolderService.getProjectFolder1>>, TError>, "data"> & {
    data: TData;
};
export const useProjectFolderServicePutProjectFolder = <TData = Awaited<ReturnType<typeof ProjectFolderService.putProjectFolder>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ProjectFolderService.putProjectFolder>>, unknown, {
    key: number;
    requestBody?: ProjectFolderEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => ProjectFolderService.putProjectFolder(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ProjectFolderService.putProjectFolder>>, TError, {
    key: number;
    requestBody?: ProjectFolderEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useProjectFolderServiceDeleteProjectFolder = <TData = Awaited<ReturnType<typeof ProjectFolderService.deleteProjectFolder>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ProjectFolderService.deleteProjectFolder>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => ProjectFolderService.deleteProjectFolder(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ProjectFolderService.deleteProjectFolder>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useProjectFolderServiceGetProjectFolderOrdersKey = "ProjectFolderServiceGetProjectFolderOrders";
export const useProjectFolderServiceGetProjectFolderOrders = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderOrders>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderOrders>>, unknown, Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderOrders>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectFolderServiceGetProjectFolderOrdersKey, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => ProjectFolderService.getProjectFolderOrders(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderOrders>>, TError>, "data"> & {
    data: TData;
};
export const useProjectFolderServiceGetProjectFolderFilesKey = "ProjectFolderServiceGetProjectFolderFiles";
export const useProjectFolderServiceGetProjectFolderFiles = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderFiles>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderFiles>>, unknown, Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderFiles>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectFolderServiceGetProjectFolderFilesKey, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => ProjectFolderService.getProjectFolderFiles(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderFiles>>, TError>, "data"> & {
    data: TData;
};
export const useProjectFolderServiceGetProjectFolderPowerpointKey = "ProjectFolderServiceGetProjectFolderPowerpoint";
export const useProjectFolderServiceGetProjectFolderPowerpoint = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderPowerpoint>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderPowerpoint>>, unknown, Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderPowerpoint>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectFolderServiceGetProjectFolderPowerpointKey, ...(queryKey ?? [{ key }])], queryFn: () => ProjectFolderService.getProjectFolderPowerpoint(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderPowerpoint>>, TError>, "data"> & {
    data: TData;
};
export const useProjectFolderServiceGetProjectFolderKingfisherDamSheetKey = "ProjectFolderServiceGetProjectFolderKingfisherDamSheet";
export const useProjectFolderServiceGetProjectFolderKingfisherDamSheet = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderKingfisherDamSheet>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderKingfisherDamSheet>>, unknown, Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderKingfisherDamSheet>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useProjectFolderServiceGetProjectFolderKingfisherDamSheetKey, ...(queryKey ?? [{ key }])], queryFn: () => ProjectFolderService.getProjectFolderKingfisherDamSheet(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ProjectFolderService.getProjectFolderKingfisherDamSheet>>, TError>, "data"> & {
    data: TData;
};
export const useProjectFolderServicePostProjectFolderUploadToKingfisherDam = <TData = Awaited<ReturnType<typeof ProjectFolderService.postProjectFolderUploadToKingfisherDam>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ProjectFolderService.postProjectFolderUploadToKingfisherDam>>, unknown, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => ProjectFolderService.postProjectFolderUploadToKingfisherDam(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ProjectFolderService.postProjectFolderUploadToKingfisherDam>>, TError, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useProjectFolderServicePostProjectFolderTransferToKingfisherDam = <TData = Awaited<ReturnType<typeof ProjectFolderService.postProjectFolderTransferToKingfisherDam>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ProjectFolderService.postProjectFolderTransferToKingfisherDam>>, unknown, {
    key: string;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => ProjectFolderService.postProjectFolderTransferToKingfisherDam(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ProjectFolderService.postProjectFolderTransferToKingfisherDam>>, TError, {
    key: string;
}, TContext>, "data"> & {
    data: TData;
};
export const useOrderServiceGetOrderKey = "OrderServiceGetOrder";
export const useOrderServiceGetOrder = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof OrderService.getOrder>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof OrderService.getOrder>>, unknown, Awaited<ReturnType<typeof OrderService.getOrder>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useOrderServiceGetOrderKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => OrderService.getOrder(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof OrderService.getOrder>>, TError>, "data"> & {
    data: TData;
};
export const useOrderServicePostOrder = <TData = Awaited<ReturnType<typeof OrderService.postOrder>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof OrderService.postOrder>>, unknown, {
    requestBody?: OrderDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => OrderService.postOrder(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof OrderService.postOrder>>, TError, {
    requestBody?: OrderDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useOrderServiceDeleteOrder = <TData = Awaited<ReturnType<typeof OrderService.deleteOrder>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof OrderService.deleteOrder>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => OrderService.deleteOrder(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof OrderService.deleteOrder>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useOrderServiceGetOrder1Key = "OrderServiceGetOrder1";
export const useOrderServiceGetOrder1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof OrderService.getOrder1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof OrderService.getOrder1>>, unknown, Awaited<ReturnType<typeof OrderService.getOrder1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useOrderServiceGetOrder1Key, ...(queryKey ?? [{ key }])], queryFn: () => OrderService.getOrder1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof OrderService.getOrder1>>, TError>, "data"> & {
    data: TData;
};
export const useOrderServicePutOrder = <TData = Awaited<ReturnType<typeof OrderService.putOrder>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof OrderService.putOrder>>, unknown, {
    key: number;
    requestBody?: OrderDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => OrderService.putOrder(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof OrderService.putOrder>>, TError, {
    key: number;
    requestBody?: OrderDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useOrderServicePutOrderPlace = <TData = Awaited<ReturnType<typeof OrderService.putOrderPlace>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof OrderService.putOrderPlace>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => OrderService.putOrderPlace(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof OrderService.putOrderPlace>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useOrderlineServiceGetOrderOrderlineKey = "OrderlineServiceGetOrderOrderline";
export const useOrderlineServiceGetOrderOrderline = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof OrderlineService.getOrderOrderline>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof OrderlineService.getOrderOrderline>>, unknown, Awaited<ReturnType<typeof OrderlineService.getOrderOrderline>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useOrderlineServiceGetOrderOrderlineKey, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => OrderlineService.getOrderOrderline(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof OrderlineService.getOrderOrderline>>, TError>, "data"> & {
    data: TData;
};
export const useOrderlineServicePostOrderOrderline = <TData = Awaited<ReturnType<typeof OrderlineService.postOrderOrderline>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof OrderlineService.postOrderOrderline>>, unknown, {
    key: number;
    requestBody?: OrderlineEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => OrderlineService.postOrderOrderline(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof OrderlineService.postOrderOrderline>>, TError, {
    key: number;
    requestBody?: OrderlineEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useOrderlineServiceGetOrderOrderline1Key = "OrderlineServiceGetOrderOrderline1";
export const useOrderlineServiceGetOrderOrderline1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof OrderlineService.getOrderOrderline1>>, TError = unknown>({ key, id }: {
    key: number;
    id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof OrderlineService.getOrderOrderline1>>, unknown, Awaited<ReturnType<typeof OrderlineService.getOrderOrderline1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useOrderlineServiceGetOrderOrderline1Key, ...(queryKey ?? [{ key, id }])], queryFn: () => OrderlineService.getOrderOrderline1(key, id), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof OrderlineService.getOrderOrderline1>>, TError>, "data"> & {
    data: TData;
};
export const useOrderlineServicePutOrderOrderline = <TData = Awaited<ReturnType<typeof OrderlineService.putOrderOrderline>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof OrderlineService.putOrderOrderline>>, unknown, {
    key: number;
    id: number;
    requestBody?: OrderlineEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, id, requestBody }) => OrderlineService.putOrderOrderline(key, id, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof OrderlineService.putOrderOrderline>>, TError, {
    key: number;
    id: number;
    requestBody?: OrderlineEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useOrderlineServiceDeleteOrderOrderline = <TData = Awaited<ReturnType<typeof OrderlineService.deleteOrderOrderline>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof OrderlineService.deleteOrderOrderline>>, unknown, {
    key: number;
    id: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, id }) => OrderlineService.deleteOrderOrderline(key, id), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof OrderlineService.deleteOrderOrderline>>, TError, {
    key: number;
    id: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useOrderlineServicePostOrderOrderlineRequestManualAdjusments = <TData = Awaited<ReturnType<typeof OrderlineService.postOrderOrderlineRequestManualAdjusments>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof OrderlineService.postOrderOrderlineRequestManualAdjusments>>, unknown, {
    key: number;
    id: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, id }) => OrderlineService.postOrderOrderlineRequestManualAdjusments(key, id), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof OrderlineService.postOrderOrderlineRequestManualAdjusments>>, TError, {
    key: number;
    id: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useOrderlineServicePostOrderOrderlineAddToSolutionizer = <TData = Awaited<ReturnType<typeof OrderlineService.postOrderOrderlineAddToSolutionizer>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof OrderlineService.postOrderOrderlineAddToSolutionizer>>, unknown, {
    key: number;
    id: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, id }) => OrderlineService.postOrderOrderlineAddToSolutionizer(key, id), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof OrderlineService.postOrderOrderlineAddToSolutionizer>>, TError, {
    key: number;
    id: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useModifierServiceGetModifierKey = "ModifierServiceGetModifier";
export const useModifierServiceGetModifier = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModifierService.getModifier>>, TError = unknown>({ query, top, skip, filter, select, orderby, expand, count }: {
    query?: string;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModifierService.getModifier>>, unknown, Awaited<ReturnType<typeof ModifierService.getModifier>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModifierServiceGetModifierKey, ...(queryKey ?? [{ query, top, skip, filter, select, orderby, expand, count }])], queryFn: () => ModifierService.getModifier(query, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModifierService.getModifier>>, TError>, "data"> & {
    data: TData;
};
export const useModifierServicePostModifier = <TData = Awaited<ReturnType<typeof ModifierService.postModifier>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModifierService.postModifier>>, unknown, {
    requestBody?: ModifierEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => ModifierService.postModifier(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModifierService.postModifier>>, TError, {
    requestBody?: ModifierEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useModifierServiceGetModifier1Key = "ModifierServiceGetModifier1";
export const useModifierServiceGetModifier1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModifierService.getModifier1>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModifierService.getModifier1>>, unknown, Awaited<ReturnType<typeof ModifierService.getModifier1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModifierServiceGetModifier1Key, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => ModifierService.getModifier1(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModifierService.getModifier1>>, TError>, "data"> & {
    data: TData;
};
export const useModifierServicePutModifier = <TData = Awaited<ReturnType<typeof ModifierService.putModifier>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModifierService.putModifier>>, unknown, {
    key: number;
    requestBody?: ModifierEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => ModifierService.putModifier(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModifierService.putModifier>>, TError, {
    key: number;
    requestBody?: ModifierEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useModifierServiceDeleteModifier = <TData = Awaited<ReturnType<typeof ModifierService.deleteModifier>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModifierService.deleteModifier>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => ModifierService.deleteModifier(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModifierService.deleteModifier>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useModifierServiceGetModifierQueryKey = "ModifierServiceGetModifierQuery";
export const useModifierServiceGetModifierQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModifierService.getModifierQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModifierService.getModifierQuery>>, unknown, Awaited<ReturnType<typeof ModifierService.getModifierQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModifierServiceGetModifierQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => ModifierService.getModifierQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModifierService.getModifierQuery>>, TError>, "data"> & {
    data: TData;
};
export const useModifierServiceGetModifierThumbnailKey = "ModifierServiceGetModifierThumbnail";
export const useModifierServiceGetModifierThumbnail = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModifierService.getModifierThumbnail>>, TError = unknown>({ key, size, format }: {
    key: number;
    size?: number;
    format?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModifierService.getModifierThumbnail>>, unknown, Awaited<ReturnType<typeof ModifierService.getModifierThumbnail>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModifierServiceGetModifierThumbnailKey, ...(queryKey ?? [{ key, size, format }])], queryFn: () => ModifierService.getModifierThumbnail(key, size, format), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModifierService.getModifierThumbnail>>, TError>, "data"> & {
    data: TData;
};
export const useModifierServiceGetModifierImageKey = "ModifierServiceGetModifierImage";
export const useModifierServiceGetModifierImage = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModifierService.getModifierImage>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModifierService.getModifierImage>>, unknown, Awaited<ReturnType<typeof ModifierService.getModifierImage>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModifierServiceGetModifierImageKey, ...(queryKey ?? [{ key }])], queryFn: () => ModifierService.getModifierImage(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModifierService.getModifierImage>>, TError>, "data"> & {
    data: TData;
};
export const useModifierServicePostModifierImage = <TData = Awaited<ReturnType<typeof ModifierService.postModifierImage>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModifierService.postModifierImage>>, unknown, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => ModifierService.postModifierImage(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModifierService.postModifierImage>>, TError, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useModifierServicePostModifierQAimages = <TData = Awaited<ReturnType<typeof ModifierService.postModifierQAimages>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModifierService.postModifierQAimages>>, unknown, {
    key: number;
    formData?: {
        files?: Array<Blob>;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => ModifierService.postModifierQAimages(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModifierService.postModifierQAimages>>, TError, {
    key: number;
    formData?: {
        files?: Array<Blob>;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useModifierServiceGetModifierQAimagesKey = "ModifierServiceGetModifierQAimages";
export const useModifierServiceGetModifierQAimages = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModifierService.getModifierQAimages>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModifierService.getModifierQAimages>>, unknown, Awaited<ReturnType<typeof ModifierService.getModifierQAimages>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModifierServiceGetModifierQAimagesKey, ...(queryKey ?? [{ key }])], queryFn: () => ModifierService.getModifierQAimages(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModifierService.getModifierQAimages>>, TError>, "data"> & {
    data: TData;
};
export const useModifierServiceGetModifierQAimages1Key = "ModifierServiceGetModifierQAimages1";
export const useModifierServiceGetModifierQAimages1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModifierService.getModifierQAimages1>>, TError = unknown>({ key, index }: {
    key: number;
    index: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModifierService.getModifierQAimages1>>, unknown, Awaited<ReturnType<typeof ModifierService.getModifierQAimages1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModifierServiceGetModifierQAimages1Key, ...(queryKey ?? [{ key, index }])], queryFn: () => ModifierService.getModifierQAimages1(key, index), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModifierService.getModifierQAimages1>>, TError>, "data"> & {
    data: TData;
};
export const useModifierServiceGetModifierApprovalHistoryImagesKey = "ModifierServiceGetModifierApprovalHistoryImages";
export const useModifierServiceGetModifierApprovalHistoryImages = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModifierService.getModifierApprovalHistoryImages>>, TError = unknown>({ key, index }: {
    key: number;
    index: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModifierService.getModifierApprovalHistoryImages>>, unknown, Awaited<ReturnType<typeof ModifierService.getModifierApprovalHistoryImages>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModifierServiceGetModifierApprovalHistoryImagesKey, ...(queryKey ?? [{ key, index }])], queryFn: () => ModifierService.getModifierApprovalHistoryImages(key, index), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModifierService.getModifierApprovalHistoryImages>>, TError>, "data"> & {
    data: TData;
};
export const useModifierServiceGetModifierApprovalHistoryKey = "ModifierServiceGetModifierApprovalHistory";
export const useModifierServiceGetModifierApprovalHistory = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModifierService.getModifierApprovalHistory>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModifierService.getModifierApprovalHistory>>, unknown, Awaited<ReturnType<typeof ModifierService.getModifierApprovalHistory>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModifierServiceGetModifierApprovalHistoryKey, ...(queryKey ?? [{ key }])], queryFn: () => ModifierService.getModifierApprovalHistory(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModifierService.getModifierApprovalHistory>>, TError>, "data"> & {
    data: TData;
};
export const useModelServiceGetModelKey = "ModelServiceGetModel";
export const useModelServiceGetModel = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelService.getModel>>, TError = unknown>({ query, top, skip, filter, select, orderby, expand, count }: {
    query?: string;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelService.getModel>>, unknown, Awaited<ReturnType<typeof ModelService.getModel>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelServiceGetModelKey, ...(queryKey ?? [{ query, top, skip, filter, select, orderby, expand, count }])], queryFn: () => ModelService.getModel(query, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelService.getModel>>, TError>, "data"> & {
    data: TData;
};
export const useModelServicePostModel = <TData = Awaited<ReturnType<typeof ModelService.postModel>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModelService.postModel>>, unknown, {
    requestBody?: ModelEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => ModelService.postModel(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModelService.postModel>>, TError, {
    requestBody?: ModelEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useModelServiceGetModel1Key = "ModelServiceGetModel1";
export const useModelServiceGetModel1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelService.getModel1>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelService.getModel1>>, unknown, Awaited<ReturnType<typeof ModelService.getModel1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelServiceGetModel1Key, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => ModelService.getModel1(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelService.getModel1>>, TError>, "data"> & {
    data: TData;
};
export const useModelServicePutModel = <TData = Awaited<ReturnType<typeof ModelService.putModel>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModelService.putModel>>, unknown, {
    key: number;
    requestBody?: ModelEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => ModelService.putModel(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModelService.putModel>>, TError, {
    key: number;
    requestBody?: ModelEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useModelServiceDeleteModel = <TData = Awaited<ReturnType<typeof ModelService.deleteModel>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModelService.deleteModel>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => ModelService.deleteModel(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModelService.deleteModel>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useModelServiceGetModelQueryKey = "ModelServiceGetModelQuery";
export const useModelServiceGetModelQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelService.getModelQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelService.getModelQuery>>, unknown, Awaited<ReturnType<typeof ModelService.getModelQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelServiceGetModelQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => ModelService.getModelQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelService.getModelQuery>>, TError>, "data"> & {
    data: TData;
};
export const useModelServiceGetModelThumbnailKey = "ModelServiceGetModelThumbnail";
export const useModelServiceGetModelThumbnail = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelService.getModelThumbnail>>, TError = unknown>({ key, size, format }: {
    key: number;
    size?: number;
    format?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelService.getModelThumbnail>>, unknown, Awaited<ReturnType<typeof ModelService.getModelThumbnail>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelServiceGetModelThumbnailKey, ...(queryKey ?? [{ key, size, format }])], queryFn: () => ModelService.getModelThumbnail(key, size, format), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelService.getModelThumbnail>>, TError>, "data"> & {
    data: TData;
};
export const useModelServiceGetModelImageKey = "ModelServiceGetModelImage";
export const useModelServiceGetModelImage = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelService.getModelImage>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelService.getModelImage>>, unknown, Awaited<ReturnType<typeof ModelService.getModelImage>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelServiceGetModelImageKey, ...(queryKey ?? [{ key }])], queryFn: () => ModelService.getModelImage(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelService.getModelImage>>, TError>, "data"> & {
    data: TData;
};
export const useModelServicePostModelImage = <TData = Awaited<ReturnType<typeof ModelService.postModelImage>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModelService.postModelImage>>, unknown, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => ModelService.postModelImage(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModelService.postModelImage>>, TError, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useModelServicePostModelQAimages = <TData = Awaited<ReturnType<typeof ModelService.postModelQAimages>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModelService.postModelQAimages>>, unknown, {
    key: number;
    formData?: {
        files?: Array<Blob>;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => ModelService.postModelQAimages(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModelService.postModelQAimages>>, TError, {
    key: number;
    formData?: {
        files?: Array<Blob>;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useModelServiceGetModelQAimagesKey = "ModelServiceGetModelQAimages";
export const useModelServiceGetModelQAimages = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelService.getModelQAimages>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelService.getModelQAimages>>, unknown, Awaited<ReturnType<typeof ModelService.getModelQAimages>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelServiceGetModelQAimagesKey, ...(queryKey ?? [{ key }])], queryFn: () => ModelService.getModelQAimages(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelService.getModelQAimages>>, TError>, "data"> & {
    data: TData;
};
export const useModelServiceGetModelQAimages1Key = "ModelServiceGetModelQAimages1";
export const useModelServiceGetModelQAimages1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelService.getModelQAimages1>>, TError = unknown>({ key, index }: {
    key: number;
    index: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelService.getModelQAimages1>>, unknown, Awaited<ReturnType<typeof ModelService.getModelQAimages1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelServiceGetModelQAimages1Key, ...(queryKey ?? [{ key, index }])], queryFn: () => ModelService.getModelQAimages1(key, index), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelService.getModelQAimages1>>, TError>, "data"> & {
    data: TData;
};
export const useModelServiceGetModelApprovalHistoryImagesKey = "ModelServiceGetModelApprovalHistoryImages";
export const useModelServiceGetModelApprovalHistoryImages = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelService.getModelApprovalHistoryImages>>, TError = unknown>({ key, index }: {
    key: number;
    index: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelService.getModelApprovalHistoryImages>>, unknown, Awaited<ReturnType<typeof ModelService.getModelApprovalHistoryImages>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelServiceGetModelApprovalHistoryImagesKey, ...(queryKey ?? [{ key, index }])], queryFn: () => ModelService.getModelApprovalHistoryImages(key, index), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelService.getModelApprovalHistoryImages>>, TError>, "data"> & {
    data: TData;
};
export const useModelServiceGetModelApprovalHistoryKey = "ModelServiceGetModelApprovalHistory";
export const useModelServiceGetModelApprovalHistory = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelService.getModelApprovalHistory>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelService.getModelApprovalHistory>>, unknown, Awaited<ReturnType<typeof ModelService.getModelApprovalHistory>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelServiceGetModelApprovalHistoryKey, ...(queryKey ?? [{ key }])], queryFn: () => ModelService.getModelApprovalHistory(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelService.getModelApprovalHistory>>, TError>, "data"> & {
    data: TData;
};
export const useModelPackServiceGetModelPackKey = "ModelPackServiceGetModelPack";
export const useModelPackServiceGetModelPack = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelPackService.getModelPack>>, TError = unknown>({ query, top, skip, filter, select, orderby, expand, count }: {
    query?: string;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelPackService.getModelPack>>, unknown, Awaited<ReturnType<typeof ModelPackService.getModelPack>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelPackServiceGetModelPackKey, ...(queryKey ?? [{ query, top, skip, filter, select, orderby, expand, count }])], queryFn: () => ModelPackService.getModelPack(query, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelPackService.getModelPack>>, TError>, "data"> & {
    data: TData;
};
export const useModelPackServicePostModelPack = <TData = Awaited<ReturnType<typeof ModelPackService.postModelPack>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModelPackService.postModelPack>>, unknown, {
    requestBody?: ModelPackEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => ModelPackService.postModelPack(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModelPackService.postModelPack>>, TError, {
    requestBody?: ModelPackEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useModelPackServiceGetModelPack1Key = "ModelPackServiceGetModelPack1";
export const useModelPackServiceGetModelPack1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelPackService.getModelPack1>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelPackService.getModelPack1>>, unknown, Awaited<ReturnType<typeof ModelPackService.getModelPack1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelPackServiceGetModelPack1Key, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => ModelPackService.getModelPack1(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelPackService.getModelPack1>>, TError>, "data"> & {
    data: TData;
};
export const useModelPackServicePutModelPack = <TData = Awaited<ReturnType<typeof ModelPackService.putModelPack>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModelPackService.putModelPack>>, unknown, {
    key: number;
    requestBody?: ModelPackEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => ModelPackService.putModelPack(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModelPackService.putModelPack>>, TError, {
    key: number;
    requestBody?: ModelPackEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useModelPackServiceDeleteModelPack = <TData = Awaited<ReturnType<typeof ModelPackService.deleteModelPack>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModelPackService.deleteModelPack>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => ModelPackService.deleteModelPack(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModelPackService.deleteModelPack>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useModelPackServiceGetModelPackQueryKey = "ModelPackServiceGetModelPackQuery";
export const useModelPackServiceGetModelPackQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelPackService.getModelPackQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelPackService.getModelPackQuery>>, unknown, Awaited<ReturnType<typeof ModelPackService.getModelPackQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelPackServiceGetModelPackQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => ModelPackService.getModelPackQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelPackService.getModelPackQuery>>, TError>, "data"> & {
    data: TData;
};
export const useModelPackServiceGetModelPackThumbnailKey = "ModelPackServiceGetModelPackThumbnail";
export const useModelPackServiceGetModelPackThumbnail = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelPackService.getModelPackThumbnail>>, TError = unknown>({ key, size, format }: {
    key: number;
    size?: number;
    format?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelPackService.getModelPackThumbnail>>, unknown, Awaited<ReturnType<typeof ModelPackService.getModelPackThumbnail>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelPackServiceGetModelPackThumbnailKey, ...(queryKey ?? [{ key, size, format }])], queryFn: () => ModelPackService.getModelPackThumbnail(key, size, format), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelPackService.getModelPackThumbnail>>, TError>, "data"> & {
    data: TData;
};
export const useModelPackServiceGetModelPackImageKey = "ModelPackServiceGetModelPackImage";
export const useModelPackServiceGetModelPackImage = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelPackService.getModelPackImage>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelPackService.getModelPackImage>>, unknown, Awaited<ReturnType<typeof ModelPackService.getModelPackImage>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelPackServiceGetModelPackImageKey, ...(queryKey ?? [{ key }])], queryFn: () => ModelPackService.getModelPackImage(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelPackService.getModelPackImage>>, TError>, "data"> & {
    data: TData;
};
export const useModelPackServicePostModelPackImage = <TData = Awaited<ReturnType<typeof ModelPackService.postModelPackImage>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModelPackService.postModelPackImage>>, unknown, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => ModelPackService.postModelPackImage(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModelPackService.postModelPackImage>>, TError, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useModelPackServicePostModelPackQAimages = <TData = Awaited<ReturnType<typeof ModelPackService.postModelPackQAimages>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ModelPackService.postModelPackQAimages>>, unknown, {
    key: number;
    formData?: {
        files?: Array<Blob>;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => ModelPackService.postModelPackQAimages(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ModelPackService.postModelPackQAimages>>, TError, {
    key: number;
    formData?: {
        files?: Array<Blob>;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useModelPackServiceGetModelPackQAimagesKey = "ModelPackServiceGetModelPackQAimages";
export const useModelPackServiceGetModelPackQAimages = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelPackService.getModelPackQAimages>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelPackService.getModelPackQAimages>>, unknown, Awaited<ReturnType<typeof ModelPackService.getModelPackQAimages>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelPackServiceGetModelPackQAimagesKey, ...(queryKey ?? [{ key }])], queryFn: () => ModelPackService.getModelPackQAimages(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelPackService.getModelPackQAimages>>, TError>, "data"> & {
    data: TData;
};
export const useModelPackServiceGetModelPackQAimages1Key = "ModelPackServiceGetModelPackQAimages1";
export const useModelPackServiceGetModelPackQAimages1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelPackService.getModelPackQAimages1>>, TError = unknown>({ key, index }: {
    key: number;
    index: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelPackService.getModelPackQAimages1>>, unknown, Awaited<ReturnType<typeof ModelPackService.getModelPackQAimages1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelPackServiceGetModelPackQAimages1Key, ...(queryKey ?? [{ key, index }])], queryFn: () => ModelPackService.getModelPackQAimages1(key, index), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelPackService.getModelPackQAimages1>>, TError>, "data"> & {
    data: TData;
};
export const useModelPackServiceGetModelPackApprovalHistoryImagesKey = "ModelPackServiceGetModelPackApprovalHistoryImages";
export const useModelPackServiceGetModelPackApprovalHistoryImages = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelPackService.getModelPackApprovalHistoryImages>>, TError = unknown>({ key, index }: {
    key: number;
    index: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelPackService.getModelPackApprovalHistoryImages>>, unknown, Awaited<ReturnType<typeof ModelPackService.getModelPackApprovalHistoryImages>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelPackServiceGetModelPackApprovalHistoryImagesKey, ...(queryKey ?? [{ key, index }])], queryFn: () => ModelPackService.getModelPackApprovalHistoryImages(key, index), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelPackService.getModelPackApprovalHistoryImages>>, TError>, "data"> & {
    data: TData;
};
export const useModelPackServiceGetModelPackApprovalHistoryKey = "ModelPackServiceGetModelPackApprovalHistory";
export const useModelPackServiceGetModelPackApprovalHistory = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ModelPackService.getModelPackApprovalHistory>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ModelPackService.getModelPackApprovalHistory>>, unknown, Awaited<ReturnType<typeof ModelPackService.getModelPackApprovalHistory>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useModelPackServiceGetModelPackApprovalHistoryKey, ...(queryKey ?? [{ key }])], queryFn: () => ModelPackService.getModelPackApprovalHistory(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ModelPackService.getModelPackApprovalHistory>>, TError>, "data"> & {
    data: TData;
};
export const useMetadataServiceMetadataKey = "MetadataServiceMetadata";
export const useMetadataServiceMetadata = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MetadataService.metadata>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MetadataService.metadata>>, unknown, Awaited<ReturnType<typeof MetadataService.metadata>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMetadataServiceMetadataKey, ...(queryKey ?? [])], queryFn: () => MetadataService.metadata(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MetadataService.metadata>>, TError>, "data"> & {
    data: TData;
};
export const useMetadataServiceSearchMetadataKey = "MetadataServiceSearchMetadata";
export const useMetadataServiceSearchMetadata = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MetadataService.searchMetadata>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MetadataService.searchMetadata>>, unknown, Awaited<ReturnType<typeof MetadataService.searchMetadata>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMetadataServiceSearchMetadataKey, ...(queryKey ?? [])], queryFn: () => MetadataService.searchMetadata(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MetadataService.searchMetadata>>, TError>, "data"> & {
    data: TData;
};
export const useMetadataServiceInternalMetadataKey = "MetadataServiceInternalMetadata";
export const useMetadataServiceInternalMetadata = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MetadataService.internalMetadata>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MetadataService.internalMetadata>>, unknown, Awaited<ReturnType<typeof MetadataService.internalMetadata>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMetadataServiceInternalMetadataKey, ...(queryKey ?? [])], queryFn: () => MetadataService.internalMetadata(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MetadataService.internalMetadata>>, TError>, "data"> & {
    data: TData;
};
export const useMetadataServiceGetKey = "MetadataServiceGet";
export const useMetadataServiceGet = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MetadataService.get>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MetadataService.get>>, unknown, Awaited<ReturnType<typeof MetadataService.get>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMetadataServiceGetKey, ...(queryKey ?? [])], queryFn: () => MetadataService.get(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MetadataService.get>>, TError>, "data"> & {
    data: TData;
};
export const useMetadataServiceSearchKey = "MetadataServiceSearch";
export const useMetadataServiceSearch = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MetadataService.search>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MetadataService.search>>, unknown, Awaited<ReturnType<typeof MetadataService.search>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMetadataServiceSearchKey, ...(queryKey ?? [])], queryFn: () => MetadataService.search(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MetadataService.search>>, TError>, "data"> & {
    data: TData;
};
export const useMetadataServiceInternalKey = "MetadataServiceInternal";
export const useMetadataServiceInternal = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MetadataService.internal>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MetadataService.internal>>, unknown, Awaited<ReturnType<typeof MetadataService.internal>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMetadataServiceInternalKey, ...(queryKey ?? [])], queryFn: () => MetadataService.internal(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MetadataService.internal>>, TError>, "data"> & {
    data: TData;
};
export const useMaterialServiceGetMaterialKey = "MaterialServiceGetMaterial";
export const useMaterialServiceGetMaterial = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MaterialService.getMaterial>>, TError = unknown>({ query, top, skip, filter, select, orderby, expand, count }: {
    query?: string;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MaterialService.getMaterial>>, unknown, Awaited<ReturnType<typeof MaterialService.getMaterial>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMaterialServiceGetMaterialKey, ...(queryKey ?? [{ query, top, skip, filter, select, orderby, expand, count }])], queryFn: () => MaterialService.getMaterial(query, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MaterialService.getMaterial>>, TError>, "data"> & {
    data: TData;
};
export const useMaterialServicePostMaterial = <TData = Awaited<ReturnType<typeof MaterialService.postMaterial>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof MaterialService.postMaterial>>, unknown, {
    requestBody?: MaterialEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => MaterialService.postMaterial(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof MaterialService.postMaterial>>, TError, {
    requestBody?: MaterialEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useMaterialServiceGetMaterial1Key = "MaterialServiceGetMaterial1";
export const useMaterialServiceGetMaterial1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MaterialService.getMaterial1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MaterialService.getMaterial1>>, unknown, Awaited<ReturnType<typeof MaterialService.getMaterial1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMaterialServiceGetMaterial1Key, ...(queryKey ?? [{ key }])], queryFn: () => MaterialService.getMaterial1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MaterialService.getMaterial1>>, TError>, "data"> & {
    data: TData;
};
export const useMaterialServicePutMaterial = <TData = Awaited<ReturnType<typeof MaterialService.putMaterial>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof MaterialService.putMaterial>>, unknown, {
    key: number;
    requestBody?: MaterialEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => MaterialService.putMaterial(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof MaterialService.putMaterial>>, TError, {
    key: number;
    requestBody?: MaterialEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useMaterialServiceDeleteMaterial = <TData = Awaited<ReturnType<typeof MaterialService.deleteMaterial>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof MaterialService.deleteMaterial>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => MaterialService.deleteMaterial(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof MaterialService.deleteMaterial>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useMaterialServiceGetMaterialQueryKey = "MaterialServiceGetMaterialQuery";
export const useMaterialServiceGetMaterialQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MaterialService.getMaterialQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MaterialService.getMaterialQuery>>, unknown, Awaited<ReturnType<typeof MaterialService.getMaterialQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMaterialServiceGetMaterialQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => MaterialService.getMaterialQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MaterialService.getMaterialQuery>>, TError>, "data"> & {
    data: TData;
};
export const useMaterialServiceGetMaterialThumbnailKey = "MaterialServiceGetMaterialThumbnail";
export const useMaterialServiceGetMaterialThumbnail = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MaterialService.getMaterialThumbnail>>, TError = unknown>({ key, size, format }: {
    key: number;
    size?: number;
    format?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MaterialService.getMaterialThumbnail>>, unknown, Awaited<ReturnType<typeof MaterialService.getMaterialThumbnail>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMaterialServiceGetMaterialThumbnailKey, ...(queryKey ?? [{ key, size, format }])], queryFn: () => MaterialService.getMaterialThumbnail(key, size, format), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MaterialService.getMaterialThumbnail>>, TError>, "data"> & {
    data: TData;
};
export const useMaterialServiceGetMaterialImageKey = "MaterialServiceGetMaterialImage";
export const useMaterialServiceGetMaterialImage = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MaterialService.getMaterialImage>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MaterialService.getMaterialImage>>, unknown, Awaited<ReturnType<typeof MaterialService.getMaterialImage>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMaterialServiceGetMaterialImageKey, ...(queryKey ?? [{ key }])], queryFn: () => MaterialService.getMaterialImage(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MaterialService.getMaterialImage>>, TError>, "data"> & {
    data: TData;
};
export const useMaterialServicePostMaterialImage = <TData = Awaited<ReturnType<typeof MaterialService.postMaterialImage>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof MaterialService.postMaterialImage>>, unknown, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => MaterialService.postMaterialImage(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof MaterialService.postMaterialImage>>, TError, {
    key: number;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useMaterialServicePostMaterialQAimages = <TData = Awaited<ReturnType<typeof MaterialService.postMaterialQAimages>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof MaterialService.postMaterialQAimages>>, unknown, {
    key: number;
    formData?: {
        files?: Array<Blob>;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, formData }) => MaterialService.postMaterialQAimages(key, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof MaterialService.postMaterialQAimages>>, TError, {
    key: number;
    formData?: {
        files?: Array<Blob>;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useMaterialServiceGetMaterialQAimagesKey = "MaterialServiceGetMaterialQAimages";
export const useMaterialServiceGetMaterialQAimages = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MaterialService.getMaterialQAimages>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MaterialService.getMaterialQAimages>>, unknown, Awaited<ReturnType<typeof MaterialService.getMaterialQAimages>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMaterialServiceGetMaterialQAimagesKey, ...(queryKey ?? [{ key }])], queryFn: () => MaterialService.getMaterialQAimages(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MaterialService.getMaterialQAimages>>, TError>, "data"> & {
    data: TData;
};
export const useMaterialServiceGetMaterialQAimages1Key = "MaterialServiceGetMaterialQAimages1";
export const useMaterialServiceGetMaterialQAimages1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MaterialService.getMaterialQAimages1>>, TError = unknown>({ key, index }: {
    key: number;
    index: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MaterialService.getMaterialQAimages1>>, unknown, Awaited<ReturnType<typeof MaterialService.getMaterialQAimages1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMaterialServiceGetMaterialQAimages1Key, ...(queryKey ?? [{ key, index }])], queryFn: () => MaterialService.getMaterialQAimages1(key, index), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MaterialService.getMaterialQAimages1>>, TError>, "data"> & {
    data: TData;
};
export const useMaterialServiceGetMaterialApprovalHistoryImagesKey = "MaterialServiceGetMaterialApprovalHistoryImages";
export const useMaterialServiceGetMaterialApprovalHistoryImages = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MaterialService.getMaterialApprovalHistoryImages>>, TError = unknown>({ key, index }: {
    key: number;
    index: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MaterialService.getMaterialApprovalHistoryImages>>, unknown, Awaited<ReturnType<typeof MaterialService.getMaterialApprovalHistoryImages>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMaterialServiceGetMaterialApprovalHistoryImagesKey, ...(queryKey ?? [{ key, index }])], queryFn: () => MaterialService.getMaterialApprovalHistoryImages(key, index), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MaterialService.getMaterialApprovalHistoryImages>>, TError>, "data"> & {
    data: TData;
};
export const useMaterialServiceGetMaterialApprovalHistoryKey = "MaterialServiceGetMaterialApprovalHistory";
export const useMaterialServiceGetMaterialApprovalHistory = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof MaterialService.getMaterialApprovalHistory>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof MaterialService.getMaterialApprovalHistory>>, unknown, Awaited<ReturnType<typeof MaterialService.getMaterialApprovalHistory>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useMaterialServiceGetMaterialApprovalHistoryKey, ...(queryKey ?? [{ key }])], queryFn: () => MaterialService.getMaterialApprovalHistory(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof MaterialService.getMaterialApprovalHistory>>, TError>, "data"> & {
    data: TData;
};
export const useInternalVideoSettingServiceGetInternalVideoSettingKey = "InternalVideoSettingServiceGetInternalVideoSetting";
export const useInternalVideoSettingServiceGetInternalVideoSetting = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalVideoSettingService.getInternalVideoSetting>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalVideoSettingService.getInternalVideoSetting>>, unknown, Awaited<ReturnType<typeof InternalVideoSettingService.getInternalVideoSetting>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalVideoSettingServiceGetInternalVideoSettingKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalVideoSettingService.getInternalVideoSetting(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalVideoSettingService.getInternalVideoSetting>>, TError>, "data"> & {
    data: TData;
};
export const useInternalVideoSettingServicePostInternalVideoSetting = <TData = Awaited<ReturnType<typeof InternalVideoSettingService.postInternalVideoSetting>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalVideoSettingService.postInternalVideoSetting>>, unknown, {
    requestBody?: InternalVideoSettingDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalVideoSettingService.postInternalVideoSetting(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalVideoSettingService.postInternalVideoSetting>>, TError, {
    requestBody?: InternalVideoSettingDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalVideoSettingServiceGetInternalVideoSetting1Key = "InternalVideoSettingServiceGetInternalVideoSetting1";
export const useInternalVideoSettingServiceGetInternalVideoSetting1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalVideoSettingService.getInternalVideoSetting1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalVideoSettingService.getInternalVideoSetting1>>, unknown, Awaited<ReturnType<typeof InternalVideoSettingService.getInternalVideoSetting1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalVideoSettingServiceGetInternalVideoSetting1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalVideoSettingService.getInternalVideoSetting1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalVideoSettingService.getInternalVideoSetting1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalVideoSettingServicePutInternalVideoSetting = <TData = Awaited<ReturnType<typeof InternalVideoSettingService.putInternalVideoSetting>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalVideoSettingService.putInternalVideoSetting>>, unknown, {
    key: number;
    requestBody?: InternalVideoSettingDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalVideoSettingService.putInternalVideoSetting(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalVideoSettingService.putInternalVideoSetting>>, TError, {
    key: number;
    requestBody?: InternalVideoSettingDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalVideoSettingServiceDeleteInternalVideoSetting = <TData = Awaited<ReturnType<typeof InternalVideoSettingService.deleteInternalVideoSetting>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalVideoSettingService.deleteInternalVideoSetting>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalVideoSettingService.deleteInternalVideoSetting(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalVideoSettingService.deleteInternalVideoSetting>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalVideoSettingServiceGetInternalVideoSettingQueryKey = "InternalVideoSettingServiceGetInternalVideoSettingQuery";
export const useInternalVideoSettingServiceGetInternalVideoSettingQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalVideoSettingService.getInternalVideoSettingQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalVideoSettingService.getInternalVideoSettingQuery>>, unknown, Awaited<ReturnType<typeof InternalVideoSettingService.getInternalVideoSettingQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalVideoSettingServiceGetInternalVideoSettingQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalVideoSettingService.getInternalVideoSettingQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalVideoSettingService.getInternalVideoSettingQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUserRenderLimitServiceGetInternalUserRenderLimitKey = "InternalUserRenderLimitServiceGetInternalUserRenderLimit";
export const useInternalUserRenderLimitServiceGetInternalUserRenderLimit = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUserRenderLimitService.getInternalUserRenderLimit>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUserRenderLimitService.getInternalUserRenderLimit>>, unknown, Awaited<ReturnType<typeof InternalUserRenderLimitService.getInternalUserRenderLimit>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUserRenderLimitServiceGetInternalUserRenderLimitKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalUserRenderLimitService.getInternalUserRenderLimit(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUserRenderLimitService.getInternalUserRenderLimit>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUserRenderLimitServicePostInternalUserRenderLimit = <TData = Awaited<ReturnType<typeof InternalUserRenderLimitService.postInternalUserRenderLimit>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUserRenderLimitService.postInternalUserRenderLimit>>, unknown, {
    requestBody?: InternalUserRenderLimitDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalUserRenderLimitService.postInternalUserRenderLimit(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUserRenderLimitService.postInternalUserRenderLimit>>, TError, {
    requestBody?: InternalUserRenderLimitDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUserRenderLimitServiceGetInternalUserRenderLimit1Key = "InternalUserRenderLimitServiceGetInternalUserRenderLimit1";
export const useInternalUserRenderLimitServiceGetInternalUserRenderLimit1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUserRenderLimitService.getInternalUserRenderLimit1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUserRenderLimitService.getInternalUserRenderLimit1>>, unknown, Awaited<ReturnType<typeof InternalUserRenderLimitService.getInternalUserRenderLimit1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUserRenderLimitServiceGetInternalUserRenderLimit1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalUserRenderLimitService.getInternalUserRenderLimit1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUserRenderLimitService.getInternalUserRenderLimit1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUserRenderLimitServicePutInternalUserRenderLimit = <TData = Awaited<ReturnType<typeof InternalUserRenderLimitService.putInternalUserRenderLimit>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUserRenderLimitService.putInternalUserRenderLimit>>, unknown, {
    key: number;
    requestBody?: InternalUserRenderLimitDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalUserRenderLimitService.putInternalUserRenderLimit(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUserRenderLimitService.putInternalUserRenderLimit>>, TError, {
    key: number;
    requestBody?: InternalUserRenderLimitDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUserRenderLimitServiceDeleteInternalUserRenderLimit = <TData = Awaited<ReturnType<typeof InternalUserRenderLimitService.deleteInternalUserRenderLimit>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUserRenderLimitService.deleteInternalUserRenderLimit>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalUserRenderLimitService.deleteInternalUserRenderLimit(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUserRenderLimitService.deleteInternalUserRenderLimit>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUserRenderLimitServiceGetInternalUserRenderLimitQueryKey = "InternalUserRenderLimitServiceGetInternalUserRenderLimitQuery";
export const useInternalUserRenderLimitServiceGetInternalUserRenderLimitQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUserRenderLimitService.getInternalUserRenderLimitQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUserRenderLimitService.getInternalUserRenderLimitQuery>>, unknown, Awaited<ReturnType<typeof InternalUserRenderLimitService.getInternalUserRenderLimitQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUserRenderLimitServiceGetInternalUserRenderLimitQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalUserRenderLimitService.getInternalUserRenderLimitQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUserRenderLimitService.getInternalUserRenderLimitQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUserAssetFilterServiceGetInternalUserAssetFilterKey = "InternalUserAssetFilterServiceGetInternalUserAssetFilter";
export const useInternalUserAssetFilterServiceGetInternalUserAssetFilter = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUserAssetFilterService.getInternalUserAssetFilter>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUserAssetFilterService.getInternalUserAssetFilter>>, unknown, Awaited<ReturnType<typeof InternalUserAssetFilterService.getInternalUserAssetFilter>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUserAssetFilterServiceGetInternalUserAssetFilterKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalUserAssetFilterService.getInternalUserAssetFilter(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUserAssetFilterService.getInternalUserAssetFilter>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUserAssetFilterServicePostInternalUserAssetFilter = <TData = Awaited<ReturnType<typeof InternalUserAssetFilterService.postInternalUserAssetFilter>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUserAssetFilterService.postInternalUserAssetFilter>>, unknown, {
    requestBody?: InternalUserAssetFilterDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalUserAssetFilterService.postInternalUserAssetFilter(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUserAssetFilterService.postInternalUserAssetFilter>>, TError, {
    requestBody?: InternalUserAssetFilterDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUserAssetFilterServiceGetInternalUserAssetFilter1Key = "InternalUserAssetFilterServiceGetInternalUserAssetFilter1";
export const useInternalUserAssetFilterServiceGetInternalUserAssetFilter1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUserAssetFilterService.getInternalUserAssetFilter1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUserAssetFilterService.getInternalUserAssetFilter1>>, unknown, Awaited<ReturnType<typeof InternalUserAssetFilterService.getInternalUserAssetFilter1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUserAssetFilterServiceGetInternalUserAssetFilter1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalUserAssetFilterService.getInternalUserAssetFilter1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUserAssetFilterService.getInternalUserAssetFilter1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUserAssetFilterServicePutInternalUserAssetFilter = <TData = Awaited<ReturnType<typeof InternalUserAssetFilterService.putInternalUserAssetFilter>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUserAssetFilterService.putInternalUserAssetFilter>>, unknown, {
    key: number;
    requestBody?: InternalUserAssetFilterDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalUserAssetFilterService.putInternalUserAssetFilter(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUserAssetFilterService.putInternalUserAssetFilter>>, TError, {
    key: number;
    requestBody?: InternalUserAssetFilterDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUserAssetFilterServiceDeleteInternalUserAssetFilter = <TData = Awaited<ReturnType<typeof InternalUserAssetFilterService.deleteInternalUserAssetFilter>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUserAssetFilterService.deleteInternalUserAssetFilter>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalUserAssetFilterService.deleteInternalUserAssetFilter(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUserAssetFilterService.deleteInternalUserAssetFilter>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUserAssetFilterServiceGetInternalUserAssetFilterQueryKey = "InternalUserAssetFilterServiceGetInternalUserAssetFilterQuery";
export const useInternalUserAssetFilterServiceGetInternalUserAssetFilterQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUserAssetFilterService.getInternalUserAssetFilterQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUserAssetFilterService.getInternalUserAssetFilterQuery>>, unknown, Awaited<ReturnType<typeof InternalUserAssetFilterService.getInternalUserAssetFilterQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUserAssetFilterServiceGetInternalUserAssetFilterQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalUserAssetFilterService.getInternalUserAssetFilterQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUserAssetFilterService.getInternalUserAssetFilterQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUserAssetFilterPresetServiceGetInternalUserAssetFilterPresetKey = "InternalUserAssetFilterPresetServiceGetInternalUserAssetFilterPreset";
export const useInternalUserAssetFilterPresetServiceGetInternalUserAssetFilterPreset = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.getInternalUserAssetFilterPreset>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.getInternalUserAssetFilterPreset>>, unknown, Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.getInternalUserAssetFilterPreset>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUserAssetFilterPresetServiceGetInternalUserAssetFilterPresetKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalUserAssetFilterPresetService.getInternalUserAssetFilterPreset(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.getInternalUserAssetFilterPreset>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUserAssetFilterPresetServicePostInternalUserAssetFilterPreset = <TData = Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.postInternalUserAssetFilterPreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.postInternalUserAssetFilterPreset>>, unknown, {
    requestBody?: InternalUserAssetFilterPresetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalUserAssetFilterPresetService.postInternalUserAssetFilterPreset(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.postInternalUserAssetFilterPreset>>, TError, {
    requestBody?: InternalUserAssetFilterPresetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUserAssetFilterPresetServiceGetInternalUserAssetFilterPreset1Key = "InternalUserAssetFilterPresetServiceGetInternalUserAssetFilterPreset1";
export const useInternalUserAssetFilterPresetServiceGetInternalUserAssetFilterPreset1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.getInternalUserAssetFilterPreset1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.getInternalUserAssetFilterPreset1>>, unknown, Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.getInternalUserAssetFilterPreset1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUserAssetFilterPresetServiceGetInternalUserAssetFilterPreset1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalUserAssetFilterPresetService.getInternalUserAssetFilterPreset1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.getInternalUserAssetFilterPreset1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUserAssetFilterPresetServicePutInternalUserAssetFilterPreset = <TData = Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.putInternalUserAssetFilterPreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.putInternalUserAssetFilterPreset>>, unknown, {
    key: number;
    requestBody?: InternalUserAssetFilterPresetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalUserAssetFilterPresetService.putInternalUserAssetFilterPreset(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.putInternalUserAssetFilterPreset>>, TError, {
    key: number;
    requestBody?: InternalUserAssetFilterPresetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUserAssetFilterPresetServiceDeleteInternalUserAssetFilterPreset = <TData = Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.deleteInternalUserAssetFilterPreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.deleteInternalUserAssetFilterPreset>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalUserAssetFilterPresetService.deleteInternalUserAssetFilterPreset(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.deleteInternalUserAssetFilterPreset>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUserAssetFilterPresetServiceGetInternalUserAssetFilterPresetQueryKey = "InternalUserAssetFilterPresetServiceGetInternalUserAssetFilterPresetQuery";
export const useInternalUserAssetFilterPresetServiceGetInternalUserAssetFilterPresetQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.getInternalUserAssetFilterPresetQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.getInternalUserAssetFilterPresetQuery>>, unknown, Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.getInternalUserAssetFilterPresetQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUserAssetFilterPresetServiceGetInternalUserAssetFilterPresetQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalUserAssetFilterPresetService.getInternalUserAssetFilterPresetQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUserAssetFilterPresetService.getInternalUserAssetFilterPresetQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUisceneSurfaceHotspotServiceGetInternalUisceneSurfaceHotspotKey = "InternalUisceneSurfaceHotspotServiceGetInternalUisceneSurfaceHotspot";
export const useInternalUisceneSurfaceHotspotServiceGetInternalUisceneSurfaceHotspot = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspot>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspot>>, unknown, Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspot>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUisceneSurfaceHotspotServiceGetInternalUisceneSurfaceHotspotKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspot(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspot>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUisceneSurfaceHotspotServicePostInternalUisceneSurfaceHotspot = <TData = Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.postInternalUisceneSurfaceHotspot>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.postInternalUisceneSurfaceHotspot>>, unknown, {
    requestBody?: InternalUisceneSurfaceHotspotDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalUisceneSurfaceHotspotService.postInternalUisceneSurfaceHotspot(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.postInternalUisceneSurfaceHotspot>>, TError, {
    requestBody?: InternalUisceneSurfaceHotspotDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUisceneSurfaceHotspotServiceGetInternalUisceneSurfaceHotspot1Key = "InternalUisceneSurfaceHotspotServiceGetInternalUisceneSurfaceHotspot1";
export const useInternalUisceneSurfaceHotspotServiceGetInternalUisceneSurfaceHotspot1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspot1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspot1>>, unknown, Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspot1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUisceneSurfaceHotspotServiceGetInternalUisceneSurfaceHotspot1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspot1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspot1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUisceneSurfaceHotspotServicePutInternalUisceneSurfaceHotspot = <TData = Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.putInternalUisceneSurfaceHotspot>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.putInternalUisceneSurfaceHotspot>>, unknown, {
    key: number;
    requestBody?: InternalUisceneSurfaceHotspotDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalUisceneSurfaceHotspotService.putInternalUisceneSurfaceHotspot(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.putInternalUisceneSurfaceHotspot>>, TError, {
    key: number;
    requestBody?: InternalUisceneSurfaceHotspotDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUisceneSurfaceHotspotServiceDeleteInternalUisceneSurfaceHotspot = <TData = Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.deleteInternalUisceneSurfaceHotspot>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.deleteInternalUisceneSurfaceHotspot>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalUisceneSurfaceHotspotService.deleteInternalUisceneSurfaceHotspot(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.deleteInternalUisceneSurfaceHotspot>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUisceneSurfaceHotspotServiceGetInternalUisceneSurfaceHotspotQueryKey = "InternalUisceneSurfaceHotspotServiceGetInternalUisceneSurfaceHotspotQuery";
export const useInternalUisceneSurfaceHotspotServiceGetInternalUisceneSurfaceHotspotQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspotQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspotQuery>>, unknown, Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspotQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUisceneSurfaceHotspotServiceGetInternalUisceneSurfaceHotspotQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspotQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUisceneSurfaceHotspotService.getInternalUisceneSurfaceHotspotQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUiscenePropsetHotspotServiceGetInternalUiscenePropsetHotspotKey = "InternalUiscenePropsetHotspotServiceGetInternalUiscenePropsetHotspot";
export const useInternalUiscenePropsetHotspotServiceGetInternalUiscenePropsetHotspot = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspot>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspot>>, unknown, Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspot>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUiscenePropsetHotspotServiceGetInternalUiscenePropsetHotspotKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspot(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspot>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUiscenePropsetHotspotServicePostInternalUiscenePropsetHotspot = <TData = Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.postInternalUiscenePropsetHotspot>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.postInternalUiscenePropsetHotspot>>, unknown, {
    requestBody?: InternalUiscenePropsetHotspotDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalUiscenePropsetHotspotService.postInternalUiscenePropsetHotspot(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.postInternalUiscenePropsetHotspot>>, TError, {
    requestBody?: InternalUiscenePropsetHotspotDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUiscenePropsetHotspotServiceGetInternalUiscenePropsetHotspot1Key = "InternalUiscenePropsetHotspotServiceGetInternalUiscenePropsetHotspot1";
export const useInternalUiscenePropsetHotspotServiceGetInternalUiscenePropsetHotspot1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspot1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspot1>>, unknown, Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspot1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUiscenePropsetHotspotServiceGetInternalUiscenePropsetHotspot1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspot1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspot1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUiscenePropsetHotspotServicePutInternalUiscenePropsetHotspot = <TData = Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.putInternalUiscenePropsetHotspot>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.putInternalUiscenePropsetHotspot>>, unknown, {
    key: number;
    requestBody?: InternalUiscenePropsetHotspotDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalUiscenePropsetHotspotService.putInternalUiscenePropsetHotspot(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.putInternalUiscenePropsetHotspot>>, TError, {
    key: number;
    requestBody?: InternalUiscenePropsetHotspotDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUiscenePropsetHotspotServiceDeleteInternalUiscenePropsetHotspot = <TData = Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.deleteInternalUiscenePropsetHotspot>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.deleteInternalUiscenePropsetHotspot>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalUiscenePropsetHotspotService.deleteInternalUiscenePropsetHotspot(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.deleteInternalUiscenePropsetHotspot>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUiscenePropsetHotspotServiceGetInternalUiscenePropsetHotspotQueryKey = "InternalUiscenePropsetHotspotServiceGetInternalUiscenePropsetHotspotQuery";
export const useInternalUiscenePropsetHotspotServiceGetInternalUiscenePropsetHotspotQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspotQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspotQuery>>, unknown, Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspotQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUiscenePropsetHotspotServiceGetInternalUiscenePropsetHotspotQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspotQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUiscenePropsetHotspotService.getInternalUiscenePropsetHotspotQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUiscenePlacementpointHotspotServiceGetInternalUiscenePlacementpointHotspotKey = "InternalUiscenePlacementpointHotspotServiceGetInternalUiscenePlacementpointHotspot";
export const useInternalUiscenePlacementpointHotspotServiceGetInternalUiscenePlacementpointHotspot = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspot>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspot>>, unknown, Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspot>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUiscenePlacementpointHotspotServiceGetInternalUiscenePlacementpointHotspotKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspot(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspot>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUiscenePlacementpointHotspotServicePostInternalUiscenePlacementpointHotspot = <TData = Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.postInternalUiscenePlacementpointHotspot>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.postInternalUiscenePlacementpointHotspot>>, unknown, {
    requestBody?: InternalUiscenePlacementpointHotspotDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalUiscenePlacementpointHotspotService.postInternalUiscenePlacementpointHotspot(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.postInternalUiscenePlacementpointHotspot>>, TError, {
    requestBody?: InternalUiscenePlacementpointHotspotDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUiscenePlacementpointHotspotServiceGetInternalUiscenePlacementpointHotspot1Key = "InternalUiscenePlacementpointHotspotServiceGetInternalUiscenePlacementpointHotspot1";
export const useInternalUiscenePlacementpointHotspotServiceGetInternalUiscenePlacementpointHotspot1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspot1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspot1>>, unknown, Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspot1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUiscenePlacementpointHotspotServiceGetInternalUiscenePlacementpointHotspot1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspot1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspot1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUiscenePlacementpointHotspotServicePutInternalUiscenePlacementpointHotspot = <TData = Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.putInternalUiscenePlacementpointHotspot>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.putInternalUiscenePlacementpointHotspot>>, unknown, {
    key: number;
    requestBody?: InternalUiscenePlacementpointHotspotDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalUiscenePlacementpointHotspotService.putInternalUiscenePlacementpointHotspot(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.putInternalUiscenePlacementpointHotspot>>, TError, {
    key: number;
    requestBody?: InternalUiscenePlacementpointHotspotDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUiscenePlacementpointHotspotServiceDeleteInternalUiscenePlacementpointHotspot = <TData = Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.deleteInternalUiscenePlacementpointHotspot>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.deleteInternalUiscenePlacementpointHotspot>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalUiscenePlacementpointHotspotService.deleteInternalUiscenePlacementpointHotspot(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.deleteInternalUiscenePlacementpointHotspot>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUiscenePlacementpointHotspotServiceGetInternalUiscenePlacementpointHotspotQueryKey = "InternalUiscenePlacementpointHotspotServiceGetInternalUiscenePlacementpointHotspotQuery";
export const useInternalUiscenePlacementpointHotspotServiceGetInternalUiscenePlacementpointHotspotQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspotQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspotQuery>>, unknown, Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspotQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUiscenePlacementpointHotspotServiceGetInternalUiscenePlacementpointHotspotQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspotQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUiscenePlacementpointHotspotService.getInternalUiscenePlacementpointHotspotQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUisceneModifierTargetHotspotServiceGetInternalUisceneModifierTargetHotspotKey = "InternalUisceneModifierTargetHotspotServiceGetInternalUisceneModifierTargetHotspot";
export const useInternalUisceneModifierTargetHotspotServiceGetInternalUisceneModifierTargetHotspot = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspot>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspot>>, unknown, Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspot>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUisceneModifierTargetHotspotServiceGetInternalUisceneModifierTargetHotspotKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspot(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspot>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUisceneModifierTargetHotspotServicePostInternalUisceneModifierTargetHotspot = <TData = Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.postInternalUisceneModifierTargetHotspot>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.postInternalUisceneModifierTargetHotspot>>, unknown, {
    requestBody?: InternalUisceneModifierTargetHotspotDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalUisceneModifierTargetHotspotService.postInternalUisceneModifierTargetHotspot(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.postInternalUisceneModifierTargetHotspot>>, TError, {
    requestBody?: InternalUisceneModifierTargetHotspotDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUisceneModifierTargetHotspotServiceGetInternalUisceneModifierTargetHotspot1Key = "InternalUisceneModifierTargetHotspotServiceGetInternalUisceneModifierTargetHotspot1";
export const useInternalUisceneModifierTargetHotspotServiceGetInternalUisceneModifierTargetHotspot1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspot1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspot1>>, unknown, Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspot1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUisceneModifierTargetHotspotServiceGetInternalUisceneModifierTargetHotspot1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspot1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspot1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalUisceneModifierTargetHotspotServicePutInternalUisceneModifierTargetHotspot = <TData = Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.putInternalUisceneModifierTargetHotspot>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.putInternalUisceneModifierTargetHotspot>>, unknown, {
    key: number;
    requestBody?: InternalUisceneModifierTargetHotspotDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalUisceneModifierTargetHotspotService.putInternalUisceneModifierTargetHotspot(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.putInternalUisceneModifierTargetHotspot>>, TError, {
    key: number;
    requestBody?: InternalUisceneModifierTargetHotspotDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUisceneModifierTargetHotspotServiceDeleteInternalUisceneModifierTargetHotspot = <TData = Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.deleteInternalUisceneModifierTargetHotspot>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.deleteInternalUisceneModifierTargetHotspot>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalUisceneModifierTargetHotspotService.deleteInternalUisceneModifierTargetHotspot(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.deleteInternalUisceneModifierTargetHotspot>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalUisceneModifierTargetHotspotServiceGetInternalUisceneModifierTargetHotspotQueryKey = "InternalUisceneModifierTargetHotspotServiceGetInternalUisceneModifierTargetHotspotQuery";
export const useInternalUisceneModifierTargetHotspotServiceGetInternalUisceneModifierTargetHotspotQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspotQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspotQuery>>, unknown, Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspotQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalUisceneModifierTargetHotspotServiceGetInternalUisceneModifierTargetHotspotQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspotQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalUisceneModifierTargetHotspotService.getInternalUisceneModifierTargetHotspotQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalTemplateTypeServiceGetInternalTemplateTypeKey = "InternalTemplateTypeServiceGetInternalTemplateType";
export const useInternalTemplateTypeServiceGetInternalTemplateType = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalTemplateTypeService.getInternalTemplateType>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalTemplateTypeService.getInternalTemplateType>>, unknown, Awaited<ReturnType<typeof InternalTemplateTypeService.getInternalTemplateType>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalTemplateTypeServiceGetInternalTemplateTypeKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalTemplateTypeService.getInternalTemplateType(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalTemplateTypeService.getInternalTemplateType>>, TError>, "data"> & {
    data: TData;
};
export const useInternalTemplateTypeServicePostInternalTemplateType = <TData = Awaited<ReturnType<typeof InternalTemplateTypeService.postInternalTemplateType>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalTemplateTypeService.postInternalTemplateType>>, unknown, {
    requestBody?: InternalTemplateTypeDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalTemplateTypeService.postInternalTemplateType(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalTemplateTypeService.postInternalTemplateType>>, TError, {
    requestBody?: InternalTemplateTypeDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalTemplateTypeServiceGetInternalTemplateType1Key = "InternalTemplateTypeServiceGetInternalTemplateType1";
export const useInternalTemplateTypeServiceGetInternalTemplateType1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalTemplateTypeService.getInternalTemplateType1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalTemplateTypeService.getInternalTemplateType1>>, unknown, Awaited<ReturnType<typeof InternalTemplateTypeService.getInternalTemplateType1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalTemplateTypeServiceGetInternalTemplateType1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalTemplateTypeService.getInternalTemplateType1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalTemplateTypeService.getInternalTemplateType1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalTemplateTypeServicePutInternalTemplateType = <TData = Awaited<ReturnType<typeof InternalTemplateTypeService.putInternalTemplateType>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalTemplateTypeService.putInternalTemplateType>>, unknown, {
    key: number;
    requestBody?: InternalTemplateTypeDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalTemplateTypeService.putInternalTemplateType(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalTemplateTypeService.putInternalTemplateType>>, TError, {
    key: number;
    requestBody?: InternalTemplateTypeDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalTemplateTypeServiceDeleteInternalTemplateType = <TData = Awaited<ReturnType<typeof InternalTemplateTypeService.deleteInternalTemplateType>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalTemplateTypeService.deleteInternalTemplateType>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalTemplateTypeService.deleteInternalTemplateType(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalTemplateTypeService.deleteInternalTemplateType>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalTemplateTypeServiceGetInternalTemplateTypeQueryKey = "InternalTemplateTypeServiceGetInternalTemplateTypeQuery";
export const useInternalTemplateTypeServiceGetInternalTemplateTypeQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalTemplateTypeService.getInternalTemplateTypeQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalTemplateTypeService.getInternalTemplateTypeQuery>>, unknown, Awaited<ReturnType<typeof InternalTemplateTypeService.getInternalTemplateTypeQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalTemplateTypeServiceGetInternalTemplateTypeQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalTemplateTypeService.getInternalTemplateTypeQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalTemplateTypeService.getInternalTemplateTypeQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalTemplateServiceGetInternalTemplateKey = "InternalTemplateServiceGetInternalTemplate";
export const useInternalTemplateServiceGetInternalTemplate = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalTemplateService.getInternalTemplate>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalTemplateService.getInternalTemplate>>, unknown, Awaited<ReturnType<typeof InternalTemplateService.getInternalTemplate>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalTemplateServiceGetInternalTemplateKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalTemplateService.getInternalTemplate(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalTemplateService.getInternalTemplate>>, TError>, "data"> & {
    data: TData;
};
export const useInternalTemplateServicePostInternalTemplate = <TData = Awaited<ReturnType<typeof InternalTemplateService.postInternalTemplate>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalTemplateService.postInternalTemplate>>, unknown, {
    requestBody?: InternalTemplateDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalTemplateService.postInternalTemplate(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalTemplateService.postInternalTemplate>>, TError, {
    requestBody?: InternalTemplateDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalTemplateServiceGetInternalTemplate1Key = "InternalTemplateServiceGetInternalTemplate1";
export const useInternalTemplateServiceGetInternalTemplate1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalTemplateService.getInternalTemplate1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalTemplateService.getInternalTemplate1>>, unknown, Awaited<ReturnType<typeof InternalTemplateService.getInternalTemplate1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalTemplateServiceGetInternalTemplate1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalTemplateService.getInternalTemplate1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalTemplateService.getInternalTemplate1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalTemplateServicePutInternalTemplate = <TData = Awaited<ReturnType<typeof InternalTemplateService.putInternalTemplate>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalTemplateService.putInternalTemplate>>, unknown, {
    key: number;
    requestBody?: InternalTemplateDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalTemplateService.putInternalTemplate(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalTemplateService.putInternalTemplate>>, TError, {
    key: number;
    requestBody?: InternalTemplateDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalTemplateServiceDeleteInternalTemplate = <TData = Awaited<ReturnType<typeof InternalTemplateService.deleteInternalTemplate>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalTemplateService.deleteInternalTemplate>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalTemplateService.deleteInternalTemplate(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalTemplateService.deleteInternalTemplate>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalTemplateServiceGetInternalTemplateQueryKey = "InternalTemplateServiceGetInternalTemplateQuery";
export const useInternalTemplateServiceGetInternalTemplateQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalTemplateService.getInternalTemplateQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalTemplateService.getInternalTemplateQuery>>, unknown, Awaited<ReturnType<typeof InternalTemplateService.getInternalTemplateQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalTemplateServiceGetInternalTemplateQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalTemplateService.getInternalTemplateQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalTemplateService.getInternalTemplateQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalTagServiceGetInternalTagKey = "InternalTagServiceGetInternalTag";
export const useInternalTagServiceGetInternalTag = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalTagService.getInternalTag>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalTagService.getInternalTag>>, unknown, Awaited<ReturnType<typeof InternalTagService.getInternalTag>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalTagServiceGetInternalTagKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalTagService.getInternalTag(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalTagService.getInternalTag>>, TError>, "data"> & {
    data: TData;
};
export const useInternalTagServicePostInternalTag = <TData = Awaited<ReturnType<typeof InternalTagService.postInternalTag>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalTagService.postInternalTag>>, unknown, {
    requestBody?: InternalTagDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalTagService.postInternalTag(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalTagService.postInternalTag>>, TError, {
    requestBody?: InternalTagDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalTagServiceGetInternalTag1Key = "InternalTagServiceGetInternalTag1";
export const useInternalTagServiceGetInternalTag1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalTagService.getInternalTag1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalTagService.getInternalTag1>>, unknown, Awaited<ReturnType<typeof InternalTagService.getInternalTag1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalTagServiceGetInternalTag1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalTagService.getInternalTag1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalTagService.getInternalTag1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalTagServicePutInternalTag = <TData = Awaited<ReturnType<typeof InternalTagService.putInternalTag>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalTagService.putInternalTag>>, unknown, {
    key: number;
    requestBody?: InternalTagDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalTagService.putInternalTag(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalTagService.putInternalTag>>, TError, {
    key: number;
    requestBody?: InternalTagDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalTagServiceDeleteInternalTag = <TData = Awaited<ReturnType<typeof InternalTagService.deleteInternalTag>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalTagService.deleteInternalTag>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalTagService.deleteInternalTag(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalTagService.deleteInternalTag>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalTagServiceGetInternalTagQueryKey = "InternalTagServiceGetInternalTagQuery";
export const useInternalTagServiceGetInternalTagQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalTagService.getInternalTagQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalTagService.getInternalTagQuery>>, unknown, Awaited<ReturnType<typeof InternalTagService.getInternalTagQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalTagServiceGetInternalTagQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalTagService.getInternalTagQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalTagService.getInternalTagQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalStillImageSubmissionPlanServiceGetInternalStillImageSubmissionPlanKey = "InternalStillImageSubmissionPlanServiceGetInternalStillImageSubmissionPlan";
export const useInternalStillImageSubmissionPlanServiceGetInternalStillImageSubmissionPlan = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlan>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlan>>, unknown, Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlan>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalStillImageSubmissionPlanServiceGetInternalStillImageSubmissionPlanKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlan(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlan>>, TError>, "data"> & {
    data: TData;
};
export const useInternalStillImageSubmissionPlanServicePostInternalStillImageSubmissionPlan = <TData = Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.postInternalStillImageSubmissionPlan>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.postInternalStillImageSubmissionPlan>>, unknown, {
    requestBody?: InternalStillImageSubmissionPlanDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalStillImageSubmissionPlanService.postInternalStillImageSubmissionPlan(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.postInternalStillImageSubmissionPlan>>, TError, {
    requestBody?: InternalStillImageSubmissionPlanDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalStillImageSubmissionPlanServiceGetInternalStillImageSubmissionPlan1Key = "InternalStillImageSubmissionPlanServiceGetInternalStillImageSubmissionPlan1";
export const useInternalStillImageSubmissionPlanServiceGetInternalStillImageSubmissionPlan1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlan1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlan1>>, unknown, Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlan1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalStillImageSubmissionPlanServiceGetInternalStillImageSubmissionPlan1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlan1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlan1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalStillImageSubmissionPlanServicePutInternalStillImageSubmissionPlan = <TData = Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.putInternalStillImageSubmissionPlan>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.putInternalStillImageSubmissionPlan>>, unknown, {
    key: number;
    requestBody?: InternalStillImageSubmissionPlanDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalStillImageSubmissionPlanService.putInternalStillImageSubmissionPlan(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.putInternalStillImageSubmissionPlan>>, TError, {
    key: number;
    requestBody?: InternalStillImageSubmissionPlanDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalStillImageSubmissionPlanServiceDeleteInternalStillImageSubmissionPlan = <TData = Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.deleteInternalStillImageSubmissionPlan>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.deleteInternalStillImageSubmissionPlan>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalStillImageSubmissionPlanService.deleteInternalStillImageSubmissionPlan(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.deleteInternalStillImageSubmissionPlan>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalStillImageSubmissionPlanServiceGetInternalStillImageSubmissionPlanQueryKey = "InternalStillImageSubmissionPlanServiceGetInternalStillImageSubmissionPlanQuery";
export const useInternalStillImageSubmissionPlanServiceGetInternalStillImageSubmissionPlanQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlanQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlanQuery>>, unknown, Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlanQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalStillImageSubmissionPlanServiceGetInternalStillImageSubmissionPlanQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlanQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalStillImageSubmissionPlanService.getInternalStillImageSubmissionPlanQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalStillImageSettingServiceGetInternalStillImageSettingKey = "InternalStillImageSettingServiceGetInternalStillImageSetting";
export const useInternalStillImageSettingServiceGetInternalStillImageSetting = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalStillImageSettingService.getInternalStillImageSetting>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalStillImageSettingService.getInternalStillImageSetting>>, unknown, Awaited<ReturnType<typeof InternalStillImageSettingService.getInternalStillImageSetting>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalStillImageSettingServiceGetInternalStillImageSettingKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalStillImageSettingService.getInternalStillImageSetting(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalStillImageSettingService.getInternalStillImageSetting>>, TError>, "data"> & {
    data: TData;
};
export const useInternalStillImageSettingServicePostInternalStillImageSetting = <TData = Awaited<ReturnType<typeof InternalStillImageSettingService.postInternalStillImageSetting>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalStillImageSettingService.postInternalStillImageSetting>>, unknown, {
    requestBody?: InternalStillImageSettingDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalStillImageSettingService.postInternalStillImageSetting(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalStillImageSettingService.postInternalStillImageSetting>>, TError, {
    requestBody?: InternalStillImageSettingDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalStillImageSettingServiceGetInternalStillImageSetting1Key = "InternalStillImageSettingServiceGetInternalStillImageSetting1";
export const useInternalStillImageSettingServiceGetInternalStillImageSetting1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalStillImageSettingService.getInternalStillImageSetting1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalStillImageSettingService.getInternalStillImageSetting1>>, unknown, Awaited<ReturnType<typeof InternalStillImageSettingService.getInternalStillImageSetting1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalStillImageSettingServiceGetInternalStillImageSetting1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalStillImageSettingService.getInternalStillImageSetting1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalStillImageSettingService.getInternalStillImageSetting1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalStillImageSettingServicePutInternalStillImageSetting = <TData = Awaited<ReturnType<typeof InternalStillImageSettingService.putInternalStillImageSetting>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalStillImageSettingService.putInternalStillImageSetting>>, unknown, {
    key: number;
    requestBody?: InternalStillImageSettingDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalStillImageSettingService.putInternalStillImageSetting(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalStillImageSettingService.putInternalStillImageSetting>>, TError, {
    key: number;
    requestBody?: InternalStillImageSettingDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalStillImageSettingServiceDeleteInternalStillImageSetting = <TData = Awaited<ReturnType<typeof InternalStillImageSettingService.deleteInternalStillImageSetting>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalStillImageSettingService.deleteInternalStillImageSetting>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalStillImageSettingService.deleteInternalStillImageSetting(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalStillImageSettingService.deleteInternalStillImageSetting>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalStillImageSettingServiceGetInternalStillImageSettingQueryKey = "InternalStillImageSettingServiceGetInternalStillImageSettingQuery";
export const useInternalStillImageSettingServiceGetInternalStillImageSettingQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalStillImageSettingService.getInternalStillImageSettingQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalStillImageSettingService.getInternalStillImageSettingQuery>>, unknown, Awaited<ReturnType<typeof InternalStillImageSettingService.getInternalStillImageSettingQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalStillImageSettingServiceGetInternalStillImageSettingQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalStillImageSettingService.getInternalStillImageSettingQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalStillImageSettingService.getInternalStillImageSettingQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSpin360SettingServiceGetInternalSpin360SettingKey = "InternalSpin360SettingServiceGetInternalSpin360Setting";
export const useInternalSpin360SettingServiceGetInternalSpin360Setting = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSpin360SettingService.getInternalSpin360Setting>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSpin360SettingService.getInternalSpin360Setting>>, unknown, Awaited<ReturnType<typeof InternalSpin360SettingService.getInternalSpin360Setting>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSpin360SettingServiceGetInternalSpin360SettingKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalSpin360SettingService.getInternalSpin360Setting(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSpin360SettingService.getInternalSpin360Setting>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSpin360SettingServicePostInternalSpin360Setting = <TData = Awaited<ReturnType<typeof InternalSpin360SettingService.postInternalSpin360Setting>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSpin360SettingService.postInternalSpin360Setting>>, unknown, {
    requestBody?: InternalSpin360SettingDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalSpin360SettingService.postInternalSpin360Setting(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSpin360SettingService.postInternalSpin360Setting>>, TError, {
    requestBody?: InternalSpin360SettingDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSpin360SettingServiceGetInternalSpin360Setting1Key = "InternalSpin360SettingServiceGetInternalSpin360Setting1";
export const useInternalSpin360SettingServiceGetInternalSpin360Setting1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSpin360SettingService.getInternalSpin360Setting1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSpin360SettingService.getInternalSpin360Setting1>>, unknown, Awaited<ReturnType<typeof InternalSpin360SettingService.getInternalSpin360Setting1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSpin360SettingServiceGetInternalSpin360Setting1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalSpin360SettingService.getInternalSpin360Setting1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSpin360SettingService.getInternalSpin360Setting1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSpin360SettingServicePutInternalSpin360Setting = <TData = Awaited<ReturnType<typeof InternalSpin360SettingService.putInternalSpin360Setting>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSpin360SettingService.putInternalSpin360Setting>>, unknown, {
    key: number;
    requestBody?: InternalSpin360SettingDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalSpin360SettingService.putInternalSpin360Setting(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSpin360SettingService.putInternalSpin360Setting>>, TError, {
    key: number;
    requestBody?: InternalSpin360SettingDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSpin360SettingServiceDeleteInternalSpin360Setting = <TData = Awaited<ReturnType<typeof InternalSpin360SettingService.deleteInternalSpin360Setting>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSpin360SettingService.deleteInternalSpin360Setting>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalSpin360SettingService.deleteInternalSpin360Setting(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSpin360SettingService.deleteInternalSpin360Setting>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSpin360SettingServiceGetInternalSpin360SettingQueryKey = "InternalSpin360SettingServiceGetInternalSpin360SettingQuery";
export const useInternalSpin360SettingServiceGetInternalSpin360SettingQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSpin360SettingService.getInternalSpin360SettingQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSpin360SettingService.getInternalSpin360SettingQuery>>, unknown, Awaited<ReturnType<typeof InternalSpin360SettingService.getInternalSpin360SettingQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSpin360SettingServiceGetInternalSpin360SettingQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalSpin360SettingService.getInternalSpin360SettingQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSpin360SettingService.getInternalSpin360SettingQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneSurfaceServiceGetInternalSceneSurfaceKey = "InternalSceneSurfaceServiceGetInternalSceneSurface";
export const useInternalSceneSurfaceServiceGetInternalSceneSurface = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneSurfaceService.getInternalSceneSurface>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneSurfaceService.getInternalSceneSurface>>, unknown, Awaited<ReturnType<typeof InternalSceneSurfaceService.getInternalSceneSurface>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneSurfaceServiceGetInternalSceneSurfaceKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalSceneSurfaceService.getInternalSceneSurface(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneSurfaceService.getInternalSceneSurface>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneSurfaceServicePostInternalSceneSurface = <TData = Awaited<ReturnType<typeof InternalSceneSurfaceService.postInternalSceneSurface>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneSurfaceService.postInternalSceneSurface>>, unknown, {
    requestBody?: InternalSceneSurfaceDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalSceneSurfaceService.postInternalSceneSurface(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneSurfaceService.postInternalSceneSurface>>, TError, {
    requestBody?: InternalSceneSurfaceDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneSurfaceServiceGetInternalSceneSurface1Key = "InternalSceneSurfaceServiceGetInternalSceneSurface1";
export const useInternalSceneSurfaceServiceGetInternalSceneSurface1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneSurfaceService.getInternalSceneSurface1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneSurfaceService.getInternalSceneSurface1>>, unknown, Awaited<ReturnType<typeof InternalSceneSurfaceService.getInternalSceneSurface1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneSurfaceServiceGetInternalSceneSurface1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalSceneSurfaceService.getInternalSceneSurface1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneSurfaceService.getInternalSceneSurface1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneSurfaceServicePutInternalSceneSurface = <TData = Awaited<ReturnType<typeof InternalSceneSurfaceService.putInternalSceneSurface>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneSurfaceService.putInternalSceneSurface>>, unknown, {
    key: number;
    requestBody?: InternalSceneSurfaceDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalSceneSurfaceService.putInternalSceneSurface(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneSurfaceService.putInternalSceneSurface>>, TError, {
    key: number;
    requestBody?: InternalSceneSurfaceDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneSurfaceServiceDeleteInternalSceneSurface = <TData = Awaited<ReturnType<typeof InternalSceneSurfaceService.deleteInternalSceneSurface>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneSurfaceService.deleteInternalSceneSurface>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalSceneSurfaceService.deleteInternalSceneSurface(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneSurfaceService.deleteInternalSceneSurface>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneSurfaceServiceGetInternalSceneSurfaceQueryKey = "InternalSceneSurfaceServiceGetInternalSceneSurfaceQuery";
export const useInternalSceneSurfaceServiceGetInternalSceneSurfaceQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneSurfaceService.getInternalSceneSurfaceQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneSurfaceService.getInternalSceneSurfaceQuery>>, unknown, Awaited<ReturnType<typeof InternalSceneSurfaceService.getInternalSceneSurfaceQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneSurfaceServiceGetInternalSceneSurfaceQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalSceneSurfaceService.getInternalSceneSurfaceQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneSurfaceService.getInternalSceneSurfaceQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneServiceGetInternalSceneKey = "InternalSceneServiceGetInternalScene";
export const useInternalSceneServiceGetInternalScene = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneService.getInternalScene>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneService.getInternalScene>>, unknown, Awaited<ReturnType<typeof InternalSceneService.getInternalScene>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneServiceGetInternalSceneKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalSceneService.getInternalScene(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneService.getInternalScene>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneServicePostInternalScene = <TData = Awaited<ReturnType<typeof InternalSceneService.postInternalScene>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneService.postInternalScene>>, unknown, {
    requestBody?: InternalSceneDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalSceneService.postInternalScene(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneService.postInternalScene>>, TError, {
    requestBody?: InternalSceneDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneServiceGetInternalScene1Key = "InternalSceneServiceGetInternalScene1";
export const useInternalSceneServiceGetInternalScene1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneService.getInternalScene1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneService.getInternalScene1>>, unknown, Awaited<ReturnType<typeof InternalSceneService.getInternalScene1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneServiceGetInternalScene1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalSceneService.getInternalScene1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneService.getInternalScene1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneServicePutInternalScene = <TData = Awaited<ReturnType<typeof InternalSceneService.putInternalScene>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneService.putInternalScene>>, unknown, {
    key: number;
    requestBody?: InternalSceneDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalSceneService.putInternalScene(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneService.putInternalScene>>, TError, {
    key: number;
    requestBody?: InternalSceneDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneServiceDeleteInternalScene = <TData = Awaited<ReturnType<typeof InternalSceneService.deleteInternalScene>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneService.deleteInternalScene>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalSceneService.deleteInternalScene(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneService.deleteInternalScene>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneServiceGetInternalSceneQueryKey = "InternalSceneServiceGetInternalSceneQuery";
export const useInternalSceneServiceGetInternalSceneQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneService.getInternalSceneQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneService.getInternalSceneQuery>>, unknown, Awaited<ReturnType<typeof InternalSceneService.getInternalSceneQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneServiceGetInternalSceneQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalSceneService.getInternalSceneQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneService.getInternalSceneQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalScenePropsetServiceGetInternalScenePropsetKey = "InternalScenePropsetServiceGetInternalScenePropset";
export const useInternalScenePropsetServiceGetInternalScenePropset = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalScenePropsetService.getInternalScenePropset>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalScenePropsetService.getInternalScenePropset>>, unknown, Awaited<ReturnType<typeof InternalScenePropsetService.getInternalScenePropset>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalScenePropsetServiceGetInternalScenePropsetKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalScenePropsetService.getInternalScenePropset(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalScenePropsetService.getInternalScenePropset>>, TError>, "data"> & {
    data: TData;
};
export const useInternalScenePropsetServicePostInternalScenePropset = <TData = Awaited<ReturnType<typeof InternalScenePropsetService.postInternalScenePropset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalScenePropsetService.postInternalScenePropset>>, unknown, {
    requestBody?: InternalScenePropsetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalScenePropsetService.postInternalScenePropset(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalScenePropsetService.postInternalScenePropset>>, TError, {
    requestBody?: InternalScenePropsetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalScenePropsetServiceGetInternalScenePropset1Key = "InternalScenePropsetServiceGetInternalScenePropset1";
export const useInternalScenePropsetServiceGetInternalScenePropset1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalScenePropsetService.getInternalScenePropset1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalScenePropsetService.getInternalScenePropset1>>, unknown, Awaited<ReturnType<typeof InternalScenePropsetService.getInternalScenePropset1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalScenePropsetServiceGetInternalScenePropset1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalScenePropsetService.getInternalScenePropset1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalScenePropsetService.getInternalScenePropset1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalScenePropsetServicePutInternalScenePropset = <TData = Awaited<ReturnType<typeof InternalScenePropsetService.putInternalScenePropset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalScenePropsetService.putInternalScenePropset>>, unknown, {
    key: number;
    requestBody?: InternalScenePropsetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalScenePropsetService.putInternalScenePropset(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalScenePropsetService.putInternalScenePropset>>, TError, {
    key: number;
    requestBody?: InternalScenePropsetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalScenePropsetServiceDeleteInternalScenePropset = <TData = Awaited<ReturnType<typeof InternalScenePropsetService.deleteInternalScenePropset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalScenePropsetService.deleteInternalScenePropset>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalScenePropsetService.deleteInternalScenePropset(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalScenePropsetService.deleteInternalScenePropset>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalScenePropsetServiceGetInternalScenePropsetQueryKey = "InternalScenePropsetServiceGetInternalScenePropsetQuery";
export const useInternalScenePropsetServiceGetInternalScenePropsetQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalScenePropsetService.getInternalScenePropsetQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalScenePropsetService.getInternalScenePropsetQuery>>, unknown, Awaited<ReturnType<typeof InternalScenePropsetService.getInternalScenePropsetQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalScenePropsetServiceGetInternalScenePropsetQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalScenePropsetService.getInternalScenePropsetQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalScenePropsetService.getInternalScenePropsetQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalScenePropsetOptionServiceGetInternalScenePropsetOptionKey = "InternalScenePropsetOptionServiceGetInternalScenePropsetOption";
export const useInternalScenePropsetOptionServiceGetInternalScenePropsetOption = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalScenePropsetOptionService.getInternalScenePropsetOption>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalScenePropsetOptionService.getInternalScenePropsetOption>>, unknown, Awaited<ReturnType<typeof InternalScenePropsetOptionService.getInternalScenePropsetOption>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalScenePropsetOptionServiceGetInternalScenePropsetOptionKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalScenePropsetOptionService.getInternalScenePropsetOption(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalScenePropsetOptionService.getInternalScenePropsetOption>>, TError>, "data"> & {
    data: TData;
};
export const useInternalScenePropsetOptionServicePostInternalScenePropsetOption = <TData = Awaited<ReturnType<typeof InternalScenePropsetOptionService.postInternalScenePropsetOption>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalScenePropsetOptionService.postInternalScenePropsetOption>>, unknown, {
    requestBody?: InternalScenePropsetOptionDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalScenePropsetOptionService.postInternalScenePropsetOption(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalScenePropsetOptionService.postInternalScenePropsetOption>>, TError, {
    requestBody?: InternalScenePropsetOptionDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalScenePropsetOptionServiceGetInternalScenePropsetOption1Key = "InternalScenePropsetOptionServiceGetInternalScenePropsetOption1";
export const useInternalScenePropsetOptionServiceGetInternalScenePropsetOption1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalScenePropsetOptionService.getInternalScenePropsetOption1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalScenePropsetOptionService.getInternalScenePropsetOption1>>, unknown, Awaited<ReturnType<typeof InternalScenePropsetOptionService.getInternalScenePropsetOption1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalScenePropsetOptionServiceGetInternalScenePropsetOption1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalScenePropsetOptionService.getInternalScenePropsetOption1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalScenePropsetOptionService.getInternalScenePropsetOption1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalScenePropsetOptionServicePutInternalScenePropsetOption = <TData = Awaited<ReturnType<typeof InternalScenePropsetOptionService.putInternalScenePropsetOption>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalScenePropsetOptionService.putInternalScenePropsetOption>>, unknown, {
    key: number;
    requestBody?: InternalScenePropsetOptionDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalScenePropsetOptionService.putInternalScenePropsetOption(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalScenePropsetOptionService.putInternalScenePropsetOption>>, TError, {
    key: number;
    requestBody?: InternalScenePropsetOptionDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalScenePropsetOptionServiceDeleteInternalScenePropsetOption = <TData = Awaited<ReturnType<typeof InternalScenePropsetOptionService.deleteInternalScenePropsetOption>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalScenePropsetOptionService.deleteInternalScenePropsetOption>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalScenePropsetOptionService.deleteInternalScenePropsetOption(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalScenePropsetOptionService.deleteInternalScenePropsetOption>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalScenePropsetOptionServiceGetInternalScenePropsetOptionQueryKey = "InternalScenePropsetOptionServiceGetInternalScenePropsetOptionQuery";
export const useInternalScenePropsetOptionServiceGetInternalScenePropsetOptionQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalScenePropsetOptionService.getInternalScenePropsetOptionQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalScenePropsetOptionService.getInternalScenePropsetOptionQuery>>, unknown, Awaited<ReturnType<typeof InternalScenePropsetOptionService.getInternalScenePropsetOptionQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalScenePropsetOptionServiceGetInternalScenePropsetOptionQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalScenePropsetOptionService.getInternalScenePropsetOptionQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalScenePropsetOptionService.getInternalScenePropsetOptionQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalScenePlacementpointServiceGetInternalScenePlacementpointKey = "InternalScenePlacementpointServiceGetInternalScenePlacementpoint";
export const useInternalScenePlacementpointServiceGetInternalScenePlacementpoint = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalScenePlacementpointService.getInternalScenePlacementpoint>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalScenePlacementpointService.getInternalScenePlacementpoint>>, unknown, Awaited<ReturnType<typeof InternalScenePlacementpointService.getInternalScenePlacementpoint>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalScenePlacementpointServiceGetInternalScenePlacementpointKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalScenePlacementpointService.getInternalScenePlacementpoint(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalScenePlacementpointService.getInternalScenePlacementpoint>>, TError>, "data"> & {
    data: TData;
};
export const useInternalScenePlacementpointServicePostInternalScenePlacementpoint = <TData = Awaited<ReturnType<typeof InternalScenePlacementpointService.postInternalScenePlacementpoint>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalScenePlacementpointService.postInternalScenePlacementpoint>>, unknown, {
    requestBody?: InternalScenePlacementpointDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalScenePlacementpointService.postInternalScenePlacementpoint(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalScenePlacementpointService.postInternalScenePlacementpoint>>, TError, {
    requestBody?: InternalScenePlacementpointDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalScenePlacementpointServiceGetInternalScenePlacementpoint1Key = "InternalScenePlacementpointServiceGetInternalScenePlacementpoint1";
export const useInternalScenePlacementpointServiceGetInternalScenePlacementpoint1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalScenePlacementpointService.getInternalScenePlacementpoint1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalScenePlacementpointService.getInternalScenePlacementpoint1>>, unknown, Awaited<ReturnType<typeof InternalScenePlacementpointService.getInternalScenePlacementpoint1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalScenePlacementpointServiceGetInternalScenePlacementpoint1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalScenePlacementpointService.getInternalScenePlacementpoint1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalScenePlacementpointService.getInternalScenePlacementpoint1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalScenePlacementpointServicePutInternalScenePlacementpoint = <TData = Awaited<ReturnType<typeof InternalScenePlacementpointService.putInternalScenePlacementpoint>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalScenePlacementpointService.putInternalScenePlacementpoint>>, unknown, {
    key: number;
    requestBody?: InternalScenePlacementpointDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalScenePlacementpointService.putInternalScenePlacementpoint(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalScenePlacementpointService.putInternalScenePlacementpoint>>, TError, {
    key: number;
    requestBody?: InternalScenePlacementpointDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalScenePlacementpointServiceDeleteInternalScenePlacementpoint = <TData = Awaited<ReturnType<typeof InternalScenePlacementpointService.deleteInternalScenePlacementpoint>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalScenePlacementpointService.deleteInternalScenePlacementpoint>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalScenePlacementpointService.deleteInternalScenePlacementpoint(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalScenePlacementpointService.deleteInternalScenePlacementpoint>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalScenePlacementpointServiceGetInternalScenePlacementpointQueryKey = "InternalScenePlacementpointServiceGetInternalScenePlacementpointQuery";
export const useInternalScenePlacementpointServiceGetInternalScenePlacementpointQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalScenePlacementpointService.getInternalScenePlacementpointQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalScenePlacementpointService.getInternalScenePlacementpointQuery>>, unknown, Awaited<ReturnType<typeof InternalScenePlacementpointService.getInternalScenePlacementpointQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalScenePlacementpointServiceGetInternalScenePlacementpointQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalScenePlacementpointService.getInternalScenePlacementpointQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalScenePlacementpointService.getInternalScenePlacementpointQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneModifierTargetServiceGetInternalSceneModifierTargetKey = "InternalSceneModifierTargetServiceGetInternalSceneModifierTarget";
export const useInternalSceneModifierTargetServiceGetInternalSceneModifierTarget = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneModifierTargetService.getInternalSceneModifierTarget>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneModifierTargetService.getInternalSceneModifierTarget>>, unknown, Awaited<ReturnType<typeof InternalSceneModifierTargetService.getInternalSceneModifierTarget>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneModifierTargetServiceGetInternalSceneModifierTargetKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalSceneModifierTargetService.getInternalSceneModifierTarget(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneModifierTargetService.getInternalSceneModifierTarget>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneModifierTargetServicePostInternalSceneModifierTarget = <TData = Awaited<ReturnType<typeof InternalSceneModifierTargetService.postInternalSceneModifierTarget>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneModifierTargetService.postInternalSceneModifierTarget>>, unknown, {
    requestBody?: InternalSceneModifierTargetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalSceneModifierTargetService.postInternalSceneModifierTarget(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneModifierTargetService.postInternalSceneModifierTarget>>, TError, {
    requestBody?: InternalSceneModifierTargetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneModifierTargetServiceGetInternalSceneModifierTarget1Key = "InternalSceneModifierTargetServiceGetInternalSceneModifierTarget1";
export const useInternalSceneModifierTargetServiceGetInternalSceneModifierTarget1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneModifierTargetService.getInternalSceneModifierTarget1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneModifierTargetService.getInternalSceneModifierTarget1>>, unknown, Awaited<ReturnType<typeof InternalSceneModifierTargetService.getInternalSceneModifierTarget1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneModifierTargetServiceGetInternalSceneModifierTarget1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalSceneModifierTargetService.getInternalSceneModifierTarget1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneModifierTargetService.getInternalSceneModifierTarget1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneModifierTargetServicePutInternalSceneModifierTarget = <TData = Awaited<ReturnType<typeof InternalSceneModifierTargetService.putInternalSceneModifierTarget>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneModifierTargetService.putInternalSceneModifierTarget>>, unknown, {
    key: number;
    requestBody?: InternalSceneModifierTargetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalSceneModifierTargetService.putInternalSceneModifierTarget(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneModifierTargetService.putInternalSceneModifierTarget>>, TError, {
    key: number;
    requestBody?: InternalSceneModifierTargetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneModifierTargetServiceDeleteInternalSceneModifierTarget = <TData = Awaited<ReturnType<typeof InternalSceneModifierTargetService.deleteInternalSceneModifierTarget>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneModifierTargetService.deleteInternalSceneModifierTarget>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalSceneModifierTargetService.deleteInternalSceneModifierTarget(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneModifierTargetService.deleteInternalSceneModifierTarget>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneModifierTargetServiceGetInternalSceneModifierTargetQueryKey = "InternalSceneModifierTargetServiceGetInternalSceneModifierTargetQuery";
export const useInternalSceneModifierTargetServiceGetInternalSceneModifierTargetQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneModifierTargetService.getInternalSceneModifierTargetQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneModifierTargetService.getInternalSceneModifierTargetQuery>>, unknown, Awaited<ReturnType<typeof InternalSceneModifierTargetService.getInternalSceneModifierTargetQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneModifierTargetServiceGetInternalSceneModifierTargetQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalSceneModifierTargetService.getInternalSceneModifierTargetQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneModifierTargetService.getInternalSceneModifierTargetQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneCameraServiceGetInternalSceneCameraKey = "InternalSceneCameraServiceGetInternalSceneCamera";
export const useInternalSceneCameraServiceGetInternalSceneCamera = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneCameraService.getInternalSceneCamera>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneCameraService.getInternalSceneCamera>>, unknown, Awaited<ReturnType<typeof InternalSceneCameraService.getInternalSceneCamera>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneCameraServiceGetInternalSceneCameraKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalSceneCameraService.getInternalSceneCamera(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneCameraService.getInternalSceneCamera>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneCameraServicePostInternalSceneCamera = <TData = Awaited<ReturnType<typeof InternalSceneCameraService.postInternalSceneCamera>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneCameraService.postInternalSceneCamera>>, unknown, {
    requestBody?: InternalSceneCameraDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalSceneCameraService.postInternalSceneCamera(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneCameraService.postInternalSceneCamera>>, TError, {
    requestBody?: InternalSceneCameraDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneCameraServiceGetInternalSceneCamera1Key = "InternalSceneCameraServiceGetInternalSceneCamera1";
export const useInternalSceneCameraServiceGetInternalSceneCamera1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneCameraService.getInternalSceneCamera1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneCameraService.getInternalSceneCamera1>>, unknown, Awaited<ReturnType<typeof InternalSceneCameraService.getInternalSceneCamera1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneCameraServiceGetInternalSceneCamera1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalSceneCameraService.getInternalSceneCamera1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneCameraService.getInternalSceneCamera1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneCameraServicePutInternalSceneCamera = <TData = Awaited<ReturnType<typeof InternalSceneCameraService.putInternalSceneCamera>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneCameraService.putInternalSceneCamera>>, unknown, {
    key: number;
    requestBody?: InternalSceneCameraDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalSceneCameraService.putInternalSceneCamera(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneCameraService.putInternalSceneCamera>>, TError, {
    key: number;
    requestBody?: InternalSceneCameraDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneCameraServiceDeleteInternalSceneCamera = <TData = Awaited<ReturnType<typeof InternalSceneCameraService.deleteInternalSceneCamera>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneCameraService.deleteInternalSceneCamera>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalSceneCameraService.deleteInternalSceneCamera(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneCameraService.deleteInternalSceneCamera>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneCameraServiceGetInternalSceneCameraQueryKey = "InternalSceneCameraServiceGetInternalSceneCameraQuery";
export const useInternalSceneCameraServiceGetInternalSceneCameraQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneCameraService.getInternalSceneCameraQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneCameraService.getInternalSceneCameraQuery>>, unknown, Awaited<ReturnType<typeof InternalSceneCameraService.getInternalSceneCameraQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneCameraServiceGetInternalSceneCameraQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalSceneCameraService.getInternalSceneCameraQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneCameraService.getInternalSceneCameraQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneCameraAspectRatioServiceGetInternalSceneCameraAspectRatioKey = "InternalSceneCameraAspectRatioServiceGetInternalSceneCameraAspectRatio";
export const useInternalSceneCameraAspectRatioServiceGetInternalSceneCameraAspectRatio = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatio>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatio>>, unknown, Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatio>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneCameraAspectRatioServiceGetInternalSceneCameraAspectRatioKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatio(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatio>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneCameraAspectRatioServicePostInternalSceneCameraAspectRatio = <TData = Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.postInternalSceneCameraAspectRatio>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.postInternalSceneCameraAspectRatio>>, unknown, {
    requestBody?: InternalSceneCameraAspectRatioDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalSceneCameraAspectRatioService.postInternalSceneCameraAspectRatio(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.postInternalSceneCameraAspectRatio>>, TError, {
    requestBody?: InternalSceneCameraAspectRatioDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneCameraAspectRatioServiceGetInternalSceneCameraAspectRatio1Key = "InternalSceneCameraAspectRatioServiceGetInternalSceneCameraAspectRatio1";
export const useInternalSceneCameraAspectRatioServiceGetInternalSceneCameraAspectRatio1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatio1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatio1>>, unknown, Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatio1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneCameraAspectRatioServiceGetInternalSceneCameraAspectRatio1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatio1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatio1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalSceneCameraAspectRatioServicePutInternalSceneCameraAspectRatio = <TData = Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.putInternalSceneCameraAspectRatio>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.putInternalSceneCameraAspectRatio>>, unknown, {
    key: number;
    requestBody?: InternalSceneCameraAspectRatioDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalSceneCameraAspectRatioService.putInternalSceneCameraAspectRatio(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.putInternalSceneCameraAspectRatio>>, TError, {
    key: number;
    requestBody?: InternalSceneCameraAspectRatioDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneCameraAspectRatioServiceDeleteInternalSceneCameraAspectRatio = <TData = Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.deleteInternalSceneCameraAspectRatio>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.deleteInternalSceneCameraAspectRatio>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalSceneCameraAspectRatioService.deleteInternalSceneCameraAspectRatio(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.deleteInternalSceneCameraAspectRatio>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalSceneCameraAspectRatioServiceGetInternalSceneCameraAspectRatioQueryKey = "InternalSceneCameraAspectRatioServiceGetInternalSceneCameraAspectRatioQuery";
export const useInternalSceneCameraAspectRatioServiceGetInternalSceneCameraAspectRatioQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatioQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatioQuery>>, unknown, Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatioQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalSceneCameraAspectRatioServiceGetInternalSceneCameraAspectRatioQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatioQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalSceneCameraAspectRatioService.getInternalSceneCameraAspectRatioQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalRenderPresetServiceGetInternalRenderPresetKey = "InternalRenderPresetServiceGetInternalRenderPreset";
export const useInternalRenderPresetServiceGetInternalRenderPreset = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalRenderPresetService.getInternalRenderPreset>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalRenderPresetService.getInternalRenderPreset>>, unknown, Awaited<ReturnType<typeof InternalRenderPresetService.getInternalRenderPreset>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalRenderPresetServiceGetInternalRenderPresetKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalRenderPresetService.getInternalRenderPreset(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalRenderPresetService.getInternalRenderPreset>>, TError>, "data"> & {
    data: TData;
};
export const useInternalRenderPresetServicePostInternalRenderPreset = <TData = Awaited<ReturnType<typeof InternalRenderPresetService.postInternalRenderPreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalRenderPresetService.postInternalRenderPreset>>, unknown, {
    requestBody?: InternalRenderPresetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalRenderPresetService.postInternalRenderPreset(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalRenderPresetService.postInternalRenderPreset>>, TError, {
    requestBody?: InternalRenderPresetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalRenderPresetServiceGetInternalRenderPreset1Key = "InternalRenderPresetServiceGetInternalRenderPreset1";
export const useInternalRenderPresetServiceGetInternalRenderPreset1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalRenderPresetService.getInternalRenderPreset1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalRenderPresetService.getInternalRenderPreset1>>, unknown, Awaited<ReturnType<typeof InternalRenderPresetService.getInternalRenderPreset1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalRenderPresetServiceGetInternalRenderPreset1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalRenderPresetService.getInternalRenderPreset1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalRenderPresetService.getInternalRenderPreset1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalRenderPresetServicePutInternalRenderPreset = <TData = Awaited<ReturnType<typeof InternalRenderPresetService.putInternalRenderPreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalRenderPresetService.putInternalRenderPreset>>, unknown, {
    key: number;
    requestBody?: InternalRenderPresetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalRenderPresetService.putInternalRenderPreset(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalRenderPresetService.putInternalRenderPreset>>, TError, {
    key: number;
    requestBody?: InternalRenderPresetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalRenderPresetServiceDeleteInternalRenderPreset = <TData = Awaited<ReturnType<typeof InternalRenderPresetService.deleteInternalRenderPreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalRenderPresetService.deleteInternalRenderPreset>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalRenderPresetService.deleteInternalRenderPreset(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalRenderPresetService.deleteInternalRenderPreset>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalRenderPresetServiceGetInternalRenderPresetQueryKey = "InternalRenderPresetServiceGetInternalRenderPresetQuery";
export const useInternalRenderPresetServiceGetInternalRenderPresetQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalRenderPresetService.getInternalRenderPresetQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalRenderPresetService.getInternalRenderPresetQuery>>, unknown, Awaited<ReturnType<typeof InternalRenderPresetService.getInternalRenderPresetQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalRenderPresetServiceGetInternalRenderPresetQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalRenderPresetService.getInternalRenderPresetQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalRenderPresetService.getInternalRenderPresetQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalProjectShareServiceGetInternalProjectShareKey = "InternalProjectShareServiceGetInternalProjectShare";
export const useInternalProjectShareServiceGetInternalProjectShare = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalProjectShareService.getInternalProjectShare>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalProjectShareService.getInternalProjectShare>>, unknown, Awaited<ReturnType<typeof InternalProjectShareService.getInternalProjectShare>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalProjectShareServiceGetInternalProjectShareKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalProjectShareService.getInternalProjectShare(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalProjectShareService.getInternalProjectShare>>, TError>, "data"> & {
    data: TData;
};
export const useInternalProjectShareServicePostInternalProjectShare = <TData = Awaited<ReturnType<typeof InternalProjectShareService.postInternalProjectShare>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalProjectShareService.postInternalProjectShare>>, unknown, {
    requestBody?: InternalProjectShareDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalProjectShareService.postInternalProjectShare(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalProjectShareService.postInternalProjectShare>>, TError, {
    requestBody?: InternalProjectShareDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalProjectShareServiceGetInternalProjectShare1Key = "InternalProjectShareServiceGetInternalProjectShare1";
export const useInternalProjectShareServiceGetInternalProjectShare1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalProjectShareService.getInternalProjectShare1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalProjectShareService.getInternalProjectShare1>>, unknown, Awaited<ReturnType<typeof InternalProjectShareService.getInternalProjectShare1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalProjectShareServiceGetInternalProjectShare1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalProjectShareService.getInternalProjectShare1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalProjectShareService.getInternalProjectShare1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalProjectShareServicePutInternalProjectShare = <TData = Awaited<ReturnType<typeof InternalProjectShareService.putInternalProjectShare>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalProjectShareService.putInternalProjectShare>>, unknown, {
    key: number;
    requestBody?: InternalProjectShareDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalProjectShareService.putInternalProjectShare(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalProjectShareService.putInternalProjectShare>>, TError, {
    key: number;
    requestBody?: InternalProjectShareDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalProjectShareServiceDeleteInternalProjectShare = <TData = Awaited<ReturnType<typeof InternalProjectShareService.deleteInternalProjectShare>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalProjectShareService.deleteInternalProjectShare>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalProjectShareService.deleteInternalProjectShare(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalProjectShareService.deleteInternalProjectShare>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalProjectShareServiceGetInternalProjectShareQueryKey = "InternalProjectShareServiceGetInternalProjectShareQuery";
export const useInternalProjectShareServiceGetInternalProjectShareQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalProjectShareService.getInternalProjectShareQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalProjectShareService.getInternalProjectShareQuery>>, unknown, Awaited<ReturnType<typeof InternalProjectShareService.getInternalProjectShareQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalProjectShareServiceGetInternalProjectShareQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalProjectShareService.getInternalProjectShareQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalProjectShareService.getInternalProjectShareQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalProjectServiceGetInternalProjectKey = "InternalProjectServiceGetInternalProject";
export const useInternalProjectServiceGetInternalProject = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalProjectService.getInternalProject>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalProjectService.getInternalProject>>, unknown, Awaited<ReturnType<typeof InternalProjectService.getInternalProject>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalProjectServiceGetInternalProjectKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalProjectService.getInternalProject(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalProjectService.getInternalProject>>, TError>, "data"> & {
    data: TData;
};
export const useInternalProjectServicePostInternalProject = <TData = Awaited<ReturnType<typeof InternalProjectService.postInternalProject>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalProjectService.postInternalProject>>, unknown, {
    requestBody?: InternalProjectDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalProjectService.postInternalProject(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalProjectService.postInternalProject>>, TError, {
    requestBody?: InternalProjectDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalProjectServiceGetInternalProject1Key = "InternalProjectServiceGetInternalProject1";
export const useInternalProjectServiceGetInternalProject1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalProjectService.getInternalProject1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalProjectService.getInternalProject1>>, unknown, Awaited<ReturnType<typeof InternalProjectService.getInternalProject1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalProjectServiceGetInternalProject1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalProjectService.getInternalProject1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalProjectService.getInternalProject1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalProjectServicePutInternalProject = <TData = Awaited<ReturnType<typeof InternalProjectService.putInternalProject>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalProjectService.putInternalProject>>, unknown, {
    key: number;
    requestBody?: InternalProjectDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalProjectService.putInternalProject(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalProjectService.putInternalProject>>, TError, {
    key: number;
    requestBody?: InternalProjectDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalProjectServiceDeleteInternalProject = <TData = Awaited<ReturnType<typeof InternalProjectService.deleteInternalProject>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalProjectService.deleteInternalProject>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalProjectService.deleteInternalProject(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalProjectService.deleteInternalProject>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalProjectServiceGetInternalProjectQueryKey = "InternalProjectServiceGetInternalProjectQuery";
export const useInternalProjectServiceGetInternalProjectQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalProjectService.getInternalProjectQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalProjectService.getInternalProjectQuery>>, unknown, Awaited<ReturnType<typeof InternalProjectService.getInternalProjectQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalProjectServiceGetInternalProjectQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalProjectService.getInternalProjectQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalProjectService.getInternalProjectQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalProjectFolderServiceGetInternalProjectFolderKey = "InternalProjectFolderServiceGetInternalProjectFolder";
export const useInternalProjectFolderServiceGetInternalProjectFolder = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalProjectFolderService.getInternalProjectFolder>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalProjectFolderService.getInternalProjectFolder>>, unknown, Awaited<ReturnType<typeof InternalProjectFolderService.getInternalProjectFolder>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalProjectFolderServiceGetInternalProjectFolderKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalProjectFolderService.getInternalProjectFolder(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalProjectFolderService.getInternalProjectFolder>>, TError>, "data"> & {
    data: TData;
};
export const useInternalProjectFolderServicePostInternalProjectFolder = <TData = Awaited<ReturnType<typeof InternalProjectFolderService.postInternalProjectFolder>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalProjectFolderService.postInternalProjectFolder>>, unknown, {
    requestBody?: InternalProjectFolderDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalProjectFolderService.postInternalProjectFolder(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalProjectFolderService.postInternalProjectFolder>>, TError, {
    requestBody?: InternalProjectFolderDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalProjectFolderServiceGetInternalProjectFolder1Key = "InternalProjectFolderServiceGetInternalProjectFolder1";
export const useInternalProjectFolderServiceGetInternalProjectFolder1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalProjectFolderService.getInternalProjectFolder1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalProjectFolderService.getInternalProjectFolder1>>, unknown, Awaited<ReturnType<typeof InternalProjectFolderService.getInternalProjectFolder1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalProjectFolderServiceGetInternalProjectFolder1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalProjectFolderService.getInternalProjectFolder1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalProjectFolderService.getInternalProjectFolder1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalProjectFolderServicePutInternalProjectFolder = <TData = Awaited<ReturnType<typeof InternalProjectFolderService.putInternalProjectFolder>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalProjectFolderService.putInternalProjectFolder>>, unknown, {
    key: number;
    requestBody?: InternalProjectFolderDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalProjectFolderService.putInternalProjectFolder(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalProjectFolderService.putInternalProjectFolder>>, TError, {
    key: number;
    requestBody?: InternalProjectFolderDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalProjectFolderServiceDeleteInternalProjectFolder = <TData = Awaited<ReturnType<typeof InternalProjectFolderService.deleteInternalProjectFolder>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalProjectFolderService.deleteInternalProjectFolder>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalProjectFolderService.deleteInternalProjectFolder(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalProjectFolderService.deleteInternalProjectFolder>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalProjectFolderServiceGetInternalProjectFolderQueryKey = "InternalProjectFolderServiceGetInternalProjectFolderQuery";
export const useInternalProjectFolderServiceGetInternalProjectFolderQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalProjectFolderService.getInternalProjectFolderQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalProjectFolderService.getInternalProjectFolderQuery>>, unknown, Awaited<ReturnType<typeof InternalProjectFolderService.getInternalProjectFolderQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalProjectFolderServiceGetInternalProjectFolderQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalProjectFolderService.getInternalProjectFolderQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalProjectFolderService.getInternalProjectFolderQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalPresetConfigurationServiceGetInternalPresetConfigurationKey = "InternalPresetConfigurationServiceGetInternalPresetConfiguration";
export const useInternalPresetConfigurationServiceGetInternalPresetConfiguration = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalPresetConfigurationService.getInternalPresetConfiguration>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalPresetConfigurationService.getInternalPresetConfiguration>>, unknown, Awaited<ReturnType<typeof InternalPresetConfigurationService.getInternalPresetConfiguration>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalPresetConfigurationServiceGetInternalPresetConfigurationKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalPresetConfigurationService.getInternalPresetConfiguration(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalPresetConfigurationService.getInternalPresetConfiguration>>, TError>, "data"> & {
    data: TData;
};
export const useInternalPresetConfigurationServicePostInternalPresetConfiguration = <TData = Awaited<ReturnType<typeof InternalPresetConfigurationService.postInternalPresetConfiguration>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalPresetConfigurationService.postInternalPresetConfiguration>>, unknown, {
    requestBody?: InternalPresetConfigurationDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalPresetConfigurationService.postInternalPresetConfiguration(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalPresetConfigurationService.postInternalPresetConfiguration>>, TError, {
    requestBody?: InternalPresetConfigurationDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalPresetConfigurationServiceGetInternalPresetConfiguration1Key = "InternalPresetConfigurationServiceGetInternalPresetConfiguration1";
export const useInternalPresetConfigurationServiceGetInternalPresetConfiguration1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalPresetConfigurationService.getInternalPresetConfiguration1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalPresetConfigurationService.getInternalPresetConfiguration1>>, unknown, Awaited<ReturnType<typeof InternalPresetConfigurationService.getInternalPresetConfiguration1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalPresetConfigurationServiceGetInternalPresetConfiguration1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalPresetConfigurationService.getInternalPresetConfiguration1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalPresetConfigurationService.getInternalPresetConfiguration1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalPresetConfigurationServicePutInternalPresetConfiguration = <TData = Awaited<ReturnType<typeof InternalPresetConfigurationService.putInternalPresetConfiguration>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalPresetConfigurationService.putInternalPresetConfiguration>>, unknown, {
    key: number;
    requestBody?: InternalPresetConfigurationDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalPresetConfigurationService.putInternalPresetConfiguration(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalPresetConfigurationService.putInternalPresetConfiguration>>, TError, {
    key: number;
    requestBody?: InternalPresetConfigurationDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalPresetConfigurationServiceDeleteInternalPresetConfiguration = <TData = Awaited<ReturnType<typeof InternalPresetConfigurationService.deleteInternalPresetConfiguration>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalPresetConfigurationService.deleteInternalPresetConfiguration>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalPresetConfigurationService.deleteInternalPresetConfiguration(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalPresetConfigurationService.deleteInternalPresetConfiguration>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalPresetConfigurationServiceGetInternalPresetConfigurationQueryKey = "InternalPresetConfigurationServiceGetInternalPresetConfigurationQuery";
export const useInternalPresetConfigurationServiceGetInternalPresetConfigurationQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalPresetConfigurationService.getInternalPresetConfigurationQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalPresetConfigurationService.getInternalPresetConfigurationQuery>>, unknown, Awaited<ReturnType<typeof InternalPresetConfigurationService.getInternalPresetConfigurationQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalPresetConfigurationServiceGetInternalPresetConfigurationQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalPresetConfigurationService.getInternalPresetConfigurationQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalPresetConfigurationService.getInternalPresetConfigurationQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalPostProcessingFeatureServiceGetInternalPostProcessingFeatureKey = "InternalPostProcessingFeatureServiceGetInternalPostProcessingFeature";
export const useInternalPostProcessingFeatureServiceGetInternalPostProcessingFeature = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalPostProcessingFeatureService.getInternalPostProcessingFeature>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalPostProcessingFeatureService.getInternalPostProcessingFeature>>, unknown, Awaited<ReturnType<typeof InternalPostProcessingFeatureService.getInternalPostProcessingFeature>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalPostProcessingFeatureServiceGetInternalPostProcessingFeatureKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalPostProcessingFeatureService.getInternalPostProcessingFeature(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalPostProcessingFeatureService.getInternalPostProcessingFeature>>, TError>, "data"> & {
    data: TData;
};
export const useInternalPostProcessingFeatureServicePostInternalPostProcessingFeature = <TData = Awaited<ReturnType<typeof InternalPostProcessingFeatureService.postInternalPostProcessingFeature>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalPostProcessingFeatureService.postInternalPostProcessingFeature>>, unknown, {
    requestBody?: InternalPostProcessingFeatureDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalPostProcessingFeatureService.postInternalPostProcessingFeature(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalPostProcessingFeatureService.postInternalPostProcessingFeature>>, TError, {
    requestBody?: InternalPostProcessingFeatureDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalPostProcessingFeatureServiceGetInternalPostProcessingFeature1Key = "InternalPostProcessingFeatureServiceGetInternalPostProcessingFeature1";
export const useInternalPostProcessingFeatureServiceGetInternalPostProcessingFeature1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalPostProcessingFeatureService.getInternalPostProcessingFeature1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalPostProcessingFeatureService.getInternalPostProcessingFeature1>>, unknown, Awaited<ReturnType<typeof InternalPostProcessingFeatureService.getInternalPostProcessingFeature1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalPostProcessingFeatureServiceGetInternalPostProcessingFeature1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalPostProcessingFeatureService.getInternalPostProcessingFeature1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalPostProcessingFeatureService.getInternalPostProcessingFeature1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalPostProcessingFeatureServicePutInternalPostProcessingFeature = <TData = Awaited<ReturnType<typeof InternalPostProcessingFeatureService.putInternalPostProcessingFeature>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalPostProcessingFeatureService.putInternalPostProcessingFeature>>, unknown, {
    key: number;
    requestBody?: InternalPostProcessingFeatureDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalPostProcessingFeatureService.putInternalPostProcessingFeature(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalPostProcessingFeatureService.putInternalPostProcessingFeature>>, TError, {
    key: number;
    requestBody?: InternalPostProcessingFeatureDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalPostProcessingFeatureServiceDeleteInternalPostProcessingFeature = <TData = Awaited<ReturnType<typeof InternalPostProcessingFeatureService.deleteInternalPostProcessingFeature>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalPostProcessingFeatureService.deleteInternalPostProcessingFeature>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalPostProcessingFeatureService.deleteInternalPostProcessingFeature(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalPostProcessingFeatureService.deleteInternalPostProcessingFeature>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalPostProcessingFeatureServiceGetInternalPostProcessingFeatureQueryKey = "InternalPostProcessingFeatureServiceGetInternalPostProcessingFeatureQuery";
export const useInternalPostProcessingFeatureServiceGetInternalPostProcessingFeatureQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalPostProcessingFeatureService.getInternalPostProcessingFeatureQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalPostProcessingFeatureService.getInternalPostProcessingFeatureQuery>>, unknown, Awaited<ReturnType<typeof InternalPostProcessingFeatureService.getInternalPostProcessingFeatureQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalPostProcessingFeatureServiceGetInternalPostProcessingFeatureQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalPostProcessingFeatureService.getInternalPostProcessingFeatureQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalPostProcessingFeatureService.getInternalPostProcessingFeatureQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalPermissionServiceGetInternalPermissionKey = "InternalPermissionServiceGetInternalPermission";
export const useInternalPermissionServiceGetInternalPermission = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalPermissionService.getInternalPermission>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalPermissionService.getInternalPermission>>, unknown, Awaited<ReturnType<typeof InternalPermissionService.getInternalPermission>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalPermissionServiceGetInternalPermissionKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalPermissionService.getInternalPermission(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalPermissionService.getInternalPermission>>, TError>, "data"> & {
    data: TData;
};
export const useInternalPermissionServicePostInternalPermission = <TData = Awaited<ReturnType<typeof InternalPermissionService.postInternalPermission>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalPermissionService.postInternalPermission>>, unknown, {
    requestBody?: InternalPermissionDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalPermissionService.postInternalPermission(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalPermissionService.postInternalPermission>>, TError, {
    requestBody?: InternalPermissionDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalPermissionServiceGetInternalPermission1Key = "InternalPermissionServiceGetInternalPermission1";
export const useInternalPermissionServiceGetInternalPermission1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalPermissionService.getInternalPermission1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalPermissionService.getInternalPermission1>>, unknown, Awaited<ReturnType<typeof InternalPermissionService.getInternalPermission1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalPermissionServiceGetInternalPermission1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalPermissionService.getInternalPermission1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalPermissionService.getInternalPermission1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalPermissionServicePutInternalPermission = <TData = Awaited<ReturnType<typeof InternalPermissionService.putInternalPermission>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalPermissionService.putInternalPermission>>, unknown, {
    key: number;
    requestBody?: InternalPermissionDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalPermissionService.putInternalPermission(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalPermissionService.putInternalPermission>>, TError, {
    key: number;
    requestBody?: InternalPermissionDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalPermissionServiceDeleteInternalPermission = <TData = Awaited<ReturnType<typeof InternalPermissionService.deleteInternalPermission>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalPermissionService.deleteInternalPermission>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalPermissionService.deleteInternalPermission(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalPermissionService.deleteInternalPermission>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalPermissionServiceGetInternalPermissionQueryKey = "InternalPermissionServiceGetInternalPermissionQuery";
export const useInternalPermissionServiceGetInternalPermissionQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalPermissionService.getInternalPermissionQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalPermissionService.getInternalPermissionQuery>>, unknown, Awaited<ReturnType<typeof InternalPermissionService.getInternalPermissionQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalPermissionServiceGetInternalPermissionQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalPermissionService.getInternalPermissionQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalPermissionService.getInternalPermissionQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOutputFileSetServiceGetInternalOutputFileSetKey = "InternalOutputFileSetServiceGetInternalOutputFileSet";
export const useInternalOutputFileSetServiceGetInternalOutputFileSet = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOutputFileSetService.getInternalOutputFileSet>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOutputFileSetService.getInternalOutputFileSet>>, unknown, Awaited<ReturnType<typeof InternalOutputFileSetService.getInternalOutputFileSet>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOutputFileSetServiceGetInternalOutputFileSetKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalOutputFileSetService.getInternalOutputFileSet(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOutputFileSetService.getInternalOutputFileSet>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOutputFileSetServicePostInternalOutputFileSet = <TData = Awaited<ReturnType<typeof InternalOutputFileSetService.postInternalOutputFileSet>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOutputFileSetService.postInternalOutputFileSet>>, unknown, {
    requestBody?: InternalOutputFileSetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalOutputFileSetService.postInternalOutputFileSet(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOutputFileSetService.postInternalOutputFileSet>>, TError, {
    requestBody?: InternalOutputFileSetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOutputFileSetServiceGetInternalOutputFileSet1Key = "InternalOutputFileSetServiceGetInternalOutputFileSet1";
export const useInternalOutputFileSetServiceGetInternalOutputFileSet1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOutputFileSetService.getInternalOutputFileSet1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOutputFileSetService.getInternalOutputFileSet1>>, unknown, Awaited<ReturnType<typeof InternalOutputFileSetService.getInternalOutputFileSet1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOutputFileSetServiceGetInternalOutputFileSet1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalOutputFileSetService.getInternalOutputFileSet1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOutputFileSetService.getInternalOutputFileSet1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOutputFileSetServicePutInternalOutputFileSet = <TData = Awaited<ReturnType<typeof InternalOutputFileSetService.putInternalOutputFileSet>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOutputFileSetService.putInternalOutputFileSet>>, unknown, {
    key: number;
    requestBody?: InternalOutputFileSetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalOutputFileSetService.putInternalOutputFileSet(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOutputFileSetService.putInternalOutputFileSet>>, TError, {
    key: number;
    requestBody?: InternalOutputFileSetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOutputFileSetServiceDeleteInternalOutputFileSet = <TData = Awaited<ReturnType<typeof InternalOutputFileSetService.deleteInternalOutputFileSet>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOutputFileSetService.deleteInternalOutputFileSet>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalOutputFileSetService.deleteInternalOutputFileSet(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOutputFileSetService.deleteInternalOutputFileSet>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOutputFileSetServiceGetInternalOutputFileSetQueryKey = "InternalOutputFileSetServiceGetInternalOutputFileSetQuery";
export const useInternalOutputFileSetServiceGetInternalOutputFileSetQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOutputFileSetService.getInternalOutputFileSetQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOutputFileSetService.getInternalOutputFileSetQuery>>, unknown, Awaited<ReturnType<typeof InternalOutputFileSetService.getInternalOutputFileSetQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOutputFileSetServiceGetInternalOutputFileSetQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalOutputFileSetService.getInternalOutputFileSetQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOutputFileSetService.getInternalOutputFileSetQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOutputFileServiceGetInternalOutputFileKey = "InternalOutputFileServiceGetInternalOutputFile";
export const useInternalOutputFileServiceGetInternalOutputFile = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOutputFileService.getInternalOutputFile>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOutputFileService.getInternalOutputFile>>, unknown, Awaited<ReturnType<typeof InternalOutputFileService.getInternalOutputFile>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOutputFileServiceGetInternalOutputFileKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalOutputFileService.getInternalOutputFile(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOutputFileService.getInternalOutputFile>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOutputFileServicePostInternalOutputFile = <TData = Awaited<ReturnType<typeof InternalOutputFileService.postInternalOutputFile>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOutputFileService.postInternalOutputFile>>, unknown, {
    requestBody?: InternalOutputFileDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalOutputFileService.postInternalOutputFile(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOutputFileService.postInternalOutputFile>>, TError, {
    requestBody?: InternalOutputFileDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOutputFileServiceGetInternalOutputFile1Key = "InternalOutputFileServiceGetInternalOutputFile1";
export const useInternalOutputFileServiceGetInternalOutputFile1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOutputFileService.getInternalOutputFile1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOutputFileService.getInternalOutputFile1>>, unknown, Awaited<ReturnType<typeof InternalOutputFileService.getInternalOutputFile1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOutputFileServiceGetInternalOutputFile1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalOutputFileService.getInternalOutputFile1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOutputFileService.getInternalOutputFile1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOutputFileServicePutInternalOutputFile = <TData = Awaited<ReturnType<typeof InternalOutputFileService.putInternalOutputFile>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOutputFileService.putInternalOutputFile>>, unknown, {
    key: number;
    requestBody?: InternalOutputFileDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalOutputFileService.putInternalOutputFile(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOutputFileService.putInternalOutputFile>>, TError, {
    key: number;
    requestBody?: InternalOutputFileDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOutputFileServiceDeleteInternalOutputFile = <TData = Awaited<ReturnType<typeof InternalOutputFileService.deleteInternalOutputFile>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOutputFileService.deleteInternalOutputFile>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalOutputFileService.deleteInternalOutputFile(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOutputFileService.deleteInternalOutputFile>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOutputFileServiceGetInternalOutputFileQueryKey = "InternalOutputFileServiceGetInternalOutputFileQuery";
export const useInternalOutputFileServiceGetInternalOutputFileQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOutputFileService.getInternalOutputFileQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOutputFileService.getInternalOutputFileQuery>>, unknown, Awaited<ReturnType<typeof InternalOutputFileService.getInternalOutputFileQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOutputFileServiceGetInternalOutputFileQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalOutputFileService.getInternalOutputFileQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOutputFileService.getInternalOutputFileQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOrderServiceGetInternalOrderKey = "InternalOrderServiceGetInternalOrder";
export const useInternalOrderServiceGetInternalOrder = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOrderService.getInternalOrder>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOrderService.getInternalOrder>>, unknown, Awaited<ReturnType<typeof InternalOrderService.getInternalOrder>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOrderServiceGetInternalOrderKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalOrderService.getInternalOrder(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOrderService.getInternalOrder>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOrderServicePostInternalOrder = <TData = Awaited<ReturnType<typeof InternalOrderService.postInternalOrder>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOrderService.postInternalOrder>>, unknown, {
    requestBody?: InternalOrderDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalOrderService.postInternalOrder(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOrderService.postInternalOrder>>, TError, {
    requestBody?: InternalOrderDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOrderServiceGetInternalOrder1Key = "InternalOrderServiceGetInternalOrder1";
export const useInternalOrderServiceGetInternalOrder1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOrderService.getInternalOrder1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOrderService.getInternalOrder1>>, unknown, Awaited<ReturnType<typeof InternalOrderService.getInternalOrder1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOrderServiceGetInternalOrder1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalOrderService.getInternalOrder1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOrderService.getInternalOrder1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOrderServicePutInternalOrder = <TData = Awaited<ReturnType<typeof InternalOrderService.putInternalOrder>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOrderService.putInternalOrder>>, unknown, {
    key: number;
    requestBody?: InternalOrderDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalOrderService.putInternalOrder(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOrderService.putInternalOrder>>, TError, {
    key: number;
    requestBody?: InternalOrderDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOrderServiceDeleteInternalOrder = <TData = Awaited<ReturnType<typeof InternalOrderService.deleteInternalOrder>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOrderService.deleteInternalOrder>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalOrderService.deleteInternalOrder(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOrderService.deleteInternalOrder>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOrderServiceGetInternalOrderQueryKey = "InternalOrderServiceGetInternalOrderQuery";
export const useInternalOrderServiceGetInternalOrderQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOrderService.getInternalOrderQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOrderService.getInternalOrderQuery>>, unknown, Awaited<ReturnType<typeof InternalOrderService.getInternalOrderQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOrderServiceGetInternalOrderQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalOrderService.getInternalOrderQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOrderService.getInternalOrderQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOrderlineServiceGetInternalOrderlineKey = "InternalOrderlineServiceGetInternalOrderline";
export const useInternalOrderlineServiceGetInternalOrderline = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOrderlineService.getInternalOrderline>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOrderlineService.getInternalOrderline>>, unknown, Awaited<ReturnType<typeof InternalOrderlineService.getInternalOrderline>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOrderlineServiceGetInternalOrderlineKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalOrderlineService.getInternalOrderline(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOrderlineService.getInternalOrderline>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOrderlineServicePostInternalOrderline = <TData = Awaited<ReturnType<typeof InternalOrderlineService.postInternalOrderline>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOrderlineService.postInternalOrderline>>, unknown, {
    requestBody?: InternalOrderlineDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalOrderlineService.postInternalOrderline(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOrderlineService.postInternalOrderline>>, TError, {
    requestBody?: InternalOrderlineDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOrderlineServiceGetInternalOrderline1Key = "InternalOrderlineServiceGetInternalOrderline1";
export const useInternalOrderlineServiceGetInternalOrderline1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOrderlineService.getInternalOrderline1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOrderlineService.getInternalOrderline1>>, unknown, Awaited<ReturnType<typeof InternalOrderlineService.getInternalOrderline1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOrderlineServiceGetInternalOrderline1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalOrderlineService.getInternalOrderline1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOrderlineService.getInternalOrderline1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOrderlineServicePutInternalOrderline = <TData = Awaited<ReturnType<typeof InternalOrderlineService.putInternalOrderline>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOrderlineService.putInternalOrderline>>, unknown, {
    key: number;
    requestBody?: InternalOrderlineDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalOrderlineService.putInternalOrderline(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOrderlineService.putInternalOrderline>>, TError, {
    key: number;
    requestBody?: InternalOrderlineDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOrderlineServiceDeleteInternalOrderline = <TData = Awaited<ReturnType<typeof InternalOrderlineService.deleteInternalOrderline>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOrderlineService.deleteInternalOrderline>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalOrderlineService.deleteInternalOrderline(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOrderlineService.deleteInternalOrderline>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOrderlineServiceGetInternalOrderlineQueryKey = "InternalOrderlineServiceGetInternalOrderlineQuery";
export const useInternalOrderlineServiceGetInternalOrderlineQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOrderlineService.getInternalOrderlineQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOrderlineService.getInternalOrderlineQuery>>, unknown, Awaited<ReturnType<typeof InternalOrderlineService.getInternalOrderlineQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOrderlineServiceGetInternalOrderlineQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalOrderlineService.getInternalOrderlineQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOrderlineService.getInternalOrderlineQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOrderlinePostProcessServiceGetInternalOrderlinePostProcessKey = "InternalOrderlinePostProcessServiceGetInternalOrderlinePostProcess";
export const useInternalOrderlinePostProcessServiceGetInternalOrderlinePostProcess = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOrderlinePostProcessService.getInternalOrderlinePostProcess>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOrderlinePostProcessService.getInternalOrderlinePostProcess>>, unknown, Awaited<ReturnType<typeof InternalOrderlinePostProcessService.getInternalOrderlinePostProcess>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOrderlinePostProcessServiceGetInternalOrderlinePostProcessKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalOrderlinePostProcessService.getInternalOrderlinePostProcess(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOrderlinePostProcessService.getInternalOrderlinePostProcess>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOrderlinePostProcessServicePostInternalOrderlinePostProcess = <TData = Awaited<ReturnType<typeof InternalOrderlinePostProcessService.postInternalOrderlinePostProcess>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOrderlinePostProcessService.postInternalOrderlinePostProcess>>, unknown, {
    requestBody?: InternalOrderlinePostProcessDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalOrderlinePostProcessService.postInternalOrderlinePostProcess(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOrderlinePostProcessService.postInternalOrderlinePostProcess>>, TError, {
    requestBody?: InternalOrderlinePostProcessDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOrderlinePostProcessServiceGetInternalOrderlinePostProcess1Key = "InternalOrderlinePostProcessServiceGetInternalOrderlinePostProcess1";
export const useInternalOrderlinePostProcessServiceGetInternalOrderlinePostProcess1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOrderlinePostProcessService.getInternalOrderlinePostProcess1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOrderlinePostProcessService.getInternalOrderlinePostProcess1>>, unknown, Awaited<ReturnType<typeof InternalOrderlinePostProcessService.getInternalOrderlinePostProcess1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOrderlinePostProcessServiceGetInternalOrderlinePostProcess1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalOrderlinePostProcessService.getInternalOrderlinePostProcess1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOrderlinePostProcessService.getInternalOrderlinePostProcess1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalOrderlinePostProcessServicePutInternalOrderlinePostProcess = <TData = Awaited<ReturnType<typeof InternalOrderlinePostProcessService.putInternalOrderlinePostProcess>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOrderlinePostProcessService.putInternalOrderlinePostProcess>>, unknown, {
    key: number;
    requestBody?: InternalOrderlinePostProcessDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalOrderlinePostProcessService.putInternalOrderlinePostProcess(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOrderlinePostProcessService.putInternalOrderlinePostProcess>>, TError, {
    key: number;
    requestBody?: InternalOrderlinePostProcessDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOrderlinePostProcessServiceDeleteInternalOrderlinePostProcess = <TData = Awaited<ReturnType<typeof InternalOrderlinePostProcessService.deleteInternalOrderlinePostProcess>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalOrderlinePostProcessService.deleteInternalOrderlinePostProcess>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalOrderlinePostProcessService.deleteInternalOrderlinePostProcess(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalOrderlinePostProcessService.deleteInternalOrderlinePostProcess>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalOrderlinePostProcessServiceGetInternalOrderlinePostProcessQueryKey = "InternalOrderlinePostProcessServiceGetInternalOrderlinePostProcessQuery";
export const useInternalOrderlinePostProcessServiceGetInternalOrderlinePostProcessQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalOrderlinePostProcessService.getInternalOrderlinePostProcessQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalOrderlinePostProcessService.getInternalOrderlinePostProcessQuery>>, unknown, Awaited<ReturnType<typeof InternalOrderlinePostProcessService.getInternalOrderlinePostProcessQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalOrderlinePostProcessServiceGetInternalOrderlinePostProcessQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalOrderlinePostProcessService.getInternalOrderlinePostProcessQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalOrderlinePostProcessService.getInternalOrderlinePostProcessQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModifierValueServiceGetInternalModifierValueKey = "InternalModifierValueServiceGetInternalModifierValue";
export const useInternalModifierValueServiceGetInternalModifierValue = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModifierValueService.getInternalModifierValue>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModifierValueService.getInternalModifierValue>>, unknown, Awaited<ReturnType<typeof InternalModifierValueService.getInternalModifierValue>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModifierValueServiceGetInternalModifierValueKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalModifierValueService.getInternalModifierValue(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModifierValueService.getInternalModifierValue>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModifierValueServicePostInternalModifierValue = <TData = Awaited<ReturnType<typeof InternalModifierValueService.postInternalModifierValue>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModifierValueService.postInternalModifierValue>>, unknown, {
    requestBody?: InternalModifierValueDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalModifierValueService.postInternalModifierValue(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModifierValueService.postInternalModifierValue>>, TError, {
    requestBody?: InternalModifierValueDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModifierValueServiceGetInternalModifierValue1Key = "InternalModifierValueServiceGetInternalModifierValue1";
export const useInternalModifierValueServiceGetInternalModifierValue1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModifierValueService.getInternalModifierValue1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModifierValueService.getInternalModifierValue1>>, unknown, Awaited<ReturnType<typeof InternalModifierValueService.getInternalModifierValue1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModifierValueServiceGetInternalModifierValue1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalModifierValueService.getInternalModifierValue1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModifierValueService.getInternalModifierValue1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModifierValueServicePutInternalModifierValue = <TData = Awaited<ReturnType<typeof InternalModifierValueService.putInternalModifierValue>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModifierValueService.putInternalModifierValue>>, unknown, {
    key: number;
    requestBody?: InternalModifierValueDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalModifierValueService.putInternalModifierValue(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModifierValueService.putInternalModifierValue>>, TError, {
    key: number;
    requestBody?: InternalModifierValueDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModifierValueServiceDeleteInternalModifierValue = <TData = Awaited<ReturnType<typeof InternalModifierValueService.deleteInternalModifierValue>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModifierValueService.deleteInternalModifierValue>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalModifierValueService.deleteInternalModifierValue(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModifierValueService.deleteInternalModifierValue>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModifierValueServiceGetInternalModifierValueQueryKey = "InternalModifierValueServiceGetInternalModifierValueQuery";
export const useInternalModifierValueServiceGetInternalModifierValueQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModifierValueService.getInternalModifierValueQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModifierValueService.getInternalModifierValueQuery>>, unknown, Awaited<ReturnType<typeof InternalModifierValueService.getInternalModifierValueQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModifierValueServiceGetInternalModifierValueQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalModifierValueService.getInternalModifierValueQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModifierValueService.getInternalModifierValueQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModifierServiceGetInternalModifierKey = "InternalModifierServiceGetInternalModifier";
export const useInternalModifierServiceGetInternalModifier = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModifierService.getInternalModifier>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModifierService.getInternalModifier>>, unknown, Awaited<ReturnType<typeof InternalModifierService.getInternalModifier>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModifierServiceGetInternalModifierKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalModifierService.getInternalModifier(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModifierService.getInternalModifier>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModifierServicePostInternalModifier = <TData = Awaited<ReturnType<typeof InternalModifierService.postInternalModifier>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModifierService.postInternalModifier>>, unknown, {
    requestBody?: InternalModifierDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalModifierService.postInternalModifier(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModifierService.postInternalModifier>>, TError, {
    requestBody?: InternalModifierDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModifierServiceGetInternalModifier1Key = "InternalModifierServiceGetInternalModifier1";
export const useInternalModifierServiceGetInternalModifier1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModifierService.getInternalModifier1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModifierService.getInternalModifier1>>, unknown, Awaited<ReturnType<typeof InternalModifierService.getInternalModifier1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModifierServiceGetInternalModifier1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalModifierService.getInternalModifier1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModifierService.getInternalModifier1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModifierServicePutInternalModifier = <TData = Awaited<ReturnType<typeof InternalModifierService.putInternalModifier>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModifierService.putInternalModifier>>, unknown, {
    key: number;
    requestBody?: InternalModifierDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalModifierService.putInternalModifier(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModifierService.putInternalModifier>>, TError, {
    key: number;
    requestBody?: InternalModifierDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModifierServiceDeleteInternalModifier = <TData = Awaited<ReturnType<typeof InternalModifierService.deleteInternalModifier>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModifierService.deleteInternalModifier>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalModifierService.deleteInternalModifier(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModifierService.deleteInternalModifier>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModifierServiceGetInternalModifierQueryKey = "InternalModifierServiceGetInternalModifierQuery";
export const useInternalModifierServiceGetInternalModifierQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModifierService.getInternalModifierQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModifierService.getInternalModifierQuery>>, unknown, Awaited<ReturnType<typeof InternalModifierService.getInternalModifierQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModifierServiceGetInternalModifierQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalModifierService.getInternalModifierQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModifierService.getInternalModifierQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelSurfaceServiceGetInternalModelSurfaceKey = "InternalModelSurfaceServiceGetInternalModelSurface";
export const useInternalModelSurfaceServiceGetInternalModelSurface = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelSurfaceService.getInternalModelSurface>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelSurfaceService.getInternalModelSurface>>, unknown, Awaited<ReturnType<typeof InternalModelSurfaceService.getInternalModelSurface>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelSurfaceServiceGetInternalModelSurfaceKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalModelSurfaceService.getInternalModelSurface(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelSurfaceService.getInternalModelSurface>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelSurfaceServicePostInternalModelSurface = <TData = Awaited<ReturnType<typeof InternalModelSurfaceService.postInternalModelSurface>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelSurfaceService.postInternalModelSurface>>, unknown, {
    requestBody?: InternalModelSurfaceDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalModelSurfaceService.postInternalModelSurface(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelSurfaceService.postInternalModelSurface>>, TError, {
    requestBody?: InternalModelSurfaceDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelSurfaceServiceGetInternalModelSurface1Key = "InternalModelSurfaceServiceGetInternalModelSurface1";
export const useInternalModelSurfaceServiceGetInternalModelSurface1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelSurfaceService.getInternalModelSurface1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelSurfaceService.getInternalModelSurface1>>, unknown, Awaited<ReturnType<typeof InternalModelSurfaceService.getInternalModelSurface1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelSurfaceServiceGetInternalModelSurface1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalModelSurfaceService.getInternalModelSurface1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelSurfaceService.getInternalModelSurface1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelSurfaceServicePutInternalModelSurface = <TData = Awaited<ReturnType<typeof InternalModelSurfaceService.putInternalModelSurface>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelSurfaceService.putInternalModelSurface>>, unknown, {
    key: number;
    requestBody?: InternalModelSurfaceDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalModelSurfaceService.putInternalModelSurface(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelSurfaceService.putInternalModelSurface>>, TError, {
    key: number;
    requestBody?: InternalModelSurfaceDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelSurfaceServiceDeleteInternalModelSurface = <TData = Awaited<ReturnType<typeof InternalModelSurfaceService.deleteInternalModelSurface>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelSurfaceService.deleteInternalModelSurface>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalModelSurfaceService.deleteInternalModelSurface(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelSurfaceService.deleteInternalModelSurface>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelSurfaceServiceGetInternalModelSurfaceQueryKey = "InternalModelSurfaceServiceGetInternalModelSurfaceQuery";
export const useInternalModelSurfaceServiceGetInternalModelSurfaceQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelSurfaceService.getInternalModelSurfaceQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelSurfaceService.getInternalModelSurfaceQuery>>, unknown, Awaited<ReturnType<typeof InternalModelSurfaceService.getInternalModelSurfaceQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelSurfaceServiceGetInternalModelSurfaceQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalModelSurfaceService.getInternalModelSurfaceQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelSurfaceService.getInternalModelSurfaceQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelServiceGetInternalModelKey = "InternalModelServiceGetInternalModel";
export const useInternalModelServiceGetInternalModel = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelService.getInternalModel>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelService.getInternalModel>>, unknown, Awaited<ReturnType<typeof InternalModelService.getInternalModel>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelServiceGetInternalModelKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalModelService.getInternalModel(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelService.getInternalModel>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelServicePostInternalModel = <TData = Awaited<ReturnType<typeof InternalModelService.postInternalModel>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelService.postInternalModel>>, unknown, {
    requestBody?: InternalModelDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalModelService.postInternalModel(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelService.postInternalModel>>, TError, {
    requestBody?: InternalModelDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelServiceGetInternalModel1Key = "InternalModelServiceGetInternalModel1";
export const useInternalModelServiceGetInternalModel1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelService.getInternalModel1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelService.getInternalModel1>>, unknown, Awaited<ReturnType<typeof InternalModelService.getInternalModel1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelServiceGetInternalModel1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalModelService.getInternalModel1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelService.getInternalModel1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelServicePutInternalModel = <TData = Awaited<ReturnType<typeof InternalModelService.putInternalModel>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelService.putInternalModel>>, unknown, {
    key: number;
    requestBody?: InternalModelDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalModelService.putInternalModel(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelService.putInternalModel>>, TError, {
    key: number;
    requestBody?: InternalModelDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelServiceDeleteInternalModel = <TData = Awaited<ReturnType<typeof InternalModelService.deleteInternalModel>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelService.deleteInternalModel>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalModelService.deleteInternalModel(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelService.deleteInternalModel>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelServiceGetInternalModelQueryKey = "InternalModelServiceGetInternalModelQuery";
export const useInternalModelServiceGetInternalModelQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelService.getInternalModelQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelService.getInternalModelQuery>>, unknown, Awaited<ReturnType<typeof InternalModelService.getInternalModelQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelServiceGetInternalModelQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalModelService.getInternalModelQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelService.getInternalModelQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelPlacementpointServiceGetInternalModelPlacementpointKey = "InternalModelPlacementpointServiceGetInternalModelPlacementpoint";
export const useInternalModelPlacementpointServiceGetInternalModelPlacementpoint = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelPlacementpointService.getInternalModelPlacementpoint>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelPlacementpointService.getInternalModelPlacementpoint>>, unknown, Awaited<ReturnType<typeof InternalModelPlacementpointService.getInternalModelPlacementpoint>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelPlacementpointServiceGetInternalModelPlacementpointKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalModelPlacementpointService.getInternalModelPlacementpoint(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelPlacementpointService.getInternalModelPlacementpoint>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelPlacementpointServicePostInternalModelPlacementpoint = <TData = Awaited<ReturnType<typeof InternalModelPlacementpointService.postInternalModelPlacementpoint>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelPlacementpointService.postInternalModelPlacementpoint>>, unknown, {
    requestBody?: InternalModelPlacementpointDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalModelPlacementpointService.postInternalModelPlacementpoint(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelPlacementpointService.postInternalModelPlacementpoint>>, TError, {
    requestBody?: InternalModelPlacementpointDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelPlacementpointServiceGetInternalModelPlacementpoint1Key = "InternalModelPlacementpointServiceGetInternalModelPlacementpoint1";
export const useInternalModelPlacementpointServiceGetInternalModelPlacementpoint1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelPlacementpointService.getInternalModelPlacementpoint1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelPlacementpointService.getInternalModelPlacementpoint1>>, unknown, Awaited<ReturnType<typeof InternalModelPlacementpointService.getInternalModelPlacementpoint1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelPlacementpointServiceGetInternalModelPlacementpoint1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalModelPlacementpointService.getInternalModelPlacementpoint1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelPlacementpointService.getInternalModelPlacementpoint1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelPlacementpointServicePutInternalModelPlacementpoint = <TData = Awaited<ReturnType<typeof InternalModelPlacementpointService.putInternalModelPlacementpoint>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelPlacementpointService.putInternalModelPlacementpoint>>, unknown, {
    key: number;
    requestBody?: InternalModelPlacementpointDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalModelPlacementpointService.putInternalModelPlacementpoint(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelPlacementpointService.putInternalModelPlacementpoint>>, TError, {
    key: number;
    requestBody?: InternalModelPlacementpointDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelPlacementpointServiceDeleteInternalModelPlacementpoint = <TData = Awaited<ReturnType<typeof InternalModelPlacementpointService.deleteInternalModelPlacementpoint>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelPlacementpointService.deleteInternalModelPlacementpoint>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalModelPlacementpointService.deleteInternalModelPlacementpoint(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelPlacementpointService.deleteInternalModelPlacementpoint>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelPlacementpointServiceGetInternalModelPlacementpointQueryKey = "InternalModelPlacementpointServiceGetInternalModelPlacementpointQuery";
export const useInternalModelPlacementpointServiceGetInternalModelPlacementpointQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelPlacementpointService.getInternalModelPlacementpointQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelPlacementpointService.getInternalModelPlacementpointQuery>>, unknown, Awaited<ReturnType<typeof InternalModelPlacementpointService.getInternalModelPlacementpointQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelPlacementpointServiceGetInternalModelPlacementpointQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalModelPlacementpointService.getInternalModelPlacementpointQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelPlacementpointService.getInternalModelPlacementpointQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelPackServiceGetInternalModelPackKey = "InternalModelPackServiceGetInternalModelPack";
export const useInternalModelPackServiceGetInternalModelPack = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelPackService.getInternalModelPack>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelPackService.getInternalModelPack>>, unknown, Awaited<ReturnType<typeof InternalModelPackService.getInternalModelPack>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelPackServiceGetInternalModelPackKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalModelPackService.getInternalModelPack(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelPackService.getInternalModelPack>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelPackServicePostInternalModelPack = <TData = Awaited<ReturnType<typeof InternalModelPackService.postInternalModelPack>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelPackService.postInternalModelPack>>, unknown, {
    requestBody?: InternalModelPackDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalModelPackService.postInternalModelPack(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelPackService.postInternalModelPack>>, TError, {
    requestBody?: InternalModelPackDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelPackServiceGetInternalModelPack1Key = "InternalModelPackServiceGetInternalModelPack1";
export const useInternalModelPackServiceGetInternalModelPack1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelPackService.getInternalModelPack1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelPackService.getInternalModelPack1>>, unknown, Awaited<ReturnType<typeof InternalModelPackService.getInternalModelPack1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelPackServiceGetInternalModelPack1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalModelPackService.getInternalModelPack1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelPackService.getInternalModelPack1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelPackServicePutInternalModelPack = <TData = Awaited<ReturnType<typeof InternalModelPackService.putInternalModelPack>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelPackService.putInternalModelPack>>, unknown, {
    key: number;
    requestBody?: InternalModelPackDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalModelPackService.putInternalModelPack(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelPackService.putInternalModelPack>>, TError, {
    key: number;
    requestBody?: InternalModelPackDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelPackServiceDeleteInternalModelPack = <TData = Awaited<ReturnType<typeof InternalModelPackService.deleteInternalModelPack>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelPackService.deleteInternalModelPack>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalModelPackService.deleteInternalModelPack(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelPackService.deleteInternalModelPack>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelPackServiceGetInternalModelPackQueryKey = "InternalModelPackServiceGetInternalModelPackQuery";
export const useInternalModelPackServiceGetInternalModelPackQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelPackService.getInternalModelPackQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelPackService.getInternalModelPackQuery>>, unknown, Awaited<ReturnType<typeof InternalModelPackService.getInternalModelPackQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelPackServiceGetInternalModelPackQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalModelPackService.getInternalModelPackQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelPackService.getInternalModelPackQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelPackItemServiceGetInternalModelPackItemKey = "InternalModelPackItemServiceGetInternalModelPackItem";
export const useInternalModelPackItemServiceGetInternalModelPackItem = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelPackItemService.getInternalModelPackItem>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelPackItemService.getInternalModelPackItem>>, unknown, Awaited<ReturnType<typeof InternalModelPackItemService.getInternalModelPackItem>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelPackItemServiceGetInternalModelPackItemKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalModelPackItemService.getInternalModelPackItem(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelPackItemService.getInternalModelPackItem>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelPackItemServicePostInternalModelPackItem = <TData = Awaited<ReturnType<typeof InternalModelPackItemService.postInternalModelPackItem>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelPackItemService.postInternalModelPackItem>>, unknown, {
    requestBody?: InternalModelPackItemDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalModelPackItemService.postInternalModelPackItem(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelPackItemService.postInternalModelPackItem>>, TError, {
    requestBody?: InternalModelPackItemDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelPackItemServiceGetInternalModelPackItem1Key = "InternalModelPackItemServiceGetInternalModelPackItem1";
export const useInternalModelPackItemServiceGetInternalModelPackItem1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelPackItemService.getInternalModelPackItem1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelPackItemService.getInternalModelPackItem1>>, unknown, Awaited<ReturnType<typeof InternalModelPackItemService.getInternalModelPackItem1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelPackItemServiceGetInternalModelPackItem1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalModelPackItemService.getInternalModelPackItem1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelPackItemService.getInternalModelPackItem1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalModelPackItemServicePutInternalModelPackItem = <TData = Awaited<ReturnType<typeof InternalModelPackItemService.putInternalModelPackItem>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelPackItemService.putInternalModelPackItem>>, unknown, {
    key: number;
    requestBody?: InternalModelPackItemDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalModelPackItemService.putInternalModelPackItem(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelPackItemService.putInternalModelPackItem>>, TError, {
    key: number;
    requestBody?: InternalModelPackItemDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelPackItemServiceDeleteInternalModelPackItem = <TData = Awaited<ReturnType<typeof InternalModelPackItemService.deleteInternalModelPackItem>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalModelPackItemService.deleteInternalModelPackItem>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalModelPackItemService.deleteInternalModelPackItem(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalModelPackItemService.deleteInternalModelPackItem>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalModelPackItemServiceGetInternalModelPackItemQueryKey = "InternalModelPackItemServiceGetInternalModelPackItemQuery";
export const useInternalModelPackItemServiceGetInternalModelPackItemQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalModelPackItemService.getInternalModelPackItemQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalModelPackItemService.getInternalModelPackItemQuery>>, unknown, Awaited<ReturnType<typeof InternalModelPackItemService.getInternalModelPackItemQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalModelPackItemServiceGetInternalModelPackItemQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalModelPackItemService.getInternalModelPackItemQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalModelPackItemService.getInternalModelPackItemQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalMetadataServiceGetInternalMetadataKey = "InternalMetadataServiceGetInternalMetadata";
export const useInternalMetadataServiceGetInternalMetadata = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalMetadataService.getInternalMetadata>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalMetadataService.getInternalMetadata>>, unknown, Awaited<ReturnType<typeof InternalMetadataService.getInternalMetadata>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalMetadataServiceGetInternalMetadataKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalMetadataService.getInternalMetadata(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalMetadataService.getInternalMetadata>>, TError>, "data"> & {
    data: TData;
};
export const useInternalMetadataServicePostInternalMetadata = <TData = Awaited<ReturnType<typeof InternalMetadataService.postInternalMetadata>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalMetadataService.postInternalMetadata>>, unknown, {
    requestBody?: InternalMetadataDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalMetadataService.postInternalMetadata(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalMetadataService.postInternalMetadata>>, TError, {
    requestBody?: InternalMetadataDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalMetadataServiceGetInternalMetadata1Key = "InternalMetadataServiceGetInternalMetadata1";
export const useInternalMetadataServiceGetInternalMetadata1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalMetadataService.getInternalMetadata1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalMetadataService.getInternalMetadata1>>, unknown, Awaited<ReturnType<typeof InternalMetadataService.getInternalMetadata1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalMetadataServiceGetInternalMetadata1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalMetadataService.getInternalMetadata1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalMetadataService.getInternalMetadata1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalMetadataServicePutInternalMetadata = <TData = Awaited<ReturnType<typeof InternalMetadataService.putInternalMetadata>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalMetadataService.putInternalMetadata>>, unknown, {
    key: number;
    requestBody?: InternalMetadataDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalMetadataService.putInternalMetadata(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalMetadataService.putInternalMetadata>>, TError, {
    key: number;
    requestBody?: InternalMetadataDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalMetadataServiceDeleteInternalMetadata = <TData = Awaited<ReturnType<typeof InternalMetadataService.deleteInternalMetadata>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalMetadataService.deleteInternalMetadata>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalMetadataService.deleteInternalMetadata(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalMetadataService.deleteInternalMetadata>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalMetadataServiceGetInternalMetadataQueryKey = "InternalMetadataServiceGetInternalMetadataQuery";
export const useInternalMetadataServiceGetInternalMetadataQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalMetadataService.getInternalMetadataQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalMetadataService.getInternalMetadataQuery>>, unknown, Awaited<ReturnType<typeof InternalMetadataService.getInternalMetadataQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalMetadataServiceGetInternalMetadataQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalMetadataService.getInternalMetadataQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalMetadataService.getInternalMetadataQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalMaterialServiceGetInternalMaterialKey = "InternalMaterialServiceGetInternalMaterial";
export const useInternalMaterialServiceGetInternalMaterial = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalMaterialService.getInternalMaterial>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalMaterialService.getInternalMaterial>>, unknown, Awaited<ReturnType<typeof InternalMaterialService.getInternalMaterial>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalMaterialServiceGetInternalMaterialKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalMaterialService.getInternalMaterial(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalMaterialService.getInternalMaterial>>, TError>, "data"> & {
    data: TData;
};
export const useInternalMaterialServicePostInternalMaterial = <TData = Awaited<ReturnType<typeof InternalMaterialService.postInternalMaterial>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalMaterialService.postInternalMaterial>>, unknown, {
    requestBody?: InternalMaterialDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalMaterialService.postInternalMaterial(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalMaterialService.postInternalMaterial>>, TError, {
    requestBody?: InternalMaterialDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalMaterialServiceGetInternalMaterial1Key = "InternalMaterialServiceGetInternalMaterial1";
export const useInternalMaterialServiceGetInternalMaterial1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalMaterialService.getInternalMaterial1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalMaterialService.getInternalMaterial1>>, unknown, Awaited<ReturnType<typeof InternalMaterialService.getInternalMaterial1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalMaterialServiceGetInternalMaterial1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalMaterialService.getInternalMaterial1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalMaterialService.getInternalMaterial1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalMaterialServicePutInternalMaterial = <TData = Awaited<ReturnType<typeof InternalMaterialService.putInternalMaterial>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalMaterialService.putInternalMaterial>>, unknown, {
    key: number;
    requestBody?: InternalMaterialDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalMaterialService.putInternalMaterial(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalMaterialService.putInternalMaterial>>, TError, {
    key: number;
    requestBody?: InternalMaterialDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalMaterialServiceDeleteInternalMaterial = <TData = Awaited<ReturnType<typeof InternalMaterialService.deleteInternalMaterial>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalMaterialService.deleteInternalMaterial>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalMaterialService.deleteInternalMaterial(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalMaterialService.deleteInternalMaterial>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalMaterialServiceGetInternalMaterialQueryKey = "InternalMaterialServiceGetInternalMaterialQuery";
export const useInternalMaterialServiceGetInternalMaterialQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalMaterialService.getInternalMaterialQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalMaterialService.getInternalMaterialQuery>>, unknown, Awaited<ReturnType<typeof InternalMaterialService.getInternalMaterialQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalMaterialServiceGetInternalMaterialQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalMaterialService.getInternalMaterialQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalMaterialService.getInternalMaterialQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalLogServiceGetInternalLogKey = "InternalLogServiceGetInternalLog";
export const useInternalLogServiceGetInternalLog = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalLogService.getInternalLog>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalLogService.getInternalLog>>, unknown, Awaited<ReturnType<typeof InternalLogService.getInternalLog>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalLogServiceGetInternalLogKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalLogService.getInternalLog(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalLogService.getInternalLog>>, TError>, "data"> & {
    data: TData;
};
export const useInternalLogServicePostInternalLog = <TData = Awaited<ReturnType<typeof InternalLogService.postInternalLog>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalLogService.postInternalLog>>, unknown, {
    requestBody?: InternalLogDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalLogService.postInternalLog(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalLogService.postInternalLog>>, TError, {
    requestBody?: InternalLogDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalLogServiceGetInternalLog1Key = "InternalLogServiceGetInternalLog1";
export const useInternalLogServiceGetInternalLog1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalLogService.getInternalLog1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalLogService.getInternalLog1>>, unknown, Awaited<ReturnType<typeof InternalLogService.getInternalLog1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalLogServiceGetInternalLog1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalLogService.getInternalLog1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalLogService.getInternalLog1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalLogServicePutInternalLog = <TData = Awaited<ReturnType<typeof InternalLogService.putInternalLog>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalLogService.putInternalLog>>, unknown, {
    key: number;
    requestBody?: InternalLogDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalLogService.putInternalLog(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalLogService.putInternalLog>>, TError, {
    key: number;
    requestBody?: InternalLogDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalLogServiceDeleteInternalLog = <TData = Awaited<ReturnType<typeof InternalLogService.deleteInternalLog>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalLogService.deleteInternalLog>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalLogService.deleteInternalLog(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalLogService.deleteInternalLog>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalLogServiceGetInternalLogQueryKey = "InternalLogServiceGetInternalLogQuery";
export const useInternalLogServiceGetInternalLogQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalLogService.getInternalLogQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalLogService.getInternalLogQuery>>, unknown, Awaited<ReturnType<typeof InternalLogService.getInternalLogQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalLogServiceGetInternalLogQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalLogService.getInternalLogQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalLogService.getInternalLogQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalKingfisherDamuploadServiceGetInternalKingfisherDamuploadKey = "InternalKingfisherDamuploadServiceGetInternalKingfisherDamupload";
export const useInternalKingfisherDamuploadServiceGetInternalKingfisherDamupload = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalKingfisherDamuploadService.getInternalKingfisherDamupload>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalKingfisherDamuploadService.getInternalKingfisherDamupload>>, unknown, Awaited<ReturnType<typeof InternalKingfisherDamuploadService.getInternalKingfisherDamupload>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalKingfisherDamuploadServiceGetInternalKingfisherDamuploadKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalKingfisherDamuploadService.getInternalKingfisherDamupload(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalKingfisherDamuploadService.getInternalKingfisherDamupload>>, TError>, "data"> & {
    data: TData;
};
export const useInternalKingfisherDamuploadServicePostInternalKingfisherDamupload = <TData = Awaited<ReturnType<typeof InternalKingfisherDamuploadService.postInternalKingfisherDamupload>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalKingfisherDamuploadService.postInternalKingfisherDamupload>>, unknown, {
    requestBody?: InternalKingfisherDamuploadDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalKingfisherDamuploadService.postInternalKingfisherDamupload(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalKingfisherDamuploadService.postInternalKingfisherDamupload>>, TError, {
    requestBody?: InternalKingfisherDamuploadDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalKingfisherDamuploadServiceGetInternalKingfisherDamupload1Key = "InternalKingfisherDamuploadServiceGetInternalKingfisherDamupload1";
export const useInternalKingfisherDamuploadServiceGetInternalKingfisherDamupload1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalKingfisherDamuploadService.getInternalKingfisherDamupload1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalKingfisherDamuploadService.getInternalKingfisherDamupload1>>, unknown, Awaited<ReturnType<typeof InternalKingfisherDamuploadService.getInternalKingfisherDamupload1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalKingfisherDamuploadServiceGetInternalKingfisherDamupload1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalKingfisherDamuploadService.getInternalKingfisherDamupload1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalKingfisherDamuploadService.getInternalKingfisherDamupload1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalKingfisherDamuploadServicePutInternalKingfisherDamupload = <TData = Awaited<ReturnType<typeof InternalKingfisherDamuploadService.putInternalKingfisherDamupload>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalKingfisherDamuploadService.putInternalKingfisherDamupload>>, unknown, {
    key: number;
    requestBody?: InternalKingfisherDamuploadDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalKingfisherDamuploadService.putInternalKingfisherDamupload(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalKingfisherDamuploadService.putInternalKingfisherDamupload>>, TError, {
    key: number;
    requestBody?: InternalKingfisherDamuploadDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalKingfisherDamuploadServiceDeleteInternalKingfisherDamupload = <TData = Awaited<ReturnType<typeof InternalKingfisherDamuploadService.deleteInternalKingfisherDamupload>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalKingfisherDamuploadService.deleteInternalKingfisherDamupload>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalKingfisherDamuploadService.deleteInternalKingfisherDamupload(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalKingfisherDamuploadService.deleteInternalKingfisherDamupload>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalKingfisherDamuploadServiceGetInternalKingfisherDamuploadQueryKey = "InternalKingfisherDamuploadServiceGetInternalKingfisherDamuploadQuery";
export const useInternalKingfisherDamuploadServiceGetInternalKingfisherDamuploadQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalKingfisherDamuploadService.getInternalKingfisherDamuploadQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalKingfisherDamuploadService.getInternalKingfisherDamuploadQuery>>, unknown, Awaited<ReturnType<typeof InternalKingfisherDamuploadService.getInternalKingfisherDamuploadQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalKingfisherDamuploadServiceGetInternalKingfisherDamuploadQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalKingfisherDamuploadService.getInternalKingfisherDamuploadQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalKingfisherDamuploadService.getInternalKingfisherDamuploadQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalHostedStoragePlanServiceGetInternalHostedStoragePlanKey = "InternalHostedStoragePlanServiceGetInternalHostedStoragePlan";
export const useInternalHostedStoragePlanServiceGetInternalHostedStoragePlan = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalHostedStoragePlanService.getInternalHostedStoragePlan>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalHostedStoragePlanService.getInternalHostedStoragePlan>>, unknown, Awaited<ReturnType<typeof InternalHostedStoragePlanService.getInternalHostedStoragePlan>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalHostedStoragePlanServiceGetInternalHostedStoragePlanKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalHostedStoragePlanService.getInternalHostedStoragePlan(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalHostedStoragePlanService.getInternalHostedStoragePlan>>, TError>, "data"> & {
    data: TData;
};
export const useInternalHostedStoragePlanServicePostInternalHostedStoragePlan = <TData = Awaited<ReturnType<typeof InternalHostedStoragePlanService.postInternalHostedStoragePlan>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalHostedStoragePlanService.postInternalHostedStoragePlan>>, unknown, {
    requestBody?: InternalHostedStoragePlanDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalHostedStoragePlanService.postInternalHostedStoragePlan(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalHostedStoragePlanService.postInternalHostedStoragePlan>>, TError, {
    requestBody?: InternalHostedStoragePlanDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalHostedStoragePlanServiceGetInternalHostedStoragePlan1Key = "InternalHostedStoragePlanServiceGetInternalHostedStoragePlan1";
export const useInternalHostedStoragePlanServiceGetInternalHostedStoragePlan1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalHostedStoragePlanService.getInternalHostedStoragePlan1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalHostedStoragePlanService.getInternalHostedStoragePlan1>>, unknown, Awaited<ReturnType<typeof InternalHostedStoragePlanService.getInternalHostedStoragePlan1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalHostedStoragePlanServiceGetInternalHostedStoragePlan1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalHostedStoragePlanService.getInternalHostedStoragePlan1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalHostedStoragePlanService.getInternalHostedStoragePlan1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalHostedStoragePlanServicePutInternalHostedStoragePlan = <TData = Awaited<ReturnType<typeof InternalHostedStoragePlanService.putInternalHostedStoragePlan>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalHostedStoragePlanService.putInternalHostedStoragePlan>>, unknown, {
    key: number;
    requestBody?: InternalHostedStoragePlanDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalHostedStoragePlanService.putInternalHostedStoragePlan(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalHostedStoragePlanService.putInternalHostedStoragePlan>>, TError, {
    key: number;
    requestBody?: InternalHostedStoragePlanDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalHostedStoragePlanServiceDeleteInternalHostedStoragePlan = <TData = Awaited<ReturnType<typeof InternalHostedStoragePlanService.deleteInternalHostedStoragePlan>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalHostedStoragePlanService.deleteInternalHostedStoragePlan>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalHostedStoragePlanService.deleteInternalHostedStoragePlan(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalHostedStoragePlanService.deleteInternalHostedStoragePlan>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalHostedStoragePlanServiceGetInternalHostedStoragePlanQueryKey = "InternalHostedStoragePlanServiceGetInternalHostedStoragePlanQuery";
export const useInternalHostedStoragePlanServiceGetInternalHostedStoragePlanQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalHostedStoragePlanService.getInternalHostedStoragePlanQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalHostedStoragePlanService.getInternalHostedStoragePlanQuery>>, unknown, Awaited<ReturnType<typeof InternalHostedStoragePlanService.getInternalHostedStoragePlanQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalHostedStoragePlanServiceGetInternalHostedStoragePlanQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalHostedStoragePlanService.getInternalHostedStoragePlanQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalHostedStoragePlanService.getInternalHostedStoragePlanQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalHostedFileServiceGetInternalHostedFileKey = "InternalHostedFileServiceGetInternalHostedFile";
export const useInternalHostedFileServiceGetInternalHostedFile = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalHostedFileService.getInternalHostedFile>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalHostedFileService.getInternalHostedFile>>, unknown, Awaited<ReturnType<typeof InternalHostedFileService.getInternalHostedFile>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalHostedFileServiceGetInternalHostedFileKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalHostedFileService.getInternalHostedFile(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalHostedFileService.getInternalHostedFile>>, TError>, "data"> & {
    data: TData;
};
export const useInternalHostedFileServicePostInternalHostedFile = <TData = Awaited<ReturnType<typeof InternalHostedFileService.postInternalHostedFile>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalHostedFileService.postInternalHostedFile>>, unknown, {
    requestBody?: InternalHostedFileDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalHostedFileService.postInternalHostedFile(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalHostedFileService.postInternalHostedFile>>, TError, {
    requestBody?: InternalHostedFileDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalHostedFileServiceGetInternalHostedFile1Key = "InternalHostedFileServiceGetInternalHostedFile1";
export const useInternalHostedFileServiceGetInternalHostedFile1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalHostedFileService.getInternalHostedFile1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalHostedFileService.getInternalHostedFile1>>, unknown, Awaited<ReturnType<typeof InternalHostedFileService.getInternalHostedFile1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalHostedFileServiceGetInternalHostedFile1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalHostedFileService.getInternalHostedFile1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalHostedFileService.getInternalHostedFile1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalHostedFileServicePutInternalHostedFile = <TData = Awaited<ReturnType<typeof InternalHostedFileService.putInternalHostedFile>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalHostedFileService.putInternalHostedFile>>, unknown, {
    key: number;
    requestBody?: InternalHostedFileDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalHostedFileService.putInternalHostedFile(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalHostedFileService.putInternalHostedFile>>, TError, {
    key: number;
    requestBody?: InternalHostedFileDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalHostedFileServiceDeleteInternalHostedFile = <TData = Awaited<ReturnType<typeof InternalHostedFileService.deleteInternalHostedFile>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalHostedFileService.deleteInternalHostedFile>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalHostedFileService.deleteInternalHostedFile(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalHostedFileService.deleteInternalHostedFile>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalHostedFileServiceGetInternalHostedFileQueryKey = "InternalHostedFileServiceGetInternalHostedFileQuery";
export const useInternalHostedFileServiceGetInternalHostedFileQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalHostedFileService.getInternalHostedFileQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalHostedFileService.getInternalHostedFileQuery>>, unknown, Awaited<ReturnType<typeof InternalHostedFileService.getInternalHostedFileQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalHostedFileServiceGetInternalHostedFileQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalHostedFileService.getInternalHostedFileQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalHostedFileService.getInternalHostedFileQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalFilenameTokenServiceGetInternalFilenameTokenKey = "InternalFilenameTokenServiceGetInternalFilenameToken";
export const useInternalFilenameTokenServiceGetInternalFilenameToken = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalFilenameTokenService.getInternalFilenameToken>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalFilenameTokenService.getInternalFilenameToken>>, unknown, Awaited<ReturnType<typeof InternalFilenameTokenService.getInternalFilenameToken>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalFilenameTokenServiceGetInternalFilenameTokenKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalFilenameTokenService.getInternalFilenameToken(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalFilenameTokenService.getInternalFilenameToken>>, TError>, "data"> & {
    data: TData;
};
export const useInternalFilenameTokenServicePostInternalFilenameToken = <TData = Awaited<ReturnType<typeof InternalFilenameTokenService.postInternalFilenameToken>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalFilenameTokenService.postInternalFilenameToken>>, unknown, {
    requestBody?: InternalFilenameTokenDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalFilenameTokenService.postInternalFilenameToken(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalFilenameTokenService.postInternalFilenameToken>>, TError, {
    requestBody?: InternalFilenameTokenDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalFilenameTokenServiceGetInternalFilenameToken1Key = "InternalFilenameTokenServiceGetInternalFilenameToken1";
export const useInternalFilenameTokenServiceGetInternalFilenameToken1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalFilenameTokenService.getInternalFilenameToken1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalFilenameTokenService.getInternalFilenameToken1>>, unknown, Awaited<ReturnType<typeof InternalFilenameTokenService.getInternalFilenameToken1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalFilenameTokenServiceGetInternalFilenameToken1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalFilenameTokenService.getInternalFilenameToken1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalFilenameTokenService.getInternalFilenameToken1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalFilenameTokenServicePutInternalFilenameToken = <TData = Awaited<ReturnType<typeof InternalFilenameTokenService.putInternalFilenameToken>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalFilenameTokenService.putInternalFilenameToken>>, unknown, {
    key: number;
    requestBody?: InternalFilenameTokenDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalFilenameTokenService.putInternalFilenameToken(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalFilenameTokenService.putInternalFilenameToken>>, TError, {
    key: number;
    requestBody?: InternalFilenameTokenDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalFilenameTokenServiceDeleteInternalFilenameToken = <TData = Awaited<ReturnType<typeof InternalFilenameTokenService.deleteInternalFilenameToken>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalFilenameTokenService.deleteInternalFilenameToken>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalFilenameTokenService.deleteInternalFilenameToken(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalFilenameTokenService.deleteInternalFilenameToken>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalFilenameTokenServiceGetInternalFilenameTokenQueryKey = "InternalFilenameTokenServiceGetInternalFilenameTokenQuery";
export const useInternalFilenameTokenServiceGetInternalFilenameTokenQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalFilenameTokenService.getInternalFilenameTokenQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalFilenameTokenService.getInternalFilenameTokenQuery>>, unknown, Awaited<ReturnType<typeof InternalFilenameTokenService.getInternalFilenameTokenQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalFilenameTokenServiceGetInternalFilenameTokenQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalFilenameTokenService.getInternalFilenameTokenQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalFilenameTokenService.getInternalFilenameTokenQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalEmailTemplateServiceGetInternalEmailTemplateKey = "InternalEmailTemplateServiceGetInternalEmailTemplate";
export const useInternalEmailTemplateServiceGetInternalEmailTemplate = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalEmailTemplateService.getInternalEmailTemplate>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalEmailTemplateService.getInternalEmailTemplate>>, unknown, Awaited<ReturnType<typeof InternalEmailTemplateService.getInternalEmailTemplate>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalEmailTemplateServiceGetInternalEmailTemplateKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalEmailTemplateService.getInternalEmailTemplate(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalEmailTemplateService.getInternalEmailTemplate>>, TError>, "data"> & {
    data: TData;
};
export const useInternalEmailTemplateServicePostInternalEmailTemplate = <TData = Awaited<ReturnType<typeof InternalEmailTemplateService.postInternalEmailTemplate>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalEmailTemplateService.postInternalEmailTemplate>>, unknown, {
    requestBody?: InternalEmailTemplateDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalEmailTemplateService.postInternalEmailTemplate(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalEmailTemplateService.postInternalEmailTemplate>>, TError, {
    requestBody?: InternalEmailTemplateDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalEmailTemplateServiceGetInternalEmailTemplate1Key = "InternalEmailTemplateServiceGetInternalEmailTemplate1";
export const useInternalEmailTemplateServiceGetInternalEmailTemplate1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalEmailTemplateService.getInternalEmailTemplate1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalEmailTemplateService.getInternalEmailTemplate1>>, unknown, Awaited<ReturnType<typeof InternalEmailTemplateService.getInternalEmailTemplate1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalEmailTemplateServiceGetInternalEmailTemplate1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalEmailTemplateService.getInternalEmailTemplate1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalEmailTemplateService.getInternalEmailTemplate1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalEmailTemplateServicePutInternalEmailTemplate = <TData = Awaited<ReturnType<typeof InternalEmailTemplateService.putInternalEmailTemplate>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalEmailTemplateService.putInternalEmailTemplate>>, unknown, {
    key: number;
    requestBody?: InternalEmailTemplateDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalEmailTemplateService.putInternalEmailTemplate(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalEmailTemplateService.putInternalEmailTemplate>>, TError, {
    key: number;
    requestBody?: InternalEmailTemplateDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalEmailTemplateServiceDeleteInternalEmailTemplate = <TData = Awaited<ReturnType<typeof InternalEmailTemplateService.deleteInternalEmailTemplate>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalEmailTemplateService.deleteInternalEmailTemplate>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalEmailTemplateService.deleteInternalEmailTemplate(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalEmailTemplateService.deleteInternalEmailTemplate>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalEmailTemplateServiceGetInternalEmailTemplateQueryKey = "InternalEmailTemplateServiceGetInternalEmailTemplateQuery";
export const useInternalEmailTemplateServiceGetInternalEmailTemplateQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalEmailTemplateService.getInternalEmailTemplateQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalEmailTemplateService.getInternalEmailTemplateQuery>>, unknown, Awaited<ReturnType<typeof InternalEmailTemplateService.getInternalEmailTemplateQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalEmailTemplateServiceGetInternalEmailTemplateQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalEmailTemplateService.getInternalEmailTemplateQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalEmailTemplateService.getInternalEmailTemplateQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodSftpServiceGetInternalDeliveryMethodSftpKey = "InternalDeliveryMethodSftpServiceGetInternalDeliveryMethodSftp";
export const useInternalDeliveryMethodSftpServiceGetInternalDeliveryMethodSftp = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftp>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftp>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftp>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodSftpServiceGetInternalDeliveryMethodSftpKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftp(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftp>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodSftpServicePostInternalDeliveryMethodSftp = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.postInternalDeliveryMethodSftp>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.postInternalDeliveryMethodSftp>>, unknown, {
    requestBody?: InternalDeliveryMethodSftpDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalDeliveryMethodSftpService.postInternalDeliveryMethodSftp(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.postInternalDeliveryMethodSftp>>, TError, {
    requestBody?: InternalDeliveryMethodSftpDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodSftpServiceGetInternalDeliveryMethodSftp1Key = "InternalDeliveryMethodSftpServiceGetInternalDeliveryMethodSftp1";
export const useInternalDeliveryMethodSftpServiceGetInternalDeliveryMethodSftp1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftp1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftp1>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftp1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodSftpServiceGetInternalDeliveryMethodSftp1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftp1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftp1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodSftpServicePutInternalDeliveryMethodSftp = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.putInternalDeliveryMethodSftp>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.putInternalDeliveryMethodSftp>>, unknown, {
    key: number;
    requestBody?: InternalDeliveryMethodSftpDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalDeliveryMethodSftpService.putInternalDeliveryMethodSftp(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.putInternalDeliveryMethodSftp>>, TError, {
    key: number;
    requestBody?: InternalDeliveryMethodSftpDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodSftpServiceDeleteInternalDeliveryMethodSftp = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.deleteInternalDeliveryMethodSftp>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.deleteInternalDeliveryMethodSftp>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalDeliveryMethodSftpService.deleteInternalDeliveryMethodSftp(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.deleteInternalDeliveryMethodSftp>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodSftpServiceGetInternalDeliveryMethodSftpQueryKey = "InternalDeliveryMethodSftpServiceGetInternalDeliveryMethodSftpQuery";
export const useInternalDeliveryMethodSftpServiceGetInternalDeliveryMethodSftpQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftpQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftpQuery>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftpQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodSftpServiceGetInternalDeliveryMethodSftpQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftpQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodSftpService.getInternalDeliveryMethodSftpQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodS3ServiceGetInternalDeliveryMethodS3Key = "InternalDeliveryMethodS3ServiceGetInternalDeliveryMethodS3";
export const useInternalDeliveryMethodS3ServiceGetInternalDeliveryMethodS3 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.getInternalDeliveryMethodS3>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.getInternalDeliveryMethodS3>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.getInternalDeliveryMethodS3>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodS3ServiceGetInternalDeliveryMethodS3Key, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalDeliveryMethodS3Service.getInternalDeliveryMethodS3(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.getInternalDeliveryMethodS3>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodS3ServicePostInternalDeliveryMethodS3 = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.postInternalDeliveryMethodS3>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.postInternalDeliveryMethodS3>>, unknown, {
    requestBody?: InternalDeliveryMethodS3DTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalDeliveryMethodS3Service.postInternalDeliveryMethodS3(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.postInternalDeliveryMethodS3>>, TError, {
    requestBody?: InternalDeliveryMethodS3DTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodS3ServiceGetInternalDeliveryMethodS31Key = "InternalDeliveryMethodS3ServiceGetInternalDeliveryMethodS31";
export const useInternalDeliveryMethodS3ServiceGetInternalDeliveryMethodS31 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.getInternalDeliveryMethodS31>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.getInternalDeliveryMethodS31>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.getInternalDeliveryMethodS31>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodS3ServiceGetInternalDeliveryMethodS31Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalDeliveryMethodS3Service.getInternalDeliveryMethodS31(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.getInternalDeliveryMethodS31>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodS3ServicePutInternalDeliveryMethodS3 = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.putInternalDeliveryMethodS3>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.putInternalDeliveryMethodS3>>, unknown, {
    key: number;
    requestBody?: InternalDeliveryMethodS3DTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalDeliveryMethodS3Service.putInternalDeliveryMethodS3(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.putInternalDeliveryMethodS3>>, TError, {
    key: number;
    requestBody?: InternalDeliveryMethodS3DTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodS3ServiceDeleteInternalDeliveryMethodS3 = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.deleteInternalDeliveryMethodS3>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.deleteInternalDeliveryMethodS3>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalDeliveryMethodS3Service.deleteInternalDeliveryMethodS3(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.deleteInternalDeliveryMethodS3>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodS3ServiceGetInternalDeliveryMethodS3QueryKey = "InternalDeliveryMethodS3ServiceGetInternalDeliveryMethodS3Query";
export const useInternalDeliveryMethodS3ServiceGetInternalDeliveryMethodS3Query = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.getInternalDeliveryMethodS3Query>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.getInternalDeliveryMethodS3Query>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.getInternalDeliveryMethodS3Query>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodS3ServiceGetInternalDeliveryMethodS3QueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalDeliveryMethodS3Service.getInternalDeliveryMethodS3Query(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodS3Service.getInternalDeliveryMethodS3Query>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodHostedStorageServiceGetInternalDeliveryMethodHostedStorageKey = "InternalDeliveryMethodHostedStorageServiceGetInternalDeliveryMethodHostedStorage";
export const useInternalDeliveryMethodHostedStorageServiceGetInternalDeliveryMethodHostedStorage = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorage>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorage>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorage>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodHostedStorageServiceGetInternalDeliveryMethodHostedStorageKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorage(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorage>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodHostedStorageServicePostInternalDeliveryMethodHostedStorage = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.postInternalDeliveryMethodHostedStorage>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.postInternalDeliveryMethodHostedStorage>>, unknown, {
    requestBody?: InternalDeliveryMethodHostedStorageDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalDeliveryMethodHostedStorageService.postInternalDeliveryMethodHostedStorage(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.postInternalDeliveryMethodHostedStorage>>, TError, {
    requestBody?: InternalDeliveryMethodHostedStorageDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodHostedStorageServiceGetInternalDeliveryMethodHostedStorage1Key = "InternalDeliveryMethodHostedStorageServiceGetInternalDeliveryMethodHostedStorage1";
export const useInternalDeliveryMethodHostedStorageServiceGetInternalDeliveryMethodHostedStorage1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorage1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorage1>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorage1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodHostedStorageServiceGetInternalDeliveryMethodHostedStorage1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorage1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorage1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodHostedStorageServicePutInternalDeliveryMethodHostedStorage = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.putInternalDeliveryMethodHostedStorage>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.putInternalDeliveryMethodHostedStorage>>, unknown, {
    key: number;
    requestBody?: InternalDeliveryMethodHostedStorageDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalDeliveryMethodHostedStorageService.putInternalDeliveryMethodHostedStorage(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.putInternalDeliveryMethodHostedStorage>>, TError, {
    key: number;
    requestBody?: InternalDeliveryMethodHostedStorageDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodHostedStorageServiceDeleteInternalDeliveryMethodHostedStorage = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.deleteInternalDeliveryMethodHostedStorage>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.deleteInternalDeliveryMethodHostedStorage>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalDeliveryMethodHostedStorageService.deleteInternalDeliveryMethodHostedStorage(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.deleteInternalDeliveryMethodHostedStorage>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodHostedStorageServiceGetInternalDeliveryMethodHostedStorageQueryKey = "InternalDeliveryMethodHostedStorageServiceGetInternalDeliveryMethodHostedStorageQuery";
export const useInternalDeliveryMethodHostedStorageServiceGetInternalDeliveryMethodHostedStorageQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorageQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorageQuery>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorageQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodHostedStorageServiceGetInternalDeliveryMethodHostedStorageQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorageQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodHostedStorageService.getInternalDeliveryMethodHostedStorageQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodFtpServiceGetInternalDeliveryMethodFtpKey = "InternalDeliveryMethodFtpServiceGetInternalDeliveryMethodFtp";
export const useInternalDeliveryMethodFtpServiceGetInternalDeliveryMethodFtp = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtp>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtp>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtp>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodFtpServiceGetInternalDeliveryMethodFtpKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtp(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtp>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodFtpServicePostInternalDeliveryMethodFtp = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.postInternalDeliveryMethodFtp>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.postInternalDeliveryMethodFtp>>, unknown, {
    requestBody?: InternalDeliveryMethodFtpDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalDeliveryMethodFtpService.postInternalDeliveryMethodFtp(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.postInternalDeliveryMethodFtp>>, TError, {
    requestBody?: InternalDeliveryMethodFtpDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodFtpServiceGetInternalDeliveryMethodFtp1Key = "InternalDeliveryMethodFtpServiceGetInternalDeliveryMethodFtp1";
export const useInternalDeliveryMethodFtpServiceGetInternalDeliveryMethodFtp1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtp1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtp1>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtp1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodFtpServiceGetInternalDeliveryMethodFtp1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtp1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtp1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodFtpServicePutInternalDeliveryMethodFtp = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.putInternalDeliveryMethodFtp>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.putInternalDeliveryMethodFtp>>, unknown, {
    key: number;
    requestBody?: InternalDeliveryMethodFtpDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalDeliveryMethodFtpService.putInternalDeliveryMethodFtp(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.putInternalDeliveryMethodFtp>>, TError, {
    key: number;
    requestBody?: InternalDeliveryMethodFtpDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodFtpServiceDeleteInternalDeliveryMethodFtp = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.deleteInternalDeliveryMethodFtp>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.deleteInternalDeliveryMethodFtp>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalDeliveryMethodFtpService.deleteInternalDeliveryMethodFtp(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.deleteInternalDeliveryMethodFtp>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodFtpServiceGetInternalDeliveryMethodFtpQueryKey = "InternalDeliveryMethodFtpServiceGetInternalDeliveryMethodFtpQuery";
export const useInternalDeliveryMethodFtpServiceGetInternalDeliveryMethodFtpQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtpQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtpQuery>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtpQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodFtpServiceGetInternalDeliveryMethodFtpQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtpQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodFtpService.getInternalDeliveryMethodFtpQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodCustomApiServiceGetInternalDeliveryMethodCustomApiKey = "InternalDeliveryMethodCustomApiServiceGetInternalDeliveryMethodCustomApi";
export const useInternalDeliveryMethodCustomApiServiceGetInternalDeliveryMethodCustomApi = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApi>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApi>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApi>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodCustomApiServiceGetInternalDeliveryMethodCustomApiKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApi(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApi>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodCustomApiServicePostInternalDeliveryMethodCustomApi = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.postInternalDeliveryMethodCustomApi>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.postInternalDeliveryMethodCustomApi>>, unknown, {
    requestBody?: InternalDeliveryMethodCustomApiDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalDeliveryMethodCustomApiService.postInternalDeliveryMethodCustomApi(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.postInternalDeliveryMethodCustomApi>>, TError, {
    requestBody?: InternalDeliveryMethodCustomApiDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodCustomApiServiceGetInternalDeliveryMethodCustomApi1Key = "InternalDeliveryMethodCustomApiServiceGetInternalDeliveryMethodCustomApi1";
export const useInternalDeliveryMethodCustomApiServiceGetInternalDeliveryMethodCustomApi1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApi1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApi1>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApi1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodCustomApiServiceGetInternalDeliveryMethodCustomApi1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApi1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApi1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodCustomApiServicePutInternalDeliveryMethodCustomApi = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.putInternalDeliveryMethodCustomApi>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.putInternalDeliveryMethodCustomApi>>, unknown, {
    key: number;
    requestBody?: InternalDeliveryMethodCustomApiDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalDeliveryMethodCustomApiService.putInternalDeliveryMethodCustomApi(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.putInternalDeliveryMethodCustomApi>>, TError, {
    key: number;
    requestBody?: InternalDeliveryMethodCustomApiDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodCustomApiServiceDeleteInternalDeliveryMethodCustomApi = <TData = Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.deleteInternalDeliveryMethodCustomApi>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.deleteInternalDeliveryMethodCustomApi>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalDeliveryMethodCustomApiService.deleteInternalDeliveryMethodCustomApi(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.deleteInternalDeliveryMethodCustomApi>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryMethodCustomApiServiceGetInternalDeliveryMethodCustomApiQueryKey = "InternalDeliveryMethodCustomApiServiceGetInternalDeliveryMethodCustomApiQuery";
export const useInternalDeliveryMethodCustomApiServiceGetInternalDeliveryMethodCustomApiQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApiQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApiQuery>>, unknown, Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApiQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryMethodCustomApiServiceGetInternalDeliveryMethodCustomApiQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApiQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryMethodCustomApiService.getInternalDeliveryMethodCustomApiQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryConfigurationServiceGetInternalDeliveryConfigurationKey = "InternalDeliveryConfigurationServiceGetInternalDeliveryConfiguration";
export const useInternalDeliveryConfigurationServiceGetInternalDeliveryConfiguration = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryConfigurationService.getInternalDeliveryConfiguration>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryConfigurationService.getInternalDeliveryConfiguration>>, unknown, Awaited<ReturnType<typeof InternalDeliveryConfigurationService.getInternalDeliveryConfiguration>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryConfigurationServiceGetInternalDeliveryConfigurationKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalDeliveryConfigurationService.getInternalDeliveryConfiguration(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryConfigurationService.getInternalDeliveryConfiguration>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryConfigurationServicePostInternalDeliveryConfiguration = <TData = Awaited<ReturnType<typeof InternalDeliveryConfigurationService.postInternalDeliveryConfiguration>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryConfigurationService.postInternalDeliveryConfiguration>>, unknown, {
    requestBody?: InternalDeliveryConfigurationDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalDeliveryConfigurationService.postInternalDeliveryConfiguration(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryConfigurationService.postInternalDeliveryConfiguration>>, TError, {
    requestBody?: InternalDeliveryConfigurationDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryConfigurationServiceGetInternalDeliveryConfiguration1Key = "InternalDeliveryConfigurationServiceGetInternalDeliveryConfiguration1";
export const useInternalDeliveryConfigurationServiceGetInternalDeliveryConfiguration1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryConfigurationService.getInternalDeliveryConfiguration1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryConfigurationService.getInternalDeliveryConfiguration1>>, unknown, Awaited<ReturnType<typeof InternalDeliveryConfigurationService.getInternalDeliveryConfiguration1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryConfigurationServiceGetInternalDeliveryConfiguration1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalDeliveryConfigurationService.getInternalDeliveryConfiguration1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryConfigurationService.getInternalDeliveryConfiguration1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDeliveryConfigurationServicePutInternalDeliveryConfiguration = <TData = Awaited<ReturnType<typeof InternalDeliveryConfigurationService.putInternalDeliveryConfiguration>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryConfigurationService.putInternalDeliveryConfiguration>>, unknown, {
    key: number;
    requestBody?: InternalDeliveryConfigurationDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalDeliveryConfigurationService.putInternalDeliveryConfiguration(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryConfigurationService.putInternalDeliveryConfiguration>>, TError, {
    key: number;
    requestBody?: InternalDeliveryConfigurationDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryConfigurationServiceDeleteInternalDeliveryConfiguration = <TData = Awaited<ReturnType<typeof InternalDeliveryConfigurationService.deleteInternalDeliveryConfiguration>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDeliveryConfigurationService.deleteInternalDeliveryConfiguration>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalDeliveryConfigurationService.deleteInternalDeliveryConfiguration(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDeliveryConfigurationService.deleteInternalDeliveryConfiguration>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDeliveryConfigurationServiceGetInternalDeliveryConfigurationQueryKey = "InternalDeliveryConfigurationServiceGetInternalDeliveryConfigurationQuery";
export const useInternalDeliveryConfigurationServiceGetInternalDeliveryConfigurationQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDeliveryConfigurationService.getInternalDeliveryConfigurationQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDeliveryConfigurationService.getInternalDeliveryConfigurationQuery>>, unknown, Awaited<ReturnType<typeof InternalDeliveryConfigurationService.getInternalDeliveryConfigurationQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDeliveryConfigurationServiceGetInternalDeliveryConfigurationQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalDeliveryConfigurationService.getInternalDeliveryConfigurationQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDeliveryConfigurationService.getInternalDeliveryConfigurationQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDefaultMetadataFieldServiceGetInternalDefaultMetadataFieldKey = "InternalDefaultMetadataFieldServiceGetInternalDefaultMetadataField";
export const useInternalDefaultMetadataFieldServiceGetInternalDefaultMetadataField = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.getInternalDefaultMetadataField>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.getInternalDefaultMetadataField>>, unknown, Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.getInternalDefaultMetadataField>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDefaultMetadataFieldServiceGetInternalDefaultMetadataFieldKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalDefaultMetadataFieldService.getInternalDefaultMetadataField(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.getInternalDefaultMetadataField>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDefaultMetadataFieldServicePostInternalDefaultMetadataField = <TData = Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.postInternalDefaultMetadataField>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.postInternalDefaultMetadataField>>, unknown, {
    requestBody?: InternalDefaultMetadataFieldDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalDefaultMetadataFieldService.postInternalDefaultMetadataField(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.postInternalDefaultMetadataField>>, TError, {
    requestBody?: InternalDefaultMetadataFieldDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDefaultMetadataFieldServiceGetInternalDefaultMetadataField1Key = "InternalDefaultMetadataFieldServiceGetInternalDefaultMetadataField1";
export const useInternalDefaultMetadataFieldServiceGetInternalDefaultMetadataField1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.getInternalDefaultMetadataField1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.getInternalDefaultMetadataField1>>, unknown, Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.getInternalDefaultMetadataField1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDefaultMetadataFieldServiceGetInternalDefaultMetadataField1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalDefaultMetadataFieldService.getInternalDefaultMetadataField1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.getInternalDefaultMetadataField1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalDefaultMetadataFieldServicePutInternalDefaultMetadataField = <TData = Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.putInternalDefaultMetadataField>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.putInternalDefaultMetadataField>>, unknown, {
    key: number;
    requestBody?: InternalDefaultMetadataFieldDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalDefaultMetadataFieldService.putInternalDefaultMetadataField(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.putInternalDefaultMetadataField>>, TError, {
    key: number;
    requestBody?: InternalDefaultMetadataFieldDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDefaultMetadataFieldServiceDeleteInternalDefaultMetadataField = <TData = Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.deleteInternalDefaultMetadataField>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.deleteInternalDefaultMetadataField>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalDefaultMetadataFieldService.deleteInternalDefaultMetadataField(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.deleteInternalDefaultMetadataField>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalDefaultMetadataFieldServiceGetInternalDefaultMetadataFieldQueryKey = "InternalDefaultMetadataFieldServiceGetInternalDefaultMetadataFieldQuery";
export const useInternalDefaultMetadataFieldServiceGetInternalDefaultMetadataFieldQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.getInternalDefaultMetadataFieldQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.getInternalDefaultMetadataFieldQuery>>, unknown, Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.getInternalDefaultMetadataFieldQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalDefaultMetadataFieldServiceGetInternalDefaultMetadataFieldQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalDefaultMetadataFieldService.getInternalDefaultMetadataFieldQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalDefaultMetadataFieldService.getInternalDefaultMetadataFieldQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalClientServiceGetInternalClientKey = "InternalClientServiceGetInternalClient";
export const useInternalClientServiceGetInternalClient = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalClientService.getInternalClient>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalClientService.getInternalClient>>, unknown, Awaited<ReturnType<typeof InternalClientService.getInternalClient>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalClientServiceGetInternalClientKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalClientService.getInternalClient(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalClientService.getInternalClient>>, TError>, "data"> & {
    data: TData;
};
export const useInternalClientServicePostInternalClient = <TData = Awaited<ReturnType<typeof InternalClientService.postInternalClient>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalClientService.postInternalClient>>, unknown, {
    requestBody?: InternalClientDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalClientService.postInternalClient(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalClientService.postInternalClient>>, TError, {
    requestBody?: InternalClientDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalClientServiceGetInternalClient1Key = "InternalClientServiceGetInternalClient1";
export const useInternalClientServiceGetInternalClient1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalClientService.getInternalClient1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalClientService.getInternalClient1>>, unknown, Awaited<ReturnType<typeof InternalClientService.getInternalClient1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalClientServiceGetInternalClient1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalClientService.getInternalClient1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalClientService.getInternalClient1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalClientServicePutInternalClient = <TData = Awaited<ReturnType<typeof InternalClientService.putInternalClient>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalClientService.putInternalClient>>, unknown, {
    key: number;
    requestBody?: InternalClientDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalClientService.putInternalClient(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalClientService.putInternalClient>>, TError, {
    key: number;
    requestBody?: InternalClientDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalClientServiceDeleteInternalClient = <TData = Awaited<ReturnType<typeof InternalClientService.deleteInternalClient>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalClientService.deleteInternalClient>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalClientService.deleteInternalClient(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalClientService.deleteInternalClient>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalClientServiceGetInternalClientQueryKey = "InternalClientServiceGetInternalClientQuery";
export const useInternalClientServiceGetInternalClientQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalClientService.getInternalClientQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalClientService.getInternalClientQuery>>, unknown, Awaited<ReturnType<typeof InternalClientService.getInternalClientQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalClientServiceGetInternalClientQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalClientService.getInternalClientQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalClientService.getInternalClientQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalAssetServiceGetInternalAssetKey = "InternalAssetServiceGetInternalAsset";
export const useInternalAssetServiceGetInternalAsset = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalAssetService.getInternalAsset>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalAssetService.getInternalAsset>>, unknown, Awaited<ReturnType<typeof InternalAssetService.getInternalAsset>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalAssetServiceGetInternalAssetKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalAssetService.getInternalAsset(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalAssetService.getInternalAsset>>, TError>, "data"> & {
    data: TData;
};
export const useInternalAssetServicePostInternalAsset = <TData = Awaited<ReturnType<typeof InternalAssetService.postInternalAsset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalAssetService.postInternalAsset>>, unknown, {
    requestBody?: InternalAssetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalAssetService.postInternalAsset(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalAssetService.postInternalAsset>>, TError, {
    requestBody?: InternalAssetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalAssetServiceGetInternalAsset1Key = "InternalAssetServiceGetInternalAsset1";
export const useInternalAssetServiceGetInternalAsset1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalAssetService.getInternalAsset1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalAssetService.getInternalAsset1>>, unknown, Awaited<ReturnType<typeof InternalAssetService.getInternalAsset1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalAssetServiceGetInternalAsset1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalAssetService.getInternalAsset1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalAssetService.getInternalAsset1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalAssetServicePutInternalAsset = <TData = Awaited<ReturnType<typeof InternalAssetService.putInternalAsset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalAssetService.putInternalAsset>>, unknown, {
    key: number;
    requestBody?: InternalAssetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalAssetService.putInternalAsset(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalAssetService.putInternalAsset>>, TError, {
    key: number;
    requestBody?: InternalAssetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalAssetServiceDeleteInternalAsset = <TData = Awaited<ReturnType<typeof InternalAssetService.deleteInternalAsset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalAssetService.deleteInternalAsset>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalAssetService.deleteInternalAsset(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalAssetService.deleteInternalAsset>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalAssetServiceGetInternalAssetQueryKey = "InternalAssetServiceGetInternalAssetQuery";
export const useInternalAssetServiceGetInternalAssetQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalAssetService.getInternalAssetQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalAssetService.getInternalAssetQuery>>, unknown, Awaited<ReturnType<typeof InternalAssetService.getInternalAssetQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalAssetServiceGetInternalAssetQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalAssetService.getInternalAssetQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalAssetService.getInternalAssetQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalAssetFilterPresetServiceGetInternalAssetFilterPresetKey = "InternalAssetFilterPresetServiceGetInternalAssetFilterPreset";
export const useInternalAssetFilterPresetServiceGetInternalAssetFilterPreset = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalAssetFilterPresetService.getInternalAssetFilterPreset>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalAssetFilterPresetService.getInternalAssetFilterPreset>>, unknown, Awaited<ReturnType<typeof InternalAssetFilterPresetService.getInternalAssetFilterPreset>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalAssetFilterPresetServiceGetInternalAssetFilterPresetKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalAssetFilterPresetService.getInternalAssetFilterPreset(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalAssetFilterPresetService.getInternalAssetFilterPreset>>, TError>, "data"> & {
    data: TData;
};
export const useInternalAssetFilterPresetServicePostInternalAssetFilterPreset = <TData = Awaited<ReturnType<typeof InternalAssetFilterPresetService.postInternalAssetFilterPreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalAssetFilterPresetService.postInternalAssetFilterPreset>>, unknown, {
    requestBody?: InternalAssetFilterPresetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalAssetFilterPresetService.postInternalAssetFilterPreset(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalAssetFilterPresetService.postInternalAssetFilterPreset>>, TError, {
    requestBody?: InternalAssetFilterPresetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalAssetFilterPresetServiceGetInternalAssetFilterPreset1Key = "InternalAssetFilterPresetServiceGetInternalAssetFilterPreset1";
export const useInternalAssetFilterPresetServiceGetInternalAssetFilterPreset1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalAssetFilterPresetService.getInternalAssetFilterPreset1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalAssetFilterPresetService.getInternalAssetFilterPreset1>>, unknown, Awaited<ReturnType<typeof InternalAssetFilterPresetService.getInternalAssetFilterPreset1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalAssetFilterPresetServiceGetInternalAssetFilterPreset1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalAssetFilterPresetService.getInternalAssetFilterPreset1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalAssetFilterPresetService.getInternalAssetFilterPreset1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalAssetFilterPresetServicePutInternalAssetFilterPreset = <TData = Awaited<ReturnType<typeof InternalAssetFilterPresetService.putInternalAssetFilterPreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalAssetFilterPresetService.putInternalAssetFilterPreset>>, unknown, {
    key: number;
    requestBody?: InternalAssetFilterPresetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalAssetFilterPresetService.putInternalAssetFilterPreset(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalAssetFilterPresetService.putInternalAssetFilterPreset>>, TError, {
    key: number;
    requestBody?: InternalAssetFilterPresetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalAssetFilterPresetServiceDeleteInternalAssetFilterPreset = <TData = Awaited<ReturnType<typeof InternalAssetFilterPresetService.deleteInternalAssetFilterPreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalAssetFilterPresetService.deleteInternalAssetFilterPreset>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalAssetFilterPresetService.deleteInternalAssetFilterPreset(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalAssetFilterPresetService.deleteInternalAssetFilterPreset>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalAssetFilterPresetServiceGetInternalAssetFilterPresetQueryKey = "InternalAssetFilterPresetServiceGetInternalAssetFilterPresetQuery";
export const useInternalAssetFilterPresetServiceGetInternalAssetFilterPresetQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalAssetFilterPresetService.getInternalAssetFilterPresetQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalAssetFilterPresetService.getInternalAssetFilterPresetQuery>>, unknown, Awaited<ReturnType<typeof InternalAssetFilterPresetService.getInternalAssetFilterPresetQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalAssetFilterPresetServiceGetInternalAssetFilterPresetQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalAssetFilterPresetService.getInternalAssetFilterPresetQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalAssetFilterPresetService.getInternalAssetFilterPresetQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalAssetApprovalHistoryServiceGetInternalAssetApprovalHistoryKey = "InternalAssetApprovalHistoryServiceGetInternalAssetApprovalHistory";
export const useInternalAssetApprovalHistoryServiceGetInternalAssetApprovalHistory = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.getInternalAssetApprovalHistory>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.getInternalAssetApprovalHistory>>, unknown, Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.getInternalAssetApprovalHistory>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalAssetApprovalHistoryServiceGetInternalAssetApprovalHistoryKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalAssetApprovalHistoryService.getInternalAssetApprovalHistory(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.getInternalAssetApprovalHistory>>, TError>, "data"> & {
    data: TData;
};
export const useInternalAssetApprovalHistoryServicePostInternalAssetApprovalHistory = <TData = Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.postInternalAssetApprovalHistory>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.postInternalAssetApprovalHistory>>, unknown, {
    requestBody?: InternalAssetApprovalHistoryDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalAssetApprovalHistoryService.postInternalAssetApprovalHistory(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.postInternalAssetApprovalHistory>>, TError, {
    requestBody?: InternalAssetApprovalHistoryDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalAssetApprovalHistoryServiceGetInternalAssetApprovalHistory1Key = "InternalAssetApprovalHistoryServiceGetInternalAssetApprovalHistory1";
export const useInternalAssetApprovalHistoryServiceGetInternalAssetApprovalHistory1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.getInternalAssetApprovalHistory1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.getInternalAssetApprovalHistory1>>, unknown, Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.getInternalAssetApprovalHistory1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalAssetApprovalHistoryServiceGetInternalAssetApprovalHistory1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalAssetApprovalHistoryService.getInternalAssetApprovalHistory1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.getInternalAssetApprovalHistory1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalAssetApprovalHistoryServicePutInternalAssetApprovalHistory = <TData = Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.putInternalAssetApprovalHistory>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.putInternalAssetApprovalHistory>>, unknown, {
    key: number;
    requestBody?: InternalAssetApprovalHistoryDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalAssetApprovalHistoryService.putInternalAssetApprovalHistory(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.putInternalAssetApprovalHistory>>, TError, {
    key: number;
    requestBody?: InternalAssetApprovalHistoryDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalAssetApprovalHistoryServiceDeleteInternalAssetApprovalHistory = <TData = Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.deleteInternalAssetApprovalHistory>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.deleteInternalAssetApprovalHistory>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalAssetApprovalHistoryService.deleteInternalAssetApprovalHistory(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.deleteInternalAssetApprovalHistory>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalAssetApprovalHistoryServiceGetInternalAssetApprovalHistoryQueryKey = "InternalAssetApprovalHistoryServiceGetInternalAssetApprovalHistoryQuery";
export const useInternalAssetApprovalHistoryServiceGetInternalAssetApprovalHistoryQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.getInternalAssetApprovalHistoryQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.getInternalAssetApprovalHistoryQuery>>, unknown, Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.getInternalAssetApprovalHistoryQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalAssetApprovalHistoryServiceGetInternalAssetApprovalHistoryQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalAssetApprovalHistoryService.getInternalAssetApprovalHistoryQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalAssetApprovalHistoryService.getInternalAssetApprovalHistoryQuery>>, TError>, "data"> & {
    data: TData;
};
export const useInternalArmodelSettingServiceGetInternalArmodelSettingKey = "InternalArmodelSettingServiceGetInternalArmodelSetting";
export const useInternalArmodelSettingServiceGetInternalArmodelSetting = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalArmodelSettingService.getInternalArmodelSetting>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalArmodelSettingService.getInternalArmodelSetting>>, unknown, Awaited<ReturnType<typeof InternalArmodelSettingService.getInternalArmodelSetting>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalArmodelSettingServiceGetInternalArmodelSettingKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => InternalArmodelSettingService.getInternalArmodelSetting(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalArmodelSettingService.getInternalArmodelSetting>>, TError>, "data"> & {
    data: TData;
};
export const useInternalArmodelSettingServicePostInternalArmodelSetting = <TData = Awaited<ReturnType<typeof InternalArmodelSettingService.postInternalArmodelSetting>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalArmodelSettingService.postInternalArmodelSetting>>, unknown, {
    requestBody?: InternalArmodelSettingDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => InternalArmodelSettingService.postInternalArmodelSetting(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalArmodelSettingService.postInternalArmodelSetting>>, TError, {
    requestBody?: InternalArmodelSettingDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalArmodelSettingServiceGetInternalArmodelSetting1Key = "InternalArmodelSettingServiceGetInternalArmodelSetting1";
export const useInternalArmodelSettingServiceGetInternalArmodelSetting1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalArmodelSettingService.getInternalArmodelSetting1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalArmodelSettingService.getInternalArmodelSetting1>>, unknown, Awaited<ReturnType<typeof InternalArmodelSettingService.getInternalArmodelSetting1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalArmodelSettingServiceGetInternalArmodelSetting1Key, ...(queryKey ?? [{ key }])], queryFn: () => InternalArmodelSettingService.getInternalArmodelSetting1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalArmodelSettingService.getInternalArmodelSetting1>>, TError>, "data"> & {
    data: TData;
};
export const useInternalArmodelSettingServicePutInternalArmodelSetting = <TData = Awaited<ReturnType<typeof InternalArmodelSettingService.putInternalArmodelSetting>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalArmodelSettingService.putInternalArmodelSetting>>, unknown, {
    key: number;
    requestBody?: InternalArmodelSettingDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => InternalArmodelSettingService.putInternalArmodelSetting(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalArmodelSettingService.putInternalArmodelSetting>>, TError, {
    key: number;
    requestBody?: InternalArmodelSettingDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalArmodelSettingServiceDeleteInternalArmodelSetting = <TData = Awaited<ReturnType<typeof InternalArmodelSettingService.deleteInternalArmodelSetting>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof InternalArmodelSettingService.deleteInternalArmodelSetting>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => InternalArmodelSettingService.deleteInternalArmodelSetting(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof InternalArmodelSettingService.deleteInternalArmodelSetting>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useInternalArmodelSettingServiceGetInternalArmodelSettingQueryKey = "InternalArmodelSettingServiceGetInternalArmodelSettingQuery";
export const useInternalArmodelSettingServiceGetInternalArmodelSettingQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof InternalArmodelSettingService.getInternalArmodelSettingQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof InternalArmodelSettingService.getInternalArmodelSettingQuery>>, unknown, Awaited<ReturnType<typeof InternalArmodelSettingService.getInternalArmodelSettingQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useInternalArmodelSettingServiceGetInternalArmodelSettingQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => InternalArmodelSettingService.getInternalArmodelSettingQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof InternalArmodelSettingService.getInternalArmodelSettingQuery>>, TError>, "data"> & {
    data: TData;
};
export const useHostedFileServicePostOrderOrderlineHostedFile = <TData = Awaited<ReturnType<typeof HostedFileService.postOrderOrderlineHostedFile>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof HostedFileService.postOrderOrderlineHostedFile>>, unknown, {
    orderId: number;
    orderlineId: number;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ orderId, orderlineId, formData }) => HostedFileService.postOrderOrderlineHostedFile(orderId, orderlineId, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof HostedFileService.postOrderOrderlineHostedFile>>, TError, {
    orderId: number;
    orderlineId: number;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useHostedFileServiceGetOrderOrderlineHostedFileAllKey = "HostedFileServiceGetOrderOrderlineHostedFileAll";
export const useHostedFileServiceGetOrderOrderlineHostedFileAll = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFileAll>>, TError = unknown>({ orderlineId, orderId }: {
    orderlineId: number;
    orderId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFileAll>>, unknown, Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFileAll>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useHostedFileServiceGetOrderOrderlineHostedFileAllKey, ...(queryKey ?? [{ orderlineId, orderId }])], queryFn: () => HostedFileService.getOrderOrderlineHostedFileAll(orderlineId, orderId), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFileAll>>, TError>, "data"> & {
    data: TData;
};
export const useHostedFileServiceGetOrderOrderlineHostedFileKey = "HostedFileServiceGetOrderOrderlineHostedFile";
export const useHostedFileServiceGetOrderOrderlineHostedFile = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFile>>, TError = unknown>({ orderlineId, hostedFileId, orderId }: {
    orderlineId: number;
    hostedFileId: number;
    orderId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFile>>, unknown, Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFile>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useHostedFileServiceGetOrderOrderlineHostedFileKey, ...(queryKey ?? [{ orderlineId, hostedFileId, orderId }])], queryFn: () => HostedFileService.getOrderOrderlineHostedFile(orderlineId, hostedFileId, orderId), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFile>>, TError>, "data"> & {
    data: TData;
};
export const useHostedFileServiceGetOrderOrderlineHostedFileZipKey = "HostedFileServiceGetOrderOrderlineHostedFileZip";
export const useHostedFileServiceGetOrderOrderlineHostedFileZip = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFileZip>>, TError = unknown>({ orderlineId, orderId, hostedFileIds }: {
    orderlineId: number;
    orderId: string;
    hostedFileIds?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFileZip>>, unknown, Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFileZip>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useHostedFileServiceGetOrderOrderlineHostedFileZipKey, ...(queryKey ?? [{ orderlineId, orderId, hostedFileIds }])], queryFn: () => HostedFileService.getOrderOrderlineHostedFileZip(orderlineId, orderId, hostedFileIds), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFileZip>>, TError>, "data"> & {
    data: TData;
};
export const useHostedFileServiceGetOrderOrderlineHostedFileThumbnailKey = "HostedFileServiceGetOrderOrderlineHostedFileThumbnail";
export const useHostedFileServiceGetOrderOrderlineHostedFileThumbnail = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFileThumbnail>>, TError = unknown>({ hostedFileId, size, filetype, orderId, orderlineId }: {
    hostedFileId: number;
    size: number;
    filetype: string;
    orderId: string;
    orderlineId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFileThumbnail>>, unknown, Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFileThumbnail>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useHostedFileServiceGetOrderOrderlineHostedFileThumbnailKey, ...(queryKey ?? [{ hostedFileId, size, filetype, orderId, orderlineId }])], queryFn: () => HostedFileService.getOrderOrderlineHostedFileThumbnail(hostedFileId, size, filetype, orderId, orderlineId), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof HostedFileService.getOrderOrderlineHostedFileThumbnail>>, TError>, "data"> & {
    data: TData;
};
export const useHelpServicePostHelpJserror = <TData = Awaited<ReturnType<typeof HelpService.postHelpJserror>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof HelpService.postHelpJserror>>, unknown, {
    message?: string;
    stack?: string;
    name?: string;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ message, stack, name }) => HelpService.postHelpJserror(message, stack, name), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof HelpService.postHelpJserror>>, TError, {
    message?: string;
    stack?: string;
    name?: string;
}, TContext>, "data"> & {
    data: TData;
};
export const useHelpServicePostHelpTemplate = <TData = Awaited<ReturnType<typeof HelpService.postHelpTemplate>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof HelpService.postHelpTemplate>>, unknown, {
    message?: string;
    templateId?: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ message, templateId }) => HelpService.postHelpTemplate(message, templateId), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof HelpService.postHelpTemplate>>, TError, {
    message?: string;
    templateId?: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useHelpServicePostHelpProject = <TData = Awaited<ReturnType<typeof HelpService.postHelpProject>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof HelpService.postHelpProject>>, unknown, {
    message?: string;
    projectId?: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ message, projectId }) => HelpService.postHelpProject(message, projectId), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof HelpService.postHelpProject>>, TError, {
    message?: string;
    projectId?: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useFilenameTokenServiceGetFilenameTokenKey = "FilenameTokenServiceGetFilenameToken";
export const useFilenameTokenServiceGetFilenameToken = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof FilenameTokenService.getFilenameToken>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof FilenameTokenService.getFilenameToken>>, unknown, Awaited<ReturnType<typeof FilenameTokenService.getFilenameToken>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useFilenameTokenServiceGetFilenameTokenKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => FilenameTokenService.getFilenameToken(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof FilenameTokenService.getFilenameToken>>, TError>, "data"> & {
    data: TData;
};
export const useExportServiceGetExportKingfisherProjectsKey = "ExportServiceGetExportKingfisherProjects";
export const useExportServiceGetExportKingfisherProjects = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ExportService.getExportKingfisherProjects>>, TError = unknown>({ start, end }: {
    start?: string;
    end?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ExportService.getExportKingfisherProjects>>, unknown, Awaited<ReturnType<typeof ExportService.getExportKingfisherProjects>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useExportServiceGetExportKingfisherProjectsKey, ...(queryKey ?? [{ start, end }])], queryFn: () => ExportService.getExportKingfisherProjects(start, end), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ExportService.getExportKingfisherProjects>>, TError>, "data"> & {
    data: TData;
};
export const useExportServiceGetExportKingfisherAssetsKey = "ExportServiceGetExportKingfisherAssets";
export const useExportServiceGetExportKingfisherAssets = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ExportService.getExportKingfisherAssets>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ExportService.getExportKingfisherAssets>>, unknown, Awaited<ReturnType<typeof ExportService.getExportKingfisherAssets>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useExportServiceGetExportKingfisherAssetsKey, ...(queryKey ?? [])], queryFn: () => ExportService.getExportKingfisherAssets(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ExportService.getExportKingfisherAssets>>, TError>, "data"> & {
    data: TData;
};
export const useExportServicePostExportKingfisherAssets = <TData = Awaited<ReturnType<typeof ExportService.postExportKingfisherAssets>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ExportService.postExportKingfisherAssets>>, unknown, {
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ formData }) => ExportService.postExportKingfisherAssets(formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ExportService.postExportKingfisherAssets>>, TError, {
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useDeliveryConfigurationServiceGetDeliveryConfigurationKey = "DeliveryConfigurationServiceGetDeliveryConfiguration";
export const useDeliveryConfigurationServiceGetDeliveryConfiguration = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof DeliveryConfigurationService.getDeliveryConfiguration>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof DeliveryConfigurationService.getDeliveryConfiguration>>, unknown, Awaited<ReturnType<typeof DeliveryConfigurationService.getDeliveryConfiguration>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useDeliveryConfigurationServiceGetDeliveryConfigurationKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => DeliveryConfigurationService.getDeliveryConfiguration(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof DeliveryConfigurationService.getDeliveryConfiguration>>, TError>, "data"> & {
    data: TData;
};
export const useDeliveryConfigurationServiceGetDeliveryConfiguration1Key = "DeliveryConfigurationServiceGetDeliveryConfiguration1";
export const useDeliveryConfigurationServiceGetDeliveryConfiguration1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof DeliveryConfigurationService.getDeliveryConfiguration1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof DeliveryConfigurationService.getDeliveryConfiguration1>>, unknown, Awaited<ReturnType<typeof DeliveryConfigurationService.getDeliveryConfiguration1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useDeliveryConfigurationServiceGetDeliveryConfiguration1Key, ...(queryKey ?? [{ key }])], queryFn: () => DeliveryConfigurationService.getDeliveryConfiguration1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof DeliveryConfigurationService.getDeliveryConfiguration1>>, TError>, "data"> & {
    data: TData;
};
export const useCutoutServiceGetCutoutFilterKey = "CutoutServiceGetCutoutFilter";
export const useCutoutServiceGetCutoutFilter = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof CutoutService.getCutoutFilter>>, TError = unknown>({ name, top, skip, filter, select, orderby, expand, count }: {
    name?: string;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof CutoutService.getCutoutFilter>>, unknown, Awaited<ReturnType<typeof CutoutService.getCutoutFilter>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useCutoutServiceGetCutoutFilterKey, ...(queryKey ?? [{ name, top, skip, filter, select, orderby, expand, count }])], queryFn: () => CutoutService.getCutoutFilter(name, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof CutoutService.getCutoutFilter>>, TError>, "data"> & {
    data: TData;
};
export const useCutoutServiceGetCutoutKey = "CutoutServiceGetCutout";
export const useCutoutServiceGetCutout = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof CutoutService.getCutout>>, TError = unknown>({ query, top, skip, filter, select, orderby, expand, count }: {
    query?: string;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof CutoutService.getCutout>>, unknown, Awaited<ReturnType<typeof CutoutService.getCutout>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useCutoutServiceGetCutoutKey, ...(queryKey ?? [{ query, top, skip, filter, select, orderby, expand, count }])], queryFn: () => CutoutService.getCutout(query, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof CutoutService.getCutout>>, TError>, "data"> & {
    data: TData;
};
export const useConfigurationServicePostConfigurationItems = <TData = Awaited<ReturnType<typeof ConfigurationService.postConfigurationItems>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ConfigurationService.postConfigurationItems>>, unknown, {
    clientId?: number;
    requestBody?: string;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ clientId, requestBody }) => ConfigurationService.postConfigurationItems(clientId, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ConfigurationService.postConfigurationItems>>, TError, {
    clientId?: number;
    requestBody?: string;
}, TContext>, "data"> & {
    data: TData;
};
export const useClientServiceGetClientKey = "ClientServiceGetClient";
export const useClientServiceGetClient = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ClientService.getClient>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ClientService.getClient>>, unknown, Awaited<ReturnType<typeof ClientService.getClient>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useClientServiceGetClientKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => ClientService.getClient(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ClientService.getClient>>, TError>, "data"> & {
    data: TData;
};
export const useClientServiceGetClient1Key = "ClientServiceGetClient1";
export const useClientServiceGetClient1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ClientService.getClient1>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ClientService.getClient1>>, unknown, Awaited<ReturnType<typeof ClientService.getClient1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useClientServiceGetClient1Key, ...(queryKey ?? [{ key }])], queryFn: () => ClientService.getClient1(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ClientService.getClient1>>, TError>, "data"> & {
    data: TData;
};
export const useClientServicePostClient = <TData = Awaited<ReturnType<typeof ClientService.postClient>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ClientService.postClient>>, unknown, {
    key: string;
    requestBody?: ClientDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => ClientService.postClient(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ClientService.postClient>>, TError, {
    key: string;
    requestBody?: ClientDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useClientServicePutClient = <TData = Awaited<ReturnType<typeof ClientService.putClient>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ClientService.putClient>>, unknown, {
    key: number;
    requestBody?: ClientDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => ClientService.putClient(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ClientService.putClient>>, TError, {
    key: number;
    requestBody?: ClientDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useClientServiceDeleteClient = <TData = Awaited<ReturnType<typeof ClientService.deleteClient>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof ClientService.deleteClient>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => ClientService.deleteClient(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof ClientService.deleteClient>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useClientServiceGetQueryKey = "ClientServiceGetQuery";
export const useClientServiceGetQuery = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof ClientService.getQuery>>, TError = unknown>({ clientId, query }: {
    clientId?: number;
    query?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof ClientService.getQuery>>, unknown, Awaited<ReturnType<typeof ClientService.getQuery>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useClientServiceGetQueryKey, ...(queryKey ?? [{ clientId, query }])], queryFn: () => ClientService.getQuery(clientId, query), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof ClientService.getQuery>>, TError>, "data"> & {
    data: TData;
};
export const useAuthenticateServiceGetApiAuthenticatePingKey = "AuthenticateServiceGetApiAuthenticatePing";
export const useAuthenticateServiceGetApiAuthenticatePing = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof AuthenticateService.getApiAuthenticatePing>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof AuthenticateService.getApiAuthenticatePing>>, unknown, Awaited<ReturnType<typeof AuthenticateService.getApiAuthenticatePing>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useAuthenticateServiceGetApiAuthenticatePingKey, ...(queryKey ?? [])], queryFn: () => AuthenticateService.getApiAuthenticatePing(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof AuthenticateService.getApiAuthenticatePing>>, TError>, "data"> & {
    data: TData;
};
export const useAuthenticateServicePostApiAuthenticateLogin = <TData = Awaited<ReturnType<typeof AuthenticateService.postApiAuthenticateLogin>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof AuthenticateService.postApiAuthenticateLogin>>, unknown, {
    requestBody?: LoginModel;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => AuthenticateService.postApiAuthenticateLogin(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof AuthenticateService.postApiAuthenticateLogin>>, TError, {
    requestBody?: LoginModel;
}, TContext>, "data"> & {
    data: TData;
};
export const useAuthenticateServicePostApiAuthenticateRefresh = <TData = Awaited<ReturnType<typeof AuthenticateService.postApiAuthenticateRefresh>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof AuthenticateService.postApiAuthenticateRefresh>>, unknown, {
    requestBody?: Token;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => AuthenticateService.postApiAuthenticateRefresh(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof AuthenticateService.postApiAuthenticateRefresh>>, TError, {
    requestBody?: Token;
}, TContext>, "data"> & {
    data: TData;
};
export const useAssetServiceGetAssetTypesAllKey = "AssetServiceGetAssetTypesAll";
export const useAssetServiceGetAssetTypesAll = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof AssetService.getAssetTypesAll>>, TError = unknown>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof AssetService.getAssetTypesAll>>, unknown, Awaited<ReturnType<typeof AssetService.getAssetTypesAll>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useAssetServiceGetAssetTypesAllKey, ...(queryKey ?? [])], queryFn: () => AssetService.getAssetTypesAll(), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof AssetService.getAssetTypesAll>>, TError>, "data"> & {
    data: TData;
};
export const useAssetServiceGetAssetMetadataNamesKey = "AssetServiceGetAssetMetadataNames";
export const useAssetServiceGetAssetMetadataNames = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof AssetService.getAssetMetadataNames>>, TError = unknown>({ name }: {
    name?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof AssetService.getAssetMetadataNames>>, unknown, Awaited<ReturnType<typeof AssetService.getAssetMetadataNames>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useAssetServiceGetAssetMetadataNamesKey, ...(queryKey ?? [{ name }])], queryFn: () => AssetService.getAssetMetadataNames(name), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof AssetService.getAssetMetadataNames>>, TError>, "data"> & {
    data: TData;
};
export const useAssetServiceGetAssetMetadataValuesKey = "AssetServiceGetAssetMetadataValues";
export const useAssetServiceGetAssetMetadataValues = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof AssetService.getAssetMetadataValues>>, TError = unknown>({ name }: {
    name: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof AssetService.getAssetMetadataValues>>, unknown, Awaited<ReturnType<typeof AssetService.getAssetMetadataValues>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useAssetServiceGetAssetMetadataValuesKey, ...(queryKey ?? [{ name }])], queryFn: () => AssetService.getAssetMetadataValues(name), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof AssetService.getAssetMetadataValues>>, TError>, "data"> & {
    data: TData;
};
export const useAssetProjectServiceGetAssetProjectKey = "AssetProjectServiceGetAssetProject";
export const useAssetProjectServiceGetAssetProject = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof AssetProjectService.getAssetProject>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof AssetProjectService.getAssetProject>>, unknown, Awaited<ReturnType<typeof AssetProjectService.getAssetProject>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useAssetProjectServiceGetAssetProjectKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => AssetProjectService.getAssetProject(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof AssetProjectService.getAssetProject>>, TError>, "data"> & {
    data: TData;
};
export const useAssetProjectServicePostAssetProject = <TData = Awaited<ReturnType<typeof AssetProjectService.postAssetProject>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof AssetProjectService.postAssetProject>>, unknown, {
    formData?: {
        ClientId?: number;
        Title?: string;
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ formData }) => AssetProjectService.postAssetProject(formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof AssetProjectService.postAssetProject>>, TError, {
    formData?: {
        ClientId?: number;
        Title?: string;
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useAssetProjectServiceGetAssetProject1Key = "AssetProjectServiceGetAssetProject1";
export const useAssetProjectServiceGetAssetProject1 = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof AssetProjectService.getAssetProject1>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof AssetProjectService.getAssetProject1>>, unknown, Awaited<ReturnType<typeof AssetProjectService.getAssetProject1>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useAssetProjectServiceGetAssetProject1Key, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => AssetProjectService.getAssetProject1(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof AssetProjectService.getAssetProject1>>, TError>, "data"> & {
    data: TData;
};
export const useAssetProjectServicePutAssetProject = <TData = Awaited<ReturnType<typeof AssetProjectService.putAssetProject>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof AssetProjectService.putAssetProject>>, unknown, {
    key: number;
    requestBody?: AssetProjectEditDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => AssetProjectService.putAssetProject(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof AssetProjectService.putAssetProject>>, TError, {
    key: number;
    requestBody?: AssetProjectEditDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useAssetProjectServiceDeleteAssetProject = <TData = Awaited<ReturnType<typeof AssetProjectService.deleteAssetProject>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof AssetProjectService.deleteAssetProject>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => AssetProjectService.deleteAssetProject(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof AssetProjectService.deleteAssetProject>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
export const useAssetProjectServiceGetAssetProjectExcelTemplateKey = "AssetProjectServiceGetAssetProjectExcelTemplate";
export const useAssetProjectServiceGetAssetProjectExcelTemplate = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof AssetProjectService.getAssetProjectExcelTemplate>>, TError = unknown>({ key }: {
    key: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof AssetProjectService.getAssetProjectExcelTemplate>>, unknown, Awaited<ReturnType<typeof AssetProjectService.getAssetProjectExcelTemplate>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useAssetProjectServiceGetAssetProjectExcelTemplateKey, ...(queryKey ?? [{ key }])], queryFn: () => AssetProjectService.getAssetProjectExcelTemplate(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof AssetProjectService.getAssetProjectExcelTemplate>>, TError>, "data"> & {
    data: TData;
};
export const useAssetProjectServiceGetAssetProjectExportKey = "AssetProjectServiceGetAssetProjectExport";
export const useAssetProjectServiceGetAssetProjectExport = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof AssetProjectService.getAssetProjectExport>>, TError = unknown>({ key }: {
    key: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof AssetProjectService.getAssetProjectExport>>, unknown, Awaited<ReturnType<typeof AssetProjectService.getAssetProjectExport>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useAssetProjectServiceGetAssetProjectExportKey, ...(queryKey ?? [{ key }])], queryFn: () => AssetProjectService.getAssetProjectExport(key), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof AssetProjectService.getAssetProjectExport>>, TError>, "data"> & {
    data: TData;
};
export const useAssetProjectServicePostAssetProjectImport = <TData = Awaited<ReturnType<typeof AssetProjectService.postAssetProjectImport>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof AssetProjectService.postAssetProjectImport>>, unknown, {
    key: number;
    addnew?: boolean;
    formData?: {
        file?: Blob;
    };
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, addnew, formData }) => AssetProjectService.postAssetProjectImport(key, addnew, formData), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof AssetProjectService.postAssetProjectImport>>, TError, {
    key: number;
    addnew?: boolean;
    formData?: {
        file?: Blob;
    };
}, TContext>, "data"> & {
    data: TData;
};
export const useAssetProjectServiceGetAssetProjectAssetsKey = "AssetProjectServiceGetAssetProjectAssets";
export const useAssetProjectServiceGetAssetProjectAssets = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof AssetProjectService.getAssetProjectAssets>>, TError = unknown>({ key, top, skip, filter, select, orderby, expand, count }: {
    key: number;
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof AssetProjectService.getAssetProjectAssets>>, unknown, Awaited<ReturnType<typeof AssetProjectService.getAssetProjectAssets>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useAssetProjectServiceGetAssetProjectAssetsKey, ...(queryKey ?? [{ key, top, skip, filter, select, orderby, expand, count }])], queryFn: () => AssetProjectService.getAssetProjectAssets(key, top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof AssetProjectService.getAssetProjectAssets>>, TError>, "data"> & {
    data: TData;
};
export const useAssetProjectServicePutAssetProjectAssets = <TData = Awaited<ReturnType<typeof AssetProjectService.putAssetProjectAssets>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof AssetProjectService.putAssetProjectAssets>>, unknown, {
    id: string;
    key: string;
    requestBody?: AssetProjectAsset;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ id, key, requestBody }) => AssetProjectService.putAssetProjectAssets(id, key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof AssetProjectService.putAssetProjectAssets>>, TError, {
    id: string;
    key: string;
    requestBody?: AssetProjectAsset;
}, TContext>, "data"> & {
    data: TData;
};
export const useAssetFilterPresetServiceGetAssetFilterPresetKey = "AssetFilterPresetServiceGetAssetFilterPreset";
export const useAssetFilterPresetServiceGetAssetFilterPreset = <TQueryKey extends Array<unknown> = unknown[], TData = Awaited<ReturnType<typeof AssetFilterPresetService.getAssetFilterPreset>>, TError = unknown>({ top, skip, filter, select, orderby, expand, count }: {
    top?: string;
    skip?: string;
    filter?: string;
    select?: string;
    orderby?: string;
    expand?: string;
    count?: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof AssetFilterPresetService.getAssetFilterPreset>>, unknown, Awaited<ReturnType<typeof AssetFilterPresetService.getAssetFilterPreset>>, unknown[]>, "queryKey" | "queryFn" | "initialData">) => useQuery({ queryKey: [useAssetFilterPresetServiceGetAssetFilterPresetKey, ...(queryKey ?? [{ top, skip, filter, select, orderby, expand, count }])], queryFn: () => AssetFilterPresetService.getAssetFilterPreset(top, skip, filter, select, orderby, expand, count), ...options }) as Omit<UseQueryResult<Awaited<ReturnType<typeof AssetFilterPresetService.getAssetFilterPreset>>, TError>, "data"> & {
    data: TData;
};
export const useAssetFilterPresetServicePostAssetFilterPreset = <TData = Awaited<ReturnType<typeof AssetFilterPresetService.postAssetFilterPreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof AssetFilterPresetService.postAssetFilterPreset>>, unknown, {
    requestBody?: AssetFilterPresetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ requestBody }) => AssetFilterPresetService.postAssetFilterPreset(requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof AssetFilterPresetService.postAssetFilterPreset>>, TError, {
    requestBody?: AssetFilterPresetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useAssetFilterPresetServicePutAssetFilterPreset = <TData = Awaited<ReturnType<typeof AssetFilterPresetService.putAssetFilterPreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof AssetFilterPresetService.putAssetFilterPreset>>, unknown, {
    key: number;
    requestBody?: AssetFilterPresetDTO;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key, requestBody }) => AssetFilterPresetService.putAssetFilterPreset(key, requestBody), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof AssetFilterPresetService.putAssetFilterPreset>>, TError, {
    key: number;
    requestBody?: AssetFilterPresetDTO;
}, TContext>, "data"> & {
    data: TData;
};
export const useAssetFilterPresetServiceDeleteAssetFilterPreset = <TData = Awaited<ReturnType<typeof AssetFilterPresetService.deleteAssetFilterPreset>>, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<Awaited<ReturnType<typeof AssetFilterPresetService.deleteAssetFilterPreset>>, unknown, {
    key: number;
}, unknown>, "mutationFn">) => useMutation({ mutationFn: ({ key }) => AssetFilterPresetService.deleteAssetFilterPreset(key), ...options }) as Omit<UseMutationResult<Awaited<ReturnType<typeof AssetFilterPresetService.deleteAssetFilterPreset>>, TError, {
    key: number;
}, TContext>, "data"> & {
    data: TData;
};
