import { EditOutlined } from "@ant-design/icons";
import { Alert, Button, Input, List, Modal, Space, Image } from "antd";
import { useCallback, useEffect, useState } from "react";
import { MaterialService, MaterialViewDTO, ModelPackService, ModelPackViewDTO, ModelService, ModelViewDTO, ModifierService, ModifierViewDTO } from "../../../openapi/requests";
import { BASE_PATH } from "../../..";

export type QueryFieldType = "Model" | "Material" | "Modelpack" | "Modifier";

const QueryField: React.FC<{ placeholder?: string, clientId: number, type: QueryFieldType, value?: string, onChange?: (value: string) => void }> = ({ onChange, placeholder, value, type, clientId }) => {
    const [isEditorOpen, setIsEditorOpen] = useState(false);

    const showEditor = useCallback(() => {
        setIsEditorOpen(true);
    }, []);

    const hideEditor = useCallback(() => {
        setIsEditorOpen(false);
    }, []);

    const onEditorChange = useCallback((val: string) => {
        setIsEditorOpen(false);
        onChange?.(val);
    }, [onChange]);

    return (
        <Space>
            <Button onClick={showEditor} icon={<EditOutlined />}></Button>
            <QueryFieldEditor clientId={clientId} type={type} isOpen={isEditorOpen} value={value ?? ""} onCancel={hideEditor} onChange={onEditorChange} />
        </Space>
    );
}

const QueryFieldEditor: React.FC<{ isOpen: boolean, clientId: number, type: QueryFieldType, value: string, onCancel: () => void, onChange: (value: string) => void }> = ({ isOpen, type, value, onCancel, onChange, clientId }) => {
    const [currentValue, setCurrentValue] = useState(value);
    const [result, setResult] = useState<ModelViewDTO[] | ModelPackViewDTO[] | ModifierViewDTO[] | MaterialViewDTO[] | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(isOpen){
            setCurrentValue(value);
            setError(undefined);
            setResult(undefined);
            setIsLoading(false);
        }
    }, [isOpen, value])

    const handleOk = useCallback(() => {
        onChange(currentValue);
    }, [currentValue, onChange]);

    const handleCancel = useCallback(() => {
        onCancel();
    }, [onCancel]);

    const handleApiError = useCallback((reason: any) => {
        if(reason.response.data.detail){
            setError(reason.response.data.detail);
        }else{
            setError(JSON.stringify(reason.response))
        }
    }, []);

    const handleTest = useCallback(() => {
        setError(undefined);
        setResult(undefined);
        setIsLoading(true);

        if(type === "Model"){
            ModelService.getModel(currentValue, undefined, undefined, `clientid eq ${clientId}`).then(resp => {
                setResult(resp.value);
                setIsLoading(false);
            }).catch(reason => {
                handleApiError(reason);
                setIsLoading(false);
            });
        }else if(type === "Modelpack"){
            ModelPackService.getModelPack(currentValue, undefined, undefined, `clientid eq ${clientId}`).then(resp => {
                setResult(resp.value);
                setIsLoading(false);
            }).catch(reason => {
                handleApiError(reason);
                setIsLoading(false);
            });
        }else if(type === "Material"){
            MaterialService.getMaterial(currentValue, undefined, undefined, `clientid eq ${clientId}`).then(resp => {
                setResult(resp.value);
                setIsLoading(false);
            }).catch(reason => {
                handleApiError(reason);
                setIsLoading(false);
            });
        }else if(type === "Modifier"){
            ModifierService.getModifier(currentValue, undefined, undefined, `clientid eq ${clientId}`).then(resp => {
                setResult(resp.value);
                setIsLoading(false);
            }).catch(reason => {
                handleApiError(reason);
                setIsLoading(false);
            });
        }
    }, [clientId, currentValue, handleApiError, type]);

    return (
        <Modal title="Edit query" width={800} open={isOpen} onOk={handleOk} onCancel={handleCancel}>
            <Input.TextArea rows={10} value={currentValue} onChange={(e) => setCurrentValue(e.target.value)} />
            <Button onClick={handleTest}>Test</Button>
            {error && <Alert message={error} type="error" showIcon />}

            {(result !== undefined || isLoading) && <div style={{maxHeight: 200, overflow: 'auto'}}>
                <List<{title: string, thumbnail: string}>
                    itemLayout="horizontal"
                    dataSource={result}
                    loading={isLoading}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta title={item.title} avatar={<Image width={50} src={BASE_PATH + item.thumbnail} />} />
                        </List.Item>
                    )}
                />
            </div>
            }

        </Modal>
    )
}

export default QueryField;