import React from 'react';
import { useParams } from 'react-router-dom';
import Project from '../../../components/admin/projects/Project';

const ProjectPage: React.FC = () => {
    let { projectId } = useParams();

    return (<div>
        <Project projectId={parseInt(projectId || '0')} />
    </div>)
};

export default ProjectPage;