import { Button, Checkbox, CheckboxProps, Col, Drawer, Form, InputNumber, notification, Row, Select, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { StillImageConfiguration } from '../../../models/StillImageConfiguration';
import { useDeliveryConfigurationServiceGetDeliveryConfiguration, useSceneServiceGetScene1, useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan } from '../../../openapi/queries';
import { OrderlineEditDTO, OrderlineService, OrderService, SceneViewDTO, TemplateEditDTO } from '../../../openapi/requests';
import { OrderStatus } from '../../../models/enums';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

type QAForm = {
    stillCameras: string[];
    animatedCameras: string[];
    lighting: string | undefined;
    stillSize: number;
    animationSize: number;
    deliveryconfigurationId: number;
    stillimagesubmissionplanId?: number | undefined;
}

const generateConfig = async (scene: SceneViewDTO, camera: string, lighting?: string): Promise<StillImageConfiguration> => {
    const config = JSON.parse(scene.defaultConfigurationSpec ?? '{}') as StillImageConfiguration;

    const lightingPropset = scene.propsets.find(e => e.label.toLowerCase() === 'lighting');

    if (lightingPropset && lighting) {
        config.PropsetSelections = [...config.PropsetSelections.filter(e => e.Name !== lightingPropset.name), {
            Name: lightingPropset.name,
            Value: lighting
        }]
    }

    config.camera = camera;

    return config;
}

const TemplateCameraRender: React.FC<{ template?: TemplateEditDTO, isOpen: boolean, onClose: () => void }> = ({ template, isOpen, onClose }) => {
    const [api, contextHolder] = notification.useNotification();
    const { data: scene } = useSceneServiceGetScene1({ key: template?.sceneId ?? 0 }, undefined, { enabled: isOpen });
    const { data: deliveries } = useDeliveryConfigurationServiceGetDeliveryConfiguration({}, undefined, { enabled: isOpen });
    const { data: submissionPlans } = useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan({}, undefined, { enabled: isOpen });
    const [working, setWorking] = useState(false);
    const [form] = Form.useForm<QAForm>();
    const selectedStillCameras = Form.useWatch('stillCameras', form);
    const selectedAnimatedCameras = Form.useWatch('animatedCameras', form);

    const lighting = useMemo(() => {
        if (scene) {
            let propset = scene.propsets.find(e => e.label.toLowerCase() === 'lighting');
            return propset;
        }
        return undefined;
    }, [scene])

    useEffect(() => {
        if (scene) {
            const config = JSON.parse(scene.defaultConfigurationSpec ?? '{}') as StillImageConfiguration;
            let lightingName: string | undefined = undefined;

            const lightingPropset = scene.propsets.find(e => e.label.toLowerCase() === 'lighting');
            lightingName = config.PropsetSelections?.find(e => e.Name === lightingPropset?.name)?.Value ?? lightingPropset?.options.find(e => e.title.toLowerCase().includes("l_day"))?.name;

            form.setFieldsValue({
                stillCameras: scene.cameras.filter(e => !e.isAnimated).map(e => e.cameraName),
                animatedCameras: scene.cameras.filter(e => e.isAnimated).map(e => e.cameraName),
                lighting: lightingName,
                stillSize: 2000,
                animationSize: 512,
            });
        }
        if (deliveries) {
            form.setFieldsValue({
                deliveryconfigurationId: deliveries.value.find(e => e.title.toLowerCase().includes("hosted"))?.id
            });
        }
        if (submissionPlans) {
            form.setFieldsValue({
                stillimagesubmissionplanId: submissionPlans.value.find(e => e.title?.toLowerCase().includes("cad final"))?.id
            });
        }
    }, [scene, form, lighting, deliveries, submissionPlans]);

    const onSubmit = () => {
        form.submit();
    }

    const onFinish = async () => {
        const templateData = form.getFieldsValue(true) as QAForm;

        if (templateData.animatedCameras.length === 0 && templateData.stillCameras.length === 0) {
            return;
        }

        if (template) {
            setWorking(true);

            const orderLines: OrderlineEditDTO[] = [];

            try {
                const orderlineDefaults: OrderlineEditDTO = {
                    templateId: template.id,
                    isPreview: true,
                    deliveryFilenameTemplate: '',
                    stillImageSetting: { backgroundcolor: '000000', fileformat: 'jpg', height: 600, width: 800, stillimagesubmissionplanId: templateData.stillimagesubmissionplanId },
                    configurationSpec: '',
                    orderlinePostProcesses: [],
                    requestManualProcess: false,
                    spin360Setting: { backgroundcolor: '000000', fileformat: 'jpg', frames: 32, height: 600, width: 800 },
                    videoSetting: { codec: '', height: 600, width: 800 },
                    armodelSetting: { createGlb: false, createUsdz: false },
                }

                for (let i = 0; i < templateData.stillCameras.length; i++) {
                    const cameraName = templateData.stillCameras[i];
                    const camera = scene.cameras.find(e => e.cameraName === cameraName)!;

                    orderLines.push({
                        ...orderlineDefaults,
                        configurationSpec: JSON.stringify(await generateConfig(scene, cameraName, templateData.lighting)),
                        stillImageSetting: { ...orderlineDefaults.stillImageSetting, width: templateData.stillSize, height: templateData.stillSize },
                        deliveryFilenameTemplate: `${template.title}-${camera.label}`
                    });
                }

                for (let i = 0; i < templateData.animatedCameras.length; i++) {
                    const cameraName = templateData.animatedCameras[i];
                    const camera = scene.cameras.find(e => e.cameraName === cameraName)!;

                    orderLines.push({
                        ...orderlineDefaults,
                        configurationSpec: JSON.stringify(await generateConfig(scene, cameraName, templateData.lighting)),
                        stillImageSetting: { ...orderlineDefaults.stillImageSetting, width: templateData.animationSize, height: templateData.animationSize },
                        videoSetting: { ...orderlineDefaults.videoSetting, width: templateData.animationSize, height: templateData.animationSize },
                        deliveryFilenameTemplate: `${template.title}-${camera.label}`
                    });
                }

                const order = await OrderService.postOrder({
                    clientId: scene.clientId,
                    deliveryconfigurationId: templateData.deliveryconfigurationId,
                    id: 0,
                    status: OrderStatus.Draft,
                    orderedByUserId: 0,
                    timestampCreate: '1900-01-01',
                    orderReference: `Thumbnails ${template.title}`,
                    notificationRecipients: ''
                });

                for (let i = 0; i < orderLines.length; i++) {
                    await OrderlineService.postOrderOrderline(order.id, orderLines[i]);
                }
                await OrderService.putOrderPlace(order.id);

                api.success({
                    message: 'Queued',
                    description: `order created width id: ${order.id}`,
                    placement: 'top',
                });
            } catch (reason: any) {
                let error = reason as Error;

                api.error({
                    message: 'Failed',
                    description: error.message,
                    placement: 'top',
                });
            }

            setWorking(false);
            onClose();
        }
    };


    const checkAllStills = selectedStillCameras?.length === scene?.cameras.filter(e => !e.isAnimated).length;
    const indeterminateStills = selectedStillCameras?.length > 0 && selectedStillCameras?.length < scene?.cameras.filter(e => !e.isAnimated).length;

    const checkAllAnimated = selectedAnimatedCameras?.length === scene?.cameras.filter(e => e.isAnimated).length;
    const indeterminateAnimated = selectedAnimatedCameras?.length > 0 && selectedAnimatedCameras?.length < scene?.cameras.filter(e => e.isAnimated).length;

    const onCheckAllStillsChange: CheckboxProps['onChange'] = (e: CheckboxChangeEvent) => {
        form.setFieldsValue({
            stillCameras: e.target.checked ? scene.cameras.filter(e => !e.isAnimated).map(e => e.cameraName) : []
        });
    };
    const onCheckAllAnimatedChange: CheckboxProps['onChange'] = (e: CheckboxChangeEvent) => {
        form.setFieldsValue({
            animatedCameras: e.target.checked ? scene.cameras.filter(e => e.isAnimated).map(e => e.cameraName) : []
        });
    };

    return (
        <>
            {contextHolder}
            <Drawer
                title={`Render ${template?.title}`}
                width={720}
                onClose={onClose}
                open={isOpen}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={working}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}>

                    <Form.Item name="stillCameras" label={<Space>
                        Still cameras
                        <Checkbox indeterminate={indeterminateStills} onChange={onCheckAllStillsChange} checked={checkAllStills}>Check all</Checkbox>
                    </Space>}>

                        <Checkbox.Group>
                            <Row>
                                {scene?.cameras.filter(e => !e.isAnimated).map(cam =>
                                    <Col span={8}>
                                        <Checkbox value={cam.cameraName} style={{ lineHeight: '32px' }} defaultChecked>
                                            {cam.label}
                                        </Checkbox>
                                    </Col>)}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item name="animatedCameras" label={<Space>
                        Animated cameras
                        <Checkbox indeterminate={indeterminateAnimated} onChange={onCheckAllAnimatedChange} checked={checkAllAnimated}>Check all</Checkbox>
                    </Space>}>

                        <Checkbox.Group>
                            <Row>
                                {scene?.cameras.filter(e => e.isAnimated).map(cam =>
                                    <Col span={8}>
                                        <Checkbox value={cam.cameraName} style={{ lineHeight: '32px' }} defaultChecked>
                                            {cam.label}
                                        </Checkbox>
                                    </Col>)}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>



                    <Form.Item name="lighting" label="Lighting">
                        <Select options={lighting?.options.map(e => ({ label: e.title, value: e.name }))}>

                        </Select>
                    </Form.Item>

                    <Form.Item name='stillSize' label="Still image size">
                        <InputNumber min={1} addonAfter="px" />
                    </Form.Item>

                    <Form.Item name='animationSize' label="Animation size">
                        <InputNumber min={1} addonAfter="px" />
                    </Form.Item>

                    <Form.Item name='deliveryconfigurationId' label="Delivery" rules={[{ required: true }]}>
                        <Select options={deliveries?.value.map(e => ({ label: e.title, value: e.id }))} />
                    </Form.Item>

                    <Form.Item name='stillimagesubmissionplanId' label="Plan" rules={[{ required: true }]}>
                        <Select options={submissionPlans?.value.map(e => ({ label: e.title, value: e.id }))} />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default TemplateCameraRender;