import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Drawer from 'react-modern-drawer';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { Point, SceneDesignerContext } from '../../contexts/SceneDesignerContext';
import AssetList from '../layout/AssetList';
import './ProductSelector.scss';
import { ReactComponent as LoadingIcon } from '../../assets/icons/loading.svg';
import { MaterialService, MaterialViewDTO, MetadataDTO, ModelPackService, ModelPackViewDTO, ModelPlacementPointDTO, ModelService, ModelSurfaceDTO, ModelViewDTO, ModifierService, ModifierViewDTO, SceneModifierTargetDTO, ScenePlacementpointDTO, ScenePropsetDTO, ScenePropsetOptionDTO, SceneSurfaceDTO } from '../../openapi/requests';
import { ConfigContext } from '../../contexts/ConfigContext';
import { GenerateODataFilter } from '../../helpers/odataFunctions';
import { BASE_PATH } from '../..';
import classNames from 'classnames';
import ReactGA from "react-ga4";


export type BaseAsset = {
    warning?: string;
}

export type ModelAsset = BaseAsset & ModelViewDTO & {
    type: "model";
    validFor: (ScenePlacementpointDTO | ModelPlacementPointDTO)[];
}

export type ModelpackAsset = BaseAsset & ModelPackViewDTO & {
    type: "modelpack";
    validFor: (ScenePlacementpointDTO | ModelPlacementPointDTO)[];
}

export type MaterialAsset = BaseAsset & MaterialViewDTO & {
    type: "material";
    validFor: (SceneSurfaceDTO | ModelSurfaceDTO)[];
}

export type ModifierAsset = BaseAsset & ModifierViewDTO & {
    type: "modifier";
    validFor: SceneModifierTargetDTO[];
}

export type PropsetAsset = BaseAsset & ScenePropsetOptionDTO & {
    type: "propset";
    validFor: ScenePropsetDTO[];
    tags: Array<string>;
    metadata: Array<MetadataDTO>;
}
export type Asset = ModelAsset | ModelpackAsset | MaterialAsset | ModifierAsset | PropsetAsset;

interface Props {
    open: boolean;
    working?: boolean;
    point: Point | undefined;
    onCancel: () => void;
    onProductSelected: (products: Asset[]) => void;
}

const addWarnings = (asset: Asset, point: Point, selectedBrand: string | undefined) => {
    if (asset.validFor.length !== point.configuration.placements.length) {
        asset.warning = 'This product is not supported in all configurations';
    }
    if (selectedBrand) {
        if (asset.metadata.some(m => m.name === 'brand') && !asset.metadata.some(m => m.name === 'brand' && m.value.toLowerCase() === selectedBrand.toLowerCase())) {
            asset.warning = `Not ${selectedBrand}`;
        }
    }
}

const sortByTitleAndWarning = (a: Asset, b: Asset) => {

    if (a.warning && !b.warning) {
        return 1;
    }
    if (b.warning && !a.warning) {
        return -1;
    }

    const nameA = a.title.toUpperCase();
    const nameB = b.title.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    return 0;
}

const ProductSelector: React.FC<Props> = ({ open, onCancel, onProductSelected, point, working }) => {
    const { odataAssetFilters, odataFilters } = useContext(ConfigContext);
    const { selectedBrand, products } = useContext(SceneDesignerContext);
    const [items, setItems] = useState<Asset[]>([]);
    const [filteredItems, setFilteredItems] = useState<Asset[]>([]);
    const [query, setQuery] = useState("");
    const [selectedItems, setSelectedItems] = useState<Asset[]>([]);
    const [useMultiSelect, setUseMultiSelect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [supportedParents, setSupportedParents] = useState<string[] | undefined>(undefined)

    useEffect(() => {
        if (point) {
            setSelectedItems(items.filter(e => point.configuration.uniqueValues.some(v => e.id === v.id)));
            setUseMultiSelect(point.configuration.uniqueValues.length > 1);
        }
    }, [items, point]);

    useEffect(() => {
        if (!useMultiSelect) {
            if (selectedItems.length > 1) {
                setSelectedItems([selectedItems[0]]);
            }
        }
    }, [selectedItems, useMultiSelect]);

    useEffect(() => {
        setItems([]);
        setLoading(true);

        if (open && point) {
            if (point.type === 'model') {
                if (point.configuration.placement.useModelpack) {
                    let assets: Asset[] = [];
                    let promises = point.configuration.placements.map(p => ModelPackService.getModelPack(p.modelSelectionFilter || undefined, undefined, undefined, GenerateODataFilter(odataFilters), undefined, 'title')
                        .then(result => {
                            result.value.forEach(a => {
                                var asset = assets.find(e => e.id === a.id);
                                if (asset && asset.type === 'modelpack') {
                                    asset.validFor.push(p);
                                } else {
                                    assets.push({ ...a, type: 'modelpack', validFor: [p] })
                                }
                            });
                        }));

                    Promise.all(promises).then(e => {
                        assets.forEach(e => addWarnings(e, point, selectedBrand));
                        assets.sort(sortByTitleAndWarning);
                        setLoading(false);
                        setItems(assets);
                    });
                } else {
                    let assets: Asset[] = [];
                    let promises = point.configuration.placements.map(p => ModelService.getModel(p.modelSelectionFilter || undefined, undefined, undefined, GenerateODataFilter(odataFilters), undefined, 'title')
                        .then(result => {
                            result.value.forEach(a => {
                                var asset = assets.find(e => e.id === a.id);
                                if (asset && asset.type === 'model') {
                                    asset.validFor.push(p);
                                } else {
                                    assets.push({ ...a, type: 'model', validFor: [p] })
                                }
                            });
                        }));

                    Promise.all(promises).then(e => {
                        assets.forEach(e => addWarnings(e, point, selectedBrand));
                        assets.sort(sortByTitleAndWarning);
                        setLoading(false);
                        setItems(assets);
                    });
                }
            } else if (point.type === 'material') {
                let assets: Asset[] = [];
                let promises = point.configuration.placements.map(p => MaterialService.getMaterial(p.materialSelectionFilter || undefined, undefined, undefined, GenerateODataFilter(odataFilters), undefined, 'title')
                    .then(result => {
                        result.value.forEach(a => {
                            var asset = assets.find(e => e.id === a.id);
                            if (asset && asset.type === 'material') {
                                asset.validFor.push(p);
                            } else {
                                assets.push({ ...a, type: 'material', validFor: [p] })
                            }
                        });
                    }));

                Promise.all(promises).then(e => {
                    assets.forEach(e => addWarnings(e, point, selectedBrand));
                    assets.sort(sortByTitleAndWarning);
                    setLoading(false);
                    setItems(assets);
                });
            } else if (point.type === 'modifier') {
                let assets: Asset[] = [];
                let promises = point.configuration.placements.map(p => ModifierService.getModifier(p.modifierSelectionFilter || undefined, undefined, undefined, GenerateODataFilter(odataFilters), undefined, 'title')
                    .then(result => {
                        result.value.forEach(a => {
                            var asset = assets.find(e => e.id === a.id);
                            if (asset && asset.type === 'modifier') {
                                asset.validFor.push(p);
                            } else {
                                assets.push({ ...a, type: 'modifier', validFor: [p] })
                            }
                        });
                    }));

                Promise.all(promises).then(e => {
                    assets.forEach(e => addWarnings(e, point, selectedBrand));
                    assets.sort(sortByTitleAndWarning);
                    setLoading(false);
                    setItems(assets);
                });
            } else if (point.type === 'propset') {
                setItems(point.configuration.placement.options.map(e => ({ ...e, type: 'propset', validFor: [point.configuration.placement], metadata: [], tags: [] })));
                setLoading(false);
            }
        }
    }, [point, open, odataAssetFilters, odataFilters, selectedBrand]);

    useEffect(() => {

        let filtered = items;

        if (query) {
            var lowercase = query.toLowerCase();

            filtered = filtered.filter((e) => {
                return (e.title ?? "").toLowerCase().includes(lowercase) || (e.tags ?? []).some(t => t.toLowerCase().includes(lowercase)) || (e.metadata ?? []).some(t => t.value.toLowerCase().includes(lowercase))
            });
        }

        setFilteredItems(filtered);
    }, [items, query]);

    const parents = useMemo(() => {
        let parents: ModelViewDTO[] = [];

        if (point) {
            for (let i = 0; i < products.length; i++) {
                const element = products[i];
                const values = element.configuration.uniqueValues;

                for (let j = 0; j < values.length; j++) {
                    const model = values[j] as ModelViewDTO;

                    if (model.placementpoints) {
                        if (model.placementpoints.some(e => point.configuration.placements.some(p => p.name === e.name))) {
                            parents.push(model);
                        }
                    }
                    if (model.surfaces) {
                        if (model.surfaces.some(e => point.configuration.placements.some(p => p.name === e.name))) {
                            parents.push(model);
                        }
                    }
                }
            }
        }

        return parents;

    }, [point, products]);

    const handleDoubleClick = useCallback((product: Asset) => {
        ReactGA.event('click', {item: 'asset', location: 'productselector'});
        if (!useMultiSelect) {
            onProductSelected([product]);
        }
    }, [onProductSelected, useMultiSelect]);

    const handleClick = useCallback((product: Asset) => {
        ReactGA.event('click', {item: 'asset', location: 'productselector'});
    }, []);

    const handleDeleteClick = useCallback(() => {
        onProductSelected([]);
    }, [onProductSelected]);

    const handleSelectionChange = useCallback((products: Asset[]) => {
        setSelectedItems(products);
    }, []);

    const handleSubmit = useCallback(() => {
        onProductSelected(selectedItems);
    }, [onProductSelected, selectedItems]);

    const handleMouseEnter = useCallback((product: Asset) => {
        let supported = parents.filter(p => 
            p.placementpoints.some(e => product.validFor.some(v => v.name === e.name)) || 
            p.surfaces.some(e => product.validFor.some(v => v.name === e.name)));

        setSupportedParents(supported.map(e => e.name));
    }, [parents]);

    const handleMouseExit = useCallback(() => {
        setSupportedParents(undefined);
    }, []);

    return (
        <Drawer className='productselector' open={open} onClose={onCancel} direction='right' size={530} overlayOpacity={0} enableOverlay={false}>
            <div className='header'>
                <button className='back' onClick={onCancel}>
                    <ArrowLeftIcon />
                </button>
                <h1>Select item</h1>
                <button className='close' onClick={onCancel}>
                    <CloseIcon />
                </button>
            </div>

            {(parents.length !== 0) &&
                <div className='parents'>
                    <h2>Subproduct for</h2>
                    <div className='parent-items'>
                        {parents.map(e => <div className={classNames({'parent': true, 'unsupported': supportedParents && !supportedParents.includes(e.name)})}><img src={BASE_PATH + e.thumbnail} alt={e.title} /><p>{e.title}</p></div>)}
                    </div>
                </div>
            }
            <input type='search' placeholder='Search' value={query} onChange={(e) => setQuery(e.target.value)} />



            <AssetList<Asset>
                assets={filteredItems}
                onItemDoubleClick={handleDoubleClick}
                onSelectionChanged={handleSelectionChange}
                onItemMouseEnter={handleMouseEnter}
                onItemMouseExit={handleMouseExit}
                onItemClick={handleClick}
                selection={selectedItems}
                allowMultiSelect={useMultiSelect}
                className='productselector-items'
                loading={loading}
                displayMode={point?.type === 'propset' ? 'list' : 'medium'} />

            <div className='multiselect'>
                <label>
                    <input type="checkbox" checked={useMultiSelect} onChange={e => setUseMultiSelect(e.target.checked)} />
                    <span className="check">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M3.5 8.5L6 11L12.5 4.5" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                    <span>Select multiple products</span>
                </label>
            </div>

            <div className='footer'>
                <button disabled={working} className='submit' onClick={handleSubmit}>
                    {working ? <LoadingIcon className='working' /> : 'Confirm'}
                </button>
                {!point?.isRequired &&
                    <button disabled={working} className='delete' onClick={handleDeleteClick}>
                        <DeleteIcon />
                    </button>
                }
            </div>
        </Drawer>
    );
};

export default ProductSelector;