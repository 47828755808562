/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigurationAssets } from '../models/ConfigurationAssets';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HelpService {

    /**
     * @param message 
     * @param stack 
     * @param name 
     * @returns any Success
     * @throws ApiError
     */
    public static postHelpJserror(
message?: string,
stack?: string,
name?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Help/jserror',
            query: {
                'message': message,
                'stack': stack,
                'name': name,
            },
        });
    }

    /**
     * @param message 
     * @param templateId 
     * @returns any Success
     * @throws ApiError
     */
    public static postHelpTemplate(
message?: string,
templateId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Help/template',
            query: {
                'message': message,
                'templateId': templateId,
            },
        });
    }

    /**
     * @param message 
     * @param projectId 
     * @returns ConfigurationAssets Success
     * @throws ApiError
     */
    public static postHelpProject(
message?: string,
projectId?: number,
): CancelablePromise<ConfigurationAssets> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Help/project',
            query: {
                'message': message,
                'projectId': projectId,
            },
        });
    }

}
