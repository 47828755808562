import { Drawer, Input, message, Space } from 'antd';
import React, { useEffect } from 'react';
import { Button, Form } from 'antd';
import { InternalClientDTO } from '../../../openapi/requests';
import { useInternalClientServicePutInternalClient } from '../../../openapi/queries';


const EditClient: React.FC<{ client?: InternalClientDTO, isOpen: boolean, onClose: () => void }> = ({ client, isOpen, onClose }) => {

    const [form] = Form.useForm<InternalClientDTO>();
    const [messageApi, contextHolder] = message.useMessage();

    const {mutate, isSuccess, isPending, isError, error} = useInternalClientServicePutInternalClient();

    const onSubmit = () => {
        form.submit();
    }

    useEffect(() => {
        if(isSuccess){
            messageApi.success("Client updated");
            onClose();
        }
    }, [isSuccess, messageApi, onClose]);

    useEffect(() => {
        if(isError){
            messageApi.error(error?.toString());
        }
    }, [error, isError, isSuccess, messageApi]);

    const onFinish = () => {
        if(client){
            const values = form.getFieldsValue(true) as InternalClientDTO;

            const clientData: InternalClientDTO = {
                ...client,
                ...values,
            }

            mutate({key: client.id, requestBody: clientData});
        }
    };

    useEffect(() => {
        if(client){
            form.setFieldsValue(client);
        }
    }, [client, form])

    return (
        <>
            {contextHolder}

            <Drawer
                title={`Edit ${client?.name}`}
                width={720}
                onClose={onClose}
                open={isOpen}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}>

                    <Form.Item rules={[{ required: true }]} name='name' label="Name">
                        <Input />
                    </Form.Item>

                    <Form.Item name='maxModelsPath' label="Max Models Path">
                        <Input />
                    </Form.Item>

                    <Form.Item name='maxModifiersPath' label="Max Modifiers Path">
                        <Input />
                    </Form.Item>

                    <Form.Item name='maxMaterialsPath' label="Max Materials Path">
                        <Input />
                    </Form.Item>

                    <Form.Item name='maxScenesPath' label="Max Scenes Path">
                        <Input />
                    </Form.Item>

                    <Form.Item name='maxOutputPath' label="Max Output Path">
                        <Input />
                    </Form.Item>

                    <Form.Item name='arModelsPath' label="AR Models Path">
                        <Input />
                    </Form.Item>

                    <Form.Item name='arMaterialsPath' label="AR Materials Path">
                        <Input />
                    </Form.Item>
                </Form>
            </Drawer>
        </>

    );
};

export default EditClient;