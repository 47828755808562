import { DiagramEngine } from '@projectstorm/react-diagrams';
import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { ModelPortModel } from './ModelPort';
import { MaterialPortModel } from './MaterialPort';
import { ConfiguratorNodeModel, ConfiguratorNodeWidget } from './ConfiguratorNode';
import { ModelPackPortModel } from './ModelPackPort';
import { ConfiguratorPortModel } from './ConfiguratorPort';
import { ModelService, ModelViewDTO } from '../../../../../openapi/requests';

export class ModelNodeModel extends ConfiguratorNodeModel<ModelViewDTO> {
	protected onPortSelected: (port: ConfiguratorPortModel) => void;

	constructor(updateCallback: () => void, onPortSelected: (port: ConfiguratorPortModel) => void) {
		super('model', updateCallback);
		this.addPort(new ModelPortModel(false, 'out', 'out', onPortSelected, ''));
		this.onPortSelected = onPortSelected;
	}

	async loadData(modelID: number) {

		const ports = this.getPorts();

		for (const key in ports) {
			if (Object.prototype.hasOwnProperty.call(ports, key)) {
				const port = ports[key] as ModelPortModel | MaterialPortModel;

				if (port.in) {
					const links = port.getLinks();

					for (const key in links) {
						if (Object.prototype.hasOwnProperty.call(links, key)) {
							links[key].remove();
						}
					}

					port.remove();
				}
			}
		}

		this.modelPorts = this.modelPorts.filter(e => !e.in);
		this.modelPackPorts = this.modelPackPorts.filter(e => !e.in);
		this.materialPorts = this.materialPorts.filter(e => !e.in);
		this.modifierPorts = this.modifierPorts.filter(e => !e.in);


		let modelResponse = await ModelService.getModel1(modelID);
		this.data = modelResponse;

		for (let i = 0; i < modelResponse.placementpoints.length; i++) {
			const point = modelResponse.placementpoints[i];

			if(point.useModelpack){
				this.addPort(new ModelPackPortModel(true, point.name, point.label, this.onPortSelected, point.modelSelectionFilter));
			}else{
				this.addPort(new ModelPortModel(true, point.name, point.label, this.onPortSelected, point.modelSelectionFilter));
			}
		}
		
		for (let i = 0; i < modelResponse.surfaces.length; i++) {
			const point = modelResponse.surfaces[i];

			this.addPort(new MaterialPortModel(true, point.name, point.label, this.onPortSelected, point.materialSelectionFilter));
		}

		this.updateCallback();
	}
}

export class ModelNodeFactory extends AbstractReactFactory<ModelNodeModel, DiagramEngine> {
	constructor() {
		super('model');
	}

	generateReactWidget(event: GenerateWidgetEvent<ModelNodeModel>): JSX.Element {
		return <ConfiguratorNodeWidget engine={this.engine} node={event.model} />;
	}

	generateModel(event: GenerateModelEvent) {
		return new ModelNodeModel(() => { }, () => {});
	}
}