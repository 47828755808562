import { Button, Drawer, Form, Input, Modal, Select, Space, Transfer, message } from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { TransferDirection } from "antd/es/transfer";
import ResetPassword from "../../authenticate/ResetPassword";
import { CancelablePromise, ResetPasswordModel, RoleService, StillImageSubmissionPlanDTO, StillImageSubmissionPlanService, UserAssetFilterDTO, UserDTO, UserService, UserViewDTO } from "../../../openapi/requests";
import { useClientServiceGetClient, useRoleServiceGetRole, useUserServiceGetUserAssetFilter, useUserServiceGetUserAssetFilterKey, useUserServiceGetUserClaimsAll, useUserServicePostUserWelcomemail, useUserServicePutUser } from "../../../openapi/queries";
import { UserFiltersEditor } from "./configuration/userFilterEditor/UserFiltersEditor";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import "./EditUser.scss";
import { FilterOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { GenerateODataFilter } from "../../../helpers/odataFunctions";
import { ConfigContext } from "../../../contexts/ConfigContext";

interface RecordType {
    key: string;
    title: string;
    disabled: boolean;
}

const EditUser: React.FC<{ user?: UserViewDTO, isOpen: boolean, onClose: () => void, onUpdate: () => void }> = ({ user, isOpen, onClose, onUpdate }) => {

    const { userClient } = useContext(ConfigContext);

    const { data: clients } = useClientServiceGetClient({}, undefined, { enabled: isOpen });
    const { data: roles } = useRoleServiceGetRole(undefined, { enabled: isOpen });
    const { data: claims } = useUserServiceGetUserClaimsAll(undefined, { enabled: isOpen });
    const { data: userAssetFilters } = useUserServiceGetUserAssetFilter({ key: user?.id ?? 0 }, [useUserServiceGetUserAssetFilterKey], { enabled: isOpen, refetchOnWindowFocus: false });
    //const { data: stillImageSubmissionPlans } = useStillImageSubmissionPlanServiceGetStillImageSubmissionPlan({ filter: GenerateODataFilter([{ name: 'client', property: 'clientid', values: [user?.clientId ?? 0], type: 'select' }]) }, undefined, { enabled: user !== undefined });
    const { mutateAsync: mutateUserAsync, isPending: isUserPending } = useUserServicePutUser();
    const { mutateAsync: mutateWelcomeAsync, isPending: isWelcomePending } = useUserServicePostUserWelcomemail();

    const [filterEditorOpen, setFilterEditorOpen] = useState(false)

    const queryClient = useQueryClient();

    const rolesClaims = useQueries({
        queries: roles
            ? roles.map((role) => {
                return {
                    queryKey: ['roleClaims', role.id],
                    queryFn: () => RoleService.getRoleClaim(role.id),
                }
            })
            : [],
    });

    const [form] = Form.useForm<UserDTO>();
    const [messageApi, contextHolder] = message.useMessage();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedClaims, setSelectedClaims] = useState<string[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [localUserAssetFilters, setLocalUserAssetFilters] = useState<UserAssetFilterDTO[]>([]);
    const [resetModel, setResetModel] = useState<ResetPasswordModel>({} as ResetPasswordModel);
    const [stillImageSubmissionPlans, setStillImageSubmissionPlans] = useState<StillImageSubmissionPlanDTO[]>([]);

    const implicitClaims = useMemo(() => {
        var claims: string[] = [];

        for (let i = 0; i < selectedRoles.length; i++) {
            var roleIndex = roles.findIndex(e => e.name === selectedRoles[i]);

            if (rolesClaims[roleIndex] && rolesClaims[roleIndex].data) {
                claims = [...claims, ...rolesClaims[roleIndex].data!.map(e => e.value)]
            }
        }

        return claims;
    }, [selectedRoles, roles, rolesClaims])

    const onSubmit = () => {
        form.submit();
    }

    const onFilterEditorClose = () => {
        setFilterEditorOpen(false)
    }

    useEffect(() => {
        if (user && claims) {
            UserService.getUserClaim(user.id).then(resp => {
                setSelectedClaims(resp.filter(e => claims.some(c => c.value === e.value)).map(x => x.value));
            });

            UserService.getUserRole(user.id).then(resp => {
                setSelectedRoles(resp);
            });

            StillImageSubmissionPlanService.getStillImageSubmissionPlan(undefined, undefined, GenerateODataFilter([{ name: 'client', property: 'clientid', values: [user?.clientId ?? 0], type: 'select' }])).then(resp => {
                setStillImageSubmissionPlans(resp.value);
            });
        }
    }, [claims, user]);

    useEffect(() => {
        if (user) {
            form.setFieldsValue(user);
            setLocalUserAssetFilters(userAssetFilters)
        }
    }, [user, form, userAssetFilters]);

    const handleClaimTransfer = (newTargetKeys: React.Key[],
        direction: TransferDirection,
        moveKeys: React.Key[],) => {
        console.log(newTargetKeys, direction, moveKeys);
        setSelectedClaims(newTargetKeys.map(e => e.toString()));
    }

    const handleRoleTransfer = (newTargetKeys: React.Key[],
        direction: TransferDirection,
        moveKeys: React.Key[],) => {
        console.log(newTargetKeys, direction, moveKeys);
        setSelectedRoles(newTargetKeys.map(e => e.toString()));
    }

    const onFinish = () => {
        const editUser = form.getFieldsValue(true) as UserDTO;
        editUser.userClaims = claims.filter(x => { return selectedClaims.includes(x.value) });
        editUser.userRoles = roles.filter(x => { return selectedRoles.includes(x.name) });

        mutateUserAsync({ key: editUser.id, requestBody: editUser })
            .then(() => {
                messageApi.success("User updated");
                onClose();
            }).catch(reason => {
                messageApi.error(JSON.stringify(reason));
            });

    };

    const updateUserAssetFilters = useCallback(async (updatedUserFilters: UserAssetFilterDTO[]) => {

        let promises: CancelablePromise<UserAssetFilterDTO>[] = [];

        updatedUserFilters.forEach(async x => {

            let userAssetFilter = userAssetFilters.find(a => a.type.toLowerCase() === x.type.toLowerCase())
            if (userAssetFilter) {
                if (x.filter !== null && x.filter !== "") {
                    userAssetFilter.filter = x.filter;
                    promises.push(
                        UserService.putUserAssetfilter(userAssetFilter.id, userAssetFilter)
                    )
                } else {
                    promises.push(
                        UserService.deleteUserAssetfilter(userAssetFilter.id)
                    );
                }
            } else {
                if (x.filter !== null && x.filter !== "") {
                    userAssetFilter = { type: x.type, filter: x.filter, userId: user?.id } as UserAssetFilterDTO
                    promises.push(
                        UserService.postUserAssetfilter(userAssetFilter)
                    )
                }

            }
        })

        return Promise.all(promises)
    }, [user, userAssetFilters])

    const onChangePasswordOpen = () => {

        UserService.postUserResettoken({ email: user?.email ?? "", password: "", token: "" }).then(resp => {
            setResetModel({
                email: user?.email ?? "",
                token: resp,
                password: ''
            });
            setIsModalOpen(true);
        });
    }

    const onChangePasswordFinish = () => {
        messageApi.success("User password changed");
        setIsModalOpen(false);
    }

    const onSendWelcomeMail = () => {
        mutateWelcomeAsync({ requestBody: { email: user?.email ?? "" } });
    }

    const onFiltersChanged = useCallback(async (userfilters: UserAssetFilterDTO[]) => {
        updateUserAssetFilters(userfilters).then(() => {
            queryClient.invalidateQueries({ queryKey: [useUserServiceGetUserAssetFilterKey] });
            queryClient.refetchQueries({ queryKey: [useUserServiceGetUserAssetFilterKey] });
        })
    }, [updateUserAssetFilters, queryClient])

    const handleCancel = useCallback(() => {
        setIsModalOpen(false)
    }, [])

    const handleDuplicates = (rule: any, value: any, callback: (errors?: any) => void) => {

        const { getFieldsValue } = form;

        if (user) {        
            let fields = getFieldsValue();

            var alreadyExists = fields.userRenderLimits.filter(x => x.submissionplanId === value).length > 1;
            if (alreadyExists) {                
                callback("Selected submissionplan already exists")
            }
        }            

        callback();
    }

    return (
        <>
            {contextHolder}
            <Drawer
                title="Edit user"
                width={720}
                onClose={onClose}
                open={isOpen}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={isUserPending}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >

                    <Form.Item rules={[{ required: true }]} name='clientId' label="Client">
                        <Select options={clients?.value.map(e => ({ label: e.name, value: e.id }))} />
                    </Form.Item>

                    <Form.Item rules={[{ required: true }]} name="username" label="Username">
                        <Input />
                    </Form.Item>

                    <Form.Item rules={[{
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    }]} name="email" label="Email">
                        <Input />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button name="password" onClick={onChangePasswordOpen}>
                                Change password
                            </Button>
                            <Button onClick={onSendWelcomeMail} loading={isWelcomePending}>
                                Send welcome mail
                            </Button>
                        </Space>
                        <Modal title="Change password" open={isModalOpen} onCancel={() => handleCancel()} footer={null}>
                            <ResetPassword onReset={onChangePasswordFinish} email={resetModel.email} token={resetModel.token} />
                        </Modal>
                    </Form.Item>

                    <Form.Item label="Roles">
                        <Transfer<RecordType>
                            dataSource={roles?.map(x => { return { key: x.name, title: x.name, disabled: false } }) ?? []}
                            listStyle={{
                                width: 300,
                                height: 300,
                            }}
                            titles={["Available", "Chosen"]}
                            targetKeys={selectedRoles}
                            onChange={handleRoleTransfer}
                            render={(item) => item.title}
                        />
                    </Form.Item>

                    <Form.Item label="Claims">
                        <Transfer<RecordType>
                            dataSource={claims?.map(x => { return { key: x.value, title: x.value, disabled: implicitClaims.includes(x.value) } }) ?? []}
                            listStyle={{
                                width: 300,
                                height: 300,
                            }}
                            titles={["Available", "Chosen"]}
                            targetKeys={[...new Set([...selectedClaims, ...implicitClaims])]}
                            onChange={handleClaimTransfer}
                            render={(item) => item.title}
                        />
                    </Form.Item>
                    <Form.Item label="Render Limits">
                        <Form.List name="userRenderLimits" >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <div key={key} style={{ display: 'flex', gap: "8px", alignItems: "center" }}>
                                            <Form.Item {...restField} style={{ flex: "1 0 auto" }} name={[name, "submissionplanId"]} rules={[{ required: true, validator: handleDuplicates }]}>
                                                <Select placeholder="Submissionplan" options={stillImageSubmissionPlans.map(x => ({ label: x.title, value: x.id }))} />
                                            </Form.Item>
                                            <Form.Item {...restField} name={[name, "limit"]} rules={[{ required: true }]}>
                                                <Input placeholder="Limit" />
                                            </Form.Item>
                                            <Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </Form.Item>
                                        </div>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add Userlimit
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        {/* <UserLimits user={user} />*/}
                    </Form.Item>

                    <Form.Item label="Filters">
                        <Button onClick={() => setFilterEditorOpen(true)} icon={<FilterOutlined />}>Filter Editor</Button>
                        <UserFiltersEditor
                            user={user}
                            userAssetFilters={localUserAssetFilters}
                            isOpen={filterEditorOpen}
                            onClose={onFilterEditorClose}
                            onFiltersChange={onFiltersChanged}
                        />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default EditUser;