import React from 'react';
import DeliveryConfigurationList from '../../../components/admin/DeliveryConfiguration/DeliveryConfigurationList';

const DeliveryConfiguration: React.FC = () => {

    return (<div>
        <DeliveryConfigurationList />
    </div>)
};

export default DeliveryConfiguration;