import { Chart } from 'react-chartjs-2';
import 'chart.js/auto';
import { ChartTypeRegistry } from "chart.js/auto";
import "./StatisticsChart.scss";
import { ChartData } from '../../../openapi/requests';

const StatisticsChart: React.FC<{ labels: string[], chartData: ChartData, color?: string }> = ({labels, chartData, color}) => {

    return (
        <Chart className='statistics-chart'       
        type={chartData.type.toLowerCase() as keyof ChartTypeRegistry}
        data={{labels: labels, datasets: [{data: chartData.data, label: chartData.name}]}}    
        options={{                            
            scales: {                            
              y: {
                beginAtZero: true                
              }              
            },          
            borderColor(ctx, options) {  
              if (color)
                return color;
            },
            backgroundColor(ctx, options) {   
              if (color)
               return color;
            } 
          }}          
        />
    )

}

export default StatisticsChart;