/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetApprovalHistoryDTO } from '../models/AssetApprovalHistoryDTO';
import type { ModifierEditDTO } from '../models/ModifierEditDTO';
import type { ModifierViewDTO } from '../models/ModifierViewDTO';
import type { ModifierViewDTOODataListResponse } from '../models/ModifierViewDTOODataListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ModifierService {

    /**
     * @param query 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ModifierViewDTOODataListResponse Success
     * @throws ApiError
     */
    public static getModifier(
query?: string,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ModifierViewDTOODataListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Modifier',
            query: {
                'query': query,
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ModifierViewDTO Success
     * @throws ApiError
     */
    public static postModifier(
requestBody?: ModifierEditDTO,
): CancelablePromise<ModifierViewDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Modifier',
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @param top The max number of records.
     * @param skip The number of records to skip.
     * @param filter A function that must evaluate to true for a record to be returned.
     * @param select Specifies a subset of properties to return. Use a comma separated list.
     * @param orderby Determines what values are used to order a collection of records.
     * @param expand Use to add related query data.
     * @param count Include the overall count
     * @returns ModifierViewDTO Success
     * @throws ApiError
     */
    public static getModifier1(
key: number,
top?: string,
skip?: string,
filter?: string,
select?: string,
orderby?: string,
expand?: string,
count?: string,
): CancelablePromise<ModifierViewDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Modifier/{key}',
            path: {
                'key': key,
            },
            query: {
                '$top': top,
                '$skip': skip,
                '$filter': filter,
                '$select': select,
                '$orderby': orderby,
                '$expand': expand,
                '$count': count,
            },
        });
    }

    /**
     * @param key 
     * @param requestBody 
     * @returns ModifierViewDTO Success
     * @throws ApiError
     */
    public static putModifier(
key: number,
requestBody?: ModifierEditDTO,
): CancelablePromise<ModifierViewDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/Modifier/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json;odata.metadata=minimal;odata.streaming=true',
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteModifier(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/Modifier/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param clientId 
     * @param query 
     * @returns ModifierViewDTO Success
     * @throws ApiError
     */
    public static getModifierQuery(
clientId?: number,
query?: string,
): CancelablePromise<Array<ModifierViewDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Modifier/query',
            query: {
                'clientId': clientId,
                'query': query,
            },
        });
    }

    /**
     * @param key 
     * @param size 
     * @param format 
     * @returns any Success
     * @throws ApiError
     */
    public static getModifierThumbnail(
key: number,
size: number = 256,
format: string = 'webp',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Modifier/{key}/thumbnail/{size}',
            path: {
                'key': key,
            },
            query: {
                'size': size,
                'format': format,
            },
        });
    }

    /**
     * @param key 
     * @returns any Success
     * @throws ApiError
     */
    public static getModifierImage(
key: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Modifier/{key}/image',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postModifierImage(
key: number,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Modifier/{key}/image',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postModifierQAimages(
key: number,
formData?: {
files?: Array<Blob>;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Modifier/{key}/QAimages',
            path: {
                'key': key,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param key 
     * @returns string Success
     * @throws ApiError
     */
    public static getModifierQAimages(
key: number,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Modifier/{key}/QAimages',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @param key 
     * @param index 
     * @returns any Success
     * @throws ApiError
     */
    public static getModifierQAimages1(
key: number,
index: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Modifier/{key}/QAimages/{index}',
            path: {
                'key': key,
                'index': index,
            },
        });
    }

    /**
     * @param key 
     * @param index 
     * @returns any Success
     * @throws ApiError
     */
    public static getModifierApprovalHistoryImages(
key: number,
index: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Modifier/{key}/ApprovalHistory/images/{index}',
            path: {
                'key': key,
                'index': index,
            },
        });
    }

    /**
     * @param key 
     * @returns AssetApprovalHistoryDTO Success
     * @throws ApiError
     */
    public static getModifierApprovalHistory(
key: number,
): CancelablePromise<Array<AssetApprovalHistoryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Modifier/{key}/ApprovalHistory',
            path: {
                'key': key,
            },
        });
    }

}
